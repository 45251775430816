import { IProjectAction, IProjectMomState } from '../interfaces/ProjectMomInterfaces';
import {
  LOAD_ALL_MOM_SITES_BY_EXTERNAL_ID,
  IS_MOM_SITES_LOADING,
  LOAD_ALL_TEMPLATE_CATALOGS,
  LOAD_MOM_TEMPLATES_BY_CATALOG_ID,
  SELECT_PROJECT_MOM_SITE,
  LOAD_PROJECT_MOM_SITE_CONTENTS,
  IS_MOM_SITES_DIALOG_LOADING,
  RESET_PROJECT_MOM_MODULE,
} from './ProjectMOMListActions';

const defaultState: IProjectMomState = {
  isLoading: false,
  isDialogLoading: false,
  projectSites: [],
  totalProjectSites: 0,
  selectedProjectSite: null,
  selectedProjectSiteContent: [],
  momTemplateCatalogs: [],
  allMomTemplates: [],
  selectedTemplateId: '',
};
const ProjectMOMListReducer = (state = defaultState, { type, payload }: IProjectAction) => {
  const newState = () => ({ ...state, ...payload });

  const reset = () => ({ ...defaultState });

  const stateActions = {
    [LOAD_ALL_MOM_SITES_BY_EXTERNAL_ID]: newState,
    [IS_MOM_SITES_LOADING]: newState,
    [IS_MOM_SITES_DIALOG_LOADING]: newState,
    [LOAD_ALL_TEMPLATE_CATALOGS]: newState,
    [LOAD_MOM_TEMPLATES_BY_CATALOG_ID]: newState,
    [SELECT_PROJECT_MOM_SITE]: newState,
    [LOAD_PROJECT_MOM_SITE_CONTENTS]: newState,
    [RESET_PROJECT_MOM_MODULE]: reset,
  };

  const currentState = stateActions[type];
  if (!currentState) return state;
  return currentState();
};

export default ProjectMOMListReducer;
