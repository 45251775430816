import Auth from 'utils/Auth';

export function getParams(
  type:
    | 'GET'
    | 'POST'
    | 'PUT'
    | 'PATCH'
    | 'DELETE'
    | 'GETREFRESH'
    | 'POSTLOGIN'
    | 'POSTFILE'
    | 'GETTEXTPLAIN'
    | 'GETCODELOGIN',
  body?: string,
  contentType?: string | undefined
) {
  const content = contentType !== undefined ? contentType : 'application/json; charset=utf-8';
  let params: any = {};
  const authHeader = Auth.getAuthHeader();
  switch (type) {
    case 'POSTLOGIN':
      params = {
        body,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        method: 'POST',
      };
      break;
    case 'GETCODELOGIN':
      params = {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        method: 'GET',
      };
      break;
    case 'GETREFRESH':
      params = {
        credentials: 'include',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'text/plain',
        },
        method: 'GET',
      };
      break;
    case 'GET':
      params = {
        headers: {
          Authorization: authHeader,
          Accept: 'application/json; charset=utf-8',
        },
        method: 'GET',
      };
      break;
    case 'GETTEXTPLAIN':
      params = {
        headers: {
          Authorization: authHeader,
          Accept: 'application/json; charset=utf-8',
        },
        method: 'GET',
      };
      break;
    case 'POST':
      params = {
        body,
        headers: {
          'Content-Type': content,
          Authorization: authHeader,
        },
        method: 'POST',
      };
      break;
    case 'POSTFILE':
      params = {
        body,
        headers: {
          // Content-Type must not be set manually here, because the multipart/form
          // content type gets set automatically (with extra necessary information)
          Authorization: authHeader,
        },
        method: 'POST',
      };
      break;
    case 'PUT':
      params = {
        body,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: authHeader,
        },
        method: 'PUT',
      };
      break;
    case 'DELETE':
      params = {
        body,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: authHeader,
        },
        method: 'DELETE',
      };
      break;
    case 'PATCH':
      params = {
        body,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: authHeader,
        },
        method: 'PATCH',
      };
      break;
  }
  return params;
}
