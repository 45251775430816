import { Box, styled, Button } from '@mui/material';

export const IconWrapper = styled(Box)<any>(
  ({ theme }) => `
  border-radius: 50%;
  padding: 4px;
  width: 68px;
  height: 68px;
  background-color: ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 24px;
  right: 25%;
  align-self: end;
  margin-bottom: 16px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
`
);

export const IconWrapperProductList = styled(Box)<any>(
  ({ theme }) => `
  border-radius: 50%;
  padding: 4px;
  width: 68px;
  height: 68px;
  background-color: #32226B;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 24px;
  right: 25%;
  align-self: end;
  margin-bottom: 16px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
`
);

export const IconProductWrapper = styled(Box)<any>(
  ({ theme }) => `
  border-radius: 50%;
  padding: 4px;
  width: 68px;
  height: 68px;
  background-color: ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 24px;
  right: 5%;
  align-self: end;
  margin-bottom: 16px;
  margin-top: 32px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
`
);

export const IconPieceworkWrapper = styled(Box)<any>(
  ({ theme }) => `
  border-radius: 50%;
  padding: 4px;
  width: 68px;
  height: 68px;
  background-color: #32226B;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 24px;
  right: 5%;
  align-self: end;
  margin-bottom: 16px;
  margin-top: 32px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
`
);

export const IconSupplierWrapper = styled(Box)<any>(
  ({ theme }) => `
  border-radius: 50%;
  padding: 4px;
  width: 28px;
  height: 28px;
  background-color: ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 24px;
  right: 5%;
  align-self: end;
  margin-bottom: 16px;
  margin-top: 32px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
`
);

export const SidebarWrapper = styled(Box)<any>(
  () => `
  width: 20%;
  border-top-left-radius: 55px;
  border-bottom-left-radius: 55px;
  background-color: #fff;
  padding-left: 48px;
  padding-right: 32px;
  padding-top: 64px;
  padding-bottom: 16px;
  height: calc(100vh - 85px);
  position: fixed;
  min-height: calc(100vh - 85px);
  max-height: calc(100vh - 85px);
  right: 0;
  `
);

export const FilterWrapper = styled(Box)<any>(
  () => `
  width: 20%;
  border-top-left-radius: 55px;
  border-bottom-left-radius: 55px;
  background-color: #fff;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 44px;
  padding-bottom: 44px;
  height: calc(100vh - 85px);
  position: fixed;
  min-height: calc(100vh - 85px);
  max-height: calc(100vh - 85px);
  overflow-y: scroll;
  right: 0;
  `
);

export const SidebarActionButtons = styled(Box)<any>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const EditButton = styled(Button)<any>`
  flex-basis: 50%;
  background: ${(props) => props.theme.palette.secondary.lighter};
  color: ${(props) => props.theme.palette.primary.main};
  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.grey[300]};
    background: ${(props) => props.theme.palette.primary.main};
  }
`;

export const DeleteButton = styled(Button)<any>`
  flex-basis: 50%;
  color: ${(props) => props.theme.palette.primary.darkRed};
  background: ${(props) => props.theme.palette.error.lighter};

  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.grey[300]};
    background: ${(props) => props.theme.palette.error.main};
  }
`;
