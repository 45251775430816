import { FC } from 'react';

type OfferTypeCircleIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const OfferTypeCircleIcon: FC<OfferTypeCircleIconPropTypes> = ({
  width = 34,
  height = 34,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#E6F0F5" />
      <path
        d="M10.6868 22.1002L11.5801 22.4735V16.4535L9.96015 20.3602C9.68681 21.0402 10.0135 21.8202 10.6868 22.1002ZM23.6868 19.6335L20.3801 11.6535C20.1735 11.1535 19.6868 10.8468 19.1735 10.8335C19.0001 10.8335 18.8201 10.8602 18.6468 10.9335L13.7335 12.9668C13.2335 13.1735 12.9268 13.6535 12.9135 14.1668C12.9068 14.3468 12.9401 14.5268 13.0135 14.7002L16.3201 22.6802C16.5268 23.1868 17.0201 23.4935 17.5401 23.5002C17.7135 23.5002 17.8868 23.4668 18.0535 23.4002L22.9601 21.3668C23.6401 21.0868 23.9668 20.3068 23.6868 19.6335ZM14.2535 14.8335C13.8868 14.8335 13.5868 14.5335 13.5868 14.1668C13.5868 13.8002 13.8868 13.5002 14.2535 13.5002C14.6201 13.5002 14.9201 13.8002 14.9201 14.1668C14.9201 14.5335 14.6201 14.8335 14.2535 14.8335ZM12.9201 22.1668C12.9201 22.9002 13.5201 23.5002 14.2535 23.5002H15.2201L12.9201 17.9402V22.1668Z"
        fill={color}
      />
    </svg>
  );
};

export default OfferTypeCircleIcon;
