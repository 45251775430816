import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import store from 'redux/store';

import {
  GetPersonResponse,
  PersonItem,
  CreateCRMPerson,
  CreateCRMPersonForm,
  UpdateEmail,
  UpdatePhone,
} from 'modules/CRM/CRMInterfaces';

import API from 'utils/API';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';

export const getPersonById = async (personId: string): Promise<PersonItem> => {
  const response = await API.get(`crm/api/persons/${personId}`);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  return data.payload;
};

type CreatePersonVariables = {
  person: CreateCRMPerson;
  callback?: Function;
};

export const createPerson = async ({ person, callback }: CreatePersonVariables): Promise<PersonItem> => {
  const response = await API.post('crm/api/persons', person);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  callback?.(data.payload);
  return data.payload;
};

type UpdatePersonVariables = {
  personId: string;
  person: CreateCRMPersonForm;
  callback?: Function;
};

export const updatePerson = async ({ personId, person, callback }: UpdatePersonVariables): Promise<PersonItem> => {
  const response = await API.put(`crm/api/persons/${personId}`, person);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  callback?.(data.payload);
  return data.payload;
};

export const deletePerson = async (personId: string): Promise<PersonItem> => {
  const response = await API.delete(`crm/api/persons/${personId}`);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  return data.payload;
};

type AddPersonEmailAddressVariables = {
  personId: string;
  newEmail: UpdateEmail;
};

type UpdatePersonEmailVariables = {
  emailId: string;
} & AddPersonEmailAddressVariables;
export const addPersonEmailAddress = async ({
  personId,
  newEmail,
}: AddPersonEmailAddressVariables): Promise<PersonItem> => {
  const response = await API.post(`crm/api/persons/${personId}/emailAddresses`, newEmail);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  return data.payload;
};

export const updatePersonEmailAddress = async ({
  personId,
  emailId,
  newEmail,
}: UpdatePersonEmailVariables): Promise<PersonItem> => {
  const response = await API.put(`crm/api/persons/${personId}/emailAddresses/${emailId}`, newEmail);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  return data.payload;
};

type AddPersonPhoneVariables = {
  personId: string;
  newPhone: UpdatePhone;
};
type UpdatePersonPhoneVariables = {
  phoneId: string;
} & AddPersonPhoneVariables;
export const addPersonPhoneNumber = async ({ personId, newPhone }: AddPersonPhoneVariables): Promise<PersonItem> => {
  const response = await API.post(`crm/api/persons/${personId}/telephoneNumbers`, newPhone);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  return data.payload;
};
export const updatePersonPhoneNumber = async ({
  personId,
  phoneId,
  newPhone,
}: UpdatePersonPhoneVariables): Promise<PersonItem> => {
  const response = await API.put(`crm/api/persons/${personId}/telephoneNumbers/${phoneId}`, newPhone);
  const { data }: AxiosResponse<GetPersonResponse> = response;
  return data.payload;
};

export const useGetPersonById = (personId: string) =>
  useQuery({ queryKey: ['person', personId], queryFn: () => getPersonById(personId), retry: 3, retryDelay: 3000 });

export const useCreatePerson = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(createPerson, {
    onSuccess: () => {
      const message = i18n.t('persons.successAddPerson');
      setTimeout(() => {
        store.dispatch(addSuccessMessage({ message }));
        queryClient.invalidateQueries(refetch || ['']);
      }, 1000);
      setTimeout(() => {
        onSuccess?.();
      }, 1500);
    },
    onError: (error: any) => {
      const failAddPersonMessage = i18n.t('persons.failAdddPerson');
      store.dispatch(addErrorMessage({ message: failAddPersonMessage }));
      onError?.();
    },
  });

export const useUpdatePerson = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(updatePerson, {
    onSuccess: () => {
      const message = i18n.t('persons.successUpdatePerson');
      store.dispatch(addSuccessMessage({ message }));
      setTimeout(() => {
        queryClient.invalidateQueries(refetch || ['']);
      }, 1000);
      setTimeout(() => {
        onSuccess?.();
      }, 1500);
    },
    onError: (error: any) => {
      const message = i18n.t('persons.failUpdatePerson');
      store.dispatch(addErrorMessage({ message }));
      onError?.();
    },
  });

export const useDeletePerson = (queryClient: QueryClient) =>
  useMutation(deletePerson, {
    onSuccess: () => {
      const successMessage = i18n.t('persons.successDeletePerson');
      store.dispatch(addSuccessMessage({ message: successMessage }));
      queryClient.invalidateQueries(['crmContacts']);
    },
    onError: (error: any) => {
      const message = i18n.t('persons.failDeletedPerson');
      store.dispatch(addErrorMessage({ message }));
    },
  });

export const useAddPersonEmailAddress = () => useMutation(addPersonEmailAddress);
export const useUpdatePersonEmailAddress = () => useMutation(updatePersonEmailAddress);

export const useAddPersonPhoneNumber = () => useMutation(addPersonPhoneNumber);
export const useUpdatePersonPhoneNumber = () => useMutation(updatePersonPhoneNumber);
