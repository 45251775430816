import {
  IS_TEMPLATE_LOADING,
  LOAD_ALL_TEMPLATES,
  SAVE_ALL_TEMPLATES_COUNT,
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_ATTACHMENTS,
  SAVE_TEMPLATE_COMPONENT_DATA,
  SAVE_TEMPLATE_CONTENT,
  SET_HAS_MORE_ITEMS,
} from './templatesActions';
import { ITemplateState } from './templatesTypes';

const defaultState = {
  items: [],
  isLoading: false,
  selectedItem: null,
  hasMoreItems: false,
  totalItems: 0,
  selectedTemplate: [],
  componentData: null,
  attachments: [],
} as ITemplateState;

export const templates = (state = defaultState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ALL_TEMPLATES:
      return { ...state, ...payload };
    case SAVE_ALL_TEMPLATES_COUNT:
      return { ...state, totalItems: payload };
    case IS_TEMPLATE_LOADING:
      return { ...state, isLoading: payload };
    case SET_HAS_MORE_ITEMS:
      return { ...state, hasMoreItems: payload };
    case SAVE_TEMPLATE:
      return { ...state, selectedItem: payload };
    case SAVE_TEMPLATE_CONTENT:
      return { ...state, selectedTemplate: payload };
    case SAVE_TEMPLATE_COMPONENT_DATA:
      return { ...state, componentData: payload };
    case SAVE_TEMPLATE_ATTACHMENTS:
      return { ...state, attachments: payload };
  }
  return state;
};

export default templates;
