import {
  INewNodesListItem,
  IPackageSpecificationItemsAction,
  IPackageSpecificationItemsState,
} from '../PackageSpecificationItems/packagesSpecificationItemsInterface';
import {
  IS_CATALOG_LOADING_IN_PACKAGES,
  SEARCH_ITEM_SUCCESS_IN_PACKAGES,
  LOAD_PRODUCTS_CATALOGS_IN_PACKAGES,
  LOAD_PIECE_WORK_TRADES_IN_PACKAGES,
  LOAD_PIECE_WORK_CATEGORIES_IN_PACKAGES,
  LOAD_PIECE_WORK_RATE_CATALOGS_IN_PACKAGES,
  LOAD_SPECIFICATION_TABLE_ITEMS_IN_PACKAGES,
  LOAD_WORK_CATEGORIES_IN_PACKAGES,
  SELECT_PRODUCT_CATALOG_IN_PACKAGES,
  SELECT_WORK_CATALOG_IN_PACKAGES,
  CLEAR_ITEMS_IN_PACKAGES,
  CLEAR_SELECTED_ITEMS_IN_PACKAGES,
  SINGLE_SELECTED_ITEM_IN_PACKAGES,
  CLEAR_SINGLE_SELECTED_ITEM_IN_PACKAGES,
  UPDATE_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES,
  LOAD_SPECIFICATION_PRODUCT_IDS_IN_PACKAGES,
  REFRESH_ITEMS_LIST_IN_PACKAGES,
  LOAD_NSCODES_CATEGORIES_IN_PACKAGES,
  SELECT_NSCODE_CATEGORY_IN_PACKAGES,
  SELECT_NODE_ROW_IN_PACKAGES,
  SELECT_SPECIFICATION_NODE_IN_PACKAGES,
  DESELECT_SPECIFICATION_NODE_IN_PACKAGES,
  IS_TABLE_LOADING_IN_PACKAGES,
  LOAD_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES,
  SELECT_SPECIFICATION_ITEM_IN_PACKAGES,
  DESELECT_SPECIFICATION_ITEM_IN_PACKAGES,
  RESET_SPECIFICATION_PACKAGES_MODULE_STATE,
} from './packageSpecificationItemsActions';

const initialState: IPackageSpecificationItemsState = {
  catalogs: [],
  isCatalogLoading: false,
  isTableLoading: false,
  itemsLastUpdate: 0,
  items: [],
  totalItems: 0,
  selectedItems: [],
  trades: [],
  pieceworkCategories: [],
  hits: [],
  totalHits: 0,
  productsCatalogs: [],
  packagesCatalogs: [],
  workCatalogs: [],
  nsCodeCategories: [],
  multiSelectedNodes: [],
  singleSelectedItem: null,
  specificationItemsConfigs: {
    specificationType: '',
    specificationCatalog: '',
    searchType: '',
    columnOption: '1',
    productsCatalogId: '',
    packagesCatalogId: '',
    workCategoriesCatalogId: '',
    nsCodesCatalogId: '',
  },
  products: [],
  selectedNode: null,
  selectedRow: null,
};

const packageSpecificationItemsReducer = (
  state = initialState,
  { type, payload }: IPackageSpecificationItemsAction
) => {
  const newState = () => ({ ...state, ...payload });

  const loadConfigItemsState = () => ({
    ...state,
    specificationItemsConfigs: { ...state.specificationItemsConfigs, ...payload },
  });

  const selectSpecificationItemsInPackageState = () => {
    const data = payload as IPackageSpecificationItemsState;
    return { ...state, selectedItems: [...state.selectedItems, ...data.selectedItems] };
  };

  const deselectItemsState = () => {
    const item = payload as INewNodesListItem;
    const selectedItems = state.selectedItems.filter(({ id }) => id !== item.id);
    return { ...state, selectedItems: [...selectedItems] };
  };

  const resetState = () => initialState;

  const stateActions = {
    [IS_CATALOG_LOADING_IN_PACKAGES]: newState,
    [SEARCH_ITEM_SUCCESS_IN_PACKAGES]: newState,
    [LOAD_PRODUCTS_CATALOGS_IN_PACKAGES]: newState,
    [LOAD_PIECE_WORK_TRADES_IN_PACKAGES]: newState,
    [LOAD_PIECE_WORK_CATEGORIES_IN_PACKAGES]: newState,
    [LOAD_PIECE_WORK_RATE_CATALOGS_IN_PACKAGES]: newState,
    [LOAD_SPECIFICATION_TABLE_ITEMS_IN_PACKAGES]: newState,
    [LOAD_WORK_CATEGORIES_IN_PACKAGES]: newState,
    [SELECT_PRODUCT_CATALOG_IN_PACKAGES]: newState,
    [SELECT_WORK_CATALOG_IN_PACKAGES]: newState,
    [CLEAR_ITEMS_IN_PACKAGES]: newState,
    [CLEAR_SELECTED_ITEMS_IN_PACKAGES]: newState,
    [SINGLE_SELECTED_ITEM_IN_PACKAGES]: newState,
    [CLEAR_SINGLE_SELECTED_ITEM_IN_PACKAGES]: newState,
    [UPDATE_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES]: newState,
    [LOAD_SPECIFICATION_PRODUCT_IDS_IN_PACKAGES]: newState,
    [REFRESH_ITEMS_LIST_IN_PACKAGES]: newState,
    [LOAD_NSCODES_CATEGORIES_IN_PACKAGES]: newState,
    [SELECT_NSCODE_CATEGORY_IN_PACKAGES]: newState,
    [SELECT_NODE_ROW_IN_PACKAGES]: newState,
    [IS_TABLE_LOADING_IN_PACKAGES]: newState,
    [SELECT_SPECIFICATION_NODE_IN_PACKAGES]: newState,
    [DESELECT_SPECIFICATION_NODE_IN_PACKAGES]: newState,
    [LOAD_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES]: loadConfigItemsState,
    [SELECT_SPECIFICATION_ITEM_IN_PACKAGES]: selectSpecificationItemsInPackageState,
    [DESELECT_SPECIFICATION_ITEM_IN_PACKAGES]: deselectItemsState,
    [RESET_SPECIFICATION_PACKAGES_MODULE_STATE]: resetState,
  };

  const currentState = stateActions[type];
  if (!currentState) {
    return state;
  }
  return currentState();
};

export default packageSpecificationItemsReducer;
