import MenuIcon from '@mui/icons-material/Menu';

import { Box, IconButton, Avatar, Typography } from '@mui/material';
import { FC, ReactElement, useContext, useMemo } from 'react';

import { AppContext, IAppContext } from 'AppProvider';

import Breadcrumb, { IBreadcrumb } from 'containers/Page/Breadcrumb';

import { IUserState } from 'modules/User/userInterfaces';
import { useModuleList } from 'modules/common';
import { stringAvatar } from 'utils/helpers';

type NavigationProps = {
  title: string;
  breadcrumb?: IBreadcrumb[];
};

const TopNavigation: FC<NavigationProps> = ({ title, breadcrumb }): ReactElement => {
  const { setIsAppDrawerOpen } = useContext(AppContext) as IAppContext;
  const { useAppSelector, t } = useModuleList();

  const { myInfo }: IUserState = useAppSelector(({ userModule }) => userModule);

  const handleSidebarToggle = () => {
    setIsAppDrawerOpen((prev: boolean) => !prev);
  };

  const fullName = useMemo(() => {
    return myInfo?.forename + ' ' + myInfo?.surname || '';
  }, [myInfo?.forename, myInfo?.surname]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2, height: '85px' }}>
      <Box display="flex" alignItems="center">
        <IconButton
          sx={{ backgroundColor: 'secondary.lighter', borderRadius: '10px', padding: 1, marginRight: 2 }}
          onClick={handleSidebarToggle}>
          <MenuIcon sx={{ color: '#000' }} />
        </IconButton>
        {/* <Typography variant="h2" component="h1">
          {title}
        </Typography> */}
        {breadcrumb && <Breadcrumb breadcrumb={breadcrumb} />}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar {...stringAvatar(fullName)} sx={{ bgcolor: 'orange', width: 32, height: 32, fontSize: 16 }} />
      </Box>
    </Box>
  );
};

export default TopNavigation;
