import { FC } from 'react';

type MultiplePersonsCirclePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MultiplePersonsCircle: FC<MultiplePersonsCirclePropTypes> = ({
  width = 57,
  height = 56,
  color = 'none',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill={color} xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#F4F7FE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0225 28.967C36.8972 30.2397 38.2108 31.9638 38.2108 34.2628V38.368H43.6844V34.2628C43.6844 31.2796 38.7993 29.5144 35.0225 28.967Z"
        fill="#0774A4"
      />
      <path
        d="M24.5268 27.4209C27.5498 27.4209 30.0004 24.9702 30.0004 21.9472C30.0004 18.9243 27.5498 16.4736 24.5268 16.4736C21.5038 16.4736 19.0532 18.9243 19.0532 21.9472C19.0532 24.9702 21.5038 27.4209 24.5268 27.4209Z"
        fill="#0673A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7375 27.4209C35.7616 27.4209 38.2111 24.9714 38.2111 21.9472C38.2111 18.9231 35.7616 16.4736 32.7375 16.4736C32.0943 16.4736 31.4922 16.6105 30.9175 16.802C32.0533 18.2115 32.7375 20.0041 32.7375 21.9472C32.7375 23.8904 32.0533 25.683 30.9175 27.0924C31.4922 27.284 32.0943 27.4209 32.7375 27.4209Z"
        fill="#0673A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5268 28.7891C20.8732 28.7891 13.5796 30.6227 13.5796 34.2627V38.3679H35.474V34.2627C35.474 30.6227 28.1804 28.7891 24.5268 28.7891Z"
        fill="#0673A3"
      />
    </svg>
  );
};

export default MultiplePersonsCircle;
