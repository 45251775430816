import { FC } from 'react';

type SelectedRoundCheckboxType = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const SelectedRoundCheckbox: FC<SelectedRoundCheckboxType> = ({
  width = 36,
  height = 36,
  color = '#0975A5',
  backgroundColor = '#F8FBFC',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M18 37C27.9411 37 36 28.9411 36 19C36 9.05887 27.9411 1 18 1C8.05887 1 0 9.05887 0 19C0 28.9411 8.05887 37 18 37Z"
        fill={backgroundColor}
      />
      <path
        d="M17.9993 10.667C13.3993 10.667 9.66602 14.4003 9.66602 19.0003C9.66602 23.6003 13.3993 27.3337 17.9993 27.3337C22.5993 27.3337 26.3327 23.6003 26.3327 19.0003C26.3327 14.4003 22.5993 10.667 17.9993 10.667ZM16.3327 23.167L12.166 19.0003L13.341 17.8253L16.3327 20.8087L22.6577 14.4837L23.8327 15.667L16.3327 23.167Z"
        fill={color}
      />
    </svg>
  );
};

export default SelectedRoundCheckbox;
