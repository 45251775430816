import { FC, ReactElement, ReactNode } from 'react';
import { Box, LinearProgress, Grid } from '@mui/material';
import { LinearProgressStyles, PageContainer, PageContents, PageHeader, PageWrapper } from './PageStyles';
import { IBreadcrumb } from './Breadcrumb';
import TopNavigation from '../Layout/TopNavigation';

interface IPage {
  children: ReactNode;
  title?: string;
  sidebarChildren?: ReactNode | null;
  sidebarWidth?: number;
  actionItems?: ReactNode | null;
  isLoading?: boolean;
  hideFilters?: boolean;
  pagingComponent?: null | ReactNode;
  breadcrumb?: Array<IBreadcrumb>;
}

export const Page: FC<IPage> = (props: IPage): ReactElement => {
  const {
    title = '',
    children = <></>,
    actionItems = <></>,
    isLoading = false,
    hideFilters = false,
    pagingComponent = null,
    breadcrumb = [],
  } = props;

  return (
    <PageContainer className="page-container">
      {isLoading && <LinearProgress sx={LinearProgressStyles} color="secondary" />}
      <PageWrapper id="PageWrapper">
        <PageHeader id="PageHeader">
          <TopNavigation title={title} breadcrumb={breadcrumb} />
          <Grid container>
            <Grid item xs={6}>
              <Grid container direction="row-reverse">
                {actionItems}
              </Grid>
            </Grid>
          </Grid>
        </PageHeader>
        <PageContents className="page-contents">{children}</PageContents>
        {!hideFilters && (
          <Box sx={{ p: 1 }} id="page-footer">
            {pagingComponent !== null && pagingComponent}
          </Box>
        )}
      </PageWrapper>
    </PageContainer>
  );
};

export default Page;
