import { Paper, Typography, styled, TypographyProps } from '@mui/material';

type CardSoftTextProps = {
  noMarginLeft?: boolean;
} & TypographyProps;

export const ExportOfferPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  boxShadow: 'none',
  borderRadius: theme.spacing(2),
}));

export const FlexCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  boxShadow: 'none',
  borderRadius: theme.spacing(2),
  height: '100%',
}));

export const CardSoftText = styled(Typography, {
  shouldForwardProp: (props) => props !== 'noMarginLeft',
})<CardSoftTextProps>(({ noMarginLeft, theme }) => ({
  // color: `${theme.palette.purple.light}er}`,
  fontFamily: 'DM Sans',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
  letterSpacing: '-0.24px',
  marginLeft: noMarginLeft ? '-16px' : '0',
}));
