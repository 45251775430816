import { FC } from 'react';

type CalculatorPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Calculator: FC<CalculatorPropTypes> = ({ width = 34, height = 34, color = '#78603D', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M26.526 4.82982H7.3684C5.86315 4.82982 4.63159 6.06138 4.63159 7.56662V26.7243C4.63159 28.2295 5.86315 29.4611 7.3684 29.4611H26.526C28.0313 29.4611 29.2628 28.2295 29.2628 26.7243V7.56662C29.2628 6.06138 28.0313 4.82982 26.526 4.82982ZM18.3567 10.3855L19.8072 8.93503L21.7366 10.8645L23.6661 8.93503L25.1166 10.3855L23.1871 12.315L25.1166 14.2444L23.6661 15.6949L21.7366 13.7792L19.8072 15.7086L18.3567 14.2581L20.2861 12.3287L18.3567 10.3855ZM9.0789 11.2887H15.9209V13.3413H9.0789V11.2887ZM16.263 22.6191H13.5262V25.3559H11.4736V22.6191H8.7368V20.5665H11.4736V17.8296H13.5262V20.5665H16.263V22.6191ZM25.1576 24.3296H18.3156V22.277H25.1576V24.3296ZM25.1576 20.9086H18.3156V18.8559H25.1576V20.9086Z"
        fill={color}
      />
    </svg>
  );
};

export default Calculator;
