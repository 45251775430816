import i18n from 'i18next';

import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';

export const IS_PDF_LOADING = 'IS_PDF_LOADING';
export const SAVE_PREVIEW_PDF_BLOB = 'SAVE_PREVIEW_PDF_BLOB';

export const setIsPdfLoading = (isLoading: boolean) => ({ type: IS_PDF_LOADING, payload: { isLoading } });

export const savePreviewPdfBlob = (blob: Blob | null) => ({
  type: SAVE_PREVIEW_PDF_BLOB,
  payload: { blob },
});

export const loadPdfPreview = (momId: string, body = { appendAttachments: false }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsPdfLoading(true));
      const URL = `/pdfprint/api/MOM/${momId}`;
      const { data } = await API.post(URL, body, { responseType: 'blob' });
      // Create a new Blob object using the response data
      const pdfBlob = new Blob([data], { type: 'application/pdf' });
      dispatch(savePreviewPdfBlob(pdfBlob));
    } catch (e) {
      const message = i18n.t('projects.errorDownloadingPdf');
      dispatch(addErrorMessage({ message }));
      console.error({ e });
    } finally {
      dispatch(setIsPdfLoading(false));
    }
  };
};
