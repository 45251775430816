import { IAccessLevel } from 'interfaces/licenses/IModules';

import { ReactNode, useMemo } from 'react';

import {
  CHECKLISTS_CODE,
  CRM_CODE,
  OFFERS_CODE,
  PRODUCTS_CODE,
  PROJECTS_CODE,
  SITES_CODE,
  PACKAGES_CODE,
  PIECEWORK_CODE,
  TEXTS_CODE,
  BUILDINGELEMENTS_CODE,
  NSCODES_CODE,
  FDV_CODE,
} from 'model/utils/moduleDefinitions';
import { SUPER_DUPER_USER_CODE } from 'model/utils/moduleDefinitions';
import Auth from 'utils/Auth';

interface IProps {
  moduleCode: number;
  role?: 'r' | 'w' | 'a';
}

interface IRoleWrapper extends IProps {
  children: ReactNode;
}

interface ICatalogAccess extends IProps {
  isCatalogPublic?: boolean;
  children: ReactNode;
}
type IModuleRole = 'n' | 'r' | 'w' | 'a';

export const checkRoleByModule = (props: { moduleCode: number }): IModuleRole => {
  const { moduleCode } = props;
  const modules = Auth.getDecodedToken()?.cordel3_modules?.split(';') ?? [];
  const isSuperUser = modules.includes('101000=');
  if (isSuperUser) return 'a';

  const role = modules.find((module) => module.split('=')[0] === `${moduleCode}`)?.split('=')?.[1] ?? 'n';
  return role as IModuleRole;
};

export const RoleWrapper = (props: IRoleWrapper) => {
  const { children, moduleCode, role } = props;

  if (canUserView({ moduleCode, role })) {
    return <>{children}</>;
  }
  return <></>;
};

export const CatalogAccess = (props: ICatalogAccess) => {
  const { children, moduleCode, role, isCatalogPublic = false } = props;
  const isServiceAdmin = useIsServiceAdmin({ moduleCode });
  if (moduleCode >= 100001 && moduleCode <= 190000) return <>{children}</>;

  const requiresWriteAccessIsNotAdminAndCatalogIsPublic = role === 'w' && !isServiceAdmin && isCatalogPublic;

  if (requiresWriteAccessIsNotAdminAndCatalogIsPublic) return <></>;
  if (canUserView({ moduleCode, role })) {
    return <>{children}</>;
  }
  return <></>;
};

export const newAccessLevel = (props: IProps): any => {
  const { moduleCode } = props;
  const token = Auth.getDecodedToken();
  if (!moduleCode) return 'n' as IAccessLevel;
  const modules = token?.cordel3_modules?.split(';') ?? [];
  for (let x = 0; x < modules.length; x++) {
    const element = modules[x];
    const userModuleCodes = element?.split('=') ?? [];
    if (userModuleCodes.length > 0 && moduleCode.toString() === userModuleCodes[0])
      return userModuleCodes[1] as IAccessLevel;
  }
  return 'n' as IAccessLevel;
};

export const canUserView = (props: IProps): boolean => {
  const { moduleCode, role = 'r' } = props;
  const token = Auth.getDecodedToken();
  const modules = token?.cordel3_modules?.split(';') ?? [];
  // debugger;

  const isSuperUser = modules.includes('101000='); // if user has 101000 modulecode, no matter what role is, the user is a superuser
  if (isSuperUser) return true;
  if (modules.includes(`${moduleCode}=${role}`)) {
    return true;
  } else if (modules.includes(`${moduleCode}=a`)) {
    return true;
  } else if (role === 'r' && modules.includes(`${moduleCode}=w`)) {
    return true;
  }
  return false;
};

export const doesUserHaveAccess = (route: string) => {
  const routeObj = {
    offers: OFFERS_CODE,
    offer_settings: OFFERS_CODE,
    projects: PROJECTS_CODE,
    momtemplates: FDV_CODE,
    adjustments: OFFERS_CODE,
    companies: CRM_CODE,
    customers: CRM_CODE,
    suppliers: CRM_CODE,
    persons: CRM_CODE,
    vendors: CRM_CODE,
    packages: PACKAGES_CODE,
    products: PRODUCTS_CODE,
    nscodes: NSCODES_CODE,
    building_elements: BUILDINGELEMENTS_CODE,
    'v1/stdTexts': TEXTS_CODE,
    'v1/sites': SITES_CODE,
    'v1/checklistTemplates': CHECKLISTS_CODE,
    'v1/piecework': PIECEWORK_CODE,
  };
  const moduleCode = routeObj[route];
  if (!moduleCode) {
    return false;
  }
  if (canUserView({ moduleCode, role: 'r' })) {
    return true;
  }

  return false;
};

export const useIsChainAdmin = () => {
  const token = Auth.getDecodedToken();
  const modules = useMemo(() => token?.cordel3_modules?.split(';') ?? [], [token]);
  const listOfAdminChainCodes = getListOfAdminChainCodes(modules);
  return listOfAdminChainCodes.length > 0;
};

export const useIsModuleAdmin = () => {
  const token = Auth.getDecodedToken();
  const modules = useMemo(() => token?.cordel3_modules?.split(';') ?? [], [token]);

  // Get all modules bewteen the range of 100001 and 190000 to get admin-modules
  const listOfModuleAdminCodes = useMemo(
    () =>
      modules.filter((mod: any) => {
        const module = mod?.split('=');
        const moduleCode = module?.[0] ?? 0;
        return Number(moduleCode) >= 100001 && Number(moduleCode) < 190000;
      }),
    [modules]
  );

  // Check for admin codes
  const isModuleAdmin = listOfModuleAdminCodes.length > 0;

  return isModuleAdmin;
};

export const useIsServiceAdmin = (props: IProps) => {
  const { moduleCode } = props;

  const token = Auth.getDecodedToken();
  const modules = useMemo(() => token?.cordel3_modules?.split(';') ?? [], [token]);

  // Get all modules bewteen the range of 100001 and 190000 to only get service modules
  const listOfServiceAdminCodes = useMemo(
    () =>
      modules.filter((mod: any) => {
        const module = mod?.split('=');
        const moduleCode = module?.[0] ?? 0;
        return Number(moduleCode) >= 100001 && Number(moduleCode) < 190000;
      }),
    [modules]
  );

  // Create a regex to search for
  const pattern = new RegExp(`${moduleCode}`);
  // Check if the moduleCode can be found within the list of admin codes
  const isServiceAdmin = listOfServiceAdminCodes.some((str) => pattern.test(str));

  return isServiceAdmin;
};

export const useIsAdminForAllChains = (arrayOfModules: Array<number>, moduleCode: number): boolean => {
  const token = Auth.getDecodedToken();
  const modules = token?.cordel3_modules?.split(';') ?? [];
  const adminModuleCode = 100.0 + moduleCode;
  const isModuleAdmin = newAccessLevel({ moduleCode: adminModuleCode }) !== 'n';
  const isSuperUser = modules.includes(SUPER_DUPER_USER_CODE.toString());

  if (isSuperUser || isModuleAdmin) return true;

  // Create an array of numbers by removing the "="-sign and access letter, and convert the string to a number
  // then filter the list to only return administrator modules for chains
  const listOfAdminChainCodes = getListOfAdminChainCodes(modules);

  if (arrayOfModules.length === 0 || listOfAdminChainCodes.length === 0) return false;
  return arrayOfModules.every((code) => listOfAdminChainCodes.includes(100000 + code));
};

const getListOfAdminChainCodes = (modules: string[]) =>
  modules
    .map((str) => Number(str.split('=')?.[0]?.replace(/\D/g, '')))
    .filter((moduleCode: number) => moduleCode >= 190000 && moduleCode < 1000000) ?? [];

export const useIsSuperUser = () => {
  const token = Auth.getDecodedToken();
  const modules = useMemo(() => token?.cordel3_modules?.split(';') ?? [], [token]);
  const isSuperUser = modules.includes(SUPER_DUPER_USER_CODE.toString());
  return isSuperUser;
};

export const isUserAdminForModule = (moduleCode: number): boolean => {
  const token = Auth.getDecodedToken();
  const modules = token?.cordel3_modules?.split(';') ?? '';
  if (modules.includes(`${moduleCode}=a`)) {
    return true;
  }
  return false;
};

export const userCanWrite = (moduleCode: number, isCatalogPublic = false) => {
  const role = checkRoleByModule({ moduleCode });
  if (role === 'w' || role === 'a') return true;
  const isOrdinaryUser = role === 'r' || role === 'n';
  if (isCatalogPublic && isOrdinaryUser) return false;
  return true;
};

export const IsBDTenant = () => {
  const { cordel3_tenantId } = Auth.getDecodedToken();
  const varsSource = window['appValues'] ? window['appValues'] : process.env;
  const { REACT_APP_BDINTEGRATION_ID = '' } = varsSource ?? {};
  return cordel3_tenantId === REACT_APP_BDINTEGRATION_ID;
};

export const IsHywerTenant = () => {
  const { cordel3_tenantId } = Auth.getDecodedToken();
  const varsSource = window['appValues'] ? window['appValues'] : process.env;
  const { REACT_APP_HYWERINTEGRATION_ID = '' } = varsSource ?? {};
  return cordel3_tenantId === REACT_APP_HYWERINTEGRATION_ID;
};

export const IS_BD_O_HYWER_TENANT = () => IsBDTenant() || IsHywerTenant();

export default RoleWrapper;
