// @ts-nocheck
import { SyntheticEvent, KeyboardEvent } from 'react';
import { FormControl, Chip, Autocomplete, TextField, createFilterOptions } from '@mui/material';

const filter = createFilterOptions();

interface IMultiChipSelect {
  onChange: Function;
  onBlur: Function;
  value: Array<string>;
  name: string;
  label: string;
  placeholder: string;
  error?: boolean;
  options?: Array<string>;
  helperText?: string | any;
  fullWidth?: boolean;
  margin?: string;
  newItemLabel?: string;
}

export const MultiChipSelect = (props: IMultiChipSelect) => {
  const {
    fullWidth,
    margin,
    options = [],
    value = [],
    onChange,
    onBlur,
    name,
    label,
    placeholder,
    error,
    helperText,
    newItemLabel = 'Add new tag',
    ...rest
  } = props;

  const handleChange = (event: SyntheticEvent<Element, Event>, selectedValues: Array<string>) => {
    if (selectedValues.length) {
      const value = [...selectedValues];
      const lastItem = value.pop() as string;
      value.push(lastItem.replace(` - ${newItemLabel}`, ''));
      onChange({ target: { name, value } });
    } else {
      onChange({ target: { name, value: [] } });
    }
  };

  const onKeyDown = (e: SyntheticEvent<KeyboardEvent | Element>) => {
    if (e.key === 'Enter') {
      e.defaultMuiPrevented = true;
      e.stopPropagation();
      e.preventDefault();
      if (e.target.value.toString().trim()) {
        onChange({ target: { name, value: [...value, e.target.value] } });
      }
    }
  };

  const handleBlur = (e) => {
    if (e.type === 'blur') {
      onBlur({ target: { name, value: e.target.value } });
    }
  };

  /**
   * @description renders Chip component for each selected item from the options
   * @param {Array} items
   * @returns {Array} array of Chip components
   */
  const renderChipTags = (items = []) => {
    return items.map((option, index) => <Chip key={index} label={option} />);
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => {
      return inputValue === option;
    });
    if (inputValue.toString().trim() !== '' && !isExisting) {
      filtered.push(`${inputValue} - ${newItemLabel}`);
    }
    return filtered;
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <Autocomplete
        autoComplete
        disableCloseOnSelect
        openOnFocus
        multiple
        fullWidth
        id={`multi-chip-select-${label}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        renderTags={renderChipTags}
        filterOptions={filterOptions}
        onKeyDown={onKeyDown}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={Array.isArray(helperText) ? helperText[0] : helperText}
          />
        )}
        {...rest}
      />
    </FormControl>
  );
};

export default MultiChipSelect;
