import { FC } from 'react';

type MenuIconPermissionIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconPermissionIcon: FC<MenuIconPermissionIconPropTypes> = ({
  width = 42,
  height = 42,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4354_22738)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        d="M19.25 19.4248L20.5082 18.4702L21.7611 19.4248L21.2838 17.8791L22.542 16.8811H21.0018L20.5082 15.3571L20.0147 16.8811H18.4744L19.7272 17.8791L19.25 19.4248ZM24.847 17.4235C24.847 15.0263 22.9054 13.0847 20.5082 13.0847C18.111 13.0847 16.1694 15.0263 16.1694 17.4235C16.1694 18.5245 16.5816 19.5224 17.2541 20.2871V24.474L20.5082 23.3893L23.7623 24.474V20.2871C24.4348 19.5224 24.847 18.5245 24.847 17.4235ZM20.5082 14.1694C22.3034 14.1694 23.7623 15.6283 23.7623 17.4235C23.7623 19.2187 22.3034 20.6776 20.5082 20.6776C18.7131 20.6776 17.2541 19.2187 17.2541 17.4235C17.2541 15.6283 18.7131 14.1694 20.5082 14.1694Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_4354_22738"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4354_22738" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4354_22738" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconPermissionIcon;
