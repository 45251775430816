import { FC } from 'react';

type AddFilesIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const AddFilesIcon: FC<AddFilesIconPropTypes> = ({ width = 263, height = 206, color = '#0975A5', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 263 206" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M64.5304 200.588H198.214C201.889 200.597 205.431 199.209 208.13 196.701C219.392 186.212 228.401 173.515 234.604 159.393C240.807 145.271 244.073 130.021 244.2 114.582C244.777 51.8035 194.051 0.148479 131.646 0.000323122C69.2063 0.147917 18.543 50.7294 18.543 113.511C18.5252 129.137 21.7234 144.597 27.9363 158.919C34.1491 173.24 43.2421 186.112 54.6426 196.724C57.3365 199.22 60.8681 200.6 64.5304 200.588Z"
        fill="#EFF1F8"
      />
      <path
        d="M184.067 202.239L111.393 151.351C110.51 150.733 109.757 149.947 109.178 149.038C108.599 148.129 108.204 147.115 108.017 146.053C107.83 144.992 107.854 143.904 108.087 142.851C108.32 141.799 108.759 140.803 109.377 139.92L190.598 23.9246C191.216 23.0417 192.002 22.2892 192.911 21.71C193.82 21.1309 194.835 20.7364 195.896 20.5493C196.958 20.3621 198.046 20.3858 199.098 20.6191C200.15 20.8524 201.146 21.2907 202.029 21.909L251.896 56.826L260.749 107.036L195.499 200.223C194.881 201.106 194.094 201.859 193.185 202.438C192.276 203.017 191.262 203.411 190.201 203.598C189.139 203.786 188.051 203.762 186.999 203.529C185.946 203.295 184.95 202.857 184.067 202.239Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="3.13419"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.816 170.507L61.4467 200.851C60.4339 201.219 59.3583 201.385 58.2814 201.338C57.2046 201.291 56.1475 201.032 55.1706 200.577C54.1937 200.121 53.3161 199.478 52.5879 198.683C51.8597 197.888 51.2952 196.958 50.9265 195.945L2.49501 62.8806C2.12635 61.8677 1.9608 60.7921 2.00781 59.7153C2.05483 58.6384 2.31349 57.5814 2.76902 56.6045C3.22455 55.6276 3.86804 54.75 4.66273 54.0218C5.45743 53.2935 6.38778 52.729 7.40066 52.3603L64.6053 31.5396L110.813 53.0866L149.721 159.986C150.09 160.999 150.256 162.075 150.209 163.152C150.162 164.229 149.903 165.286 149.447 166.263C148.992 167.239 148.348 168.117 147.554 168.845C146.759 169.573 145.829 170.138 144.816 170.507Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="3.13419"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.811 53.0866L84.6468 62.6097C83.634 62.9783 82.5584 63.1439 81.4815 63.0968C80.4047 63.0498 79.3476 62.7912 78.3707 62.3356C77.3938 61.8801 76.5162 61.2366 75.788 60.4419C75.0598 59.6472 74.4953 58.7169 74.1266 57.704L64.6035 31.5396L110.811 53.0866Z"
        fill={color}
      />
      <path
        d="M57.8039 63.2583L25.5256 75.0067C23.8902 75.6019 23.047 77.4102 23.6422 79.0456L23.6422 79.0456C24.2375 80.6811 26.0458 81.5243 27.6812 80.929L59.9595 69.1807C61.5949 68.5854 62.4381 66.7771 61.8429 65.1417C61.2476 63.5063 59.4394 62.6631 57.8039 63.2583Z"
        fill="#D4D9E3"
      />
      <path
        d="M98.7816 73.4764L33.6018 97.1999C31.9664 97.7952 31.1232 99.6035 31.7184 101.239L31.7184 101.239C32.3136 102.874 34.1219 103.718 35.7574 103.122L100.937 79.3987C102.573 78.8035 103.416 76.9952 102.821 75.3598C102.225 73.7244 100.417 72.8812 98.7816 73.4764Z"
        fill="#D4D9E3"
      />
      <path
        d="M106.86 95.6698L41.6799 119.393C40.0445 119.989 39.2013 121.797 39.7965 123.432L39.7965 123.432C40.3918 125.068 42.2001 125.911 43.8355 125.316L109.015 101.592C110.651 100.997 111.494 99.1886 110.899 97.5531C110.303 95.9177 108.495 95.0745 106.86 95.6698Z"
        fill="#D4D9E3"
      />
      <path
        d="M114.938 117.863L49.758 141.586C48.1226 142.182 47.2794 143.99 47.8747 145.625L47.8747 145.625C48.4699 147.261 50.2782 148.104 51.9136 147.509L117.093 123.785C118.729 123.19 119.572 121.382 118.977 119.746C118.382 118.111 116.573 117.268 114.938 117.863Z"
        fill="#D4D9E3"
      />
      <path
        d="M106.314 146.135L57.8342 163.78C56.1988 164.375 55.3556 166.183 55.9508 167.819C56.5461 169.454 58.3544 170.297 59.9898 169.702L108.469 152.057C110.104 151.462 110.948 149.653 110.352 148.018C109.757 146.383 107.949 145.539 106.314 146.135Z"
        fill="#D4D9E3"
      />
      <path
        d="M260.746 107.036L237.938 91.0656C237.055 90.4474 236.302 89.6613 235.723 88.7522C235.144 87.8431 234.749 86.8289 234.562 85.7674C234.375 84.7059 234.399 83.6179 234.632 82.5655C234.865 81.5132 235.304 80.5171 235.922 79.6342L251.892 56.826L260.746 107.036Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.23 104.998C228.519 104.584 228.706 104.108 228.776 103.608C228.845 103.107 228.794 102.598 228.628 102.121C228.461 101.645 228.184 101.214 227.818 100.866C227.453 100.517 227.01 100.261 226.526 100.117L191.997 89.8804C191.109 89.6171 190.161 89.6397 189.286 89.9449C188.412 90.2502 187.656 90.8226 187.125 91.5812L167.393 119.761C165.515 118.459 163.056 117.562 160.314 117.322C153.962 116.766 148.494 119.948 148.102 124.428C147.71 128.909 152.542 132.991 158.895 133.547C165.049 134.086 170.372 131.116 171.055 126.857L182.582 110.394L210.113 118.554L198.34 135.367C196.535 134.243 194.267 133.475 191.761 133.256C185.409 132.7 179.942 135.882 179.55 140.363C179.158 144.843 183.989 148.926 190.342 149.482C196.694 150.037 202.162 146.856 202.554 142.375C202.575 142.132 202.581 141.89 202.572 141.65L228.23 104.998ZM186.858 104.288L191.82 97.2012L219.351 105.361L214.389 112.447L186.858 104.288Z"
        fill="#D4D9E3"
      />
      <path
        d="M175.732 170.791H87.0126C84.8357 170.791 82.748 169.926 81.2087 168.387C79.6695 166.848 78.8047 164.76 78.8047 162.583V20.979C78.8047 18.8022 79.6695 16.7144 81.2087 15.1752C82.748 13.6359 84.8357 12.7711 87.0126 12.7711H147.888L183.94 48.8227V162.583C183.94 163.661 183.728 164.728 183.315 165.724C182.903 166.72 182.298 167.625 181.536 168.387C180.774 169.149 179.869 169.754 178.873 170.166C177.877 170.579 176.81 170.791 175.732 170.791Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="3.13419"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.372 206C145.797 206 157.49 194.306 157.49 179.882C157.49 165.457 145.797 153.763 131.372 153.763C116.947 153.763 105.254 165.457 105.254 179.882C105.254 194.306 116.947 206 131.372 206Z"
        fill={color}
      />
      <path
        d="M141.493 177.391H133.865V169.763C133.865 169.103 133.602 168.471 133.135 168.005C132.668 167.538 132.035 167.276 131.375 167.276C130.715 167.276 130.082 167.538 129.615 168.005C129.148 168.471 128.885 169.103 128.884 169.763V177.391H121.363C120.731 177.381 120.117 177.604 119.64 178.019C119.163 178.433 118.856 179.01 118.778 179.637C118.744 179.983 118.782 180.332 118.891 180.663C119 180.993 119.177 181.296 119.411 181.554C119.644 181.812 119.929 182.018 120.247 182.158C120.565 182.299 120.909 182.372 121.256 182.372H128.884V189.893C128.874 190.526 129.097 191.139 129.512 191.617C129.927 192.094 130.503 192.401 131.13 192.479C131.476 192.513 131.826 192.474 132.156 192.365C132.486 192.256 132.79 192.079 133.047 191.846C133.305 191.612 133.511 191.328 133.652 191.01C133.793 190.692 133.865 190.348 133.865 190V182.372H141.493C142.154 182.372 142.787 182.11 143.254 181.643C143.722 181.176 143.984 180.542 143.984 179.882C143.984 179.221 143.722 178.588 143.254 178.121C142.787 177.654 142.154 177.391 141.493 177.391V177.391Z"
        fill="white"
      />
      <path
        d="M183.94 48.8227H156.097C153.92 48.8227 151.832 47.9579 150.293 46.4186C148.753 44.8793 147.889 42.7916 147.889 40.6147V12.7711L183.94 48.8227Z"
        fill={color}
      />
      <path
        d="M158.378 70.7168H104.367C103.155 70.7168 102.172 71.6997 102.172 72.9122V126.923C102.172 128.135 103.155 129.118 104.367 129.118H158.378C159.59 129.118 160.573 128.135 160.573 126.923V72.9122C160.573 71.6997 159.59 70.7168 158.378 70.7168Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="3.13419"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.379 129.118C158.961 129.118 159.52 128.887 159.931 128.475C160.343 128.063 160.574 127.505 160.574 126.923V110.531L148.391 98.3469C147.638 97.5946 146.618 97.172 145.554 97.172C144.49 97.172 143.47 97.5946 142.718 98.3469L127.103 113.962L121.567 110.483L119.092 129.118H158.379Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="3.13419"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.367 129.118H158.378C158.768 129.116 159.151 129.009 159.486 128.808L123.46 92.7812C122.707 92.0289 121.687 91.6063 120.623 91.6063C119.559 91.6063 118.539 92.0289 117.787 92.7812L102.172 108.396V126.923C102.172 127.505 102.403 128.063 102.815 128.475C103.227 128.887 103.785 129.118 104.367 129.118Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="3.13419"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.497 93.3825C147.383 93.3825 151.344 89.4217 151.344 84.5359C151.344 79.65 147.383 75.6892 142.497 75.6892C137.611 75.6892 133.65 79.65 133.65 84.5359C133.65 89.4217 137.611 93.3825 142.497 93.3825Z"
        fill="#D4D9E3"
      />
    </svg>
  );
};

export default AddFilesIcon;
