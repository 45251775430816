import { FC } from 'react';

type GraphPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const GraphSimple: FC<GraphPropTypes> = ({ width, height, color = '#0091D2', ...rest }) => {
  return (
    <svg width={width || 62} height={height || 32} viewBox="0 0 62 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.78906 29.7041C1.78906 29.7041 7.93491 31.4949 14.0806 29.7041C28.4034 25.5305 23.7891 -1 34.7891 5.99999C47.9675 14.3863 58.4453 13.9388 60.2891 2"
        stroke="url(#paint0_linear_2288_15214)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2288_15214"
          x1="-35.2109"
          y1="38"
          x2="48.3488"
          y2="-12.866"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor="#046B99" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GraphSimple;
