import React from 'react';
import Card from './components/Card';
import * as utils from './utils/initialCards';
import './assets/app.scss';

export default function Game() {
  const [cards, setCards] = React.useState<any>(utils.initialCards());
  const [lastCard, setLastCard] = React.useState<any>(null);
  const [locked, setLocked] = React.useState<any>(false);
  const [matches, setMatches] = React.useState<any>(0);
  const [clickCount, setClickCount] = React.useState<any>(0);

  const timer = useTimer();

  const handleClickCount = () => {
    if (matches !== cards.length / 2) {
      setClickCount(clickCount + 1);
      if (clickCount === 0) {
        timer.start();
      }
    } else {
      // If the user has matched all the cards, stop the timer
      timer.toggle();
    }
  };

  React.useEffect(() => {
    if (matches === cards.length / 2) {
      // If the user has matched all the cards, stop the timer
      timer.toggle();
    }
  }, [matches]);

  const checkMatch = (value: string, id: any) => {
    if (locked) {
      return;
    }

    const newCards = [...cards];
    newCards[id].flipped = true;
    setCards(newCards);
    setLocked(true);

    if (lastCard) {
      if (value === lastCard.value) {
        const newMatches = matches;

        newCards[id].matched = true;
        newCards[lastCard.id].matched = true;
        setCards(newCards);
        setLastCard(null);
        setLocked(false);
        setMatches(newMatches + 1);
      } else {
        setTimeout(() => {
          newCards[id].flipped = false;
          newCards[lastCard.id].flipped = false;
          setCards(newCards);
          setLastCard(null);
          setLocked(false);
        }, 800);
      }
    } else {
      setLastCard({ id, value });
      setLocked(false);
    }
  };

  const renderCards = (cardItems: any) => {
    if (cardItems !== undefined && cardItems.length > 0) {
      return cardItems.map((card: any, index: number) => {
        return (
          <Card
            key={index}
            value={card.cid}
            id={index}
            image={card.value}
            matched={card.matched}
            flipped={card.flipped}
            checkMatch={checkMatch}
            bg={card.bg}
            handleClickCount={handleClickCount}
          />
        );
      });
    }
  };

  const reset = () => {
    const initCards = utils.initialCards();
    setCards(initCards);
    setLastCard(null);
    setLocked(false);
    setMatches(0);
    setClickCount(0);
    timer.clear();
  };

  const fields = () => {
    let btnText = 'Nullstill';
    let btnMainColor = '#0975A5';
    let btnShadowColor = btnMainColor + '99';
    if (cards !== undefined && matches === cards.length / 2) {
      btnText = 'Play again!';
      btnMainColor = '#0975A5';
      btnShadowColor = btnMainColor + '99';
    } else if (matches !== undefined) {
      btnText = 'Restart';
    }

    return (
      <div className="game-wrapper">
        <div className="game-header App-toprow">
          <div className="score" style={{ display: 'flex', justifyContent: 'space-evenly', fontSize: 'large' }}>
            <p>
              <strong>Score:</strong> {clickCount}{' '}
            </p>
            <p>
              <strong>Time:</strong> {timer.minutes}:{timer.seconds}
            </p>
          </div>
        </div>
        <div className="game">{renderCards(cards)}</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {matches !== undefined && matches >= 1 && (
            <button
              onClick={reset}
              value={btnText}
              style={{
                textAlign: 'center',
                fontSize: '24px',
                fontFamily: "'Raleway', sans-serif",
                color: 'black',
                width: '150px',
                height: '50px',
                margin: '35px',
                borderRadius: '30px',
                boxShadow: `0px 0px 0px 15px ${btnMainColor}, 0px 10px 0px 15px ${btnShadowColor}, 0px 20px 20px 15px #0003`,
                cursor: 'pointer',
              }}>
              {btnText}
            </button>
          )}
        </div>
      </div>
    );
  };

  return <>{fields()}</>;
}

export const useTimer = () => {
  const [seconds, setSeconds] = React.useState<string | number>('00');
  const [minutes, setMinutes] = React.useState<string | number>('00');
  const [isActive, setIsActive] = React.useState(false);
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
        const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

        setSeconds(computedSecond);
        setMinutes(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const toggle = () => setIsActive(!isActive);
  const start = () => setIsActive(true);

  const clear = () => {
    setIsActive(false);
    setCounter(0);
    setSeconds('00');
    setMinutes('00');
  };

  return { seconds, minutes, toggle, start, clear };
};
