import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { FC } from 'react';

import { ConnectionContainer } from './styled';

import { customTagColors } from 'utils/helpers';

type ConectionItemProps = {
  conection: string;
};

const ConectionItem: FC<ConectionItemProps> = ({ conection }) => {
  const theme = useTheme();
  return (
    <ConnectionContainer sx={{ backgroundColor: customTagColors(conection, theme).bgColor }}>
      <Typography variant="caption2" color={customTagColors(conection, theme).color}>
        {conection}
      </Typography>
    </ConnectionContainer>
  );
};

export default ConectionItem;
