import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import { Button, Grid, Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useEffect, useState, useCallback, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DynamicFilterWrapper, FilterButtonAcceptWrapper, FilterButtonResetWrapper } from './DynamicFilter.styled';
import FilterDatePickers from './FilterDatePickers';
import { dateOptionsProps, totalsProps } from './FilterOptions';
import FilterTags from './FilterTags';
import FilterTotals from './FilterTotals';
import { useAddOfferFilterToUserConfig, useGetOfferFilterFromUserConfig } from 'query-services';
import { useRouteUtils } from 'routeUtils';

import { IconItem } from 'components/Icons';
import NewAutoComplete from 'components/NewAutoComplete/NewAutoComplete';
import { FilterWrapper } from 'containers/Page/ListPage/index.styled';

import { ICustomerState, ICustomer } from 'modules/Customers/CustomersInterfaces';
import { searchForCustomers } from 'modules/Customers/redux/CustomersActions';
import { AutoSelectWrapper } from 'modules/Offers/offerStyles';
import {
  IOfferFilterParameters,
  IOffersState,
  IOption,
  OfferAmountFilter,
  OfferFilters,
  OfferStatus,
} from 'modules/Offers/offersInterfaces';
import { getOfferResponsiblePerson, loadAllCategories, searchForUsers } from 'modules/Offers/redux/offersActions';
import { IUserState } from 'modules/User/userInterfaces';
import { DEFAULT_OFFER_FILTER } from 'utils/UserConfigurationKeys';
import { correctTimeZoneOffset, hasObjectAtLeastOneTruthyProperty, convertFiltersBack } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';
import useDefaultFilters from 'utils/hooks/useDefaultFilter';

type IProps = {
  fetchTags?: any;
  filterItems: any;
  saveChosenFilters?: (args: OfferFilters | null) => void;
  handleOpenClose: Function;
  resetFilter?: boolean;
  setResetFilter?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type IFilterTag = {
  tagName: string;
  isSelected: boolean;
};

const dateHelper = (status: OfferStatus, dateFrom?: string | Date, dateTo?: string | Date) => {
  switch (status) {
    case 'SentToCustomer':
      return {
        fromSentToCustomer: dateFrom || null,
        toSentToCustomer: dateTo || null,
      };
    case 'PlannedFollowUpDate':
      return {
        fromPlannedFollowUpDate: dateFrom || null,
        toPlannedFollowUpDate: dateTo || null,
      };
    case 'AcceptedByCustomer':
      return {
        fromAcceptedByCustomer: dateFrom || null,
        toAcceptedByCustomer: dateTo || null,
      };
    case 'RejectedByCustomer':
      return {
        fromRejectedByCustomer: dateFrom || null,
        toRejectedByCustomer: dateTo || null,
      };
    default:
      return null;
  }
};

const filterHelper = (amountFilter: OfferAmountFilter, amountFrom: string | number, amountTo: string | number) => {
  switch (amountFilter) {
    case 'CustomerAmount':
      return {
        fromCustomerAmount: amountFrom || null,
        toCustomerAmount: amountTo || null,
      };
    case 'Contribution':
      return {
        fromContribution: amountFrom || null,
        toContribution: amountTo || null,
      };
    default:
      return null;
  }
};

const zeroValues: IOfferFilterParameters = {
  categories: '',
  dateFilter: 'None',
  dateFrom: null,
  dateTo: null,
  tags: [],
  statuses: '',
  amountFilter: 'None',
  amountFrom: '',
  amountTo: '',
  responsibleUserId: '',
  customerId: '',
};

const DynamicFilter = (props: IProps) => {
  const { fetchTags, saveChosenFilters, handleOpenClose, resetFilter } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { defaultFilter } = useDefaultFilters({ filterKey: DEFAULT_OFFER_FILTER });
  const { responsibleUserId, forename, surname } = queryString.parse(window.location.search);
  const { mutate } = useAddOfferFilterToUserConfig();

  const { refetch }: UseQueryResult<AxiosResponse<{ filters: any }>> = useGetOfferFilterFromUserConfig();

  const { myInfo }: IUserState = useAppSelector(({ userModule }) => userModule);
  const { tagFilters, categories, chosenFilters, usersList, offerResponsiblePerson }: IOffersState = useAppSelector(
    ({ offersModule }) => offersModule
  );

  const { hits: searchedCustomers }: ICustomerState = useAppSelector(({ customersModule }) => customersModule);

  const initialValues = useMemo(() => {
    if (responsibleUserId) {
      const id: string = responsibleUserId as string;
      const filterSettings = {
        responsibleUserId: id,
      };
      return convertFiltersBack(filterSettings);
    }
    if (defaultFilter && !responsibleUserId) return convertFiltersBack(defaultFilter);
    return hasObjectAtLeastOneTruthyProperty(chosenFilters) ? convertFiltersBack(chosenFilters || {}) : zeroValues;
  }, [responsibleUserId, defaultFilter, chosenFilters]);

  const { setQueryParam } = useRouteUtils();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mappedTags, setMappedTags] = useState<Array<IFilterTag>>([]);
  const [mappedCategories, setMappedCategories] = useState<Array<IOption>>([]);
  const [searchValue, setSearchValue] = useState({
    customerId: '',
    responsibleUserId: '',
  });

  const caseWorkerValue = useDebounce<string>(searchValue.responsibleUserId, 500);
  const customerValue = useDebounce<string>(searchValue.customerId, 500);

  const handleFilterClose = () => handleOpenClose();
  const { values, handleChange, setFieldValue, errors, touched, submitForm, handleBlur } = useFormik({
    enableReinitialize: true,
    initialValues,
    onReset: () => {
      refetch();
    },
    onSubmit: async (values: IOfferFilterParameters) => {
      let tags: Array<string> = [];
      setQueryParam({ pageNumber: 0 });
      if (values.tags && typeof values.tags !== 'string')
        for (let i = 0; i < values.tags?.length; i++) {
          tags.push(values?.tags?.[i]?.value!);
        }
      const offerFilters: OfferFilters = {
        categoryId: values.categories || null,
        customerId: values.customerId || null,
        ...dateHelper(
          values?.dateFilter || 'None',
          correctTimeZoneOffset(values.dateFrom!),
          correctTimeZoneOffset(values.dateTo!)
        ),
        ...filterHelper(values.amountFilter!, values.amountFrom!, values.amountTo!),
        responsibleUserId: values.responsibleUserId || null,
        tags,
      };

      await mutate(offerFilters);
      dispatch(saveChosenFilters?.(offerFilters));
    },
  });

  const handleResetValues = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    // newSearchParams.set('pageNumber', '0');
    setSearchParams(newSearchParams);
    dispatch(saveChosenFilters?.(null));
    mutate(null);
    setFieldValue('dateFrom', null);
    setFieldValue('dateTo', null);
    setFieldValue('amountFrom', '');
    setFieldValue('amountTo', '');
    setFieldValue('tags', []);
    setFieldValue('categories', '');
    setFieldValue('dateFilter', 'None');
    setFieldValue('amountFilter', 'None');
    setFieldValue('responsibleUserId', '');
  }, [dispatch, mutate, saveChosenFilters, searchParams, setFieldValue, setSearchParams]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>, name: string): void => {
    if (!e?.target?.value) return;
    setSearchValue({ ...searchValue, [name]: e.target.value });
  };

  const caseWorkerOptions = useMemo(() => {
    return usersList || [];
  }, [usersList]);

  const responsibleUser = {
    forename: forename,
    surname: surname,
    id: responsibleUserId ? responsibleUserId : '',
  };

  const chosenFilterSetting = chosenFilters?.responsibleUserId
    ? chosenFilters.responsibleUserId
    : values.responsibleUserId;

  const newCustomerOptions = useMemo(() => {
    if (!searchedCustomers) return [];
    return searchedCustomers
      ?.map((item) => {
        if ('customerNumber' in item && 'name' in item && 'id' in item) {
          const customer = item as ICustomer;
          return {
            label: `${customer.externalReference || customer.customerNumber} - ${customer.name}`,
            value: customer.id,
          };
        }
        return null;
      })
      ?.filter(Boolean); // Filter out any null values
  }, [searchedCustomers]);

  useEffect(() => {
    if (!responsibleUserId) return;
    submitForm();
  }, [responsibleUserId]);

  useEffect(() => {
    if (fetchTags) dispatch(fetchTags());
    if (loadAllCategories) dispatch(loadAllCategories());
  }, [fetchTags, dispatch]);

  useEffect(() => {
    if (customerValue.length > 0) {
      dispatch(searchForCustomers(customerValue, true, { pageNumber: 0, pageSize: 100 }));
    }
    if (customerValue.length === 0) {
      dispatch(searchForCustomers(null, true, { pageNumber: 0, pageSize: 100 }));
    }
  }, [customerValue, dispatch]);

  useEffect(() => {
    if (caseWorkerValue.length > 0) dispatch(searchForUsers(caseWorkerValue));
    if (values.responsibleUserId) dispatch(getOfferResponsiblePerson(values.responsibleUserId));
    if (caseWorkerValue.length === 0)
      // added myInfo.forename because otherwise it overwrote the searched users in create offer dialog
      dispatch(searchForUsers(offerResponsiblePerson?.forename || myInfo?.forename || 'a'));
  }, [caseWorkerValue, dispatch, offerResponsiblePerson?.forename, myInfo?.forename, values.responsibleUserId]);

  useEffect(() => {
    if (!values.responsibleUserId) return;
    dispatch(getOfferResponsiblePerson(values.responsibleUserId));
  }, [dispatch, values.responsibleUserId]);

  useEffect(() => {
    if (tagFilters?.length === 0 || !tagFilters) return;

    let arr: Array<IFilterTag> = [];
    for (let i = 0; i < tagFilters.length; i++) {
      const tag: IFilterTag = {
        tagName: tagFilters?.[i]?.tag!,
        isSelected: false,
      };
      arr.push(tag);
    }
    setMappedTags(() => [...arr]);
  }, [tagFilters]);

  useEffect(() => {
    if (categories?.length === 0 || !categories) return;
    let arr: Array<IOption> = [];
    for (let i = 0; i < categories.length; i++) {
      const category: IOption = {
        label: categories?.[i]?.name || '',
        value: categories?.[i]?.id || '',
      };
      arr.push(category);
    }
    setMappedCategories(() => [...arr]);
  }, [categories]);

  useEffect(() => {
    handleResetValues();
  }, [handleResetValues, resetFilter]);

  return (
    <FilterWrapper>
      <Grid sx={{ height: '100%' }}>
        <Grid
          container
          pb={1}
          sx={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', position: 'sticky' }}>
          <Grid item pl={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Grid item>
              <IconItem name="Filter" />
            </Grid>
            <Grid item p={1}>
              <Typography fontWeight={600}>{t('globals.filters')}</Typography>
              <Typography variant="subtitle1" lineHeight={1}>
                {t('globals.filterDescription')}
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={handleFilterClose}>
                <CancelRoundedIcon sx={{ color: '#046B9938' }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DynamicFilterWrapper>
          <Typography sx={{ fontWeight: 600, pb: 1 }}>{t('globals.category')}</Typography>
          <Grid item>
            <NewAutoComplete
              fullWidth
              hasBorder={false}
              options={mappedCategories}
              onChange={handleChange}
              optionValue="value"
              optionLabel="label"
              name="categories"
              value={chosenFilters?.categoryId ? chosenFilters?.categoryId : values.categories ?? ''}
            />
          </Grid>
          <Grid item pt={2}>
            <FilterTags
              mode="tags"
              values={values}
              title={t('globals.tags')}
              filterTags={mappedTags}
              setFieldValue={setFieldValue}
            />
          </Grid>
          <Grid item pt={2}>
            <Typography fontWeight={600}>{t('offers.caseWorker')}</Typography>
            <AutoSelectWrapper>
              <NewAutoComplete
                fullWidth
                hasBorder={false}
                onBlur={handleBlur}
                options={responsibleUserId ? [responsibleUser] : caseWorkerOptions}
                optionKeyLabel={['forename', 'surname']}
                optionKeyValue="id"
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => handleNameChange(e, 'responsibleUserId')}
                name="responsibleUserId"
                error={touched.responsibleUserId && Boolean(errors.responsibleUserId)}
                value={responsibleUserId ? values.responsibleUserId : chosenFilterSetting}
                onChange={handleChange}
              />
            </AutoSelectWrapper>
          </Grid>
          <Grid item pt={2}>
            <Typography fontWeight={600}>{t('offers.customer')}</Typography>
            <AutoSelectWrapper>
              <NewAutoComplete
                fullWidth
                hasBorder={false}
                options={newCustomerOptions}
                optionKeyLabel="label"
                onBlur={handleBlur}
                optionKeyValue="value"
                onChange={handleChange}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => handleNameChange(e, 'customerId')}
                error={touched.customerId && Boolean(errors.customerId)}
                name="customerId"
                value={values.customerId || chosenFilters?.customerId}
              />
            </AutoSelectWrapper>
          </Grid>
          <Grid item pt={2}>
            <FilterDatePickers
              options={dateOptionsProps}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
            />
          </Grid>
          <Grid item pt={2}>
            <FilterTotals
              totalsOptions={totalsProps}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              touched={touched}
            />
          </Grid>
        </DynamicFilterWrapper>
        <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'} pt={2}>
          <Grid item xs={12} md={6} lg={6} pr={1} justifyContent={'end'}>
            <FilterButtonAcceptWrapper data-testid="applyButton" fullWidth onClick={submitForm}>
              {t('globals.accept')}
            </FilterButtonAcceptWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={6} pl={1} justifyContent={'end'}>
            <FilterButtonResetWrapper data-testid="resetButton" fullWidth onClick={handleResetValues}>
              {t('globals.reset')}
            </FilterButtonResetWrapper>
          </Grid>
        </Grid>
      </Grid>
    </FilterWrapper>
  );
};
export default DynamicFilter;
