import { IMomSidebarState } from '../interfaces/MomTreeInterfaces';
import {
  MOM_SIDEBAR_IS_LOADING,
  MOM_SIDEBAR_SELECT_ROW,
  MOM_SIDEBAR_SET_SELECTED_TEMPLATE_ID,
  MOM_SIDEBAR_SET_SELECTED_TEMPLATE_CATALOG_ID,
  MOM_SIDEBAR_LOAD_ALL_TEMPLATE_CATALOGS,
  MOM_SIDEBAR_GET_TEMPLATE_CONTENT_BY_ID,
  MOM_SIDEBAR_LOAD_TEMPLATES_BY_CATALOG_ID,
  MOM_SIDEBAR_SELECT_NODE,
  MOM_SIDEBAR_DESELECT_NODE,
  MOM_SIDEBAR_SET_SELECTED_NODES_CONTAINERS,
  MOM_SIDEBAR_SET_NODES_COUNT,
} from './MomSidebarActions';

const initialState: IMomSidebarState = {
  selectedRow: null,
  selectedTemplateId: null,
  selectedTemplateCatalogId: null,
  momTemplateCatalogs: [],
  selectedTemplate: [],
  allMomTemplates: [],
  selectedNodesIds: {},
  idsOfSelectedNodesContainers: {},
  numberOfNodes: 0,
  numberOfSelectedNodes: 0,
};

const MomTreeReducer = (state = initialState, { type, payload }: any) => {
  const newState = () => ({ ...state, ...payload });

  const stateActions = {
    [MOM_SIDEBAR_IS_LOADING]: newState,
    [MOM_SIDEBAR_SELECT_ROW]: newState,
    [MOM_SIDEBAR_SET_SELECTED_TEMPLATE_ID]: newState,
    [MOM_SIDEBAR_SET_SELECTED_TEMPLATE_CATALOG_ID]: newState,
    [MOM_SIDEBAR_LOAD_ALL_TEMPLATE_CATALOGS]: newState,
    [MOM_SIDEBAR_GET_TEMPLATE_CONTENT_BY_ID]: newState,
    [MOM_SIDEBAR_LOAD_TEMPLATES_BY_CATALOG_ID]: newState,
    [MOM_SIDEBAR_SELECT_NODE]: newState,
    [MOM_SIDEBAR_DESELECT_NODE]: newState,
    [MOM_SIDEBAR_SET_SELECTED_NODES_CONTAINERS]: newState,
    [MOM_SIDEBAR_SET_NODES_COUNT]: newState,
  };

  const currentState = stateActions[type];
  if (!currentState) return state;
  return currentState();
};

export default MomTreeReducer;
