import { AxiosResponse } from 'axios';
import i18next from 'i18next';

import {
  ADD_ERROR_MESSAGE_ACTION,
  LOAD_DOCUMENTS_ACTION,
  LOAD_FOLDERS_ACTION,
  SAVE_SEARCHED_FOLDERS_ACTION,
  SET_HAS_MORE_ITEMS_ACTION,
  SET_IS_LOADING_ACTION,
  SET_TOTAL_DOCUMENTS_ACTION,
} from './types';

import {
  ICopyFile,
  ICreateFolder,
  IDocument,
  IDocumentsResponse,
  IFolder,
  IFoldersResponse,
  IMoveFile,
} from './DocumentsInterface';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_TOTAL_DOCUMENTS = 'SET_TOTAL_DOCUMENTS';
export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS';
export const LOAD_FOLDERS = 'LOAD_FOLDERS';
export const SAVE_SEARCHED_FOLDERS = 'SAVE_SEARCHED_FOLDERS';

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const setHasMoreItems = (hasMoreItems: boolean): SET_HAS_MORE_ITEMS_ACTION => ({
  type: SET_HAS_MORE_ITEMS,
  hasMoreItems,
});

export const setIsLoading = (isLoading: boolean): SET_IS_LOADING_ACTION => ({
  type: SET_IS_LOADING,
  isLoading,
});

export const setTotalDocumentsConnected = (totalDocuments: number): SET_TOTAL_DOCUMENTS_ACTION => ({
  type: SET_TOTAL_DOCUMENTS,
  totalDocuments,
});

export const loadDocumentsSuccess = (documents: Array<IDocument>): LOAD_DOCUMENTS_ACTION => ({
  type: LOAD_DOCUMENTS,
  documents,
});

export const loadFoldersSuccess = (folders: Array<IFolder>): LOAD_FOLDERS_ACTION => ({
  type: LOAD_FOLDERS,
  folders,
});

export const saveSearchedFoldersSuccess = (searchedFolders: Array<IFolder>): SAVE_SEARCHED_FOLDERS_ACTION => ({
  type: SAVE_SEARCHED_FOLDERS,
  searchedFolders,
});

export const loadDocumentsConnectedCount = (externalObjectId: string) => {
  return async (dispatch: Function) => {
    try {
      const getCount = `/documents/api/documentdata/connectedTo/${externalObjectId}`;
      const getCountPromise = API.get(getCount);
      const {
        data: { payload },
      }: AxiosResponse<IDocumentsResponse> = await getCountPromise;
      dispatch(setTotalDocumentsConnected(payload.length));
    } catch {}
  };
};

export const loadDocumentsConnectedToObject = (externalObjectId: string) => {
  return async (dispatch: Function) => {
    try {
      const getDocuemnts = `/documents/api/documentData/connectedTo/${externalObjectId}`;
      const getDocumentsPromise = API.get(getDocuemnts);
      const {
        data: { payload: documents },
      }: AxiosResponse<IDocumentsResponse> = await getDocumentsPromise;
      dispatch(loadDocumentsSuccess(documents));
      if (documents.length > 0) dispatch(setTotalDocumentsConnected(documents.length));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    }
  };
};

export const loadFolders = (externalObjectId: string) => {
  return async (dispatch: Function) => {
    try {
      const getFolders = `/documents/api/documentFolders/externalObject/${externalObjectId}`;
      const getFoldersPromise = API.get(getFolders);
      const {
        data: { payload: folders },
      }: AxiosResponse<IFoldersResponse> = await getFoldersPromise;
      dispatch(loadFoldersSuccess(folders));
    } catch (error) {
      dispatch(loadFoldersSuccess([]));
      dispatch(addErrorMessageAction(error));
      try {
        await dispatch(createExternalObject(externalObjectId));
      } catch {
        dispatch(addErrorMessage({ message: i18next.t('documents.errorCreatingNewExternalObject') }));
        dispatch(loadFoldersSuccess([]));
      }
    } finally {
      dispatch(loadDocumentsConnectedCount(externalObjectId));
    }
  };
};

export const createFolder = (externalObjectId: string, folder: ICreateFolder) => {
  return async (dispatch: Function) => {
    try {
      const createFolder = `/documents/api/documentFolders/${externalObjectId}`;
      const createFolderPromise = API.post(createFolder, folder);
      const {
        data: { payload },
      }: AxiosResponse<IFoldersResponse> = await createFolderPromise;
      dispatch(loadFolders(externalObjectId));
      dispatch(addSuccessMessage({ message: i18next.t('documents.createdNewFolder') }));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorCreatingNewFolder') }));
    }
  };
};

export const deleteFolder = (externalObjectId: string, folderId: string) => {
  return async (dispatch: Function) => {
    try {
      const deleteFolder = `/documents/api/documentFolders/${folderId}`;
      const deleteFolderPromise = API.delete(deleteFolder);
      const {
        data: { payload },
      } = await deleteFolderPromise;
      dispatch(addSuccessMessage({ message: i18next.t('documents.deletedFolder') }));
      dispatch(loadFolders(externalObjectId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.folderContainsFiles') }));
    }
  };
};

export const editFolder = (externalObjectId: string, folderId: string, newName: string) => {
  return async (dispatch: Function) => {
    try {
      const editFolder = `/documents/api/documentFolders/${folderId}`;
      const editFolderPromise = API.put(editFolder, { name: newName });
      const {
        data: { payload },
      } = await editFolderPromise;
      dispatch(addSuccessMessage({ message: i18next.t('documents.editedFolder') }));
      dispatch(loadFolders(externalObjectId));
    } catch (e) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorEditingFolder') }));
    }
  };
};

export const copyFile = (externalObjectId: string, fileToCopy: ICopyFile) => {
  return async (dispatch: Function) => {
    try {
      const copyFile = `/documents/api/Documents/Copy`;
      const copyFilePromise = API.post(copyFile, fileToCopy);
      const {
        data: { payload },
      }: AxiosResponse<IDocumentsResponse> = await copyFilePromise;
      dispatch(addSuccessMessage({ message: i18next.t('documents.copiedFile') }));
      dispatch(loadFolders(externalObjectId));
      dispatch(loadDocumentsConnectedToObject(externalObjectId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorCopyingFile') }));
    }
  };
};

export const deleteFile = (externalObjectId: string, fileId: string) => {
  return async (dispatch: Function) => {
    try {
      const deleteFile = `/documents/api/documents/${fileId}`;
      const deleteFilePromise = API.delete(deleteFile);
      const {
        data: { payload },
      } = await deleteFilePromise;
      dispatch(addSuccessMessage({ message: i18next.t('documents.deletedFile') }));
      dispatch(loadFolders(externalObjectId));
      dispatch(loadDocumentsConnectedToObject(externalObjectId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorDeletingFile') }));
    }
  };
};

export const moveFile = (externalObjectId: string, linkId: string, folderItem: IMoveFile) => {
  return async (dispatch: Function) => {
    try {
      const url = `/documents/api/documentLinks/${linkId}`;
      await API.put(url, folderItem);
      dispatch(addSuccessMessage({ message: i18next.t('documents.successMovingFile') }));
      dispatch(loadFolders(externalObjectId));
    } catch {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorMovingFile') }));
    }
  };
};

export const addFileToFolder = (externalObjectId: string, folderId: string, fileToUpload: any) => {
  return async (dispatch: Function) => {
    try {
      const formData = new FormData();
      formData.append('file', fileToUpload);
      const url = `/documents/api/documents/Upload?externalObjectId=${externalObjectId}&folderId=${folderId}`;
      await API.post(url, formData);
      dispatch(addSuccessMessage({ message: i18next.t('documents.addedFile') }));
      dispatch(loadFolders(externalObjectId));
      dispatch(loadDocumentsConnectedToObject(externalObjectId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorAddingFile') }));
    }
  };
};

export const downloadFile = (file: any) => {
  return async (dispatch: Function) => {
    try {
      handleDownload(file);
    } catch {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorDownloadingFile') }));
    }
  };
};

export const handleDownload = (fileToDownload: any) => {
  if (fileToDownload !== undefined) {
    const element = document.createElement('a');
    element.href = fileToDownload.file;
    element.download = fileToDownload.fileName.toLocaleLowerCase();
    element.click();
  }
};

export const createExternalObject = (externalObjectId: string) => {
  if (!externalObjectId) return;
  return async (dispatch: Function) => {
    try {
      const getOfferUrl = `/offers/api/offers/${externalObjectId}`;
      const getOffer = API.get(getOfferUrl);
      const {
        data: { payload: offer },
      } = await getOffer;
      if (offer) {
        const newExternalObject = {
          id: externalObjectId,
          name: offer.name,
          description: offer.description,
          typeDescription: 'offer',
        };
        const url = `/documents/api/externalObjects`;
        await API.post(url, newExternalObject);
        dispatch(createFolder(externalObjectId, { name: 'Default', description: 'Default folder' }));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
