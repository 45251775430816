import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import globals from './globals.json';

import BuildingElements from 'modules/BuildingElements/buildingElementsTranslation.json';
import CRM from 'modules/CRM/crmTranslations.json';
import Adjustments from 'modules/CollectiveAdjustments/adjustmentsTranslation.json';
import CompaniesTranslations from 'modules/Companies/companiesTranslation.json';
import Customers from 'modules/Customers/CustomersTranslations.json';
import Dashboard from 'modules/Dashboard/dashboardTranslations.json';
import Login from 'modules/Login/loginTranslations.json';
import MOMTemplatesTranslations from 'modules/MOMTemplates/MOMTemplatesTranslations.json';
import NSCodes from 'modules/NSCodes/nsCodesTranslations.json';
import OfferSetting from 'modules/OfferSettings/OfferSettingsTranslations.json';
import Documents from 'modules/Offers/DocumentTab/documentsTranslation.json';
import OfferProperties from 'modules/Offers/offerProperties.json';
import Offers from 'modules/Offers/offersTranslations.json';
import Packages from 'modules/Packages/packagesTranslations.json';
import PersonsTranslations from 'modules/Persons/personsTranslations.json';
import Products from 'modules/Products/ProductsTranslations.json';
import ProjectsTranslations from 'modules/Projects/ProjectsTranslations.json';
import ResetPassword from 'modules/ResetPassword/ResetPassword.json';
import Suppliers from 'modules/Suppliers/SuppliersTranslations.json';
import UsersTranslations from 'modules/User/userTranslations.json';
import Vendors from 'modules/Vendors/vendorsTranslations.json';

const resources = {
  en: {
    translation: {
      adjustments: Adjustments.en,
      buildingElements: BuildingElements.en,
      companies: CompaniesTranslations.en,
      customers: Customers.en,
      dashboard: Dashboard.en,
      documents: Documents.en,
      globals: globals.en,
      login: Login.en,
      nsCodes: NSCodes.en,
      offers: Offers.en,
      offerSettings: OfferSetting.en,
      packages: Packages.en,
      persons: PersonsTranslations.en,
      products: Products.en,
      ResetPassword: ResetPassword.en,
      suppliers: Suppliers.en,
      user: UsersTranslations.en,
      vendors: Vendors.en,
      crm: CRM.en,
      offerProperties: OfferProperties.en,
      projects: ProjectsTranslations.en,
      momTemplates: MOMTemplatesTranslations.en,
    },
  },
  no: {
    translation: {
      adjustments: Adjustments.no,
      buildingElements: BuildingElements.no,
      companies: CompaniesTranslations.no,
      customers: Customers.no,
      dashboard: Dashboard.no,
      documents: Documents.no,
      globals: globals.no,
      login: Login.no,
      nsCodes: NSCodes.no,
      offers: Offers.no,
      offerSettings: OfferSetting.no,
      packages: Packages.no,
      persons: PersonsTranslations.no,
      products: Products.no,
      ResetPassword: ResetPassword.no,
      suppliers: Suppliers.no,
      user: UsersTranslations.no,
      vendors: Vendors.no,
      crm: CRM.no,
      offerProperties: OfferProperties.no,
      projects: ProjectsTranslations.no,
      momTemplates: MOMTemplatesTranslations.no,
    },
  },
  se: {
    translation: {
      adjustments: Adjustments.se,
      buildingElements: BuildingElements.se,
      companies: CompaniesTranslations.se,
      customers: Customers.se,
      dashboard: Dashboard.se,
      documents: Documents.se,
      globals: globals.se,
      login: Login.se,
      nsCodes: NSCodes.se,
      offers: Offers.se,
      offerSettings: OfferSetting.se,
      package: Packages.se,
      persons: PersonsTranslations.se,
      products: Products.se,
      ResetPassword: ResetPassword.se,
      suppliers: Suppliers.se,
      user: UsersTranslations.se,
      vendors: Vendors.se,
      crm: CRM.se,
      offerProperties: OfferProperties.se,
      projects: ProjectsTranslations.se,
      momTemplates: MOMTemplatesTranslations.se,
    },
  },
};
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'no',
    // lng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
