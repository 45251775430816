import { FC } from 'react';

type ArrowRightFilledPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ArrowRightFilled: FC<ArrowRightFilledPropTypes> = ({
  width = 15,
  height = 13,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 9.20841L9.375 6.50008L6.25 3.79175V9.20841Z" fill={color} />
    </svg>
  );
};

export default ArrowRightFilled;
