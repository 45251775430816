import i18n from 'i18next';

import { getMomSiteById, loadProjectMomSiteContents } from '../../../Projects/redux/ProjectMOMListActions';
import { ITemplate, ITemplateContent, ITemplateFile } from './templatesTypes';

import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import { ICreateMOMTemplate } from 'modules/MOMTemplates/MOMTemplateDetails/Dialogs/CreateNewMOMTemplateDialog';
import { IEditTemplateHeader } from 'modules/MOMTemplates/MOMTemplateDetails/Dialogs/EditHeaderDialog';
import { checkIfUpdatingStatus } from 'modules/MOMTemplates/Utils/helperFunctions';
import { selectMomTreeRow } from 'modules/Projects/redux/MomTreeActions';
import API from 'utils/API';

export const IS_TEMPLATE_LOADING = 'IS_TEMPLATE_LOADING';
export const LOAD_ALL_TEMPLATES = 'LOAD_ALL_TEMPLATES';
export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';
export const SAVE_ALL_TEMPLATES_COUNT = 'SAVE_ALL_TEMPLATES_COUNT';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_TEMPLATE_CONTENT = 'SAVE_TEMPLATE_CONTENT';
export const SAVE_TEMPLATE_COMPONENT_DATA = 'SAVE_TEMPLATE_COMPONENT_DATA';
export const SAVE_TEMPLATE_ATTACHMENTS = 'SAVE_TEMPLATE_ATTACHMENTS';

const setIsLoading = (payload: boolean) => ({ type: IS_TEMPLATE_LOADING, payload });

export const setHasMoreItems = (payload: boolean) => ({ type: SET_HAS_MORE_ITEMS, payload });

const loadTemplatesSuccess = (payload: ITemplate[], pageInfo: IPageInfo) => ({
  type: LOAD_ALL_TEMPLATES,
  payload: { items: payload, pageInfo },
});

const loadTemplatesCountSuccess = (payload: number) => ({
  type: SAVE_ALL_TEMPLATES_COUNT,
  payload,
});

const saveTemplateSuccess = (payload: ITemplate) => ({
  type: SAVE_TEMPLATE,
  payload,
});

const saveTemplateContentSuccess = (payload: ITemplateContent) => ({
  type: SAVE_TEMPLATE_CONTENT,
  payload,
});

export const saveTemplateComponentDataSuccess = (payload: any) => ({
  type: SAVE_TEMPLATE_COMPONENT_DATA,
  payload,
});

export const saveTemplateAttachmentsSuccess = (payload: ITemplateFile[]) => ({
  type: SAVE_TEMPLATE_ATTACHMENTS,
  payload,
});

const urlHelper = (mode: string) => {
  let url = ``;
  if (mode === 'template') url = `/fdv/api/templates`;
  if (mode === 'project') url = `/fdv/api/sites`;
  return url;
};

export const loadAllTemplates = (catalogId: string, pageSize?: number, pageNumber?: number, mode = 'template') => {
  return async (dispatch: Function) => {
    const size = pageSize || 10;
    const number = pageNumber || 0;
    const url = `/fdv/api/templates/catalog/${catalogId}?pageSize=${size}&pageNumber=${number}`;
    const countUrl = `/fdv/api/templates/catalog/${catalogId}/count`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload, pageInfo },
      } = await API.get(url);
      const {
        data: { payload: count },
      } = await API.get(countUrl);
      dispatch(loadTemplatesCountSuccess(count));
      if (payload.length === 0 || payload.length < Number(size)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(loadTemplatesSuccess(payload, pageInfo));
    } catch (e) {
      const message = i18n.t('momTemplates.errorLoadingTemplates');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchTemplates = (catalogId: string, searchValue: string, pageSize?: number, pageNumber?: number) => {
  return async (dispatch: Function) => {
    const body = {
      query: searchValue,
      pageNumber: pageNumber || 0,
      pageSize: pageSize || 10,
      fields: ['name'],
      catalogIds: [catalogId],
    };
    const url = `/search/api/fdv/templates/autoComplete`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { hits, pageInfo },
      } = await API.post(url, body);
      dispatch(loadTemplatesSuccess(hits, pageInfo));
    } catch (error) {
      const message = i18n.t('momTemplates.errorLoadingTemplates');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadTemplateById = (templateId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(saveTemplateSuccess(payload));
    } catch (e) {
      const message = i18n.t('momTemplates.errorLoadingTemplate');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const editTemplate = (templateId: string, body: IEditTemplateHeader, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}`;
    try {
      dispatch(setIsLoading(true));
      await API.put(url, body);
      dispatch(loadTemplateById(templateId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorEditingTemplate');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getTemplateContent = (templateId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/content`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      if (mode === 'project') return dispatch(loadProjectMomSiteContents(templateId));
      dispatch(saveTemplateContentSuccess(payload));
    } catch (e) {
      const message = i18n.t('momTemplates.errorLoadingContent');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getTemplateComponentData = (templateId: string, componentId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(saveTemplateComponentDataSuccess(payload));
    } catch (e) {
      // removed error message for now, it appeard too often
      // const message = i18n.t('momTemplates.errorLoadingComponents');
      // dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createNewTemplate = (body: ICreateMOMTemplate, mode = 'template', callback?: (id: string) => void) => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.post(url, body);
      !callback && dispatch(loadAllTemplates(body.templateCatalogId));
      callback?.(`/momtemplates/${payload?.id}`);
    } catch (e) {
      const message = i18n.t('momTemplates.errorCreatingTemplate');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteTemplate = (templateId: string, catalogId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const message = i18n.t('momTemplates.catalogIdMissing');
    if (!catalogId) {
      dispatch(addErrorMessage({ message }));
      return;
    }
    const url = `${urlHelper(mode)}/${templateId}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(loadAllTemplates(catalogId));
    } catch (e) {
      const message = i18n.t('momTemplates.errorDeletingTemplate');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const saveSiteAsTemplate = (targetSourceId: string, body: any) => {
  return async (dispatch: Function) => {
    const url = `/fdv/api/templates/${targetSourceId}/copyFromSite`;
    try {
      dispatch(setIsLoading(true));
      await API.post(url, body);
    } catch (e) {
      const message = i18n.t('momTemplates.errorCreatingTemplate');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createNewComponent = (templateId: string, body: any, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.post(url, body);
      dispatch(getTemplateContent(templateId, mode));
      dispatch(selectMomTreeRow(payload));
    } catch (e) {
      const message = i18n.t('momTemplates.errorCreatingComponent');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const editComponent = (templateId: string, componentId: string, body: any, mode = 'template') => {
  return async (dispatch: Function, getState: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.put(url, body);
      const prevRow = getState().momTreeModule.selectedRow;

      if (mode === 'project' && checkIfUpdatingStatus(body)) {
        dispatch(getMomSiteById(templateId));
      }
      if (prevRow.id === componentId) {
        dispatch(selectMomTreeRow({ ...prevRow, ...payload }));
      }
      dispatch(loadTemplateById(templateId, mode));
      dispatch(getTemplateContent(templateId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorEditingComponent');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteComponent = (templateId: string, componentId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      if (mode === 'project') {
        dispatch(getMomSiteById(templateId));
      }
      dispatch(getTemplateContent(templateId, mode));
      dispatch(selectMomTreeRow(null));
    } catch (e) {
      const message = i18n.t('momTemplates.errorDeletingComponent');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createNewOperation = (templateId: string, componentId: string, body: any, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/operation`;
    try {
      dispatch(setIsLoading(true));
      await API.post(url, body);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorCreatingOperation');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const editOperation = (
  templateId: string,
  componentId: string,
  operationId: string,
  body: any,
  mode = 'template'
) => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/operation/${operationId}`;
    try {
      dispatch(setIsLoading(true));
      await API.put(url, body);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorEditingOperation');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteOperation = (templateId: string, componentId: string, operationId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/operation/${operationId}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorDeletingOperation');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createNewMaintenance = (templateId: string, componentId: string, body: any, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/maintenance`;
    try {
      dispatch(setIsLoading(true));
      await API.post(url, body);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorCreatingMaintenance');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const editMaintenance = (
  templateId: string,
  componentId: string,
  maintenanceId: string,
  body: any,
  mode = 'template'
) => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/maintenance/${maintenanceId}`;
    try {
      dispatch(setIsLoading(true));
      await API.put(url, body);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorEditingMaintenance');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteMaintenance = (
  templateId: string,
  componentId: string,
  maintenanceId: string,
  mode = 'template'
) => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/maintenance/${maintenanceId}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorDeletingMaintenance');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createNewDangers = (templateId: string, componentId: string, body: any, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/dangers`;
    try {
      dispatch(setIsLoading(true));
      await API.post(url, body);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorCreatingDanger');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const editDangers = (
  templateId: string,
  componentId: string,
  dangerId: string,
  body: any,
  mode = 'template'
) => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/dangers/${dangerId}`;
    try {
      dispatch(setIsLoading(true));
      await API.put(url, body);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorEditingDanger');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteDangers = (templateId: string, componentId: string, dangerId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/components/${componentId}/dangers/${dangerId}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(getTemplateComponentData(templateId, componentId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorDeletingDanger');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateNodePosition = ({ itemId, itemType, index, parentId }: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const id = getState().momTemplatesModule.selectedItem.id;
      const type = itemType === 'component' ? 'components' : 'sections';
      const URL = `/fdv/api/templates/${id}/${type}/${itemId}`;
      await API.put(URL, { index, parentId: parentId ?? id });
      dispatch(getTemplateContent(id));
    } catch (e) {
      const message = i18n.t('products.errorUpdatingList');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addSection = (templateId: string, body: any, mode = 'template') => {
  return async (dispatch: Function, getState: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/sections`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.post(url, body);
      dispatch(selectMomTreeRow(payload));
      dispatch(getTemplateContent(templateId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorAddingSection');

      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const copyNodeFromTemplate = ({ sourceSectionId, targetIndex, targetParentId }: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const id = getState().momTemplatesModule.selectedItem.id;
      const sourceId = getState().momTreeModule.selectedTemplateId;
      const URL = `/fdv/api/templates/${id}/copyFromTemplate`;
      await API.post(URL, {
        sourceId,
        sections: [{ sourceSectionId, targetIndex, targetParentId: targetParentId ?? id }],
      });
      dispatch(getTemplateContent(id));
    } catch (e) {
      const message = i18n.t('products.errorAddingNewItem');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const editSection = (templateId: string, sectionId: string, body: any, mode = 'template') => {
  return async (dispatch: Function, getState: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/sections/${sectionId}`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.put(url, body);
      const prevRow = getState().momTreeModule.selectedRow;
      if (prevRow.id === sectionId) {
        dispatch(selectMomTreeRow({ ...prevRow, ...payload }));
      }

      if (mode === 'project' && checkIfUpdatingStatus(body)) {
        dispatch(getMomSiteById(templateId));
      }

      dispatch(loadTemplateById(templateId, mode));
      dispatch(getTemplateContent(templateId, mode));
    } catch (e) {
      const message = i18n.t('momTemplates.errorEditingSection');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteSection = (templateId: string, sectionId: string, mode = 'template') => {
  return async (dispatch: Function) => {
    const url = `${urlHelper(mode)}/${templateId}/sections/${sectionId}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      if (mode === 'project') {
        dispatch(getMomSiteById(templateId));
      }
      dispatch(loadTemplateById(templateId, mode));
      dispatch(getTemplateContent(templateId, mode));
      dispatch(selectMomTreeRow(null));
    } catch (e) {
      const message = i18n.t('momTemplates.errorDeletingSection');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const uploadAttachmentToTemplateItem = (
  siteId: string,
  sectionId: string,
  fileToUpload: any,
  mode = 'project'
) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const formData = new FormData();
      formData.append('file', fileToUpload);
      const url = `${urlHelper(mode)}/${siteId}/sections/${sectionId}/attachments/upload`;
      await API.post(url, formData);
      dispatch(getAttachments(siteId, sectionId, mode));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18n.t('documents.errorAddingFile') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getAttachments = (siteId: string, sectionId: string, mode = 'project') => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `${urlHelper(mode)}/${siteId}/sections/${sectionId}/attachments`;
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(saveTemplateAttachmentsSuccess(payload));
    } catch (e) {
      dispatch(addErrorMessage({ message: i18n.t('documents.errorLoadingFiles') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteAttachments = (siteId: string, sectionId: string, attachmentId: string, mode = 'project') => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `${urlHelper(mode)}/${siteId}/sections/${sectionId}/attachments/${attachmentId}`;
      await API.delete(url);
      dispatch(getAttachments(siteId, sectionId, mode));
    } catch (e) {
      dispatch(addErrorMessage({ message: i18n.t('documents.errorLoadingFiles') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addMultipleProducts = (siteId: string, componentId: string, body: any, mode = 'project') => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `${urlHelper(mode)}/${siteId}/components/${componentId}/products/multiple`;
      await API.post(url, body);
      setTimeout(() => {
        dispatch(getTemplateComponentData(siteId, componentId, mode));
      }, 800);
    } catch (e) {
      dispatch(addErrorMessage({ message: i18n.t('products.errorAddingNewItem') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteProduct = (siteId: string, componentId: string, productId: string, mode = 'project') => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `${urlHelper(mode)}/${siteId}/components/${componentId}/products/${productId}`;
      await API.delete(url);
      dispatch(getTemplateComponentData(siteId, componentId, mode));
    } catch (e) {
      dispatch(addErrorMessage({ message: i18n.t('products.errorDeletingItem') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
