export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SUCCESS_MESSAGE = 'success';
export const ERROR_MESSAGE = 'error';
export const INFO_MESSAGE = 'info';
export const WARNING_MESSAGE = 'warning';

const duration = 5000;

export interface IAppMessage {
  message: string;
  type?: string;
  duration?: number;
  id?: string | number;
  description?: string;
}

export const addMessage = ({ message, type }: IAppMessage) => {
  return (dispatch: Function) => {
    const payload = {
      type,
      message,
      duration,
      id: new Date().getTime(),
    };
    dispatch({
      type: ADD_MESSAGE,
      payload,
    });
    dispatch(removeMessage(payload));
  };
};

export const addSuccessMessage = (payload: IAppMessage) => {
  return addMessage({ ...payload, type: SUCCESS_MESSAGE });
};

export const addErrorMessage = (payload: IAppMessage) => {
  return addMessage({ ...payload, type: ERROR_MESSAGE });
};

export const addInfoMessage = (payload: IAppMessage) => {
  return addMessage({ ...payload, type: INFO_MESSAGE });
};

export const addWarningMessage = (payload: IAppMessage) => {
  return addMessage({ ...payload, type: WARNING_MESSAGE });
};

export const removeMessage = (payload: IAppMessage) => {
  const { duration = 0 } = payload;
  return (dispatch: Function) => {
    setTimeout(() => {
      dispatch({ type: REMOVE_MESSAGE, payload });
    }, duration);
  };
};
