import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import API from 'utils/API';
import store from 'redux/store';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { CRMResponse, CreateCustomer, CreateVendor, PersonResponse } from 'modules/CRM/CRMInterfaces';
import { ICustomerResponse } from 'modules/Customers/CustomersInterfaces';
import { CreateSiteResponse, SiteItem, SiteResponse } from 'modules/Offers/offersInterfaces';
import { IVendorItemResponse } from 'modules/Vendors/vendorsInterfaces';
import { IS_BD_O_HYWER_TENANT } from 'utils/authentication/RoleWrapper';

export type SearchArguments = {
  query: string | null;
  pageSize?: number | string;
  pageNumber?: number | string;
  fields?: string[];
  deleted?: boolean;
  orderBy?: string;
  isVendor?: boolean | null;
  isCustomer?: boolean | null;
  isSupplier?: boolean | null;
  isPerson?: boolean | null;
  descending?: boolean;
};

const searchCRMContacts = async (searchArguments: SearchArguments) => {
  const BDHywerFields = IS_BD_O_HYWER_TENANT() ? 'externalReference' : 'customerNumber';
  const fields = searchArguments.query
    ? ['postalCode', 'postalAddress', 'address', 'name', 'phoneNumber', BDHywerFields]
    : null;
  const response = await API.post('/search/api/contacts/autocomplete', { ...searchArguments, fields, deleted: false });
  const { data }: AxiosResponse<CRMResponse> = response;
  return data;
};

const searchPersons = async (searchArguments: SearchArguments) => {
  const response = await API.post('/search/api/persons/autocomplete', {
    ...searchArguments,
    query: searchArguments.query ? searchArguments.query : null,
    fields: searchArguments.query ? ['firstname', 'lastname', 'phoneNumber'] || null : null,
    pageNumber: 0,
    pageSize: 50,
  });
  const { data }: AxiosResponse<PersonResponse> = response;
  return data;
};

const searchCompanies = async (searchArguments: SearchArguments) => {
  const response = await API.post('/search/api/companies/autocomplete', searchArguments);
  const { data }: AxiosResponse<PersonResponse> = response;
  return data;
};

const getSites = async (): Promise<SiteItem[]> => {
  const {
    data: { payload },
  }: AxiosResponse<SiteResponse> = await API.get('sites/api/sites');
  return payload;
};

const createSite = async (site: SiteItem): Promise<SiteItem> => {
  const response = await API.post('sites/api/sites', site);
  const { data }: AxiosResponse<CreateSiteResponse> = response;
  return data.payload;
};

const createCustomer = async (customer: CreateCustomer) => {
  const response = await API.post('crm/api/customers', customer);
  const { data }: AxiosResponse<ICustomerResponse> = response;
  return data.payload;
};

const createVendor = async (vendor: CreateVendor) => {
  const response = await API.post('crm/api/vendors', vendor);
  const { data }: AxiosResponse<IVendorItemResponse> = response;
  return data.payload;
};

export const useSearchCRMContacts = (searchArguments: SearchArguments) =>
  useQuery({
    queryKey: [
      'crmContacts',
      searchArguments.query,
      searchArguments.pageNumber,
      searchArguments.pageSize,
      searchArguments.isPerson,
      searchArguments.isCustomer,
      searchArguments.isSupplier,
      searchArguments.isVendor,
    ],
    queryFn: () => searchCRMContacts(searchArguments),
  });

export const useSearchPersons = (searchArguments: SearchArguments) =>
  useQuery({
    queryKey: ['persons', searchArguments.query],
    queryFn: () => searchPersons(searchArguments),
  });

export const useSearchCompanies = (searchArguments: SearchArguments) =>
  useQuery({ queryKey: ['companies'], queryFn: () => searchCompanies(searchArguments) });

export const useSites = () => useQuery({ queryKey: ['sites'], queryFn: () => getSites() });

export const useCreateCustomer = (
  queryClient?: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(createCustomer, {
    onSuccess: () => {
      const message = i18n.t('customers.successCreatingNewCustomer');
      store.dispatch(addSuccessMessage({ message }));
      queryClient?.invalidateQueries(refetch || ['']);
      setTimeout(() => {
        onSuccess?.();
      }, 1000);
    },
    onError: (error: any) => {
      const failAddCustomerMessage = i18n.t('customers.failedToCreateNewCustomer');
      store.dispatch(addErrorMessage({ message: failAddCustomerMessage }));
      onError?.(error);
    },
  });

export const useCreateVendor = (
  queryClient?: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(createVendor, {
    onSuccess: () => {
      const message = i18n.t('vendors.successCreatingVendor');
      setTimeout(() => {
        store.dispatch(addSuccessMessage({ message }));
        queryClient?.invalidateQueries(refetch || ['']);
      }, 1000);
      setTimeout(() => {
        onSuccess?.();
      }, 1500);
    },
    onError: (error: any) => {
      const failAddVendorMessage = i18n.t('vendors.errorCreatingVendor');
      store.dispatch(addErrorMessage({ message: failAddVendorMessage }));
      onError?.(error);
    },
  });

export const useCreateSite = (queryClient: QueryClient, refetch?: string[], onSuccess?: Function, onError?: Function) =>
  useMutation(createSite, {
    onSuccess: () => {
      const message = i18n.t('crm.successCreateSite');
      setTimeout(() => {
        store.dispatch(addSuccessMessage({ message }));
        queryClient.invalidateQueries(refetch || ['']);
      }, 1000);
      setTimeout(() => {
        onSuccess?.();
      }, 1500);
    },
    onError: (error: any) => {
      const failAddPersonMessage = i18n.t('crm.failCreateSite');
      store.dispatch(addErrorMessage({ message: failAddPersonMessage }));
      onError?.(error);
    },
  });
