import { FC } from 'react';

type NSCodePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};



export const NSCode: FC<NSCodePropTypes> = ({ width = 48, height = 48, color = '#0673A3', background='#E6F0F5', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 11.2C0 5.01441 5.01441 0 11.2 0H36.8C42.9856 0 48 5.01441 48 11.2V36.8C48 42.9856 42.9856 48 36.8 48H11.2C5.01441 48 0 42.9856 0 36.8V11.2Z" fill={background}/>
      <path 
        transform="translate(12, 12)"
        d="M6 13C3.8 13 2 14.8 2 17C2 19.2 3.8 21 6 21C8.2 21 10 19.2 10 17C10 14.8 8.2 13 6 13ZM12 3C9.8 3 8 4.8 8 7C8 9.2 9.8 11 12 11C14.2 11 16 9.2 16 7C16 4.8 14.2 3 12 3ZM18 13C15.8 13 14 14.8 14 17C14 19.2 15.8 21 18 21C20.2 21 22 19.2 22 17C22 14.8 20.2 13 18 13Z" fill={color}/>
    </svg>
  );
};

export default NSCode;