import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type ArrowRightPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ArrowRight: FC<ArrowRightPropTypes> = ({ width = 20, height = 20, color = 'softPurple', ...rest }) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M7.99996 2.66675L7.05996 3.60675L10.78 7.33342H2.66663V8.66675H10.78L7.05996 12.3934L7.99996 13.3334L13.3333 8.00008L7.99996 2.66675Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default ArrowRight;
