import i18n from 'i18next';

import { IAllowedModule, ICatalog } from '../ProductsInterfaces';

import { addSuccessMessage, addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';

export const EDIT_CATALOG = 'EDIT_CATALOG';
export const SELECT_CATALOG = 'SELECT_CATALOG';
export const DELETE_CATALOG = 'DELETE_CATALOG';
export const HARD_DELETE_CATALOG = 'HARD_DELETE_CATALOG';
export const LOAD_ALL_CATALOGS = 'LOAD_ALL_CATALOGS';
export const LOAD_ALLOWED_MODULES = 'LOAD_ALLOWED_MODULES';
export const LOAD_DELETED_CATALOGS = 'LOAD_DELETED_CATALOGS';
export const RESTORE_CATALOG = 'RESTORE_CATALOG';
export const IS_CATALOG_LOADING = 'IS_CATALOG_LOADING';
export const IS_CATALOG_DIALOG_LOADING = 'IS_CATALOG_DIALOG_LOADING';

const loadCatalogsSuccess = (payload: Array<ICatalog>, pagingInfo: IPageInfo) => ({
  type: LOAD_ALL_CATALOGS,
  payload: { items: [...payload], ...pagingInfo },
});

const loadAllowedModulesSuccess = (payload: Array<IAllowedModule>) => ({
  type: LOAD_ALLOWED_MODULES,
  payload: { allowedModules: [...payload] },
});

const editCatalogSuccess = (payload: ICatalog) => ({ type: EDIT_CATALOG, payload });

const loadDeletedCatalogsSuccess = (payload: Array<ICatalog>) => ({
  type: LOAD_DELETED_CATALOGS,
  payload: { deletedItems: [...payload] },
});

const deleteCatalogSuccess = (payload: ICatalog, hardDelete = false) => ({
  type: hardDelete ? HARD_DELETE_CATALOG : DELETE_CATALOG,
  payload,
});

const restoreCatalogSuccess = (payload: ICatalog) => ({ type: RESTORE_CATALOG, payload });

const setIsLoading = (payload: boolean) => ({ type: IS_CATALOG_LOADING, payload });

const setIsDialogLoading = (payload: boolean) => ({ type: IS_CATALOG_DIALOG_LOADING, payload });

export const loadCatalogs = (catalogId?: string, params: IParams = { pageSize: 10, pageNumber: 0 }) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const url = '/products/api/catalogs';
      dispatch(setIsLoading(true));
      const { selectedItem } = getState().productsCatalogsModule;
      const {
        data: { payload, pageInfo },
      } = await API.get(url);
      dispatch(loadCatalogsSuccess(payload, pageInfo));
      if (payload?.length) {
        if (!catalogId && !selectedItem?.id) {
          dispatch(selectCatalog(payload[0]));
        } else {
          const catId = catalogId ?? selectedItem.id;
          const catalog = payload.find(({ id }: ICatalog) => id === catId);
          dispatch(selectCatalog(catalog));
        }
      }
    } catch (error) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const selectCatalog = (payload: ICatalog) => ({
  type: SELECT_CATALOG,
  payload,
});

export const loadAllowedModules = () => {
  return async (dispatch: Function) => {
    const url = '/licenses/api/Modules/User/AdministratorFor';
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(loadAllowedModulesSuccess(payload));
    } catch (e) {
      const message = i18n.t('products.errorLoadingAllowedModules');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const createCatalog = (data: ICatalog, callback?: Function) => {
  return async (dispatch: Function) => {
    const url = '/products/api/catalogs';
    try {
      dispatch(setIsDialogLoading(true));
      await API.post(url, data);
      callback?.();
      dispatch(loadCatalogs());
      const message = i18n.t('products.successCreatingCatalog');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('products.errorCreatingCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const editCatalog = (data: ICatalog, id: string, callback = () => {}) => {
  return async (dispatch: Function) => {
    const url = `/products/api/catalogs/${id}`;
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.put(url, data);
      callback();
      dispatch(editCatalogSuccess(payload));
      const message = i18n.t('products.successEditingCatalog');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('products.errorEditingCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const loadDeletedCatalogs = () => {
  return async (dispatch: Function) => {
    const url = '/products/api/catalogs/Deleted';
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.get(url);

      dispatch(loadDeletedCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('products.errorLoadingDeletedCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const deleteCatalog = (id: string, hardDelete = false, callback?: Function | null) => {
  return async (dispatch: Function, getState: Function) => {
    const { selectedItem, items } = getState().productsCatalogsModule;
    const { id: selectedItemId = '' } = selectedItem || {};

    const url = `/products/api/catalogs/${id}?hardDelete=${hardDelete}`;
    try {
      hardDelete ? dispatch(setIsDialogLoading(true)) : dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.delete(url);

      dispatch(deleteCatalogSuccess(payload, hardDelete));
      if (selectedItemId === id) {
        dispatch(selectCatalog(items[0]));
      }
      dispatch(loadDeletedCatalogs());
      const message = i18n.t('products.successDeletingCatalog');
      dispatch(addSuccessMessage({ message }));
      if (callback) callback();
    } catch (e) {
      const message = i18n.t('products.errorDeletingCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      hardDelete ? dispatch(setIsDialogLoading(false)) : dispatch(setIsLoading(false));
    }
  };
};

export const restoreCatalog = (id: string) => {
  return async (dispatch: Function) => {
    const url = `/products/api/catalogs/Deleted/${id}`;
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.put(url);
      dispatch(restoreCatalogSuccess(payload));
      const message = i18n.t('products.successRestoringCatalog');
      dispatch(addSuccessMessage({ message }));
      dispatch(loadCatalogs());
    } catch (e) {
      const message = i18n.t('products.errorRestoringCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const addCatalogRequiredModule = (catalogId: string, moduleCode: number) => {
  return async (dispatch: Function) => {
    const url = `products/api/catalogs/${catalogId}/RequiredModules/${moduleCode}`;
    try {
      dispatch(setIsLoading(true));
      await API.post(url);
      dispatch(loadCatalogs());
    } catch (e) {
      const message = i18n.t('globals.errorAddingModuleToCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const deleteCatalogRequiredModule = (catalogId: string, moduleCode: number) => {
  return async (dispatch: Function) => {
    const url = `products/api/catalogs/${catalogId}/RequiredModules/${moduleCode}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(loadCatalogs());
    } catch (e) {
      const message = i18n.t('globals.errorDeletingModuleToCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
