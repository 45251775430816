
import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

const MuiTextFieldStyled = styled(MuiTextField)(({ theme, size }) => ({
  ...(size === 'medium' && {
    '.MuiOutlinedInput-input': {
      // padding: '14px 16px',
      backgroundColor: '#fff',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '.MuiInputLabel-outlined:not(.Mui-focused)': {
      // transform: 'translate(14px, 14px) scale(1)',
      lineHeight: '20px',
    },
  }),
  ...(size === 'small' && {
    '.MuiOutlinedInput-input': {
      // padding: '10px 16px',
      backgroundColor: '#fff',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '.MuiInputLabel-outlined:not(.Mui-focused)': {
      // transform: 'translate(10px, 10px) scale(1)',
      lineHeight: '20px',
    },
  }),
  ...{
    '.MuiInputBase-multiline': {
      padding: '0',
    },
  },
}));
export const TextField = (props: TextFieldProps): ReactElement => {
  return <MuiTextFieldStyled {...props} />;
};

export default TextField;
