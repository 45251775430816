import { t } from 'i18next';
import { generalPalette } from 'theme/palette';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { FC, MouseEventHandler } from 'react';

import { TagContainer } from './styled';

type Props = {
  connection: string;
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isHeader?: boolean;
};
const Tag: FC<Props> = ({ connection = 'default', isSelected, onClick, isHeader = false }) => {
  const color = {
    [t('customers.customer')]: { bgColor: generalPalette.error.lighter, color: generalPalette.brown.main },
    [t('vendors.vendor')]: { bgColor: generalPalette.success.main, color: generalPalette.success.light },
    [t('suppliers.supplier')]: { bgColor: generalPalette.primary.lighter, color: generalPalette.purple.main },
    default: { bgColor: generalPalette.purple.lighter, color: generalPalette.purple.main },
  };

  return (
    <TagContainer
      sx={{
        backgroundColor: color[connection]?.bgColor,
        color: color[connection]?.color,
        border: (theme) => (isSelected ? `1px solid ${theme.palette.primary.main}` : 'none'),
      }}
      onClick={onClick}>
      <Typography
        lineHeight={1.5}
        pl={2}
        pr={2}
        fontWeight={600}
        fontSize={isHeader ? '18px' : '12px'}
        variant="caption2"
        color={color[connection]?.color}>
        {connection}
      </Typography>
    </TagContainer>
  );
};

export default Tag;
