import i18n from 'i18next';

import { IPagingInfo, ISupplier } from '../SuppliersInterfaces';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { ICreateSupplier } from 'modules/Products/ProductsInterfaces';
import { LOAD_PRODUCT_SUPPLIERS } from 'modules/Products/redux/ProductsActions';
import API from 'utils/API';

export const LOAD_ALL_SUPPLIERS = 'LOAD_ALL_SUPPLIERS';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const HARD_DELETE_SUPPLIER = 'HARD_DELETE_SUPPLIER';
export const IS_SUPPLIERS_LOADING = 'IS_SUPPLIERS_LOADING';
export const IS_SUPPLIERS_DIALOG_LOADING = 'IS_SUPPLIERS_DIALOG_LOADING';
export const LOAD_DELETED_SUPPLIERS = 'LOAD_DELETED_SUPPLIERS';
export const RESTORE_SUPPLIER = 'RESTORE_SUPPLIER';
export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
export const EDIT_SUPPLIER = 'EDIT_SUPPLIER';
export const SELECT_SUPPLIER = 'SELECT_SUPPLIER';
export const LOAD_AVAILABLE_SUPPLIERS = 'LOAD_AVAILABLE_SUPPLIERS';
export const LOAD_LIST_OF_SUPPLIERS = 'LOAD_LIST_OF_SUPPLIERS';

export const SAVE_SEARCHED_SUPPLIERS = 'SAVE_SEARCHED_SUPPLIERS';

const setIsLoading = (payload: boolean) => ({ type: IS_SUPPLIERS_LOADING, payload });

const setIsDialogLoading = (payload: boolean) => ({ type: IS_SUPPLIERS_DIALOG_LOADING, payload });

export const saveSearcheSuppliersSuccess = (payload: Array<ISupplier>, totalHits: number) => ({
  type: SAVE_SEARCHED_SUPPLIERS,
  payload,
});

export const loadAllSuppliersSuccess = (items: Array<ISupplier>, totalItems: number, pagingInfo?: IPagingInfo) => {
  return {
    type: LOAD_ALL_SUPPLIERS,
    payload: { items: [...items], totalItems, pagingInfo },
  };
};

export const loadAvailableSuppliersSuccess = (payload: Array<ISupplier>, pagingInfo: IPagingInfo) => {
  return {
    type: LOAD_AVAILABLE_SUPPLIERS,
    payload,
  };
};

export const loadListOfSuppliersSuccess = (payload: ISupplier[]) => ({
  type: LOAD_LIST_OF_SUPPLIERS,
  payload,
});
export const loadListOfProductSuppliersSuccess = (payload: ISupplier[]) => ({
  type: LOAD_PRODUCT_SUPPLIERS,
  payload: { suppliers: [...payload] },
});

export const deleteSupplierSuccess = (payload: ISupplier, hardDelete: boolean) => ({
  payload,
  type: hardDelete ? HARD_DELETE_SUPPLIER : DELETE_SUPPLIER,
});

export const loadDeletedSuppliersSuccess = (payload: Array<ISupplier>) => ({
  type: LOAD_DELETED_SUPPLIERS,
  payload: { deletedItems: [...payload] },
});

export const restoreSupplierSuccess = (payload: ISupplier) => ({
  type: RESTORE_SUPPLIER,
  payload,
});

export const selectSupplier = (payload: ISupplier | null) => ({
  payload,
  type: SELECT_SUPPLIER,
});

export const createSupplierSuccess = (payload: ISupplier) => ({ type: CREATE_SUPPLIER, payload });

export const editSupplierSuccess = (payload: ISupplier) => ({ type: EDIT_SUPPLIER, payload });

export const loadAllSuppliers = (params?: any, externalReference?: string) => {
  const { pageNumber = 0, pageSize = 10 } = params ?? {};
  let URL = `/products/api/suppliers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (externalReference)
    URL = `/products/api/suppliers?pageNumber=${pageNumber}&pageSize=${pageSize}&externalReference=${externalReference}`;
  return async (dispatch: Function) => {
    try {
      const countURL = `/products/api/suppliers/count`;
      dispatch(setIsLoading(true));
      const countPromise = API.get(countURL);
      const dataPromise = API.get(URL);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      const pagingData = { pageNumber: pageNumber + 1, pageSize };
      dispatch(loadAllSuppliersSuccess(payload, totalItems, pagingData));
    } catch (e) {
      const message = i18n.t('suppliers.errorLoadingSuppliers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadMoreSuppliers = () => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { pagingInfo, items } = getState().suppliersModule;
      const { pageNumber = 0, pageSize = 10 } = pagingInfo;
      const URL = `/products/api/suppliers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countURL = `/products/api/suppliers/count`;
      dispatch(setIsLoading(true));
      const countPromise = API.get(countURL);
      const dataPromise = API.get(URL);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      const pagingData = { pageNumber: pageNumber + 1, pageSize };
      dispatch(loadAllSuppliersSuccess([...items, ...payload], totalItems, pagingData));
    } catch (e) {
      console.log({ e });
      const message = i18n.t('suppliers.errorLoadingSuppliers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadProductSuppliers = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/products/${id}/suppliers`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadListOfProductSuppliersSuccess(payload));
    } catch (error) {
      const message = i18n.t('products.errorLoadingProductSuppliers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadListOfSuppliers = (listOfIds: string[], params?: any) => {
  const handleList = (supplierIds: Array<string>) => {
    let queryString = '';
    if (supplierIds.length === 0) return queryString;
    queryString = `?id=${supplierIds[0]}`;
    if (supplierIds.length > 1) {
      for (let i = 1; i < supplierIds.length; i++) {
        const iteration = `&id=${supplierIds[i]}`;
        queryString += iteration;
      }
    }
    return queryString;
  };

  return async (dispatch: Function) => {
    try {
      const url = `/products/api/suppliers/list${handleList(listOfIds)}`;
      const getListOfSuppliers = API.get(url);
      const {
        data: { payload },
      } = await getListOfSuppliers;
      dispatch(loadListOfSuppliersSuccess(payload));
    } catch (e) {
      const message = i18n.t('suppliers.errorLoadingList');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const deleteSupplier = (id: string, hardDelete = false) => {
  return async (dispatch: Function) => {
    try {
      hardDelete ? dispatch(setIsDialogLoading(true)) : dispatch(setIsLoading(true));
      const URL = `/products/api/suppliers/${id}?hardDelete=${hardDelete}`;
      const {
        data: { payload },
      } = await API.delete(URL);
      const message = i18n.t('suppliers.successDeletingSupplier');
      dispatch(addSuccessMessage({ message }));
      dispatch(deleteSupplierSuccess(payload, hardDelete));
    } catch (e) {
      const message = i18n.t('suppliers.errorDeletingSupplier');
      dispatch(addErrorMessage({ message }));
    } finally {
      hardDelete ? dispatch(setIsDialogLoading(false)) : dispatch(setIsLoading(false));
    }
  };
};

export const loadDeletedSuppliers = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/products/api/suppliers/deleted`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadDeletedSuppliersSuccess(payload));
    } catch (e) {
      const message = i18n.t('suppliers.errorLoadingDeletedSuppliers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const restoreSupplier = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/products/api/suppliers/deleted/${id}`;
      const {
        data: { payload },
      } = await API.put(URL);
      dispatch(restoreSupplierSuccess(payload));
      const message = i18n.t('suppliers.successRestoringSupplier');
      dispatch(addSuccessMessage({ message }));
      dispatch(loadAllSuppliers());
    } catch (e) {
      const message = i18n.t('suppliers.errorRestoringSupplier');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const createSupplier = (data: ICreateSupplier) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/products/api/suppliers`;
      const {
        data: { payload },
      } = await API.post(URL, data);
      const message = i18n.t('suppliers.successCreatingSupplier');
      dispatch(addSuccessMessage({ message }));
      dispatch(createSupplierSuccess(payload));
      return payload;
    } catch (e) {
      const message = i18n.t('suppliers.errorCreatingSupplier');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const editSupplier = (data: ICreateSupplier, id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/suppliers/${id}`;
      const {
        data: { payload },
      } = await API.put(URL, data);
      const message = i18n.t('suppliers.successUpdatingSupplier');
      dispatch(addSuccessMessage({ message }));
      dispatch(editSupplierSuccess(payload));
    } catch (e) {
      const message = i18n.t('suppliers.errorUpdatingSupplier');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForSuppliers = (query: string, params: IParams = { pageNumber: 0, pageSize: 15 }) => {
  const searchForSuppliersUrl = `/products/api/supplierSearch`;
  const countUrl = `/products/api/supplierSearch/count`;
  const searchFields = {
    query,
    fields: ['name', 'externalreference'],
    ...params,
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForSuppliersPromise = API.post(searchForSuppliersUrl, { ...searchFields });
      const countPromise = API.post(countUrl, { ...searchFields });
      const {
        data: { payload },
      } = await searchForSuppliersPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      const pagingData = { pageNumber: Number(params.pageNumber) + 1, pageSize: Number(params.pageSize) };
      dispatch(loadAllSuppliersSuccess(payload, totalItems, pagingData));
    } catch (error) {
      //This error is getting spewed out even tho there is no error....
      // const message = i18n.t('suppliers.errorSearchingForSuppliers');
      // dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadAvailableSuppliers = (params?: any, externalReference?: string) => {
  const { pageNumber = 0, pageSize = 10 } = params ?? {};
  let URL = `/suppliers/api/suppliers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (externalReference)
    URL = `/suppliers/api/suppliers?pageNumber=${pageNumber}&pageSize=${pageSize}&externalReference=${externalReference}`;
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const dataPromise = API.get(URL);
      const {
        data: { payload },
      } = await dataPromise;
      const pagingData = { pageNumber: pageNumber + 1, pageSize };
      dispatch(loadAvailableSuppliersSuccess(payload, pagingData));
    } catch (e) {
      const message = i18n.t('suppliers.errorLoadingSuppliers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
