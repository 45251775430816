import { FC } from 'react';

type ThreeDotHorizontalIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ThreeDotHorizontalIcon: FC<ThreeDotHorizontalIconPropTypes> = ({ width = 22, height = 6, color = "#364670", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9993 5.33329C12.288 5.33329 13.3327 4.28862 13.3327 2.99996C13.3327 1.71129 12.288 0.666626 10.9993 0.666626C9.71068 0.666626 8.66602 1.71129 8.66602 2.99996C8.66602 4.28862 9.71068 5.33329 10.9993 5.33329Z" fill={color}/>
    <path d="M19.1673 5.33329C20.456 5.33329 21.5007 4.28862 21.5007 2.99996C21.5007 1.71129 20.456 0.666626 19.1673 0.666626C17.8787 0.666626 16.834 1.71129 16.834 2.99996C16.834 4.28862 17.8787 5.33329 19.1673 5.33329Z" fill={color}/>
    <path d="M2.83333 5.33329C4.122 5.33329 5.16667 4.28862 5.16667 2.99996C5.16667 1.71129 4.122 0.666626 2.83333 0.666626C1.54467 0.666626 0.5 1.71129 0.5 2.99996C0.5 4.28862 1.54467 5.33329 2.83333 5.33329Z" fill={color}/>
    </svg>

  );
};

export default ThreeDotHorizontalIcon;
