import i18n from 'i18next';

import { addErrorMessage } from '../../AppAlerts/AppAlertsActions';
import { ITemplateContent } from '../../MOMTemplates/redux/Templates/templatesTypes';
import { IMomSidebarState } from '../interfaces/MomTreeInterfaces';
import {
  IChapter,
  IComponent,
  ISection,
  ITemplate,
  ITemplateCatalog,
  nodesIdsType,
  NodeType,
} from '../interfaces/ProjectMomInterfaces';

import API from 'utils/API';

export const MOM_SIDEBAR_IS_LOADING = 'MOM_SIDEBAR_IS_LOADING';
export const MOM_SIDEBAR_SELECT_ROW = 'MOM_SIDEBAR_SELECT_ROW';
export const MOM_SIDEBAR_SET_SELECTED_TEMPLATE_ID = 'MOM_SIDEBAR_SET_SELECTED_TEMPLATE_ID';
export const MOM_SIDEBAR_SET_SELECTED_TEMPLATE_CATALOG_ID = 'MOM_SIDEBAR_SET_SELECTED_TEMPLATE_CATALOG_ID';
export const MOM_SIDEBAR_LOAD_ALL_TEMPLATE_CATALOGS = 'MOM_SIDEBAR_LOAD_ALL_TEMPLATE_CATALOGS';
export const MOM_SIDEBAR_GET_TEMPLATE_CONTENT_BY_ID = 'MOM_SIDEBAR_GET_TEMPLATE_CONTENT_BY_ID';
export const MOM_SIDEBAR_LOAD_TEMPLATES_BY_CATALOG_ID = 'MOM_SIDEBAR_LOAD_TEMPLATES_BY_CATALOG_ID';
export const MOM_SIDEBAR_SELECT_NODE = 'MOM_SIDEBAR_SELECT_NODE';
export const MOM_SIDEBAR_DESELECT_NODE = 'MOM_SIDEBAR_DESELECT_NODE';
export const MOM_SIDEBAR_SET_SELECTED_NODES_CONTAINERS = 'MOM_SIDEBAR_SET_SELECTED_NODES_CONTAINERS';
export const MOM_SIDEBAR_SET_NODES_COUNT = 'MOM_SIDEBAR_SET_NODES_COUNT';

const setIsLoading = (isLoading: boolean) => ({ type: MOM_SIDEBAR_IS_LOADING, payload: { isLoading } });

export const selectMomTreeRow = (selectedRow: IChapter | IComponent | ISection) => ({
  type: MOM_SIDEBAR_SELECT_ROW,
  payload: { selectedRow },
});

const buildSelectedNodeWithChildren = (node: NodeType, allNodes: any) => {
  allNodes[node.id] = node.id;
  if (node.sectionType !== 'component' && Array.isArray(node?.children)) {
    node.children.forEach((node) => buildSelectedNodeWithChildren(node, allNodes));
  }
};

export const selectNode = (node: NodeType) => {
  return (dispatch: Function, getState: Function) => {
    const allSelectedNodes: nodesIdsType = {};
    buildSelectedNodeWithChildren(node, allSelectedNodes);
    const { selectedNodesIds }: IMomSidebarState = getState().momSidebarModule;
    delete allSelectedNodes.root;
    dispatch({
      type: MOM_SIDEBAR_SELECT_NODE,
      payload: { selectedNodesIds: { ...selectedNodesIds, ...allSelectedNodes } },
    });
  };
};

export const deselectNode = (deselectedNode: NodeType) => {
  return (dispatch: Function, getState: Function) => {
    const allNodesToDeselect: nodesIdsType = {};
    buildSelectedNodeWithChildren(deselectedNode, allNodesToDeselect);
    const { selectedNodesIds }: IMomSidebarState = getState().momSidebarModule;
    const selectedNodesIdsClone = structuredClone(selectedNodesIds);

    Object.keys(allNodesToDeselect).forEach((id: string) => {
      delete selectedNodesIdsClone[id];
    });
    dispatch({
      type: MOM_SIDEBAR_DESELECT_NODE,
      payload: {
        selectedNodesIds: selectedNodesIdsClone,
      },
    });
  };
};

export const setNodesContainer = (idsOfSelectedNodesContainers: nodesIdsType) => ({
  type: MOM_SIDEBAR_SET_SELECTED_NODES_CONTAINERS,
  payload: { idsOfSelectedNodesContainers },
});

export const setNodesCount = (numberOfNodes = 0, numberOfSelectedNodes = 0) => ({
  type: MOM_SIDEBAR_SET_NODES_COUNT,
  payload: { numberOfNodes, numberOfSelectedNodes },
});

export const saveSelectedTemplateId = (selectedTemplateId: string) => ({
  type: MOM_SIDEBAR_SET_SELECTED_TEMPLATE_ID,
  payload: { selectedTemplateId },
});

export const setSelectedTemplateCatalogId = (selectedTemplateCatalogId: string | null) => ({
  type: MOM_SIDEBAR_SET_SELECTED_TEMPLATE_CATALOG_ID,
  payload: { selectedTemplateCatalogId },
});

const loadAllTemplateCatalogsSuccess = (momTemplateCatalogs: Array<ITemplateCatalog>) => ({
  type: MOM_SIDEBAR_LOAD_ALL_TEMPLATE_CATALOGS,
  payload: { momTemplateCatalogs },
});

const loadMomTemplatesByCatalogIdSuccess = (allMomTemplates: Array<ITemplate>, totalMomTemplates: number) => ({
  type: MOM_SIDEBAR_LOAD_TEMPLATES_BY_CATALOG_ID,
  payload: { allMomTemplates },
});

const getTemplateContentSuccess = (selectedTemplate: ITemplateContent) => ({
  type: MOM_SIDEBAR_GET_TEMPLATE_CONTENT_BY_ID,
  payload: { selectedTemplate },
});

export const loadAllTemplateCatalogs = () => {
  return async (dispatch: Function) => {
    const url = '/fdv/api/templateCatalogs';
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(loadAllTemplateCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('projects.errorLoadingTemplateCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadMomTemplatesByCatalogId = (props: any) => {
  return async (dispatch: Function) => {
    const { pageSize = 50, pageNumber = 0, catalogId = '' } = props;
    const url = `/fdv/api/templates/catalog/${catalogId}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    const countUrl = `/fdv/api/templates/catalog/${catalogId}/count`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      const {
        data: { payload: totalTemplates },
      } = await API.get(countUrl);
      dispatch(loadMomTemplatesByCatalogIdSuccess(payload, totalTemplates));
      dispatch(setSelectedTemplateCatalogId(catalogId));
    } catch (e) {
      const message = i18n.t('products.errorLoadingMomTemplates');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getTemplateContent = (templateId: string) => {
  return async (dispatch: Function) => {
    const url = `/fdv/api/templates/${templateId}/content`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(getTemplateContentSuccess(payload));
    } catch (e) {
      const message = i18n.t('products.errorLoadingTemplates');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
