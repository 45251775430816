import { FC } from 'react';

type EmptyStateInstructionIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const EmptyStateInstructionIcon: FC<EmptyStateInstructionIconPropTypes> = ({
  width = 133,
  height = 104,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 133 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.3243 104H102.775C104.766 104.005 106.686 103.285 108.149 101.985C114.252 96.5461 119.135 89.9633 122.496 82.6412C125.858 75.3191 127.628 67.4125 127.697 59.4077C128.009 26.8588 100.518 0.0769828 66.6979 0.000167531C32.8584 -0.0766912 5.40129 26.3019 5.40129 58.8526C5.39162 66.9542 7.1249 74.97 10.492 82.3952C13.8591 89.8205 18.787 96.4944 24.9655 101.997C26.4255 103.291 28.3395 104.006 30.3243 104V104Z"
        fill="#EFF1F8"
      />
      <path
        d="M89.6091 27.2402C87.0911 27.2402 84.5978 27.7147 82.2714 28.6366C79.9451 29.5584 77.8313 30.9096 76.0508 32.613C74.2703 34.3164 72.8579 36.3386 71.8943 38.5641C70.9307 40.7897 70.4348 43.175 70.4348 45.584C70.4348 45.5962 70.4357 45.6082 70.4357 45.6204L56.0695 42.8866V63.9276H89.6091C94.6945 63.9276 99.5716 61.995 103.167 58.5549C106.763 55.1148 108.784 50.449 108.784 45.5839C108.784 40.7189 106.763 36.0531 103.167 32.613C99.5716 29.1729 94.6945 27.2402 89.6091 27.2402V27.2402Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.58952"
        strokeMiterlimit="10"
      />
      <path
        d="M75.3438 57.8046C79.5008 54.8346 82.8465 50.9481 85.0965 46.4756C87.3465 42.0031 88.4342 37.0769 88.2671 32.116C88.1 27.155 86.683 22.3061 84.1367 17.9814C81.5903 13.6568 77.9899 9.98447 73.6417 7.2767C69.2935 4.56892 64.326 2.90581 59.1617 2.4288C53.9974 1.9518 48.7891 2.675 43.9797 4.53692C39.1703 6.39883 34.902 9.34438 31.5379 13.1231C28.1737 16.9017 25.8132 21.4018 24.6571 26.2405C21.9368 25.7345 19.1359 25.7783 16.4343 26.3689C13.7327 26.9596 11.19 28.0841 8.96946 29.6703C6.74892 31.2565 4.89958 33.2694 3.54015 35.5797C2.18072 37.89 1.34123 40.4467 1.07556 43.0857C0.809901 45.7247 1.12393 48.3877 1.99751 50.9037C2.87108 53.4197 4.28489 55.7333 6.14819 57.6957C8.0115 59.6582 10.2831 61.2263 12.8173 62.2993C15.3514 63.3724 18.0921 63.9267 20.8633 63.9267H63.0366"
        fill="white"
      />
      <path
        d="M75.3438 57.8046C79.5008 54.8346 82.8465 50.9481 85.0965 46.4756C87.3465 42.0031 88.4342 37.0769 88.2671 32.116C88.1 27.155 86.683 22.3061 84.1367 17.9814C81.5903 13.6568 77.9899 9.98447 73.6417 7.2767C69.2935 4.56892 64.326 2.90581 59.1617 2.4288C53.9974 1.9518 48.7891 2.675 43.9797 4.53692C39.1703 6.39883 34.902 9.34438 31.5379 13.1231C28.1737 16.9017 25.8132 21.4018 24.6571 26.2405C21.9368 25.7345 19.1359 25.7783 16.4343 26.3689C13.7327 26.9596 11.19 28.0841 8.96946 29.6703C6.74892 31.2565 4.89958 33.2694 3.54015 35.5797C2.18072 37.89 1.34123 40.4467 1.07556 43.0857C0.809901 45.7247 1.12393 48.3877 1.99751 50.9037C2.87108 53.4197 4.28489 55.7333 6.14819 57.6957C8.0115 59.6582 10.2831 61.2263 12.8173 62.2993C15.3514 63.3724 18.0921 63.9267 20.8633 63.9267H63.0366"
        stroke="#D4D9E3"
        strokeWidth="1.58952"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8538 33.1064C23.8538 24.9323 27.2479 17.0931 33.2896 11.3132C39.3312 5.53326 47.5255 2.28613 56.0697 2.28613C64.6139 2.28613 72.8081 5.53326 78.8498 11.3132C84.8915 17.0931 88.2856 24.9323 88.2856 33.1064"
        fill="white"
      />
      <path
        d="M23.8538 33.1064C23.8538 24.9323 27.2479 17.0931 33.2896 11.3132C39.3312 5.53326 47.5255 2.28613 56.0697 2.28613C64.6139 2.28613 72.8081 5.53326 78.8498 11.3132C84.8915 17.0931 88.2856 24.9323 88.2856 33.1064"
        stroke="#D4D9E3"
        strokeWidth="1.58952"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.7256 101.094H46.5612C45.5266 101.094 44.5344 100.701 43.8028 100.001C43.0713 99.3016 42.6603 98.3523 42.6603 97.3626V32.9799C42.6603 31.9902 43.0713 31.0409 43.8028 30.3411C44.5344 29.6412 45.5266 29.248 46.5612 29.248H75.4928L92.6265 45.6395V97.3626C92.6265 98.3523 92.2155 99.3016 91.4839 100.001C90.7524 100.701 89.7602 101.094 88.7256 101.094Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.58952"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.6266 45.6395H79.3938C78.8815 45.6395 78.3743 45.543 77.901 45.3554C77.4277 45.1679 76.9977 44.893 76.6355 44.5464C76.2732 44.1999 75.9859 43.7885 75.7898 43.3357C75.5938 42.883 75.4929 42.3977 75.4929 41.9076V29.248L92.6266 45.6395Z"
        fill="#0975A5"
      />
      <path
        d="M67.2991 41.7412H50.9741C50.147 41.7412 49.4764 42.3827 49.4764 43.174C49.4764 43.9652 50.147 44.6067 50.9741 44.6067H67.2991C68.1262 44.6067 68.7967 43.9652 68.7967 43.174C68.7967 42.3827 68.1262 41.7412 67.2991 41.7412Z"
        fill="#D4D9E3"
      />
      <path
        d="M83.9392 51.4004H50.9741C50.147 51.4004 49.4764 52.0419 49.4764 52.8331V52.8331C49.4764 53.6244 50.147 54.2659 50.9741 54.2659H83.9392C84.7663 54.2659 85.4368 53.6244 85.4368 52.8331C85.4368 52.0419 84.7663 51.4004 83.9392 51.4004Z"
        fill="#D4D9E3"
      />
      <path
        d="M83.9392 61.0605H50.9741C50.147 61.0605 49.4764 61.702 49.4764 62.4933V62.4933C49.4764 63.2846 50.147 63.926 50.9741 63.926H83.9392C84.7663 63.926 85.4368 63.2846 85.4368 62.4933C85.4368 61.702 84.7663 61.0605 83.9392 61.0605Z"
        fill="#D4D9E3"
      />
      <path
        d="M83.9392 70.7207H50.9741C50.147 70.7207 49.4764 71.3622 49.4764 72.1535C49.4764 72.9447 50.147 73.5862 50.9741 73.5862H83.9392C84.7663 73.5862 85.4368 72.9447 85.4368 72.1535C85.4368 71.3622 84.7663 70.7207 83.9392 70.7207Z"
        fill="#D4D9E3"
      />
      <path
        d="M84.0597 80.3809H50.9741C50.147 80.3809 49.4764 81.0223 49.4764 81.8136C49.4764 82.6049 50.147 83.2464 50.9741 83.2464H84.0597C84.8868 83.2464 85.5573 82.6049 85.5573 81.8136C85.5573 81.0223 84.8868 80.3809 84.0597 80.3809Z"
        fill="#D4D9E3"
      />
      <path
        d="M120.89 19.3652C119.431 19.3652 117.986 19.6402 116.638 20.1743C115.291 20.7084 114.066 21.4913 113.034 22.4783C112.002 23.4653 111.184 24.637 110.626 25.9265C110.067 27.216 109.78 28.5981 109.78 29.9939C109.78 30.001 109.781 30.0079 109.781 30.015L101.457 28.431V40.6226H120.89C123.837 40.6226 126.662 39.5028 128.746 37.5095C130.83 35.5163 132 32.8128 132 29.9939C132 27.175 130.83 24.4716 128.746 22.4783C126.662 20.485 123.837 19.3652 120.89 19.3652H120.89Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.58952"
        strokeMiterlimit="10"
      />
      <path
        d="M112.624 37.0747C115.033 35.3538 116.971 33.1019 118.275 30.5104C119.579 27.919 120.209 25.0647 120.112 22.1902C120.015 19.3157 119.194 16.5062 117.719 14.0004C116.244 11.4946 114.158 9.36681 111.638 7.79788C109.119 6.22894 106.24 5.2653 103.248 4.98892C100.256 4.71253 97.238 5.13157 94.4513 6.21039C91.6647 7.28922 89.1916 8.99593 87.2423 11.1854C85.2931 13.3748 83.9253 15.9822 83.2555 18.7859C81.6793 18.4927 80.0564 18.518 78.491 18.8602C76.9257 19.2025 75.4524 19.854 74.1658 20.7731C72.8791 21.6922 71.8076 22.8585 71.0199 24.1971C70.2322 25.5358 69.7458 27.0172 69.5919 28.5463C69.4379 30.0753 69.6199 31.6183 70.1261 33.0762C70.6322 34.534 71.4514 35.8745 72.5311 37.0116C73.6107 38.1487 74.9269 39.0573 76.3952 39.679C77.8636 40.3008 79.4516 40.6219 81.0573 40.6219H101.456"
        fill="white"
      />
      <path
        d="M112.624 37.0747C115.033 35.3538 116.971 33.1019 118.275 30.5104C119.579 27.919 120.209 25.0647 120.112 22.1902C120.015 19.3157 119.194 16.5062 117.719 14.0004C116.244 11.4946 114.158 9.36681 111.638 7.79788C109.119 6.22894 106.24 5.2653 103.248 4.98892C100.256 4.71253 97.238 5.13157 94.4513 6.21039C91.6647 7.28922 89.1916 8.99593 87.2423 11.1854C85.2931 13.3748 83.9253 15.9822 83.2555 18.7859C81.6793 18.4927 80.0564 18.518 78.491 18.8602C76.9257 19.2025 75.4524 19.854 74.1658 20.7731C72.8791 21.6922 71.8076 22.8585 71.0199 24.1971C70.2322 25.5358 69.7458 27.0172 69.5919 28.5463C69.4379 30.0753 69.6199 31.6183 70.1261 33.0762C70.6322 34.534 71.4514 35.8745 72.5311 37.0116C73.6107 38.1487 74.9269 39.0573 76.3952 39.679C77.8636 40.3008 79.4516 40.6219 81.0573 40.6219H105.493"
        stroke="#D4D9E3"
        strokeWidth="1.58952"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.79 22.7641C82.79 18.0279 84.7567 13.4857 88.2573 10.1367C91.758 6.78769 96.5059 4.90625 101.457 4.90625C106.407 4.90625 111.155 6.78769 114.656 10.1367C118.156 13.4857 120.123 18.0279 120.123 22.7641"
        stroke="#D4D9E3"
        strokeWidth="1.58952"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyStateInstructionIcon;
