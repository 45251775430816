import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import {
  SET_COMPANIES_LOADER_ACTION,
  SAVE_COMPANIES_ACTION,
  DELETE_COMPANY_REQUEST_ACTION,
  DELETE_COMPANY_SUCCESS_ACTION,
  IS_DELETED_COMPANIES_LOADING_ACTION,
  SAVE_DELETED_COMPANIES_ACTION,
  ADD_ERROR_MESSAGE_ACTION,
  RESTORE_COMPANY_ACTION,
  LOAD_COMPANY_ITEM_ACTION,
  UPDATE_COMPANY_ITEM_ACTION,
  LOAD_COMPANIES_CONTACTS_SUCCESS_ACTION,
  DELETE_COMPANY_CONTACT_ITEM_ACTION,
  SAVE_SEARCHED_COMPANY_ACTION,
  SAVE_SEARCHED_CONTACT_ACTION,
  CREATE_COMPANY_ACTION,
} from './types';

import {
  ICompanyItem,
  IUpdateCompany,
  ICompaniesLoadResponse,
  ICompaniesDeleteResponse,
  ICompanyContactResponse,
  ICompanyItemResponse,
  IUpdatePhone,
  IUpdateEmail,
  IPersonItem,
  ICompanyContactItem,
  IOrganizationSearch,
  IAddContact,
  IAddPersonItemResponse,
  ISearchedContactResponse,
  IValues,
  ISearchResult,
} from '../companiesInterfaces';

import { addSuccessMessage, addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import { addPersonAction } from 'modules/Persons/redux/personsActions';
import API from 'utils/API';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

export const SET_COMPANIES_LOADER = 'SET_COMPANIES_LOADER';
export const SAVE_COMPANIES = 'SAVE_COMPANIES';

export const LOAD_COMPANIES_CONTACTS_SUCCESS = 'LOAD_COMPANIES_CONTACTS_SUCCESS';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';

export const DELETE_COMPANY_CONTACT_ITEM = 'DELETE_COMPANY_CONTACT_ITEM';

export const IS_DELETED_COMPANIES_LOADING = 'IS_DELETED_COMPANIES_LOADING';
export const SAVE_DELETED_COMPANIES = 'SAVE_DELETED_COMPANIES';

export const LOAD_COMPANY_ITEM = 'LOAD_COMPANY_ITEM';

export const UPDATE_COMPANY_ITEM = 'UPDATE_COMPANY_ITEM';

export const RESTORE_COMPANY = 'RESTORE_COMPANY';

export const SAVE_ORGANIZATIONS = 'SAVE_ORGANIZATIONS';

export const SAVE_SEARCHED_CONTACT = 'SAVE_SEARCHED_CONTACT';

export const SAVE_SEARCHED_COMPANIES_BY_NAME = 'SAVE_SEARCHED_COMPANIES_BY_NAME';

export const CREATE_COMPANY = 'CREATE_COMPANY';

export const setCompaniesLoaderAction = (isLoading: boolean): SET_COMPANIES_LOADER_ACTION => ({
  type: SET_COMPANIES_LOADER,
  isLoading,
});
export const saveCompaniesAction = (
  allCompanies: ICompanyItem[],
  totalCompaniesNumber: number
): SAVE_COMPANIES_ACTION => ({
  type: SAVE_COMPANIES,
  allCompanies,
  totalCompaniesNumber,
});

export const deleteCompanyRequestAction = (id: string): DELETE_COMPANY_REQUEST_ACTION => ({
  type: DELETE_COMPANY_REQUEST,
  id,
});
export const deleteCompanySuccessAction = (message: string, id: string): DELETE_COMPANY_SUCCESS_ACTION => ({
  type: DELETE_COMPANY_SUCCESS,
  message,
  id,
});

export const deleteCompanyContactItemAction = (
  companyId: string,
  contactId: string
): DELETE_COMPANY_CONTACT_ITEM_ACTION => ({
  type: DELETE_COMPANY_CONTACT_ITEM,
  companyId,
  contactId,
});

export const setDeletedCompaniesLoadingAction = (isLoading: boolean): IS_DELETED_COMPANIES_LOADING_ACTION => ({
  type: IS_DELETED_COMPANIES_LOADING,
  isLoading,
});

export const getCompaniesContactsSuccessAction = (
  contacts: ICompanyContactItem[]
): LOAD_COMPANIES_CONTACTS_SUCCESS_ACTION => ({
  type: LOAD_COMPANIES_CONTACTS_SUCCESS,
  contacts,
});

export const saveDeletedCompaniesAction = (
  deletedCompanies: ICompanyItem[],
  deletedCompaniesNumber: number
): SAVE_DELETED_COMPANIES_ACTION => ({
  type: SAVE_DELETED_COMPANIES,
  deletedCompanies,
  deletedCompaniesNumber,
});

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const getCompanyItemAction = (company: ICompanyItem): LOAD_COMPANY_ITEM_ACTION => ({
  type: LOAD_COMPANY_ITEM,
  company,
});

export const restoreCompanyAction = (companyId: string): RESTORE_COMPANY_ACTION => ({
  type: RESTORE_COMPANY,
  companyId,
});

export const updateCompanyAction = (company: ICompanyItem): UPDATE_COMPANY_ITEM_ACTION => ({
  type: UPDATE_COMPANY_ITEM,
  company,
});

export const saveSearcheOrganizationsAction = (
  organizations: IOrganizationSearch | null
): SAVE_SEARCHED_COMPANY_ACTION => ({
  type: SAVE_ORGANIZATIONS,
  organizations,
});

export const saveSearchCompaniesByNameAction = (companies: ISearchResult | null) => ({
  type: SAVE_SEARCHED_COMPANIES_BY_NAME,
  companies,
});

export const saveSearchedContactPersonAction = (
  contactPersons: [IPersonItem] | null
): SAVE_SEARCHED_CONTACT_ACTION => ({
  type: SAVE_SEARCHED_CONTACT,
  contactPersons,
});

export const addCompanyAction = (company: ICompanyItem | null): CREATE_COMPANY_ACTION => ({
  type: CREATE_COMPANY,
  company,
});

export const loadAllCompanies = (params?: IParams) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;
  const countUrl = '/crm/api/companies/count';
  const allCompaniesURL = `/crm/api/companies?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      const countPromise = API.get(countUrl);
      const allCompaniesData = API.get(allCompaniesURL);
      const {
        data: { payload: companies },
      }: AxiosResponse<ICompaniesLoadResponse> = await allCompaniesData;
      const {
        data: { payload: totalCompaniesNumber },
      } = await countPromise;
      dispatch(saveCompaniesAction(companies, totalCompaniesNumber));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const loadDeletedCompanies = (params?: IParams) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;
  const getDeletedCompaniesURL = `crm/api/companies/deleted?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  const getDeletedCompaniesNumberUrl = 'crm/api/companies/deleted/count';
  return async (dispatch: Function) => {
    dispatch(setDeletedCompaniesLoadingAction(true));
    try {
      const deletedCompaniesPromise = API.get(getDeletedCompaniesURL);
      const countPromise = API.get(getDeletedCompaniesNumberUrl);
      const {
        data: { payload: companies },
      }: AxiosResponse<ICompaniesLoadResponse> = await deletedCompaniesPromise;
      const {
        data: { payload: deletedCompaniesNumber },
      } = await countPromise;
      dispatch(saveDeletedCompaniesAction(companies, deletedCompaniesNumber));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const deleteCompany = (companyId: string, isPermament: boolean = false) => {
  const deletePersonURL = `/crm/api/companies/${companyId}?hardDelete=${isPermament}`;
  return async (dispatch: Function) => {
    dispatch(deleteCompanyRequestAction(companyId));
    try {
      const response = API.delete(deletePersonURL, { data: companyId });
      // const { data: { payload, message } }: AxiosResponse<ICompaniesDeleteResponse> = await response;
      const data: AxiosResponse<ICompaniesDeleteResponse> = await response;
      if (!data) {
        dispatch(addErrorMessageAction('Something unexpected happened'));
        return;
      }
      const {
        data: { payload, message: responseMessage },
      } = data;
      dispatch(deleteCompanySuccessAction(responseMessage, payload.id));
      dispatch(loadDeletedCompanies());
      const message = i18n.t('companies.successDeleteCompany');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failDeleteCompany');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const restoreCompany = (companyId: string) => {
  const restorePersonURL = `crm/api/companies/deleted/${companyId}`;
  return async (dispatch: Function) => {
    dispatch(setDeletedCompaniesLoadingAction(true));
    try {
      const countPromise = API.put(restorePersonURL);
      await countPromise;
      dispatch(loadAllCompanies());
      dispatch(loadDeletedCompanies());
      const message = i18n.t('companies.successRestoreCompany');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failrestoreCompany');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const loadCompaniesContacts = (companyId: string) => {
  const companiesContactsUrl = `/crm/api/companies/${companyId}/contacts?pageNumber=0&pageSize=100`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      const companiesContacts = API.get(companiesContactsUrl);
      const {
        data: { payload: contacts },
      }: AxiosResponse<ICompanyContactResponse> = await companiesContacts;
      dispatch(getCompaniesContactsSuccessAction(contacts));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const deleteCompanyContactItem = (companyId: string, contactId: string) => {
  const companiesContactsDeleteUrl = `/crm/api/companies/${companyId}/contacts/${contactId}`;
  return async (dispatch: Function) => {
    try {
      const response = API.delete(companiesContactsDeleteUrl);
      // we may need to add a check in case the response failed. See deleteCompany function
      await response;
      dispatch(loadCompaniesContacts(companyId));
      const message = i18n.t('companies.successContactPersonDelete');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failContactPersonDelete');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const getCompanyItem = (companyId: string) => {
  const getCompanyUrl = `crm/api/companies/${companyId}`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      const getCompanyPromise = API.get(getCompanyUrl);
      const {
        data: { payload: company },
      }: AxiosResponse<ICompanyItemResponse> = await getCompanyPromise;
      dispatch(getCompanyItemAction(company));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const updateCompanyItem = (companyId: string, updatedCompany: IUpdateCompany) => {
  if (!companyId) return;

  const updateCompanyUrl = `crm/api/companies/${companyId}`;
  return async (dispatch: Function) => {
    dispatch(setDeletedCompaniesLoadingAction(true));
    try {
      const updatePromise = API.put(updateCompanyUrl, { ...updatedCompany });
      const {
        data: { payload: company },
      }: AxiosResponse<ICompanyItemResponse> = await updatePromise;
      dispatch(updateCompanyAction(company));
      dispatch(getCompanyItem(companyId));
      const message = i18n.t('companies.successUpdateContactPerson');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failUpdateContactPerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const addCompany = (company: ICompanyItem, callback: any) => {
  const addCompanyUrl = 'crm/api/companies';

  return async (dispatch: Function) => {
    dispatch(setDeletedCompaniesLoadingAction(true));
    try {
      const {
        data: { payload },
      } = await API.post(addCompanyUrl, { ...company });
      dispatch(addCompanyAction(payload));
      dispatch(loadAllCompanies());
      callback();
      const message = i18n.t('companies.successAddCompany');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failAdddCompany');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const editPhoneNumber = (companyId: string, phoneId: string, data: IUpdatePhone) => {
  const editPhoneNumberUrl = `crm/api/companies/${companyId}/TelephoneNumbers/${phoneId}`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      await API.put(editPhoneNumberUrl, { ...data });
      dispatch(getCompanyItem(companyId));
      const message = i18n.t('companies.successEditPhoneNumber');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failEditPhoneNumber');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const deletePhoneNumber = (companyId: string, phoneId: string) => {
  const deletePhoneNumberUrl = `crm/api/companies/${companyId}/TelephoneNumbers/${phoneId}`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      await API.delete(deletePhoneNumberUrl);
      dispatch(getCompanyItem(companyId));
      const message = i18n.t('companies.successDeletePhoneNumber');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failDeletePhoneNumber');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const editEmail = (companyId: string, emailId: string, data: IUpdateEmail) => {
  const editEmailUrl = `crm/api/companies/${companyId}/EmailAddresses/${emailId}`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      await API.put(editEmailUrl, { ...data });
      dispatch(getCompanyItem(companyId));
      const message = i18n.t('companies.successEditEmail');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failEditEmail');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const deleteEmail = (companyId: string, emailId: string) => {
  const deleteEmailUrl = `crm/api/companies/${companyId}/EmailAddresses/${emailId}`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      await API.delete(deleteEmailUrl);
      dispatch(getCompanyItem(companyId));
      const message = i18n.t('companies.successDeleteEmail');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failDeleteEmail');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const searchForCompanyContact = (name: string, params: IParams = { pageNumber: 0, pageSize: 15 }) => {
  const searchForCompanyContactUrl = `search/api/persons/autocomplete`;
  const searchFields = {
    query: name,
    ...params,
    fields: ['firstname', 'lastname', 'postalcode', 'postaladdress', 'address'],
  };
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      const searchForCompanyContactsPromise = API.post(searchForCompanyContactUrl, { ...searchFields });
      const {
        data: { hits: searchedContacts },
      }: AxiosResponse<ISearchedContactResponse> = await searchForCompanyContactsPromise;
      dispatch(saveSearchedContactPersonAction(searchedContacts));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const addCompanyContactItem = (contact: IAddContact, companyId: string, callback: any) => {
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      if (!companyId) {
        addErrorMessageAction('Company Id is required');
        callback();
        throw new Error('Company Id is required');
      }

      const addPersonUrl = 'crm/api/persons';
      const addContactUrl = `crm/api/companies/${companyId}/contacts`;

      const addPersonObj = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        telephoneNumbers: [
          {
            number: contact.number,
            primary: true,
          },
        ],
        emailAddresses: [
          {
            email: contact.email,
            primary: true,
          },
        ],
      };

      const addPersonPromise = API.post(addPersonUrl, { ...addPersonObj });
      const {
        data: { payload: person },
      }: AxiosResponse<IAddPersonItemResponse> = await addPersonPromise;
      dispatch(addPersonAction(person));

      const addContactObj = {
        role: contact.role,
        personId: person.id,
      };
      const addContactPromise = API.post(addContactUrl, { ...addContactObj });
      await addContactPromise;
      dispatch(loadCompaniesContacts(companyId));

      if (callback) callback();
      const message = i18n.t('companies.successAddContactPerson');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failAddContactPerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const updateCompanyContactItem = (contactId: string, companyId: string, updatedPerson: IValues) => {
  if (!contactId) return null;
  const updateCompanyContactUrl = `crm/api/companies/${companyId}/contacts/${contactId}`;
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      // updating contact's role
      const updateContactPromise = API.put(updateCompanyContactUrl, {
        contactId,
        role: updatedPerson.description,
      });
      await updateContactPromise;
      dispatch(loadCompaniesContacts(companyId));
      const message = i18n.t('companies.successUpdateContactPerson');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('companies.failUpdateContactPerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const searchForCompanies = (
  query: string,
  saveInSearched: boolean = false,
  params: IParams = { pageNumber: 0, pageSize: 15 }
) => {
  const searchForCompaniesUrl = `search/api/companies/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: ['name', 'address'],
  };
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      const searchForCompaniesPromise = API.post(searchForCompaniesUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForCompaniesPromise;
      if (saveInSearched) dispatch(saveSearchCompaniesByNameAction({ totalHits, hits }));
      if (!saveInSearched) dispatch(saveCompaniesAction(hits, totalHits));
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCompany');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};

export const searchForOrganizations = (query: string, params: IParams = { pageNumber: 0, pageSize: 15 }) => {
  const searchForCompaniesUrl = `search/api/organizations/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: [
      'name',
      'description',
      'organizationnumber',
      'phonenumber',
      'email',
      'postalcode',
      'postalarea',
      'address',
      'country',
    ],
  };
  return async (dispatch: Function) => {
    dispatch(setCompaniesLoaderAction(true));
    try {
      const searchForCompaniesPromise = API.post(searchForCompaniesUrl, { ...searchFields });
      const { data }: AxiosResponse<IOrganizationSearch> = await searchForCompaniesPromise;
      dispatch(saveSearcheOrganizationsAction(data));
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCompany');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setCompaniesLoaderAction(false));
    }
  };
};
