import {
  DELETE_PRODUCT,
  HARD_DELETE_PRODUCT,
  IS_PRODUCTS_DIALOG_LOADING,
  IS_PRODUCTS_LOADING,
  LOAD_ALL_PRODUCTS,
  GET_PRODUCT_BY_ID,
  LOAD_DELETED_PRODUCTS,
  RESTORE_PRODUCT,
  EDIT_PRODUCT,
  LOAD_PRODUCT_SUPPLIERS,
  REMOVE_PIECEWORK_FROM_PRODUCT,
  LOAD_LIST_OF_PRODUCTS,
  SET_HAS_MORE_ITEMS,
  SELECT_PRODUCT_FOR_REPLACEMENT,
  DESELECT_PRODUCT_FOR_REPLACEMENT,
  RESET_PRODUCTS_STATE,
  SELECT_REPLACEMENT_PRODUCT_FOR_DELETION,
  DESELECT_REPLACEMENT_PRODUCT_FOR_DELETION,
  SEARCH_FOR_PRODUCTS,
  CLEAR_SELECTED_REPLACEMENT_PRODUCTS,
  CLEAR_SELECTED_REPLACEMENT_ITEMS_FOR_PRODUCT,
} from './ProductsActions';
import { IProduct, IProductAction, IProductState } from '../ProductsInterfaces';

const defaultState: IProductState = {
  items: [],
  selectedItem: null,
  totalItems: 0,
  isLoading: false,
  hasMoreItems: false,
  isDialogLoading: false,
  deletedItems: [],
  suppliers: [],
  hits: [],
  totalHits: 0,
  selectedItemsForProductReplacements: [],
  selectedReplacementItemsForProduct: [],
};

const products = (state = defaultState, { type, payload, overwrite }: IProductAction) => {
  const newState = () => ({ ...state, ...payload });

  const updateAllProducts = () => {
    if (overwrite) {
      return {
        ...state,
        items: [...state.items, ...(payload as Array<IProduct>)],
      };
    }
    return newState();
  };

  const deleteOrRestoreProduct = () => {
    const deletedItem = payload as IProduct;
    const key = type === DELETE_PRODUCT ? 'items' : 'deletedItems';
    const deletedItems = state[key]?.filter(({ id }: IProduct) => id !== deletedItem.id);
    return { ...state, [key]: deletedItems };
  };

  const resetState = () => defaultState;

  const stateActions = {
    [IS_PRODUCTS_LOADING]: newState,
    [IS_PRODUCTS_DIALOG_LOADING]: newState,
    [LOAD_ALL_PRODUCTS]: updateAllProducts,
    [LOAD_PRODUCT_SUPPLIERS]: newState,
    [REMOVE_PIECEWORK_FROM_PRODUCT]: newState,
    [LOAD_DELETED_PRODUCTS]: newState,
    [EDIT_PRODUCT]: newState,
    [DELETE_PRODUCT]: deleteOrRestoreProduct,
    [HARD_DELETE_PRODUCT]: deleteOrRestoreProduct,
    [RESTORE_PRODUCT]: deleteOrRestoreProduct,
    [GET_PRODUCT_BY_ID]: newState,
    [LOAD_LIST_OF_PRODUCTS]: newState,
    [SET_HAS_MORE_ITEMS]: newState,
    [SELECT_PRODUCT_FOR_REPLACEMENT]: newState,
    [DESELECT_PRODUCT_FOR_REPLACEMENT]: newState,
    [RESET_PRODUCTS_STATE]: resetState,
    [SELECT_REPLACEMENT_PRODUCT_FOR_DELETION]: newState,
    [DESELECT_REPLACEMENT_PRODUCT_FOR_DELETION]: newState,
    [SEARCH_FOR_PRODUCTS]: newState,
    [CLEAR_SELECTED_REPLACEMENT_PRODUCTS]: newState,
    [CLEAR_SELECTED_REPLACEMENT_ITEMS_FOR_PRODUCT]: newState,
  };

  const currentState = stateActions[type];
  if (!currentState) {
    return state;
  }
  return currentState();
};

export default products;
