import { ReactElement } from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useModuleList } from 'modules/common';

export interface IBreadcrumb {
  name: string;
  link?: string;
}

interface IBreadcrumbList {
  breadcrumb?: Array<IBreadcrumb>;
}

const Breadcrumb = ({ breadcrumb = [] }: IBreadcrumbList): ReactElement => {
  const { t } = useModuleList();

  return (
    <Breadcrumbs aria-label="Breadcrumb">
      {breadcrumb?.map(({ name, link = '' }, index, arr) => {
        if (index === arr.length - 1 && name !== t('globals.home')) {
          return (
            <Typography variant="h3" fontWeight="500" key={`breadcrumb-${index}`} sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
              {name}
            </Typography>
          );
        }
        return (
          <Link
            variant="h3"
            fontWeight="400"
            to={link}
            component={NavLink}
            sx={{ color: 'rgba(0, 0, 0, 0.6)', ':hover': { textDecoration: 'none' } }}
            underline="hover"
            key={`breadcrumb-${index}`}>
            {name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
