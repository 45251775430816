import {
  ISpecificationItem,
  ISpecificationItemsAction,
  ISpecificationItemsState,
} from '../SpecificationItems/specificationItemsInterfaces';
import {
  IS_TABLE_LOADING,
  IS_CATALOG_LOADING,
  SEARCH_ITEM_SUCCESS,
  LOAD_PACKAGES_CATALOGS,
  LOAD_PRODUCTS_CATALOGS,
  LOAD_PIECE_WORK_TRADES,
  SELECT_SPECIFICATION_ITEM,
  LOAD_PIECE_WORK_CATEGORIES,
  DESELECT_SPECIFICATION_ITEM,
  LOAD_PIECE_WORK_RATE_CATALOGS,
  LOAD_SPECIFICATION_TABLE_ITEMS,
  LOAD_WORK_CATEGORIES,
  SELECT_PRODUCT_CATALOG,
  SELECT_WORK_CATALOG,
  SELECT_PACKAGE_CATALOG,
  CLEAR_ITEMS,
  CLEAR_SELECTED_ITEMS,
  SINGLE_SELECTED_ITEM,
  CLEAR_SINGLE_SELECTED_ITEM,
  LOAD_SPECIFICATION_ITEMS_CONFIG,
  UPDATE_SPECIFICATION_ITEMS_CONFIG,
  LOAD_SPECIFICATION_PRODUCT_IDS,
  REFRESH_ITEMS_LIST,
  LOAD_NSCODES_CATEGORIES,
  SELECT_NSCODE_CATEGORY,
  IS_CODES_LOADING,
  SEARCH_FOR_CODE_ITEMS,
  SELECT_CODE_ITEM,
  CLEAR_CODE_ITEMS,
  CLEAR_SEARCH_ITEMS,
  RESET_SPECIFICATION_ITEMS_STATE,
} from './specificationItemsActions';

const initialState: ISpecificationItemsState = {
  catalogs: [],
  isCatalogLoading: false,
  isTableLoading: false,
  itemsLastUpdate: 0,
  items: [],
  totalItems: 0,
  selectedItems: [],
  trades: [],
  pieceworkCategories: [],
  hits: [],
  totalHits: 0,
  productsCatalogs: [],
  workCatalogs: [],
  packagesCatalogs: [],
  nsCodeCategories: [],
  singleSelectedItem: null,
  specificationItemsConfigs: {
    specificationType: '',
    specificationCatalog: '',
    searchType: 'all',
    columnOption: '1',
    productsCatalogId: '',
    packagesCatalogId: '',
    workCategoriesCatalogId: '',
    nsCodesCatalogId: '',
  },
  products: [],
  isCodeLoading: false,
  codeItems: [],
  totalCodeItems: 0,
  selectedCodeItem: null,
};

const specificationItemsReducer = (state = initialState, { type, payload }: ISpecificationItemsAction) => {
  const newState = () => ({ ...state, ...payload });

  const specificationConfigUpdates = () => ({
    ...state,
    specificationItemsConfigs: { ...state.specificationItemsConfigs, ...payload },
  });

  const selectSpecificationItem = () => {
    const data = payload as ISpecificationItem;
    let selectedItems = JSON.parse(JSON.stringify(state.selectedItems)) as Array<ISpecificationItem>;
    const itemIndex = selectedItems.findIndex((item) => item.id === data.id);
    if (itemIndex !== -1) {
      selectedItems[itemIndex] = data;
    } else {
      selectedItems = [...selectedItems, data];
    }
    return {
      ...state,
      selectedItems,
    };
  };

  const deselectSpecificationItem = () => {
    const item = payload as ISpecificationItem;
    const selectedItems = state.selectedItems.filter(({ id }) => id !== item.id);
    return { ...state, selectedItems: [...selectedItems] };
  };

  const resetState = () => initialState;

  const stateActions = {
    [IS_TABLE_LOADING]: newState,
    [IS_CATALOG_LOADING]: newState,
    [SEARCH_ITEM_SUCCESS]: newState,
    [LOAD_PACKAGES_CATALOGS]: newState,
    [LOAD_PRODUCTS_CATALOGS]: newState,
    [LOAD_PIECE_WORK_TRADES]: newState,
    [LOAD_PIECE_WORK_CATEGORIES]: newState,
    [LOAD_PIECE_WORK_RATE_CATALOGS]: newState,
    [LOAD_SPECIFICATION_TABLE_ITEMS]: newState,
    [LOAD_WORK_CATEGORIES]: newState,
    [SELECT_PRODUCT_CATALOG]: newState,
    [SELECT_WORK_CATALOG]: newState,
    [SELECT_PACKAGE_CATALOG]: newState,
    [CLEAR_ITEMS]: newState,
    [CLEAR_SEARCH_ITEMS]: newState,
    [CLEAR_SELECTED_ITEMS]: newState,
    [SINGLE_SELECTED_ITEM]: newState,
    [CLEAR_SINGLE_SELECTED_ITEM]: newState,
    [UPDATE_SPECIFICATION_ITEMS_CONFIG]: newState,
    [LOAD_SPECIFICATION_PRODUCT_IDS]: newState,
    [REFRESH_ITEMS_LIST]: newState,
    [LOAD_NSCODES_CATEGORIES]: newState,
    [SELECT_NSCODE_CATEGORY]: newState,
    [IS_CODES_LOADING]: newState,
    [SEARCH_FOR_CODE_ITEMS]: newState,
    [SELECT_CODE_ITEM]: newState,
    [CLEAR_CODE_ITEMS]: newState,
    [LOAD_SPECIFICATION_ITEMS_CONFIG]: specificationConfigUpdates,
    [SELECT_SPECIFICATION_ITEM]: selectSpecificationItem,
    [DESELECT_SPECIFICATION_ITEM]: deselectSpecificationItem,
    [RESET_SPECIFICATION_ITEMS_STATE]: resetState,
  };

  const currentState = stateActions[type];
  if (!currentState) {
    return state;
  }
  return currentState();
};

export default specificationItemsReducer;
