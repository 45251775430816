import { FC } from 'react';

type RecentIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const RecentIcon: FC<RecentIconPropTypes> = ({ width = 56, height = 56, color = '#0673A3', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56.8417" height="56.8417" rx="12" fill="#F5F6F7"/>
    <path d="M36.6298 42.1048L36.6161 33.8943L31.1562 28.4207L36.6161 22.9334L36.6298 14.7367H20.209V22.9471L25.6826 28.4207L20.209 33.8806V42.1048H36.6298ZM22.9458 22.2629V17.4735H33.893V22.2629L28.4194 27.7365L22.9458 22.2629Z" fill={color}/>
    </svg>
  );
};

export default RecentIcon;
