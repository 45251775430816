import { FC } from 'react';

type EmptyStateActionCenterIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const EmptyStateActionCenterIcon: FC<EmptyStateActionCenterIconPropTypes> = ({
  width = 213,
  height = 191,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 213 191" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.7033 189.444H169.294C172.746 189.453 176.073 188.148 178.609 185.793C189.189 175.938 197.653 164.011 203.481 150.743C209.308 137.476 212.376 123.149 212.495 108.645C213.037 49.6672 165.382 1.13949 106.756 1.0003C48.0961 0.861038 0.50007 48.6582 0.50007 107.639C0.483322 122.319 3.4879 136.843 9.32463 150.297C15.1614 163.752 23.7038 175.844 34.4141 185.814C36.9449 188.159 40.2627 189.456 43.7033 189.444Z"
        fill="#EFF1F8"
      />
      <path
        d="M139.621 188.985H44.9992C43.8496 188.985 42.7113 188.759 41.6492 188.319C40.5871 187.879 39.6221 187.234 38.8092 186.422C37.9963 185.609 37.3515 184.644 36.9116 183.581C36.4717 182.519 36.2452 181.381 36.2452 180.232V29.2071C36.2452 26.8854 37.1675 24.6588 38.8092 23.0171C40.4509 21.3754 42.6775 20.4531 44.9992 20.4531H109.925L148.375 58.903V180.232C148.375 182.553 147.452 184.78 145.811 186.421C144.169 188.063 141.942 188.985 139.621 188.985Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="2.94444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.366 168.519H62.7445C61.7882 168.519 60.8413 168.331 59.9578 167.965C59.0744 167.599 58.2716 167.062 57.5955 166.386C56.9193 165.71 56.3829 164.907 56.017 164.024L54.6568 164.587L56.017 164.024C55.651 163.141 55.4627 162.194 55.4627 161.237V10.213C55.4627 9.25671 55.651 8.30982 56.017 7.42635C56.3829 6.54289 56.9193 5.74016 57.5955 5.06398C58.2717 4.38781 59.0744 3.85144 59.9579 3.4855C60.8413 3.11955 61.7882 2.93121 62.7445 2.93121H127.06L164.648 40.5187V161.237C164.648 162.194 164.459 163.141 164.094 164.024C163.728 164.907 163.191 165.71 162.515 166.386C161.839 167.063 161.036 167.599 160.153 167.965C159.269 168.331 158.322 168.519 157.366 168.519Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="2.94444"
      />
      <path
        d="M166.404 39.9089H136.708C134.386 39.9089 132.159 38.9866 130.518 37.3449C128.876 35.7032 127.954 33.4766 127.954 31.1549V1.45898L166.404 39.9089Z"
        fill="#0975A5"
      />
      <path
        d="M157.649 169.991H63.0279C61.8783 169.991 60.74 169.765 59.6779 169.325C58.6158 168.885 57.6508 168.24 56.8379 167.427C56.025 166.614 55.3802 165.649 54.9403 164.587C54.5004 163.525 54.2739 162.387 54.2739 161.237V10.213C54.2739 9.06337 54.5004 7.92504 54.9403 6.86296C55.3802 5.80088 56.025 4.83585 56.8379 4.02297C57.6508 3.21008 58.6158 2.56527 59.6779 2.12534C60.74 1.68541 61.8783 1.45898 63.0279 1.45898H127.954L166.403 39.9089V161.237C166.403 162.387 166.177 163.525 165.737 164.587C165.297 165.649 164.652 166.614 163.839 167.427C163.027 168.24 162.062 168.885 160.999 169.325C159.937 169.765 158.799 169.991 157.649 169.991Z"
        stroke="#D4D9E3"
        strokeWidth="1.96296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.2279 85.6532C99.0902 85.6532 102.221 82.5222 102.221 78.6599C102.221 74.7975 99.0902 71.6665 95.2279 71.6665C91.3655 71.6665 88.2345 74.7975 88.2345 78.6599C88.2345 82.5222 91.3655 85.6532 95.2279 85.6532Z"
        fill="#0975A5"
      />
      <path
        d="M124.998 85.6532C128.861 85.6532 131.992 82.5222 131.992 78.6599C131.992 74.7975 128.861 71.6665 124.998 71.6665C121.136 71.6665 118.005 74.7975 118.005 78.6599C118.005 82.5222 121.136 85.6532 124.998 85.6532Z"
        fill="#0975A5"
      />
      <path
        d="M132.374 111.828C131.794 111.829 131.22 111.715 130.684 111.493C130.148 111.271 129.661 110.945 129.251 110.535C124.172 105.467 117.289 102.62 110.113 102.62C102.938 102.62 96.0548 105.466 90.975 110.535C90.5649 110.945 90.0781 111.27 89.5422 111.492C89.0063 111.714 88.4319 111.829 87.8519 111.829C87.2718 111.829 86.6974 111.715 86.1615 111.493C85.6256 111.271 85.1387 110.945 84.7285 110.535C84.3183 110.125 83.993 109.638 83.771 109.102C83.5491 108.566 83.4349 107.992 83.4349 107.412C83.435 106.832 83.5493 106.257 83.7714 105.721C83.9934 105.186 84.3189 104.699 84.7291 104.289C91.4667 97.5663 100.596 93.7911 110.113 93.791C119.631 93.791 128.76 97.5662 135.497 104.288C136.115 104.906 136.536 105.693 136.706 106.55C136.877 107.407 136.789 108.295 136.455 109.102C136.121 109.909 135.554 110.599 134.828 111.084C134.102 111.569 133.248 111.828 132.374 111.828L132.374 111.828Z"
        fill="#0975A5"
      />
      <path
        d="M158.247 126.868L150.131 134.983L180.671 165.523L188.786 157.408L158.247 126.868Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="2.94444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.689 141.807L164.689 141.807C159.603 146.893 159.603 155.139 164.689 160.225L183.967 179.503C189.053 184.589 197.299 184.589 202.384 179.503L202.384 179.503C207.47 174.418 207.47 166.172 202.384 161.086L183.106 141.807C178.02 136.722 169.774 136.722 164.689 141.807Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="2.94444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.8695 46.7216C55.9258 55.6653 49.8351 67.0603 47.3675 79.4656C44.8999 91.8709 46.1664 104.729 51.0067 116.415C55.847 128.1 64.0438 138.088 74.5605 145.115C85.0772 152.142 97.4414 155.893 110.09 155.893C122.738 155.893 135.102 152.142 145.619 145.115C156.136 138.088 164.333 128.1 169.173 116.415C174.013 104.729 175.28 91.8709 172.812 79.4656C170.344 67.0603 164.254 55.6653 155.31 46.7216C149.372 40.7832 142.322 36.0726 134.563 32.8587C126.804 29.6449 118.488 27.9907 110.09 27.9907C101.692 27.9907 93.3756 29.6449 85.6167 32.8587C77.8578 36.0726 70.8079 40.7832 64.8695 46.7216ZM146.969 128.821C139.675 136.115 130.382 141.083 120.265 143.095C110.148 145.108 99.6608 144.075 90.1306 140.127C80.6004 136.18 72.4548 129.495 66.7239 120.918C60.993 112.341 57.9341 102.257 57.9341 91.9418C57.9341 81.6264 60.993 71.5426 66.7239 62.9657C72.4549 54.3887 80.6005 47.7038 90.1307 43.7563C99.6609 39.8087 110.148 38.7759 120.265 40.7883C130.382 42.8008 139.675 47.7681 146.969 55.0622C156.75 64.8433 162.245 78.1093 162.245 91.9418C162.245 105.774 156.75 119.04 146.969 128.821Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="2.94444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyStateActionCenterIcon;
