import { FC } from 'react';

type PackagesIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const PackagesIcon: FC<PackagesIconPropTypes> = ({ width = 48, height = 48, color = '#32226B', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 11.2C0 5.01441 5.01441 0 11.2 0H36.8C42.9856 0 48 5.01441 48 11.2V36.8C48 42.9856 42.9856 48 36.8 48H11.2C5.01441 48 0 42.9856 0 36.8V11.2Z" fill="#F3EEFE"/>
    <path d="M32 14H16C15 14 14 14.9 14 16V19.01C14 19.73 14.43 20.35 15 20.7V32C15 33.1 16.1 34 17 34H31C31.9 34 33 33.1 33 32V20.7C33.57 20.35 34 19.73 34 19.01V16C34 14.9 33 14 32 14ZM27 26H21V24H27V26ZM32 19H16V16L32 15.98V19Z" fill={color}/>
    </svg>
  );
};

export default PackagesIcon;
