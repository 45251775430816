import { Autocomplete, AutocompleteProps, styled } from '@mui/material';

type MuiAutocompleteProps<T> = {
  hasBorder?: boolean;
  bgColor?: string;
  error?: boolean;
} & AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>;

const renderBorder = (hasBorder: boolean, error: boolean) => {
  if (error) return '1px solid #CC2500';
  if (hasBorder) return '1px solid #efefef';
  if (!hasBorder) return 0;
  if (hasBorder && !error) return '1px solid #fefefe';
  return '1px solid #CC2500';
};
export const MuiAutoCompletedStyled = styled(Autocomplete, {
  shouldForwardProp: (props) => props !== 'hasBorder' && props !== 'bgColor' && props !== 'error',
})<MuiAutocompleteProps<any>>(({ hasBorder = false, bgColor = 'white', error = false }) => {
  return {
    ...{
      border: renderBorder(hasBorder, error),
      backgroundColor: bgColor,
      borderRadius: '10px',
      '.MuiOutlinedInput-root': {
        padding: 0,
      },
      input: {
        backgroundColor: bgColor,
        borderRadius: '10px',
        padding: '9px 14px',
      },
      fieldset: {
        border: '0',
      },
    },
  };
});
