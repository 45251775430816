import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

type InfiniteScrollingProps = {
  isLoading: boolean;
  hasMoreItems: boolean;
  pageNumber: any;
  searchedItems: any[];
  searchValueLength: number;
};

const useInfiniteScroll = ({
  isLoading,
  pageNumber,
  hasMoreItems,
  searchValueLength,
  searchedItems,
}: InfiniteScrollingProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPageRef = useRef(Number(pageNumber));
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    // if user starts searching for the first time, we need to make the page number 0
    if (searchValueLength > 0 && (!searchedItems || searchedItems.length === 0)) {
      const newSearchParams = new URLSearchParams(searchParams);
      currentPageRef.current = 0;
      newSearchParams.set('pageNumber', currentPageRef.current.toString());
      setSearchParams(newSearchParams);
      return;
    }
    if (inView && hasMoreItems && !isLoading) {
      const newSearchParams = new URLSearchParams(searchParams);
      currentPageRef.current += 1;
      newSearchParams.set('pageNumber', currentPageRef.current.toString());
      setSearchParams(newSearchParams);
    }
  }, [inView, hasMoreItems, isLoading, searchValueLength, setSearchParams]);

  return { ref };
};

export default useInfiniteScroll;
