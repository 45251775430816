import { LoadingButton } from '@mui/lab';

import { styled } from '@mui/material';

import { TButtonProps } from './Button';

export const StyledContainer = styled(LoadingButton)<TButtonProps>`
  height: 100%;
  box-shadow: none;
  position: relative;
  transition: all 0.3s;
  border-radius: 8px;
  font-weight: bold;
  ${(props) => {
    switch (props.size) {
      case 'large':
        return `padding: ${props.theme.spacing(1.5)} ${props.theme.spacing(7)}; font-size: 24px;`;
      case 'medium':
        return `padding: ${props.theme.spacing(1.5)} ${props.theme.spacing(4.5)}; font-size: 16px;`;
      case 'small':
        return `padding: ${props.theme.spacing(1)} ${props.theme.spacing(3)}; font-size: 13px;`;
      default:
        return `padding: ${props.theme.spacing(1.5)} ${props.theme.spacing(3)}; font-size: 14px;`;
    }
  }}
  &:hover {
    box-shadow: none;
  }
`;
