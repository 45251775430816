import i18n from 'i18next';
import API from 'utils/API';
import { AxiosResponse } from 'axios';
import { IVendor, IVendorItemResponse, IEditVendorProperties, ISearchResult } from '../vendorsInterfaces';
import { IUpdateCompany } from 'modules/Companies/companiesInterfaces';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { updateCompanyItem } from 'modules/Companies/redux/companiesActions';
import { updatePersonItem } from 'modules/Persons/redux/personsActions';
import {
  IS_VENDORS_LOADING_ACTION,
  IS_VENDORS_DIALOG_LOADING_ACTION,
  LOAD_ALL_VENDORS_ACTION,
  DELETE_VENDOR_ACTION,
  LOAD_DELETED_VENDORS_ACTION,
  RESTORE_VENDOR_ACTION,
  CREATE_VENDOR_ACTION,
  EDIT_VENDOR_ACTION,
  LOAD_VENDOR_ITEM_ACTION,
  ADD_ERROR_MESSAGE_ACTION,
} from './types';
import { IUpdatePerson } from 'modules/Persons/personsInterfaces';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const LOAD_ALL_VENDORS = 'LOAD_ALL_VENDORS';
export const LOAD_VENDOR_ITEM = 'LOAD_VENDOR_ITEM';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const HARD_DELETE_VENDOR = 'HARD_DELETE_VENDOR';
export const IS_VENDORS_LOADING = 'IS_VENDORS_LOADING';
export const IS_VENDORS_DIALOG_LOADING = 'IS_VENDORS_DIALOG_LOADING';
export const LOAD_DELETED_VENDORS = 'LOAD_DELETED_VENDORS';
export const RESTORE_VENDOR = 'RESTORE_VENDOR';
export const CREATE_VENDOR = 'CREATE_VENDOR';
export const EDIT_VENDOR = 'EDIT_VENDOR';

const setIsLoading = (isLoading: boolean): IS_VENDORS_LOADING_ACTION => ({ type: IS_VENDORS_LOADING, isLoading });

const setIsDialogLoading = (isDialogLoading: boolean): IS_VENDORS_DIALOG_LOADING_ACTION => ({
  type: IS_VENDORS_DIALOG_LOADING,
  isDialogLoading,
});

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const getVendorItemAction = (vendor: IVendor): LOAD_VENDOR_ITEM_ACTION => ({
  type: LOAD_VENDOR_ITEM,
  vendor,
});

export const loadAllVendorsSuccessAction = (vendors: [IVendor], totalItems: number): LOAD_ALL_VENDORS_ACTION => ({
  type: LOAD_ALL_VENDORS,
  vendors,
  totalItems,
});

export const deleteVendorSuccessAction = (vendorItem: IVendor): DELETE_VENDOR_ACTION => ({
  vendorItem,
  type: DELETE_VENDOR,
});

export const loadDeletedVendorsSuccessAction = (deletedVendors: [IVendor]): LOAD_DELETED_VENDORS_ACTION => ({
  type: LOAD_DELETED_VENDORS,
  deletedVendors,
});

export const restoreVendorSuccessAction = (vendorItem: IVendor): RESTORE_VENDOR_ACTION => ({
  type: RESTORE_VENDOR,
  vendorItem,
});

export const createVendorSuccess = (vendorItem: IVendor): CREATE_VENDOR_ACTION => ({ type: CREATE_VENDOR, vendorItem });

export const editVendorSuccessAction = (vendorItem: IVendor): EDIT_VENDOR_ACTION => ({ type: EDIT_VENDOR, vendorItem });

export const loadAllVendors = () => {
  return async (dispatch: Function) => {
    try {
      const URL = `/crm/api/vendors`;
      dispatch(setIsLoading(true));
      const dataPromise = API.get(URL);
      const {
        data: { payload },
      } = await dataPromise;
      dispatch(loadAllVendorsSuccessAction(payload, payload.length));
    } catch (e) {
      const message = i18n.t('vendors.errorLoadingVendors');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteVendor = (id: string, hardDelete = false) => {
  return async (dispatch: Function) => {
    try {
      hardDelete ? dispatch(setIsDialogLoading(true)) : dispatch(setIsLoading(true));
      const URL = `/crm/api/vendors/${id}?hardDelete=${hardDelete}`;
      const {
        data: { payload },
      } = await API.delete(URL);
      const message = i18n.t('vendors.successDeletingVendor');
      dispatch(addSuccessMessage({ message }));
      dispatch(deleteVendorSuccessAction(payload));
      if (!hardDelete) {
        dispatch(loadDeletedVendors());
      }
    } catch (e) {
      const message = i18n.t('vendors.errorDeletingVendor');
      dispatch(addErrorMessage({ message }));
    } finally {
      hardDelete ? dispatch(setIsDialogLoading(false)) : dispatch(setIsLoading(false));
    }
  };
};

export const loadDeletedVendors = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/crm/api/vendors/deleted`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadDeletedVendorsSuccessAction(payload));
    } catch (e) {
      const message = i18n.t('vendors.errorLoadingDeletedVendors');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const restoreVendor = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/crm/api/vendors/deleted/${id}`;
      const {
        data: { payload },
      } = await API.put(URL);
      dispatch(restoreVendorSuccessAction(payload));
      const message = i18n.t('vendors.successRestoringVendor');
      dispatch(addSuccessMessage({ message }));
      dispatch(loadAllVendors());
    } catch (e) {
      const message = i18n.t('vendors.errorRestoringVendor');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const createVendor = (data: IVendor, callback: Function) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/crm/api/vendors`;
      const {
        data: { payload },
      } = await API.post(URL, data);
      const message = i18n.t('vendors.successCreatingVendor');
      callback();
      dispatch(addSuccessMessage({ message }));
      dispatch(createVendorSuccess(payload));
    } catch (e) {
      const message = i18n.t('vendors.errorCreatingVendor');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const editVendor = (
  vendorId: string,
  companyPersonId: string,
  updatedVendor: IEditVendorProperties,
  isCompany: boolean
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/crm/api/vendors/${vendorId}`;
      const {
        data: { payload },
      } = await API.put(URL, updatedVendor);
      const message = i18n.t('vendors.successUpdatingVendor');
      dispatch(addSuccessMessage({ message }));
      const updatedVendorType = {
        country: updatedVendor.country,
        address: updatedVendor.address,
        postalCode: updatedVendor.postalCode,
      };
      if (isCompany) {
        await dispatch(updateCompanyItem(companyPersonId, updatedVendorType as IUpdateCompany));
      } else {
        await dispatch(updatePersonItem(companyPersonId, updatedVendorType as IUpdatePerson));
      }
      dispatch(getVendorItem(vendorId));
      dispatch(editVendorSuccessAction(payload));
    } catch (e) {
      const message = i18n.t('vendors.errorUpdatingVendor');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getVendorItem = (vendorId: string) => {
  const getVendorUrl = `crm/api/vendors/${vendorId}`;
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const getVendorPromise = API.get(getVendorUrl);
      const {
        data: { payload: vendor },
      }: AxiosResponse<IVendorItemResponse> = await getVendorPromise;
      dispatch(getVendorItemAction(vendor));
    } catch (error) {
      const message = i18n.t('vendors.errorFetchingVendor');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForVendors = (query: string, params: IParams = { pageNumber: 0, pageSize: 15 }) => {
  const searchForVendorsUrl = `search/api/vendors/autocomplete`;
  const searchFields = {
    query,
    ...params,
    // TODO: doesn't work for: customerNumber, contactFirstname, contactLastname
    fields: [
      'ourcustomernumber',
      'organizationnumber',
      'description',
      'name',
      'postalCode',
      'postalAddress',
      'address',
    ],
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForVendorsPromise = API.post(searchForVendorsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForVendorsPromise;
      dispatch(loadAllVendorsSuccessAction(hits, totalHits));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
