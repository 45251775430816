import type { PaletteRange } from '@mui/joy/styles';
import { SnackbarProvider } from 'notistack';

import { PaletteMode, PaletteOptions, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ColorSystemOptions, createTheme as createMaterialTheme } from '@mui/material/styles';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { ReactElement, ReactNode } from 'react';

import ModeToggle from './ModeToggler';
import SmartcraftThemeProvider from './SmartCraftThemeProvider';
import { lightPalette, darkPalette } from './palette';
import typography from './typography';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    secondary: PaletteRange;
  }
}

declare module '@mui/joy/styles' {
  interface ColorSchemeOverrides {
    cordelDark: true;
    cordelLight: true;
  }

  interface ColorSchemeOptions {
    cordelDark: ColorSystemOptions;
    cordelLight: ColorSystemOptions;
  }
}

export const materialTheme = materialExtendTheme({
  typography,
  colorSchemes: {
    light: {
      palette: lightPalette,
    },
    dark: {
      palette: darkPalette,
    },
  },
});

interface AppThemeProps {
  children: ReactNode;
}
export type PaletteColorKey = keyof Theme['palette'];
declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Roboto: React.CSSProperties;
    h2Roboto: React.CSSProperties;
    h3Roboto: React.CSSProperties;
    h4Roboto?: React.CSSProperties;
    subtitle1Roboto?: React.CSSProperties;
    subtitle2Roboto?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Roboto?: React.CSSProperties;
    h2Roboto?: React.CSSProperties;
    h3Roboto?: React.CSSProperties;
    h4Roboto?: React.CSSProperties;
    subtitle1Roboto?: React.CSSProperties;
    subtitle2Roboto?: React.CSSProperties;
    body2Roboto?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Roboto: true;
    h2Roboto: true;
    h3Roboto: true;
    h4Roboto: true;
    subtitle1Roboto: true;
    subtitle2Roboto: true;
    body2Roboto: true;
    caption2: true;
  }
}

const getDesignTokens = (mode: PaletteMode): PaletteOptions => {
  if (mode === 'light') return lightPalette;
  return darkPalette;
};

export const createAppTheme = (mode: PaletteMode) =>
  createMaterialTheme({
    typography,
    palette: getDesignTokens(mode),
  });

export const AppTheme = (props: AppThemeProps): ReactElement => {
  const { children } = props;

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <SmartcraftThemeProvider>
        <ModeToggle>
          <CssBaseline enableColorScheme />
          <SnackbarProvider maxSnack={5}>{children}</SnackbarProvider>
        </ModeToggle>
      </SmartcraftThemeProvider>
    </MaterialCssVarsProvider>
  );
};

export default AppTheme;
