import { FC } from 'react';

type SquaresPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Squares: FC<SquaresPropTypes> = ({ width = 33, height = 34, color = '#0673A3', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.13135 18.5139H15.0786V4.82983H4.13135V18.5139ZM4.13135 29.4611H15.0786V21.2507H4.13135V29.4611ZM17.8154 29.4611H28.7626V15.7771H17.8154V29.4611ZM17.8154 4.82983V13.0403H28.7626V4.82983H17.8154Z"
        fill={color}
      />
    </svg>
  );
};

export default Squares;
