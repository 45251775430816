import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import store from 'redux/store';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';

import {
  CreateCRMCompanyForm,
  CompanyItem,
  GetCompanyResponse,
  UpdatePhone,
  UpdateEmail,
  CompanyContactItem,
  CompanyContactItemResponse,
  ContactPersonItem,
} from 'modules/CRM/CRMInterfaces';

import API from 'utils/API';
import { isNumeric } from 'utils/helpers';
import { ExternalSearchedCompany, ExternalSearchedCompanyResponse } from 'modules/Companies/companiesInterfaces';

export const searchForExternalCompaniesbyName = async (query: string): Promise<ExternalSearchedCompany[]> => {
  // We are searching only first 50 always, because this API is used only for dropdowns.
  const search = isNumeric(query) ? `organisasjonsnummer=${query}` : `navn=${query}`;
  const response = await API.get(`externalsearch/api/companyInfo/search?${search}&pageSize=50&pageNumber=0`);
  const { data }: AxiosResponse<ExternalSearchedCompanyResponse> = response;
  return data.payload;
};

export const getCompanyById = async (companyId: string): Promise<CompanyItem> => {
  const response = await API.get(`crm/api/companies/${companyId}`);
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};

export const createCompany = async (company: CreateCRMCompanyForm): Promise<CompanyItem> => {
  const response = await API.post('crm/api/companies', company);
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};

type UpdateCompanyVariables = {
  companyId: string;
  company: CreateCRMCompanyForm;
};

export const updateCompany = async ({ companyId, company }: UpdateCompanyVariables): Promise<CompanyItem> => {
  const response = await API.put(`crm/api/companies/${companyId}`, company);
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};

export const deleteCompany = async (companyId: string): Promise<CompanyItem> => {
  const response = await API.delete(`crm/api/companies/${companyId}`);
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};

type AddCompanyEmailAddressVariables = {
  companyId: string;
  newEmail: UpdateEmail;
};

type UpdateCompanyEmailVariables = {
  emailId: string;
} & AddCompanyEmailAddressVariables;
export const addCompanyEmailAddress = async ({
  companyId,
  newEmail,
}: AddCompanyEmailAddressVariables): Promise<CompanyItem> => {
  const response = await API.post(`crm/api/companies/${companyId}/emailAddresses`, { newEmail });
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};

export const updateCompanyEmailAddress = async ({
  companyId,
  emailId,
  newEmail,
}: UpdateCompanyEmailVariables): Promise<CompanyItem> => {
  console.log('updaing email', newEmail);
  const response = await API.put(`crm/api/companies/${companyId}/emailAddresses/${emailId}`, { newEmail });
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};

type AddCompanyPhoneVariables = {
  companyId: string;
  newPhone: UpdatePhone;
};
type UpdateCompanyPhoneVariables = {
  phoneId: string;
} & AddCompanyPhoneVariables;
export const addCompanyPhoneNumber = async ({
  companyId,
  newPhone,
}: AddCompanyPhoneVariables): Promise<CompanyItem> => {
  const response = await API.post(`crm/api/companies/${companyId}/telephoneNumbers`, { newPhone });
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};
export const updateCompanyPhoneNumber = async ({
  companyId,
  phoneId,
  newPhone,
}: UpdateCompanyPhoneVariables): Promise<CompanyItem> => {
  const response = await API.put(`crm/api/companies/${companyId}/telephoneNumbers/${phoneId}`, { newPhone });
  const { data }: AxiosResponse<GetCompanyResponse> = response;
  return data.payload;
};
type GetCompanyContactVariables = {
  companyId: string;
  pageSize: number;
  pageNumber: number;
};
export const getCompanyContacts = async ({
  companyId,
  pageNumber,
  pageSize,
}: GetCompanyContactVariables): Promise<CompanyContactItem[]> => {
  const response = API.get(`crm/api/companies/${companyId}/contacts?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  const { data }: AxiosResponse<CompanyContactItemResponse> = await response;
  return data.payload;
};
type UpdateCompanyContactVariables = {
  companyId: string;
  contactId: string;
  contact: ContactPersonItem;
};
export const updateCompanyContact = async ({
  companyId,
  contactId,
  contact,
}: UpdateCompanyContactVariables): Promise<CompanyContactItem[]> => {
  const response = API.put(`crm/api/companies/${companyId}/contacts/${contactId}`, contact);
  const { data }: AxiosResponse<CompanyContactItemResponse> = await response;
  return data.payload;
};

type DeleteCompanyContactVariables = {
  companyId: string;
  contactId: string;
};
export const deleteCompanyContact = async ({
  companyId,
  contactId,
}: DeleteCompanyContactVariables): Promise<CompanyContactItem[]> => {
  const response = API.delete(`crm/api/companies/${companyId}/contacts/${contactId}`);
  const { data }: AxiosResponse<CompanyContactItemResponse> = await response;
  return data.payload;
};

type AddContactToCompanyVariables = {
  companyId: string;
  personId: string;
  role: string;
  location: string;
};
const addContactToCompany = async ({
  companyId,
  personId,
  role,
  location,
}: AddContactToCompanyVariables): Promise<CompanyItem> => {
  const response = API.post(`crm/api/companies/${companyId}/contacts`, { personId, role, location });
  const { data }: AxiosResponse<GetCompanyResponse> = await response;
  return data.payload;
};

export const useSearchExternalCompanies = (query: string) =>
  useQuery({
    queryKey: ['externalCompanies', query],
    queryFn: () => searchForExternalCompaniesbyName(query),
    staleTime: 300000,
    retry: 3,
    retryDelay: 3000,
    enabled: false,
  });

export const useGetCompanyById = (companyId: string) =>
  useQuery({
    queryKey: ['company', companyId],
    queryFn: () => getCompanyById(companyId),
    staleTime: 300000,
    retry: 3,
    retryDelay: 3000,
  });

export const useCreateCompany = () =>
  useMutation(createCompany, {
    onSuccess: () => {
      const message = i18n.t('companies.successAddCompany');
      store.dispatch(addSuccessMessage({ message }));
    },
    onError: (error) => {
      const message = i18n.t('companies.failAdddCompany');
      store.dispatch(addErrorMessage({ message }));
    },
  });
export const useUpdateCompany = (queryClient: QueryClient, refetch?: string[]) =>
  useMutation(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(refetch || ['']);
    },
  });

export const useDeleteCompany = (queryClient: QueryClient) =>
  useMutation(deleteCompany, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['crmContacts']);
      }, 1000);
    },
  });

export const useAddCompanyEmailAddress = () => useMutation(addCompanyEmailAddress);
export const useUpdateCompanyEmailAddress = () => useMutation(updateCompanyEmailAddress);

export const useAddCompanyPhoneNumber = () => useMutation(addCompanyPhoneNumber);
export const useUpdateCompanyPhoneNumber = () => useMutation(updateCompanyPhoneNumber);

export const useGetCompanyContacts = ({ companyId, pageNumber = 0, pageSize = 100 }: GetCompanyContactVariables) =>
  useQuery({
    queryKey: ['companyContacts', companyId, pageNumber],
    queryFn: () => getCompanyContacts({ companyId, pageNumber, pageSize }),
    staleTime: 300000,
    retry: 3,
    retryDelay: 3000,
  });

export const useUpdateCompanyContact = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(updateCompanyContact, {
    onSuccess: () => {
      const message = i18n.t('crm.successUpdateContact');
      store.dispatch(addSuccessMessage({ message }));
      queryClient.invalidateQueries(['company', ...refetch!]);
      queryClient.invalidateQueries(['companyContacts', ...refetch!]);
      setTimeout(() => {
        onSuccess?.();
      }, 500);
    },
    onError: (error: any) => {
      const failUpdateContact = i18n.t('crm.failUpdateContact');
      store.dispatch(addErrorMessage({ message: failUpdateContact }));
      onError?.(error);
    },
  });
export const useDeleteCompanyContact = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(deleteCompanyContact, {
    onSuccess: () => {
      const message = i18n.t('crm.successDeleteContact');
      store.dispatch(addSuccessMessage({ message }));
      queryClient.invalidateQueries(['company', ...refetch!]);
      queryClient.invalidateQueries(['companyContacts', ...refetch!]);
      setTimeout(() => {
        onSuccess?.();
      }, 500);
    },
    onError: (error: any) => {
      const failDeleteContact = i18n.t('crm.failDeleteContact');
      store.dispatch(addErrorMessage({ message: failDeleteContact }));
      onError?.(error);
    },
  });

export const useAddContactToCompany = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(addContactToCompany, {
    onSuccess: () => {
      const message = i18n.t('crm.successCreateContact');
      store.dispatch(addSuccessMessage({ message }));
      queryClient.invalidateQueries(refetch || ['']);
      queryClient.invalidateQueries(['company', ...refetch!]);
      queryClient.invalidateQueries(['companyContacts', ...refetch!]);
      onSuccess?.();
    },
    onError: (error: any) => {
      const failAddContact = i18n.t('crm.failCreateContact');
      store.dispatch(addErrorMessage({ message: error.response.data.message || failAddContact }));
      onError?.();
    },
  });
