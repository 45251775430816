import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import {
  SET_OFFERS_LOADER_ACTION,
  SET_DIALOG_LOADER_ACTION,
  LOAD_ALL_OFFERS_SUCCESS_ACTION,
  ADD_ERROR_MESSAGE_ACTION,
  SAVE_DELETED_OFFERS_ACTION,
  SAVE_SEARCHED_OFFERS_ACTION,
  SAVE_STD_PARAMETERS_ACTION,
  SAVE_OFFER_ITEM_ACTION,
  UPDATE_OFFER_ITEM_ACTION,
  SAVE_OFFERS_TOTAL_ACTION,
  SAVE_OFFERS_COUNT_ACTION,
  SET_OFFER_ITEM_LOADER_ACTION,
  SET_HAS_MORE_ITEMS_ACTION,
  SAVE_FILTER_OFFERS_TAGS_ACTION,
  SAVE_OFFER_RESPONSIBLE_PERSON_ACTION,
  SAVE_SEARCHED_USERS_ACTION,
  SET_ACTIVE_VERSION_ID_ACTION,
  SAVE_OFFER_VERSIONS_ACTION,
  SAVE_OFFER_CATEGORIES_ACTION,
  SAVE_OFFER_CHOSEN_FILTERS_ACTION,
  DELETE_OFFER_ITEM_ACTION,
  ADD_NEW_OFFER_ACTION,
} from './types';

import {
  IParams,
  IOfferItem,
  IOffersResponse,
  ISearchResult,
  ICreateOfferItem,
  IStdParameter,
  IStdParameterResponse,
  IOfferItemResponse,
  IUpdateOfferItem,
  IOffersTotal,
  IOffersTotalResponse,
  IOfferFilterTag,
  IOfferFilterTagResponse,
  IOfferFilterParameters,
  ICreateOfferResponse,
  IOfferVersionsResponse,
  IOfferVersionItem,
  IOfferCategory,
  IOfferCategoriesResponse,
  OfferFilters,
} from '../offersInterfaces';
import { deselectSelectedNode } from './offerSpecificationActions';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { ICreateCurrencyRate } from 'modules/OfferSettings/OfferSettingsView/CurrencyForIncome';
import { IUserInfo, IUserInfoResponse, IUserSearch, IUserSearchResponse } from 'modules/User/userInterfaces';
import API from 'utils/API';
import { IS_BD_O_HYWER_TENANT } from 'utils/authentication/RoleWrapper';
import { SYSTEM_ADMIN_ID } from 'utils/uuidFunctions';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

export const SET_OFFERS_LOADER = 'SET_OFFERS_LOADER';
export const SET_OFFER_ITEM_LOADER = 'SET_OFFER_ITEM_LOADER';
export const SET_DIALOG_LOADER = 'SET_DIALOG_LOADER';
export const LOAD_ALL_OFFERS_SUCCESS = 'LOAD_ALL_OFFERS_SUCCESS';

export const SAVE_DELETED_OFFERS = 'SAVE_DELETED_OFFERS';

export const SAVE_SEARCHED_OFFERS = 'SAVE_SEARCHED_OFFERS';

export const SAVE_STD_PARAMETERS = 'SAVE_STD_PARAMETERS';

export const SAVE_OFFER_ITEM = 'SAVE_OFFER_ITEM';
export const UPDATE_OFFER_ITEM = 'UPDATE_OFFER_ITEM';
export const SAVE_OFFERS_TOTAL = 'SAVE_OFFERS_TOTAL';

export const SAVE_OFFERS_COUNT = 'SAVE_OFFERS_COUNT';

export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';

export const SAVE_FILTER_OFFERS_TAGS = 'SAVE_FILTER_OFFERS_TAGS';

export const SAVE_OFFER_CATEGORIES = 'SAVE_OFFER_CATEGORIES';

export const SAVE_OFFER_RESPONSIBLE_PERSON = 'SAVE_OFFER_RESPONSIBLE_PERSON';

export const SAVE_SEARCHED_USERS = 'SAVE_SEARCHED_USERS';

export const SET_ACTIVE_VERSION_ID = 'SET_ACTIVE_VERSION_ID';

export const SAVE_OFFER_VERSIONS = 'SAVE_OFFER_VERSIONS';

export const SAVE_OFFER_CHOSEN_FILTERS = 'SAVE_OFFER_CHOSEN_FILTERS';

export const DELETE_OFFER_ITEM = 'DELETE_OFFER_ITEM';

export const ADD_NEW_OFFER = 'ADD_NEW_OFFER';

export const RESET_OFFERS_STATE = 'RESET_OFFERS_STATE';

export const resetOffersState = () => ({ type: RESET_OFFERS_STATE });

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const setOffersLoader = (isLoading: boolean): SET_OFFERS_LOADER_ACTION => ({
  type: SET_OFFERS_LOADER,
  isLoading,
});
export const setOfferItemLoader = (isLoading: boolean): SET_OFFER_ITEM_LOADER_ACTION => ({
  type: SET_OFFER_ITEM_LOADER,
  isLoading,
});
export const setDialogLoader = (isDialogLoading: boolean): SET_DIALOG_LOADER_ACTION => ({
  type: SET_DIALOG_LOADER,
  isDialogLoading,
});

export const setActiveVersionId = (activeVersionId: string): SET_ACTIVE_VERSION_ID_ACTION => ({
  type: SET_ACTIVE_VERSION_ID,
  activeVersionId,
});

export const saveAllOffersAction = (
  allOffers: IOfferItem[] | [],
  totalOffersNumber: number,
  overWrite?: boolean
): LOAD_ALL_OFFERS_SUCCESS_ACTION => ({
  type: LOAD_ALL_OFFERS_SUCCESS,
  allOffers,
  totalOffersNumber,
  overWrite,
});

export const saveOfferCountAction = (totalOffersNumber: number): SAVE_OFFERS_COUNT_ACTION => ({
  type: SAVE_OFFERS_COUNT,
  totalOffersNumber,
});

export const saveDeletedOffersAction = (
  deletedOffers: IOfferItem[],
  totalDeletedOffersNumber: number
): SAVE_DELETED_OFFERS_ACTION => ({
  type: SAVE_DELETED_OFFERS,
  deletedOffers,
  totalDeletedOffersNumber,
});

export const saveSearchedOffersAction = (
  searchResult: ISearchResult | null,
  overWrite = false
): SAVE_SEARCHED_OFFERS_ACTION => ({
  type: SAVE_SEARCHED_OFFERS,
  searchResult,
  overWrite,
});

export const saveDeletedCompaniesAction = (
  deletedOffers: IOfferItem[],
  totalDeletedOffersNumber: number
): SAVE_DELETED_OFFERS_ACTION => ({
  type: SAVE_DELETED_OFFERS,
  deletedOffers,
  totalDeletedOffersNumber,
});

export const saveStdParameters = (stdParameters: IStdParameter[] | null): SAVE_STD_PARAMETERS_ACTION => ({
  type: SAVE_STD_PARAMETERS,
  stdParameters,
});

export const saveOfferItem = (offerItem: IOfferItem | null): SAVE_OFFER_ITEM_ACTION => ({
  type: SAVE_OFFER_ITEM,
  offerItem,
});

export const updateOfferItemAction = (offerItem: IOfferItem | null): UPDATE_OFFER_ITEM_ACTION => ({
  type: UPDATE_OFFER_ITEM,
  offerItem,
});

export const saveOffersTotal = (
  offersTotal: IOffersTotal | null,
  offerVersionId: string
): SAVE_OFFERS_TOTAL_ACTION => ({
  type: SAVE_OFFERS_TOTAL,
  offersTotal,
  offerVersionId,
});

export const setHasMoreItems = (hasMoreItems: boolean): SET_HAS_MORE_ITEMS_ACTION => ({
  type: SET_HAS_MORE_ITEMS,
  hasMoreItems,
});

export const saveOfferFilterTags = (tags: IOfferFilterTag[]): SAVE_FILTER_OFFERS_TAGS_ACTION => ({
  type: SAVE_FILTER_OFFERS_TAGS,
  tags,
});

export const saveOfferCategories = (categories: IOfferCategory[]): SAVE_OFFER_CATEGORIES_ACTION => ({
  type: SAVE_OFFER_CATEGORIES,
  categories,
});

export const saveSearchedUsers = (users: IUserSearch[]): SAVE_SEARCHED_USERS_ACTION => ({
  type: SAVE_SEARCHED_USERS,
  users,
});

export const saveOfferResponsiblePerson = (
  offerResponsiblePerson: IUserInfo | null
): SAVE_OFFER_RESPONSIBLE_PERSON_ACTION => ({
  type: SAVE_OFFER_RESPONSIBLE_PERSON,
  offerResponsiblePerson,
});

export const saveOfferVersions = (offerVersions: IOfferVersionItem[]): SAVE_OFFER_VERSIONS_ACTION => ({
  type: SAVE_OFFER_VERSIONS,
  offerVersions,
});

export const saveOfferChosenFilters = (chosenFilters: OfferFilters | null): SAVE_OFFER_CHOSEN_FILTERS_ACTION => ({
  type: SAVE_OFFER_CHOSEN_FILTERS,
  chosenFilters,
});

export const deleteOfferItemAction = (offerId: string): DELETE_OFFER_ITEM_ACTION => ({
  type: DELETE_OFFER_ITEM,
  offerId,
});

export const addNewOfferItem = (offerItem: IOfferItem): ADD_NEW_OFFER_ACTION => ({
  type: ADD_NEW_OFFER,
  offerItem,
});

const useExternalReference = IS_BD_O_HYWER_TENANT();

export const loadAndSearchOffers = (query: string, searchParams: OfferFilters, overWrite?: boolean) => {
  const pageNumber = searchParams?.pageNumber || 0;
  const pageSize = searchParams?.pageSize || 10;
  const fetchAndSearchoffersUrl = `/search/api/offers/autocomplete`;
  const customerNumber = useExternalReference ? 'customerexternalreference' : 'customerNumber';
  const reqBody = {
    query: query ? query : null,
    ...searchParams,
    pageNumber,
    pageSize,
    deleted: false,
    descending: true,
    fields: query
      ? [customerNumber, 'name', 'number', 'customername', 'sitename', 'description', 'responsibleuser', 'tags']
      : null,
  };

  return async (dispatch: Function) => {
    dispatch(setOfferItemLoader(true));
    try {
      const searchForOffersPromise = API.post(fetchAndSearchoffersUrl, { ...reqBody });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForOffersPromise;
      if (hits.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));

      dispatch(saveAllOffersAction(hits, totalHits, overWrite));
    } catch (error) {
      const message = i18n.t('offers.errorSearchingForOffer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setOfferItemLoader(false));
    }
  };
};

export const loadAndSearchOffersForCopyNodes = (query: string, searchParams: OfferFilters, overWrite?: boolean) => {
  const pageNumber = searchParams?.pageNumber || 0;
  const pageSize = searchParams?.pageSize || 10;
  const fetchAndSearchoffersUrl = `/search/api/offers/autocomplete`;
  const customerNumber = useExternalReference ? 'customerexternalreference' : 'customerNumber';
  const reqBody = {
    query: query ? query : null,
    ...searchParams,
    pageNumber,
    pageSize,
    deleted: false,
    descending: true,
    fields: query ? [customerNumber, 'name', 'number', 'customername', 'tags'] : null,
  };

  return async (dispatch: Function) => {
    dispatch(setOfferItemLoader(true));
    try {
      const searchForOffersPromise = API.post(fetchAndSearchoffersUrl, { ...reqBody });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForOffersPromise;
      if (hits.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));

      dispatch(saveAllOffersAction(hits, totalHits, overWrite));
    } catch (error) {
      const message = i18n.t('offers.errorSearchingForOffer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setOfferItemLoader(false));
    }
  };
};

export const loadAllOffers = (catalogId: string | null, params?: IParams | null, overWrite?: boolean) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;
  const countUrl = '/offer/api/offers/count';
  let allOffersURL = `/offer/api/offers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (catalogId) {
    allOffersURL = `/offer/api/offers?catalogId=${catalogId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }
  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const countPromise = API.get(countUrl);
      const allOffersData = API.get(allOffersURL);
      const {
        data: { payload: offers },
      }: AxiosResponse<IOffersResponse> = await allOffersData;
      const {
        data: { payload: totalOffersNumber },
      } = await countPromise;
      if (offers.length === 0 || offers.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));

      dispatch(saveAllOffersAction(offers, totalOffersNumber, overWrite));
      // We nullify the searched items, so we can accuratly switch between pageNumber for searchOffers and loadOffers
      dispatch(saveSearchedOffersAction(null));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const loadOfferCount = () => {
  const countUrl = '/offer/api/offers/count';
  return async (dispatch: Function) => {
    try {
      const {
        data: { payload: totalOffersNumber },
      } = await API.get(countUrl);
      dispatch(saveOfferCountAction(totalOffersNumber));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    }
  };
};

export const addOffer = (offer: ICreateOfferItem, callback: Function) => {
  const addOffrUrl = '/offer/api/offers';

  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const {
        data: { payload: offerItem },
      }: AxiosResponse<ICreateOfferResponse> = await API.post(addOffrUrl, { ...offer });
      dispatch(loadAllOffers(null, { pageSize: 10, pageNumber: 0 }, true));
      if (offerItem.versionId) dispatch(updateCurrencyRates(offerItem.id, offerItem.versionId));
      callback?.(offerItem.id);
      const message = i18n.t('offers.successAddOffer');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      const message = i18n.t('offers.failAdddOffer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const deleteOffer = (offerItem: IOfferItem, isPermament: boolean = false) => {
  const deleteOfferUrl = `/offer/api/offers/${offerItem.id}?hardDelete=${isPermament}`;
  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const response = API.delete(deleteOfferUrl, { data: offerItem.id });
      const data = await response;
      if (!data) {
        dispatch(addErrorMessage({ message: 'Something unexpected happened' }));
        return;
      }
      const message = i18n.t('offers.successDeleteOffer');
      setTimeout(() => {
        dispatch(deleteOfferItemAction(offerItem.id));
      }, 1500);
      setTimeout(() => {
        dispatch(addSuccessMessage({ message }));
      }, 1000);
      // dispatch(loadDeletedOffers());
    } catch (error) {
      const message = i18n.t('offers.failDeleteOffer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const loadDeletedOffers = (params?: IParams) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;
  const getDeletedOffersURL = `/offer/api/offers/deleted?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  const getDeletedOffersNumberUrl = '/offer/api/offers/deleted/count';
  return async (dispatch: Function) => {
    dispatch(setDialogLoader(true));
    try {
      const deletedOffersPromise = API.get(getDeletedOffersURL);
      const countPromise = API.get(getDeletedOffersNumberUrl);
      const {
        data: { payload: offers },
      }: AxiosResponse<IOffersResponse> = await deletedOffersPromise;
      const {
        data: { payload: deletedOffersNumber },
      } = await countPromise;
      dispatch(saveDeletedOffersAction(offers, deletedOffersNumber as number));
    } catch (error) {
      dispatch(addErrorMessage({ message: error as string }));
    } finally {
      dispatch(setDialogLoader(false));
    }
  };
};

export const restoreOffer = (offerId: string) => {
  const restorePersonURL = `/offer/api/offers/deleted/${offerId}`;
  return async (dispatch: Function) => {
    dispatch(setDialogLoader(true));
    try {
      const countPromise = API.put(restorePersonURL);
      await countPromise;
      dispatch(loadAllOffers(null));
      dispatch(loadDeletedOffers());
      const message = i18n.t('offers.successRestoreOffer');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      const message = i18n.t('offers.failRestoreOffer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setDialogLoader(false));
    }
  };
};

export const searchForOffers = (
  query: string,
  saveInSearched: boolean = false,
  params: IParams,
  categoryId?: string | null,
  overWrite?: boolean
) => {
  const pageSize = params?.pageSize || 12;
  const searchForCompaniesUrl = `search/api/offers/autocomplete`;
  const searchFields = {
    query,
    ...params,
    categoryId,
    fields: ['customerNumber', 'number', 'customername', 'sitename', 'description', 'responsibleUserFullName', 'tags'],
  };
  return async (dispatch: Function) => {
    dispatch(setOfferItemLoader(true));
    try {
      const searchForCompaniesPromise = API.post(searchForCompaniesUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForCompaniesPromise;
      if (totalHits === 0 || totalHits < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));

      if (saveInSearched) {
        // We nullify the searched items, so we can accuratly switch between pageNumber for searchOffers and loadOffers
        // dispatch(saveAllOffersAction([], 0, true));
        dispatch(saveSearchedOffersAction({ totalHits, hits }, overWrite));
      }
      if (!saveInSearched) dispatch(saveAllOffersAction(hits, totalHits, true));
    } catch (error) {
      const message = i18n.t('offers.errorSearchingForOffer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setOfferItemLoader(false));
    }
  };
};

export const loadStdParametes = () => {
  const offerStdParameterUrl = '/offer/api/stdparameters';
  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const deletedOffersPromise = API.get(offerStdParameterUrl);
      const {
        data: { payload: stdParameter },
      }: AxiosResponse<IStdParameterResponse> = await deletedOffersPromise;
      dispatch(saveStdParameters(stdParameter));
    } catch (error) {
      dispatch(addErrorMessage({ message: "Couldn't load parameters" }));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const loadOfferItem = (offerId: string) => {
  const getOfferUrl = `/offer/api/offers/${offerId}`;
  return async (dispatch: Function) => {
    dispatch(setOfferItemLoader(true));
    try {
      const getOfferPromise = API.get(getOfferUrl);
      const {
        data: { payload: offerItem },
      }: AxiosResponse<IOfferItemResponse> = await getOfferPromise;
      dispatch(deselectSelectedNode()); // Reset selectedNode, multiSelectedNodes and updatedNode
      dispatch(saveOfferItem(offerItem));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setOfferItemLoader(false));
    }
  };
};

export const updateOfferItem = (offerId: string, updatedOfferItem: IUpdateOfferItem) => {
  if (!offerId) return;
  const updateOfferItem = `/offer/api/offers/${offerId}`;
  return async (dispatch: Function) => {
    dispatch(setOfferItemLoader(true));
    try {
      const updatePromise = API.put(updateOfferItem, { ...updatedOfferItem });
      const {
        data: { payload: offerItem },
      }: AxiosResponse<IOfferItemResponse> = await updatePromise;
      dispatch(updateOfferItemAction(offerItem));
      dispatch(loadOfferItem(offerId));
      const message = i18n.t('offers.successUpdateOffer');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('offers.failUpdateOffer');
      dispatch(addErrorMessage({ message }));
      setOfferItemLoader(false);
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const loadOfferTotal = (offerId: string, offerVersionId: string) => {
  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const getOfferTotal = `/offer/api/offerCalc/${offerId}/Totals?versionId=${offerVersionId}`;
      const getOfferTotalPromise = API.get(getOfferTotal);
      const {
        data: { payload: offerTotal },
      }: AxiosResponse<IOffersTotalResponse> = await getOfferTotalPromise;
      dispatch(saveOffersTotal(offerTotal, offerVersionId));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const loadOfferFilterTags = () => {
  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const getOfferFilterTags = '/offer/api/tags';
      const getOfferFilterTagsPromise = API.get(getOfferFilterTags);
      const {
        data: { payload: tags },
      }: AxiosResponse<IOfferFilterTagResponse> = await getOfferFilterTagsPromise;
      dispatch(saveOfferFilterTags(tags));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const deleteOfferFilterTag = (offerId: string, tags: Array<string>) => {
  return async (dispatch: Function) => {
    try {
      const deleteOfferFilterTag = `/offer/api/offers/${offerId}/tags?${createQueryString(tags)}`;
      const deleteOfferFilterTagPromise = API.delete(deleteOfferFilterTag);
      await deleteOfferFilterTagPromise;
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    }
  };
};

export const updateCurrencyRates = (offerId: string, versionId: string, callback = () => {}) => {
  return async (dispatch: Function) => {
    try {
      const URL = `/bdintegration/api/offers/${offerId}/version/${versionId}/currencyrates`;
      await API.put(URL);
      console.log('updated currency rates');
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingCurrencyRate');
      dispatch(addErrorMessage({ message }));
    } finally {
      callback();
    }
  };
};

export const addOfferFilterTag = (offerId: string, tags: Array<string>) => {
  return async (dispatch: Function) => {
    try {
      const addOfferFilterTag = `/offer/api/offers/${offerId}/tags?${createQueryString(tags)}`;
      const addOfferFilterTagPromise = API.post(addOfferFilterTag);
      await addOfferFilterTagPromise;
    } catch (e) {
      dispatch(addErrorMessageAction(e));
    } finally {
      dispatch(loadOfferItem(offerId));
    }
  };
};

export const createQueryString = (tags: Array<string>) => {
  return tags.map((tag) => `tag=${tag}`).join('&');
};

export const getOfferResponsiblePerson = (userId: string) => {
  return async (dispatch: Function) => {
    dispatch(setOfferItemLoader(true));
    try {
      if (userId === SYSTEM_ADMIN_ID) return;
      const getResponsiblePerson = `/users/api/users/${userId}`;
      const getResponsiblePersonPromise = API.get(getResponsiblePerson);
      const {
        data: { payload: userInfo },
      }: AxiosResponse<IUserInfoResponse> = await getResponsiblePersonPromise;
      dispatch(saveOfferResponsiblePerson(userInfo));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setOfferItemLoader(false));
    }
  };
};

export const searchForUsers = (query: string = 'a', params: IParams = { pageSize: 100, pageNumber: 0 }) => {
  const searchForUsersUrl = `/search/api/users/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: ['username', 'forename', 'surname'],
  };
  return async (dispatch: Function) => {
    dispatch(setOfferItemLoader(true));
    try {
      const searchForUsers = API.post(searchForUsersUrl, { ...searchFields });
      const {
        data: { hits },
      }: AxiosResponse<IUserSearchResponse> = await searchForUsers;
      dispatch(saveSearchedUsers(hits));
    } catch (error) {
      const message = i18n.t('offers.errorSearchingForUser');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setOfferItemLoader(false));
    }
  };
};

export const filterOffers = (params?: IOfferFilterParameters | null, overWrite: boolean = true) => {
  const categories = params?.categories || '';
  const dateFilter = params?.dateFilter || '';
  const dateFrom = params?.dateFrom || '';
  const dateTo = params?.dateTo || '';
  const tags = params?.tags || '';
  const amountFilter = params?.amountFilter || '';
  const amountFrom = params?.amountFrom || '';
  const amountTo = params?.amountTo || '';
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;

  const allOffersURL =
    `/offer/api/offers?` +
    `categoryId=${categories}` +
    `&dateFilter=${dateFilter}` +
    `&dateFrom=${dateFrom}` +
    `&dateTo=${dateTo}` +
    `&tags=${tags}` +
    `&amountFilter=${amountFilter}` +
    `&amountFrom=${amountFrom}` +
    `&amountTo=${amountTo}` +
    `&pageNumber=${pageNumber}` +
    `&pageSize=${pageSize}`;

  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const allOffersData = API.get(allOffersURL);
      const {
        data: { payload: offers },
      }: AxiosResponse<IOffersResponse> = await allOffersData;
      if (offers.length === 0 || offers.length < pageSize) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      // dispatch(saveSearchedOffersAction({ totalHits: offers.length, hits: offers }, overWrite));
      dispatch(saveAllOffersAction(offers, offers.length, overWrite));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const loadAllCategories = (pageNumber?: number, pageSize?: number) => {
  const number = pageNumber || 0;
  const size = pageSize || 10;
  const allCategoriesURL = `/offer/api/Categories?` + `pageNumber=${number}` + `&pageSize=${size}`;

  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const allCategoriesData = API.get(allCategoriesURL);
      const {
        data: { payload: categories },
      }: AxiosResponse<IOfferCategoriesResponse> = await allCategoriesData;
      if (categories.length === 0 || categories.length < size) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(saveOfferCategories(categories));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const loadOfferVersions = (offerId: string) => {
  return async (dispatch: Function) => {
    dispatch(setOffersLoader(true));
    try {
      const getOfferVersions = `/offer/api/offers/${offerId}/versions`;
      const getOfferVersionsPromise = API.get(getOfferVersions);
      const {
        data: { payload: offerVersions },
      }: AxiosResponse<IOfferVersionsResponse> = await getOfferVersionsPromise;
      dispatch(saveOfferVersions(offerVersions));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};

export const addCurrencyRate = (offerId: string, currencyRate: ICreateCurrencyRate, offerVersionId: string) => {
  return async (dispatch: Function) => {
    try {
      const addCurrency = `/offer/api/offers/${offerId}/parameters/currencyrates?versionId=${offerVersionId}`;
      const addCurrencyPromise = API.post(addCurrency, currencyRate);
      await addCurrencyPromise;
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
    }
  };
};

export const deleteCurrencyRate = (offerId: string, currencyRateId: string, offerVersionId: string) => {
  return async (dispatch: Function) => {
    try {
      const deleteCurrency = `/offer/api/offers/${offerId}/parameters/currencyrates/${currencyRateId}?versionId=${offerVersionId}`;
      const deleteCurrencyPromise = API.delete(deleteCurrency);
      await deleteCurrencyPromise;
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
    }
  };
};

export const lockUnlockOffer = (offerId: string, isLocked: boolean) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setOffersLoader(true));
      const apiPath = isLocked ? 'unlock' : 'lock';
      const URL = `/offer/api/offers/${offerId}/${apiPath}`;
      const {
        data: { payload },
      } = await API.put(URL);
      dispatch(saveOfferItem(payload));
    } catch (e) {
      const message = i18n.t('offers.errorSearchingForUser');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setOffersLoader(false));
    }
  };
};
