import { FC } from 'react';

type PreviewIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const PreviewIcon: FC<PreviewIconPropTypes> = ({
  width = 20,
  height = 18,
  color = '#0975A5',
  bgColor = 'grayLight',
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 14" {...rest}>
      <path
        d="M13.6878 0.25H2.02116C1.09616 0.25 0.354492 0.925 0.354492 1.75V12.25C0.354492 13.075 1.09616 13.75 2.02116 13.75H13.6878C14.6045 13.75 15.3545 13.075 15.3545 12.25V1.75C15.3545 0.925 14.6128 0.25 13.6878 0.25ZM13.6878 12.25H2.02116V3.25H13.6878V12.25ZM9.10449 7.75C9.10449 8.3725 8.54616 8.875 7.85449 8.875C7.16283 8.875 6.60449 8.3725 6.60449 7.75C6.60449 7.1275 7.16283 6.625 7.85449 6.625C8.54616 6.625 9.10449 7.1275 9.10449 7.75ZM7.85449 4.75C5.57949 4.75 3.63783 5.995 2.85449 7.75C3.63783 9.505 5.57949 10.75 7.85449 10.75C10.1295 10.75 12.0712 9.505 12.8545 7.75C12.0712 5.995 10.1295 4.75 7.85449 4.75ZM7.85449 9.625C6.70449 9.625 5.77116 8.785 5.77116 7.75C5.77116 6.715 6.70449 5.875 7.85449 5.875C9.00449 5.875 9.93783 6.715 9.93783 7.75C9.93783 8.785 9.00449 9.625 7.85449 9.625Z"
        fill={color}
      />
    </svg>
  );
};

export default PreviewIcon;
