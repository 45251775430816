import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type MenuIconDownloadIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const MenuIconDownloadIcon: FC<MenuIconDownloadIconPropTypes> = ({
  width = 37,
  height = 37,
  color = '#C3CAD9',
  bgColor = 'white',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  const resolvedBgColor = resolveThemeColor(bgColor, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2828_16705)">
        <rect x="6" y="4" width="24.3956" height="24.3956" rx="12.1978" fill={resolvedBgColor} />
        <rect x="6" y="4" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637" />
      </g>
      <path
        d="M14.4023 20.5347H21.9921V19.4505H14.4023V20.5347ZM21.9921 14.5714H19.8236V11.3186H16.5708V14.5714H14.4023L18.1972 18.3662L21.9921 14.5714Z"
        fill={resolvedColor}
      />
      <defs>
        <filter
          id="filter0_d_2828_16705"
          x="0.1875"
          y="0.186768"
          width="36.0215"
          height="36.022"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2828_16705" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2828_16705" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconDownloadIcon;
