import { FC } from 'react';

type MenuIconSectionIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconSectionIcon: FC<MenuIconSectionIconPropTypes> = ({
  width = 35,
  height = 35,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6074_24150)">
        <rect x="5" y="4" width="24.3956" height="24.3956" rx="12.1978" fill="white" />
        <rect x="5" y="4" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637" />
      </g>
      <path
        d="M12.8601 12.9451H11.7759V20.5349C11.7759 21.1312 12.2638 21.6191 12.8601 21.6191H20.4499V20.5349H12.8601V12.9451ZM21.5341 10.7766H15.0286C14.4323 10.7766 13.9444 11.2645 13.9444 11.8609V18.3664C13.9444 18.9627 14.4323 19.4506 15.0286 19.4506H21.5341C22.1305 19.4506 22.6184 18.9627 22.6184 18.3664V11.8609C22.6184 11.2645 22.1305 10.7766 21.5341 10.7766ZM20.992 15.6557H15.5708V14.5715H20.992V15.6557ZM18.8235 17.8242H15.5708V16.74H18.8235V17.8242ZM20.992 13.4872H15.5708V12.403H20.992V13.4872Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_6074_24150"
          x="0.121078"
          y="0.747268"
          width="34.1538"
          height="34.1538"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.62637" />
          <feGaussianBlur stdDeviation="2.03297" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6074_24150" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6074_24150" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconSectionIcon;
