import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import Auth from 'utils/Auth';

const varsSource = (window as any)['appValues'] ?? process.env;

type AxiosConfigCustom = {
  _retry?: boolean;
  headers?: Partial<AxiosRequestHeaders>;
} & InternalAxiosRequestConfig &
  Partial<AxiosRequestConfig>;

interface AxiosErrorCustom extends AxiosError<unknown, any> {
  config: AxiosConfigCustom;
}

const config: AxiosRequestConfig = {
  baseURL: varsSource.REACT_APP_BACKEND_API_HOST,
  headers: {
    Authorization: Auth.getAuthHeader(),
  },
  withCredentials: true,
};

export const API: AxiosInstance = axios.create(config);

let isRefreshing = false;

// Array to store requests that need to be retried after token refresh
const pendingRequests: Function[] = [];

// Function to retry pending requests after token refresh
const retryPendingRequests = async () => {
  await Promise.all(pendingRequests.map(async (callback) => await callback()));
  pendingRequests.length = 0; // Clear the queue
};

const refreshAndRetry = async (originalRequest: AxiosConfigCustom) => {
  const response = await Auth.refreshToken();
  const { authToken } = response;

  // Retry the original request with the updated access token
  originalRequest._retry = true;
  originalRequest.headers = { ...(originalRequest?.headers ?? {}) };
  originalRequest.headers.Authorization = `Bearer ${authToken}`;

  const retryResponse = await API(originalRequest);
  // Once the token refresh and request retry are successful, retry pending requests
  await retryPendingRequests();

  return retryResponse;
};

const addToRequestQueue = (originalRequest: AxiosConfigCustom) => {
  return new Promise((resolve, reject) => {
    pendingRequests.push(async () => {
      try {
        const response = await API(originalRequest);
        resolve(response.config);
      } catch (error) {
        reject(error);
      }
    });
  });
};

const handleRefreshTokenRequest = async (originalRequest: AxiosConfigCustom) => {
  isRefreshing = true;

  // Try to perform a token refresh request
  try {
    return await refreshAndRetry(originalRequest);
  } catch (refreshError) {
    // Clear any authentication, log the user out and reqject the request
    Auth.logout();
    return Promise.reject(refreshError);
  } finally {
    isRefreshing = false;
  }
};

// Add a request interceptor to attach the bearer token
API.interceptors.request.use(
  (config) => {
    if (config?.headers) config.headers.Authorization = Auth.getAuthHeader();
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors
API.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosErrorCustom) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      // If a refresh token request is already in progress, add the request to the queue
      if (isRefreshing) return addToRequestQueue(originalRequest);
      // Else try to refresh the token, before retrying the request
      return await handleRefreshTokenRequest(originalRequest);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    throw new axios.Cancel('Operation canceled by the user.');
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default API;
