import { FC } from 'react';

type CollapseArrowRightIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const CollapseArrowRightIcon: FC<CollapseArrowRightIconPropTypes> = ({
  width = 22,
  height = 22,
  color = '#5A6E81',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.70898 18.5443L7.33148 20.1668L16.4982 11.0002L7.33148 1.8335L5.70898 3.456L13.2532 11.0002L5.70898 18.5443Z"
        fill={color}
      />
    </svg>
  );
};

export default CollapseArrowRightIcon;
