import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import DialogWrapper from 'containers/NewDialog/NewDialog';

import { useModuleList } from 'modules/common';

export const deleteDialog = 'deleteDialog';

type Props = {
  onDelete: () => void;
  title: string;
  name: string;
  isOpen?: boolean;
  setIsOpen?: Function;
};

const DeleteDialog: FC<Props> = ({ title, onDelete, isOpen, name, setIsOpen }) => {
  const { t } = useTranslation();

  const { showDialog, closeDialog } = useModuleList();

  const handleConfirm = () => {
    onDelete();
    setIsOpen?.(false);
    closeDialog();
  };

  const handleClose = () => {
    setIsOpen?.(false);
    closeDialog();
  };

  return (
    <DialogWrapper
      maxWidth="sm"
      open={showDialog === deleteDialog || !!isOpen}
      title={title}
      closeHandler={handleClose}>
      <Box></Box>
      <Grid container sx={{ backgroundColor: 'gray.lighter', borderRadius: '12px' }} p={2} my={2}>
        <Typography variant="h2" textAlign="center" color="text.disabled">
          {t('globals.areYouSureYouWantToDelete')} "{name}"?
        </Typography>
        <Grid container pt={4} spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={handleClose} data-testid="deleteDialogCloseButton">
              {t('globals.cancel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth color="error" onClick={handleConfirm} data-testid="deleteDialogDeleteButton">
              {t('globals.delete')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default DeleteDialog;
