import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import { ICreatePackage, IPackage, ISearchResult } from './types';

import { useQueryParams } from 'routeUtils';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { ICatalog } from 'modules/Products/ProductsInterfaces';
import API from 'utils/API';

export const SELECT_CATALOG_IN_PACKAGES_REGISTER = 'SELECT_CATALOG_IN_PACKAGES_REGISTER';
export const DELETE_CATALOG_IN_PACKAGES_REGISTER = 'DELETE_CATALOG_IN_PACKAGES_REGISTER';
export const HARD_DELETE_CATALOG_IN_PACKAGES_REGISTER = 'HARD_DELETE_CATALOG_IN_PACKAGES_REGISTER';
export const LOAD_ALL_CATALOGS_IN_PACKAGES_REGISTER = 'LOAD_ALL_CATALOGS_IN_PACKAGES_REGISTER';
export const LOAD_DELETED_CATALOGS_IN_PACKAGES_REGISTER = 'LOAD_DELETED_CATALOGS_IN_PACKAGES_REGISTER';
export const IS_CATALOG_LOADING_IN_PACKAGES_REGISTER = 'IS_CATALOG_LOADING_IN_PACKAGES_REGISTER';
export const IS_CATALOG_DIALOG_LOADING_IN_PACKAGES_REGISTER = 'IS_CATALOG_DIALOG_LOADING_IN_PACKAGES_REGISTER';
export const LOAD_PACKAGES_IN_PACKAGES_REGISTER = 'LOAD_PACKAGES_IN_PACKAGES_REGISTER';
export const SET_HAS_MORE_ITEMS_IN_PACKAGES_REGISTER = 'SET_HAS_MORE_ITEMS_IN_PACKAGES_REGISTER';
export const SAVE_SEARCHED_PACKAGES_IN_PACKAGES_REGISTER = 'SAVE_SEARCHED_PACKAGES_IN_PACKAGES_REGISTER';
export const SAVE_TOTAL_PACKAGES_IN_PACKAGES_REGISTER = 'SAVE_TOTAL_PACKAGES_IN_PACKAGES_REGISTER';
export const LOAD_SINGLE_PACKAGE_IN_PACKAGES_REGISTER = 'LOAD_SINGLE_PACKAGE_IN_PACKAGES_REGISTER';
export const RESET_PACKAGE_STATE_MODULE = 'RESET_PACKAGE_STATE_MODULE';

export const resetPackageStateModule = () => ({ type: RESET_PACKAGE_STATE_MODULE });

export const saveTotalPackagesSuccess = (payload: number) => ({
  type: SAVE_TOTAL_PACKAGES_IN_PACKAGES_REGISTER,
  payload,
});

export const searchForPackagesSuccess = (payload: Array<IPackage>, pagingInfo: IParams, totalHits: number) => ({
  type: SAVE_SEARCHED_PACKAGES_IN_PACKAGES_REGISTER,
  payload: { packages: [...payload], totalItems: totalHits },
});

export const loadPackagesSuccess = (payload: Array<IPackage>) => ({
  type: LOAD_PACKAGES_IN_PACKAGES_REGISTER,
  payload,
});

export const loadSinglePackageSuccess = (payload: IPackage) => ({
  type: LOAD_SINGLE_PACKAGE_IN_PACKAGES_REGISTER,
  payload,
});

const loadCatalogsSuccess = (payload: Array<ICatalog>, pagingInfo: IPageInfo) => ({
  type: LOAD_ALL_CATALOGS_IN_PACKAGES_REGISTER,
  payload,
});

const loadDeletedCatalogsSuccess = (payload: Array<ICatalog>) => ({
  type: LOAD_DELETED_CATALOGS_IN_PACKAGES_REGISTER,
  payload: { deletedItems: [...payload] },
});

const deleteCatalogSuccess = (payload: ICatalog, hardDelete = false) => ({
  type: hardDelete ? HARD_DELETE_CATALOG_IN_PACKAGES_REGISTER : DELETE_CATALOG_IN_PACKAGES_REGISTER,
  payload,
});

const setIsLoading = (payload: boolean) => ({ type: IS_CATALOG_LOADING_IN_PACKAGES_REGISTER, payload });

const setIsDialogLoading = (payload: boolean) => ({ type: IS_CATALOG_DIALOG_LOADING_IN_PACKAGES_REGISTER, payload });

export const selectCatalog = (payload: ICatalog) => ({
  type: SELECT_CATALOG_IN_PACKAGES_REGISTER,
  payload,
});

export const setHasMoreItems = (payload: boolean) => ({ type: SET_HAS_MORE_ITEMS_IN_PACKAGES_REGISTER, payload });

//CATALOGS
export const loadCatalogs = (catalogId?: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const url = '/packages/api/catalogs';
      dispatch(setIsLoading(true));
      const { selectedItem } = getState().packagesModule;
      const {
        data: { payload, pageInfo },
      } = await API.get(url);
      dispatch(loadCatalogsSuccess(payload, pageInfo));
      if (payload?.length) {
        if (!catalogId) {
          dispatch(selectCatalog(payload[0]));
        } else {
          const catId = catalogId ?? selectedItem?.id ?? '';
          const catalog = payload?.find(({ id }: ICatalog) => id === catId) || null;
          dispatch(selectCatalog(catalog));
        }
      }
    } catch (error) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteCatalog = (id: string, hardDelete = false, callback?: Function | null) => {
  return async (dispatch: Function, getState: Function) => {
    const { selectedItem, catalogs = [] } = getState().packagesModule;
    const { id: selectedItemId = '' } = selectedItem || {};
    const url = `/packages/api/catalogs/${id}?hardDelete=${hardDelete}`;
    try {
      hardDelete ? dispatch(setIsDialogLoading(true)) : dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.delete(url);

      dispatch(deleteCatalogSuccess(payload, hardDelete));
      if (selectedItemId === id && catalogs.length > 0) {
        dispatch(selectCatalog(catalogs[0]));
      }

      const message = i18n.t('products.successDeletingCatalog');
      dispatch(addSuccessMessage({ message }));
      if (callback) callback();
    } catch (e) {
      const message = i18n.t('products.errorDeletingCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      hardDelete ? dispatch(setIsDialogLoading(false)) : dispatch(setIsLoading(false));
      dispatch(loadCatalogs());
    }
  };
};

export const loadDeletedCatalogs = () => {
  return async (dispatch: Function) => {
    const url = '/packages/api/catalogs/Deleted';
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.get(url);

      dispatch(loadDeletedCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('products.errorLoadingDeletedCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const deletePackage = (catalogId: string, packageId: string) => {
  return async (dispatch: Function) => {
    try {
      const url = `/packages/api/packages/${packageId}`;
      const deletePackage = API.delete(url);
      await deletePackage;
      dispatch(loadPackages(catalogId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18n.t('packages.errorDeletingPackage') }));
    }
  };
};

export const getPackageById = (packageId: string) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `/facade/api/packages/${packageId}`;
      const getPackage = API.get(url);
      const {
        data: { payload },
      } = await getPackage;
      dispatch(loadSinglePackageSuccess(payload));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18n.t('packages.errorLoadingPackage') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updatePackage = (packageId: string, data: ICreatePackage) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `/packages/api/packages/${packageId}`;
      const updatePackage = API.put(url, data);
      await updatePackage;
    } catch (error) {
      dispatch(addErrorMessage({ message: i18n.t('packages.errorUpdatingPackage') }));
    } finally {
      dispatch(getPackageById(packageId));
      dispatch(setIsLoading(false));
    }
  };
};

export const createCatalog = (data: ICatalog, callback = async () => {}) => {
  return async (dispatch: Function) => {
    const url = '/packages/api/catalogs';
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: {
          payload: { id },
        },
      } = await API.post(url, data);
      await callback();
      dispatch(loadCatalogs(id));
      const message = i18n.t('products.successCreatingCatalog');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('products.errorCreatingCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const editCatalog = (data: ICatalog, id: string, callback = async () => {}) => {
  return async (dispatch: Function) => {
    const url = `/packages/api/catalogs/${id}`;
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.put(url, data);
      await callback();
      dispatch(loadCatalogs(payload?.id));
      const message = i18n.t('products.successEditingCatalog');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('products.errorEditingCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const loadPackages = (catalogId: string) => {
  return async (dispatch: Function) => {
    try {
      const { pageSize, pageNumber } = useQueryParams();
      const url = `/packages/api/packages/catalog/${catalogId}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      const totalPackages = `packages/api/packages/catalog/${catalogId}/count`;
      const getTotalPackagesPromise = API.get(totalPackages);
      const getCatalogPromise = API.get(url);
      const {
        data: { payload },
      } = await getCatalogPromise;
      const {
        data: { payload: totalItems },
      } = await getTotalPackagesPromise;
      dispatch(loadPackagesSuccess(payload));
      dispatch(saveTotalPackagesSuccess(totalItems));
      if (payload.length === 0 || payload.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
    } catch (error) {}
  };
};

export const searchForPackages = (
  query: string,
  catalogId: string,
  fieldParams: string[],
  params: IParams = { pageNumber: 0, pageSize: 15 }
) => {
  const searchForProductsUrl = `/search/api/packages/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: fieldParams || ['code', 'tags', 'description'],
    catalogIds: [catalogId],
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForProductsPromise = API.post(searchForProductsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForProductsPromise;
      dispatch(searchForPackagesSuccess(hits, { pageSize: params.pageSize, pageNumber: params.pageSize }, totalHits));
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createPackage = (catalogId: string, data: ICreatePackage) => {
  return async (dispatch: Function) => {
    try {
      const url = `/packages/api/packages/catalog/${catalogId}`;
      const createNewPackage = API.post(url, data);
      await createNewPackage;
      dispatch(loadPackages(catalogId));
    } catch (error) {
      const message = i18n.t('packages.errorCreatingPackage');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const addCatalogRequiredModule = (catalogId: string, moduleCode: number) => {
  return async (dispatch: Function) => {
    const url = `/packages/api/catalogs/${catalogId}/RequiredModules/${moduleCode}`;
    try {
      dispatch(setIsLoading(true));
      await API.post(url);
      dispatch(loadCatalogs());
    } catch (e) {
      const message = i18n.t('globals.errorAddingModuleToCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const deleteCatalogRequiredModule = (catalogId: string, moduleCode: number) => {
  return async (dispatch: Function) => {
    const url = `/packages/api/catalogs/${catalogId}/RequiredModules/${moduleCode}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(loadCatalogs());
    } catch (e) {
      const message = i18n.t('globals.errorDeletingModuleToCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
