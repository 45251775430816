// @ts-nocheck
import queryString from 'query-string';

import { useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

interface IQueryString {
  pageSize?: number;
  pageNumber?: number;
}

export interface IUseRouteUtils {
  value: number | string;
  setValue: Function;
  setSearchParams: Function;
  queryStringObject: IQueryString;
  onChange: Function;
  setQueryParam: Function;
  openDialog: Function;
  closeDialog: Function;
}

export const useRouteUtils = (key = '', defaultValue: string | number = '') => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryStringObject = queryString.parse(location.search);

  const [value, setValue] = useState(searchParams.get(key) ?? defaultValue);
  const onChange = (event): void => {
    const { value } = event.target;
    setValue(value);
    const queryStringObject: IQueryString = queryString.parse(location.search);
    queryStringObject[key] = value;
    setSearchParams(queryStringObject);
  };

  const setQueryParam = (param) => {
    const queryStringObject: IQueryString = queryString.parse(location.search);
    setSearchParams({ ...queryStringObject, ...param });
  };

  const deleteParam = (param) => {
    searchParams.delete(param);
    setSearchParams(searchParams);
  };

  const closeDialog = () => {
    const queryStringObject: IQueryString = queryString.parse(location.search);
    const keys = Object.keys(queryStringObject);
    keys.forEach((key) => {
      if (key.toLowerCase().includes('dialog')) {
        delete queryStringObject[key];
      }
    });
    setSearchParams({ ...queryStringObject });
  };

  const openDialog = (showDialog) => setQueryParam({ showDialog });

  const setTab = (tab) => setQueryParam({ tab });

  const setDialogTab = (dialogTab) => setQueryParam({ dialogTab });

  const resetPageNumber = () => setQueryParam({ pageNumber: 0 });

  const resetSearchPageNumber = () => setQueryParam({ searchPageNumber: 0 });

  return {
    value,
    setValue,
    searchParams,
    setSearchParams,
    queryStringObject,
    onChange,
    setQueryParam,
    openDialog,
    closeDialog,
    setTab,
    setDialogTab,
    resetPageNumber,
    resetSearchPageNumber,
    deleteParam,
  };
};

export default useRouteUtils;
