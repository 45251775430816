import { FC } from 'react';

type MenuIconAddIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconAddIcon: FC<MenuIconAddIconPropTypes> = ({
  width = 42,
  height = 42,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4801_10031)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        d="M20.5082 13.0845C17.5144 13.0845 15.0847 15.5142 15.0847 18.508C15.0847 21.5017 17.5144 23.9315 20.5082 23.9315C23.502 23.9315 25.9317 21.5017 25.9317 18.508C25.9317 15.5142 23.502 13.0845 20.5082 13.0845ZM23.22 19.0503H21.0506V21.2197H19.9659V19.0503H17.7965V17.9656H19.9659V15.7962H21.0506V17.9656H23.22V19.0503Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_4801_10031"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4801_10031" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4801_10031" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconAddIcon;
