import { ISupplier, ISupplierState, ISupplierStateAction } from '../SuppliersInterfaces';
import {
  IS_SUPPLIERS_LOADING,
  LOAD_ALL_SUPPLIERS,
  DELETE_SUPPLIER,
  HARD_DELETE_SUPPLIER,
  LOAD_DELETED_SUPPLIERS,
  RESTORE_SUPPLIER,
  IS_SUPPLIERS_DIALOG_LOADING,
  CREATE_SUPPLIER,
  EDIT_SUPPLIER,
  SELECT_SUPPLIER,
  LOAD_LIST_OF_SUPPLIERS,
  SAVE_SEARCHED_SUPPLIERS,
} from './SuppliersActions';

const defaultState = {
  items: [],
  selectedItem: null,
  suppliers: [],
  searchedSuppliers: [],
  totalItems: 0,
  isLoading: false,
  isDialogLoading: false,
  deletedItems: [],
  pagingInfo: { pageSize: 10, pageNumber: 0 },
} as ISupplierState;

export const suppliersReducer = (state = defaultState, { type, payload }: ISupplierStateAction) => {
  switch (type) {
    case IS_SUPPLIERS_LOADING:
      return { ...state, isLoading: payload };

    case IS_SUPPLIERS_DIALOG_LOADING:
      return { ...state, isDialogLoading: payload };

    case CREATE_SUPPLIER:
      return { ...state, items: [...state.items, { ...payload }] };

    case SAVE_SEARCHED_SUPPLIERS:
      return { ...state, searchedSuppliers: payload };

    case LOAD_ALL_SUPPLIERS:
      
    case LOAD_DELETED_SUPPLIERS:
      return { ...state, ...payload };

    case LOAD_LIST_OF_SUPPLIERS:
      return { ...state, suppliers: payload };

    case SELECT_SUPPLIER:
      return { ...state, selectedItem: payload };

    case EDIT_SUPPLIER:
      const newItem = payload as ISupplier;
      return {
        ...state,
        items: state.items.map((item: ISupplier) => (item.id !== newItem.id ? item : newItem)),
      };

    case DELETE_SUPPLIER:
    case HARD_DELETE_SUPPLIER:
    case RESTORE_SUPPLIER:
      const deletedItem = payload as ISupplier;
      const key = type === DELETE_SUPPLIER ? 'items' : 'deletedItems';
      const deletedItems = state[key]?.filter(({ id }: ISupplier) => id !== deletedItem.id);
      return { ...state, [key]: deletedItems };
  }
  return state;
};

export default suppliersReducer;
