import i18n from 'i18next';
import API from 'utils/API';
import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import {
  INewSpecificationItem,
  IPieceworkCategory,
  ISpecificationItem,
  ISpecificationItemsState,
  ISpecificationType,
  ITrade,
} from '../SpecificationItems/specificationItemsInterfaces';
import { searchByPost } from 'utils/helpers';
import { IProduct } from 'modules/Products/ProductsInterfaces';
import { INewPackageSpecificationItem } from 'modules/Packages/PackageEditTable/PackageSpecificationItems';
import { ISpecificationItemNodeOverview } from '../Specification/specificationInterface';
import { containsOnlyNumbers } from 'utils';
import { useQueryParams } from 'routeUtils';

export const LOAD_PRODUCTS_CATALOGS = 'LOAD_PRODUCTS_CATALOGS';
export const LOAD_PACKAGES_CATALOGS = 'LOAD_PACKAGES_CATALOGS';
export const LOAD_NSCODES_CATEGORIES = 'LOAD_NSCODES_CATEGORIES';
export const LOAD_PIECE_WORK_RATE_CATALOGS = 'LOAD_PIECE_WORK_RATE_CATALOGS';
export const IS_CATALOG_LOADING = 'IS_CATALOG_LOADING';
export const IS_TABLE_LOADING = 'IS_TABLE_LOADING';
export const LOAD_SPECIFICATION_TABLE_ITEMS = 'LOAD_SPECIFICATION_TABLE_ITEMS';
export const SELECT_SPECIFICATION_ITEM = 'SELECT_SPECIFICATION_ITEM';
export const DESELECT_SPECIFICATION_ITEM = 'DESELECT_SPECIFICATION_ITEM';
export const LOAD_PIECE_WORK_TRADES = 'LOAD_PIECE_WORK_TRADES';
export const LOAD_PIECE_WORK_CATEGORIES = 'LOAD_PIECE_WORK_CATEGORIES';
export const SEARCH_ITEM_SUCCESS = 'SEARCH_ITEM_SUCCESS';
export const LOAD_WORK_CATEGORIES = 'LOAD_WORK_CATEGORIES';
export const SELECT_PRODUCT_CATALOG = 'SELECT_PRODUCT_CATALOG';
export const SELECT_PACKAGE_CATALOG = 'SELECT_PACKAGE_CATALOG';
export const SELECT_NSCODE_CATEGORY = 'SELECT_NSCODE_CATEGORY';
export const SELECT_WORK_CATALOG = 'SELECT_WORK_CATALOG';
export const CLEAR_ITEMS = 'CLEAR_ITEMS';
export const CLEAR_SEARCH_ITEMS = 'CLEAR_SEARCH_ITEMS';
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS';
export const SINGLE_SELECTED_ITEM = 'SINGLE_SELECTED_ITEM';
export const CLEAR_SINGLE_SELECTED_ITEM = 'CLEAR_SINGLE_SELECTED_ITEM';
export const LOAD_SPECIFICATION_ITEMS_CONFIG = 'LOAD_SPECIFICATION_ITEMS_CONFIG';
export const UPDATE_SPECIFICATION_ITEMS_CONFIG = 'UPDATE_SPECIFICATION_ITEMS_CONFIG';
export const LOAD_SPECIFICATION_PRODUCT_IDS = 'LOAD_SPECIFICATION_PRODUCT_IDS';
export const REFRESH_ITEMS_LIST = 'REFRESH_ITEMS_LIST';
export const IS_CODES_LOADING = 'IS_CODES_LOADING';
export const SEARCH_FOR_CODE_ITEMS = 'SEARCH_FOR_CODE_ITEMS';
export const SELECT_CODE_ITEM = 'SELECT_CODE_ITEM';
export const CLEAR_CODE_ITEMS = 'CLEAR_CODE_ITEMS';

export const RESET_SPECIFICATION_ITEMS_STATE = 'RESET_SPECIFICATION_ITEMS_STATE';

export const resetSpecificationItems = () => ({ type: RESET_SPECIFICATION_ITEMS_STATE });

const setIsCatalogLoading = (value: boolean) => ({ type: IS_CATALOG_LOADING, payload: { isCatalogLoading: value } });

const setIsTableLoading = (value: boolean) => ({ type: IS_TABLE_LOADING, payload: { isTableLoading: value } });

const setIsCodesLoading = (value: boolean) => ({ type: IS_CODES_LOADING, payload: { isCodeLoading: value } });

export const refreshListItems = () => ({
  type: REFRESH_ITEMS_LIST,
  payload: { itemsLastUpdate: Date.now() },
});

export const loadSpecificationProductIdsSuccess = (products: Array<IProduct>) => ({
  type: LOAD_SPECIFICATION_PRODUCT_IDS,
  payload: { products },
});

export const loadProductsCatalogsSuccess = (productsCatalogs: Array<any>) => ({
  type: LOAD_PRODUCTS_CATALOGS,
  payload: { productsCatalogs },
});

export const selectProductCatalog = (selectedProductCatalog: ISpecificationType) => ({
  type: SELECT_PRODUCT_CATALOG,
  payload: {
    selectedProductCatalog,
    selectedPackageCatalog: null,
    selectedWorkCatalog: null,
    selectedNSCodeCategory: null,
  },
});

export const loadNSCodesCategoriesSuccess = (nsCodeCategories: Array<any>) => ({
  type: LOAD_NSCODES_CATEGORIES,
  payload: { nsCodeCategories },
});

export const selectNSCodeCategory = (selectedNSCodeCategory: ISpecificationType) => ({
  type: SELECT_NSCODE_CATEGORY,
  payload: {
    selectedProductCatalog: null,
    selectedPackageCatalog: null,
    selectedWorkCatalog: null,
    selectedNSCodeCategory,
  },
});

export const loadPackagesCatalogsSuccess = (packagesCatalogs: Array<any>) => ({
  type: LOAD_PACKAGES_CATALOGS,
  payload: { packagesCatalogs },
});

export const selectPackageCatalog = (selectedPackageCatalog: ISpecificationType) => ({
  type: SELECT_PACKAGE_CATALOG,
  payload: {
    selectedProductCatalog: null,
    selectedPackageCatalog,
    selectedWorkCatalog: null,
    selectedNSCodeCategory: null,
  },
});

export const loadWorkCategoriesCatalogsSuccess = (workCatalogs: Array<any>) => ({
  type: LOAD_WORK_CATEGORIES,
  payload: { workCatalogs },
});

export const selectWorkCatalog = (selectedWorkCatalog: ISpecificationType) => ({
  type: SELECT_WORK_CATALOG,
  payload: {
    selectedProductCatalog: null,
    selectedPackageCatalog: null,
    selectedWorkCatalog,
    selectedNSCodeCategory: null,
  },
});

export const loadItemsForCatalogSuccess = (items: Array<ISpecificationItem>, totalItems: number) => ({
  type: LOAD_SPECIFICATION_TABLE_ITEMS,
  payload: { items, totalItems, itemsLastUpdate: Date.now() },
});

export const selectItem = (item: ISpecificationItem) => ({
  type: SELECT_SPECIFICATION_ITEM,
  payload: item,
});

export const deselectItem = (payload: ISpecificationItem) => ({ type: DESELECT_SPECIFICATION_ITEM, payload });

export const loadPieceWorkTradeSuccess = (trades: Array<ITrade>) => ({
  type: LOAD_PIECE_WORK_TRADES,
  payload: { trades },
});

export const loadPieceWorkRatesCategoriesSuccess = (pieceworkCategories: Array<IPieceworkCategory>) => ({
  type: LOAD_PIECE_WORK_CATEGORIES,
  payload: { pieceworkCategories },
});

export const searchForItemSuccess = (hits: Array<ISpecificationItem>, totalHits: number) => ({
  type: SEARCH_ITEM_SUCCESS,
  payload: { hits, totalHits },
});

export const clearItems = () => ({ type: CLEAR_ITEMS, payload: { items: [], totalItems: 0 } });
export const clearSearchItems = () => ({ type: CLEAR_SEARCH_ITEMS, payload: { hits: [], totalHits: 0 } });

export const clearSelectedItems = () => ({ type: CLEAR_SELECTED_ITEMS, payload: { selectedItems: [] } });

export const setSingleSelectedItem = (item: ISpecificationItem | any) => ({
  type: SINGLE_SELECTED_ITEM,
  payload: { singleSelectedItem: item },
});

export const clearSingleSelectedItem = () => ({
  payload: { singleSelectedItem: null },
  type: CLEAR_SINGLE_SELECTED_ITEM,
});

const loadSpecificationItemsConfigsSuccess = (specificationItemsConfigs: any) => ({
  type: LOAD_SPECIFICATION_ITEMS_CONFIG,
  payload: { ...specificationItemsConfigs },
});
const updateSpecificationItemsConfigsSuccess = (specificationItemsConfigs: any) => ({
  type: UPDATE_SPECIFICATION_ITEMS_CONFIG,
  payload: { specificationItemsConfigs },
});

const searchForCodeItemsSuccess = (codeItems: Array<ISpecificationItem>, totalCodeItems: number) => ({
  type: SEARCH_FOR_CODE_ITEMS,
  payload: { codeItems, totalCodeItems },
});

export const clearCodeItems = () => ({
  type: CLEAR_CODE_ITEMS,
  payload: { codeItems: [], totalCodeItems: 0 },
});

export const selectCodeItem = (selectedCodeItem: ISpecificationItem) => ({
  type: SELECT_CODE_ITEM,
  payload: { selectedCodeItem },
});

export const findAndSelectSingleItem = (draggedNode: INewSpecificationItem | INewPackageSpecificationItem) => {
  return (dispatch: Function, getState: Function) => {
    const { items }: ISpecificationItemsState = getState().specificationItemsModule;
    const target = items.find(({ id }) => id === draggedNode?.id);
    const body = { ...target, ...draggedNode };
    dispatch(setSingleSelectedItem(body));
  };
};

export const loadProductsCatalogs = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/products/api/catalogs';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadProductsCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingProductsCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadPackagesCatalogs = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/packages/api/catalogs';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadPackagesCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingPackagesCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadNSCodesCategories = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/nscodes/api/categories';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadNSCodesCategoriesSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingNSCodesCategories');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadProductsForCatalog = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { catalogId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const countUrl = `/products/api/products/catalog/${catalogId}/count`;
      const url = `/products/api/products/catalog/${catalogId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countPromise = API.get(countUrl);
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      dispatch(loadItemsForCatalogSuccess(payload, totalItems));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadPackagesForCatalog = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { catalogId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      // const countUrl = `/packages/api/packages/catalog/${catalogId}/count`;
      const url = `/packages/api/packages/catalog/${catalogId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      //const countPromise = API.get(countUrl);
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      // const {
      //   data: { payload: totalItems },
      // } = await countPromise;
      dispatch(loadItemsForCatalogSuccess(payload, 0));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadNSCodesForCategories = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { categoryId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const countUrl = `/nscodes/api/nscodes/category/${categoryId}/count`; // this does not work as expected
      const url = `/nscodes/api/nscodes/category/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countPromise = API.get(countUrl);
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      const items = payload.map((item: any) => ({ ...item, code: item.nsCode }));
      dispatch(loadItemsForCatalogSuccess(items, totalItems));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingNSCodesCategories');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadPieceWorkTrade = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/piecework/api/trades';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadPieceWorkTradeSuccess(payload));
    } catch (e) {
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadPieceWorkRatesCategories = (tradeId: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = `/piecework/api/PieceWorkCategories/trade/${tradeId}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadPieceWorkRatesCategoriesSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingPieceWorkRatesCategories');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadPieceworkRatesForCategories = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { categoryId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const URL = `/piecework/api/pieceworkRates/category/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadItemsForCatalogSuccess(payload, 0));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const searchForItem = (searchString: string, type: string, searchType: string, showDeleted = false) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { pageSize, pageNumber } = useQueryParams();
      const module = getState().specificationItemsModule;
      const {
        pieceworkCategories,
        productsCatalogs,
        packagesCatalogs,
        workCatalogs,
        nsCodeCategories,
        selectedProductCatalog,
        selectedPackageCatalog,
        selectedWorkCatalog,
        selectedNSCodeCategory,
      }: ISpecificationItemsState = module;

      const isSearchTypeCode = searchType === 'code';
      const isTypeNSCode = type === 'nsCodes';

      const searchTypeArray = isTypeNSCode
        ? isSearchTypeCode
          ? ['nsCode']
          : ['nsCode', 'description']
        : ['code', 'description'];

      const isProductType = type === 'products';
      const isSearchTypeTag = searchType === 'tags';
      const isProductAndSearchTypeTag = isProductType && isSearchTypeTag;
      const tags = searchString.includes(',') ? searchString.split(',') : [searchString];
      let data: any = {
        query: isProductAndSearchTypeTag ? null : searchString,
        fields:
          searchType === 'all' || (isSearchTypeCode && isTypeNSCode)
            ? searchTypeArray
            : isProductAndSearchTypeTag
            ? null
            : [searchType],
        orderBy: isSearchTypeCode || searchType === 'tags' ? (isTypeNSCode ? 'nsCode' : 'code') : null,
        deleted: showDeleted,
        pageSize,
        pageNumber,
        tags: isSearchTypeTag ? tags : null,
      };

      if (type === 'products') {
        data.catalogIds = selectedProductCatalog?.id
          ? [selectedProductCatalog?.id]
          : productsCatalogs?.map(({ id }) => id);
      } else if (type === 'packages') {
        data.catalogIds = selectedPackageCatalog?.id
          ? [selectedPackageCatalog?.id]
          : packagesCatalogs?.map(({ id }) => id);
      } else if (type === 'workCategories') {
        data.catalogIds = selectedWorkCatalog?.id ? [selectedWorkCatalog?.id] : workCatalogs?.map(({ id }) => id);
      } else if (isTypeNSCode) {
        data.categoryIds = selectedNSCodeCategory?.id
          ? [selectedNSCodeCategory?.id]
          : nsCodeCategories?.map(({ id }) => id);
      } else {
        data.categoryIds = pieceworkCategories?.map(({ id }) => id);
      }
      const URL = `/search/api/${type}/autocomplete`;

      const { hits, totalHits } = await searchByPost(URL, data);
      dispatch(searchForItemSuccess(hits, totalHits));
    } catch (e) {
      const key =
        type === 'products'
          ? `errorSearchingForProducts`
          : type === 'packages'
          ? 'errorSearchingForPackages'
          : 'errorSearchingForPieceworkRates';
      const message = i18n.t(`offers.${key}`);
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadWorkCategories = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/workCategories/api/catalogs';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadWorkCategoriesCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorWorkCategories');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadWorkCategoriesForCatalog = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { catalogId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const URL = `/workCategories/api/workCategories/catalog/${catalogId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadItemsForCatalogSuccess(payload, 0));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadSpecificationItemsConfigs = () => {
  return async (dispatch: Function) => {
    const URL = '/userConfiguration/tenantdata/SpecificationItemsConfigs';
    try {
      const { data } = await API.get(URL);
      dispatch(loadSpecificationItemsConfigsSuccess(data));
    } catch (e) {}
  };
};

export const updateSpecificationItemsConfigs = (key: string, value: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { specificationItemsConfigs }: ISpecificationItemsState = getState().specificationItemsModule;
    const URL = '/userConfiguration/tenantdata/SpecificationItemsConfigs';
    const objKey = typeof value === 'object' && value.hasOwnProperty('name') ? value.name + 'CatalogId' : key;
    const objVal = typeof value === 'object' && value.hasOwnProperty('id') ? value.id : value;
    const data = { [objKey]: objVal };
    try {
      await API.put(URL, data);
      dispatch(updateSpecificationItemsConfigsSuccess({ ...specificationItemsConfigs, ...data }));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingSpecificationItemsConfigs');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const loadStandardText = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { pageNumber = 0, pageSize = 10 } = params ?? {};
      const countURL = `/texts/api/texts/count`;
      const URL = `/texts/api/texts?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countPromise = API.get(countURL);
      const {
        data: { payload },
      } = await API.get(URL);
      const {
        data: { payload: totalItems },
      } = await countPromise;
      dispatch(loadItemsForCatalogSuccess(payload, totalItems));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingStandardText');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadSpecificationProductIds = (listOfIds: Array<string>) => {
  let url = `/products/api/products/List`;
  url += `?id=${listOfIds[0]}`;
  if (listOfIds[1]) {
    for (let i = 1; i < listOfIds.length; i++) {
      url += `&id=${listOfIds[i]}`;
    }
  }
  return async (dispatch: Function) => {
    try {
      //dispatch(setIsLoading(true));
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;

      dispatch(loadSpecificationProductIdsSuccess(payload));
    } catch (error) {
    } finally {
      //dispatch(setIsLoading(false));
    }
  };
};

const searchForNsCodes = (data: any, selectedIds?: Array<string>) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsCodesLoading(true));
      const { nsCodeCategories }: ISpecificationItemsState = getState().specificationItemsModule;
      let ids: Array<string>;
      if (!nsCodeCategories.length) {
        const URL = '/nscodes/api/categories';
        const {
          data: { payload },
        } = await API.get(URL);
        dispatch(loadNSCodesCategoriesSuccess(payload));
        ids = payload.map(({ id }: ISpecificationType) => id);
      } else if (selectedIds) {
        ids = selectedIds;
      } else {
        ids = nsCodeCategories.map(({ id }) => id);
      }
      data.categoryIds = ids;
      const URL = `/search/api/nsCodes/autocomplete`;
      const { hits, totalHits } = await searchByPost(URL, data);
      dispatch(searchForCodeItemsSuccess(hits, totalHits));
    } catch (e) {
    } finally {
      dispatch(setIsCodesLoading(false));
    }
  };
};

const searchForProducts = (data: any, selectedIds?: Array<string>) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsCodesLoading(true));
      const { productsCatalogs }: ISpecificationItemsState = getState().specificationItemsModule;
      let ids: Array<string>;
      if (!productsCatalogs.length) {
        const URL = '/products/api/catalogs';
        const {
          data: { payload },
        } = await API.get(URL);
        dispatch(loadProductsCatalogsSuccess(payload));
        ids = payload.map(({ id }: ISpecificationType) => id);
      } else if (selectedIds) {
        ids = selectedIds;
      } else {
        ids = productsCatalogs.map(({ id }) => id);
      }
      data.catalogIds = ids;
      const URL = `/search/api/products/autocomplete`;
      const { hits, totalHits } = await searchByPost(URL, data);
      dispatch(searchForCodeItemsSuccess(hits, totalHits));
    } catch (e) {
    } finally {
      dispatch(setIsCodesLoading(false));
    }
  };
};

const searchForPackages = (data: any, selectedIds?: Array<string>) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsCodesLoading(true));
      const { packagesCatalogs }: ISpecificationItemsState = getState().specificationItemsModule;
      let ids: Array<string>;
      if (!packagesCatalogs.length) {
        const URL = '/packages/api/catalogs';
        const {
          data: { payload },
        } = await API.get(URL);
        dispatch(loadPackagesCatalogsSuccess(payload));
        ids = payload.map(({ id }: ISpecificationType) => id);
      } else if (selectedIds) {
        ids = selectedIds;
      } else {
        ids = packagesCatalogs.map(({ id }) => id);
      }
      data.catalogIds = ids;
      const URL = `/search/api/packages/autocomplete`;
      const { hits, totalHits } = await searchByPost(URL, data);
      dispatch(searchForCodeItemsSuccess(hits, totalHits));
    } catch (e) {
    } finally {
      dispatch(setIsCodesLoading(false));
    }
  };
};

const searchForWorkCategories = (data: any, selectedIds?: Array<string>) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsCodesLoading(true));
      const { workCatalogs }: ISpecificationItemsState = getState().specificationItemsModule;
      let ids: Array<string>;
      if (!workCatalogs.length) {
        const URL = '/workCategories/api/catalogs';
        const {
          data: { payload },
        } = await API.get(URL);
        dispatch(loadPackagesCatalogsSuccess(payload));
        ids = payload.map(({ id }: ISpecificationType) => id);
      } else if (selectedIds) {
        ids = selectedIds;
      } else {
        ids = workCatalogs.map(({ id }) => id);
      }
      data.catalogIds = ids;
      const URL = `/search/api/workCategories/autocomplete`;
      const { hits, totalHits } = await searchByPost(URL, data);
      dispatch(searchForCodeItemsSuccess(hits, totalHits));
    } catch (e) {
    } finally {
      dispatch(setIsCodesLoading(false));
    }
  };
};

export const searchForClosestCodes = (
  node: ISpecificationItemNodeOverview,
  query: string,
  selectedIds?: Array<string>
) => {
  return (dispatch: Function) => {
    const { nodeType } = node;
    let fields = nodeType.toLowerCase() === 'nscode' ? ['nsCode'] : ['code'];
    const orderBy = !containsOnlyNumbers(query) ? null : nodeType.toLowerCase() === 'nscode' ? 'nsCode' : 'code';
    fields.push('description');
    let data: any = {
      query,
      fields,
      orderBy,
      deleted: false,
    };
    let type = nodeType.toLowerCase();
    if (type === 'nscode') {
      dispatch(searchForNsCodes(data, selectedIds));
    } else if (type === 'package') {
      dispatch(searchForPackages(data, selectedIds));
    } else if (type === 'product') {
      dispatch(searchForProducts(data, selectedIds));
    } else if (type === 'hour') {
      dispatch(searchForWorkCategories(data, selectedIds));
    } else if (type === 'piecework') {
    }
  };
};
