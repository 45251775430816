import { IMomTreeState } from '../interfaces/MomTreeInterfaces';
import {
  SELECT_MOM_TREE_ROW,
  SET_SELECTED_TEMPLATE_ID,
  SET_SELECTED_TEMPLATE_CATALOG_ID,
  LOAD_ALL_TEMPLATE_CATALOGS,
  GET_TEMPLATE_CONTENT_BY_ID,
  LOAD_MOM_TEMPLATES_BY_CATALOG_ID,
  MOM_SELECT_NODE,
  MOM_DESELECT_NODE,
  MOM_SET_SELECTED_NODES_CONTAINERS,
  MOM_RESET_MODULE,
  MOM_SELECT_ALL_NODES,
  MOM_DESELECT_ALL_NODES,
  MOM_SET_NODES_COUNT,
} from './MomTreeActions';

const initialState: IMomTreeState = {
  selectedRow: null,
  selectedTemplateId: null,
  selectedTemplateCatalogId: null,
  momTemplateCatalogs: [],
  selectedTemplate: [],
  allMomTemplates: [],
  selectedNodesIds: {},
  idsOfSelectedNodesContainers: {},
  numberOfNodes: 0,
  numberOfSelectedNodes: 0,
};

const MomTreeReducer = (state = initialState, { type, payload }: any) => {
  const newState = () => ({ ...state, ...payload });

  const resetModule = () => initialState;

  const stateActions = {
    [SELECT_MOM_TREE_ROW]: newState,
    [SET_SELECTED_TEMPLATE_ID]: newState,
    [SET_SELECTED_TEMPLATE_CATALOG_ID]: newState,
    [LOAD_ALL_TEMPLATE_CATALOGS]: newState,
    [GET_TEMPLATE_CONTENT_BY_ID]: newState,
    [LOAD_MOM_TEMPLATES_BY_CATALOG_ID]: newState,
    [MOM_SELECT_NODE]: newState,
    [MOM_DESELECT_NODE]: newState,
    [MOM_SET_SELECTED_NODES_CONTAINERS]: newState,
    [MOM_SELECT_ALL_NODES]: newState,
    [MOM_DESELECT_ALL_NODES]: newState,
    [MOM_RESET_MODULE]: resetModule,
    [MOM_SET_NODES_COUNT]: newState,
  };

  const currentState = stateActions[type];
  if (!currentState) return state;
  return currentState();
};

export default MomTreeReducer;
