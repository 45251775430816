import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type FileOpenFilledCirclePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const FileOpenFilledCircle: FC<FileOpenFilledCirclePropTypes> = ({
  width = 56,
  height = 56,
  color = '#0975A5',
  bgColor = '#F8F8F8',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  const resolvedBgColor = resolveThemeColor(bgColor, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill={resolvedBgColor} />
      <path
        d="M30.6831 14.4619H19.7358C18.2306 14.4619 16.999 15.6935 16.999 17.1987V39.0932C16.999 40.5984 18.2169 41.83 19.7221 41.83H32.0515V30.8827H38.8935V22.6723L30.6831 14.4619ZM29.3146 24.0407V16.5145L36.8409 24.0407H29.3146ZM34.7883 41.3647V33.6196H42.5334V36.3564H39.4682L43.505 40.3931L41.5755 42.3226L37.5251 38.2858V41.351H34.7883V41.3647Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default FileOpenFilledCircle;
