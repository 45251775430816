import { FC } from 'react';

type MenuIconRangeIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconRangeIcon: FC<MenuIconRangeIconPropTypes> = ({
  width = 42,
  height = 42,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4905_15600)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        d="M16.1692 15.7965V16.8812C16.1692 17.1795 15.9251 17.4235 15.6268 17.4235H15.0845V19.5929H15.6268C15.9251 19.5929 16.1692 19.837 16.1692 20.1353V21.22C16.1692 22.1149 16.9013 22.847 17.7962 22.847H19.4233V21.7623H17.7962C17.4979 21.7623 17.2539 21.5183 17.2539 21.22V20.1353C17.2539 19.4302 16.7983 18.8228 16.1692 18.6004V18.416C16.7983 18.1937 17.2539 17.5862 17.2539 16.8812V15.7965C17.2539 15.4982 17.4979 15.2541 17.7962 15.2541H19.4233V14.1694H17.7962C16.9013 14.1694 16.1692 14.9016 16.1692 15.7965Z"
        fill={color}
      />
      <path
        d="M25.3892 17.4235C25.0909 17.4235 24.8469 17.1795 24.8469 16.8812V15.7965C24.8469 14.9016 24.1147 14.1694 23.2198 14.1694H21.5928V15.2541H23.2198C23.5181 15.2541 23.7622 15.4982 23.7622 15.7965V16.8812C23.7622 17.5862 24.2177 18.1937 24.8469 18.416V18.6004C24.2177 18.8228 23.7622 19.4248 23.7622 20.1353V21.22C23.7622 21.5183 23.5181 21.7623 23.2198 21.7623H21.5928V22.847H23.2198C24.1147 22.847 24.8469 22.1149 24.8469 21.22V20.1353C24.8469 19.837 25.0909 19.5929 25.3892 19.5929H25.9316V17.4235H25.3892Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_4905_15600"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4905_15600" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4905_15600" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconRangeIcon;
