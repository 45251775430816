import { Box, Button, Checkbox, styled } from '@mui/material';

import NewDatePicker from 'components/NewDatePicker/NewDatePicker';

export const DynamicFilterWrapper = styled(Box)<any>`
  max-height: calc(100% - 20%);
  overflow: auto;
  padding: 0 10px;
  padding-bottom: 10px;
  content: '';
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #046b9938;
  }
`;

export const FilterButtonResetWrapper = styled(Button)<any>`
  border-radius: 8px;
  background: #5ed97961;
  color: #00684f;
  boxshadow: none;
  padding: 1;
  width: 100%;
  & .MuiButton-root:hover {
    background: #00684f;
    color: #e6f0f5;
    border-radius: 8px;
  }
`;

export const FilterButtonAcceptWrapper = styled(Button)<any>`
  position: sticky;
  border-radius: 8px;
  background: #e6f0f5;
  color: #046b99;
  boxshadow: none;
  heigt: 40px;
  padding: 1;
  width: 100%;
  &.MuiButton-root:hover {
    background: #046b99;
    color: #e6f0f5;
    border-radius: 8px;
  }
`;

export const CatalogButtonAcceptWrapper = styled(Button)<any>`
  position: sticky;
  border-radius: 8px;
  background: #32226b;
  color: #fefefe;
  boxshadow: none;
  padding: 1;
  width: 100%;
  &.MuiButton-root:hover {
    background: light-grey;
    color: #f3eefe;
    border-radius: 8px;
  }
`;

export const CatalogButtonAcceptWrapperBlue = styled(Button)<any>`
  position: sticky;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.primary.main};
  color: #fefefe;
  boxshadow: none;
  padding: 1;
  width: 100%;
  &.MuiButton-root:hover {
    background: ${({ theme }) => theme.palette.primary.dark};
    color: #f3eefe;
    border-radius: 8px;
  }
`;

export const FilterCheckboxWrapper = styled(Checkbox)<any>`
  &.MuiCheckbox-root {
    color: #e6f0f5;
  }
  &.Mui-checked {
    color: #3689ad;
  }
  &.MuiCheckbox-root:hover {
    color: #3689ad;
  }
`;

export const FilterTotalsInputWrapper = styled(Button)<any>`
  border: 2px solid #efefef;
  border-radius: 10.24px !important;
  box-shadow: none;
  max-height: 60px;
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;

export const SpecificationButtonAcceptWrapper = styled(Button)`
  position: sticky;
  border-radius: 8px;
  background: #046b99;
  color: #fcfcfc;
  boxshadow: none;
  padding: 0;
  height: 48px;
  width: 100%;
  &.MuiButton-root:hover {
    background: #046b99;
    color: #e6f0f5;
    border-radius: 8px;
  }
`;

export const SpecificationCancelButtonWrapper = styled(Button)<any>`
  variant: outlined;
  border: 2px solid #acacac;
  border-radius: 8px;
  color: black;
  background: none;
  boxshadow: none;
  padding: 0;
  height: 48px;
  width: 100%;
  &.MuiButton-root:hover {
    background: none;
    color: #acacac;
    border-radius: 8px;
  }
`;

export const DatePickerStyled = styled(NewDatePicker)<any>`
  border: 2px solid #efefef;
  border-radius: 10.24px !important;
  box-shadow: none;
`;
