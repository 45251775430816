import { IOrderOfferNode, IOrderOfferPostBodyItem } from './orderHandlingInterface';

export function hasItemsToSend(item: IOrderOfferNode): boolean {
  return item.hasOwnProperty('itemsToSend') && item.itemsToSend !== undefined;
}

export function hasRemainingGreaterThanZero(item: IOrderOfferNode): boolean {
  return item.hasOwnProperty('remaining') && item.remaining! > 0;
}

export function hasProductNodeType(item: IOrderOfferNode): boolean {
  return item.nodeType === 'product';
}
export function hasChildren(item: IOrderOfferNode): boolean {
  return item.hasOwnProperty('children') && Array.isArray(item.children) && item.children.length > 0;
}

export const getProductsWithRemaining = (items: Array<IOrderOfferNode>): Array<IOrderOfferNode> => {
  return items.filter((item) => {
    if (hasProductNodeType(item)) {
      if (hasItemsToSend(item) || hasRemainingGreaterThanZero(item)) {
        return true;
      }
    }
    if (item.hasOwnProperty('children') && Array.isArray(item.children)) {
      const filteredChildren = getProductsWithRemaining(item.children);
      return filteredChildren.length > 0;
    }
    return false;
  });
};

export function getProductsForPostBody(items: Array<IOrderOfferNode>): Array<IOrderOfferPostBodyItem> {
  const flattenedItems: Array<IOrderOfferPostBodyItem> = [];
  for (const item of items) {
    const newItem = {
      nodeId: item.nodeId,
      quantity: hasItemsToSend(item) ? item.itemsToSend! : item.remaining,
      deliveryMethod: item?.deliveryMethod ?? null,
    };
    if (newItem.quantity > 0) flattenedItems.push(newItem);
    if (hasChildren(item)) {
      const childrenItems = getProductsForPostBody(item.children!);
      flattenedItems.push(...childrenItems);
    }
  }
  return flattenedItems;
}
