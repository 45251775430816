import { FC } from 'react';

type DividedCircleIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  background?: string;
  [x: string]: any;
};

export const DividedCircleIcon: FC<DividedCircleIconPropTypes> = ({ width = 48, height = 48, color = '#32226B', background = '#F3EEFE', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11.2C0 5.01441 5.01441 0 11.2 0H36.8C42.9856 0 48 5.01441 48 11.2V36.8C48 42.9856 42.9856 48 36.8 48H11.2C5.01441 48 0 42.9856 0 36.8V11.2Z" fill={background}/>
        <path d="M23 14V34C17.93 33.5 14 29.21 14 24C14 18.79 17.93 14.5 23 14ZM25.03 14V22.99H34C33.53 18.25 29.76 14.47 25.03 14ZM25.03 25.01V34C29.77 33.53 33.53 29.75 34 25.01H25.03Z" fill={color}/>
    </svg>
  );
};

export default DividedCircleIcon;
