import { FC } from 'react';

type MenuIconThreeDotIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconThreeDotIcon: FC<MenuIconThreeDotIconPropTypes> = ({
  width = 42,
  height = 42,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4354_22750)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        d="M20.9999 21.6667C20.2666 21.6667 19.6666 22.2667 19.6666 23C19.6666 23.7333 20.2666 24.3333 20.9999 24.3333C21.7333 24.3333 22.3333 23.7333 22.3333 23C22.3333 22.2667 21.7333 21.6667 20.9999 21.6667ZM20.9999 20.3333C21.7333 20.3333 22.3333 19.7333 22.3333 19C22.3333 18.2667 21.7333 17.6667 20.9999 17.6667C20.2666 17.6667 19.6666 18.2667 19.6666 19C19.6666 19.7333 20.2666 20.3333 20.9999 20.3333ZM20.9999 16.3333C21.7333 16.3333 22.3333 15.7333 22.3333 15C22.3333 14.2667 21.7333 13.6667 20.9999 13.6667C20.2666 13.6667 19.6666 14.2667 19.6666 15C19.6666 15.7333 20.2666 16.3333 20.9999 16.3333Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_4354_22750"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4354_22750" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4354_22750" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconThreeDotIcon;
