import { FC } from 'react';

type OfferCircleIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const OfferCircleIcon: FC<OfferCircleIconPropTypes> = ({
  width = 34,
  height = 34,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#E6F0F5" />
      <path
        d="M21.6667 12.3333V21.6667H12.3333V12.3333H21.6667ZM22.4 11H11.6C11.2667 11 11 11.2667 11 11.6V22.4C11 22.6667 11.2667 23 11.6 23H22.4C22.6667 23 23 22.6667 23 22.4V11.6C23 11.2667 22.6667 11 22.4 11ZM16.3333 13.6667H20.3333V15H16.3333V13.6667ZM16.3333 16.3333H20.3333V17.6667H16.3333V16.3333ZM16.3333 19H20.3333V20.3333H16.3333V19ZM13.6667 13.6667H15V15H13.6667V13.6667ZM13.6667 16.3333H15V17.6667H13.6667V16.3333ZM13.6667 19H15V20.3333H13.6667V19Z"
        fill={color}
      />
    </svg>
  );
};

export default OfferCircleIcon;
