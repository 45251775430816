import { FC } from 'react';

type RemoveSectionsPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const RemoveSections: FC<RemoveSectionsPropTypes> = ({
  width = 70,
  height = 45,
  color = '#777777',
  ...rest
}) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1667 5.50001L9.16667 10.5M9.16667 5.50001L14.1667 10.5M2.26667 8.80001L5.86667 13.6C6.16 13.9911 6.30667 14.1867 6.49254 14.3277C6.65717 14.4526 6.8436 14.5458 7.04231 14.6026C7.26667 14.6667 7.51111 14.6667 8 14.6667H14.3333C15.7335 14.6667 16.4335 14.6667 16.9683 14.3942C17.4387 14.1545 17.8212 13.7721 18.0609 13.3017C18.3333 12.7669 18.3333 12.0668 18.3333 10.6667V5.33334C18.3333 3.93321 18.3333 3.23315 18.0609 2.69837C17.8212 2.22796 17.4387 1.84551 16.9683 1.60583C16.4335 1.33334 15.7335 1.33334 14.3333 1.33334H8C7.51111 1.33334 7.26667 1.33334 7.04231 1.39742C6.8436 1.45417 6.65717 1.54738 6.49254 1.6723C6.30667 1.81335 6.16 2.0089 5.86667 2.40001L2.26667 7.20001C2.05151 7.48689 1.94392 7.63033 1.90245 7.78787C1.86585 7.92693 1.86585 8.07309 1.90245 8.21215C1.94392 8.36969 2.05151 8.51313 2.26667 8.80001Z"
        stroke="#777777"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemoveSections;
