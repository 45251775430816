import { FC } from 'react';

type WorkIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const WorkIcon: FC<WorkIconPropTypes> = ({ width = 60, height = 60, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 14C0.5 6.26801 6.76801 0 14.5 0H46.5C54.232 0 60.5 6.26801 60.5 14V46C60.5 53.732 54.232 60 46.5 60H14.5C6.76801 60 0.5 53.732 0.5 46V14Z" fill="#E0EDF2"/>
    <path d="M43.2965 38.9835L36.009 31.696H34.6478L31.1553 35.1885V36.5498L38.4428 43.8373C38.979 44.3735 39.8453 44.3735 40.3815 43.8373L43.2965 40.9223C43.8328 40.3998 43.8328 39.5198 43.2965 38.9835Z" fill={color}/>
    <path d="M37.3425 28.0112L39.2812 26.0725L42.1963 28.9875C43.805 27.3787 43.805 24.7662 42.1963 23.1575L37.3288 18.29L35.39 20.2288V16.3513L34.4275 15.375L29.56 20.2425L30.5363 21.2188H34.4275L32.4888 23.1575L33.9462 24.615L29.9725 28.5887L24.2937 22.91V20.9575L20.1412 16.805L16.25 20.6962L20.4163 24.8625H22.355L28.0337 30.5412L26.865 31.71H23.95L16.6625 38.9975C16.1262 39.5338 16.1262 40.4 16.6625 40.9363L19.5775 43.8512C20.1137 44.3875 20.98 44.3875 21.5162 43.8512L28.8038 36.5637V33.6488L35.885 26.5675L37.3425 28.0112Z" fill="#046B99"/>
    </svg>
  );
};

export default WorkIcon;
