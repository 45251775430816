import { FC } from 'react';

type EmptyStateTemplatesIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const EmptyStateTemplatesIcon: FC<EmptyStateTemplatesIconPropTypes> = ({
  width = 210,
  height = 207,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 210 207" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.7957 200.103H167.201C170.621 200.111 173.917 198.819 176.429 196.486C186.909 186.724 195.293 174.909 201.066 161.767C206.838 148.625 209.877 134.433 209.995 120.066C210.532 61.6446 163.327 13.5747 105.253 13.4368C47.1471 13.2989 6.89693e-05 60.6451 6.89693e-05 119.069C-0.0165202 133.611 2.95972 147.998 8.74138 161.325C14.523 174.653 22.9849 186.632 33.5941 196.507C36.1011 198.83 39.3876 200.114 42.7957 200.103Z"
        fill="#EFF1F8"
      />
      <path
        d="M163.092 22.9492H47.1003C43.6392 22.9492 40.8335 25.755 40.8335 29.216V193.859C40.8335 197.32 43.6392 200.126 47.1003 200.126H163.092C166.553 200.126 169.359 197.32 169.359 193.859V29.216C169.359 25.755 166.553 22.9492 163.092 22.9492Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="2.91667"
        strokeMiterlimit="10"
      />
      <path
        d="M157.689 29.9902H52.6927C50.4386 29.9902 48.6113 31.8175 48.6113 34.0716V188.934C48.6113 191.188 50.4386 193.016 52.6927 193.016H157.689C159.943 193.016 161.77 191.188 161.77 188.934V34.0716C161.77 31.8175 159.943 29.9902 157.689 29.9902Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.94444"
        strokeMiterlimit="10"
      />
      <path
        d="M121.958 56.5186H63.8142C61.9652 56.5186 60.4663 58.0175 60.4663 59.8665C60.4663 61.7155 61.9652 63.2144 63.8142 63.2144H121.958C123.807 63.2144 125.306 61.7155 125.306 59.8665C125.306 58.0175 123.807 56.5186 121.958 56.5186Z"
        fill="#D4D9E3"
      />
      <path
        d="M63.8142 84.6035H146.264C147.152 84.6035 148.004 84.9562 148.631 85.5841C149.259 86.212 149.612 87.0635 149.612 87.9514C149.612 88.8394 149.259 89.6909 148.631 90.3188C148.004 90.9466 147.152 91.2993 146.264 91.2993H63.8142C62.9263 91.2993 62.0747 90.9466 61.4469 90.3188C60.819 89.6909 60.4663 88.8394 60.4663 87.9514C60.4663 87.5118 60.5529 87.0764 60.7211 86.6702C60.8894 86.2641 61.136 85.895 61.4469 85.5841C61.7578 85.2732 62.1268 85.0266 62.533 84.8584C62.9392 84.6901 63.3746 84.6035 63.8142 84.6035Z"
        fill="#D4D9E3"
      />
      <path
        d="M146.264 112.688H63.8142C61.9652 112.688 60.4663 114.187 60.4663 116.036C60.4663 117.885 61.9652 119.384 63.8142 119.384H146.264C148.113 119.384 149.612 117.885 149.612 116.036C149.612 114.187 148.113 112.688 146.264 112.688Z"
        fill="#D4D9E3"
      />
      <path
        d="M146.264 140.773H63.8142C61.9652 140.773 60.4663 142.272 60.4663 144.121C60.4663 145.97 61.9652 147.469 63.8142 147.469H146.264C148.113 147.469 149.612 145.97 149.612 144.121C149.612 142.272 148.113 140.773 146.264 140.773Z"
        fill="#D4D9E3"
      />
      <path
        d="M121.959 168.858H63.8142C61.9652 168.858 60.4663 170.357 60.4663 172.206V172.206C60.4663 174.055 61.9652 175.554 63.8142 175.554H121.959C123.808 175.554 125.306 174.055 125.306 172.206C125.306 170.357 123.808 168.858 121.959 168.858Z"
        fill="#D4D9E3"
      />
      <path
        d="M133.058 14.3548H124.635C122.9 14.3395 121.205 13.8319 119.748 12.891C118.29 11.9502 117.13 10.6147 116.401 9.04013C115.397 6.93277 113.815 5.15311 111.841 3.90718C109.867 2.66124 107.58 2 105.245 2C102.91 2 100.623 2.66124 98.649 3.90718C96.6746 5.15311 95.0935 6.93277 94.0886 9.04013C93.3603 10.6147 92.1999 11.9502 90.7423 12.891C89.2847 13.8319 87.5899 14.3395 85.8551 14.3548H77.4319C76.2345 14.3548 75.0861 14.8305 74.2394 15.6772C73.3927 16.5239 72.917 17.6723 72.917 18.8697V36.0758C72.917 37.2733 73.3927 38.4217 74.2394 39.2684C75.0861 40.1151 76.2345 40.5908 77.432 40.5908H133.058C134.255 40.5908 135.404 40.1151 136.251 39.2684C137.097 38.4216 137.573 37.2732 137.573 36.0758V18.8698C137.573 17.6723 137.097 16.5239 136.251 15.6772C135.404 14.8305 134.256 14.3548 133.058 14.3548Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="2.91667"
        strokeMiterlimit="10"
      />
      <path
        d="M131.341 32.9736H81.4117C80.429 32.9736 79.6323 33.7703 79.6323 34.753V34.753C79.6323 35.7357 80.429 36.5323 81.4117 36.5323H131.341C132.324 36.5323 133.121 35.7357 133.121 34.753C133.121 33.7703 132.324 32.9736 131.341 32.9736Z"
        fill="#D4D9E3"
      />
      <path
        d="M140.284 194.39H140.284H140.284C134.28 186.527 131.671 176.611 134.379 163.481C134.477 163.031 134.694 162.616 135.006 162.277C135.318 161.938 135.715 161.689 136.156 161.555C136.596 161.421 137.065 161.407 137.513 161.514C137.961 161.621 138.372 161.845 138.705 162.164C139.759 163.157 140.947 163.998 142.234 164.662C139.387 153.721 140.233 143.567 146.925 134.656C147.217 134.266 147.614 133.967 148.069 133.793C148.523 133.619 149.018 133.576 149.496 133.671C149.974 133.765 150.416 133.992 150.77 134.326C151.125 134.66 151.378 135.087 151.501 135.559C152.161 138.126 153.127 140.604 154.379 142.94C152.061 132.88 154.147 123.072 160.72 113.521C160.983 113.133 161.346 112.824 161.771 112.626C162.195 112.428 162.666 112.349 163.132 112.397C163.598 112.445 164.043 112.619 164.418 112.899C164.793 113.18 165.086 113.557 165.264 113.99C165.832 115.458 166.655 116.814 167.694 117.997C174.907 103.751 184.154 94.3705 197.265 94.236C197.646 94.2321 198.024 94.312 198.371 94.4701C198.718 94.6281 199.026 94.8605 199.273 95.1507C199.52 95.441 199.701 95.782 199.802 96.1497C199.903 96.5174 199.922 96.9028 199.858 97.2787C197.632 110.503 190.995 121.926 180.339 131.706C184.746 131.042 188.992 129.572 192.867 127.37C193.102 127.241 193.373 127.194 193.637 127.238C193.902 127.281 194.144 127.412 194.325 127.609C194.507 127.807 194.616 128.059 194.637 128.326C194.658 128.594 194.588 128.86 194.44 129.083C184.104 144.565 171.802 156.791 156.491 164.03C159.528 164.365 162.697 163.468 165.965 161.64C166.272 161.471 166.623 161.4 166.972 161.436C167.32 161.473 167.649 161.615 167.915 161.844C168.18 162.073 168.368 162.378 168.455 162.717C168.542 163.057 168.523 163.415 168.4 163.743C163.541 176.538 155.208 185.55 144.532 192.01"
        fill="#0975A5"
      />
      <path
        d="M144.748 191.279C149.141 176.043 157.841 156.357 171.028 134.373C173.532 130.202 175.744 125.862 177.648 121.385C177.699 121.262 177.703 121.125 177.659 120.999C177.615 120.874 177.526 120.769 177.409 120.706C177.292 120.642 177.155 120.625 177.026 120.656C176.897 120.688 176.784 120.767 176.709 120.877C161.439 143.703 149.196 168.413 140.285 194.39C140.265 194.364 140.246 194.338 140.227 194.312C139.245 198.199 138.502 202.143 138.004 206.122C137.985 206.285 138.025 206.45 138.116 206.586C138.207 206.723 138.343 206.823 138.501 206.869C138.659 206.915 138.828 206.904 138.978 206.837C139.128 206.77 139.249 206.652 139.321 206.504L139.71 205.7C140.481 204.108 141.135 202.462 141.667 200.774L144.541 191.669L144.748 191.279Z"
        fill="#0975A5"
      />
      <path
        d="M144.748 191.279C149.141 176.043 157.841 156.357 171.028 134.373C173.532 130.202 175.744 125.862 177.648 121.385C177.699 121.262 177.703 121.125 177.659 120.999C177.615 120.874 177.526 120.769 177.409 120.706C177.292 120.642 177.155 120.625 177.026 120.656C176.897 120.688 176.784 120.767 176.709 120.877C161.439 143.703 149.196 168.413 140.285 194.39C140.265 194.364 140.246 194.338 140.227 194.312C139.245 198.199 138.502 202.143 138.004 206.122C137.985 206.285 138.025 206.45 138.116 206.586C138.207 206.723 138.343 206.823 138.501 206.869C138.659 206.915 138.828 206.904 138.978 206.837C139.128 206.77 139.249 206.652 139.321 206.504L139.71 205.7C140.481 204.108 141.135 202.462 141.667 200.774L144.541 191.669L144.748 191.279Z"
        fill="black"
        fillOpacity="0.15"
      />
    </svg>
  );
};

export default EmptyStateTemplatesIcon;
