import { Typography } from '@mui/material';
import { FlexColumnStart, FlexStart, IconWrapper } from 'components/Stylings';
import { FC } from 'react';
import { FlexCard } from './Card.styled';

type CardProps = {
  description: React.ReactNode;
  title?: string;
  icon?: JSX.Element;
  header?: string;
};

const Card: FC<CardProps> = ({ title, description, header, icon: Icon }) => {
  return (
    <FlexCard>
      <FlexColumnStart>
        {header && (
          <Typography variant="h4" mb={2}>
            {header}
          </Typography>
        )}
        <FlexStart>
          {Icon ? (
            <IconWrapper className="card_icon_wrapper" sx={{ minWidth: 56, minHeight: 56 }}>
              {Icon}
            </IconWrapper>
          ) : null}
          <FlexColumnStart ml={2} sx={{ gap: 0.5 }}>
            {title ? (
              <Typography variant="h3" lineHeight={1.5}>
                {title}
              </Typography>
            ) : null}
            {description}
          </FlexColumnStart>
        </FlexStart>
      </FlexColumnStart>
    </FlexCard>
  );
};

export default Card;
