export interface IOption {
  label: string;
  value: string | number;
}

///////////////////////////////Offers Filter///////////////////////////////////////////////////////

export const dateOptionsProps: Array<IOption> = [
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Sent to customer',
    value: 'SentToCustomer',
  },
  {
    label: 'Follow up date',
    value: 'PlannedFollowUpDate',
  },
  {
    label: 'Accepted by customer',
    value: 'AcceptedByCustomer',
  },
  {
    label: 'Rejected by customer',
    value: 'RejectedByCustomer',
  },
];

export const statusesTagProps: Array<any> = [
  {
    tagName: 'Active',
    isSelected: false,
  },
  {
    tagName: 'Inactive',
    isSelected: false,
  },
  {
    tagName: 'Ready to pay',
    isSelected: false,
  },
  {
    tagName: 'Deadline near',
    isSelected: false,
  },
];

export const categoriesProps: Array<IOption> = [
  {
    label: 'Offers',
    value: 'offers',
  },
];

export const totalsProps: Array<IOption> = [
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Customer amount',
    value: 'CustomerAmount',
  },
  {
    label: 'Contribution',
    value: 'Contribution',
  },
];

///////////////////////////////////////////////////////////////////////////////////////////////////
