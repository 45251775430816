import { TextField, styled } from '@mui/material';

import { NewAutoComplete } from 'components';

export const TextFieldSearchBar = styled(TextField)<any>(
  () => `
      border: none;
      background: white;
      border-radius: 10.24px !important;
      height: 46px;
      width: 100%;
      box-shadow: none;
      .MuiOutlinedInput-notchedOutline { border: 0 };
      & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline { border: 0 };
      .MuiInputBase-input { height: 5px }
  `
);

export const NewAutoCompleteStyled = styled(NewAutoComplete)(
  () => `
    border: 2px solid #efefef;
    background: white;
    border-radius: 10.24px !important;
    align-items: center;
    height: 46px;
    // min-width: 363px; // unknown why this was neccessary. 
    //commenting out temporary to fix horizontal scroll in Dynamic filter
    box-shadow: none;
`
);

export const TextFieldStyledSearch = styled(TextField)(
  () => `
    border: 2px solid #efefef;
    background: white;
    border-radius: 10.24px !important;
    height: 46px;
    width: 100%;
    box-shadow: none;
    line-height: 0px;
    .MuiOutlinedInput-notchedOutline { 
      border: 0; 
    };
    .MuiInputBase-input { 
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }
    & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline { 
      border: 0;
    };
`
);

export const NewTextFieldStyled = styled(TextField)(
  () => `
    border: 2px solid #efefef;
    background: white;
    border-radius: 10.24px !important;
    align-items: left;
    height: 46px;
    width: 100%;
    box-shadow: none;
    .MuiOutlinedInput-notchedOutline { border: 0 };
    & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline { border: 0 };
`
);
