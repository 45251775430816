import { FC } from 'react';

type MenuIconChapterIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconChapterIcon: FC<MenuIconChapterIconPropTypes> = ({
  width = 35,
  height = 35,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6074_24144)">
        <rect x="5" y="4" width="24.3956" height="24.3956" rx="12.1978" fill="white" />
        <rect x="5" y="4" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637" />
      </g>
      <path
        d="M20.8568 10.0989H13.5381C12.8673 10.0989 12.3184 10.6478 12.3184 11.3187V21.077C12.3184 21.7478 12.8673 22.2967 13.5381 22.2967H20.8568C21.5277 22.2967 22.0766 21.7478 22.0766 21.077V11.3187C22.0766 10.6478 21.5277 10.0989 20.8568 10.0989ZM13.5381 11.3187H16.5876V16.1978L15.0629 15.283L13.5381 16.1978V11.3187Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_6074_24144"
          x="0.121078"
          y="0.747268"
          width="34.1538"
          height="34.1538"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.62637" />
          <feGaussianBlur stdDeviation="2.03297" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6074_24144" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6074_24144" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconChapterIcon;
