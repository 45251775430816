import { FC } from 'react';

type DeletePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Delete: FC<DeletePropTypes> = ({ width = 22, height = 22, color = '#5A6E81', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.50016 17.4167C5.50016 18.425 6.32516 19.25 7.3335 19.25H14.6668C15.6752 19.25 16.5002 18.425 16.5002 17.4167V6.41667H5.50016V17.4167ZM17.4168 3.66667H14.2085L13.2918 2.75H8.7085L7.79183 3.66667H4.5835V5.5H17.4168V3.66667Z"
        fill={color}
      />
    </svg>
  );
};

export default Delete;
