import { FC } from 'react';

type TagsIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const TagsIcon: FC<TagsIconPropTypes> = ({ width = 34, height = 34, color = '#0975A5', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#F4F7FE" />
      <path
        d="M10.2286 22.4035L11.2359 22.8245V16.0366L9.40924 20.4416C9.10104 21.2083 9.46938 22.0878 10.2286 22.4035ZM24.8868 19.6222L21.1583 10.6244C20.9253 10.0606 20.3766 9.7148 19.7977 9.69977C19.6023 9.69977 19.3993 9.72984 19.2039 9.81252L13.6639 12.1052C13.1001 12.3382 12.7543 12.8795 12.7393 13.4583C12.7318 13.6612 12.7693 13.8642 12.852 14.0596L16.5805 23.0575C16.8135 23.6288 17.3698 23.9746 17.9561 23.9821C18.1515 23.9821 18.347 23.9445 18.5349 23.8693L24.0674 21.5766C24.8341 21.2609 25.2025 20.3814 24.8868 19.6222ZM14.2502 14.21C13.8368 14.21 13.4985 13.8717 13.4985 13.4583C13.4985 13.0448 13.8368 12.7066 14.2502 12.7066C14.6636 12.7066 15.0019 13.0448 15.0019 13.4583C15.0019 13.8717 14.6636 14.21 14.2502 14.21ZM12.7468 22.4787C12.7468 23.3056 13.4233 23.9821 14.2502 23.9821H15.3402L12.7468 17.7129V22.4787Z"
        fill={color}
      />
    </svg>
  );
};

export default TagsIcon;
