import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type PowerPointIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const PowerPointIcon: FC<PowerPointIconPropTypes> = ({
  width = 54,
  height = 54,
  color = '#0774A4',
  bgColor = '#F4F7FE',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  const resolvedBgColor = resolveThemeColor(bgColor, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="52.8417" height="52.8417" rx="12" fill={resolvedBgColor} />
      <path
        d="M25.5537 34.6313C25.5537 36.1365 24.3221 37.3681 22.8169 37.3681C21.3116 37.3681 20.0801 36.1365 20.0801 34.6313C20.0801 33.126 21.3116 31.8945 22.8169 31.8945C24.3221 31.8945 25.5537 33.126 25.5537 34.6313ZM22.8169 23.684C21.3116 23.684 20.0801 24.9156 20.0801 26.4209C20.0801 27.9261 21.3116 29.1577 22.8169 29.1577C24.3221 29.1577 25.5537 27.9261 25.5537 26.4209C25.5537 24.9156 24.3221 23.684 22.8169 23.684ZM22.8169 15.4736C21.3116 15.4736 20.0801 16.7052 20.0801 18.2104C20.0801 19.7157 21.3116 20.9472 22.8169 20.9472C24.3221 20.9472 25.5537 19.7157 25.5537 18.2104C25.5537 16.7052 24.3221 15.4736 22.8169 15.4736ZM31.0273 20.9472C32.5325 20.9472 33.7641 19.7157 33.7641 18.2104C33.7641 16.7052 32.5325 15.4736 31.0273 15.4736C29.5221 15.4736 28.2905 16.7052 28.2905 18.2104C28.2905 19.7157 29.5221 20.9472 31.0273 20.9472ZM31.0273 23.684C29.5221 23.684 28.2905 24.9156 28.2905 26.4209C28.2905 27.9261 29.5221 29.1577 31.0273 29.1577C32.5325 29.1577 33.7641 27.9261 33.7641 26.4209C33.7641 24.9156 32.5325 23.684 31.0273 23.684ZM31.0273 31.8945C29.5221 31.8945 28.2905 33.126 28.2905 34.6313C28.2905 36.1365 29.5221 37.3681 31.0273 37.3681C32.5325 37.3681 33.7641 36.1365 33.7641 34.6313C33.7641 33.126 32.5325 31.8945 31.0273 31.8945Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default PowerPointIcon;
