import { PaletteColorKey } from 'theme';

import { TextField as MuiTextField, OutlinedInput, TextFieldProps, OutlinedInputProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type NewTextFieldProps = {
  hasBorder?: boolean;
  input?: 'textfield' | 'outlineInput';
  bgColor?: PaletteColorKey | string;
  padding?: number;
  bRadius?: number;
} & TextFieldProps &
  OutlinedInputProps;

type MuiTextFieldProps = {
  hasBorder?: boolean;
  bgColor?: PaletteColorKey | string;
  padding?: number;
  error?: boolean;
  bRadius?: number;
} & TextFieldProps;

const renderBorder = (hasBorder: boolean, error: boolean) => {
  if (error) return '1px solid #CC2500';
  if (hasBorder) return '1px solid #efefef';
  if (!hasBorder) return 0;
  if (hasBorder && !error) return '1px solid #fefefe';
  return '1px solid #CC2500';
};
const MuiTextFieldStyled = styled(MuiTextField, {
  shouldForwardProp: (props) =>
    props !== 'hasBorder' && props !== 'bgColor' && props !== 'error' && props !== 'padding' && props !== 'bRadius',
})<MuiTextFieldProps>(
  ({ theme, size, hasBorder = false, bgColor = 'white', error = false, padding = 2, bRadius = 2 }) => ({
    ...{
      input: {
        backgroundColor: bgColor,
        borderRadius: theme.spacing(bRadius) || theme.spacing(2),
        padding: theme.spacing(padding) || theme.spacing(1.5, 2),
        border: renderBorder(hasBorder, error),
      },
      fieldset: {
        border: '0',
      },
    },
  })
);

const OutlinedInputStyled = styled(OutlinedInput, {
  shouldForwardProp: (props) => props !== 'hasBorder' && props !== 'bgColor' && props !== 'error',
})<MuiTextFieldProps>(({ theme, size, hasBorder = false, bgColor = 'white', error = false }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.primary[bgColor],
  border: renderBorder(hasBorder, error),
  input: {
    borderRadius: '10px 0 10px 10px',
    padding: '9px 14px',
  },
  fieldset: {
    border: '0',
  },
}));
export const NewTextField = ({
  input = 'textfield',
  bgColor = 'white',
  error,
  ...props
}: NewTextFieldProps): ReactElement => {
  const theme = useTheme();
  const resolvedColor = resolveThemeColor(bgColor as string, theme);
  if (input === 'outlineInput') return <OutlinedInputStyled error={error} bgColor={resolvedColor} {...props} />;
  return <MuiTextFieldStyled error={error} bgColor={resolvedColor} {...props} />;
};

export default NewTextField;
