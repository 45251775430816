import React, { ReactElement } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { DrawerProps, Drawer as MuiDrawer } from '@mui/material';

// const drawerWidth = 250;

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => !['open', 'drawerWidth'].includes(prop),
})<any>(({ theme, open, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface IDesktopDrawer extends DrawerProps {
  open: boolean;
  drawerWidth: number;
}

const DesktopDrawer: React.FC<IDesktopDrawer> = (props): ReactElement => {
  const { open, children, drawerWidth, ...rest } = props;
  return (
    <Drawer open={open} drawerWidth={drawerWidth} {...rest}>
      {children}
    </Drawer>
  );
};

export default DesktopDrawer;
