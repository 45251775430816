import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  color?: string;
};

const Placeholder: FC<Props> = ({ color = 'softLightGrey' }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="caption" color={`primary.${color}`} lineHeight={1.5} sx={{ fontStyle: 'italic' }}>
      {t('globals.propertyNotSet')}
    </Typography>
  );
};

export default Placeholder;
