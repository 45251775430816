import { FC } from 'react';

type ArrowDownFilledPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ArrowDownFilled: FC<ArrowDownFilledPropTypes> = ({
  width = 13,
  height = 15,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M3.79165 6.25L6.49998 9.375L9.20831 6.25H3.79165Z" fill={color}/>
    </svg>
  );
};

export default ArrowDownFilled;
