import { FC } from 'react';

type MenuIconSearchIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconSearchIcon: FC<MenuIconSearchIconPropTypes> = ({
  width = 56,
  height = 56,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4453_23540)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7171 20.7671H23.2168L23.0394 20.5961C23.7994 19.7094 24.1921 18.4997 23.9768 17.2141C23.6791 15.4534 22.2098 14.0474 20.4364 13.8321C17.7574 13.5027 15.5028 15.7574 15.8321 18.4364C16.0474 20.2097 17.4534 21.6791 19.2141 21.9767C20.4998 22.1921 21.7094 21.7994 22.5961 21.0394L22.7671 21.2167V21.7171L25.4588 24.4087C25.7184 24.6684 26.1428 24.6684 26.4024 24.4087C26.6621 24.1491 26.6621 23.7247 26.4024 23.4651L23.7171 20.7671ZM19.9172 20.767C18.3402 20.767 17.0672 19.494 17.0672 17.917C17.0672 16.34 18.3402 15.067 19.9172 15.067C21.4942 15.067 22.7672 16.34 22.7672 17.917C22.7672 19.494 21.4942 20.767 19.9172 20.767Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_4453_23540"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4453_23540" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4453_23540" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconSearchIcon;
