import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import {
  SET_PERSONS_LOADER_ACTION,
  SAVE_PERSONS_ACTION,
  DELETE_PERSON_REQUEST_ACTION,
  DELETE_PERSON_SUCCESS_ACTION,
  IS_DELETED_PERSONS_LOADING_ACTION,
  LOAD_DELETED_PERSONS_ACTION,
  ADD_ERROR_MESSAGE_ACTION,
  RESTORE_PERSON_ACTION,
  LOAD_PERSON_ITEM_ACTION,
  UPDATE_PERSON_ITEM_ACTION,
  CREATE_PERSON_ACTION,
  GET_SEARCH_RESULT_ACTION,
} from './types';

import {
  IPersonItem,
  IUpdatePerson,
  IParams,
  IPersonsLoadResponse,
  IPersonsDeleteResponse,
  IPersonItemResponse,
  IUpdatePhone,
  IUpdateEmail,
  ISearchResult,
} from '../personsInterfaces';

import { addSuccessMessage, addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

export const SET_PERSONS_LOADER = 'SET_PERSONS_LOADER';
export const SAVE_PERSONS = 'SAVE_PERSONS';

export const DELETE_PERSON_REQUEST = 'DELETE_PERSON_REQUEST';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';

export const IS_DELETED_PERSONS_LOADING = 'IS_DELETED_PERSONS_LOADING';
export const LOAD_DELETED_PERSONS = 'LOAD_DELETED_PERSONS';

export const LOAD_PERSON_ITEM = 'LOAD_PERSON_ITEM';

export const UPDATE_PERSON_ITEM = 'UPDATE_PERSON_ITEM';

export const RESTORE_PERSON = 'RESTORE_PERSON';
export const CREATE_PERSON = 'CREATE_PERSON';

export const SAVE_SEARCH_RESULT = 'SAVE_SEARCH_RESULT';

export const setPersonsLoaderAction = (isLoading: boolean): SET_PERSONS_LOADER_ACTION => ({
  type: SET_PERSONS_LOADER,
  isLoading,
});

export const savePersonsAction = (allPersons: IPersonItem[], totalPersonsNumber: number): SAVE_PERSONS_ACTION => ({
  type: SAVE_PERSONS,
  allPersons,
  totalPersonsNumber,
});

export const deletePersonRequestAction = (id: string): DELETE_PERSON_REQUEST_ACTION => ({
  type: DELETE_PERSON_REQUEST,
  id,
});
export const deletePersonSuccessAction = (message: string, id: string): DELETE_PERSON_SUCCESS_ACTION => ({
  type: DELETE_PERSON_SUCCESS,
  message,
  id,
});

export const setDeletedPersonsLoadingAction = (isLoading: boolean): IS_DELETED_PERSONS_LOADING_ACTION => ({
  type: IS_DELETED_PERSONS_LOADING,
  isLoading,
});
export const loadDeletedPersonsAction = (deletedPersons: IPersonItem[]): LOAD_DELETED_PERSONS_ACTION => ({
  type: LOAD_DELETED_PERSONS,
  deletedPersons,
});

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const getPersonItemAction = (person: IPersonItem): LOAD_PERSON_ITEM_ACTION => ({
  type: LOAD_PERSON_ITEM,
  person,
});

export const restorePersonAction = (personId: string): RESTORE_PERSON_ACTION => ({
  type: RESTORE_PERSON,
  personId,
});

export const updatePersonAction = (person: IPersonItem): UPDATE_PERSON_ITEM_ACTION => ({
  type: UPDATE_PERSON_ITEM,
  person,
});

export const addPersonAction = (person: IPersonItem | null): CREATE_PERSON_ACTION => ({ type: CREATE_PERSON, person });

export const saveSearchedPersonAction = (searchResult: ISearchResult | null): GET_SEARCH_RESULT_ACTION => ({
  type: SAVE_SEARCH_RESULT,
  searchResult,
});

export const loadAllPersons = (params?: IParams) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;
  const countUrl = '/crm/api/persons/count';
  const allPersonsURL = `/crm/api/persons?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    dispatch(setPersonsLoaderAction(true));
    try {
      const countPromise = API.get(countUrl);
      const allPersonsData = API.get(allPersonsURL);
      const {
        data: { payload: persons },
      }: AxiosResponse<IPersonsLoadResponse> = await allPersonsData;
      const {
        data: { payload: totalPersonsNumber },
      } = await countPromise;
      dispatch(savePersonsAction(persons, totalPersonsNumber));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const loadDeletedPersons = (params?: IParams) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;
  const getDeletedPersonsURL = `crm/api/persons/deleted?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    dispatch(setDeletedPersonsLoadingAction(true));
    try {
      const countPromise = API.get(getDeletedPersonsURL);
      const {
        data: { payload: persons },
      }: AxiosResponse<IPersonsLoadResponse> = await countPromise;
      dispatch(loadDeletedPersonsAction(persons));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const deletePerson = (personId: string, isPermament: boolean = false) => {
  const deletePersonURL = `/crm/api/persons/${personId}?hardDelete=${isPermament}`;
  return async (dispatch: Function) => {
    dispatch(deletePersonRequestAction(personId));
    try {
      const response = API.delete(deletePersonURL, { data: personId });
      // const { data: { payload, message } }: AxiosResponse<IPersonsDeleteResponse> = await response;
      const data: AxiosResponse<IPersonsDeleteResponse> = await response;
      if (!data) {
        dispatch(addErrorMessageAction('Something unexpected happened'));
        return;
      }
      const {
        data: { payload, message },
      } = data;
      dispatch(deletePersonSuccessAction(message, payload.id!));
      dispatch(loadDeletedPersons());
      const successMessage = i18n.t('persons.successDeletePerson');
      dispatch(addSuccessMessage({ message: successMessage }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failDeletedPerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const restorePerson = (personId: string) => {
  const restorePersonURL = `crm/api/persons/deleted/${personId}`;
  return async (dispatch: Function) => {
    dispatch(setDeletedPersonsLoadingAction(true));
    try {
      const countPromise = API.put(restorePersonURL);
      await countPromise;
      dispatch(loadAllPersons());
      dispatch(loadDeletedPersons());
      const message = i18n.t('persons.successRestorePerson');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failrestorePerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const getPersonItem = (personId: string) => {
  const getPersonURL = `crm/api/persons/${personId}`;
  return async (dispatch: Function) => {
    dispatch(setPersonsLoaderAction(true));
    try {
      const updatePromise = API.get(getPersonURL);
      const {
        data: { payload: person },
      }: AxiosResponse<IPersonItemResponse> = await updatePromise;
      dispatch(getPersonItemAction(person));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const updatePersonItem = (personId: string, updatedPerson: IUpdatePerson) => {
  if (!personId) return null;
  const updatePersonUrl = `crm/api/persons/${personId}`;
  return async (dispatch: Function) => {
    dispatch(setDeletedPersonsLoadingAction(true));
    try {
      const updatePromise = API.put(updatePersonUrl, { ...updatedPerson });
      const {
        data: { payload: person },
      }: AxiosResponse<IPersonItemResponse> = await updatePromise;
      dispatch(updatePersonAction(person));
      dispatch(getPersonItem(personId));
      const message = i18n.t('persons.successUpdatePerson');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failUpdatePerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const addPerson = (person: IPersonItem, callback = () => {}) => {
  const addPersonUrl = 'crm/api/persons';

  return async (dispatch: Function) => {
    dispatch(setDeletedPersonsLoadingAction(true));
    try {
      const {
        data: { payload },
      } = await API.post(addPersonUrl, { ...person });
      dispatch(addPersonAction(payload));
      dispatch(loadAllPersons());
      callback();
      const message = i18n.t('persons.successAddPerson');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failAdddPerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

// export const searchForPerson = (searchString: string) => {
//   return async (dispatch: Function) => {
//     try {
//       dispatch(setPersonsLoaderAction(true));
//       const URL = `/search/api/persons?fieldsToSearch=lastName&fieldsToSearch=firstName&searchString=${searchString}`;
//       const payload: ISearchResult = await search(URL);
//       dispatch(saveSearchedPersonAction(payload));
//     } catch (e) {
//       const message = i18n.t('customers.errorSearchingForPerson');
//       dispatch(addErrorMessageAction({ message }));
//     } finally {
//       dispatch(setPersonsLoaderAction(false));
//     }
//   };
// };

export const editPhoneNumber = (personId: string, phoneId: string, data: IUpdatePhone) => {
  const editPhoneNumberUrl = `crm/api/persons/${personId}/TelephoneNumbers/${phoneId}`;
  return async (dispatch: Function) => {
    dispatch(setPersonsLoaderAction(true));
    try {
      await API.put(editPhoneNumberUrl, { ...data });
      dispatch(getPersonItem(personId));
      const message = i18n.t('persons.successEditPhoneNumber');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failEditPhoneNumber');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const deletePhoneNumber = (personId: string, phoneId: string) => {
  const deletePhoneNumberUrl = `crm/api/persons/${personId}/TelephoneNumbers/${phoneId}`;
  return async (dispatch: Function) => {
    dispatch(setPersonsLoaderAction(true));
    try {
      await API.delete(deletePhoneNumberUrl);
      dispatch(getPersonItem(personId));
      const message = i18n.t('persons.successDeletePhoneNumber');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failDeletePhoneNumber');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const editEmail = (personId: string, emailId: string, data: IUpdateEmail) => {
  const editEmailUrl = `crm/api/persons/${personId}/EmailAddresses/${emailId}`;
  return async (dispatch: Function) => {
    dispatch(setPersonsLoaderAction(true));
    try {
      await API.put(editEmailUrl, { ...data });
      dispatch(getPersonItem(personId));
      const message = i18n.t('persons.successEditEmail');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failEditEmail');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const deleteEmail = (personId: string, emailId: string) => {
  const deleteEmailUrl = `crm/api/persons/${personId}/EmailAddresses/${emailId}`;
  return async (dispatch: Function) => {
    dispatch(setPersonsLoaderAction(true));
    try {
      await API.delete(deleteEmailUrl);
      dispatch(getPersonItem(personId));
      const message = i18n.t('persons.successDeleteEmail');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.failDeleteEmail');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};

export const searchForPersons = (
  query: string,
  saveInSearched: boolean = false,
  params: IParams = { pageNumber: 0, pageSize: 15 }
) => {
  const searchForPersonsUrl = `search/api/persons/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: ['firstname', 'lastname', 'postalcode', 'postaladdress', 'address'],
  };
  return async (dispatch: Function) => {
    dispatch(setPersonsLoaderAction(true));
    try {
      const searchForPersonsPromise = API.post(searchForPersonsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForPersonsPromise;
      if (saveInSearched) dispatch(saveSearchedPersonAction({ hits, totalHits }));
      if (!saveInSearched) dispatch(savePersonsAction(hits, totalHits));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('persons.errorSearchingForPerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setPersonsLoaderAction(false));
    }
  };
};
