import { FC } from 'react';

type ThreeDotMenuVerticalIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ThreeDotMenuVerticalIcon: FC<ThreeDotMenuVerticalIconPropTypes> = ({
  width = 18,
  height = 18,
  color = '#140A36',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.5C10.5 4.32841 9.82841 5 9 5C8.17159 5 7.5 4.32841 7.5 3.5C7.5 2.67157 8.17159 2 9 2C9.82841 2 10.5 2.67157 10.5 3.5ZM9 10.25C9.82841 10.25 10.5 9.57841 10.5 8.75C10.5 7.92159 9.82841 7.25 9 7.25C8.17159 7.25 7.5 7.92159 7.5 8.75C7.5 9.57841 8.17159 10.25 9 10.25ZM9 15.5C9.82841 15.5 10.5 14.8284 10.5 14C10.5 13.1716 9.82841 12.5 9 12.5C8.17159 12.5 7.5 13.1716 7.5 14C7.5 14.8284 8.17159 15.5 9 15.5Z"
        fill={color}
      />
    </svg>
  );
};

export default ThreeDotMenuVerticalIcon;
