import { FC } from 'react';

type StatisticsPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Statistics: FC<StatisticsPropTypes> = ({ width, height, color = '#0091D2', ...rest }) => {
  return (
    <svg width={width || 65} height={height || 65} viewBox="0 0 65 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="58.8628"
        y="45.5704"
        width="45.5705"
        height="5.33286"
        rx="2.66643"
        transform="rotate(-90 58.8628 45.5704)"
        fill="#E9EDF7"
      />
      <rect
        x="58.8628"
        y="45.5704"
        width="10.8668"
        height="5.33286"
        rx="2.66643"
        transform="rotate(-90 58.8628 45.5704)"
        fill="#046B99"
      />
      <rect
        x="44.3438"
        y="45.5704"
        width="45.5705"
        height="5.33281"
        rx="2.66641"
        transform="rotate(-90 44.3438 45.5704)"
        fill="#E9EDF7"
      />
      <rect
        x="44.3438"
        y="45.5704"
        width="41.364"
        height="5.33287"
        rx="2.66643"
        transform="rotate(-90 44.3438 45.5704)"
        fill="#046B99"
      />
      <rect
        x="29.8257"
        y="45.5704"
        width="45.5705"
        height="5.33286"
        rx="2.66643"
        transform="rotate(-90 29.8257 45.5704)"
        fill="#E9EDF7"
      />
      <rect
        x="29.8257"
        y="45.5704"
        width="31.5488"
        height="5.33287"
        rx="2.66643"
        transform="rotate(-90 29.8257 45.5704)"
        fill="#046B99"
      />
      <rect
        x="15.3076"
        y="45.5704"
        width="45.5705"
        height="5.33287"
        rx="2.66644"
        transform="rotate(-90 15.3076 45.5704)"
        fill="#E9EDF7"
      />
      <rect
        x="15.3076"
        y="45.5704"
        width="23.4863"
        height="5.33287"
        rx="2.66643"
        transform="rotate(-90 15.3076 45.5704)"
        fill="#046B99"
      />
      <rect
        x="0.789062"
        y="45.5704"
        width="45.5705"
        height="5.33287"
        rx="2.66644"
        transform="rotate(-90 0.789062 45.5704)"
        fill="#E9EDF7"
      />
      <rect
        x="0.789062"
        y="45.5704"
        width="37.508"
        height="5.33288"
        rx="2.66644"
        transform="rotate(-90 0.789062 45.5704)"
        fill="#046B99"
      />
    </svg>
  );
};

export default Statistics;
