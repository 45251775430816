import { LoadingButtonProps } from '@mui/lab';
import { generalPalette } from 'theme/palette';

import { FC, MouseEventHandler } from 'react';

import { StyledContainer } from './Button.styled';

import * as icons from 'components/Icons/index';

import { renderIcon } from 'utils/helpers';

export type TButtonProps = {
  startIcon?: keyof typeof icons | JSX.Element;
  endIcon?: keyof typeof icons | JSX.Element;
  iconColor?: string;
  color?: keyof typeof generalPalette;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & LoadingButtonProps;

// If you have to change the color, backgroundColor and icon color to suit your custom needs,
// you are better off using MUI direct button
export const Button: FC<TButtonProps> = ({
  children,
  startIcon,
  endIcon,
  color = 'primary',
  variant = 'contained',
  iconColor = 'white',
  onClick,
  disabled,
  size,
  ...rest
}) => {
  return (
    <StyledContainer variant={variant} color={color} disabled={disabled} size={size} onClick={onClick} {...rest}>
      <>
        {startIcon && renderIcon(startIcon, iconColor)}
        {children}
        {endIcon && renderIcon(endIcon, iconColor)}
      </>
    </StyledContainer>
  );
};

export default Button;
