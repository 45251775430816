import { styled, ListItemButton, Box, ListItemButtonProps, ListItemIcon } from '@mui/material';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { NavLinkProps, LinkProps } from 'react-router-dom';

type TOpenProps = {
  isOpen: boolean;
};

interface TListItemButtonProps extends ListItemButtonProps {
  hasPadding?: boolean;
  component?: ForwardRefExoticComponent<NavLinkProps & RefAttributes<HTMLAnchorElement>>;
}

type TListItemButtonStyled = TListItemButtonProps | NavLinkProps | LinkProps;

type TListItemButton = TListItemButtonProps & TListItemButtonStyled;

export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (props) => props !== 'hasPadding',
})<TListItemButton>((props) => ({
  paddingLeft: props.hasPadding ? props.theme.spacing(9) : props.theme.spacing(2),
  color: props.theme.palette.text.secondary,
  '&.Mui-selected': {
    backgroundColor: `${props.theme.palette.secondary.lighter}`,
    color: props.theme.palette.primary.main,
    borderLeft: '4px solid #0673A3',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    marginRight: 4,
  },
}));

export const ListItemIconStyled = styled(ListItemIcon, {
  shouldForwardProp: (props) => props !== 'hasPadding',
})<TListItemButton>((props) => ({
  minWidth: `40px`,
}));

export const FirstContainerStyled = styled(Box, {
  shouldForwardProp: (props) => props !== 'isOpen',
})<TOpenProps>(({ isOpen }) => ({
  margin: isOpen ? '16px' : 0,
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: 1,
}));

export const SecondContainerStyled = styled(ListItemButton, {
  shouldForwardProp: (props) => props !== 'isOpen',
})<TOpenProps>(({ isOpen }) => ({
  display: 'flex',
  backgroundColor: '#fff',
  padding: isOpen ? '16px' : '16px',
  paddingTop: '16px',
  paddingBottom: '16px',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const UserListItemButtonStyles = (isOpen: boolean) => {
  return {
    borderRadius: 1,
    paddingX: isOpen ? 2 : 1,
  };
};

export const ListStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const IconStyles = (isOpen: boolean) => {
  return {
    minWidth: 0,
    marginRight: isOpen ? 1 : 0,
  };
};
