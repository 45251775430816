import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  CatalogsResponse,
  CreateSupplierResponse,
  IProductResponse,
  ISupplier,
  ProductRegistry,
} from 'modules/Products/ProductsInterfaces';
import API from 'utils/API';

export const getCatalogs = async () => {
  const response = await API.get('products/api/catalogs?pageNumber=0&pageSize=100');
  const {
    data: { payload: catalogs },
  }: AxiosResponse<CatalogsResponse> = await response;
  return catalogs;
};

export const getProducts = async (catalogId: string) => {
  const response = await API.get(`products/api/products/catalog/${catalogId}?pageNumber=0&pageSize=100`);
  const {
    data: { payload: products },
  }: AxiosResponse<IProductResponse> = await response;
  return products;
};

export const createSupplier = async (args: {
  productId: string;
  supplier: { productDescription: string };
}): Promise<ISupplier> => {
  const { productId, supplier } = args;
  const response = await API.post(`products/api/products/${productId}/suppliers`, { supplier });
  const {
    data: { payload: createdSupplier },
  }: AxiosResponse<CreateSupplierResponse> = await response;
  return createdSupplier;
};

const savePoductRegistryConfig = (productRegistry: ProductRegistry | null) =>
  API.put('/userConfiguration/tenantdata/productRegistry', { productRegistry });

const getPoductRegistryConfig = () => API.get('/userConfiguration/tenantdata/productRegistry');

export const useGetCatalogs = () => useQuery({ queryKey: ['catalogs'], queryFn: getCatalogs, staleTime: 20000 });

export const useGetProducts = (catalogId: string) =>
  useQuery({
    queryKey: ['products', catalogId],
    queryFn: () => getProducts(catalogId),
    staleTime: 20000,
    enabled: false,
  });

export const useCreateSupplier = () => useMutation(createSupplier);

export const useAddProductRegistryConfig = () => useMutation(savePoductRegistryConfig);

export const useGetProductRegistryConfig = () =>
  useQuery({ queryKey: ['productRegistry'], queryFn: getPoductRegistryConfig, enabled: false });
