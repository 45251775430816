import { Box, styled } from '@mui/material';

export const PageContainer = styled(Box)`
  display: flex;
  position: relative;
  flex-grow: 1;
  background-color: #f4f4f4;
  overflow: auto;
`;

export const PageWrapper = styled(Box)`
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const PageHeader = styled(Box)<any>`
  padding: 0 ${(props) => props.theme.spacing(1)} 0 ${(props) => props.theme.spacing(2)};
`;

export const PageContents = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
`;

export const LinearProgressStyles = {
  mb: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  height: 6,
  width: '100%',
};

export const PaperStyles = {
  flexShrink: 0,
  height: '100%',
  backgroundColor: '#E5E7ED',
  position: 'relative',
};

export const spanStyles = {
  top: 19,
  left: 0,
  position: 'absolute',
  width: 24,
  height: 24,
  backgroundColor: '#E5E7ED',
  borderRadius: '0px 4px 4px 0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '> .backArrow': {
    position: 'relative',
    left: 2,
  },
  '> svg': {
    fontSize: 14,
  },
} as any;
