import queryString from 'query-string';

export const useQueryParams = () => {
  const queryStringObject = queryString.parse(window.location.search);
  const pageNumber = queryStringObject?.pageNumber ?? 0;
  const pageSize = queryStringObject?.pageSize ?? 10;
  const dialogPageNumber = queryStringObject?.dialogPageNumber ?? 0;
  const dialogPageSize = queryStringObject?.dialogPageSize ?? 10;
  const showDialog: string = queryStringObject?.showDialog ? (queryStringObject?.showDialog as string) : '';
  const tab = queryStringObject?.tab ?? 0;
  const dialogTab = queryStringObject?.dialogTab ?? 0;
  const searchPageNumber = queryStringObject?.searchPageNumber ?? 0;
  const searchPageSize = queryStringObject?.searchPageSize ?? 10;

  return {
    pageNumber,
    pageSize,
    showDialog,
    dialogPageNumber,
    dialogPageSize,
    tab,
    dialogTab,
    searchPageNumber,
    searchPageSize,
  };
};

export default useQueryParams;
