import { FC } from 'react';

type MOMSitePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MOMSite: FC<MOMSitePropTypes> = ({ width = 60, height = 60, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#E4E4E4" />
      <path
        d="M60 30C60 34.9508 58.7747 39.8247 56.4335 44.187C54.0923 48.5492 50.7078 52.2643 46.582 55.0007C42.4562 57.7372 37.7173 59.4101 32.7879 59.8702C27.8584 60.3303 22.8918 59.5632 18.3308 57.6375L30 30H60Z"
        fill="#B2C8B6"
      />
      <path
        d="M21.535 58.781C15.2566 56.9344 9.75424 53.0854 5.86672 47.8209C1.97919 42.5564 -0.0802354 36.165 0.00239121 29.6212C0.0850178 23.0774 2.30516 16.7401 6.32438 11.5754C10.3436 6.41073 15.9414 2.70195 22.2644 1.01448L30 30L21.535 58.781Z"
        fill="#0975A5"
      />
      <path d="M22 1L30 30H60" stroke="#F4F4F4" strokeWidth="3" />
      <path d="M21.5 59L30 30" stroke="#F4F4F4" strokeWidth="3" />
    </svg>
  );
};

export default MOMSite;
