import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';

const { REACT_APP_MIXPANEL_KEY = '' } = process.env;

const hasEnvVariable = Boolean(REACT_APP_MIXPANEL_KEY).valueOf();
let isMixpanelEnabled = false;

export const setMixpanelEnabled = (enable: boolean) => {
  isMixpanelEnabled = enable;
};

export const track = (
  event_name: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback
) => {
  if (!isMixpanelEnabled) {
    return console.warn('Mixpanel is not initialized. Event not tracked:', event_name);
  }
  if (!hasEnvVariable) {
    return console.warn('Missing ENV variable for tracking');
  }

  mixpanel.track(event_name, properties, optionsOrCallback, callback);
};

export const reset = () => {
  if (!hasEnvVariable) return;
  if (!isMixpanelEnabled) return;
  mixpanel.reset();
};

export const identify = (uniqueId: string) => {
  if (!hasEnvVariable) return;
  if (!isMixpanelEnabled) return;
  mixpanel.identify(uniqueId);
};

export function peopleSet(prop: string, to?: any, callback?: Callback): void;
export function peopleSet(prop: Dict, callback?: Callback): void;
export function peopleSet(prop: any, to: any = undefined, callback: any = undefined): void {
  if (!hasEnvVariable) return;
  if (!isMixpanelEnabled) return;
  mixpanel.people.set(prop, to, callback);
}
