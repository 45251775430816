import { AxiosResponse } from 'axios';
import i18n from 'i18next';
import store from 'redux/store';

import { useMutation, useQuery, UseQueryResult, QueryClient } from '@tanstack/react-query';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { IOfferFilterTagResponse, IOfferFilterTag } from 'modules/Offers/offersInterfaces';
import {
  ProductTag,
  ProductTagCountResponse,
  ProductTagResponse,
  SingleProductTagResponse,
} from 'modules/Products/ProductsInterfaces';
import API from 'utils/API';

type Tags = {
  productId: string;
  tag: Tag;
};

export type Tag = {
  tag: string;
  color: string;
  useCount: number;
  created: string;
  createdBy: string;
  lastUpdated: string;
  lastUpdatedBy: string;
};

export type ICreateTag = {
  tag: string;
  color?: string;
};

type IAddToProduct = {
  productId: string;
  tag: ICreateTag | string;
};

type IUpdateTag = {
  oldName: string;
  tag: ICreateTag;
};

export type ISearchTag = {
  tag: string;
};

const getOfferTags = async () => {
  const response = await API.get('/offer/api/tags');
  const {
    data: { payload: tags },
  }: AxiosResponse<IOfferFilterTagResponse> = await response;
  return tags;
};

const addTagToProduct = async ({ productId, tag }: IAddToProduct) =>
  API.put(`products/api/products/${productId}/tags?tag=${tag}`);

const deleteTagsFromProduct = async ({ productId, tag }: Tags) =>
  API.delete(`products/api/products/${productId}/tags?tag=${tag.tag}`);

const deleteProductTag = async (tag: string) => API.delete(`/products/api/productTags?tag=${tag}`);

const getProductTags = async () => {
  const response = await API.get(`/products/api/productTags?pageNumber=0&page&pageSize=200`);
  const {
    data: { payload: tags },
  }: AxiosResponse<ProductTagResponse> = await response;
  return tags;
};

const getProductTagsCount = async () => {
  const response = await API.get(`/products/api/productTags/count`);
  const {
    data: { payload: count },
  }: AxiosResponse<ProductTagCountResponse> = await response;
  return count;
};

const getProductTagByTag = async (tag: string) => {
  const response = await API.get(`/products/api/productTags/byTag/${tag}`);
  const {
    data: { payload: productTag },
  }: AxiosResponse<SingleProductTagResponse> = await response;
  return productTag;
};

const createProductTag = async (tag: ICreateTag) => {
  const response = await API.post(`/products/api/productTags`, tag);
  const {
    data: { payload: createdTag },
  }: AxiosResponse<ProductTagResponse> = await response;
  return createdTag;
};

const updateProductTag = async ({ oldName, tag }: IUpdateTag) => {
  const response = await API.put(`/products/api/productTags?tag=${oldName}`, tag);
  const {
    data: { payload: updatedTag },
  }: AxiosResponse<SingleProductTagResponse> = await response;
  const tagUpdated = updatedTag.tag ?? '';
  return tagUpdated;
};

export const searchForProductTag = async ({ tag }: ISearchTag) => {
  const response = await API.get(`/products/api/productTags?prefixSearch=${tag}`);
  const {
    data: { payload: productTag },
  }: AxiosResponse<ProductTagResponse> = await response;
  return productTag;
};
const searchForProductTagStrings = async ({ tag }: ISearchTag) => {
  const response = await API.get(`/products/api/productTags/AsStrings?prefixSearch=${tag}`);
  const {
    data: { payload: productTag },
  }: AxiosResponse<{ payload: Array<string> }> = await response;
  return productTag;
};

export const useGetProductTagsCount = (): UseQueryResult<number> =>
  useQuery({ queryKey: [''], queryFn: getProductTagsCount, staleTime: 20000 });

export const useSearchForProductTag = (tag: string, keepPreviousData = false) =>
  useQuery({
    queryFn: () => searchForProductTag({ tag }),
    queryKey: ['productTags', tag],
    staleTime: Infinity,
    cacheTime: 0,
    keepPreviousData: keepPreviousData,
  });
export const useSearchProductTagStrings = (tag: string) =>
  useQuery({
    queryFn: () => searchForProductTagStrings({ tag }),
    queryKey: ['productTagsStrings', { tag }],
    staleTime: Infinity,
    cacheTime: 0,
  });

export const useGetOfferTags = (): UseQueryResult<IOfferFilterTag[]> =>
  useQuery({ queryKey: ['tags'], queryFn: getOfferTags, staleTime: 20000 });

export const useAddTagsToProduct = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(addTagToProduct, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(refetch || ['']);
      }, 1000);
      setTimeout(() => {
        onSuccess?.();
      }, 1500);
    },
    onError: (error: any) => {
      store.dispatch(
        addErrorMessage({ message: error?.response?.data?.message || 'This product already has this tag' })
      );
      onError?.();
    },
  });
export const useDeleteTagsFromProduct = () => useMutation(deleteTagsFromProduct);

export const useDeleteProductTag = (queryClient: QueryClient, refetch = []) =>
  useMutation(deleteProductTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(['productTags', ...refetch]);
    },
  });

export const useGetProductTags = (): UseQueryResult<ProductTag[]> =>
  useQuery({ queryKey: ['productTags'], queryFn: getProductTags, staleTime: 20000 });

export const useCreateProductTag = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(createProductTag, {
    onSuccess: () => {
      const message = i18n.t('products.sucessCreatingProductTag');
      store.dispatch(addSuccessMessage({ message }));
      setTimeout(() => {
        queryClient.invalidateQueries(refetch || ['productTags']);
        onSuccess?.();
      }, 500);
    },
    onError: (error: any) => {
      const failCreatingProductTag = i18n.t('products.failCreatingProductTag');
      store.dispatch(addErrorMessage({ message: failCreatingProductTag }));
      onError?.();
    },
  });

export const useUpdateProductTag = (queryClient: QueryClient, refetch?: string[], onError?: Function) =>
  useMutation(updateProductTag, {
    onSuccess: () => {
      const message = i18n.t('products.sucessUpdatingProductTag');
      store.dispatch(addSuccessMessage({ message }));
      setTimeout(() => {
        queryClient.invalidateQueries(refetch || ['productTags']);
      }, 500);
    },
    onError: (error: any) => {
      const failCreatingProductTag = i18n.t('products.failUpdatingProductTag');
      store.dispatch(addErrorMessage({ message: failCreatingProductTag }));
      onError?.();
    },
  });
