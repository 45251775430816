import { FC } from 'react';

type DragAndDropIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const DragAndDropIcon: FC<DragAndDropIconPropTypes> = ({
  width = 8,
  height = 12,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.2" cy="1.2" r="1.2" fill={color} />
      <circle cx="5.9998" cy="1.2" r="1.2" fill={color} />
      <circle cx="1.2" cy="5.99993" r="1.2" fill={color} />
      <circle cx="5.9998" cy="5.99993" r="1.2" fill={color} />
      <circle cx="1.2" cy="10.7999" r="1.2" fill={color} />
      <circle cx="5.9998" cy="10.7999" r="1.2" fill={color} />
    </svg>
  );
};

export default DragAndDropIcon;
