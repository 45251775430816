import jwtDecode from 'jwt-decode';
import { codeLogin } from 'model/remote/RemoteAuth';

import { ILoginOptions } from 'modules/Login/loginInterfaces';

interface IAccessToken {
  cordel3_userId: string;
  cordel3_modules: string;
  cordel3_tenantId: string;
}

type LoginResponse = {
  authToken: string;
  expires: string;
  authExpires: string;
};

const varsSource = (window as any)['appValues'] ?? process.env;

// See comment for the similar line in API.ts
const apiURL = varsSource.REACT_APP_BACKEND_API_HOST;

class Auth {
  redirectedUrl: string;
  TOKEN_KEY: string;
  EXPIRES_AT_KEY: string;
  REFRESH_TOKEN_EXPIRES_AT: string;
  constructor() {
    this.redirectedUrl = '/';
    this.TOKEN_KEY = 'authToken';
    this.EXPIRES_AT_KEY = 'expiresAt';
    this.REFRESH_TOKEN_EXPIRES_AT = 'refreshTokenExpiresAt';
  }

  // get and store the login options object. It contains a list of legal ways to
  // login for this user, and some more security information which needs to be
  // passed along unchanged in the next login step
  getLoginOptions = (username: string) => {
    const loginOptionsUrl = `${apiURL}/auth/login?username=${username}`;
    return new Promise((resolve, reject) => {
      fetch(loginOptionsUrl, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async (res) => {
          if (res.status !== 200) {
            const res_1 = await res.json();
            throw res_1;
          }
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // complete the two-step login process, supplying a choice of login method, plus a password or OAuth2 code
  loginWithMethod = async (
    loginOptions: ILoginOptions,
    choice: number,
    password?: string,
    code?: string
  ): Promise<LoginResponse> => {
    const loginURL = `${apiURL}/auth/login`;
    try {
      const response = await fetch(loginURL, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          options: loginOptions,
          choice,
          password,
          code,
        }),
      });
      const res: LoginResponse = await response.json();
      if (response.status !== 200) throw res;

      this.setLoginSession(res);
      return res;
    } catch (error) {
      throw error;
    }
  };

  oldLogin = (data: any) => {
    const params: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    };
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${apiURL}/auth`, { ...params });
        const newToken = await response.json();
        this.setLoginSession(newToken);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  oauthAuthenticate = async (code: any) => {
    if (typeof code !== 'string') return new Promise((resolve, reject) => reject('Invalid code'));

    const response = await codeLogin(code);
    if (response.authToken !== undefined) {
      this.setLoginSession(response);
    }
    return response;
  };

  refreshToken = (newTenantId?: string, newRoleType?: string): Promise<LoginResponse> => {
    const tenant = newTenantId !== undefined ? 'newTenantId=' + newTenantId : '';
    const role = newRoleType !== undefined ? 'newRoleType=' + newRoleType : '';
    const bothNotUndefined = newTenantId !== undefined && newRoleType !== undefined ? '&' : '';
    const query = '?' + tenant + bothNotUndefined + role;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve: (value: LoginResponse) => void, reject: (reason?: any) => void) => {
      const params: RequestInit = { credentials: 'include' }; // Needed to send the refreshToken from cookies
      try {
        const resp = await fetch(`${apiURL}/auth/refresh` + query, params);
        const data: LoginResponse = await resp.json();
        this.setLoginSession(data);
        // if (newTenantId) window.location.reload(); // reload page if switching tenant
        resolve(data);
      } catch (error) {
        this.setRedirectedUrl(window.location.pathname);
        this.logout();
        reject(error);
      }
    });
  };

  logout = () => {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.EXPIRES_AT_KEY);
    localStorage.removeItem(this.REFRESH_TOKEN_EXPIRES_AT);
    window.location.replace('/login');
  };

  isTokenValid = (token: string) => {
    const UTCDate = new Date(new Date().toUTCString()).toISOString();
    const expiresAt = localStorage.getItem(token);
    if (expiresAt && expiresAt !== 'undefined') {
      const expiresAtDate = new Date(expiresAt).toISOString();
      const isValid = UTCDate < expiresAtDate;
      return isValid;
    }
    return false;
  };

  isLoggedIn = () => this.isTokenValid(this.REFRESH_TOKEN_EXPIRES_AT);

  setLoginSession = (response: LoginResponse) => {
    localStorage.setItem(this.TOKEN_KEY, response?.authToken ?? '');
    localStorage.setItem(this.EXPIRES_AT_KEY, response?.authExpires ?? '');
    localStorage.setItem(this.REFRESH_TOKEN_EXPIRES_AT, response?.expires ?? '');
  };

  getAuthHeader = () => 'Bearer ' + localStorage.getItem(this.TOKEN_KEY);
  getAuthToken = () => localStorage.getItem(this.TOKEN_KEY);
  setRedirectedUrl = (url?: string) => (this.redirectedUrl = url ?? '/');

  getRedirectedUrl = () => this.redirectedUrl;

  getDecodedToken = () => {
    const token = localStorage?.getItem(this.TOKEN_KEY) ?? '';
    if (!token) return {} as IAccessToken;
    return jwtDecode(token) as IAccessToken;
  };
}

const auth = new Auth();

export default auth;
