import i18n from 'i18next';

import { ICatalog, ICreateCatalog } from './catalogsTypes';

import { addSuccessMessage, addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import { IAllowedModule } from 'modules/Products/ProductsInterfaces';
import API from 'utils/API';

export const EDIT_CATALOG = 'EDIT_CATALOG';
export const SELECT_CATALOG = 'SELECT_CATALOG';
export const DELETE_CATALOG = 'DELETE_CATALOG';
export const HARD_DELETE_CATALOG = 'HARD_DELETE_CATALOG';
export const LOAD_ALL_CATALOGS = 'LOAD_ALL_CATALOGS';
export const LOAD_ALLOWED_MODULES = 'LOAD_ALLOWED_MODULES';
export const LOAD_DELETED_CATALOGS = 'LOAD_DELETED_CATALOGS';
export const RESTORE_CATALOG = 'RESTORE_CATALOG';
export const IS_CATALOG_LOADING = 'IS_CATALOG_LOADING';
export const IS_CATALOG_DIALOG_LOADING = 'IS_CATALOG_DIALOG_LOADING';

const loadCatalogsSuccess = (payload: Array<ICatalog>, pagingInfo: IPageInfo) => ({
  type: LOAD_ALL_CATALOGS,
  payload: { items: [...payload], ...pagingInfo },
});

const loadAllowedModulesSuccess = (payload: Array<IAllowedModule>) => ({
  type: LOAD_ALLOWED_MODULES,
  payload: { allowedModules: [...payload] },
});

const setIsLoading = (payload: boolean) => ({ type: IS_CATALOG_LOADING, payload });

const setIsDialogLoading = (payload: boolean) => ({ type: IS_CATALOG_DIALOG_LOADING, payload });

export const selectCatalog = (payload: ICatalog) => ({
  type: SELECT_CATALOG,
  payload,
});

export const loadAllowedModules = () => {
  return async (dispatch: Function) => {
    const url = '/licenses/api/Modules/User/AdministratorFor';
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(loadAllowedModulesSuccess(payload));
    } catch (e) {
      const message = i18n.t('products.errorLoadingAllowedModules');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const loadAllCatalogs = () => {
  return async (dispatch: Function) => {
    const url = '/fdv/api/templateCatalogs';
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload, pageInfo },
      } = await API.get(url);
      dispatch(loadCatalogsSuccess(payload, pageInfo));
    } catch (e) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createCatalog = (catalog: ICreateCatalog) => {
  return async (dispatch: Function) => {
    const url = '/fdv/api/templateCatalogs';
    try {
      dispatch(setIsLoading(true));
      await API.post(url, catalog);
      dispatch(loadAllCatalogs());
    } catch (e) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const editCatalog = (catalogId: string, catalog: ICreateCatalog) => {
  return async (dispatch: Function) => {
    const url = `/fdv/api/templateCatalogs/${catalogId}`;
    try {
      dispatch(setIsLoading(true));
      await API.put(url, catalog);
      dispatch(loadAllCatalogs());
    } catch (e) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteCatalog = (catalogId: string) => {
  return async (dispatch: Function) => {
    const url = `/fdv/api/templateCatalogs/${catalogId}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(loadAllCatalogs());
    } catch (e) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addCatalogRequiredModule = (catalogId: string, moduleCode: number) => {
  return async (dispatch: Function) => {
    const url = `/fdv/api/TemplateCatalogs/${catalogId}/RequiredModules/${moduleCode}`;
    try {
      dispatch(setIsLoading(true));
      await API.post(url);
      dispatch(loadAllCatalogs());
    } catch (e) {
      const message = i18n.t('globals.errorAddingModuleToCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const deleteCatalogRequiredModule = (catalogId: string, moduleCode: number) => {
  return async (dispatch: Function) => {
    const url = `/fdv/api/TemplateCatalogs/${catalogId}/RequiredModules/${moduleCode}`;
    try {
      dispatch(setIsLoading(true));
      await API.delete(url);
      dispatch(loadAllCatalogs());
    } catch (e) {
      const message = i18n.t('globals.errorDeletingModuleToCatalog');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
