import { FC } from 'react';

type CopyPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Copy: FC<CopyPropTypes> = ({ width = 21, height = 21, color = '#fff', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4612 0.841339H3.36527C2.43981 0.841339 1.68262 1.59853 1.68262 2.52399V14.3025H3.36527V2.52399H13.4612V0.841339ZM12.6198 4.20664L17.6678 9.25458V17.6678C17.6678 18.5933 16.9106 19.3505 15.9851 19.3505H6.72215C5.79669 19.3505 5.04791 18.5933 5.04791 17.6678L5.05633 5.88928C5.05633 4.96383 5.80511 4.20664 6.73056 4.20664H12.6198ZM11.7785 10.0959H16.4058L11.7785 5.46862V10.0959Z"
        fill={color}
      />
    </svg>
  );
};

export default Copy;
