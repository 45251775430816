import { FC } from 'react';

type PersonInSquarePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const PersonInSquare: FC<PersonInSquarePropTypes> = ({
  width = 26,
  height = 26,
  color = '#0975A5',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.33335 6.5H2.16669V21.6667C2.16669 22.8583 3.14169 23.8333 
            4.33335 23.8333H19.5V21.6667H4.33335V6.5ZM21.6667 2.16667H8.66669C7.47502 
            2.16667 6.50002 3.14167 6.50002 4.33334V17.3333C6.50002 18.525 7.47502 
            19.5 8.66669 19.5H21.6667C22.8584 19.5 23.8334 18.525 23.8334 
            17.3333V4.33334C23.8334 3.14167 22.8584 2.16667 21.6667 2.16667ZM15.1667 
            4.33334C16.965 4.33334 18.4167 5.785 18.4167 7.58334C18.4167 9.38167 16.965 
            10.8333 15.1667 10.8333C13.3684 10.8333 11.9167 9.38167 11.9167 7.58334C11.9167
            5.785 13.3684 4.33334 15.1667 4.33334ZM21.6667 17.3333H8.66669V15.7083C8.66669 
            13.5525 13 12.4583 15.1667 12.4583C17.3334 12.4583 21.6667 13.5525 21.6667
            15.7083V17.3333Z"
        fill={color}
      />
    </svg>
  );
};

export default PersonInSquare;
