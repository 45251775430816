import { FC } from 'react';

type FactoryPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Factory: FC<FactoryPropTypes> = ({ width = 30, height = 30, color = '#202236', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9875 0.5C6.08755 0.5 0.487549 6.1 0.487549 13C0.487549 19.9 6.08755 25.5 12.9875 25.5C19.8875 25.5 25.4875 19.9 25.4875 13C25.4875 6.1 19.8875 0.5 12.9875 0.5V0.5ZM17.5 8.425C18.8375 8.425 19.9125 9.5 19.9125 10.8375C19.9125 12.175 18.8375 13.25 17.5 13.25C16.1625 13.25 15.0875 12.175 15.0875 10.8375C15.075 9.5 16.1625 8.425 17.5 8.425V8.425ZM10 6.45C11.625 6.45 12.95 7.775 12.95 9.4C12.95 11.025 11.625 12.35 10 12.35C8.37505 12.35 7.05005 11.025 7.05005 9.4C7.05005 7.7625 8.36255 6.45 10 6.45ZM10 17.8625V22.55C7.00005 21.6125 4.62505 19.3 3.57505 16.35C4.88755 14.95 8.16255 14.2375 10 14.2375C10.6625 14.2375 11.5 14.3375 12.375 14.5125C10.325 15.6 10 17.0375 10 17.8625ZM12.9875 23C12.65 23 12.325 22.9875 12 22.95V17.8625C12 16.0875 15.675 15.2 17.5 15.2C18.8375 15.2 21.15 15.6875 22.3 16.6375C20.8375 20.35 17.225 23 12.9875 23V23Z"
        fill={color}
      />
    </svg>
  );
};

export default Factory;
