import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type ListIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  background?: string;
  [x: string]: any;
};

export const ListIcon: FC<ListIconPropTypes> = ({
  width = 48,
  height = 48,
  color = '#0673A3',
  background = '#E6F0F5',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 11.2C0 5.01441 5.01441 0 11.2 0H36.8C42.9856 0 48 5.01441 48 11.2V36.8C48 42.9856 42.9856 48 36.8 48H11.2C5.01441 48 0 42.9856 0 36.8V11.2Z"
        fill={background}
      />
      <path
        d="M31.2083 16.7917V30.2083H17.7917V16.7917H31.2083ZM32.2625 14.875H16.7375C16.2583 14.875 15.875 15.2583 15.875 15.7375V31.2625C15.875 31.6458 16.2583 32.125 16.7375 32.125H32.2625C32.6458 32.125 33.125 31.6458 33.125 31.2625V15.7375C33.125 15.2583 32.6458 14.875 32.2625 14.875V14.875ZM23.5417 18.7083H29.2917V20.625H23.5417V18.7083ZM23.5417 22.5417H29.2917V24.4583H23.5417V22.5417ZM23.5417 26.375H29.2917V28.2917H23.5417V26.375ZM19.7083 18.7083H21.625V20.625H19.7083V18.7083ZM19.7083 22.5417H21.625V24.4583H19.7083V22.5417ZM19.7083 26.375H21.625V28.2917H19.7083V26.375Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default ListIcon;
