import { FC } from 'react';

type TotalsIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const TotalsIcon: FC<TotalsIconPropTypes> = ({ width = 60, height = 60, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 14C0.5 6.26801 6.76801 0 14.5 0H46.5C54.232 0 60.5 6.26801 60.5 14V46C60.5 53.732 54.232 60 46.5 60H14.5C6.76801 60 0.5 53.732 0.5 46V14Z" fill="#E0EDF2"/>
    <path d="M23.125 18.125H19V22.25H16.25V15.375H23.125V18.125ZM43.75 22.25V15.375H36.875V18.125H41V22.25H43.75ZM23.125 42.875H19V38.75H16.25V45.625H23.125V42.875ZM41 38.75V42.875H36.875V45.625H43.75V38.75H41ZM39.625 38.75C39.625 40.2625 38.3875 41.5 36.875 41.5H23.125C21.6125 41.5 20.375 40.2625 20.375 38.75V22.25C20.375 20.7375 21.6125 19.5 23.125 19.5H36.875C38.3875 19.5 39.625 20.7375 39.625 22.25V38.75ZM34.125 25H25.875V27.75H34.125V25ZM34.125 29.125H25.875V31.875H34.125V29.125ZM34.125 33.25H25.875V36H34.125V33.25Z" fill={color}/>
    </svg>
  );
};

export default TotalsIcon;
