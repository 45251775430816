import { AxiosResponse } from 'axios';
import i18next from 'i18next';

import {
  ICopyFile,
  ICreateFolder,
  IDocument,
  IDocumentsResponse,
  IFolder,
  IFoldersResponse,
  IMoveFile,
} from '../interfaces/ProjectDocumentsInterfaces';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { IProject } from 'modules/Projects/interfaces/ProjectInterfaces';
import API from 'utils/API';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_TOTAL_DOCUMENTS = 'SET_TOTAL_DOCUMENTS';
export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS';
export const LOAD_FOLDERS = 'LOAD_FOLDERS';
export const SAVE_SEARCHED_FOLDERS = 'SAVE_SEARCHED_FOLDERS';

export const addErrorMessageAction = (error: any) => ({
  type: ADD_ERROR_MESSAGE,
  payload: { isLoading: false, error },
});

export const setHasMoreItems = (hasMoreItems: boolean) => ({
  type: SET_HAS_MORE_ITEMS,
  payload: { hasMoreItems },
});

export const setIsLoading = (isLoading: boolean) => ({
  type: SET_IS_LOADING,
  payload: { isLoading },
});

export const setTotalDocumentsConnected = (totalDocuments: number) => ({
  type: SET_TOTAL_DOCUMENTS,
  payload: { totalDocuments },
});

export const loadDocumentsSuccess = (documents: Array<IDocument>) => ({
  type: LOAD_DOCUMENTS,
  payload: { documents },
});

export const loadFoldersSuccess = (folder: IFolder | null) => ({
  type: LOAD_FOLDERS,
  payload: { rootFolder: folder },
});

export const loadDocumentsConnectedCount = (externalObjectId: string) => {
  return async (dispatch: Function) => {
    try {
      const getCount = `/documents/api/documentdata/connectedTo/${externalObjectId}`;
      const getCountPromise = API.get(getCount);
      const {
        data: { payload },
      }: AxiosResponse<IDocumentsResponse> = await getCountPromise;
      dispatch(setTotalDocumentsConnected(payload.length));
    } catch {}
  };
};

export const loadDocumentsFromFacade = (externalObjectId: string) => {
  return async (dispatch: Function) => {
    const url = `/facade/api/projects/${externalObjectId}/documents`;
    await getDocuments(url, dispatch);
  };
};

export const loadDocumentsConnectedToObject = (externalObjectId: string) => {
  return async (dispatch: Function) => {
    const url = `/documents/api/documentData/connectedTo/${externalObjectId}`;
    await getDocuments(url, dispatch);
  };
};

export const loadFolders = (externalObjectId: string) => {
  return async (dispatch: Function) => {
    try {
      const getFolders = `/documents/api/documentFolders/externalObject/${externalObjectId}`;
      const getFoldersPromise = API.get(getFolders);
      const {
        data: { payload: folders },
      }: AxiosResponse<IFoldersResponse> = await getFoldersPromise;
      dispatch(loadFoldersSuccess(folders));
    } catch (error) {
      dispatch(loadFoldersSuccess(null));
      dispatch(addErrorMessageAction(error));
      try {
        await dispatch(createExternalObject(externalObjectId));
      } catch {
        dispatch(addErrorMessage({ message: i18next.t('documents.errorCreatingNewExternalObject') }));
        dispatch(loadFoldersSuccess(null));
      }
    } finally {
      dispatch(loadDocumentsConnectedCount(externalObjectId));
    }
  };
};

export const createFolder = (externalObjectId: string, folder: ICreateFolder) => {
  return async (dispatch: Function) => {
    try {
      const createFolder = `/documents/api/documentFolders/${externalObjectId}`;
      const createFolderPromise = API.post(createFolder, folder);
      await createFolderPromise;
      dispatch(loadFolders(externalObjectId));
      dispatch(addSuccessMessage({ message: i18next.t('documents.createdNewFolder') }));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorCreatingNewFolder') }));
    }
  };
};

export const deleteFolder = (externalObjectId: string, folderId: string) => {
  return async (dispatch: Function) => {
    try {
      const deleteFolder = `/documents/api/documentFolders/${folderId}`;
      const deleteFolderPromise = API.delete(deleteFolder);
      await deleteFolderPromise;
      handleFolderAction(dispatch, externalObjectId, i18next.t('documents.deletedFolder'));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.folderContainsFiles') }));
    }
  };
};

export const editFolder = (externalObjectId: string, folderId: string, newName: string) => {
  return async (dispatch: Function) => {
    try {
      const editFolder = `/documents/api/documentFolders/${folderId}`;
      const editFolderPromise = API.put(editFolder, { name: newName });
      await editFolderPromise;
      handleFolderAction(dispatch, externalObjectId, i18next.t('documents.editedFolder'));
    } catch (e) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorEditingFolder') }));
    }
  };
};

export const copyFile = (externalObjectId: string, fileToCopy: ICopyFile) => {
  return async (dispatch: Function) => {
    try {
      const copyFile = `/documents/api/Documents/Copy`;
      const copyFilePromise = API.post(copyFile, fileToCopy);
      await copyFilePromise;
      handleFolderAction(dispatch, externalObjectId, i18next.t('documents.copiedFile'));
      dispatch(loadDocumentsConnectedToObject(externalObjectId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorCopyingFile') }));
    }
  };
};

export const deleteFile = (externalObjectId: string, fileId: string) => {
  return async (dispatch: Function) => {
    try {
      const deleteFile = `/documents/api/documents/${fileId}`;
      const deleteFilePromise = API.delete(deleteFile);
      await deleteFilePromise;
      dispatch(addSuccessMessage({ message: i18next.t('documents.deletedFile') }));
      dispatch(loadFolders(externalObjectId));
      dispatch(loadDocumentsConnectedToObject(externalObjectId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorDeletingFile') }));
    }
  };
};

export const moveFile = (externalObjectId: string, linkId: string, folderItem: IMoveFile) => {
  return async (dispatch: Function) => {
    try {
      const url = `/documents/api/documentLinks/${linkId}`;
      await API.put(url, folderItem);
      dispatch(addSuccessMessage({ message: i18next.t('documents.successMovingFile') }));
      dispatch(loadFolders(externalObjectId));
    } catch {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorMovingFile') }));
    }
  };
};

export const addFileToFolder = (externalObjectId: string, folderId: string, fileToUpload: any) => {
  return async (dispatch: Function) => {
    try {
      const formData = new FormData();
      formData.append('file', fileToUpload);
      const url = `/documents/api/documents/Upload?externalObjectId=${externalObjectId}&folderId=${folderId}`;
      await API.post(url, formData);
      dispatch(addSuccessMessage({ message: i18next.t('documents.addedFile') }));
      dispatch(loadFolders(externalObjectId));
      dispatch(loadDocumentsConnectedToObject(externalObjectId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorAddingFile') }));
    }
  };
};

export const downloadFile = (file: any) => {
  return async (dispatch: Function) => {
    try {
      handleDownload(file);
    } catch {
      dispatch(addErrorMessage({ message: i18next.t('documents.errorDownloadingFile') }));
    }
  };
};

export const handleDownload = (fileToDownload: any) => {
  if (fileToDownload !== undefined) {
    const element = document.createElement('a');
    element.href = fileToDownload.file;
    element.download = fileToDownload.fileName.toLocaleLowerCase();
    element.click();
  }
};

export const createExternalObject = (externalObjectId: string) => {
  if (!externalObjectId) return;
  return async (dispatch: Function, getState: Function) => {
    try {
      const { id, name, description }: IProject = getState()?.projectsModule?.selectedItem ?? {};

      let project;
      if (!id) project = await getProjectById(externalObjectId);

      const newExternalObject = {
        id: externalObjectId,
        name: name ?? project?.name ?? null,
        description: description ?? project?.description ?? null,
        typeDescription: 'project',
      };
      const url = `/documents/api/externalObjects`;
      await API.post(url, newExternalObject);
      dispatch(createFolder(externalObjectId, { name: 'Default', description: 'Default folder' }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getProjectById = async (projectId: string) => {
  const getProjectUrl = `/Projects/api/Projects/${projectId}`;
  const {
    data: { payload: project },
  } = await API.get(getProjectUrl);
  return project as IProject;
};

const getDocuments = async (url: string, dispatch: Function) => {
  try {
    const getDocumentsPromise = API.get(url);
    const {
      data: { payload: documents },
    }: AxiosResponse<IDocumentsResponse> = await getDocumentsPromise;
    dispatch(loadDocumentsSuccess(documents));
    if (documents.length > 0) dispatch(setTotalDocumentsConnected(documents.length));
  } catch (error) {
    dispatch(addErrorMessageAction(error));
  }
};

const handleFolderAction = (dispatch: Function, externalObjectId: string, message: string) => {
  dispatch(addSuccessMessage({ message }));
  dispatch(loadFolders(externalObjectId));
};
