import { IProjectState, IProjectAction } from '../interfaces/ProjectInterfaces';
import { IS_PROJECT_LOADING, SET_SELECTED_PROJECT, SET_INTERNAL_PROJECT_SITE } from './ProjectsActions';

const defaultState: IProjectState = {
  items: [],
  totalItems: 0,
  isLoading: false,
  selectedItem: null,
};

const ProjectsReducer = (state = defaultState, { type, payload }: IProjectAction) => {
  const newState = () => ({ ...state, ...payload });

  const updatedSiteState = () => {
    const { selectedItem } = state;
    if (!selectedItem) return state;
    if (!payload) return state;
    return {
      ...state,
      selectedItem: {
        ...selectedItem,
        ...payload,
      },
    };
  };

  const stateActions = {
    [IS_PROJECT_LOADING]: newState,
    [SET_SELECTED_PROJECT]: newState,
    [SET_INTERNAL_PROJECT_SITE]: updatedSiteState,
  };

  const currentState = stateActions[type];
  if (!currentState) return state;
  return currentState();
};

export default ProjectsReducer;
