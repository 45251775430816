import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type ArrowDownPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ArrowDown: FC<ArrowDownPropTypes> = ({ width = 24, height = 24, color = 'softPurple', ...rest }) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M20 12L18.59 10.59L13 16.17V4L11 4V16.17L5.41 10.59L4 12L12 20L20 12Z" fill={resolvedColor} />
    </svg>
  );
};

export default ArrowDown;
