import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type SaveIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const SaveIcon: FC<SaveIconPropTypes> = ({ width = 21, height = 18, color = '#fff', ...rest }) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 2.25H4.66667C3.74167 2.25 3 2.925 3 3.75V14.25C3 15.075 3.74167 15.75 4.66667 15.75H16.3333C17.25 15.75 18 15.075 18 14.25V5.25L14.6667 2.25ZM10.5 14.25C9.11667 14.25 8 13.245 8 12C8 10.755 9.11667 9.75 10.5 9.75C11.8833 9.75 13 10.755 13 12C13 13.245 11.8833 14.25 10.5 14.25ZM13 6.75H4.66667V3.75H13V6.75Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default SaveIcon;
