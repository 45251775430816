import cordelVertikalSvart from "./../img/cordel-vertikal-svart.png";


export default function Card({
    flipped,
    matched,
    checkMatch,
    handleClickCount,
    handleClick,
    value,
    id,
    image,
    bg,
}: {
    flipped?: boolean;
    matched?: boolean;
    checkMatch?: any;
    handleClickCount?: any;
    handleClick?: any;
    value?: any;
    id?: number;
    image?: string;
    bg?: string;
}) {

    const cardClasses = () => {
        const classes: string[] = ["card"];
        if (flipped) {
            classes.push("card-flipped");
        }

        if (matched) {
            classes.push("card-matched");
        }

        return classes.join(" ");
    };

    const onClick = (): void => {
        if (!flipped) {
            handleClickCount();
            checkMatch(value, id);
        }
    };

    const cardField = () => {

        const cardValue = flipped ? image : "";

        const bgColor = {
            backgroundColor: bg,
        };

        const bgImage = {
            backgroundImage: `url(${cordelVertikalSvart})`,
        };

        const bgSettings = {
            backgroundPosition: "center center",
            backgroundSize: "25%",
        };

        return (
            <div className={cardClasses()} onClick={onClick}>
                <div className={"card-inner" + (flipped ? " flipped" : "")}>
                    <div
                        className="card-front"
                        style={{ backgroundColor: "#ECECEC", ...bgImage, ...bgSettings }}
                    ></div>
                    <div className="card-back" style={bgColor}>
                        <img src={cardValue} alt="" />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            {cardField()}
        </>
    );
}

// export default class Card extends React.Component<any, ICardProps> {
//   constructor(props: any) {
//     super(props);

//     this.onClick = this.onClick.bind(this);
//   }

//   public render() {

//     const cardValue = this.props.flipped ? this.props.image : "";

//     const bgColor = {
//       backgroundColor: this.props.bg,
//     };

//     const bgImage = {
//       backgroundImage: `url(${cordelVertikalSvart})`,
//     };

//     const bgSettings = {
//       backgroundPosition: "center center",
//       backgroundSize: "25%",
//     };

//     return (
//       <div className={this.cardClasses()} onClick={this.onClick}>
//         <div className={"card-inner" + (this.props.flipped ? " flipped" : "")}>
//           <div
//             className="card-front"
//             style={{ backgroundColor: "#ECECEC", ...bgImage, ...bgSettings }}
//           ></div>
//           <div className="card-back" style={bgColor}>
//             <img src={cardValue} alt="" />
//           </div>
//         </div>
//       </div>
//     );
//   }

//   private cardClasses() {
//     const classes: string[] = ["card"];
//     if (this.props.flipped) {
//       classes.push("card-flipped");
//     }

//     if (this.props.matched) {
//       classes.push("card-matched");
//     }

//     return classes.join(" ");
//   }

//   private onClick = (): void => {
//     if (!this.props.flipped) {
//       this.props.handleClickCount();
//       this.props.checkMatch(this.props.value, this.props.id);
//     }
//   }
// }
