import { IPersonsState } from '../personsInterfaces';
import { PersonInitial } from './initialState';
import {
  SET_PERSONS_LOADER,
  SAVE_PERSONS,
  DELETE_PERSON_REQUEST,
  DELETE_PERSON_SUCCESS,
  IS_DELETED_PERSONS_LOADING,
  LOAD_DELETED_PERSONS,
  LOAD_PERSON_ITEM,
  ADD_ERROR_MESSAGE,
  UPDATE_PERSON_ITEM,
  CREATE_PERSON,
  SAVE_SEARCH_RESULT,
} from './personsActions';
import { PERSONS_ACTIONS } from './types';

const defaultState: IPersonsState = {
  error: null,
  isItemsLoading: false,
  isDialogLoading: false,
  isDeletedItemsLoading: false,
  items: [PersonInitial],
  totalItemsNumber: 0,
  selectedItem: PersonInitial,
  deletedItems: [PersonInitial],
  totalDeletedItemNumber: 0,
  createdItem: null,
  searchedItems: null,
};

const personsReducer = (state = defaultState, action: PERSONS_ACTIONS) => {
  switch (action.type) {
    case SET_PERSONS_LOADER:
      return { ...state, isItemsLoading: action.isLoading };
    case IS_DELETED_PERSONS_LOADING:
      return { ...state, isDeletedItemsLoading: true };
    case SAVE_PERSONS:
      return {
        ...state,
        items: action.allPersons,
        isItemsLoading: false,
        totalItemsNumber: action.totalPersonsNumber,
      };
    case DELETE_PERSON_REQUEST:
      return { ...state, isItemsLoading: true };
    case DELETE_PERSON_SUCCESS:
      const persons = state.items.filter((item) => item.id !== action.id);
      return { ...state, isItemsLoading: false, persons };
    case LOAD_DELETED_PERSONS:
      return {
        ...state,
        deletedItems: action.deletedPersons,
        isDeletedItemsLoading: false,
      };
    case LOAD_PERSON_ITEM:
      return {
        ...state,
        selectedItem: action.person,
        isItemsLoading: false,
      };
    case UPDATE_PERSON_ITEM:
      return { ...state, selectedItem: action.person, loader: false };
    case ADD_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        isItemsLoading: false,
        error: action.error,
      };
    case CREATE_PERSON:
      return { ...state, createdItem: action.person };
    case SAVE_SEARCH_RESULT:
      return { ...state, searchedItems: action.searchResult };
    default:
      return state;
  }
};

export default personsReducer;
