// @ts-nocheck
import { Box, Stack, styled, Paper } from '@mui/material';

import { AutoComplete, TextField, Button, TabPanel, FlexCenter } from 'components';

// Add New offer Dialog
export const BoxStyled = styled(Box)(
  ({ theme }) => `
background-color: ${theme.palette.primary['50']};
padding: ${theme.spacing(3)}
`
);

export const StackStyled = styled(Stack)(
  ({ theme }) => `
  width: 100%;
  margin-bottom: ${theme.spacing(8)};
`
);

export const SearchField = {
  '.MuiOutlinedInput-input': {
    // padding: '14px 16px',
    backgroundColor: '#f7f7f7',
    fontSize: '14px',
    lineHeight: '20px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const AutoCompleteStyled = styled(AutoComplete)(({ height }) => ({
  backgroundColor: '#fff',
  minWidth: '200px',
  '.MuiOutlinedInput-root': {
    // transform: 'translate(10px, 10px) scale(1)',
    height,
  },
}));

export const TextFieldStyled = styled(TextField)`
  input {
    background-color: #fff;
  }
`;

export const ButtonStyled = styled(Button)`
  background-color: #fff;
`;

export const StepStyles = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: 'success.main', // circle color (COMPLETED)
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    //color: 'grey.500', // Just text label (COMPLETED)
    fontSize: '12px',
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: 'success.main', // circle color (ACTIVE)
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: 'primary.light', // Just text label (ACTIVE)
    fontSize: '12px',
  },
};

// Offer Details
export const OverViewItemContainer = styled(Box)(({ backgroundColor }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '8px',
  paddingBottom: '8px',
  marginRight: '8px',
  width: '16.6%',
  backgroundColor,
}));

export const IconContainer = styled(Box)(({ borderColor }) => ({
  width: '48px',
  height: '48px',
  borderRadius: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '16px',
  border: `1px solid ${borderColor}`,
}));

export const AutoSelectWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  borderRadius: '10px',
  border: '1px solid #efefef',
}));

export const IconWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.grayLight,
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.purple.lighter,
  },
}));

export const PaperStyled = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  marginTop: '24px',
  padding: theme.spacing(1.5),
}));

export const IconWrapper = {
  backgroundColor: 'theme.palette.primary.main',
  '&:hover': {
    backgroundColor: 'primary.softDarkBlue',
  },
  borderRadius: '50%',
  width: '15px',
  height: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  mb: '1px',
  ml: 2,
  p: 2,
};

// overview
type OverViewContainerProps = {
  isIconRight?: boolean;
  isActive?: boolean;
  paddingRight?: number;
};

export const OverViewContainer = styled(Box, {
  shouldForwardProp: (props) => props !== 'isIconRight' && props !== 'isActive',
})<OverViewContainerProps>(({ isIconRight, isActive, paddingRight, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isIconRight ? 'space-between' : 'flex-end',
  padding: '16px 24px',
  borderRadius: '20px',
  width: 'calc(25% - 10px)',
  flexShrink: 1,
  flexGrow: 1,
  cursor: 'pointer',
  flexDirection: isIconRight ? 'row' : 'row-reverse',
  background: isActive ? 'linear-gradient(135deg, rgba(4, 107, 153, 0.99) 0%, rgba(10, 131, 185, 0.99) 100%)' : 'white',
  color: isActive ? 'grey.300' : 'text.primary',
  paddingRight: paddingRight ?? 'auto',
  gap: isIconRight ? 'auto' : '16px',
}));

// Totals
export const TotalsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  px: 2,
  pt: 1,
  pb: 3,
  borderRadius: 3,
  boxShadow: '0px 0px 34px rgba(214,214,214, 0.25)',
});

type TDialogTagContainer = {
  isActive?: boolean;
};

export const DialogTagContainer = styled(Box, {
  shouldForwardProp: (props) => props !== 'isActive',
})<TDialogTagContainer>(({ isActive, theme }) => ({
  backgroundColor: isActive ? theme.palette.primary.main : theme.palette.grey.dark,
  padding: '6px 16px',
  margin: '4px 4px 0 4px',
  borderRadius: '6px',
  cursor: 'pointer',
  opacity: isActive ? 1 : 0.3,
}));

export const TabPanelStyled = styled(TabPanel)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const ButtonBorderRadius = styled(Button)`
  border-radius: 10px;
`;

export const ExportOfferPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  boxShadow: 'none',
  borderRadius: theme.spacing(2),
}));

export const ImportIconWrapper = styled(FlexCenter)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  padding: `${theme.spacing(8)} 0px`,
  backgroundColor: theme.palette.grey[300],
  border: `1px solid ${theme.palette.primary.grayLight}`,
  borderRadius: theme.spacing(2),
}));
