import {
  LOAD_OFFER_SETTINGS,
  IS_OFFER_SETTINGS_LOADING,
  IS_OFFER_SETTING_ITEM_LOADING,
  IS_OFFER_SETTINGS_DIALOG_LOADING,
  EDIT_OFFER_SETTING,
  LOAD_DELETED_OFFER_SETTING,
  RESTORE_OFFER_SETTINGS,
  DELETE_OFFER_SETTINGS,
  HARD_DELETE_OFFER_SETTINGS,
  SAVE_SEARCH_RESULT,
  GET_OFFER_SETTINGS_BY_ID,
  GET_PRODUCT_PRICE_SELECTORS,
  RESET_OFFER_SETTINGS_STATE,
} from './OfferSettingsActions';
import { IOfferSettingState, IOfferSettingAction, IOfferSetting } from '../OfferSettingsInterfaces';

const defaultState: IOfferSettingState = {
  items: [],
  selectedItem: null,
  productPriceSelectors: [],
  totalItems: 0,
  isItemLoading: false,
  isItemsLoading: false,
  isDialogLoading: false,
  deletedItems: [],
  totalDeletedItems: 0,
};

const offerSettings = (state = defaultState, { type, payload }: IOfferSettingAction) => {
  const newState = () => ({ ...state, ...payload });
  const deleteState = () => {
    const deletedItem = payload as IOfferSetting;
    const key = type === DELETE_OFFER_SETTINGS ? 'items' : 'deletedItems';
    const deletedItems = state[key]?.filter(({ id }: IOfferSetting) => id !== deletedItem.id);
    return { ...state, [key]: deletedItems };
  };

  const resetState = () => defaultState;

  const stateActions = {
    [LOAD_OFFER_SETTINGS]: newState,
    [IS_OFFER_SETTINGS_LOADING]: newState,
    [IS_OFFER_SETTING_ITEM_LOADING]: newState,
    [IS_OFFER_SETTINGS_DIALOG_LOADING]: newState,
    [EDIT_OFFER_SETTING]: newState,
    [LOAD_DELETED_OFFER_SETTING]: newState,
    [SAVE_SEARCH_RESULT]: newState,
    [GET_OFFER_SETTINGS_BY_ID]: newState,
    [GET_PRODUCT_PRICE_SELECTORS]: newState,
    [DELETE_OFFER_SETTINGS]: deleteState,
    [RESTORE_OFFER_SETTINGS]: deleteState,
    [HARD_DELETE_OFFER_SETTINGS]: deleteState,
    [RESET_OFFER_SETTINGS_STATE]: resetState,
  };

  const currentState = stateActions[type];
  if (!currentState) {
    return state;
  }
  return currentState();
};

export default offerSettings;
