import i18n from 'i18next';

import { IEditSite } from '../ProjectDetails/SiteDetail/Dialogs/EditHeaderDialog';
import { IMomSidebarState } from '../interfaces/MomTreeInterfaces';
import { ITemplateCatalog, ISite, ITemplate, INewSite } from '../interfaces/ProjectMomInterfaces';

import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';

export const IS_MOM_SITES_LOADING = 'IS_MOM_SITES_LOADING';
export const IS_MOM_SITES_DIALOG_LOADING = 'IS_MOM_SITES_DIALOG_LOADING';
export const LOAD_ALL_MOM_SITES_BY_EXTERNAL_ID = 'LOAD_ALL_MOM_SITES_BY_EXTERNAL_ID';
export const LOAD_ALL_TEMPLATE_CATALOGS = 'LOAD_ALL_TEMPLATE_CATALOGS';
export const LOAD_MOM_TEMPLATES_BY_CATALOG_ID = 'LOAD_MOM_TEMPLATES_BY_CATALOG_ID';
export const SELECT_PROJECT_MOM_SITE = 'SELECT_PROJECT_MOM_SITE';
export const LOAD_PROJECT_MOM_SITE_CONTENTS = 'LOAD_PROJECT_MOM_SITE_CONTENTS';
export const RESET_PROJECT_MOM_MODULE = 'RESET_PROJECT_MOM_MODULE';

const setIsLoading = (isLoading: boolean) => ({ type: IS_MOM_SITES_LOADING, payload: { isLoading } });

const setIsDialogLoading = (isDialogLoading: boolean) => ({
  type: IS_MOM_SITES_DIALOG_LOADING,
  payload: { isDialogLoading },
});

export const resetProjectMomModuleRoInitialState = () => ({ type: RESET_PROJECT_MOM_MODULE });

const loadAllMomSitesByExternalIdSuccess = (projectSites: Array<ISite>, totalProjectSites = 0) => ({
  type: LOAD_ALL_MOM_SITES_BY_EXTERNAL_ID,
  payload: { projectSites, totalProjectSites },
});

const loadAllTemplateCatalogsSuccess = (momTemplateCatalogs: Array<ITemplateCatalog>) => ({
  type: LOAD_ALL_TEMPLATE_CATALOGS,
  payload: { momTemplateCatalogs },
});

const loadMomTemplatesByCatalogIdSuccess = (allMomTemplates: Array<ITemplate>, totalMomTemplates: number) => ({
  type: LOAD_MOM_TEMPLATES_BY_CATALOG_ID,
  payload: { allMomTemplates },
});

export const selectProjectMomSite = (selectedProjectSite: ISite | null) => ({
  type: SELECT_PROJECT_MOM_SITE,
  payload: { selectedProjectSite },
});

export const loadProjectMomSiteContentsSuccess = (selectedProjectSiteContent: Array<any>) => ({
  type: LOAD_PROJECT_MOM_SITE_CONTENTS,
  payload: { selectedProjectSiteContent },
});

export const loadAllMomSitesByExternalId = (props: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { pageSize = 10, pageNumber = 0, externalId = '' } = props;
      const URL = `/fdv/api/sites/externalId/${externalId}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      const countUrl = `/fdv/api/sites/externalId/${externalId}/count?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      const {
        data: { payload },
      } = await API.get(URL);
      const {
        data: { payload: totalItems },
      } = await API.get(countUrl);
      dispatch(loadAllMomSitesByExternalIdSuccess(payload, totalItems));
    } catch (e) {
      const message = i18n.t('projects.errorLoadingProductsSites');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getMomSiteById = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/fdv/api/sites/${id}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(selectProjectMomSite(payload));
    } catch (e) {
      const message = i18n.t('projects.errorLoadingProjectSite');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteProjectSiteById = (props: any) => {
  return async (dispatch: Function) => {
    try {
      const { id = '', externalId = '' } = props;
      dispatch(setIsLoading(true));
      const URL = `/fdv/api/sites/${id}`;
      await API.delete(URL);
      dispatch(loadAllMomSitesByExternalId({ externalId }));
    } catch (e) {
      const message = i18n.t('projects.errorDeletingProjectSite');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadAllTemplateCatalogs = () => {
  return async (dispatch: Function) => {
    const url = '/fdv/api/templateCatalogs';
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      dispatch(loadAllTemplateCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('projects.errorLoadingTemplateCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadMomTemplatesByCatalogId = (props: any) => {
  return async (dispatch: Function) => {
    const { pageSize = 50, pageNumber = 0, catalogId = '' } = props;
    const url = `/fdv/api/templates/catalog/${catalogId}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    const countUrl = `/fdv/api/templates/catalog/${catalogId}/count`;
    try {
      dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.get(url);
      const {
        data: { payload: totalTemplates },
      } = await API.get(countUrl);
      dispatch(loadMomTemplatesByCatalogIdSuccess(payload, totalTemplates));
    } catch (e) {
      const message = i18n.t('products.errorLoadingMomTemplates');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createMomSite = (siteData: INewSite, callback = (id: any) => {}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = '/fdv/api/sites';
      const {
        data: { payload },
      } = await API.post(URL, siteData);
      dispatch(selectProjectMomSite(payload));
      callback(payload?.id);
    } catch (e) {
      const message = i18n.t('products.errorCreatingMomSite');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const editMomSite = (siteId: string, body: IEditSite) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/fdv/api/sites/${siteId}`;
      await API.put(URL, body);
      dispatch(loadProjectMomSiteContents(siteId));
      dispatch(getMomSiteById(siteId));
    } catch (e) {
      const message = i18n.t('products.errorEditingMomSite');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const loadProjectMomSiteContents = (siteId: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/fdv/api/sites/${siteId}/content`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadProjectMomSiteContentsSuccess(payload));
    } catch (e) {
      const message = i18n.t('products.errorLoadingProjectMomSiteContents');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateNodePosition = (siteId: string, { itemId, itemType, index, parentId }: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const type = itemType === 'component' ? 'components' : 'sections';
      const URL = `/fdv/api/sites/${siteId}/${type}/${itemId}`;
      await API.put(URL, { index, parentId: parentId ?? siteId });
      dispatch(loadProjectMomSiteContents(siteId));
    } catch (e) {
      const message = i18n.t('products.errorUpdatingList');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const copyNodeFromTemplate = (
  siteId: string,
  { sourceSectionId, targetIndex, targetParentId }: any,
  sourceId?: string
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const id = getState().projectsMomModule.selectedProjectSite?.id ?? siteId;
      const getSourceId = getState().momTreeModule.selectedTemplateId ?? sourceId;
      const URL = `/fdv/api/sites/${id}/copyFromTemplate`;
      await API.post(URL, {
        sourceId: getSourceId,
        sections: [{ sourceSectionId, targetIndex, targetParentId: targetParentId ?? id }],
      });
      dispatch(loadProjectMomSiteContents(id));
    } catch (e) {
      console.log(e);
      const message = i18n.t('products.errorAddingNewItem');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addSelectedNodesFromTemplate = (id: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedNodesIds, selectedTemplateId }: IMomSidebarState = getState().momSidebarModule;
      const sections = Object.values(selectedNodesIds).map((sourceSectionId) => ({
        sourceSectionId,
        targetParentId: null,
        targetIndex: null,
      }));
      const data = { sourceId: selectedTemplateId, sections };
      const URL = `/fdv/api/sites/${id}/copyFromTemplate`;
      await API.post(URL, data);
      dispatch(loadProjectMomSiteContents(id));
    } catch (e) {
      const message = i18n.t('products.errorAddingNewItem');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
