import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type MusicIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const MusicIcon: FC<MusicIconPropTypes> = ({
  width = 54,
  height = 54,
  color = '#0774A4',
  bgColor = '#F4F7FE',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  const resolvedBgColor = resolveThemeColor(bgColor, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="52.8417" height="52.8417" rx="12" fill={resolvedBgColor} />
      <path
        d="M15.9731 18.2104H13.2363V37.368C13.2363 38.8732 14.4679 40.1048 15.9731 40.1048H35.1308V37.368H15.9731V18.2104ZM37.8676 12.7368H21.4467C19.9415 12.7368 18.7099 13.9683 18.7099 15.4736V31.8944C18.7099 33.3996 19.9415 34.6312 21.4467 34.6312H37.8676C39.3728 34.6312 40.6044 33.3996 40.6044 31.8944V15.4736C40.6044 13.9683 39.3728 12.7368 37.8676 12.7368ZM26.9204 29.8418V17.5262L35.1308 23.684L26.9204 29.8418Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default MusicIcon;
