import { Box, BoxProps, styled } from '@mui/material';

export const TagContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'hasBorder' && prop !== 'borderColor',
})<BoxProps>`
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0.5) + ' ' + theme.spacing(1)};
  cursor: pointer;
  margin: ${({ theme }) => theme.spacing(0.5)};
  line-height: 0px;
`;

export const ConnectionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'hasBorder',
})<BoxProps>`
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(0) + ' ' + theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(0.5)};
`;
