import { Dispatch } from 'redux';
import i18n from 'i18next';
import { useQueryParams } from 'routeUtils';
import { AxiosResponse } from 'axios';
import {
  CREATE_NSCODE_ACTION,
  DELETE_NSCODE_ACTION,
  DELETE_NSCODE_TAG_ACTION,
  ICategory,
  ICreateCategory,
  ICreateNSCode,
  ICreateNSCodeResponse,
  INSCode,
  INSCodeFilterParameters,
  ISearchResult,
  IUpdateNSCode,
  SearchParams,
  INSCodesResponse,
} from './nsCodesInterface';
import {
  CREATE_CATEGORY_ACTION,
  SAVE_NSCODES_ACTION,
  UPDATE_NSCODE_ACTION,
  SET_NSCODE_ITEM_LOADER_ACTION,
  SET_HAS_MORE_ITEMS_ACTION,
  LOAD_ALL_NSCODES_SUCCESS_ACTION,
  SET_NSCODES_LOADER_ACTION,
  SAVE_SEARCHED_NSCODES_ACTION,
  ADD_ERROR_MESSAGE_ACTION,
  SAVE_NSCODES_COUNT_ACTION,
} from './types';
import API from 'utils/API';
import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';

export const CREATE_NSCODE = 'CREATE_NSCODE';
export const UPDATE_NSCODE = 'UPDATE_NSCODE';
export const DELETE_NSCODE = 'DELETE_NSCODE';

export const SAVE_NSCODES = 'SAVE_NSCODES';
export const SAVE_NSCODES_COUNT = 'SAVE_NSCODES_COUNT';

export const SET_NSCODES_LOADER = 'SET_NSCODES_LOADER';
export const SET_NSCODE_ITEM_LOADER = 'SET_NSCODE_ITEM_LOADER';
export const LOAD_ALL_NSCODES_SUCCESS = 'LOAD_ALL_NSCODES_SUCCESS';

export const SAVE_SEARCHED_NSCODES = 'SAVE_SEARCHED_NSCODES';

export const DELETE_NSCODE_TAG = 'DELETE_NSCODE_TAG';

export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const setHasMoreItems = (hasMoreItems: boolean): SET_HAS_MORE_ITEMS_ACTION => ({
  type: SET_HAS_MORE_ITEMS,
  hasMoreItems,
});

export const setNSCodeItemLoader = (isLoading: boolean): SET_NSCODE_ITEM_LOADER_ACTION => ({
  type: SET_NSCODE_ITEM_LOADER,
  isLoading,
});

export const setNSCodesLoader = (isLoading: boolean): SET_NSCODES_LOADER_ACTION => ({
  type: SET_NSCODES_LOADER,
  isLoading,
});

export const saveNSCodes = (nsCodes: INSCode[], totalItemsNumber?: number): SAVE_NSCODES_ACTION => ({
  type: SAVE_NSCODES,
  nsCodes,
  totalItemsNumber,
});

export const updateNSCodeAction = (nsCodeItem: INSCode): UPDATE_NSCODE_ACTION => ({
  type: UPDATE_NSCODE,
  nsCodeItem,
});

export const createCategoryAction = (categoryData: ICategory): CREATE_CATEGORY_ACTION => ({
  type: CREATE_CATEGORY,
  payload: categoryData,
});

export const createNSCodeAction = (nsCodeItem: INSCode): CREATE_NSCODE_ACTION => ({
  type: CREATE_NSCODE,
  nsCodeItem,
});

export const deleteNSCodeAction = (nsCodeId: string): DELETE_NSCODE_ACTION => ({
  type: DELETE_NSCODE,
  nsCodeId,
});

export const deleteNSCodeTagAction = (nsCodeId: string, nsCodeTagId: string): DELETE_NSCODE_TAG_ACTION => ({
  type: DELETE_NSCODE_TAG,
  nsCodeId,
  nsCodeTagId,
});

export const saveAllNSCodesAction = (
  allNSCodes: INSCode[] | [],
  totalNSCodesNumber: number,
  overWrite?: boolean
): LOAD_ALL_NSCODES_SUCCESS_ACTION => ({
  type: LOAD_ALL_NSCODES_SUCCESS,
  allNSCodes,
  totalNSCodesNumber,
  overWrite,
});

export const saveNSCodeCountAction = (totalNSCodesNumber: number): SAVE_NSCODES_COUNT_ACTION => ({
  type: SAVE_NSCODES_COUNT,
  totalNSCodesNumber,
});

export const saveSearchedNSCodesAction = (
  searchResult: ISearchResult | null,
  overWrite = false
): SAVE_SEARCHED_NSCODES_ACTION => ({
  type: SAVE_SEARCHED_NSCODES,
  searchResult,
  overWrite,
});

export const createCategory = (categoryData: ICreateCategory) => {
  return async (dispatch: Dispatch) => {
    const response = await API.post<ICategory>('nscodes/api/Categories', categoryData);

    dispatch(createCategoryAction(response.data));
  };
};

export const createNSCode = (nsCodeItem: ICreateNSCode, categoryId: string) => {
  return async (dispatch: Dispatch<CREATE_NSCODE_ACTION>) => {
    try {
      const { data }: AxiosResponse<ICreateNSCodeResponse> = await API.post(
        `nscodes/api/NSCodes/Category/${categoryId}`,
        nsCodeItem
      );
      dispatch(createNSCodeAction(data.payload));
      const { pageSize, pageNumber } = useQueryParams();
      // @ts-ignore
      setTimeout(() => dispatch(loadAndSearchNSCodes("", {pageSize, pageNumber, categoryId})), 1000);
    } catch (error) {}
  };
};

export const updateNSCode = (nsCodeItem: IUpdateNSCode) => {
  return async (dispatch: Dispatch<UPDATE_NSCODE_ACTION>) => {
    try {
      const URL = `nscodes/api/NSCodes/${nsCodeItem.id}`;
      const {
        data: { payload },
      } = await API.put(URL, nsCodeItem);
      dispatch(updateNSCodeAction(payload));
    } catch (error) {}
  };
};

export const deleteNSCode = (nsCodeId: string) => {
  return async (dispatch: Function) => {
    try {
      await API.delete(`nscodes/api/NSCodes/${nsCodeId}`);
      dispatch(deleteNSCodeAction(nsCodeId));
    } catch (error) {
      const message = i18n.t('nsCodes.somethingWentWrong');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const fetchNSCodes = (categoryId: string, { pageNumber = 0, pageSize = 10 }) => {
  return async (dispatch: Function) => {
    try {
      const { data }: AxiosResponse<{ payload: INSCode[] }> = await API.get(
        `nscodes/api/NSCodes/Category/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      dispatch(saveNSCodes(data.payload));
    } catch (error) {
      const message = i18n.t('nsCodes.somethingWentWrong');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const deleteTag = (nsCodeId: string, nsCodeTagId: string) => {
  return async (dispatch: Function) => {
    try {
      await API.delete(`nscodes/api/NsCodes/${nsCodeId}/Tags/${nsCodeTagId}`);
      dispatch(deleteNSCodeTagAction(nsCodeId, nsCodeTagId));
    } catch (error) {
      const message = i18n.t('nsCodes.somethingWentWrong');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const loadNSCodeItem = (nsCodeId: string) => {
  const getNSCodeUrl = `nscodes/api/NSCodes/${nsCodeId}`;
  return async (dispatch: Function) => {
    dispatch(setNSCodeItemLoader(true));
    try {
      const getNSCodePromise = API.get(getNSCodeUrl);
      const {
        data: { payload: offerItem },
      }: AxiosResponse<INSCodesResponse> = await getNSCodePromise;
      dispatch(saveNSCodes(offerItem));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setNSCodeItemLoader(false));
    }
  };
};

export const searchForNSCodes = (
  query: string,
  saveInSearched: boolean = false,
  params: IParams,
  categoryId?: string | null,
  overWrite?: boolean
) => {
  const pageSize = params?.pageSize || 12;
  const searchForNSCodesUrl = `search/api/nscodes/autocomplete`;
  const searchFields = {
    query,
    ...params,
    categoryId,
    fields: ['nsCode', 'description', 'treatment', 'tags'],
  };
  return async (dispatch: Function) => {
    dispatch(setNSCodeItemLoader(true));
    try {
      const searchForNSCodesPromise = API.post(searchForNSCodesUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForNSCodesPromise;
      if (totalHits === 0 || totalHits < Number(pageSize)) {
        Promise.reject();
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));

      if (saveInSearched) {
        dispatch(saveSearchedNSCodesAction({ totalHits, hits }, overWrite));
      }
      if (!saveInSearched) dispatch(saveAllNSCodesAction(hits, totalHits, true));
    } catch (error) {
      const message = i18n.t('nsCodes.errorSearchingForOffer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setNSCodeItemLoader(false));
    }
  };
};

export const loadAndSearchNSCodes = (query: string, searchParams: SearchParams) => {
  const pageNumber = searchParams?.pageNumber || 0;
  const pageSize = searchParams?.pageSize || 10;
  const fetchAndSearchNSCodesUrl = `search/api/nscodes/autocomplete`;
  const reqBody = {
    query: query ? query : null,
    ...searchParams,
    pageNumber,
    pageSize,
    deleted: false,
    fields: query ? ['nsCode', 'description', 'treatment', 'tags'] : null,
    categoryIds: searchParams.categoryId ? [searchParams.categoryId] : [],
    orderBy: "nsCode",
  };

  return async (dispatch: Function) => {
    dispatch(setNSCodeItemLoader(true));
    try {
      const searchForNSCodesPromise = API.post(fetchAndSearchNSCodesUrl, { ...reqBody });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForNSCodesPromise;
      if (hits.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else {
        dispatch(setHasMoreItems(true));
      }
      dispatch(saveNSCodes(hits, totalHits));
    } catch (error) {
      const message = i18n.t('nsCodes.errorSearchingForNSCodes');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setNSCodeItemLoader(false));
    }
  };
};

export const loadAllNSCodes = (categoryId: string | null, params?: IParams | null, overWrite?: boolean) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;
  const countUrl = `/nscodes/api/nscodes/${categoryId}/count`;
  let allNSCodesURL = `/nscodes/api/nscodes?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (categoryId) {
    allNSCodesURL = `/nscodes/api/nscodes?categoryId=${categoryId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }
  return async (dispatch: Function) => {
    dispatch(setNSCodesLoader(true));
    try {
      const countPromise = API.get(countUrl);
      const allNSCodesData = API.get(allNSCodesURL);
      const {
        data: { payload: nsCodes },
      }: AxiosResponse<INSCodesResponse> = await allNSCodesData;
      const {
        data: { payload: totalNSCodesNumber },
      } = await countPromise;
      if (nsCodes.length === 0 || nsCodes.length < Number(pageSize)) {
        Promise.reject();
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(saveAllNSCodesAction(nsCodes, totalNSCodesNumber, overWrite));
      // We nullify the searched items, so we can accuratly switch between pageNumber for searchNSCodes and loadNSCodes
      dispatch(saveSearchedNSCodesAction(null));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    } finally {
      dispatch(setNSCodesLoader(false));
    }
  };
};

export const filterNSCodes = (params?: INSCodeFilterParameters | null, overWrite: boolean = true) => {
  const categories = params?.categories || '';
  const dateFilter = params?.dateFilter || '';
  const tags = params?.tags || '';
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 10;

  const allNSCodesURL =
    `/nscodes/api/nscodes?` +
    `categoryId=${categories}` +
    `&dateFilter=${dateFilter}` +
    `&tags=${tags}` +
    `&pageNumber=${pageNumber}` +
    `&pageSize=${pageSize}`;

  return async (dispatch: Function) => {
    dispatch(setNSCodesLoader(true));
    try {
      const allNSCodesData = API.get(allNSCodesURL);
      const {
        data: { payload: nsCodes },
      }: AxiosResponse<INSCodesResponse> = await allNSCodesData;
      if (nsCodes.length === 0 || nsCodes.length < pageSize) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(saveAllNSCodesAction(nsCodes, nsCodes.length, overWrite));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    } finally {
      dispatch(setNSCodesLoader(false));
    }
  };
};
