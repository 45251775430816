import { IDocumentState, IProjectDocumentsAction } from '../interfaces/ProjectDocumentsInterfaces';
import {
  ADD_ERROR_MESSAGE,
  LOAD_DOCUMENTS,
  LOAD_FOLDERS,
  SAVE_SEARCHED_FOLDERS,
  SET_HAS_MORE_ITEMS,
  SET_IS_LOADING,
  SET_TOTAL_DOCUMENTS,
} from '../redux/ProjectsDocumentsActions';

const defaultState: IDocumentState = {
  error: null,
  isLoading: false,
  hasMoreItems: false,
  totalDocuments: 0,
  documents: [],
  rootFolder: null,
};

const projectDocumentsReducer = (state = defaultState, { type, payload }: IProjectDocumentsAction) => {
  const newState = () => ({ ...state, ...payload });

  const stateActions = {
    [SET_IS_LOADING]: newState,
    [ADD_ERROR_MESSAGE]: newState,
    [SET_HAS_MORE_ITEMS]: newState,
    [SET_TOTAL_DOCUMENTS]: newState,
    [LOAD_DOCUMENTS]: newState,
    [LOAD_FOLDERS]: newState,
    [SAVE_SEARCHED_FOLDERS]: newState,
  };

  const currentState = stateActions[type];
  if (!currentState) return state;
  return currentState();
};

export default projectDocumentsReducer;
