import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type DocumentsIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const DocumentsIcon: FC<DocumentsIconPropTypes> = ({
  width = 56,
  height = 56,
  color = '#0774A4',
  bgColor = '#F4F7FE',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  const resolvedBgColor = resolveThemeColor(bgColor, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill={resolvedBgColor} />
      <path
        d="M21.1057 15.8298H17.0005V19.935H14.2637V13.093H21.1057V15.8298ZM41.6317 19.935V13.093H34.7897V15.8298H38.8949V19.935H41.6317ZM21.1057 40.4611H17.0005V36.3559H14.2637V43.1979H21.1057V40.4611ZM38.8949 36.3559V40.4611H34.7897V43.1979H41.6317V36.3559H38.8949ZM37.5265 36.3559C37.5265 37.8611 36.295 39.0927 34.7897 39.0927H21.1057C19.6004 39.0927 18.3689 37.8611 18.3689 36.3559V19.935C18.3689 18.4298 19.6004 17.1982 21.1057 17.1982H34.7897C36.295 17.1982 37.5265 18.4298 37.5265 19.935V36.3559ZM32.0529 22.6718H23.8425V25.4086H32.0529V22.6718ZM32.0529 26.777H23.8425V29.5139H32.0529V26.777ZM32.0529 30.8823H23.8425V33.6191H32.0529V30.8823Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default DocumentsIcon;
