import { LOAD_ALL_CATALOGS } from './catalogsActions';
import { ICatalogAction, ICatalogState } from './catalogsTypes';

const defaultState = {
  items: [],
  maxPageSize: 0,
  defaultPageSize: 0,
  selectedItem: null,
  allowedModules: [],
  isLoading: false,
  isDialogLoading: false,
} as ICatalogState;

export const catalogs = (state = defaultState, action: ICatalogAction) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ALL_CATALOGS:
      return { ...state, ...payload };
  }
  return state;
};

export default catalogs;
