import { Box, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonListItem = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', my: 1 }}>
      <Box
        sx={{
          width: '10px',
          height: '10px',
          backgroundColor: 'tertiary.main',
          borderRadius: '50%',
        }}
      />
      <Box
        sx={{
          backgroundColor: 'grey.300',
          borderRadius: '10px',
          px: 2,
          py: 1,
          ml: 2,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
        <Skeleton variant="rectangular" width="48px" height="48px" sx={{ borderRadius: 3, marginRight: 3 }} />
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', width: '100%' }}>
          <Box>
            <Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width="80px" sx={{ fontSize: '1rem' }} />
          </Box>
          <Box>
            <Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width="80px" sx={{ fontSize: '1rem' }} />
          </Box>
          <Box>
            <Skeleton variant="text" width="100px" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width="80px" sx={{ fontSize: '1rem' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonListItem;
