// export const LICENSES_CODE = 10000; // NOT IN USE FRONTEND
// export const USERS_CODE = 10001; // NOT IN USE FRONTEND

export const GLOBALCONFIGURATION_CODE = 15000;
export const EMPLOYEES_CODE = 15001;
export const ORGANIZATIONS_CODE = 15002;
export const PRINT_CODE = 15003;
export const TEXTS_CODE = 15004;
export const SEARCH_CODE = 15005;

export const CRM_CODE = 20000;
export const CHECKLISTS_CODE = 20001;
export const DEVIATIONS_CODE = 20002;
export const DIBKAPPLICATIONS_CODE = 20003;
export const EXTERNALSEARCH_CODE = 20004;
export const DOCUMENTS_CODE = 20005;
export const FDV_CODE = 20006;
export const SITES_CODE = 20007;
export const MANUALS_CODE = 20008;

export const PROJECTS_CODE = 21000;
export const OFFERS_CODE = 21001;
export const BUILDINGELEMENTS_CODE = 21002;
export const NSCODES_CODE = 21003;

export const PRODUCTS_CODE = 22000;
export const WORKCATEGORIES_CODE = 22001;
export const PACKAGES_CODE = 22002;
export const PIECEWORK_CODE = 22003;

export const INVOICEPAYMENT_CODE = 23000;
export const DATAWAREHOUSE_CODE = 23001;

//Organizations
export const RØRENTREPRENØRENE_NORGE_CODE = 90000;
export const MLF_CODE = 90001;
export const VBL_CODE = 90002;
export const NELFO_CODE = 90003;

//Organizations for testing
export const TESTFAGORGANISASJON_3_CODE = 91999;
export const TESTFAGORGANISASJON_2_CODE = 91998;
export const TESTFAGORGANISASJON_1_CODE = 91997;

//Chains
export const BADEMILJØ_CODE = 92000;
export const COMFORT_CODE = 92001;
export const VARME_OG_BAD_CODE = 92002;
export const VVS_FAGMANN_CODE = 92003;
export const VVS_EKSPERTEN_CODE = 92004;
export const RØRKJØP_CODE = 92005;
export const ELPROFFEN_CODE = 92006;
export const HÅNDVERKSGRUPPEN_CODE = 92007;
export const FLOW_CODE = 92008;
export const NRA_CODE = 92009;
export const ELSCOOP_GROUP_CODE = 92010;
export const ELKONOR_CODE = 92011;
export const ELFAG_CODE = 92012;
export const SIKRINGEN_CODE = 92013;
export const NORGESELITEN_CODE = 92014;
export const AHLSELL_PARTNER_CODE = 92015;
// export const TESTKJEDE_3_CODE = 92999; // Chain for testing // NOT IN USE FRONTEND
// export const TESTKJEDE_2_CODE = 92998; // Chain for testing // NOT IN USE FRONTEND
// export const TESTKJEDE_1_CODE = 92997; // Chain for testing // NOT IN USE FRONTEND

// Industries
export const VVS_CODE = 93000;
export const ELEKTRO_CODE = 93001;
export const MALER_CODE = 93002;
export const BYGG_CODE = 93003;
export const VENTILASJON_CODE = 93004;
export const BLIKKENSLAGER_CODE = 93005;

export const LOCAL_TENANT_ADMINISTRATOR_CODE = 100001; // Admin access for users in own organization.
export const LOCAL_LICENSE_ADMINISTRATOR_CODE = 100002; //Admin access for user-licenses for users in own organization
export const LOCAL_GROUP_ADMINISTRATOR_CODE = 100003; // Admin access to the group the users tenant is a part of

export const SUPER_DUPER_USER_CODE = 101000; // Access to everything. This is only for Cordel employees.
export const GLOBAL_TENANT_ADMINISTRATOR_CODE = 101001; // Admin access for tenants and users
export const GLOBAL_LICENSE_ADMINISTRATOR_CODE = 101002; // Admin access for licenses
export const NEWS_FEED_ADMINISTRATOR_CODE = 101003; // Admin access for NewsFeed service

export const GLOBALCONFIGURATION_ADMINISTRATOR_CODE = 115000;
export const EMPLOYEES_ADMINISTRATOR_CODE = 115001;
export const ORGANIZATIONS_ADMINISTRATOR_CODE = 115002;
export const PRINT_ADMINISTRATOR_CODE = 115003;
export const TEXTS_ADMINISTRATOR_CODE = 115004;
export const SEARCH_ADMINISTRATOR_CODE = 115005;

export const CRM_ADMINISTRATOR_CODE = 120000;
export const CHECKLISTS_ADMINISTRATOR_CODE = 120001;
export const DEVIATIONS_ADMINISTRATOR_CODE = 120002;
export const DIBKAPPLICATIONS_ADMINISTRATOR_CODE = 120003;
export const EXTERNALSEARCH_ADMINISTRATOR_CODE = 120004;
export const DOCUMENTS_ADMINISTRATOR_CODE = 120005;
export const FDV_ADMINISTRATOR_CODE = 120006;
export const SITES_ADMINISTRATOR_CODE = 120007;
export const MANUALS_ADMINISTRATOR_CODE = 120008;
export const PROJECTS_ADMINISTRATOR_CODE = 121000;
export const OFFERS_ADMINISTRATOR_CODE = 121001;
export const NSCODES_ADMINISTRATOR_CODE = 121003;
export const PRODUCTS_ADMINISTRATOR_CODE = 122000;
export const WORKCATEGORIES_ADMINISTRATOR_CODE = 122001;
export const PACKAGES_ADMINISTRATOR_CODE = 122002;
export const PIECEWORK_ADMINISTRATOR_CODE = 122003;
export const INVOICEPAYMENT_ADMINISTRATOR_CODE = 123000;
export const DATAWAREHOUSE_ADMINISTRATOR_CODE = 123001;

export const RØRENTREPRENØRENE_NORGE_ADMINISTRATOR_CODE = 190000;
export const MLF_ADMINISTRATOR_CODE = 190001;
export const VBL_ADMINISTRATOR_CODE = 190002;
export const NELFO_ADMINISTRATOR_CODE = 190003;

export const BADEMILJØ_ADMINISTRATOR_CODE = 192000;
export const COMFORT_ADMINISTRATOR_CODE = 192001;
export const VARME_OG_BAD_ADMINISTRATOR_CODE = 192002;
export const VVS_FAGMANN_ADMINISTRATOR_CODE = 192003;
export const VVS_EKSPERTEN_ADMINISTRATOR_CODE = 192004;
export const RØRKJØP_ADMINISTRATOR_CODE = 192005;
export const ELPROFFEN_ADMINISTRATOR_CODE = 192006;
export const HÅNDVERKSGRUPPEN_ADMINISTRATOR_CODE = 192007;
export const FLOW_ADMINISTRATOR_CODE = 192008;
export const NRA_ADMINISTRATOR_CODE = 192009;
export const ELSCOOP_GROUP_ADMINISTRATOR_CODE = 192010;
export const ELKONOR_ADMINISTRATOR_CODE = 192011;
export const ELFAG_ADMINISTRATOR_CODE = 192012;
export const SIKRINGEN_ADMINISTRATOR_CODE = 192013;
export const NORGESELITEN_ADMINISTRATOR_CODE = 192014;
export const AHLSELL_PARTNER_ADMINISTRATOR_CODE = 192015;

export const VVS_ADMINISTRATOR_CODE = 193000;
export const ELEKTRO_ADMINISTRATOR_CODE = 193001;
export const MALER_ADMINISTRATOR_CODE = 193002;
export const BYGG_ADMINISTRATOR_CODE = 193003;
export const VENTILASJON_ADMINISTRATOR_CODE = 193004;
export const BLIKKENSLAGER_ADMINISTRATOR_CODE = 193005;

// Test chains / union organizations
// export const TESTFAGORGANISASJON_1_ADMINISTRATOR_CODE = 191997; // NOT IN USE FRONTEND
// export const TESTFAGORGANISASJON_2_ADMINISTRATOR_CODE = 191998; // NOT IN USE FRONTEND
// export const TESTFAGORGANISASJON_3_ADMINISTRATOR_CODE = 191999; // NOT IN USE FRONTEND
// export const TESTKJEDE_1_ADMINISTRATOR_CODE = 192997; // NOT IN USE FRONTEND
// export const TESTKJEDE_2_ADMINISTRATOR_CODE = 192998; // NOT IN USE FRONTEND
// export const TESTKJEDE_3_ADMINISTRATOR_CODE = 192999; // NOT IN USE FRONTEND
