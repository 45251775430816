// @ts-nocheck
import { ChangeEvent, MouseEvent, useEffect, useState, useMemo } from 'react';
import useRouteUtils, { IUseRouteUtils } from './useRouteUtils';
import { TablePagination, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface IPaging {
  length: number;
  totalItems: number | undefined;
  dialogMode?: boolean;
  changePageNumber?: number;
  searchMode?: boolean;
  spaceBetween?: boolean;
}

const TablePaginationStyled = styled(TablePagination, {
  shouldForwardProp: (propName: string) => !['spaceBetween'].includes(propName),
})`
  .MuiTablePagination-spacer {
    display: none !important;
  }

  .MuiTablePagination-displayedRows {
    flex-grow: ${(props) => (props.spaceBetween ? 1 : 0)};
  }
`;

export const Paging = (props: IPaging) => {
  const { t } = useTranslation();
  const {
    totalItems = 0,
    length,
    dialogMode = false,
    searchMode = false,
    changePageNumber,
    spaceBetween = true,
    ...rest
  } = props;
  // const pageNumberKey = dialogMode ? 'dialogPageNumber' : searchMode ? 'searchPageNumber' : 'pageNumber';
  // const pageSizeKey = dialogMode ? 'dialogPageSize' : searchMode ? 'searchPageSize' : 'pageSize';
  const pageNumberKey = useMemo(
    () => (dialogMode ? 'dialogPageNumber' : searchMode ? 'searchPageNumber' : 'pageNumber'),
    [dialogMode, searchMode]
  );
  const pageSizeKey = useMemo(
    () => (dialogMode ? 'dialogPageSize' : searchMode ? 'searchPageSize' : 'pageSize'),
    [dialogMode, searchMode]
  );
  const label = t('globals.showOnPage');
  const [searchParams] = useSearchParams();
  const { setQueryParam }: IUseRouteUtils = useRouteUtils(pageNumberKey, 0);
  const [page, setPage] = useState(searchParams.get(pageNumberKey) ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(searchParams.get(pageSizeKey) ?? 10);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, pageNumber: number) => {
    setPage(pageNumber);
    setQueryParam({ [pageNumberKey]: pageNumber });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = event.target.value;
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setQueryParam({ [pageSizeKey]: pageSize, [pageNumberKey]: 0 });
  };

  useEffect(() => {
    setPage(parseInt(searchParams.get(pageNumberKey)) || 0);
  }, [pageNumberKey, searchParams]);

  useEffect(() => {
    if (changePageNumber && changePageNumber !== 0) setPage(0);
  }, [changePageNumber]);

  useEffect(() => {
    if (!totalItems) return;
    if (parseInt(page) * rowsPerPage > totalItems) {
      setPage(0);
    }
  }, [page, rowsPerPage, totalItems]);

  if (!totalItems) return null;
  if (totalItems < length || totalItems === length) return null;

  return (
    <TablePaginationStyled
      showFirstButton
      showLastButton
      spaceBetween={spaceBetween}
      component="div"
      labelRowsPerPage={label}
      count={totalItems}
      page={parseInt(page) ?? 0}
      rowsPerPage={parseInt(rowsPerPage)}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      {...rest}
    />
  );
};

export default Paging;
