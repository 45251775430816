import { FC } from 'react';

type MenuIconDeleteIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconDeleteIcon: FC<MenuIconDeleteIconPropTypes> = ({ width = 35, height = 35, color = "#C3CAD9", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2539_15879)">
    <rect x="5" y="4.19263" width="24.3956" height="24.3956" rx="12.1978" fill="white"/>
    <rect x="5" y="4.19263" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637"/>
    </g>
    <path d="M13.9445 20.1854C13.9445 20.7817 14.4324 21.2697 15.0287 21.2697H19.3657C19.9621 21.2697 20.45 20.7817 20.45 20.1854V13.6799H13.9445V20.1854ZM20.9921 12.0535H19.0947L18.5525 11.5114H15.8419L15.2998 12.0535H13.4023V13.1378H20.9921V12.0535Z" fill={color}/>
    <defs>
    <filter id="filter0_d_2539_15879" x="0.121566" y="0.939895" width="34.1534" height="34.1538" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.62637"/>
    <feGaussianBlur stdDeviation="2.03297"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2539_15879"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2539_15879" result="shape"/>
    </filter>
    </defs>
    </svg>
  );
};

export default MenuIconDeleteIcon;
