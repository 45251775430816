import { Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { IFilterTag } from './DynamicFilter';
import { IOfferFilterParameters, OfferFilters } from 'modules/Offers/offersInterfaces';
import { useModuleList } from 'modules/common';
import { IOption } from './FilterOptions';
import NewSelect from 'components/NewSelect';
import { useAppSelector } from 'redux/hooks';

type IProps = {
  title: string;
  filterTags?: Array<IFilterTag>;
  setFieldValue: Function;
  mode: 'statuses' | 'tags';
  disabled?: boolean;
  values: IOfferFilterParameters;
  defaultValue?: Array<IOption>;
};

const FilterTags = (props: IProps) => {
  const { title, filterTags, setFieldValue, disabled = false, values } = props;
  const { t } = useModuleList();
  const defaultValue = props.defaultValue;
  const [tagFilterOptions, setTagFilterOptions] = useState<Array<IOption>>();
  const chosenFilters: OfferFilters = useAppSelector(({ offersModule }) => offersModule?.chosenFilters || {});

  const initallySelectedTags = useMemo(() => {
    if (!defaultValue) return [];
    return defaultValue;
  }, [defaultValue]);

  useEffect(() => {
    const tagOptions = chosenFilters?.tags?.map((tag) => ({ label: tag, value: tag }));
    setFieldValue('tags', tagOptions);
  }, [chosenFilters?.tags]);

  useEffect(() => {
    const setInitData = () => {
      if (!filterTags) return;
      let arr = [];
      for (let i = 0; i < filterTags?.length; i++) {
        const newTagItem = {
          label: filterTags?.[i]?.tagName || '',
          value: filterTags?.[i]?.tagName || '',
        };
        arr.push(newTagItem);
      }
      setTagFilterOptions(arr);
    };
    if (filterTags && filterTags?.length > 0) setInitData();
  }, [filterTags]);

  return (
    <Grid container>
      <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography fontWeight={600}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        {tagFilterOptions && (
          <NewSelect
            mode="searchable"
            isMulti
            menuPosition="fixed"
            name="tags"
            isDisabled={disabled}
            defaultValue={initallySelectedTags}
            options={tagFilterOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={t('globals.tags')}
            onChange={(tag: any) => {
              setFieldValue('tags', tag);
            }}
            value={values?.tags}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default FilterTags;
