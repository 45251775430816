import { Box, styled } from '@mui/material';

type FlexProps = {
  fullWidth?: boolean;
  alignCenter?: boolean;
  between?: boolean;
  column?: boolean;
};

export const Flex = styled(Box, {
  shouldForwardProp: (props) =>
    props !== 'fullWidth' && props !== 'alignCenter' && props !== 'between' && props !== 'column',
})<FlexProps>(({ fullWidth, alignCenter, between, column }) => ({
  display: 'flex',
  width: fullWidth ? '100%' : 'auto',
  alignItems: alignCenter ? 'center' : 'flex-start',
  justifyContent: between ? 'space-between' : 'flex-start',
  flexDirection: column ? 'column' : 'row',
}));

export const FlexBetween = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});
export const FlexStart = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: '100%',
});
export const FlexCenter = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FlexEnd = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  width: '100%',
});

export const FlexBetweenWhite = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: 'grey.300',
});

export const FlexColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const FlexColumnCenter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export const FlexColumnStart = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  width: '100%',
});

export const FlexColumnEnd = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'end',
  width: '100%',
});

type TDialogTagContainer = {
  bgcolor?: string;
  borderRadius?: string;
};

export const IconWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== 'isActive',
})<TDialogTagContainer>(({ borderRadius = '50%', theme }) => ({
  backgroundColor: theme.palette.gray.lighter,
  padding: theme.spacing(1),
  borderRadius: borderRadius,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const IconRoundWrapper = styled(Box)<any>(
  ({ theme }) => `
  border-radius: 50%;
  padding: 4px;
  width: 68px;
  height: 68px;
  background-color: ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 10%;
  right: 25%;
  align-self: end;
  margin-bottom: 16px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);`
);

export const IconRoundWrapperNoSidebar = styled(Box)<any>(
  ({ theme }) => `
  border-radius: 50%;
  padding: 4px;
  width: 68px;
  height: 68px;
  background-color: ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 24px;
  align-self: end;
  margin-bottom: 16px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  right: 5%;
  margin-left: auto;
  `
);

export const SpanStyled = styled('span')(
  () => `
    font-weight: bold;
    `
);

export const AutoSelectWrapper = styled(Box)<any>(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  borderRadius: '10px',
  border: '1px solid #efefef',
}));
