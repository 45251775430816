import { FC } from 'react';

type ViewIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ViewIcon: FC<ViewIconPropTypes> = ({ width = 22, height = 22, color = '#5A6E81', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0001 4.125C6.41675 4.125 2.50258 6.97583 0.916748 11C2.50258 15.0242 6.41675 17.875 11.0001 17.875C15.5834 17.875 19.4976 15.0242 21.0834 11C19.4976 6.97583 15.5834 4.125 11.0001 4.125ZM11.0001 15.5833C8.47008 15.5833 6.41675 13.53 6.41675 11C6.41675 8.47 8.47008 6.41667 11.0001 6.41667C13.5301 6.41667 15.5834 8.47 15.5834 11C15.5834 13.53 13.5301 15.5833 11.0001 15.5833ZM11.0001 8.25C9.47841 8.25 8.25008 9.47833 8.25008 11C8.25008 12.5217 9.47841 13.75 11.0001 13.75C12.5217 13.75 13.7501 12.5217 13.7501 11C13.7501 9.47833 12.5217 8.25 11.0001 8.25Z"
        fill={color}
      />
    </svg>
  );
};

export default ViewIcon;
