import { useState, useRef } from 'react';
import { File } from 'modules/Offers/offersInterfaces';

const useFileUpload = () => {
  const inputRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState('');

  const handleFileChange = (e: any) => {
    if (e === null) {
      setFile(null);
      setPreview('');
      return;
    }
    e.preventDefault();
    if (!e.target || !e.target.files) return;

    const reader = new FileReader();
    const file = e.target.files[0];

    if (inputRef?.current?.accept) {
      const accepts = inputRef.current.accept.split(',').map((type: string) => type.trim());
      if (!accepts.includes(file.type)) return;
    }

    reader.onloadstart = () => {
      setLoading(true);
      setFile(null);
      setPreview('');
    };

    reader.onloadend = (event: any) => {
      setLoading(false);
      setFile(file);
      setPreview(event?.target?.result);
    };

    reader.readAsDataURL(file);
  };

  // TODO: This is a temporary solution. We export the handleFileChange function, but the correct solution is to export the inputRef and the rest of the functionality logic be done here, inside the hook.
  // useEffect(() => {
  //   console.log('iside hook inputRef', inputRef);
  //   if (!inputRef.current) return;
  //   inputRef.current.onchange = handleFileChange;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inputRef.current, inputRef]);

  return { file, inputRef, preview, loading, handleFileChange };
};

export default useFileUpload;
