import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type CompanyPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const Company: FC<CompanyPropTypes> = ({
  width = 48,
  height = 48,
  color = '#A3AED0',
  bgColor = '#A3AED0',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  const resolvedBgColor = resolveThemeColor(bgColor, theme);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      color={resolvedColor}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="11.2" fill={resolvedBgColor} />
      <path
        d="M29 23V15H19V19H15V33H23V29H25V33H33V23H29ZM19 31H17V29H19V31ZM19 27H17V25H19V27ZM19 23H17V21H19V23ZM23 27H21V25H23V27ZM23 23H21V21H23V23ZM23 19H21V17H23V19ZM27 27H25V25H27V27ZM27 23H25V21H27V23ZM27 19H25V17H27V19ZM31 31H29V29H31V31ZM31 27H29V25H31V27Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default Company;
