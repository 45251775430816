import { useFormik } from 'formik';
import { useAppDispatch } from 'redux/hooks';

import { Box, Typography, DialogActions, Avatar } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getMyInfo, switchUserAccount } from './redux/userActions';
import { IUserState } from './userInterfaces';

import { AutoComplete, Button } from 'components';
import Dialog from 'containers/Dialog/Dialog';

import { useModuleList } from 'modules/common';
import { doesUserHaveAccess } from 'utils/authentication/RoleWrapper';
import { getRoute } from 'utils/helpers';
import { stringAvatar } from 'utils/helpers';
import { track } from 'utils/tracking';

export const switchAccountDialog = 'switchAccountDialog';

const UserSwitchDialog = () => {
  const { t, showDialog, isDialogLoading, useAppSelector, navigate } = useModuleList();
  const { myInfo }: IUserState = useAppSelector(({ userModule }) => userModule);

  const location = useLocation();
  const navigateTo = useNavigate();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true, //** re-initialize after personItem value changes. E.g. after initial load */,
    initialValues: {
      selectedCompanyId: myInfo?.currentRole.tenant.id,
    },
    onSubmit: async (values: any) => {
      await dispatch(switchUserAccount(values.selectedCompanyId));
      await dispatch(getMyInfo());
      const route = getRoute(location.pathname);
      if (doesUserHaveAccess(route)) {
        navigateTo(route);
        window.location.reload();
      } else {
        navigateTo('/');
        window.location.reload();
      }
    },
  });

  const { handleChange, values, validateForm, handleSubmit, submitForm } = formik;

  const availableCompanies = myInfo?.availableRoles
    ?.filter((role) => role.roleType === 'Cordel3')
    ?.map((role) => {
      return { label: role.tenant.companyName, value: role.tenant.id };
    });

  const handleCloseDialog = () => {
    navigate(-1);
  };

  const handleManualSubmit = async () => {
    const errors = await validateForm();
    if (!Object.keys(errors).length) {
      track('User switched tenant', {
        from: myInfo?.currentRole.tenant.id,
        to: values.selectedCompanyId,
      });
      await submitForm();
    }
  };

  const fullName = useMemo(() => {
    return myInfo?.forename + ' ' + myInfo?.surname || '';
  }, [myInfo?.forename, myInfo?.surname]);

  return (
    <Dialog maxWidth="sm" hideActions isDialogLoading={isDialogLoading} open={showDialog === switchAccountDialog}>
      <Box sx={{ height: '100%', width: '100%', backgroundColor: '#fff' }}>
        <Box display="flex" marginLeft={2} paddingX={3} paddingY={1}>
          <Avatar {...stringAvatar(fullName)} sx={{ bgcolor: 'orange' }} />
          <Box sx={{ marginLeft: 2 }}>
            <Typography sx={{ fontSize: '20px', color: '#333' }}>{fullName || ''}</Typography>
            <Typography sx={{ color: '#333' }}>{myInfo?.username || ''}</Typography>
          </Box>
        </Box>
        <Box sx={{ padding: 4, marginY: 2, backgroundColor: '#f7f7f7' }}>
          <form onSubmit={handleSubmit}>
            <AutoComplete
              name="selectedCompanyId"
              fullWidth
              label={t('user.selectCompany')}
              options={availableCompanies}
              optionKeyValue="value"
              optionKeyLabel="label"
              value={values.selectedCompanyId}
              onChange={handleChange}
              sx={{ backgroundColor: 'white' }}
            />
          </form>
        </Box>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            {t('globals.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleManualSubmit}>
            {t('user.switch')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UserSwitchDialog;
