import { FC } from 'react';

type CirclePersonSquareIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const CirclePersonSquareIcon: FC<CirclePersonSquareIconPropTypes> = ({
  width = 34,
  height = 34,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#E6F0F5" />
      <path
        d="M11.6666 13H10.3333V22.3333C10.3333 23.0667 10.9333 23.6667 11.6666 23.6667H20.9999V22.3333H11.6666V13ZM22.3333 10.3333H14.3333C13.5999 10.3333 12.9999 10.9333 12.9999 11.6667V19.6667C12.9999 20.4 13.5999 21 14.3333 21H22.3333C23.0666 21 23.6666 20.4 23.6666 19.6667V11.6667C23.6666 10.9333 23.0666 10.3333 22.3333 10.3333ZM18.3333 11.6667C19.4399 11.6667 20.3333 12.56 20.3333 13.6667C20.3333 14.7733 19.4399 15.6667 18.3333 15.6667C17.2266 15.6667 16.3333 14.7733 16.3333 13.6667C16.3333 12.56 17.2266 11.6667 18.3333 11.6667ZM22.3333 19.6667H14.3333V18.6667C14.3333 17.34 16.9999 16.6667 18.3333 16.6667C19.6666 16.6667 22.3333 17.34 22.3333 18.6667V19.6667Z"
        fill={color}
      />
    </svg>
  );
};

export default CirclePersonSquareIcon;
