import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import {
  SAVE_MY_INFORMATION_ACTION,
  SAVE_ADMINISTRATOR_FOR_LIST_ACTION,
  ADD_ERROR_MESSAGE_ACTION,
  SET_MY_INFO_LOADER_ACTION,
  UPDATE_MY_INFO_ACTION,
  SAVE_USER_INFO_ACTION,
  SAVE_ALL_USERS_ACTION,
} from './types';

import { GetUserResponse, IMyInfo, IMyInfoResponse, IUpdateMyInfo, User, IModuleObject } from '../userInterfaces';

import { CatalogModulesResponse } from 'containers/Sidebars/CatalogSidebar/CatalogInterface';

import { addSuccessMessage, addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';
import Auth from 'utils/Auth';

export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const SET_MY_INFO_LOADER = 'SET_MY_INFO_LOADER';

export const SAVE_MY_INFORMATION = 'SAVE_MY_INFORMATION';
export const UPDATE_MY_INFO = 'UPDATE_MY_INFO';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const SAVE_ADMINISTRATOR_FOR_LIST = 'SAVE_ADMINISTRATOR_FOR_LIST';
export const SAVE_ALL_USERS = 'SAVE_ALL_USERS';

export const saveMyInfoAction = (myInfo: IMyInfo): SAVE_MY_INFORMATION_ACTION => ({
  type: SAVE_MY_INFORMATION,
  myInfo,
});
export const saveAdministratorForListAction = (
  administratorForList: Array<IModuleObject>
): SAVE_ADMINISTRATOR_FOR_LIST_ACTION => ({
  type: SAVE_ADMINISTRATOR_FOR_LIST,
  administratorForList,
});
export const updateMyInfoAction = (myInfo: IMyInfo): UPDATE_MY_INFO_ACTION => ({
  type: UPDATE_MY_INFO,
  myInfo,
});

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});
export const setMyInfoLoaderAction = (isLoading: boolean): SET_MY_INFO_LOADER_ACTION => ({
  type: SET_MY_INFO_LOADER,
  isLoading,
});

export const saveUserInfo = (userInfo: User): SAVE_USER_INFO_ACTION => ({
  type: SAVE_USER_INFO,
  userInfo,
});

export const saveAllUsers = (users: User[]): SAVE_ALL_USERS_ACTION => ({
  type: SAVE_ALL_USERS,
  users,
});

export const getMyInfo = () => {
  const getMyUserURL = `/users/api/users/me`;
  return async (dispatch: Function) => {
    dispatch(setMyInfoLoaderAction(true));
    try {
      const countPromise = API.get(getMyUserURL);
      const {
        data: { payload: myInfo },
      }: AxiosResponse<IMyInfoResponse> = await countPromise;
      dispatch(saveMyInfoAction(myInfo));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setMyInfoLoaderAction(false));
    }
  };
};

export const getModuleAdministratorFor = () => {
  return async (dispatch: Function) => {
    dispatch(setMyInfoLoaderAction(true));
    try {
      const url = '/Licenses/api/Modules/User/AdministratorFor';
      const response = API.get(url);
      const {
        data: { payload },
      }: AxiosResponse<CatalogModulesResponse> = await response;
      if (payload) dispatch(saveAdministratorForListAction(payload as Array<IModuleObject>));
    } catch (error) {
      const errorMessage = 'Error while fetching user modules: ' + error;
      dispatch(addErrorMessageAction(errorMessage));
    } finally {
      dispatch(setMyInfoLoaderAction(false));
    }
  };
};

export const updateUserInfo = (userId: string, userInfo: IUpdateMyInfo) => {
  const updatedMyInfoUrl = `/users/api/users/${userId}`;
  return async (dispatch: Function) => {
    dispatch(setMyInfoLoaderAction(true));
    try {
      const updatePromise = API.put(updatedMyInfoUrl, { ...userInfo });
      const {
        data: { payload: myInfo },
      }: AxiosResponse<IMyInfoResponse> = await updatePromise;
      updateMyInfoAction(myInfo);
      dispatch(getMyInfo());
      const message = i18n.t('user.successUpdateUser');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('user.failUpdateUser');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setMyInfoLoaderAction(false));
    }
  };
};

export const switchUserAccount = (tenantId: string) => {
  const switchAccountUrl = `/users/api/refresh?newTenantId=${tenantId}`;
  return async (dispatch: Function) => {
    dispatch(setMyInfoLoaderAction(true));
    try {
      await API.get(switchAccountUrl);
      await Auth.refreshToken();
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setMyInfoLoaderAction(false));
    }
  };
};

export const logOutAccount = () => {
  const logOutAccountUrl = '/auth/logout';
  return async (dispatch: Function) => {
    dispatch(setMyInfoLoaderAction(true));
    try {
      const response = await API.get(logOutAccountUrl);
      if (response?.status === 200) {
        Auth.logout();
      }
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setMyInfoLoaderAction(false));
    }
  };
};

export const getUserById = (userId: string) => {
  const getUserByIdUrl = `/users/api/users/${userId}`;
  return async (dispatch: Function) => {
    dispatch(setMyInfoLoaderAction(true));
    try {
      const {
        data: { payload: userInfo },
      }: AxiosResponse<GetUserResponse> = await API.get(getUserByIdUrl);
      dispatch(saveUserInfo(userInfo));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setMyInfoLoaderAction(false));
    }
  };
};
