import { FC } from 'react';

type AddFolderIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const AddFolderIcon: FC<AddFolderIconPropTypes> = ({ width = 22, height = 22, color = "#0975A5", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.334 5.50008H11.0007L9.16732 3.66675H3.66732C2.64982 3.66675 1.84315 4.48258 1.84315 5.50008L1.83398 16.5001C1.83398 17.5176 2.64982 18.3334 3.66732 18.3334H18.334C19.3515 18.3334 20.1673 17.5176 20.1673 16.5001V7.33341C20.1673 6.31591 19.3515 5.50008 18.334 5.50008ZM17.4173 12.8334H14.6673V15.5834H12.834V12.8334H10.084V11.0001H12.834V8.25008H14.6673V11.0001H17.4173V12.8334Z" fill={color}/>
    </svg>
  );
};

export default AddFolderIcon;
