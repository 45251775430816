import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import API from 'utils/API';
import store from 'redux/store';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import {
  AccountResponse,
  CreatedAccount,
  EditAccount,
  EditAddress,
  IAccount,
  ICustomer,
  ICustomerResponse,
} from 'modules/Customers/CustomersInterfaces';

export const getCustomerById = async (customerId: string): Promise<ICustomer> => {
  const response = await API.get(`crm/api/customers/${customerId}`);
  const { data }: AxiosResponse<ICustomerResponse> = response;
  return data.payload;
};

type AddAccountToCustomerVariables = {
  customerId: string;
  account: CreatedAccount;
};
const addAccountToCustomer = async ({ customerId, account }: AddAccountToCustomerVariables): Promise<IAccount> => {
  const response = API.post(`crm/api/customers/${customerId}/accounts`, account);
  const { data }: AxiosResponse<AccountResponse> = await response;
  return data.payload;
};
type EditCustomerAccountVariables = {
  customerId: string;
  accountId: string;
  account: EditAccount;
};
const updateCustomerAccount = async ({
  customerId,
  accountId,
  account,
}: EditCustomerAccountVariables): Promise<IAccount> => {
  const response = API.put(`crm/api/customers/${customerId}/accounts/${accountId}`, account);
  const { data }: AxiosResponse<AccountResponse> = await response;
  return data.payload;
};
type EditCustomerAccountAddressVariables = {
  customerId: string;
  accountId: string;
  address: EditAddress;
};
const updateCustomerAccountDeliveryAddress = async ({
  customerId,
  accountId,
  address,
}: EditCustomerAccountAddressVariables) => {
  const response = API.put(`crm/api/customers/${customerId}/accounts/${accountId}/deliveryaddress`, address);
  const { data }: AxiosResponse<AccountResponse> = await response;
  return data.payload;
};
const updateCustomerAccountInvoiceAddress = async ({
  customerId,
  accountId,
  address,
}: EditCustomerAccountAddressVariables) => {
  const response = API.put(`crm/api/customers/${customerId}/accounts/${accountId}/invoiceaddress`, address);
  const { data }: AxiosResponse<AccountResponse> = await response;
  return data.payload;
};

type DeleteCustomerContactVariables = {
  customerId: string;
  accountId: string;
};
export const deleteCustomerAccount = async ({
  customerId,
  accountId,
}: DeleteCustomerContactVariables): Promise<ICustomer> => {
  const response = API.delete(`crm/api/customers/${customerId}/accounts/${accountId}`);
  const { data }: AxiosResponse<ICustomerResponse> = await response;
  return data.payload;
};

export const useGetCustomerById = (customerId: string) =>
  useQuery({
    queryKey: ['customer', customerId],
    queryFn: () => getCustomerById(customerId),
    retry: 3,
    retryDelay: 3000,
    staleTime: 120000,
  });

export const useAddAccountToCustomer = (queryClient: QueryClient, customerId: string) =>
  useMutation(addAccountToCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', customerId]);
      const message = i18n.t('customers.successCreatedNewAccount');
      store.dispatch(addSuccessMessage({ message }));
    },
    onError: (error: any) => {
      store.dispatch(addErrorMessage({ message: error.response.data.message || error.response.data }));
    },
  });

export const useDeleteCustomerAccount = (queryClient: QueryClient, customerId: string) =>
  useMutation(deleteCustomerAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries(['company', customerId]);
    },
    onError: (error: any) => {
      store.dispatch(addErrorMessage({ message: error.response.data.message || error.response.data }));
    },
  });

export const useUpdateCustomerAccount = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(updateCustomerAccount, {
    onSuccess: () => {
      const message = i18n.t('crm.successUpdateAccount');
      store.dispatch(addSuccessMessage({ message }));
      queryClient.invalidateQueries(refetch || ['']);
      setTimeout(() => {
        onSuccess?.();
      }, 500);
    },
    onError: (error: any) => {
      const failAddPersonMessage = i18n.t('crm.failUpdateAccount');
      store.dispatch(addErrorMessage({ message: failAddPersonMessage }));
      onError?.(error);
    },
  });
export const useUpdateCustomerAccountDeliveryAddress = () => useMutation(updateCustomerAccountDeliveryAddress);
export const useUpdateCustomerAccountInvoiceAddress = () => useMutation(updateCustomerAccountInvoiceAddress);
