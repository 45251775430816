import { FC } from 'react';

type DownloadPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Download: FC<DownloadPropTypes> = ({ width = 66, height = 65, color = '#E6F0F5', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0566 54.1493H51.9427V48.737H14.0566V54.1493ZM51.9427 24.3817H41.1181V8.14478H24.8812V24.3817H14.0566L32.9997 43.3247L51.9427 24.3817Z"
        fill={color}
      />
    </svg>
  );
};

export default Download;
