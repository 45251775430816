import { Tab, styled } from '@mui/material';

export const TabItemStyled = styled(Tab, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'color',
})<any>(({ theme, color }) => ({
  borderRadius: theme.spacing(1),
  fontSize: theme.spacing(1.5),
  fontWeight: 500,
  height: theme.spacing(4),
  letterSpacing: `0.2px`,
  minHeight: `auto`,
  minWidth: `auto`,
  lineHeight: 1,
  padding: theme.spacing(1.25, 1.5),
  marginRight: theme.spacing(2),
  '&:last-of-type': {
    marginRight: 0,
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.primary[color]}`,
    color: `${theme.palette.grey[300]}`,
    borderBottom: 0,
  },
}));
