import { Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DatePicker from 'components/DatePicker/DatePicker';
import { SelectWrapper } from 'components/Select/Select.styled';
import { IOption } from './FilterOptions';
import { useTranslation } from 'react-i18next';
import NewDatePicker from 'components/NewDatePicker/NewDatePicker';
import { DatePickerStyled } from './DynamicFilter.styled';
import { NewAutoCompleteStyled } from 'modules/AdminBoard/AdminBoard.styled';

type IProps = {
  options: Array<IOption>;
  handleChange: Function;
  setFieldValue: Function;
  values: any;
};

const FilterDatePickers = (props: IProps) => {
  const { options, setFieldValue, handleChange, values } = props;
  const { t } = useTranslation();

  return (
    <Grid container sx={{ flexDirection: 'column', p: 0 }}>
      <Typography sx={{ fontWeight: 600, pb: 1 }}>{t('globals.filterOnDate')}</Typography>
      <Grid item pb={1}>
        <NewAutoCompleteStyled
          fullWidth
          hasBorder={false}
          options={options}
          onChange={handleChange}
          name="dateFilter"
          optionKeyLabel="label"
          optionKeyValue="value"
          value={values.dateFilter}
          sx={{ borderRadius: '12px' }}
        />
      </Grid>
      <Grid item display={'flex'} flexDirection={'column'}>
        <Grid item pt={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerStyled
              fullWidth
              hasBorder={false}
              value={values.dateFrom}
              setFieldValue={setFieldValue}
              name="dateFrom"
              label={'From'}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item pt={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerStyled
              fullWidth
              hasBorder={false}
              value={values.dateTo}
              setFieldValue={setFieldValue}
              name="dateTo"
              label={'To'}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FilterDatePickers;
