import { FC } from 'react';
import {
  FormControl,
  Select as MuiSelect,
  InputLabel,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';

interface IOption {
  label: string | any;
  value: string | number | any;
}

interface IProps {
  options: Array<IOption>;
  value?: string;
  name?: string;
  label?: string;
  onChange?: Function;
  onBlur?: Function;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  size?: 'small' | 'medium';
  variant?: 'standard' | 'filled' | 'outlined';
}

export const Select: FC<any> = (props: IProps) => {
  const {
    fullWidth = false,
    margin = 'none',
    options = [],
    value,
    onChange = () => {},
    onBlur = () => {},
    name,
    label,
    error,
    helperText,
    size = 'medium',
    variant = 'outlined',
    ...rest
  } = props;

  const inputLabelId = `id-${label?.replace(' ', '-')}`;

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value && event.target.value !== value) {
      onChange({ target: { name, value: event.target.value } });
    }
  };

  const handleBlur = (event: SelectChangeEvent) => {
    if (event.target.value) {
      onBlur(event);
    }
  };

  return (
    <FormControl error={error} margin={margin} size={size} fullWidth={fullWidth} variant={variant}>
      {label && <InputLabel id={inputLabelId}>{label}</InputLabel>}
      <MuiSelect
        style={{ paddingLeft: '6px' }}
        displayEmpty
        name={name}
        value={value}
        label={label}
        labelId={inputLabelId}
        onChange={handleChange}
        // @ts-ignore
        onBlur={handleBlur}
        {...rest}>
        {options.map((option) => (
          <MenuItem key={`select-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText id={`${label}-helper-text`}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
