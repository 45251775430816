import { AxiosResponse } from 'axios';
import i18n from 'i18next';
import store from 'redux/store';

import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { AddTags, ChangeTagsToOfferResponse, ISearchResult, OfferFilters } from 'modules/Offers/offersInterfaces';
import API from 'utils/API';
import { IS_BD_O_HYWER_TENANT } from 'utils/authentication/RoleWrapper';

export type OffersSearchArguments = {
  query: string | null;
} & OfferFilters;

export const searchOffers = async (searchParams: OffersSearchArguments) => {
  const pageNumber = searchParams?.pageNumber || 0;
  const pageSize = searchParams?.pageSize || 10;
  const fetchAndSearchoffersUrl = `search/api/offers/autocomplete`;
  const customerNumber = IS_BD_O_HYWER_TENANT() ? 'customerexternalreference' : 'customerNumber';
  const reqBody = {
    ...searchParams,
    query: searchParams.query ? searchParams.query : null,
    pageNumber,
    pageSize,
    deleted: false,
    descending: true,
    fields: searchParams.query
      ? [customerNumber, 'name', 'number', 'customername', 'sitename', 'description', 'responsibleuser', 'tags']
      : null,
  };
  const searchForOffersPromise = API.post(fetchAndSearchoffersUrl, { ...reqBody });
  const {
    data: { totalHits, hits },
  }: AxiosResponse<ISearchResult> = await searchForOffersPromise;
  return { totalHits, hits };
};
const saveOfferFilterToUserConfig = (config: OfferFilters | null) =>
  API.put('/userConfiguration/data/offerFilters', { filters: config });

const getOfferFilterFromUserConfig = () => API.get('/userConfiguration/data/offerFilters');

const addTagsToOffer = ({ offerId, tags }: AddTags): Promise<AxiosResponse<ChangeTagsToOfferResponse>> => {
  const parameters = tags.map((tag) => `tag=${tag}`).join('&');
  return API.post(`offer/api/offers/${offerId}/tags?${parameters}`);
};

const deleteTagsFromOffer = ({ offerId, tags }: AddTags): Promise<AxiosResponse<ChangeTagsToOfferResponse>> => {
  const parameters = tags.map((tag) => `tag=${tag}`).join('&');
  return API.delete(`offer/api/offers/${offerId}/tags?${parameters}`);
};

type Configuration = {
  keepPosNumberOnChildren: boolean;
  keepPosNumberOnSiblings: boolean;
};

const savePositionNodeConfiguration = (config: Configuration) =>
  API.put('/userConfiguration/data/positionNodeConfiguration', { ...config });

const getPositionNodeConfiguration = () => API.get('/userConfiguration/data/positionNodeConfiguration');

export const useSearchOffers = (searchArguments: OffersSearchArguments) =>
  useQuery({
    queryKey: [
      'searchOffers',
      searchArguments.query,
      searchArguments.pageNumber,
      searchArguments.pageSize,
      searchArguments.tags,
      searchArguments.categoryId,
      searchArguments.responsibleUserId,
      searchArguments.accountId,
      searchArguments.customerId,
      searchArguments.fromOfferDate,
      searchArguments.toOfferDate,
      searchArguments.fromDeadline,
      searchArguments.toDeadline,
      searchArguments.fromWantedDeliveryDate,
      searchArguments.toWantedDeliveryDate,
      searchArguments.fromSentToCustomer,
      searchArguments.toSentToCustomer,
      searchArguments.fromPlannedFollowupDate,
      searchArguments.toPlannedFollowupDate,
      searchArguments.fromActualFollowupDate,
      searchArguments.toActualFollowupDate,
      searchArguments.fromAcceptedByCustomer,
      searchArguments.toAcceptedByCustomer,
      searchArguments.fromRejectedByCustomer,
      searchArguments.toRejectedByCustomer,
      searchArguments.fromCustomerAmount,
      searchArguments.toCustomerAmount,
      searchArguments.fromContribution,
      searchArguments.toContribution,
      searchArguments.active,
      searchArguments.deleted,
      searchArguments.orderBy,
      searchArguments.descending,
      searchArguments.offerType,
    ],
    queryFn: () => searchOffers(searchArguments),
  });

export const useAddOfferFilterToUserConfig = () => useMutation(saveOfferFilterToUserConfig);

export const useGetOfferFilterFromUserConfig = () =>
  useQuery({ queryKey: ['offerFilters'], queryFn: getOfferFilterFromUserConfig, enabled: false });

export const useAddTagsToOffer = () => useMutation(addTagsToOffer);

export const useDeleteTagsFromOffer = () => useMutation(deleteTagsFromOffer);

// export const useSetPositionNodeConfiguration = () => useMutation(savePositionNodeConfiguration);

export const useSetPositionNodeConfiguration = (
  queryClient: QueryClient,
  refetch?: string[],
  onSuccess?: Function,
  onError?: Function
) =>
  useMutation(savePositionNodeConfiguration, {
    onSuccess: () => {
      const message = i18n.t('offers.successUpdatePosNumber');
      setTimeout(() => {
        store.dispatch(addSuccessMessage({ message }));
        queryClient.invalidateQueries(refetch || ['positionNodeConfiguration']);
      }, 1000);
      setTimeout(() => {
        onSuccess?.();
      }, 1500);
    },
    onError: (error: any) => {
      const failAddPersonMessage = i18n.t('offers.failUpdatePosNumber');
      store.dispatch(addErrorMessage({ message: error.response.data.message || failAddPersonMessage }));
      onError?.(error);
    },
  });

export const useGetPositionNodeConfiguration = () =>
  useQuery({ queryKey: ['positionNodeConfiguration'], queryFn: getPositionNodeConfiguration, enabled: false });
