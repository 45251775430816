import { NSCODES_ACTIONS } from './types';
import { INSCodesState } from './nsCodesInterface';
import {
  CREATE_NSCODE,
  CREATE_CATEGORY,
  SAVE_NSCODES,
  DELETE_NSCODE,
  UPDATE_NSCODE,
  DELETE_NSCODE_TAG,
  SAVE_SEARCHED_NSCODES,
  SAVE_NSCODES_COUNT,
  LOAD_ALL_NSCODES_SUCCESS,
} from './nsCodesActions';

const defaultState: INSCodesState = {
  isItemsLoading: false,
  isDialogLoading: false,
  isItemLoading: false,
  items: [],
  totalItemsNumber: 0,
  deletedItems: null,
  totalDeletedItemNumber: 0,
  selectedItem: null,
  error: null,
  createdItem: null,
  searchedItems: null,
  hasMoreItems: true,
  tagFilters: [],
  categories: [],
  usersList: null,
  chosenFilters: null,
};

const nsCodesReducer = (state = defaultState, action: NSCODES_ACTIONS) => {
  switch (action.type) {
    case LOAD_ALL_NSCODES_SUCCESS:
      if (action.overWrite)
        return {
          ...state,
          items: action.allNSCodes,
          isItemsLoading: false,
          totalItemsNumber: action.totalNSCodesNumber,
        };
      return {
        ...state,
        items: [...state.items, ...action.allNSCodes],
        isItemsLoading: false,
        totalItemsNumber: action.totalNSCodesNumber,
      };
    case CREATE_NSCODE:
      return {
        ...state,
        items: [...state.items, action.nsCodeItem],
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    case UPDATE_NSCODE:
      const updatedItems = state.items.map((code) => (code.id === action.nsCodeItem.id ? action.nsCodeItem : code));
      return {
        ...state,
        items: [...updatedItems],
      };
    case DELETE_NSCODE:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.nsCodeId),
      };
    case SAVE_NSCODES:
      return {
        ...state,
        items: action.nsCodes,
        totalItemsNumber: action.totalItemsNumber || 0,
      };
    case DELETE_NSCODE_TAG:
      const newItems = state.items.map((item) =>
        item.id === action.nsCodeId ? { ...item, tags: item.tags.filter((tag) => tag !== action.nsCodeTagId) } : item
      );
      return {
        ...state,
        items: newItems,
      };
    case SAVE_NSCODES_COUNT:
      return { ...state, totalItemsNumber: action.totalNSCodesNumber };
    case SAVE_SEARCHED_NSCODES:
      if (action.searchResult?.totalHits || action.searchResult?.totalHits === 0) {
        if (!action.overWrite) {
          return {
            ...state,
            searchedItems: [...(state.searchedItems || []), ...action.searchResult.hits],
            totalItemsNumber: action.searchResult?.totalHits,
          };
        } else {
          return {
            ...state,
            searchedItems: action.searchResult.hits,
            totalItemsNumber: action.searchResult?.totalHits,
          };
        }
      }
      return { ...state, searchedItems: action.searchResult?.hits || null };
    default:
      return state;
  }
};

export default nsCodesReducer;
