import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { IUser } from 'interfaces/users/IUsers';
import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import { IOfferItem, ISearchResult } from 'modules/Offers/offersInterfaces';
import API from 'utils/API';
import { ISearchUsersResult } from './types';

export const IS_LOADING = 'IS_LOADING';
export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';
export const LOAD_USERS = 'LOAD_USERS';
export const SAVE_TOTAL_USERS = 'SAVE_TOTAL_USERS';
export const LOAD_ALL_OFFERS = 'LOAD_ALL_OFFERS';
export const SAVE_TOTAL_OFFERS = 'SAVE_TOTAL_OFFERS';

const setIsLoading = (payload: boolean) => ({ type: IS_LOADING, payload });

export const setHasMoreItems = (payload: boolean) => ({ type: SET_HAS_MORE_ITEMS, payload });

export const saveTotalUsersSuccess = (payload: number) => ({
  type: SAVE_TOTAL_USERS,
  payload,
});

export const saveTotalOffersSuccess = (payload: number) => ({
  type: SAVE_TOTAL_OFFERS,
  payload,
});

export const loadUsersSuccess = (payload: Array<IUser>) => ({
  type: LOAD_USERS,
  payload,
});

export const loadAlloffersSuccess = (payload: Array<IOfferItem>) => ({
  type: LOAD_ALL_OFFERS,
  payload,
});

export const loadUsers = (tenantId: string, pageSize: number, pageNumber: number) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const getUsers = `users/api/tenants/${tenantId}/users?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      const getUsersPromise = await API.get(getUsers);
      const {
        data: { payload },
      } = await getUsersPromise;
      if (payload.length === 0 || payload.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(loadUsersSuccess(payload));
      dispatch(saveTotalUsersSuccess(payload.length));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorGettingUsers') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadUsersViaSearch = (pageSize: number, pageNumber: number) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const getUsers = `search/api/users/autocomplete`;
      const body = {
        pageSize,
        pageNumber,
        own: true,
      };
      const getUsersPromise = await API.post(getUsers, body);
      const {
        data: { hits, totalHits },
      } = await getUsersPromise;
      if (totalHits.length === 0 || totalHits.length < Number(pageSize)) {
        Promise.reject();
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(loadUsersSuccess(hits));
      dispatch(saveTotalUsersSuccess(totalHits));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorGettingUsers') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForUsers = (pageSize: number, pageNumber: number, searchValue: string) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchUsers = `search/api/users/autocomplete`;
      const body = {
        query: searchValue,
        pageSize,
        pageNumber,
        fields: ['username', 'forename', 'surname'],
      };
      const getUsersPromise = await API.post(searchUsers, body);
      const {
        data: { hits, totalHits },
      }: AxiosResponse<ISearchUsersResult> = await getUsersPromise;
      const length: number = hits.length;
      if (length === 0 || hits.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(loadUsersSuccess(hits));
      dispatch(saveTotalUsersSuccess(totalHits));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorGettingUsers') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadAllOffers = (pageSize: number | 10, pageNumber: number | 0) => {
  let allOffersURL = `/search/api/offers/autocomplete`;
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const body = {
        pageSize,
        pageNumber,
        orderBy: 'number',
        descending: true,
      };
      const allOffersData = await API.post(allOffersURL, body);
      const {
        data: { hits: offers, totalHits },
      }: AxiosResponse<ISearchResult> = await allOffersData;
      if (offers.length === 0 || offers.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(saveTotalOffersSuccess(totalHits));
      dispatch(loadAlloffersSuccess(offers));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorGettingOffers') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchAllOffers = (pageSize: number | 10, pageNumber: number | 0, searchValue: string) => {
  let allOffersURL = `/search/api/offers/autocomplete`;
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const body = {
        query: searchValue,
        pageSize,
        pageNumber,
        fields: ['name', 'description', 'tags', 'responsibleuser', 'number'],
        orderBy: 'number',
        descending: true,
      };
      const searchOffersData = await API.post(allOffersURL, body);
      const {
        data: { hits: offers, totalHits },
      }: AxiosResponse<ISearchResult> = await searchOffersData;
      if (offers.length === 0 || offers.length < Number(pageSize)) {
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(saveTotalOffersSuccess(totalHits));
      dispatch(loadAlloffersSuccess(offers));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateMultiUsersConfiguration = (userIds: string[], filterValues: any, userConfigKey: string) => {
  return async (dispatch: Function) => {
    try {
      const url = `userconfiguration/administration/${userConfigKey}`;
      const finalUrl = url.concat(handleUserList(userIds));
      await API.put(finalUrl, filterValues);
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    }
  };
};

export const handleUserList = (userIds: string[]) => {
  let finalQuery = `?userid[]=${userIds[0]}`;
  if (userIds.length > 1) {
    for (let i = 1; i < userIds.length; i++) {
      const query = `&userid[]=${userIds[i]}`;
      finalQuery = finalQuery.concat(query);
    }
  }
  return finalQuery;
};
