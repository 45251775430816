import { FC } from 'react';

type MenuIconEditPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconEdit: FC<MenuIconEditPropTypes> = ({ width = 42, height = 42, color = '#C3CAD9', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_7684_10063)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        d="M15.627 21.3553V23.3891H17.6609L23.6592 17.3908L21.6254 15.3569L15.627 21.3553ZM25.2321 15.8179C25.4436 15.6064 25.4436 15.2647 25.2321 15.0532L23.963 13.7841C23.7514 13.5726 23.4098 13.5726 23.1983 13.7841L22.2058 14.7766L24.2396 16.8104L25.2321 15.8179Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_7684_10063"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7684_10063" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7684_10063" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MenuIconEdit;
