import { FC } from 'react';

type MenuIconComponentIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconComponentIcon: FC<MenuIconComponentIconPropTypes> = ({
  width = 35,
  height = 35,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6074_24156)">
        <rect x="5" y="4" width="24.3956" height="24.3956" rx="12.1978" fill="white" />
        <rect x="5" y="4" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637" />
      </g>
      <g clipPath="url(#clip0_6074_24156)">
        <path
          d="M13.4019 10.7765C13.4019 10.4783 13.1579 10.2344 12.8598 10.2344C12.5616 10.2344 12.3176 10.4783 12.3176 10.7765V12.945H11.2334V16.1977H14.4861V12.945H13.4019V10.7765ZM15.5704 18.3662C15.5704 19.071 16.0258 19.6673 16.6546 19.895V22.1611H17.7389V19.895C18.3678 19.6728 18.8231 19.0764 18.8231 18.3662V17.282H15.5704V18.3662ZM11.2334 18.3662C11.2334 19.071 11.6888 19.6673 12.3176 19.895V22.1611H13.4019V19.895C14.0308 19.6673 14.4861 19.071 14.4861 18.3662V17.282H11.2334V18.3662ZM22.0759 12.945V10.7765C22.0759 10.4783 21.8319 10.2344 21.5338 10.2344C21.2356 10.2344 20.9916 10.4783 20.9916 10.7765V12.945H19.9074V16.1977H23.1601V12.945H22.0759ZM17.7389 10.7765C17.7389 10.4783 17.4949 10.2344 17.1968 10.2344C16.8986 10.2344 16.6546 10.4783 16.6546 10.7765V12.945H15.5704V16.1977H18.8231V12.945H17.7389V10.7765ZM19.9074 18.3662C19.9074 19.071 20.3628 19.6673 20.9916 19.895V22.1611H22.0759V19.895C22.7048 19.6728 23.1601 19.0764 23.1601 18.3662V17.282H19.9074V18.3662Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6074_24156"
          x="0.121078"
          y="0.747268"
          width="34.1538"
          height="34.1538"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.62637" />
          <feGaussianBlur stdDeviation="2.03297" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6074_24156" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6074_24156" result="shape" />
        </filter>
        <clipPath id="clip0_6074_24156">
          <rect width="13.011" height="13.011" fill="white" transform="translate(10.6914 9.69226)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuIconComponentIcon;
