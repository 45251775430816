import { FC } from 'react';

export const NorwayFlag: FC = () => {
  return (
    <svg
      width="28px"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 203.55 141.6">
      <g fillRule="nonzero">
        <path
          fill="#EF2B2D"
          d="M11.19 0h181.17c6.15 0 11.19 5.03 11.19 
          11.19v119.22c0 6.16-5.04 11.19-11.19 11.19H11.19C5.03 141.6
         0 136.57 0 130.41V11.19C0 5.03 5.03 0 11.19 0z"
        />
        <path fill="#fff" d="M92.35 0v141.6H54.67V0z" />
        <path fill="#fff" d="M0 51.96h203.55v37.68H0z" />
        <path fill="#002868" d="M82.93 0v141.6H64.09V0z" />
        <path fill="#002868" d="M0 61.38h203.55v18.84H0z" />
      </g>
    </svg>
  );
};

export default NorwayFlag;
