import { FC } from 'react';

type ProductSelectionIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ProductSelectionIcon: FC<ProductSelectionIconPropTypes> = ({ width = 56, height = 56, color = "#78603D", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="28" fill="#FDF7ED"/>
    <path d="M37.526 18.5666V37.7243H18.3684V18.5666H37.526ZM39.0313 15.8298H16.8632C16.179 15.8298 15.6316 16.3772 15.6316 17.0614V39.2295C15.6316 39.7769 16.179 40.4611 16.8632 40.4611H39.0313C39.5786 40.4611 40.2628 39.7769 40.2628 39.2295V17.0614C40.2628 16.3772 39.5786 15.8298 39.0313 15.8298ZM26.5788 21.3034H34.7892V24.0402H26.5788V21.3034ZM26.5788 26.777H34.7892V29.5138H26.5788V26.777ZM26.5788 32.2507H34.7892V34.9875H26.5788V32.2507ZM21.1052 21.3034H23.842V24.0402H21.1052V21.3034ZM21.1052 26.777H23.842V29.5138H21.1052V26.777ZM21.1052 32.2507H23.842V34.9875H21.1052V32.2507Z" fill={color}/>
    </svg>
  );
};

export default ProductSelectionIcon;
