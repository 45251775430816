import { FC } from 'react';

type RoundCheckboxPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const RoundCheckbox: FC<RoundCheckboxPropTypes> = ({ width = 16, height = 16, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1990_14635)">
    <rect x="14" y="12" width="16" height="16" rx="8" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2 18.5602L22.2087 21.44V21.44L21.2115 22.4L20.2144 21.44L20.22 21.4347L18.8 20.0529L19.7974 19.0929L21.2171 20.4749L24.2029 17.6L25.2 18.5602Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_d_1990_14635" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1990_14635"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1990_14635" result="shape"/>
    </filter>
    </defs>
    </svg>
  );
};

export default RoundCheckbox;
