import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import {
  IProduct,
  ISupplier,
  ISearchResult,
  ICreateListPrice,
  IAddPiecework,
  CreateProductItem,
  IProductState,
} from '../ProductsInterfaces';
import { useQueryParams } from 'routeUtils';

import { addSuccessMessage, addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const LOAD_ALL_PRODUCTS = 'LOAD_ALL_PRODUCTS';
export const IS_PRODUCTS_LOADING = 'IS_PRODUCTS_LOADING';
export const IS_PRODUCTS_DIALOG_LOADING = 'IS_PRODUCTS_DIALOG_LOADING';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const HARD_DELETE_PRODUCT = 'HARD_DELETE_PRODUCT';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const LOAD_DELETED_PRODUCTS = 'LOAD_DELETED_PRODUCTS';
export const RESTORE_PRODUCT = 'RESTORE_PRODUCT';
export const LOAD_PRODUCT_SUPPLIERS = 'LOAD_PRODUCT_SUPPLIERS';
export const REMOVE_PIECEWORK_FROM_PRODUCT = 'REMOVE_PIECEWORK_FROM_PRODUCT';
export const SEARCH_FOR_PRODUCTS = 'SEARCH_FOR_PRODUCTS';
export const LOAD_LIST_OF_PRODUCTS = 'LOAD_LIST_OF_PRODUCTS';
export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';
export const SELECT_PRODUCT_FOR_REPLACEMENT = 'SELECT_PRODUCT_FOR_REPLACEMENT';
export const DESELECT_PRODUCT_FOR_REPLACEMENT = 'DESELECT_PRODUCT_FOR_REPLACEMENT';
export const RESET_PRODUCTS_STATE = 'RESET_PRODUCTS_STATE';
export const SELECT_REPLACEMENT_PRODUCT_FOR_DELETION = 'SELECT_REPLACEMENT_PRODUCT_FOR_DELETION';
export const DESELECT_REPLACEMENT_PRODUCT_FOR_DELETION = 'DESELECT_REPLACEMENT_PRODUCT_FOR_DELETION';
export const CLEAR_SELECTED_REPLACEMENT_PRODUCTS = 'CLEAR_SELECTED_REPLACEMENT_PRODUCTS';
export const CLEAR_SELECTED_REPLACEMENT_ITEMS_FOR_PRODUCT = 'CLEAR_SELECTED_REPLACEMENT_ITEMS_FOR_PRODUCT';

export const searchForProductsInSameCatalogSuccess = (hits: Array<IProduct>, totalHits: number) => ({
  type: SEARCH_FOR_PRODUCTS,
  payload: { hits: [...hits], totalHits: totalHits, overwrite: true },
});

export const resetProductsState = () => ({ type: RESET_PRODUCTS_STATE });
const setIsLoading = (isLoading: boolean) => ({ type: IS_PRODUCTS_LOADING, payload: { isLoading } });

const setIsDialogLoading = (isDialogLoading: boolean) => ({
  type: IS_PRODUCTS_DIALOG_LOADING,
  payload: { isDialogLoading },
});

const loadAllProductsSuccess = (
  payload: Array<IProduct>,
  pagingInfo: IParams,
  totalItems: number,
  overwrite: boolean
) => ({
  type: LOAD_ALL_PRODUCTS,
  payload: { items: [...payload], ...pagingInfo, totalItems, overwrite },
});

const deleteProductSuccess = (payload: IProduct, hardDelete: boolean) => ({
  payload,
  type: hardDelete ? HARD_DELETE_PRODUCT : DELETE_PRODUCT,
});

export const getProductByIdSuccess = (selectedItem: IProduct | null) => ({
  payload: { selectedItem },
  type: GET_PRODUCT_BY_ID,
});

export const setHasMoreItemsSuccess = (hasMoreItems: boolean) => ({
  type: SET_HAS_MORE_ITEMS,
  payload: { hasMoreItems },
});

export const loadDeletedProductsSuccess = (
  payload: Array<IProduct>,
  pagingInfo: IPageInfo,
  totalDeletedItems: number
) => ({
  payload: { deletedItems: [...payload], totalDeletedItems, ...pagingInfo },
  type: LOAD_DELETED_PRODUCTS,
});

export const loadProductSuppliersSuccess = (payload: Array<ISupplier>) => ({
  type: LOAD_PRODUCT_SUPPLIERS,
  payload: { suppliers: [...payload] },
});

export const restoreProductSuccess = (payload: IProduct) => ({
  payload,
  type: RESTORE_PRODUCT,
});

export const editProductSuccess = (selectedItem: IProduct) => ({
  payload: { selectedItem },
  type: EDIT_PRODUCT,
});

export const loadAllProducts = (catalogId: string, overwrite: boolean, pageParams?: IParams) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { pageSize: size = 20, pageNumber: number = 0 } = pageParams ? pageParams : useQueryParams();
      const countUrl = `/products/api/products/catalog/${catalogId}/count`;
      const url = `/products/api/products/catalog/${catalogId}?pageNumber=${number}&pageSize=${size}`;
      const countPromise = API.get(countUrl);
      const dataPromise = API.get(url);
      const {
        data: { payload, pageInfo },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      dispatch(loadAllProductsSuccess(payload, pageInfo, totalItems, overwrite));
      if (totalItems > size && overwrite) dispatch(setHasMoreItemsSuccess(true));
      else dispatch(setHasMoreItemsSuccess(false));
    } catch (error) {
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createProduct = (data: CreateProductItem, catalogId: string, callback = () => {}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const url = `/products/api/products/catalog/${catalogId}`;
      const response = await API.post(url, data);
      const message = i18n.t('products.successCreatingProduct');
      dispatch(addSuccessMessage({ message }));
      dispatch(loadAllProducts(catalogId, false));
      callback();
      return response.data.payload;
    } catch (error) {
      const message = i18n.t('products.errorCreatingProduct');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const deleteProduct = (id: string, hardDelete = false) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { selectedItem } = getState().productsCatalogsModule;
      const URL = `/products/api/products/${id}?hardDelete=${hardDelete}`;
      hardDelete ? dispatch(setIsDialogLoading(true)) : dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.delete(URL);
      dispatch(deleteProductSuccess(payload, hardDelete));
      const message = i18n.t('products.successDeletingProduct');
      dispatch(addSuccessMessage({ message }));
      dispatch(loadDeletedProducts({ catalogId: selectedItem.id }));
    } catch (error) {
      const message = i18n.t('products.errorDeletingProduct');
      dispatch(addErrorMessage({ message }));
    } finally {
      hardDelete ? dispatch(setIsDialogLoading(false)) : dispatch(setIsLoading(false));
    }
  };
};

export const getProductById = (id: string) => {
  return async (dispatch: Function) => {
    try {
      const URL = `/products/api/products/${id}`;
      const {
        data: { payload },
      } = await API.get(URL);

      dispatch(getProductByIdSuccess(payload));
    } catch (error) {
      const message = i18n.t('products.errorLoadingProduct');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const loadDeletedProducts = (params?: any) => {
  const { catalogId, pageNumber = 0, pageSize = 10 } = params;
  if (!catalogId) return { type: '' };
  const countURL = `/products/api/products/catalog/${catalogId}/deleted/count`;
  const URL = `/products/api/products/catalog/${catalogId}/deleted?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const countPromise = API.get(countURL);
      const dataPromise = API.get(URL);
      const {
        data: { payload, pageInfo },
      } = await dataPromise;
      const {
        data: { payload: totalDeletedItems },
      } = await countPromise;
      dispatch(loadDeletedProductsSuccess(payload, pageInfo, totalDeletedItems));
    } catch (error) {
      const message = i18n.t('products.errorLoadingDeletedProducts');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const restoreProduct = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/products/api/products/deleted/${id}`;
      const {
        data: { payload },
      } = await API.put(URL);
      dispatch(restoreProductSuccess(payload));
      const message = i18n.t('products.successRestoringDeletedProduct');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      const message = i18n.t('products.errorRestoringDeletedProducts');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const editProduct = (id: string, data: IProduct) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/products/${id}`;
      const {
        data: { payload },
      } = await API.put(URL, data);
      dispatch(editProductSuccess(payload));
      const message = i18n.t('products.successEditingProduct');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      const message = i18n.t('products.errorEditingProduct');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadProductSuppliers = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/products/${id}/suppliers`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadProductSuppliersSuccess(payload));
    } catch (error) {
      const message = i18n.t('products.errorLoadingProductSuppliers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addSupplierToProduct = (id: string, data: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/products/${id}/suppliers`;
      await API.post(URL, data);
      const message = i18n.t('products.successAddingSupplierToProduct');
      dispatch(addSuccessMessage({ message }));
      dispatch(getProductById(id));
    } catch (e) {
      const message = i18n.t('products.errorAddingSupplierToProduct');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addListPriceToSupplier = (productId: string, supplierId: string, data: ICreateListPrice) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/products/${productId}/suppliers/${supplierId}/ListPrices`;
      await API.post(URL, data);
      const message = i18n.t('globals.addedListPrice');
      dispatch(addSuccessMessage({ message }));
      dispatch(getProductById(productId));
    } catch (e) {
      const message = i18n.t('globals.errorAddingListPrice');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addPieceworkRateToProduct = (productId: string, data: IAddPiecework) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/products/${productId}/pieceWorkRates`;
      await API.post(URL, data);
      const message = i18n.t('globals.addedPiecework');
      dispatch(addSuccessMessage({ message }));
      dispatch(getProductById(productId));
    } catch (e) {
      const message = i18n.t('globals.errorAddingPiecework');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const removePieceworkFromProduct = (productId: string, pieceworkId: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/products/api/products/${productId}/pieceWorkRates/${pieceworkId}`;
      await API.delete(URL);
      const message = i18n.t('globals.removedPiecework');
      dispatch(addSuccessMessage({ message }));
      dispatch(getProductById(productId));
    } catch (e) {
      const message = i18n.t('globals.errorRemovingPiecework');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForProducts = (
  query: string,
  catalogId: string,
  params: IParams = { pageNumber: 0, pageSize: 15 },
  tags?: string[]
) => {
  const searchForProductsUrl = `search/api/products/autocomplete`;
  const fields = query?.length > 0 ? ['code', 'ean', 'description'] : null;
  const isOnlyNumbersSearch = query?.match(/^[0-9]+$/) ? true : false;
  const finalFieldsToSearch = isOnlyNumbersSearch ? ['code'] : fields;
  const searchFields = {
    query: query || null,
    ...params,
    fields: finalFieldsToSearch,
    tags,
    catalogIds: [catalogId],
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForProductsPromise = API.post(searchForProductsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForProductsPromise;

      dispatch(
        loadAllProductsSuccess(hits, { pageSize: params.pageSize, pageNumber: params.pageSize }, totalHits, true)
      );
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchProductByCode = (
  catalogId: string,
  searchString: string,
  pageNumber?: number,
  pageSize?: number
) => {
  const searchForProductsUrl = `search/api/products/autocomplete`;
  const searchFields = {
    query: searchString,
    ...{ pageNumber, pageSize },
    fields: ['code'],
    deleted: false,
    catalogIds: [catalogId],
    orderBy: 'code',
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForProductsPromise = API.post(searchForProductsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForProductsPromise;
      dispatch(
        loadAllProductsSuccess(hits, { pageSize: Number(pageSize), pageNumber: Number(pageNumber) }, totalHits, false)
      );
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchProductByDescription = (
  catalogId: string,
  searchString: string,
  pageNumber?: number,
  pageSize?: number
) => {
  const searchForProductsUrl = `search/api/products/autocomplete`;
  const searchFields = {
    query: searchString,
    ...{ pageNumber, pageSize },
    fields: ['description'],
    deleted: false,
    catalogIds: [catalogId],
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForProductsPromise = API.post(searchForProductsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForProductsPromise;
      dispatch(loadAllProductsSuccess(hits, { pageSize: 50, pageNumber: 0 }, totalHits, false));
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForTagsOnProducts = (
  tags: string,
  catalogId: string,
  params: IParams = { pageNumber: 0, pageSize: 50 }
) => {
  const searchForProductsUrl = `search/api/products/autocomplete`;
  const searchFields = {
    query: null,
    ...params,
    fields: null,
    deleted: false,
    catalogIds: [catalogId],
    tags: [tags],
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForProductsPromise = API.post(searchForProductsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForProductsPromise;
      dispatch(loadAllProductsSuccess(hits, { pageNumber: 0, pageSize: 50 }, totalHits, false));
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const selectProductsForReplacements = (product: IProduct) => {
  return async (dispatch: Function, getState: Function) => {
    const { selectedItemsForProductReplacements }: IProductState = getState().productsModule;
    dispatch({
      type: SELECT_PRODUCT_FOR_REPLACEMENT,
      payload: { selectedItemsForProductReplacements: [...selectedItemsForProductReplacements, product] },
    });
  };
};

export const deselectProductsForReplacements = (product: IProduct) => {
  return async (dispatch: Function, getState: Function) => {
    const { selectedItemsForProductReplacements }: IProductState = getState().productsModule;
    const items = selectedItemsForProductReplacements.filter((item) => item.id !== product.id);
    dispatch({
      type: DESELECT_PRODUCT_FOR_REPLACEMENT,
      payload: { selectedItemsForProductReplacements: items },
    });
  };
};

export const addProductsToProductReplacement = (productId: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedItemsForProductReplacements }: IProductState = getState().productsModule;
      const URL = `/products/api/products/${productId}/Replacement/multiple`;
      const replacements = selectedItemsForProductReplacements.map(({ id: productId }: IProduct) => ({ productId }));
      await API.put(URL, { replacements });
      dispatch(getProductById(productId));
      dispatch(clearSelectedReplacementProducts());
      const message = i18n.t('globals.changesWasSaved');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('products.errorAddingProductAsReplacement');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const clearSelectedReplacementProducts = () => ({
  type: CLEAR_SELECTED_REPLACEMENT_PRODUCTS,
  payload: { selectedItemsForProductReplacements: [] },
});

export const clearSelectedReplacementItemsForProduct = (productId?: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { selectedReplacementItemsForProduct }: IProductState = getState().productsModule;
    const newItems = !productId ? [] : selectedReplacementItemsForProduct.filter((item) => item.id !== productId);
    dispatch({
      type: CLEAR_SELECTED_REPLACEMENT_ITEMS_FOR_PRODUCT,
      payload: { selectedReplacementItemsForProduct: newItems },
    });
  };
};

export const selectReplacementProductForDeletion = (product: IProduct) => {
  return async (dispatch: Function, getState: Function) => {
    const { selectedReplacementItemsForProduct }: IProductState = getState().productsModule;
    dispatch({
      type: SELECT_REPLACEMENT_PRODUCT_FOR_DELETION,
      payload: { selectedReplacementItemsForProduct: [...selectedReplacementItemsForProduct, product] },
    });
  };
};

export const deselectReplacementProductForDeletion = (product: IProduct) => {
  return async (dispatch: Function, getState: Function) => {
    const { selectedReplacementItemsForProduct }: IProductState = getState().productsModule;
    const items = selectedReplacementItemsForProduct.filter((item) => item.id !== product.id);
    dispatch({
      type: DESELECT_REPLACEMENT_PRODUCT_FOR_DELETION,
      payload: { selectedReplacementItemsForProduct: items },
    });
  };
};

export const removeReplacementProduct = (replacementProductId: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedItem }: IProductState = getState().productsModule;
      const productId = selectedItem?.id ?? '';
      const URL = `/products/api/products/${productId}/Replacement/${replacementProductId}`;
      await API.delete(URL);
      dispatch(getProductById(productId));
      dispatch(clearSelectedReplacementItemsForProduct(replacementProductId));
      const message = i18n.t('globals.changesWasSaved');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('products.errorRemovingReplacementProduct');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const removeSelectedReplacementProducts = () => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedItem, selectedReplacementItemsForProduct: items }: IProductState = getState().productsModule;
      const productId = selectedItem?.id ?? '';
      let replacementProductId = '';
      items.forEach(({ id }, index) => {
        replacementProductId += `${index === 0 ? '' : '&'}replacementProductId=${id}`;
      });
      const URL = `/products/api/products/${productId}/Replacement/multiple?${replacementProductId}`;
      await API.delete(URL);
      dispatch(getProductById(productId));
      dispatch(clearSelectedReplacementItemsForProduct());
      const message = i18n.t('globals.changesWasSaved');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('products.errorRemovingReplacementProduct');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForProductsInSameCatalog = (query: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedItem }: IProductState = getState().productsModule;
      const { searchPageSize: pageSize, searchPageNumber: pageNumber } = useQueryParams();
      const URL = `/search/api/products/autocomplete`;
      const data = {
        query,
        pageSize,
        pageNumber,
        fields: ['code', 'description'],
        catalogIds: [selectedItem?.catalogId],
      };
      const {
        data: { hits, totalHits },
      } = await API.post(URL, data);
      dispatch(searchForProductsInSameCatalogSuccess(hits, totalHits));
    } catch (e) {
      const message = i18n.t('products.errorSearchingForProducts');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
