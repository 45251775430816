import { TableCell as MuiTableCell, TableCellProps, styled } from '@mui/material';

export const TableCellStyled = styled(MuiTableCell)(({ theme, size }) => ({
  ...(size === 'medium' && {
    paddingTop: '24px',
    paddingBottom: '24px',
    fontSize: '12px',
    lineHeight: '18px',
  }),
  ...(size === 'small' && {
    paddingTop: '12px',
    paddingBottom: '12px',
    fontSize: '14px',
    lineHeight: '20px',
  }),
  fontWeight: 400,
  // color: theme.palette.primary.main,
}));

export const TableData = (props: TableCellProps) => {
  const { children, size = 'medium', ...rest } = props;
  return (
    <TableCellStyled size={size} {...rest}>
      {children}
    </TableCellStyled>
  );
};

export default TableData;
