import { Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { useMemo } from 'react';

import { IconItem } from 'components/Icons';
import * as icons from 'components/Icons/index';

type TButtonProps = {
  icon?: keyof typeof icons | JSX.Element;
  children?: React.ReactNode;
  btn?: 'red-inline' | 'blue-inline';
} & MuiButtonProps;

const ButtonStyled = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'isAppDrawerOpen',
})`
  height: 100%;
  line-height: 20px;
  box-shadow: none;

  ${({ size }) => {
    if (size === 'large') {
      return `padding: 12px 16px; font-size: 16px;`;
    } else if (size === 'small') {
      return 'padding: 6px 12px; font-size: 13px;';
    }
    return 'padding: 8px 12px; font-size: 14px;';
  }}
  &:hover {
    box-shadow: none;
  }
`;

export const BlueButtonInline = styled(MuiButton)<any>`
  flex-basis: 50%;
  background: ${(props) => props.theme.palette.primary.lighter};
  color: ${(props) => props.theme.palette.primary.main};
  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.primary.contrastText};
    background: ${(props) => props.theme.palette.primary.main};
  }
`;

export const RedButtonInline = styled(MuiButton)<any>`
  flex-basis: 50%;
  color: ${(props) => props.theme.palette.primary.darkRed};
  background: ${(props) => props.theme.palette.error.lighter};

  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.error.contrastText};
    background: ${(props) => props.theme.palette.error.main};
  }
`;

export const Button = (props: TButtonProps) => {
  const { children, icon, btn, ...rest } = props;
  const Icon = useMemo(() => {
    if (typeof icon === 'string') return <IconItem name={icon} />;
    return icon;
  }, [icon]);
  if (btn === 'red-inline') {
    return (
      <RedButtonInline {...rest}>
        <>
          {Icon}
          {children}
        </>
      </RedButtonInline>
    );
  }
  if (btn === 'blue-inline') {
    return (
      <BlueButtonInline {...rest}>
        <>
          {Icon}
          {children}
        </>
      </BlueButtonInline>
    );
  }
  return (
    <ButtonStyled {...rest}>
      <>
        {Icon}
        {children}
      </>
    </ButtonStyled>
  );
};

export default Button;
