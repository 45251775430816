import API from 'utils/API';
import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import i18n from 'i18next';
import { IPiecework, ITradeResponse, ITrade, ICategory, ICategoryResponse, IPieceworksResponse, IPieceworkResponse, ISearchResult } from './PieceworkInterface';
import { AxiosResponse } from 'axios';
import { useQueryParams } from 'routeUtils';

export const LOAD_ALL_CATEGORIES = 'LOAD_ALL_CATEGORIES';
export const LOAD_ALL_TRADES = 'LOAD_ALL_TRADES';
export const LOAD_ALL_PIECEWORKS = 'LOAD_ALL_PIECEWORKS';
export const LOAD_ONE_PIECEWORK = 'LOAD_ONE_PIECEWORK';
export const IS_PIECEWORK_LOADING = 'IS_PIECEWORK_LOADING';
export const LOAD_LIST_OF_PIECEWORKS = 'LOAD_LIST_OF_PIECEWORKS';
export const LOAD_SEARCHED_PIECEWORKS = 'LOAD_SEARCHED_PIECEWORKS';
export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';
export const LOAD_TOTAL_PIECEWORK_COUNT = 'LOAD_TOTAL_PIECEWORK_COUNT';
export const SAVE_SEARCHED_PIECEWORKS = 'SAVE_SEARCHED_PIECEWORKS';

const loadPieceworksCategoriesSuccess = (payload: Array<ICategory>, pagingInfo: IPageInfo) => ({
    type: LOAD_ALL_CATEGORIES,
    payload,
});

export const setHasMoreItems = (hasMoreItems: boolean) => ({
  type: SET_HAS_MORE_ITEMS,
  hasMoreItems,
});

const loadTotalPieceworkCountSuccess = (payload: Array<IPiecework>) => ({
  type: LOAD_TOTAL_PIECEWORK_COUNT,
  payload,
});

const saveSearchedPieceworksSuccess = (payload: ISearchResult) => ({
  type: SAVE_SEARCHED_PIECEWORKS,
  payload,
});

const loadSearchedPieceworksSuccess = (payload: Array<IPiecework>, pagingInfo: IPageInfo) => ({
  type: LOAD_SEARCHED_PIECEWORKS,
  payload,
})

const loadPieceworkSuccess = (payload: IPiecework, pagingInfo: IPageInfo) => ({
  type: LOAD_ONE_PIECEWORK,
  payload,
});

const loadListOfPieceworksSuccess = (payload: Array<IPiecework>, pagingInfo: IPageInfo) => ({
  type: LOAD_LIST_OF_PIECEWORKS,
  payload,
})

const loadTradesSuccess = (payload: Array<ITrade>, pagingInfo: IPageInfo) => ({
  type: LOAD_ALL_TRADES,
  payload,
});

const setIsLoading = (payload: boolean) => ({ type: IS_PIECEWORK_LOADING, payload });

export const loadListOfPieceworks = (listOfPieceworkIds: Array<string>) => {

  const handleList = (listOfPieceworkIds: Array<string>) => {
    let queryString = "";
    if(listOfPieceworkIds.length === 0) return queryString;
    queryString = `?id=${listOfPieceworkIds[0]}`;
    if(listOfPieceworkIds.length > 1) {
      for(let i = 1; i < listOfPieceworkIds.length; i++) {
        const iteration = `&id=${listOfPieceworkIds[i]}`;
        queryString += iteration;
      };
    };
    return queryString;
  };

  return async (dispatch: Function, getState: Function) => {
    try {
      const url = `/piecework/api/pieceWorkRates/List${handleList(listOfPieceworkIds)}`;
      dispatch(setIsLoading(true));
      const {
        data: { payload, pagingInfo },
      }: AxiosResponse<IPieceworksResponse> = await API.get(url);
      dispatch(loadListOfPieceworksSuccess(payload, pagingInfo));
      if (payload?.length) {
        
        } else {
          
        }
      } catch (error) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadSearchedPieceworks = (categoryId: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { pageNumber, pageSize } = useQueryParams();
    try {
      const url = `/piecework/api/pieceWorkRates/Category/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countUrl = `/piecework/api/pieceWorkRates/Category/${categoryId}/Count`;
      dispatch(setIsLoading(true));
      const pieceworkCount = API.get(countUrl);
      const {
        data: { payload: totalPieceworks },
      } = await pieceworkCount;
      dispatch(loadTotalPieceworkCountSuccess(totalPieceworks));
      
      const {
        data: { payload, pagingInfo },
      }: AxiosResponse<IPieceworksResponse> = await API.get(url);      
      dispatch(loadSearchedPieceworksSuccess(payload, pagingInfo));

      if (payload.length === 0 || payload.length < Number(pageSize)) {
        Promise.reject();
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      } catch (error) {
      const message = i18n.t('piecework.errorGettingSearchedPieceworks');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadPiecework = (pieceworkRateId: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const url = `/piecework/api/pieceWorkRates/${pieceworkRateId}`;
      dispatch(setIsLoading(true));
      const {
        data: { payload, pagingInfo },
      }: AxiosResponse<IPieceworkResponse> = await API.get(url);
      dispatch(loadPieceworkSuccess(payload, pagingInfo));
      if (payload) {
        
        } else {
          
        }
      } catch (error) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadPieceworksCategories = (tradeId: string) => {
    return async (dispatch: Function, getState: Function) => {
      try {
        const url = `/piecework/api/pieceWorkCategories/trade/${tradeId}`;
        dispatch(setIsLoading(true));
        const {
          data: { payload, pagingInfo },
        }: AxiosResponse<ICategoryResponse> = await API.get(url);
        dispatch(loadPieceworksCategoriesSuccess(payload, pagingInfo));
        if (payload?.length) {
          
          } else {
            
          }
        } catch (error) {
        const message = i18n.t('products.errorLoadingCatalogs');
        dispatch(addErrorMessage({ message }));
      } finally {
        dispatch(setIsLoading(false));
      }
    };
};

export const loadTrades = () => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const url = '/piecework/api/Trades';
      dispatch(setIsLoading(true));
      const {
        data: { payload, pagingInfo },
      }: AxiosResponse<ITradeResponse> = await API.get(url);
      dispatch(loadTradesSuccess(payload, pagingInfo));
      if (payload?.length) {
        
        } else {
          
        }
      } catch (error) {
      const message = i18n.t('products.errorLoadingCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForPieceworks = (query: string, categoryId: string, saveInSearched: boolean = false, params: IParams) => {
  const pageSize = params?.pageSize || 12;
  const searchForPieceworksUrl = `search/api/pieceworkrates/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: ['code', 'description'],
    categoryIds: [categoryId],
  };
  return async (dispatch: Function) => {
    try {
      const searchForPieceworkPromise = API.post(searchForPieceworksUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForPieceworkPromise;
      if (totalHits === 0 || totalHits < Number(pageSize)) {
        Promise.reject();
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));

      if (saveInSearched) dispatch(saveSearchedPieceworksSuccess({hits, totalHits}));
    } catch (error) {
      const message = i18n.t('offers.errorSearchingForOffer');
      dispatch(addErrorMessage({ message }));
    } finally {

    }
  };
};