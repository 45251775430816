import { FC } from 'react';

type IndicatorRightSMPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const IndicatorRightSM: FC<IndicatorRightSMPropTypes> = ({
  width = 20,
  height = 20,
  color = '#0774A4',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#0774A4" />
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M7.32961 13.138L10.4656 9.974L7.32961 6.824H9.58361L12.7196 9.974L9.58361 13.138H7.32961Z"
        fill="white"
      />
    </svg>
  );
};

export default IndicatorRightSM;
