import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type PhotoIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const PhotoIcon: FC<PhotoIconPropTypes> = ({
  width = 54,
  height = 54,
  color = '#0774A4',
  bgColor = '#F4F7FE',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="52.8417" height="52.8417" rx="12" fill={resolvedColor} />
      <path
        d="M39.2367 35.9997V16.842C39.2367 15.3368 38.0052 14.1052 36.4999 14.1052H17.3423C15.837 14.1052 14.6055 15.3368 14.6055 16.842V35.9997C14.6055 37.5049 15.837 38.7365 17.3423 38.7365H36.4999C38.0052 38.7365 39.2367 37.5049 39.2367 35.9997ZM22.1317 28.4735L25.5527 32.5923L30.3421 26.4208L36.4999 34.6313H17.3423L22.1317 28.4735Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default PhotoIcon;
