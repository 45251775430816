import React, { Component, ErrorInfo, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ErrorView from 'views/mainViews/navigation/ErrorView';
import { Location } from 'react-router-dom';

type ErrorBoundaryProps = {
    children: React.ReactNode;
    fallback?: ReactNode;
    location?: Location;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

type ErrorBoundaryPropsWithTranslation = ErrorBoundaryProps & WithTranslation;

  class ErrorBoundary extends Component<ErrorBoundaryPropsWithTranslation, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryPropsWithTranslation) {
      super(props);
      this.state = { hasError: false };
    }

    /** Update state so the next render will show the fallback component. */
    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    /** Function where you can log the errors */
    componentDidCatch(error: Error, info: ErrorInfo) {}

    componentDidUpdate(prevProps: ErrorBoundaryPropsWithTranslation) {
        if (this.props.location && this.props.location !== prevProps.location) {
          this.setState({ hasError: false }); // Reset the state if the location has changed after the error
        }
      }

    render() {
        const { children, fallback } = this.props;

        if (!this.state.hasError) { return children; }
        if (fallback) return fallback;
        return <ErrorView/>;
    }
}

const ErrorBoundaryWithTranslation = withTranslation()(ErrorBoundary) as React.ComponentType<ErrorBoundaryProps>;

export default ErrorBoundaryWithTranslation;
