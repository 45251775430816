import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type SendFilledCircleIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const SendFilledCircleIcon: FC<SendFilledCircleIconPropTypes> = ({
  width = 56,
  height = 56,
  color = '#0975A5',
  bgColor = '#F8F8F8',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  const resolvedBgColor = resolveThemeColor(bgColor, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="28" cy="28" r="28" fill={resolvedBgColor} />
      <path
        d="M14.2783 40.4613L43.0011 28.1457L14.2783 15.8301L14.2646 25.4089L34.7907 28.1457L14.2646 30.8825L14.2783 40.4613Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default SendFilledCircleIcon;
