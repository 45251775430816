import { useTheme } from '@mui/material/styles';
import { useState, FC } from 'react';

import * as icons from './index';

import { resolveThemeColor } from 'utils/helpers';

type IconProps = {
  name: keyof typeof icons;
  width?: number;
  height?: number;
  onClick?: any;
  isDisabled?: boolean;
  activeColor?: string;
  defaultColor?: string;
  className?: string;
  isHover?: boolean;
  bgcolor?: string;
  styles?: any;
  [x: string]: any;
};

const IconItem: FC<IconProps> = ({
  name,
  onClick,
  activeColor = '#727991',
  width,
  height,
  defaultColor = '#0975A5',
  className,
  isHover = false,
  isDisabled = false,
  bgcolor: backgroundColor,
  ...rest
}) => {
  const theme = useTheme();

  const Icon = name ? icons[name] : () => null;
  const [isHovered, setIsHovered] = useState(false);

  const resolvedActiveColor = resolveThemeColor(activeColor, theme);
  const resolvedDefaultColor = resolveThemeColor(defaultColor, theme);

  return (
    <div
      data-testid={name}
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: isHover && !isDisabled ? 'pointer' : 'auto',
        opacity: isDisabled ? 0.5 : 1,
        ...rest.styles,
      }}
      onClick={!isDisabled ? onClick : () => {}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Icon
        width={width}
        height={height}
        name={name}
        color={isHover && isHovered ? resolvedActiveColor : resolvedDefaultColor}
        bgcolor={backgroundColor}
      />
    </div>
  );
};

export default IconItem;
