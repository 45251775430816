import { FC } from 'react';

type SquarePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Square: FC<SquarePropTypes> = ({ width = 48, height = 48, color = 'none', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11.2C0 5.01441 5.01441 0 11.2 0H36.8C42.9856 0 48 5.01441 48 11.2V36.8C48 42.9856 42.9856 48 36.8 48H11.2C5.01441 48 0 42.9856 0 36.8V11.2Z" fill={color}/>
    </svg>
  );
};

export default Square;
