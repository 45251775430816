import { useState, useId } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { InputValue, InfoProps } from './types';

import { TextField } from 'components/StyledMUI';

/**
 *
 * @param name
 * @returns an object which than can be used to populate the textfields of fieldOne and fieldTwo
 * @purpose to avoid re-writting objects, we can use this function since all the object's properties(keys) are the same
 */
export const fillInfoFields = (name: string) => {
  return { name, placeholder: name, label: name };
};

const InfoSaver = ({ fieldOne, fieldTwo, handleInfo }: InfoProps) => {
  const [inputValue, setInputValue] = useState<InputValue>({ fieldOneValue: '', fieldTwoValue: '' });
  const [savedInfo, setSavedInfo] = useState<InputValue[]>([]);

  const infoId = useId();

  const handleFieldOne = (e: any) => {
    setInputValue((prev) => ({ ...prev, fieldOneValue: e.target.value }));
  };
  const handleFieldTwo = (e: any) => {
    setInputValue((prev) => ({ ...prev, fieldTwoValue: e.target.value }));
  };

  const handleAddInfo = () => {
    setSavedInfo((prev) => [...prev, inputValue]);
    setInputValue({ fieldOneValue: '', fieldTwoValue: '', id: infoId });
    typeof handleInfo === 'function' && handleInfo(inputValue);
  };

  const handleDeleteInfo = (index: number) => {
    const newSavedInfo = [...savedInfo];
    newSavedInfo.splice(index, 1);
    setSavedInfo(newSavedInfo);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginY="20px">
      <Box marginY={2} width="100%">
        {savedInfo.map((info, index) => {
          return (
            <Box
              key={info.fieldOneValue}
              border="1px dotted black"
              width="100%"
              marginY="5px"
              paddingX="10px"
              paddingY="5px">
              <Grid spacing={2} container display="flex" alignItems="center">
                <Grid item xs={5}>
                  <Box display="flex" alignItems="center">
                    <Typography color="#646464">{fieldOne.name}: </Typography>
                    <Typography color="#333333" fontWeight="bold">
                      {info.fieldOneValue}{' '}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <Typography color="#646464">{fieldTwo.name}: </Typography>
                    <Typography>{info.fieldTwoValue} </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="flex-end" onClick={() => handleDeleteInfo(index)}>
                  <ClearIcon />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            onChange={(e) => handleFieldOne(e)}
            size="medium"
            fullWidth
            placeholder={fieldOne.placeholder}
            value={inputValue.fieldOneValue}
            name={fieldOne.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(e) => handleFieldTwo(e)}
            size="medium"
            fullWidth
            placeholder={fieldTwo.placeholder}
            value={inputValue.fieldTwoValue}
            name={fieldTwo.name}
          />
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="flex-end">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={handleAddInfo}
            sx={{ height: '100%', backgroundColor: '#fff', width: 56, border: '1px solid #3333', borderRadius: '4px' }}>
            <AddIcon />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoSaver;
