import { Fetcher } from 'model/utils/Fetcher';

const varsSource = window['appValues'] ? window['appValues'] : process.env;

export async function login(body: string) {
  const response = await Fetcher.postLogin(varsSource.REACT_APP_BACKEND_API_HOST + '/auth', body);
  return response;
}

export async function codeLogin(code: string) {
  const response = await Fetcher.getCodeLogin(varsSource.REACT_APP_BACKEND_API_HOST + '/auth/code?code=' + code);
  return response;
}

export async function refresh(newTenantId?: string, newRoleType?: string) {
  const tenant = newTenantId !== undefined ? 'newTenantId=' + newTenantId : '';
  const role = newRoleType !== undefined ? 'newRoleType=' + newRoleType : '';
  const bothNotUndefined = newTenantId !== undefined && newRoleType !== undefined ? '&' : '';
  const query = '?' + tenant + bothNotUndefined + role;
  const response = await Fetcher.getRefresh(varsSource.REACT_APP_BACKEND_API_HOST + '/auth/refresh' + query);
  return response;
}

export async function logout() {
  const response = await Fetcher.getRefresh(varsSource.REACT_APP_BACKEND_API_HOST + '/auth/logout');
  return response;
}
