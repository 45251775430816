import { ReactElement, useContext } from 'react';
import { Box } from '@mui/material';
import DesktopDrawer from './DesktopDrawer';
import NavigationLinks from './NavigationLinks';
import { useBreakPoints } from 'theme/index';
import { useModuleList } from 'modules/common';
import Logo from './Logo';
import UserPanel from './UserPanel';
import UserSwitchDialog, { switchAccountDialog } from 'modules/User/UserSwitchDialog';
import { AppContext, IAppContext } from 'AppProvider';

const Layout = (): ReactElement => {
  const { showDialog } = useModuleList();
  const { isMobileView } = useBreakPoints();
  const { isAppDrawerOpen = false, drawerWidth = 0 } = useContext(AppContext) as IAppContext;

  return (
    <>
      {!isMobileView && (
        <DesktopDrawer open={isAppDrawerOpen} drawerWidth={drawerWidth} variant="permanent">
          <Box
            display="flex"
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
            borderRight="1px solid #ECEEF5">
            <Box display="flex" flexDirection="column">
              <Logo hideText={!isAppDrawerOpen} />
              <NavigationLinks isAppDrawerOpen={isAppDrawerOpen} />
            </Box>
            <UserPanel isOpen={isAppDrawerOpen} />
          </Box>
        </DesktopDrawer>
      )}
      {showDialog === switchAccountDialog && <UserSwitchDialog />}
    </>
  );
};

export default Layout;
