import { useAppSelector, useAppDispatch } from 'redux/hooks';

import { useTheme } from '@mui/material/styles';
import { useState, useLayoutEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation, Link, useSearchParams } from 'react-router-dom';

import { useQueryParams, useRouteUtils } from 'routeUtils';

export const useModuleList = (params?: any) => {
  const { items = [] } = params ?? {};
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const { openDialog, closeDialog, setTab, setDialogTab, resetPageNumber, resetSearchPageNumber, setQueryParam } =
    useRouteUtils();
  const { showDialog } = useQueryParams();
  const { isLoading, isDialogLoading } = useAppSelector((state) => state);
  const {
    pageSize = 10,
    pageNumber = 0,
    dialogPageSize = 10,
    dialogPageNumber = 0,
    searchPageSize = 10,
    searchPageNumber = 0,
    tab,
    dialogTab,
  } = useQueryParams();
  const [tableHeight, setTableHeight] = useState(0);
  const onTabChange = (event: SyntheticEvent, value: number) => setTab(value);
  const onDialogTabChange = (event: SyntheticEvent, value: number) => setDialogTab(value);
  const [isOpen, setIsOpen] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);

  useLayoutEffect(() => {
    if (!items.length) setTableHeight(0);
    const pageHeader = document.getElementById('page-header');
    const pageFooter = document.getElementById('page-footer');
    const windowHeight = window.innerHeight;
    const headerHeight = pageHeader?.offsetHeight ?? 60;
    const footerHeight = pageFooter?.offsetHeight ?? 68;
    setTableHeight(windowHeight - headerHeight - footerHeight);
  }, [items.length]);

  const prevRoute = () => navigate(-1);

  return {
    pageSize,
    pageNumber,
    dialogPageSize,
    dialogPageNumber,
    isLoading,
    isDialogLoading,
    tableHeight,
    dispatch,
    useAppSelector,
    t,
    openDialog,
    showDialog,
    navigate,
    useParams,
    location,
    Link,
    onTabChange,
    onDialogTabChange,
    isOpen,
    setIsOpen,
    restoreDialog,
    setRestoreDialog,
    closeDialog,
    tab: Number(tab),
    dialogTab: Number(dialogTab),
    setTab,
    setDialogTab,
    prevRoute,
    resetPageNumber,
    searchPageNumber,
    searchPageSize,
    resetSearchPageNumber,
    setQueryParam,
    useSearchParams,
    theme,
  };
};

export default useModuleList;
