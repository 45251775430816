import {
  IS_CATALOG_LOADING_IN_PACKAGES_REGISTER,
  LOAD_ALL_CATALOGS_IN_PACKAGES_REGISTER,
  LOAD_PACKAGES_IN_PACKAGES_REGISTER,
  LOAD_SINGLE_PACKAGE_IN_PACKAGES_REGISTER,
  SAVE_SEARCHED_PACKAGES_IN_PACKAGES_REGISTER,
  SAVE_TOTAL_PACKAGES_IN_PACKAGES_REGISTER,
  SET_HAS_MORE_ITEMS_IN_PACKAGES_REGISTER,
  RESET_PACKAGE_STATE_MODULE,
} from './packagesActions';
import { IPackageAction, IPackageState } from './types';

const defaultState: IPackageState = {
  packages: [],
  catalogs: [],
  selectedItem: null,
  totalItems: 0,
  isLoading: false,
  hasMoreItems: false,
  isDialogLoading: false,
  deletedItems: [],
};

const packages = (state = defaultState, { type, payload, overwrite }: IPackageAction) => {
  const resetState = () => defaultState;

  const stateActions = {
    [IS_CATALOG_LOADING_IN_PACKAGES_REGISTER]: () => ({ ...state, ...payload }),
    [SAVE_SEARCHED_PACKAGES_IN_PACKAGES_REGISTER]: () => ({ ...state, ...payload }),
    [SAVE_TOTAL_PACKAGES_IN_PACKAGES_REGISTER]: () => ({ ...state, totalItems: payload }),
    [LOAD_PACKAGES_IN_PACKAGES_REGISTER]: () => ({ ...state, packages: payload }),
    [LOAD_ALL_CATALOGS_IN_PACKAGES_REGISTER]: () => ({ ...state, catalogs: payload }),
    [SET_HAS_MORE_ITEMS_IN_PACKAGES_REGISTER]: () => ({ ...state, hasMoreItems: payload }),
    [LOAD_SINGLE_PACKAGE_IN_PACKAGES_REGISTER]: () => ({ ...state, selectedItem: payload }),
    [RESET_PACKAGE_STATE_MODULE]: resetState,
  };

  const currentState = stateActions[type];
  if (!currentState) {
    return state;
  }
  return currentState();
};

export default packages;
