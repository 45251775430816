import { FC } from 'react';

type IndicatorLeftSMPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const IndicatorLeftSM: FC<IndicatorLeftSMPropTypes> = ({
  width = 20,
  height = 20,
  color = '#0774A4',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M10.3956 13.138L7.27361 9.974L10.3956 6.824H12.6636L9.51361 9.974L12.6636 13.138H10.3956Z"
        fill="white"
      />
    </svg>
  );
};

export default IndicatorLeftSM;
