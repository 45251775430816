import { useState, useEffect, SyntheticEvent, FC, ReactElement } from 'react';
import { FormControl, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MuiAutoCompletedStyled } from './NewAutoComplete.styled';
import { NewTextField as TextField } from 'components/StyledMUI';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
  value?: string;
  name?: string;
  label: string;
  onBlur?: Function;
  options: Array<any>;
  onChange?: Function;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  optionKeyValue: string;
  optionKeyLabel: string;
  size?: 'small' | 'medium';
  loading: boolean;
  hasBorder?: boolean;
}

let counter = 0;

export const NewAutoComplete: FC<any> = (props: IProps): ReactElement => {
  const {
    fullWidth,
    margin,
    options = [],
    value,
    onChange = () => {},
    onBlur = () => {},
    name,
    label,
    placeholder,
    error,
    helperText,
    optionKeyValue,
    optionKeyLabel,
    size = 'medium',
    loading = false,
    hasBorder = true,
    disabled = false,
    ...rest
  } = props;

  const [objectValue, setObjectValue] = useState(options.find((item) => item[optionKeyValue] === value) ?? null);

  useEffect(() => {
    // In case we need to return the whole object, we won't pass the optionKeyValue property and this function
    // will set the whole object as the value. (Example AddnewOfferDialog with customer add. We need id AND customerType)
    if (optionKeyValue) setObjectValue(() => options.find((item) => item[optionKeyValue] === value) ?? null);
    else setObjectValue(() => options.find((item) => item === value) ?? null);
  }, [options, optionKeyValue, value]);

  const handleChange = (e: SyntheticEvent<Element, Event>, option: any) => {
    if (option) {
      // In case we need to return the whole object, we won't pass the optionKeyValue property and this function
      // will set the whole object as the value. (Example AddnewOfferDialog with customer add. We need id AND customerType)
      onChange({ target: { name, value: optionKeyValue ? option[optionKeyValue] : option } });
      setObjectValue({ ...option });
    } else {
      onChange({ target: { name, value: '' } });
    }
  };

  const handleBlur = (e: SyntheticEvent<Element, Event>) => {
    const target = e.target as HTMLInputElement;
    if (e.type === 'blur') {
      onBlur({ target: { name, value: target?.value } });
    }
  };

  const getOptionLabel = (option: any) => {
    let value = '';
    if (Array.isArray(optionKeyLabel)) {
      optionKeyLabel.forEach((item) => {
        value = value + option[item] + ' ';
      });
      value = value.trim();
    } else {
      value = option[optionKeyLabel] || '';
    }
    return value;
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <MuiAutoCompletedStyled
        popupIcon={<KeyboardArrowDownIcon sx={{ color: 'rgb(204, 204, 204)' }} />}
        // forcePopupIcon
        hasBorder
        disabled={disabled}
        error={error}
        fullWidth
        autoComplete
        clearOnEscape
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        value={objectValue}
        isOptionEqualToValue={(option) => option[optionKeyValue] === objectValue[optionKeyValue]}
        getOptionLabel={getOptionLabel}
        renderOption={(props = {}, option, state) => {
          // @ts-ignore
          props.key = option.id
            ? option.id
            : !Array.isArray(optionKeyLabel)
            ? `${optionKeyLabel}-${counter++}`
            : `key-${counter++}`;
          return (
            <Box component="li" {...props}>
              {getOptionLabel(option) ?? ''}
            </Box>
          );
        }}
        renderInput={(params) => {
          // @ts-ignore
          params.size = size;
          return (
            <TextField
              {...params}
              hasBorder={hasBorder}
              placeholder={placeholder}
              disabled={loading || disabled}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                startAdornment: <>{loading ? <CircularProgress size={20} color="secondary" /> : null}</>,
              }}
            />
          );
        }}
        {...rest}
      />
    </FormControl>
  );
};

export default NewAutoComplete;
