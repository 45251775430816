import { FC } from 'react';

type MaterialsPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Materials: FC<MaterialsPropTypes> = ({ width = 60, height = 60, color = "#046B99", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 14C0.5 6.26801 6.76801 0 14.5 0H46.5C54.232 0 60.5 6.26801 60.5 14V46C60.5 53.732 54.232 60 46.5 60H14.5C6.76801 60 0.5 53.732 0.5 46V14Z" fill="#E0EDF2"/>
    <path d="M21.75 31.875C18.725 31.875 16.25 34.35 16.25 37.375C16.25 40.4 18.725 42.875 21.75 42.875C24.775 42.875 27.25 40.4 27.25 37.375C27.25 34.35 24.775 31.875 21.75 31.875ZM30 18.125C26.975 18.125 24.5 20.6 24.5 23.625C24.5 26.65 26.975 29.125 30 29.125C33.025 29.125 35.5 26.65 35.5 23.625C35.5 20.6 33.025 18.125 30 18.125ZM38.25 31.875C35.225 31.875 32.75 34.35 32.75 37.375C32.75 40.4 35.225 42.875 38.25 42.875C41.275 42.875 43.75 40.4 43.75 37.375C43.75 34.35 41.275 31.875 38.25 31.875Z" fill={color}/>
    </svg>
  );
};

export default Materials;
