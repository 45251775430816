import { OFFERS_ACTIONS } from './types';

import { IOfferItem, IOffersState } from '../offersInterfaces';
import {
  SET_OFFERS_LOADER,
  SET_DIALOG_LOADER,
  SET_OFFER_ITEM_LOADER,
  LOAD_ALL_OFFERS_SUCCESS,
  ADD_ERROR_MESSAGE,
  SAVE_DELETED_OFFERS,
  SAVE_SEARCHED_OFFERS,
  SAVE_STD_PARAMETERS,
  SAVE_OFFER_ITEM,
  SAVE_OFFERS_TOTAL,
  SAVE_OFFERS_COUNT,
  SET_HAS_MORE_ITEMS,
  SAVE_FILTER_OFFERS_TAGS,
  SAVE_OFFER_RESPONSIBLE_PERSON,
  SAVE_SEARCHED_USERS,
  SET_ACTIVE_VERSION_ID,
  SAVE_OFFER_VERSIONS,
  SAVE_OFFER_CATEGORIES,
  SAVE_OFFER_CHOSEN_FILTERS,
  DELETE_OFFER_ITEM,
  ADD_NEW_OFFER,
  RESET_OFFERS_STATE,
} from './offersActions';

const defaultState: IOffersState = {
  error: null,
  isItemsLoading: false,
  isDialogLoading: false,
  isItemLoading: false,
  items: [],
  totalItemsNumber: 0,
  selectedItem: null,
  deletedItems: null,
  totalDeletedItemNumber: 0,
  searchedItems: null,
  createdItem: null,
  stdParameters: null,
  offersTotal: null,
  hasMoreItems: true,
  tagFilters: [],
  categories: [],
  offerResponsiblePerson: null,
  usersList: null,
  activeOfferVersionId: '',
  chosenFilters: null,
  buildingElementsId: null,
  projectNumber: null,
};

const offersReducer = (state = defaultState, action: OFFERS_ACTIONS): IOffersState => {
  switch (action.type) {
    case SET_OFFERS_LOADER:
      const newState = { ...state, isItemsLoading: action.isLoading };
      return newState;
    case SET_OFFER_ITEM_LOADER:
      return { ...state, isItemLoading: action.isLoading };
    case SET_DIALOG_LOADER:
      return { ...state, isDialogLoading: action.isDialogLoading };
    case LOAD_ALL_OFFERS_SUCCESS:
      if (action.overWrite)
        return { ...state, items: action.allOffers, isItemsLoading: false, totalItemsNumber: action.totalOffersNumber };
      return {
        ...state,
        items: [...state.items, ...action.allOffers],
        isItemsLoading: false,
        totalItemsNumber: action.totalOffersNumber,
      };
    case SAVE_OFFERS_COUNT:
      return { ...state, totalItemsNumber: action.totalOffersNumber };
    case ADD_ERROR_MESSAGE:
      return {
        ...state,
        isItemsLoading: false,
        error: action.error,
      };
    case SAVE_STD_PARAMETERS:
      return { ...state, stdParameters: action.stdParameters };
    case SAVE_OFFER_ITEM:
      return { ...state, selectedItem: action.offerItem };
    case SAVE_OFFERS_TOTAL:
      return { ...state, offersTotal: { [action.offerVersionId]: action.offersTotal } };
    case SAVE_DELETED_OFFERS:
      return { ...state, deletedItems: action.deletedOffers, totalDeletedItemNumber: action.totalDeletedOffersNumber };
    case SAVE_SEARCHED_OFFERS:
      if (action.searchResult?.totalHits || action.searchResult?.totalHits === 0) {
        if (!action.overWrite) {
          return {
            ...state,
            searchedItems: [...(state.searchedItems || []), ...action.searchResult.hits],
            totalItemsNumber: action.searchResult?.totalHits,
          };
        } else {
          return {
            ...state,
            searchedItems: action.searchResult.hits,
            totalItemsNumber: action.searchResult?.totalHits,
          };
        }
      }
      return { ...state, searchedItems: action.searchResult?.hits || null };
    case SET_HAS_MORE_ITEMS:
      return { ...state, hasMoreItems: action.hasMoreItems };
    case SAVE_FILTER_OFFERS_TAGS:
      return { ...state, tagFilters: action.tags };
    case SAVE_OFFER_CATEGORIES:
      return { ...state, categories: action.categories };
    case SAVE_OFFER_RESPONSIBLE_PERSON:
      return { ...state, offerResponsiblePerson: action.offerResponsiblePerson };
    case SAVE_SEARCHED_USERS:
      return { ...state, usersList: action.users };
    case SET_ACTIVE_VERSION_ID:
      return {
        ...state,
        selectedItem: { ...state.selectedItem, defaultVersionId: action.activeVersionId } as IOfferItem,
      };
    case SAVE_OFFER_VERSIONS:
      return { ...state, selectedItem: { ...state.selectedItem, versions: action.offerVersions } as IOfferItem };
    case SAVE_OFFER_CHOSEN_FILTERS:
      return { ...state, chosenFilters: action.chosenFilters };
    case DELETE_OFFER_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.offerId),
      };
    case ADD_NEW_OFFER:
      return {
        ...state,
        items: [action.offerItem, ...state.items],
      };
    case RESET_OFFERS_STATE:
      return defaultState;
    default:
      return state;
  }
};

export default offersReducer;
