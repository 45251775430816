import { FC } from 'react';

type DropDownPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const DropDown: FC<DropDownPropTypes> = ({ width = 10, height = 6, color = '#505565', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.58398 5.53918L5.02703 5.11602L9.00977 1.29259L8.12366 0.371583L4.58386 3.7717L1.04406 0.371583L0.157959 1.29259L4.1407 5.11602L4.58398 5.53918Z"
        fill={color}
      />
    </svg>
  );
};

export default DropDown;
