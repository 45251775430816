import { FC } from 'react';

type GroupWorkPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const GroupWork: FC<GroupWorkPropTypes> = ({ width = 16, height = 16, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00004 1.33325C4.32004 1.33325 1.33337 4.31992 1.33337 7.99992C1.33337 11.6799 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.6799 
        14.6667 7.99992C14.6667 4.31992 11.68 1.33325 8.00004 1.33325ZM5.33337 11.6666C4.41337 11.6666 3.66671 10.9199 3.66671 9.99992C3.66671 9.07992 4.41337 
        8.33325 5.33337 8.33325C6.25337 8.33325 7.00004 9.07992 7.00004 9.99992C7.00004 10.9199 6.25337 11.6666 5.33337 11.6666ZM6.33337 5.33325C6.33337 4.41325 
        7.08004 3.66659 8.00004 3.66659C8.92004 3.66659 9.66671 4.41325 9.66671 5.33325C9.66671 6.25325 8.92004 6.99992 8.00004 6.99992C7.08004 6.99992 6.33337 
        6.25325 6.33337 5.33325ZM10.6667 11.6666C9.74671 11.6666 9.00004 10.9199 9.00004 9.99992C9.00004 9.07992 9.74671 8.33325 10.6667 8.33325C11.5867 8.33325 
        12.3334 9.07992 12.3334 9.99992C12.3334 10.9199 11.5867 11.6666 10.6667 11.6666Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupWork;
