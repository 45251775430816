import { useState } from 'react';

import useIsomorphicLayoutEffect from './useIsomophicLayoutEffect';
import useEventListener from './useEventListener';

interface WindowSize {
  scrollY: number;
}

function useWindowOffset(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    scrollY: 0,
  });

  const handleSize = () => {
    setWindowSize({
      scrollY: window.scrollY,
    });
  };

  useEventListener('scroll', handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
}

export default useWindowOffset;
