import i18n from 'i18next';
import { AxiosResponse } from 'axios';

import API from 'utils/API';
import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import {
  IIndustryItem,
  IIndustryLoadResponse,
  IAdjustmentItem,
  IAdjustmentListLoadResponse,
  IAdjustmentItemLoadResponse,
  ISearchResult,
} from 'modules/CollectiveAdjustments/adjustmentInterfaces';
import {
  SET_INDUSTRY_LIST_ACTION,
  SET_LOADING_ACTION,
  ADD_ERROR_MESSAGE_ACTION,
  SET_ADJUSTMENT_LIST_ACTION,
  CHANGE_INDUSTRY_ACTION,
  SAVE_INDUSTRY_ITEM_ACTION,
} from './types';

export const SET_LOADING = 'SET_LOADING';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const SET_INDUSTRY_LIST = 'SET_INDUSTRY_LIST';
export const SET_ADJUSTMENT_LIST = 'SET_ADJUSTMENT_LIST';
export const CHANGE_INDUSTRY = 'CHANGE_INDUSTRY';
export const SAVE_INDUSTRY_ITEM = 'SAVE_INDUSTRY_ITEM';

const setIsLoading = (isLoading: boolean): SET_LOADING_ACTION => ({ type: SET_LOADING, isLoading });

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const setIndustryListAction = (allIndustries: IIndustryItem[]): SET_INDUSTRY_LIST_ACTION => ({
  type: SET_INDUSTRY_LIST,
  allIndustries,
});

export const changeIndustryAction = (industryId: string): CHANGE_INDUSTRY_ACTION => ({
  type: CHANGE_INDUSTRY,
  industryId,
});

export const saveSelectedAdjustmentAction = (adjustment: IAdjustmentItem): SAVE_INDUSTRY_ITEM_ACTION => ({
  type: SAVE_INDUSTRY_ITEM,
  adjustment,
});

export const setAdjustmentListAction = (
  allAdjustments: IAdjustmentItem[],
  totalAdjustmentsNumber: number
): SET_ADJUSTMENT_LIST_ACTION => ({
  type: SET_ADJUSTMENT_LIST,
  allAdjustments,
  totalAdjustmentsNumber,
});

export const loadIndustryList = (regionCode: string = 'NO') => {
  const allIndustriesUrl = `/piecework/api/Trades/RegionCode/${regionCode}`;
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const allIndustries = API.get(allIndustriesUrl);
      const {
        data: { payload: industries },
      }: AxiosResponse<IIndustryLoadResponse> = await allIndustries;
      dispatch(setIndustryListAction(industries));
    } catch (error) {
      const message = i18n.t('adjustments.failedToGetIndustries');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadAdjustmentList = (tradeId: string = 'NO', params: IParams = { pageNumber: 0, pageSize: 15 }) => {
  const allIndustriesUrl = `/piecework/api/CollectiveAdjustments/Trade/${tradeId}?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`;
  const allIndustriesCountUrl = `/piecework/api/CollectiveAdjustments/Trade/${tradeId}/count`;
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const countPromise = API.get(allIndustriesCountUrl);
      const allAdjustments = API.get(allIndustriesUrl);
      const {
        data: { payload: adjustments },
      }: AxiosResponse<IAdjustmentListLoadResponse> = await allAdjustments;
      const {
        data: { payload: totalAdjustmentsNumber },
      } = await countPromise;
      dispatch(setAdjustmentListAction(adjustments, totalAdjustmentsNumber));
    } catch (error) {
      const message = i18n.t('adjustments.failedTogetAdjustments');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadAdjustmentItem = (adjustmentId: string) => {
  const adjustmentUrl = `/piecework/api/CollectiveAdjustments/${adjustmentId}`;
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const adjustment = API.get(adjustmentUrl);
      const {
        data: { payload: adjustmentItem },
      }: AxiosResponse<IAdjustmentItemLoadResponse> = await adjustment;
      dispatch(saveSelectedAdjustmentAction(adjustmentItem));
    } catch (error) {
      const message = i18n.t('adjustments.failedTogetAdjustment');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const searchForAdjustments = (
  query: string,
  tradeIds: [string] = [''],
  params: IParams = { pageNumber: 0, pageSize: 15 }
) => {
  const searchForCollectiveAdjustmentsUrl = `search/api/collectiveAdjustments/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: ['code', 'description'],
    tradeIds,
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForCollectiveAdjustmentsPromise = API.post(searchForCollectiveAdjustmentsUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearchResult> = await searchForCollectiveAdjustmentsPromise;
      dispatch(setAdjustmentListAction(hits, totalHits));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
      const message = i18n.t('adjustments.errorSearchingForAdjustments');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
