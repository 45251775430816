import { FC } from 'react';

type NoFileAttachedPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const NoFileAttached: FC<NoFileAttachedPropTypes> = ({
  width = 16,
  height = 16,
  color = '#6B7A99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M8.78072 1.25488H3.76318C3.07326 1.25488 2.50879 1.81936 2.50879 2.50927V12.5444C2.50879 13.2343 3.06699 13.7987 3.7569 13.7987H9.40791V8.7812H12.5439V5.01804L8.78072 1.25488ZM8.15353 5.64523V2.19567L11.6031 5.64523H8.15353ZM10.6623 13.5855V10.0356H14.2122V11.29H12.8073L14.6575 13.1402L13.7732 14.0245L11.9167 12.1743V13.5792H10.6623V13.5855Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default NoFileAttached;
