import { FC } from 'react';

type FilterPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Filter: FC<FilterPropTypes> = ({ width = 48, height = 48, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="47.7725" height="47.7725" rx="12.3549" fill={color} fillOpacity="0.22" />
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M30.8076 14.0002H16.1924C15.611 14.0002 15.0534 14.2279 14.6423 14.6334C14.2312 15.0388 14 15.5888 14 16.1621V18.3243C14.0003 18.8748 14.2133 19.4044 14.5956 19.8054L19.8462 25.3078V32.738C19.8462 33.2759 20.0496 33.7947 20.4168 34.1928C20.7839 34.5907 21.2882 34.8396 21.8314 34.8903C22.3746 34.9411 22.9174 34.7903 23.354 34.4676L26.2771 32.3054H26.2768C26.8289 31.8972 27.1538 31.2564 27.1538 30.5758V25.3076L32.4044 19.8051C32.7867 19.4042 32.9997 18.8746 33 18.3241V16.1619C33 15.5886 32.7688 15.0386 32.3577 14.6332C31.9466 14.2277 31.3889 14 30.8076 14L30.8076 14.0002ZM30.8076 18.3243L24.9614 24.4501V30.5759L22.0383 32.7381L22.0385 24.4501L16.1923 18.3243V16.1622H30.8075L30.8076 18.3243Z"
        fill="white"
      />
    </svg>
  );
};

export default Filter;
