import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

import { FormControl } from '@mui/material';
import { FC } from 'react';

import { TextField } from 'components';

interface IProps {
  value?: string | Date;
  name?: string;
  setFieldValue: Function;
  label: string;
  onChange?: Function;
  placeholder?: string;
  error?: boolean;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  disabled?: true | false;
}
export const DatePicker: FC<IProps> = ({
  value,
  name,
  setFieldValue,
  label,
  onChange,
  placeholder,
  error,
  fullWidth,
  margin,
  disabled,
  ...rest
}) => {
  return (
    <FormControl fullWidth={fullWidth} margin={margin} data-testid="datePicker">
      <MuiDatePicker
        label={label}
        disabled={disabled}
        inputFormat="dd.mm.yyyy"
        renderInput={(params: any) => (
          <TextField
            variant="filled"
            name={name}
            {...params}
            error={error}
            InputProps={{
              disableUnderline: true,
              sx: { border: '2px solid #EFEFEF', borderRadius: 3, background: 'white' },
            }}
          />
        )}
        value={value}
        onChange={(newDate: any) => {
          setFieldValue(name, newDate);
        }}
        {...rest}
      />
    </FormControl>
  );
};

export default DatePicker;
