import { FC } from 'react';

type PlusIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const PlusIcon: FC<PlusIconPropTypes> = ({ width = 56, height = 56, color = '#046B99', ...rest }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 1.75V12.25M1.75 7H12.25"
        stroke="#140A36"
        strokeOpacity="0.32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
