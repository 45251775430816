import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type DocumentIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const DocumentIcon: FC<DocumentIconPropTypes> = ({
  width = 54,
  height = 54,
  color = '#0774A4',
  bgColor = '#F4F7FE',
  ...rest
}) => {
  const theme = useTheme();

  const resolvedBgColor = resolveThemeColor(color, theme);
  const resolvedColor = resolveThemeColor(bgColor, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="52.8417" height="52.8417" rx="12" fill={resolvedBgColor} />
      <path
        d="M38.4294 21.5083L31.82 14.8989C31.3137 14.3926 30.6158 14.1052 29.8905 14.1052H17.3423C15.837 14.1052 14.6055 15.3368 14.6055 16.842V35.9997C14.6055 37.5049 15.837 38.7365 17.3423 38.7365H36.4999C38.0052 38.7365 39.2367 37.5049 39.2367 35.9997V23.4514C39.2367 22.7262 38.9494 22.0283 38.4294 21.5083ZM20.0791 19.5788H29.6579V22.3156H20.0791V19.5788ZM33.7631 33.2629H20.0791V30.5261H33.7631V33.2629ZM33.7631 27.7893H20.0791V25.0524H33.7631V27.7893Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default DocumentIcon;
