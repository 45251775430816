import { IPersonItem, IPersonsLoadResponse } from '../personsInterfaces';
export const PersonInitial: IPersonItem = {
  firstName: '',
  lastName: '',
  address: '',
  country: '',
  created: '',
  createdBy: '',
  deleted: false,
  id: '',
  lastUpdated: '',
  lastUpdatedBy: '',
  postalAddress: '',
  postalCode: '',
  telephoneNumbers: [
    {
      created: '',
      createdBy: '',
      description: '',
      id: '',
      lastUpdated: '',
      lastUpdatedBy: '',
      number: '',
      primary: false,
    },
  ],
  emailAddresses: [
    {
      email: '',
      created: '',
      createdBy: '',
      description: '',
      id: '',
      lastUpdated: '',
      lastUpdatedBy: '',
      primary: false,
    },
  ],
};

export const PersonsListInitial: IPersonsLoadResponse = {
  payload: [{ ...PersonInitial }],
  message: '',
  serviceTime: '',
  statusCode: 0,
  pagingInfo: {
    defaultPageSize: 0,
    maxPageSize: 0,
  },
};
