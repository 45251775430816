import { FC } from 'react';

type FilterMenuIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const FilterMenuIcon: FC<FilterMenuIconPropTypes> = ({
  width = 42,
  height = 42,
  color = '#C3CAD9',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4354_22732)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        d="M16.3049 15.0426C17.4005 16.4473 19.4235 19.0506 19.4235 19.0506V22.3046C19.4235 22.6029 19.6675 22.847 19.9658 22.847H21.0505C21.3488 22.847 21.5929 22.6029 21.5929 22.3046V19.0506C21.5929 19.0506 23.6104 16.4473 24.7059 15.0426C24.9825 14.6846 24.7276 14.1694 24.2775 14.1694H16.7334C16.2833 14.1694 16.0284 14.6846 16.3049 15.0426Z"
        fill={color}
      />
      <defs>
        <filter
          id="filter0_d_4354_22732"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4354_22732" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4354_22732" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default FilterMenuIcon;
