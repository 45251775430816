import { FC } from 'react';

type RemoveCirclePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  background?: string;
  [x: string]: any;
};

export const RemoveCircle: FC<RemoveCirclePropTypes> = ({ width = 21, height = 21, color = "#FF8080", background = "#FFFFFF",  ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="10.5" cy="10.3723" rx="10.5" ry="10.3723" fill="#FF8080"/>
        <path d="M6.72818 11.0467V9.18674H14.0115V11.0467H6.72818Z" fill={background}/>
    </svg>
  );
};

export default RemoveCircle;
