import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import { IOfferType } from './types';
import i18next from 'i18next';
import API from 'utils/API';

export const LOAD_ALL_OFFER_TYPES = 'LOAD_ALL_OFFER_TYPES';
export const LOAD_OFFER_TYPE = 'LOAD_OFFER_TYPE';
export const SAVE_OFFER_TYPE = 'SAVE_OFFER_TYPE';
export const UPDATE_OFFER_TYPE = 'UPDATE_OFFER_TYPE';
export const DELETE_OFFER_TYPE = 'DELETE_OFFER_TYPE';
export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';
export const SAVE_TOTAL_OFFER_TYPES = 'SAVE_TOTAL_OFFER_TYPES';

export const setHasMoreItems = (payload: boolean) => ({ type: SET_HAS_MORE_ITEMS, payload });

export const loadAllOfferTypesSuccess = (payload: Array<IOfferType>) => ({
  type: LOAD_ALL_OFFER_TYPES,
  payload,
});

export const loadOfferTypeSuccess = (payload: IOfferType) => ({
  type: LOAD_OFFER_TYPE,
  payload,
});

export const saveOfferTypeSuccess = (payload: IOfferType) => ({
  type: SAVE_OFFER_TYPE,
  payload,
});

export const updateOfferTypeSuccess = (payload: IOfferType) => ({
  type: UPDATE_OFFER_TYPE,
  payload,
});

export const deleteOfferTypeSuccess = (payload: IOfferType) => ({
  type: DELETE_OFFER_TYPE,
  payload,
});

export const saveTotalOfferTypes = (payload: number) => ({
  type: SAVE_TOTAL_OFFER_TYPES,
  payload,
});

export const loadAllOfferTypes = () => {
  return async (dispatch: Function) => {
    try {
      const getTotalOfferTypes = `offers/api/offerTypes/count`;
      const getTotalOfferTypesPromise = await API.get(getTotalOfferTypes);
      const {
        data: { payload: totalOfferTypes },
      } = await getTotalOfferTypesPromise;
      dispatch(saveTotalOfferTypes(totalOfferTypes));
      const getOfferTypes = `offers/api/offerTypes`;
      const getOfferTypesPromise = await API.get(getOfferTypes);
      const {
        data: { payload },
      } = await getOfferTypesPromise;
      dispatch(loadAllOfferTypesSuccess(payload));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorGettingOfferTypes') }));
    }
  };
};

export const loadOfferType = (type: string) => {
  return async (dispatch: Function) => {
    try {
      const getOfferType = `offers/api/offerTypes/${type}`;
      const getOfferTypePromise = await API.get(getOfferType);
      const {
        data: { payload },
      } = await getOfferTypePromise;
      dispatch(loadOfferTypeSuccess(payload));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorGettingOfferType') }));
    }
  };
};

export const saveOfferType = (offerType: IOfferType) => {
  return async (dispatch: Function) => {
    try {
      const saveOfferType = `offers/api/offerTypes`;
      const saveOfferTypePromise = await API.post(saveOfferType, offerType);
      const {
        data: { payload },
      } = await saveOfferTypePromise;
      dispatch(saveOfferTypeSuccess(payload));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorSavingOfferType') }));
    } finally {
      dispatch(loadAllOfferTypes());
    }
  };
};

export const updateOfferType = (offerType: IOfferType, updatedOfferType: IOfferType) => {
  return async (dispatch: Function) => {
    try {
      const updateOfferType = `offers/api/offerTypes/${offerType.type}`;
      const updateOfferTypePromise = await API.put(updateOfferType, updatedOfferType);
      const {
        data: { payload },
      } = await updateOfferTypePromise;
      dispatch(updateOfferTypeSuccess(payload));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorUpdatingOfferType') }));
    } finally {
      dispatch(loadAllOfferTypes());
    }
  };
};

export const deleteOfferType = (offerType: string) => {
  return async (dispatch: Function) => {
    try {
      const deleteOfferType = `offers/api/offerTypes/${offerType}`;
      const deleteOfferTypePromise = await API.delete(deleteOfferType);
      const {
        data: { payload },
      } = await deleteOfferTypePromise;
      dispatch(deleteOfferTypeSuccess(payload));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18next.t('globals.errorDeletingOfferType') }));
    } finally {
      dispatch(loadAllOfferTypes());
    }
  };
};
