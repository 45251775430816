import { Box, styled } from '@mui/material';

export const SwitchContainer = styled(Box)<any>(() => ({
  position: 'relative',
  width: '53px',
  height: '27px',
  overflow: 'hidden',
  borderRadius: '100px',
}));

export const SwitchInput = styled('input')<any>(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '0',
  margin: '0',
  opacity: '0',
  cursor: 'pointer',
  zIndex: '3',
  // '&:checked + knobs:before': {
  //   left: '50%',
  //   backgroundColor: 'red',
  // },
  // '&:checked ~ .layer': {
  //   backgroundColor: '#fcebe',
  // },
}));

export const SwitchKnobs = styled(Box, {
  shouldForwardProp: (prop: string) => !['isChecked', 'isLoading'].includes(prop),
})<any>((prop) => {
  const { isChecked, theme } = prop;
  return {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    // width: '50%',
    // height: '100%',
    // borderRadius: '100px',
    transition: 'all 0.3s ease',
    backgroundColor: isChecked ? theme.palette.primary.light : '#f5f7fe',
    zIndex: '2',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '4px',
      left: isChecked ? '50%' : '4px',
      width: '20px',
      height: '10px',
      color: '#fff',
      fontSize: '10px',
      fontWeight: 'bold',
      textAlign: 'center',
      lineHeight: '1',
      padding: '9px 4px',
      transition: 'all 0.3s ease',
      backgroundColor: isChecked ? theme.palette.grey[300] : theme.palette.grey[300],
      border: !isChecked && `1px solid ${theme.palette.secondary.lighter}`,
      borderRadius: '50%',
    },
  };
});

export const SwitchLayer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isChecked',
})<any>((prop) => {
  const { isChecked } = prop;
  return {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
    borderRadius: '100px',
    transition: 'all 0.3s ease',
    backgroundColor: isChecked ? '#fcebe' : '#03A9F4',
    zIndex: '1',
  };
});
