import { FC } from 'react';

type CloseIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const CloseIcon: FC<CloseIconPropTypes> = ({ width = 12, height = 12, color = '#935D64', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5.5" r="5" fill="white" />
      <path
        d="M6.25 4.50179L5.99821 4.25L5 5.24821L4.00179 4.25L3.75 4.50179L4.74821 5.5L3.75 6.49821L4.00179 6.75L5 5.75179L5.99821 6.75L6.25 6.49821L5.25179 5.5L6.25 4.50179Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
