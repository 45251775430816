import { FC } from 'react';

type TemplateHeaderIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const TemplateHeaderIcon: FC<TemplateHeaderIconPropTypes> = ({
  width = 60,
  height = 60,
  color = '#202236',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="14" fill="#E9EDF7" />
      <path
        d="M23.7498 18.7501H19.9998V22.5001H17.4998V16.2501H23.7498V18.7501ZM42.4998 22.5001V16.2501H36.2498V18.7501H39.9998V22.5001H42.4998ZM23.7498 41.2501H19.9998V37.5001H17.4998V43.7501H23.7498V41.2501ZM39.9998 37.5001V41.2501H36.2498V43.7501H42.4998V37.5001H39.9998ZM38.7498 37.5001C38.7498 38.8751 37.6248 40.0001 36.2498 40.0001H23.7498C22.3748 40.0001 21.2498 38.8751 21.2498 37.5001V22.5001C21.2498 21.1251 22.3748 20.0001 23.7498 20.0001H36.2498C37.6248 20.0001 38.7498 21.1251 38.7498 22.5001V37.5001ZM33.7498 25.0001H26.2498V27.5001H33.7498V25.0001ZM33.7498 28.7501H26.2498V31.2501H33.7498V28.7501ZM33.7498 32.5001H26.2498V35.0001H33.7498V32.5001Z"
        fill={color}
      />
    </svg>
  );
};

export default TemplateHeaderIcon;
