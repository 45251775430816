import LocalizedStrings from 'react-localization';

import IStrings from './IStrings';

/**
 * ***Returns a language specific string.***
 * Some strings takes variables, which increase re-useablility.
 * We can achieve this by using the `.formatString()` function that merges several variable into one sentence.
 *
 * **Example 1:**
 *
 * *Strings in localization file*
 *
 * ```ts
 * myString: "Was not connected",
 * ```
 *
 * *Usage in components*
 *
 * ```ts
 * const myNewString = strings.myString;
 * ```
 *
 * *Returns:*
 * ```
 * "Was not connected"
 * ```
 *
 *
 * **Example 2 (*with variables*):**
 *
 * *Strings in localization file*
 *
 * ```ts
 * myString: "{0} was not connected to {1}",
 * variable1: "User",
 * ```
 *
 * *Usage in components*
 *
 * ```ts
 * const myNewString = strings.formatString(strings.myString, strings.variable1, "variable2")
 * ```
 * *Returns:*
 * ```
 * "User was not connected to variable2"
 * ```
 * */
let strings: IStrings;

strings = new LocalizedStrings({
  no: {
    addNewUsers: 'Legg til nye brukere',
    addExistingUsers: 'Legg til eksisterende brukere',
    searchForUsers: 'Søk etter brukere',
    access: 'Tilgang',
    accessLevel: 'Tilgangsnivå',
    activate: 'Aktiver',
    activated: 'Aktivert',
    activateUser: 'Aktiver bruker',
    activationMailSent: 'Aktiveringsmail sendt',
    active: 'Aktiv',
    activeRevision: 'Aktiv revisjon',
    activeSince: 'Aktiv siden',
    add: 'Opprett',
    addAndSendActivationMail: 'Opprett og send aktiveringsmail',
    addAreaOfResponsibility: 'Legg til nytt ansvarsområde',
    addCustomerBeforeOfferWarning: 'Opprett kunde før du oppretter nytt tilbud',
    addExisting: 'Legg til eksisterende',
    additionalType: 'Tilleggstype',
    additions: 'Tillegg',
    addNew: 'Legg til',
    addNewCatalog: 'Legg til ny katalog',
    addNewCompany: 'Opprett nytt firma',
    addNewContactPerson: 'Legg til ny kontaktperson',
    addNewCustomer: 'Opprett ny kunde',
    addNewFromTemplate: 'Legg til ny fra mal',
    addNewPackage: 'Opprett ny pakke',
    addNewPerson: 'Opprett ny person',
    addNewProperty: 'Legg til ny eiendom',
    addNewSite: 'Opprett nytt anlegg',
    addParamsBeforeOfferWarning: 'Opprett std. parameter før du oppretter nytt tilbud',
    addPiecework: 'Legg til akkord',
    address: 'Adresse',
    addUserRoleFirst: 'Legg til en brukerrolle først',
    addVendor: 'Opprett ny leverandør',
    adjustments: 'Justeringer',
    adjustPieceWork: 'Juster akkord',
    adjustTo: 'Juster til',
    admin: 'Admin',
    administration: 'Administrasjon',
    advancedPriceCalculation: 'Avansert priskalkyle',
    agreement: 'Avtale',
    all: 'Alle',
    allowComments: 'Tillat kommentarer',
    altinnLoggedIn: 'Du er logget inn i Altinn.',
    amount: 'Rundsum',
    amountOfPoints: 'Antall punkter',
    amountOfUsers: 'Antall brukere',
    aPDFOfTheManualWasAddedToTheProject: 'En PDF av håndboken ble lagt til prosjektet',
    application: 'Søknad',
    applications: 'Søknader',
    applicationSavedInAltinn: 'Søknad / erklæring ble lagret i Altinn',
    approve: 'Godkjenn',
    approved: 'Godkjent',
    approveVersion: 'Godkjenn versjon',
    areaOfResponsibility: 'Ansvarsområde',
    areYouSure: 'Er du sikker?',
    areYouSureFinalizeChecklist:
      'Hvis du velger å ferdigstille sjekklisten kan du ikke lenger gjøre endringer. Er du sikker på at du vil ferdigstille sjekklisten?',
    areYouSureYouWantToDelete: 'Er du sikker på at du vil slette',
    around: 'Rundt',
    as: 'Som',
    asDeleted: 'Som slettet',
    attachments: 'Vedlegg',
    available: 'Tilgjengelig',
    availableExternally: 'Tilgjengelig eksternt',
    availables: 'Tilgjengelige',
    averageSalary: 'Gj.snitt Timelønn',
    back: 'Tilbake',
    base: 'Base',
    basicRegister: 'Basisregister',
    blank: 'Blank',
    buildingElement: 'Bygningsdel',
    buildingElements: 'Bygningsdeler',
    calculatePieceWork: 'Kalkulere akkordarbeid',
    calculatePieceWorkWarning1:
      'Det er ikke huket av for å kalkulere akkordarbeid i kalkulasjonsparameterne. Skal det beregnes akkordkostnader på',
    calculatePieceWorkWarning2: ', må dette være huket av for i kalkulasjonsparameterne.',
    calculatePieceWorkWarning3:
      'Akkordlønn per time er ikke satt i kalkulasjonsparameterne. For å beregne akkordkostnader, samt justere selvkost og påslag på arbeid må gjennomsnittlig akkordlønn settes i kalkulasjonsparameterne og være større enn 0.',
    calculationType: 'Kalkyletype',
    cancel: 'Avbryt',
    cannotDeleteASignedApplication: 'Du kan ikke slette en signert søknad',
    cannotDeleteCompletedChecklist: 'Kan ikke slette en ferdigstilt sjekkliste',
    cannotDeleteStartedChecklist: 'Kan ikke slette en påbegynt sjekkliste',
    cannotDeleteTenantWithUsers: 'Kan ikke slette en tenant med tilknyttede brukere.',
    cantDeletePersonError: 'Personen er tilknyttet en kunde og kan ikke slettes.',
    caseManager: 'Saksbehandler',
    catalog: 'Katalog',
    catalogs: 'Kataloger',
    catalogType: 'Katalogtype',
    categories: 'Kategorier',
    category: 'Kategori',
    categoryOrAgreement: 'Kategori',
    chainAdminPrivileges:
      'Da du har administratorrettigheter for flere kjeder, er du nødt til å velge hvilke kjeder du ønsker å knytte den offentlige katalogen opp mot.',
    change: 'Endre',
    changeChapterWarning: 'Endringer du gjør på denne siden vil tre i kraft alle steder hvor kapittelet er i bruk!',
    changeCustomer: 'Bytt kunde',
    changePlacement: 'Endre plassering',
    changesHaveBeenMade: 'Det er gjort endringer på',
    changesWasSaved: 'Endringer ble lagret',
    chapter: 'Kapittel',
    checkboxField: 'Avkrysningsfelt',
    checkForm: 'Kontroller skjema',
    checklist: 'Sjekkliste',
    checklists: 'Sjekklister',
    checklistSettingsDescription:
      'Velg hvilken informasjon som skal vises på sjekklisten, og om den skal være synlig i håndholdte systemer.',
    checklistTemplate: 'Sjekklistemal',
    checklistTemplates: 'Sjekklistemaler',
    checkpoint: 'Sjekklistepunkt',
    checkpointTemplate: 'Punktmal',
    checkpointTemplates: 'Punktmaler',
    choose: 'Velg',
    chooseCompany: 'Velg firma',
    chooseCustomer: 'Velg kunde',
    chooseCustomerType: 'Velg kundetype',
    chooseFilter: 'Velg filtrering',
    chooseModulePackage: 'Velg modulpakke',
    chooseNodesToUpdate: 'Velg hva i spesifikasjonen som skal oppdateres',
    chooseOrganization: 'Velg organisasjon',
    choosePackageCatalogToCopyTo: 'Velg katalog som pakken skal kopieres til',
    chooseParams: 'Velg kalkulasjonsmetode',
    choosePieceworkCategory: 'Velg akkordkategori',
    chooseRevision: 'Velg revisjon',
    chooseSite: 'Velg anlegg',
    chooseSorting: 'Velg sortering',
    chooseTenant: 'Tenant må velges',
    chooseType: 'Velg type',
    chooseUnit: 'Velg enhet',
    chooseUser: 'Velg bruker',
    chooseValidFromDate: 'Gyldig fra dato må velges',
    chooseValidToDate: 'Gyldig til dato må velges',
    chooseWhatToAdjust: 'Velg hva som skal justeres',
    city: 'Sted',
    clearFilter: 'Tøm filter',
    close: 'Lukk',
    closeSideMenu: 'Lukk sidemeny',
    code: 'Kode',
    collectiveAdjustment: 'Tarifftillegg',
    collectiveAdjustmentAmount: 'Beløp tillegg',
    collectiveAdjustmentFactor: 'Faktor for tarifftillegg',
    collectiveAdjustmentPercent: 'Tillegg (%)',
    column: 'Kolonne',
    columnLayout: 'Kolonneoppsett',
    columns: 'Kolonner',
    comment: 'Kommentar',
    companies: 'Firmaer',
    company: 'Firma',
    companyinformation: 'Firmainformasjon',
    companyName: 'Firmanavn',
    companyNameRequired: 'Firmanavn må fylles ut',
    complete: 'Ferdigstill',
    completed: 'Ferdigstilt',
    completedInFieldSystem: 'Sendt fra Cordel UTE',
    completedInFieldSystemAndApproved: 'Ferdigstilt UTE og godkjent',
    completedMultiple: 'Ferdigstilte',
    compositePoint: 'Egendefinert',
    confirmPassword: 'Bekreft passord',
    confirmPasswordPlease: 'Vennligst bekreft passord',
    conflict: 'Konflikt',
    connectCatalog: 'Knytt katalog til en eller flere av dine kjeder:',
    connectPersonToEmployee: 'Knytt medarbeider til person i person-registeret',
    contact: 'Kontakt',
    contactInformation: 'Kontaktinformasjon',
    contactPerson: 'Kontaktperson',
    contactpersons: 'Kontaktpersoner',
    contactResponsibleIn: 'Kontakt ansvarlig i',
    contactWasChanged: 'Kontakt ble endret',
    content: 'Innhold',
    copy_noun: 'Kopi',
    copy: 'Kopier',
    copyPackage: 'Kopier pakke',
    cost: 'Selvkost',
    costAndSurcharge: 'Selvkost + påslag oversikt',
    costPrice: 'Selvkost',
    costProcureProducts: 'Selvkost prisbokvarer',
    costsAndWastage: 'Omkostninger/svinn',
    costStockProducts: 'Selvkost prisbokvarer',
    couldNotFindEditPoint: 'Kunne ikke finne punkt å redigere. Lukk vinduet og prøv igjen.',
    couldNotLoadImages: 'Kunne ikke laste inn bilder. Last inn siden på nytt og prøv igjen.',
    couldNotSignApplicationError: 'Noe gikk galt. Kunne ikke signere søknad / erklæring',
    couldNotSignError: 'Noe gikk galt. Kunne ikke signere',
    country: 'Land',
    coverageOnSalary: 'Dekning på lønn',
    coveragePerHour: 'Dekning per time',
    coverageTotal: 'Dekning totalt',
    create: 'Opprett',
    createAndSendActivationMail: 'Opprett og send aktiverings e-post',
    createBlank: 'Opprett blank',
    createCopy: 'Opprett kopi',
    created: 'Opprettet',
    createdBy: 'Opprettet av',
    createImageParagraph: 'Opprett bildeavsnitt',
    createNewOffer: 'Opprett nytt tilbud',
    createNewRevision: 'Lag ny revisjon',
    createTextParagraph: 'Opprett tekstavsnitt',
    cubicMeter_short: 'm3',
    cubicMeter: 'Kubikkmeter',
    currency: 'Valuta',
    currentLicenses: 'Gjeldende lisenser',
    currentlyUsing: 'Du bruker nå',
    customer: 'Kunde',
    customerAmount: 'Sum',
    customerAmountIncVat: 'Kundens totalbeløp inkl. mva',
    customerIdNotValid: 'Kunde-id er ikke gyldig.',
    customerInfo: 'Kundeinformasjon',
    customerName: 'Kundenavn',
    customerNumber: 'Kundenr.',
    customerNumberOrOrganizationAlreadyExist: 'Kundenummer eller organisasjon eksisterer allerede',
    customerNumberRequired: 'Kundenr. påkrevd',
    customerPrice: 'Kundens pris eks. mva',
    customers: 'Kunder',
    customerTotalAmount: 'Kundens totalbeløp',
    customerType: 'Kundetype',
    customerUnitPrice: 'Kundens enhetspris',
    dashboard: 'Dashbord',
    date: 'Dato',
    dateAsc: 'Eldst til nyest',
    dateDesc: 'Nyest til eldst',
    dateEarliestValid: 'Første gyldige dato',
    dateField: 'Dato og tidspunkt',
    dateIsPastValid: 'Gyldig til dato kan ikke være lengre enn 2 år fram i tid',
    dateLatestValid: 'Siste gyldige dato',
    dateMustBeWithinValidLicenseDate: 'Dato må være innenfor gyldig lisensperiode',
    dates: 'Datoer',
    day: 'Dag',
    deactivated: 'Deaktivert',
    deactivatedLicenses: 'Deaktiverte lisenser',
    declarationAndSigningHeader: 'Erklæringen og signering',
    declarationApplies: 'Erklæringen gjelder',
    declarationCheckboxKontroll:
      'Ansvarlig kontrollerende erklærer uavhengighet, jf. SAK10 § 14-1, og vil redegjøre for endringer som kan påvirke uavhengigheten jf. SAK10 §12-5',
    declarationCheckboxProsjekterende:
      'Ansvarlig prosjekterende erklærer at prosjekteringen skal være planlagt, gjennomført og kvalitetssikret i henhold til pbl jf. SAK10 §12-3',
    declarationCheckboxUtfoerende:
      'Ansvarlig utførende erklærer at arbeidet ikke skal starte før det foreligger kvalitetssikret produksjonsunderlag for respektive del av utførelsen jf SAK10 § 12-4',
    declarationHeader: 'Erklæring',
    declarationPenaltyInfo:
      'Vi kjenner til reglene om straff og sanksjoner i plan- og bygningsloven kapittel 32, og at uriktig opplysninger kan føre til reaksjoner.',
    declarationSAKInfo:
      'Vi forplikter oss til å stille med riktig kompetanse i byggeprosjektet, jf. SAK10 kapittel 10 og 11.',
    declarationSignedAndSubmitted: 'Erklæringen er signert og sendt inn.',
    default: 'Standard',
    defaultCost: 'Standard kostnad',
    defaultCostCurrency: 'Standard kostnadsvaluta',
    defaultMaterialMarkup: 'Standard påslag',
    defaultPoints: 'Standardpunkter',
    defaultPriceCurrency: 'Standard prisvaluta',
    defaultVendorDiscount: 'Bruk standard rabatt',
    DefaultVendorDiscountSource: 'Bruk standard påslag',
    delete: 'Slett',
    deleted: 'Slettede',
    deletedChecklist: 'Slettet sjekkliste',
    deletedChecklistInfo: 'Denne sjekklisten er slettet!',
    deletedChecklistTemplates: 'Slettede sjekklistemaler',
    deletedCompanies: 'Slettede firmaer',
    deletedCustomers: 'Slettede kunder',
    deletedManualDraftChaptersRegister: 'Slettede håndbok kapittler',
    deletedManualDraftsRegister: 'Slettede håndbok utkast',
    deletedPersons: 'Slettede personer',
    deletedSites: 'Slettede anlegg',
    deletedVendors: 'Slettede leverandører',
    department: 'Avdeling',
    description: 'Beskrivelse',
    descriptionIsRequired: 'Beskrivelse må fylles ut',
    detailsMaterial: 'Detaljer materiell',
    detailsWork: 'Detaljer akkord (arbeid)',
    discount: 'Rabatt',
    discountAndSurcharge: 'Rabatt og påslag',
    discountPercent: 'Rabatt kunde (%)',
    discountPerUnit: 'Rabatt per enhet',
    documents: 'Dokumenter',
    doYouWantToResetChanges: 'Ønsker du å tilbakestille alle endringer?',
    draft: 'Utkast',
    drafts: 'Utkast',
    ean: 'EAN',
    edit: 'Rediger',
    editCatalogs: 'Rediger kataloger',
    editCompany: 'Rediger firma',
    editContactInfo: 'Rediger kontaktinformasjon',
    editCustomerInfo: 'Rediger kundeinformasjon',
    editEmail: 'Rediger e-post',
    editInformation: 'Rediger informasjon',
    editOffer: 'Rediger tilbud',
    editPackage: 'Rediger pakke',
    editPackageGlobal: 'Endre pakke globalt',
    editPackageInfo: 'Rediger pakkeinformasjon',
    editPerson: 'Rediger person',
    editPhonenumber: 'Rediger telefonnr.',
    editPriceCalculation: 'Rediger kalkyle',
    editProduct: 'Rediger vare',
    editVendor: 'Rediger leverandør',
    editVendorInfo: 'Rediger leverandørinformasjon',
    email: 'E-post',
    employees: 'Medarbeidere',
    endDate: 'Sluttdato',
    entrepreneur: 'Entreprenør',
    environmentTax: 'Miljøavgift',
    erklaeringOmAnsvarsrett: 'Erklæring om ansvarsrett',
    event: 'Hendelse',
    example: 'Eksempel',
    excludeFromSum: 'Ikke summér',
    expired: 'Utløpt',
    export: 'Eksporter',
    extendTo: 'Forleng til',
    externalCode: 'Eksternt nr.',
    factorBasis: 'Grunnlag beregning av faktor 1',
    farmNo: 'Gårdsnr.',
    favorite: 'Favoritt',
    file: 'Fil',
    fileType: 'Filtype',
    fileTypeHasExistingDefaultFolder: 'Filtype har eksisterende standardmappe',
    fileTypeMustBeImage: 'Filtype må være jpeg, jpg eller png.',
    fillInAllRequiredFields: 'Fyll inn alle påkrevde felter',
    fillInAllRequiredFieldsOrMarkAsNotApplicable:
      'Alle felter som er påkrevd må fylles ut eller markeres som "ikke aktuelt"',
    filter: 'Filter',
    finalize: 'Ferdigstill',
    finalizeChecklist: 'Ferdigstille sjekkliste?',
    finalized: 'Avsluttet',
    finishProject: 'Ferdigstill prosjekt',
    firstName: 'Fornavn',
    firstNameRequired: 'Fornavn må fylles ut',
    fixedPrice: 'Fast pris',
    folder: 'Mappe',
    forename: 'Fornavn',
    forgotPassword: 'Glemt passord?',
    formatACatalogIsNeededBeforeCreatingAnX: 'En katalog må opprettes før du kan opprette en {0}',
    formatACatalogIsNeededBeforeCreatingAX: 'En katalog må opprettes før du kan opprette en {0}',
    formatAddAfterX: 'Legg til etter {0}',
    formatAddExistingX: 'Legg til eksisterende {0}',
    formatAddNewX: 'Legg til ny {0}',
    formatAddX: 'Legg til {0}',
    formatAddXAfterX: 'Legg til {0} etter {1}',
    formatAddXBeforeX: 'Legg til {0} før {1}',
    formatAddXOfXToX: 'Legg til {0} av {1} til {2}',
    formatAddXTo: 'Legg til på {0}',
    formatAddXToX: 'Legg til {0} på {1}',
    formatAllXX: 'Alle {0} {1}',
    formatAreYouSureYouWantToDeleteX: 'Er du sikker på at du vil slette {0}?',
    formatAreYouSureYouWantToPermanentlyDeleteX: 'Er du sikker på at du vil permanent slette {0}?',
    formatAXIsNeededBeforeCreatingAnX: 'En {0} må opprettes før du kan opprette en {1}',
    formatAXIsNeededBeforeCreatingAX: 'En {0} må opprettes før du kan opprette en {1}',
    formatCannotPreviewX: 'Kan ikke forhåndsvise {0}',
    formatCatalogWillBeAvailableForX: 'Denne katalogen vil bli tilgjengelig for {0}',
    formatChoosePlacementForX: 'Velg plassering for {0}',
    formatChooseX: 'Velg {0}',
    formatCouldNotAddXToX: 'Kunne ikke legge {0} til {1}',
    formatCouldNotConnectXToX: 'Kunne ikke koble {0} til {1}',
    formatCouldNotDeleteX: 'Kan ikke slette {0}',
    formatCouldNotDeleteXConnectedToX: 'Kan ikke slette {0} som er tilknyttet {1}',
    formatCouldNotDeleteXContainingX: 'Kan ikke slette {0} som inneholder {1}',
    formatCouldNotDeleteXFromX: 'Kan ikke slette {0} fra {1}',
    formatCouldNotFindAnyX: 'Kunne ikke finne noen {0}',
    formatCouldNotGetInformationAboutX: 'Kunne ikke hente informasjon om {0}',
    formatCouldNotLoadX: 'Kunne ikke laste {0}',
    formatCouldNotPreviewX: 'Kunne ikke forhåndsvise {0}',
    formatCouldNotRestoreX: 'Kunne ikke gjenopprette {0}',
    formatCouldNotUpdateX: 'Kunne ikke oppdatere {0}. Prøv igjen.',
    formatCreateANewVersionOfAnExistingX: 'Opprett ny versjon av en eksisterende {0}',
    formatCreateANewVersionOfX: 'Opprett en ny versjon av {0}',
    formatCreateANewX: 'Opprett nytt {0}',
    formatCreateNewX: 'Opprett ny {0}',
    formatCreateX: 'Opprett {0}',
    formatDeletedX: 'Slettede {0}',
    formatDeleteX: 'Slett {0}',
    formatDeleteXFromX: 'Slett {0} fra {1}',
    formatDownloadX: 'Last ned {0}',
    formatEditX: 'Rediger {0}',
    formatEnterX: 'Skriv inn {0}...',
    formatErrorDownloadingX: 'Feil ved nedlasting av {0}. Prøv igjen.',
    formatErrorXIsX: 'Feil ved {0} - {0} er {1}.',
    formatExtendTheX: 'Forleng {0}',
    formatExtendX: 'Utvid {0}',
    formatGoToNextX: 'Gå til neste {0}',
    formatGoToPreviousX: 'Gå til forrige {0}',
    formatIfYouDeleteXItCanNotBeUndone:
      'Hvis du velger å slette {0}, er dette en handling som ikke kan gjøres om. Vi spør derfor en gang til:',
    formatManuallyAddX: 'Legg til bruker manuelt',
    formatMaxAllowedXIsX: 'Maksimum tillatte {0} er {1}',
    formatMinAllowedXIsX: 'Minimum tillatte {0} er {1}',
    formatMoveX: 'Flytt {0}',
    formatMoveXToX: 'Flytt {0} til {1}',
    formatNewXAdded: 'Nytt {0} ble lagt til',
    formatNoX: 'Ingen {0}',
    formatNoXAvailable: 'Ingen {0} tilgjengelig',
    formatNoXChosen: 'Ingen {0} valgt',
    formatOrInDefaultFolderForX: 'Eller i standardmappe for {0}',
    formatProblemCreatingX: 'Problem med å opprette {0}',
    formatPutXOnX: 'Sett {0} på {1}',
    formatRemoveX: 'Fjern {0}',
    formatResetX: 'Tilbakestill {0}',
    formatSelectAXToPreview: 'Velg en {0} for å forhåndsvise',
    formatSelectedX: 'Valgt {0}',
    formatSelectX: 'Velg {0}',
    formatSelectXToCreateAnX: 'Velg {0} for å opprette en {1}',
    formatSelectXToCreateAX: 'Velg {0} for å opprette en {1}',
    formatSelectXToCreateX: 'Velg {0} for å opprette {1}',
    formatThereExistsNoX: 'Det finnes ingen {0}',
    formatThereExistsNoXForX: 'Det finnes ingen {0} for {1}',
    formatTypeInX: 'Skriv inn {0}...',
    formatUploadNewX: 'Last opp ny {0}',
    formatUploadX: 'Last opp {0}',
    formatWasAdded: '{0} ble lagt til', // Takes a parameter that gets added before the sentence I.E `"{variable} ble lagt til"`
    formatXAlreadyAdded: '{0} er allerede lagt til',
    formatXCannotExceedX: '{0} kan ikke overskride {1}',
    formatXDoesNotHaveAnX: '{0} har ikke en {1}',
    formatXDoesNotHaveAX: '{0} har ikke en {1}',
    formatXDoesNotHaveX: '{0} har ikke {1}',
    formatXFailedToImport: '{0} ble ikke importeret',
    formatXFromX: '{0} fra {1}',
    formatXGotImported: '{0} ble importert',
    formatXHyphenX: '{0} – {1}',
    formatXIsAlreadyConnectedToTheX: '{0} er allerede tilknyttet {1}',
    formatXIsAlreadyConnectedToX: '{0} er allerede tilknyttet {1}',
    formatXIsNotValid: '{0} er ikke gyldig',
    formatXIsRequired: '{0} er påkrevd',
    formatXMarkedAsX: '{0} er merket som {1}',
    formatXMissingX: '{0} mangler {1}',
    formatXMustBeAValidX: '{0} må være en gyldig {1}',
    formatXNeedsX: '{0} må ha {1}',
    formatXNeedsXAndX: '{0} må ha {1} og {2}',
    formatXWasAdded: '{0} ble lagt til',
    formatXWasConnectedToX: '{0} ble koblet til {1}', // Takes two parameters, one before and one after.
    formatXWasCopied: '{0} ble kopiert',
    formatXWasDeleted: '{0} ble slettet',
    formatXWasDisconnectedFromX: '{0} ble koblet fra {1}',
    formatXWasNotAdded: '{0} ble ikke lagt til',
    formatXWasNotConnectedToX: '{0} ble ikke koblet til {1}',
    formatXWasNotDisconnectedFromX: '{0} ble ikke koblet fra {1}',
    formatXWasPublished: '{0} ble publisert',
    formatXWasRestored: '{0} ble gjennopprettet',
    formatXWasSaved: '{0} ble lagret',
    formatXWasSavedInX: '{0} ble lagret i {1}',
    formatXWasUpdated: '{0} ble oppdatert',
    formatXWasUploaded: '{0} ble lastet opp',
    formatXWithXXAlreadyExists: '{0} med {1} {2} eksisterer allerede',
    formatXXWasAdded: '{0} {1} ble lagt til',
    formatYouNeedToAddAtleastOneXBeforeYouCanAddX: 'Du må legge til minst en {0} før du kan legge til {1}',
    formatYouNeedToAddXBeforeYouCanAddX: 'Du må legge til {0} før du kan legge til {1}',
    freeText: 'Fritekst',
    freight: 'Frakt',
    fromAltinn: 'Fra Altinn',
    fullName: 'Navn',
    general: 'Generelt',
    generalContractor: 'Entreprenør',
    generalInformation: 'Generell informasjon',
    getCorrectAccessAltinn: '. For å kunne sende inn søknad / erklæring må du ha riktig tilgang i Altinn.',
    getStartedWithCordelByggsok: 'Kom i gang med Cordel Byggsøk - Cordel Kundesider',
    getStartedWithCordelKS: 'Kom i gang med Cordel KS | HMS - Cordel Kundesider',
    getXml: 'Hent XML',
    goBack: 'Gå tilbake',
    goodAfternoon: 'God ettermiddag',
    goodEvening: 'God kveld',
    goodMorning: 'God morgen',
    goTo: 'Gå til',
    handheld: 'Håndholdt',
    headerField: 'Overskrift',
    hereYouWillFindByggsok: 'Her finner du all informasjon du trenger for å komme i gang med Cordel byggsøk',
    hereYouWillFindKS: 'Her finner du all informasjon du trenger for å komme i gang med Cordel KS | HMS',
    hide: 'Skjul',
    history: 'Historikk',
    hour_short: 'Tim',
    hour: 'Time',
    hourCategory: 'Timekategori',
    hourCategoryNr: 'Timekategorinr.',
    identical: 'Identisk',
    ifNoPlacementIsSelectedXWillBePlacedOnTopLevel:
      'Dersom ingen plassering blir valgt, vil {0} bli plassert på øverste nivå',
    import: 'Importer',
    importNS3459: 'Importer prisforespørsel NS3459',
    include: 'Inkluder',
    includes: 'Inneholder',
    incomeCurrency: 'Valuta for inntekter',
    incSocialCostsFactor: 'Faktor for inkl sosiale kostnader',
    indirectCosts: 'Indirekte kostnader',
    indirectCostsFactor: 'Faktor for indirekte kostnader',
    industry: 'Bransje',
    information: 'Informasjon',
    informationField: 'Informasjonsfelt',
    inProgress: 'Pågående',
    internal: 'Internt',
    internalInformation: 'Intern informasjon',
    internals: 'Interne',
    itemsPerPackage: 'Antall pr. pakke',
    keywords: 'Stikkord',
    kilogram_short: 'kg',
    kilogram: 'Kilo',
    kilometer_short: 'Km',
    kilometer: 'Kilometer',
    knowledge: 'Kunnskap',
    knowledgeBase: 'Kunnskapsbase',
    last: 'siste',
    lastName: 'Etternavn',
    lastNameRequired: 'Etternavn må fylles ut',
    lastUpdated: 'Sist endret',
    lastUpdatedBy: 'Sist endret av',
    latestProject: 'Siste projekt',
    leaseNumber: 'Festenummer',
    license: 'Lisens',
    licenseInformation: 'Lisensinformasjon',
    licenses: 'Lisenser',
    line: 'Linje',
    linebreak: 'Linjeskift',
    listPrice: 'Listepris',
    litre_short: 'l',
    litre: 'Liter',
    loading: 'Laster...',
    loadMore: 'Last inn mer',
    locked: 'Låst',
    lockPrice: 'Lås pris',
    log: 'Logg',
    loggedIn: 'Forrige innlogging',
    loggedOutIn: 'Du blir logget ut om:',
    login: 'Logg inn',
    loginWith: 'Logg inn med',
    logo: 'Logo',
    logout: 'Logg ut',
    logs: 'Logger',
    mainSubmissionNumber: 'Hovedinnsendingsnr.',
    manual: 'Manuelt',
    manualDraft: 'Håndbokmal',
    manualDraftRegister: 'Håndbokregister for utkast av håndbok',
    manualDrafts: 'Håndbokmaler',
    manualDraftsRegister: 'Håndbokmaler',
    manualHasNoChapters: 'Håndbok har ingen kapitler',
    manualHMS: 'Håndbok',
    manualId: 'Håndbok-Id',
    manuals: 'Håndbøker',
    manualsRegister: 'Håndbokregister',
    markup: 'Påslag',
    markupBasis: 'Påslagsgrunnlag',
    markupPercent: 'Påslag (%)',
    markupPercentProcureProducts: 'Påslag skaffevarer',
    markupPercentStockProducts: 'Påslag prisbokvarer',
    matCost: 'Selvkost materiell',
    matCostPricePerUnit: 'Selvost per enhet',
    matCostSum: 'Sum selvkost mat.',
    matCustomerAmount: 'Beløp mat. eks. mva',
    matCustomerPrice: 'Enh.pris mat. eks. mva',
    material: 'Materiell',
    materialCostPrice: 'Selvkost enh. mat.',
    MaterialDiscountPercent: 'Rabatt på matriell i prosent',
    materialDiscountSource: 'Grunnlag for rabatt på materiell',
    materialMarkupPercent_short: 'Påslag matr.',
    MaterialMarkupPercent: 'Påslag på matriell i prosent',
    materialMarkupSource: 'Grunnlag for påslag på materiell',
    materialPrice: 'Pris for materiell',
    materialsList: 'Materialliste',
    matMarkupPercent: 'Påslag materiell',
    matPriceInklMarkup: 'Pris inkl. påslag',
    matProfitPercent: 'Fortjeneste mat. (%)',
    matrikkelInformation: 'Matrikkelinformasjon',
    meter_short: 'm',
    meter: 'Meter',
    minute_short: 'Min',
    minute: 'Minutt',
    minutes: 'Minutter',
    modified: 'Endret',
    modifiedBy: 'Endret av',
    module: 'Modul',
    moduleCode: 'Modulkode',
    moduleConnection: 'Modulkobling',
    moduleName: 'Modulnavn',
    modulePackage: 'Modulpakke',
    modulePackageInformation: 'Modulpakkeinformasjon',
    modulePackages: 'Modulpakker',
    modules: 'Moduler',
    more: 'Mer...',
    municipality: 'Kommune',
    municipalityCaseNumber: 'Kommunens Saksnr.',
    municipalityNo: 'Kommunenummer',
    name: 'Navn',
    nameIsRequired: 'Navn må fylles ut',
    nameRequired: 'Navn må fylles ut',
    nb: 'NB!',
    netCostPrice_short: 'Selvkost matr.',
    netCostPrice: 'Netto selvkost',
    netCostPriceLocked: 'Lås selvkostpris',
    newContactAdded: 'Ny kontakt lagt til',
    newTenantAdded: 'Ny tenant lagt til',
    next: 'Neste',
    nextPage: 'Gå til neste side',
    no: 'Nei',
    noAbortAndCancel: 'Nei, angre og avbryt',
    noAccessInAltinnTo: 'Du har ikke tilgang i Altinn til',
    noAccessPage: 'Du har ikke tilgang til denne siden.',
    noAltinnAccessForCompany:
      'Du har ikke tilgang i Altinn til firmaet. For å kunne sende inn søknad/erklæring må du ha riktig tilgang i Altinn. Kontakt ansvarlig i firmaet.',
    noCaseManagerSelected: 'Ingen saksbehandler valgt',
    noCatalog: 'Ingen kataloger',
    noCatalogsExists: 'Det eksisterer ingen kataloger',
    noCatalogsExistsInstructions: 'For å kunne fortsette, må du først opprette en katalog.',
    noCatalogsForPackages:
      'Det eksisterer ingen kataloger for pakker. For å kunne opprette en pakke, må det knyttes til en eksisterende katalog.',
    noCatalogsOnHourCategoryWarning:
      'Det eksisterer ingen kataloger for timekategorier. For å kunne opprette en timekategori, må det knyttes til en eksisterende katalog.',
    noCategoriesExistsInstructions: 'For å kunne fortsette, må du først opprette en kategori.',
    noCityInAltinn:
      'Mottok ufullstendig data fra Altinn. Kontroller / oppdater bedriften sin adresse i Altinn eller Brønnøysundregisteret.',
    noContactPerson: 'Ingen kontaktperson lagt til',
    noContent: 'Intet innhold',
    noCustomer: 'Ingen kunde',
    noData: 'Ingen data',
    noDataInTable: 'Ingen data i tabellen',
    noElementsOnThisPage: 'Ingen element på denne siden',
    noEmail: 'Ingen e-post',
    noInformationAvailable: 'Ingen Informasjon tilgjengelig',
    noModuleInModulePackage: 'Ingen modul i modulpakke',
    none: 'Ingen',
    noOptionsText: 'Ingen valg samsvarer',
    noPersonSelected: 'Ingen person valgt',
    noPhoneNumber: 'Ingen telefonnr.',
    noProjectAvailableInDashboard:
      'Her får du opp informasjon om det siste prosjektet du har sett på. Velg prosjekt fra menyen eller gå til prosjekter for å få opp informasjon her.',
    noSubContent: 'Intet underinnhold',
    notActive: 'Ikke aktiv',
    note: 'Notat',
    notice: 'Merknad',
    notloggedInToAltinn: 'Du er ikke logget inn i Altinn.',
    noWriteAccess: 'Du har ikke skrivetilgang på denne siden.',
    nsCode: 'NS-kode',
    nscode: 'NS-kode',
    nsCodes: 'NS-koder',
    nsSum: 'NSSum',
    number: 'Nummer',
    numberOfHours: 'Antall timer',
    numberOfHoursProduction: 'Antall timer produksjon',
    numberOfHoursTravel: 'Antall timer reise',
    numberOfSquareMeters: 'Antall kvadratmeter',
    numberShort: 'Nr.',
    numericField: 'Nummerisk',
    of: 'av',
    offer: 'Tilbud',
    offerDate: 'Tilbudsdato',
    offerDeadline: 'Anbudsfrist',
    offerInfo: 'Tilbudsinformasjon',
    offerNumber: 'Tilbudsnr.',
    offers: 'Tilbud',
    offerSettings: 'Innstillinger tilbud',
    ok: 'Ok',
    onBehalfOf: 'på vegne av',
    oneOrMoreTypesNotAdded: 'En eller flere typer ble ikke lagt til',
    oneOrMoreTypesWasNotRemoved: 'En eller flere typer ble ikke fjernet',
    ongoing: 'Pågående',
    onlyCreatorOfTemplateCanHideInfo: 'Kun skaperen av malen kan vise/skjule versionsinformasjon',
    open: 'Åpne',
    orderBy: 'Sorter etter',
    orDragFileHere: 'eller dra filen hit.',
    organization: 'Organisasjon',
    organizationDeleted: 'Organisasjon ble slettet',
    organizationInformation: 'Organisasjonsinformasjon',
    organizationMissingName: 'Organisasjonens navn må fylles ut',
    organizationMissingNumber: 'Organisasjonsnummer må fylles ut',
    organizationNumber: 'Organisasjonsnr',
    organizationRestored: 'Organisasjon ble gjenopprettet',
    organizations: 'Organisasjoner',
    organizationsCouldNotLoad: 'Kunne ikke hente inn organisasjoner. Prøv igjen senere.',
    organizationsLogo: 'Organisasjonens logo',
    ourCustomerNumber: 'Egendefinert kundenr.',
    ourDeclarationsAvailableAtCompliance: 'Våre samsvarserklæringer vil foreligge ved:',
    ourDeclarationsAvailableAtControl: 'Våre kontrollerklæringer vil foreligge ved:',
    outcome: 'Utfall',
    overall: 'Samlet',
    overallTotals: 'Totalbildet',
    overview: 'Oversikt',
    package: 'Pakke',
    packageCopyError: 'Pakkenr. må være unik per katalog. Kopier pakken til en annen katalog eller endre pakkenr.',
    packageInfo: 'Pakkeinformasjon',
    packageNumber: 'Pakkenr.',
    packages: 'Pakker',
    packageUnit: 'Pakkenehet',
    packageWithoutFixedPrice: 'Denne pakken har ikke fast pris',
    page: 'Side',
    pageBreak: 'Sideskift',
    paragraph: 'Avsnitt',
    param: 'Std.parameter',
    params: 'Parametere',
    partsSurcharge: 'Tillegg deler',
    partsSurchargeAmount: 'Beløp deler',
    partsSurchargeBasis_short: 'Grl. deler',
    partsSurchargeBasis: 'Grunnlag deler/omkostninger',
    partsSurchargeMethod: 'Metode for tillegg deler',
    partsSurchargePercent: 'Deler (%)',
    partyNo: 'Festenr.',
    password: 'Passord',
    passwordMismatch: 'Passordene er ikke like',
    passwordRequirment: 'Passord må oppfylle passordkrav',
    passwordRequirmentsNotMet: 'Passordkrav er ikke oppfylt',
    passwordSuccessfullyActivatedMessage: 'Du har nå aktivert din bruker og vil nå bli omdirigert til logg inn siden.',
    passwordSuccessfullyResetMessage: 'Du har tilbakestilt ditt passord, og vil nå bli omdirigert til logg inn siden.',
    pdf: 'PDF',
    permanent: 'Permanent',
    person: 'Person',
    personIdNotValid: 'Person-id er ikke gyldig.',
    persons: 'Personer',
    phone: 'Telefon',
    pickFile: 'Velg fil',
    piece_short: 'Stk',
    piece: 'Stykk',
    piecework: 'Akkordtariff',
    pieceWorkAmount: 'Akkordlønn per enhet',
    pieceWorkAmount2: 'Lønn akkord',
    pieceWorkAmountFactor: 'Faktor for lønn akkord',
    pieceWorkCategory: 'Akkordkategori',
    pieceWorkCode: 'Akkordkode',
    pieceWorkCostCurrency: 'Valuta for kostnader fra akkord',
    pieceWorkDescription: 'Akkordbeskrivelse',
    pieceWorkIncAdjustments: 'Akkord inkl. justering',
    pieceWorkIncAdjustmentsFactor: 'Faktor for akkord inkl. justering',
    pieceWorkRate: 'Akkordsats',
    pieceworkSalary: 'Akkordlønn per time',
    pieceWorkWarning1: 'Det er ikke huket av for å kalkulere akkordarbeid i kalkulasjonsparameterne.',
    pieceWorkWarning2: 'Akkordlønn per time er ikke satt i kalkulasjonsparameterne.',
    placeholderDescription: 'Skriv inn beskrivelse...',
    placeholderName: 'Skriv inn navn...',
    placementOfParagraph: 'Plassering av avsnitt',
    planAndBuilding: 'etter plan- og bygningsloven (pbl) § 23-3',
    pointTemplates: 'Punktmaler',
    posNumber: 'Pos.nr.',
    postalAddress: 'Sted',
    postalCode: 'Postnr.',
    preview: 'Forhåndsvis',
    previewNotAvailable: 'Forhåndsvisning ikke tilgjengelig. Last ned for å åpne fil.',
    previousPage: 'Gå til forrige side',
    price: 'Pris',
    priceCalculation: 'Priskalkyle',
    priceIncVat: 'Enhetspris inkl. mva',
    priceIsLocked: 'Lås',
    pricePerSquareMeter: 'Pris per kvadratmeter',
    pricePerUser: 'Pris per bruker',
    pricePerUserRequired: 'Pris per bruker må fylles ut',
    priceRequired: 'Pris må fylles ut',
    print: 'Skriv ut',
    printing: 'Utskrift',
    printOfferForCustomer: 'Tilbud til kunde',
    printOfferForCustomerSimple: 'Tilbud til kunde forenklet',
    printPDF: 'Skriv ut PDF',
    procureProducts: 'Skaffevarer',
    product: 'Vare',
    productCatalogWarning: 'Opprett en katalog for å legge til varer',
    productInfo: 'Vareinformasjon',
    products: 'Varer',
    profit: 'Fortjeneste',
    profitAmount: 'Fortjeneste (kr)',
    profitOnSalary: 'Fortjeneste per lønnskrone',
    profitPercent: 'Fortjeneste (%)',
    profitPerHour: 'Fortjeneste per time',
    project: 'Prosjekt',
    projectDescription: 'Prosjektbeskrivelse',
    projectFinished: 'Prosjekt ferdigstilt',
    projectIdNotValid: 'Prosjekt-id er ikke gyldig',
    projectInformation: 'Prosjektinformasjon',
    projectName: 'Prosjektnavn',
    projectNoLongerFinalized: 'Prosjektet er ikke lenger ferdigstilt',
    projectNumber: 'Prosjektnr.',
    projectRegister: 'Prosjektregister',
    projectRequirements: 'Et prosjekt må ha et navn, gyldig dato, kategori og en kunde',
    projects: 'Prosjekter',
    projectType: 'Prosjekttype',
    propertyAndMatrikkelInfo: 'Eiendoms- og matrikkelinformasjon',
    propertyAndSite: 'Eiendom / Byggested',
    propertyUnitNumber: 'Bruksnummer',
    publish: 'Publiser',
    quantity: 'Antall',
    radioField: 'Radioknapper',
    rateUnit: 'Satsenhet',
    rateUnitAmount: 'Beløp',
    rateUnitHour: 'Timer',
    rateUnitMinutes: 'Minutter',
    reactivate: 'Reaktiver',
    reactivateProject: 'Reaktiver prosjekt',
    read: 'Lese',
    refreshDescriptions: 'Oppdater beskrivelser',
    refreshHourAndProductPrices: 'Oppdater priser på timer og matriell',
    refreshMarkupOnProcureProducts: 'Oppdater skaffevarer til std. påslag',
    refreshPackageContent: 'Oppdater pakke-spesifikasjoner',
    refreshPieceWorkRates: 'Oppdater akkordsatser',
    remove: 'Fjern',
    removeChoice: 'Fjern valg',
    removed: 'Fjernet',
    removeFile: 'Fjern fil',
    removePieceWork: 'Fjern akkord',
    replacementproduct: 'Skaffevare',
    required: 'Påkrevd',
    requireImage: 'Krev bilde',
    requiresLoginToAltinn: 'Krever innlogging til Altinn',
    reset: 'Nullstill',
    responsibilityInContructionProject: 'Ansvar i byggeprosjekt',
    responsibleApplicant: 'Ansvarlig Søker',
    responsibleCompany: 'Ansvarlig foretak',
    restore: 'Gjenopprett',
    revisionOverview: 'Revisjonsoversikt',
    revisions: 'Revisjoner',
    revoked: 'Opphevet',
    root: 'Start spesifikasjoen',
    routine: 'Rutine',
    row: 'Rad',
    salaryAmount: 'Lønn timer',
    salaryAndSocialCosts: 'Inkl. Sosiale kostnader',
    salaryPerUnit: 'Lønn per enhet',
    salaryPerUnitPieceWorkAndHours: 'Total lønn per enhet (akkord + timer)',
    salesPrice: 'Salgspris',
    samsvarserklaering: 'Samsvarserklæring',
    save: 'Lagre',
    saveInAltinn: 'Lagre i Altinn',
    savePDFOnProject: 'Lagre PDF på prosjekt',
    search: 'Søk',
    searchBy: 'Søk etter',
    searchFor: 'Søk etter',
    searchIn: 'Søk i',
    sectionNo: 'Seksjonsnummer',
    seeMore: 'Se mer',
    selectCaseManager: 'Velg saksbehandler',
    selectColumnLayout: 'Velg kolonneoppsett',
    selected: 'Valgt',
    selectedRootError: 'Root kan ikke være markert når du skal legge linjer i nytt avsnitt! Fjern øverste markering :',
    selectExisting: 'Velg eksisterende',
    selectFileTypesThisFolderShouldBeDefaultFor: 'Velg filtyper denne mappen skal være standard for',
    selectLicenseValidityPeriode: 'Velg lisensens gyldighetsperiode',
    selectUsecases: 'Velg bruksområde:',
    sendActivationEmail: 'Send aktiveringsmail',
    sendActivationMail: 'Send aktiveringsmail',
    sentFromFieldSystem: 'Sendt fra app',
    setAsActive: 'Sett som aktiv',
    setIndividualValidityDateUserLicense: 'Sett individuell gyldighetsdato for brukerlisens',
    setNewPassword: 'Sett nytt passord',
    settings: 'Innstillinger',
    show: 'Vis',
    showInHandheld: 'Vis i håndholdt',
    showSideMenu: 'Vis sidemeny',
    showTotals: 'Vis totalbildet',
    showVersionInfo: 'Vis versjoninformasjon på PDF',
    signatureField: 'Signatur',
    signedBy: 'Signert av',
    signingHeader: 'Signering',
    signingInfo:
      'For å signere må skjemaet først valideres, for å sjekke at det oppfyller kravene for innsending. Avdekkes det ingen vesentlige feil, vil du få mulighet til å signere og sende inn. Det er også mulig å lagre skjemaet i Altinn før det sendes inn',
    site: 'Anlegg',
    siteAddress: 'Anleggsadresse',
    siteInformation: 'Anleggsinformasjon',
    sites: 'Anlegg',
    socialCostsFactor: 'Faktor for Sosiale kostnader',
    somethingWentWrong: 'Noe gikk galt!',
    refreshPage: 'Last inn siden på nytt',
    somethingWentWrongError: 'Noe gikk galt, prøv igjen.',
    sort: 'Sorter',
    sosialCosts: 'Sosiale kostnader',
    specification: 'Spesifikasjon',
    specificationUpdated: 'Spesifikasjonen er nå oppdatert',
    spesificationSelectFieldsToAddDiscount: 'Du må markere felter i spesifikasjonen for å legge til rabatt og påslag',
    squareMeter_short: 'm2',
    squareMeter: 'Kvadratmeter',
    start: 'Start',
    startDate: 'Startdato',
    status: 'Status',
    stdParamName: 'Navn på metode',
    stdParams_short: 'Std. parametere',
    stdParams: 'Standardparametere',
    stdText_short: 'Std.tekst',
    stdText: 'Standardtekst',
    subcontract: 'Underentreprise',
    sumUnitPrice: 'Samlet enh.pris',
    supplier: 'Leverandør',
    supplierName: 'Leverandør',
    suppliers: 'Leverandører',
    surname: 'Etternavn',
    table: 'Tabell',
    tableField: 'Tabell',
    takeBreakAndPlay: 'Vi vet hvor frustrerende det kan være. Ta deg en liten pust i bakken og spill en runde.',
    technicalInformation: 'Teknisk beskrivelse',
    telephoneNumber: 'Telefonnummer',
    telephoneNumbers: 'Telefonnummer',
    templateCatalog: 'Mal-katalog',
    templateInformation: 'Malinformasjon',
    tenant: 'Cordelkunde',
    tenantAdmin: 'Tenantadmin',
    tenantAlreadyExists: 'Tenant finnes allerede i organisasjonen',
    tenantDeleted: 'Tenant ble slettet',
    tenantId: 'Tenant-id',
    tenants: 'Tenants',
    text: 'Tekst',
    textField: 'Tekstfelt',
    theApplication: 'Søknaden',
    theCompany: 'firmaet',
    title: 'Tittel',
    total: 'Total',
    totalAmount: 'Totalt kalkulert',
    totalAmountChecklists: 'Totalt antall sjekklister',
    totalCost: 'Total selvkost',
    totalCosts: 'Totale kostnader',
    totalCustomerMaterialCost: 'Kundens totalpris materiell',
    totalCustomerWorkCost: 'Kundens totalpris arbeid',
    totalMaterialCost: 'Total selvkost materiell',
    totalPartsSurcharge: 'Sum deler',
    totalProfit: 'Total fortjeneste',
    totalProfitPercent: 'Total fortjeneste',
    totals: 'Totaler',
    totalsMaterialWork: 'Materiell og arbeid samlet',
    totalUnitPrice: 'Total enhetspris',
    totalVatAmount: 'Totalt inkl. mva',
    totalWorkCost: 'Total selvkost arbeid',
    travelCostAmount: 'Transport/diett',
    travelCostAmount2: 'Lønn reise',
    type: 'Type',
    under: 'Under',
    unit: 'Enhet',
    unitPrice: 'Enhetspris',
    unitPricesPosNumber: 'Enhetspriser totalt med posisjonsnummer',
    unknown: 'Ukjent',
    unknowNodetype: 'Ukjent nodetype',
    unLockPrice: 'Lås opp pris',
    update: 'Oppdater',
    updateParams: 'Oppdateringsparametere',
    uploadCsvUsersInstructions:
      'For at filen skal lastes opp riktig, må den første linjen i filen være en overskrift bestående av',
    uploadFromCsvFile: 'Last opp fra CSV-fil',
    uploadOneFileError: 'Det er kun mulig å laste opp én fil av gangen.',
    uploadUsersFromACsvFile: 'Last opp brukere fra en csv fil.',
    useNo: 'Bruksnr.',
    user: 'Bruker',
    userActivated: 'Bruker aktivert',
    userCountInvalid: 'Antall brukere må være 0 eller flere',
    userInformation: 'Brukerinformasjon',
    userLicense: 'Brukerlisens',
    userLicenses: 'Brukerlisenser',
    username: 'Brukernavn',
    userRole: 'Brukerrolle',
    userRoles: 'Brukerroller',
    users: 'Brukere',
    userSettings: 'Brukerinnstillinger',
    userType: 'Brukertype',
    userTypeRequired: 'Brukertype må velges',
    userWillReceiveMail:
      'Dersom du har en bruker i vårt system, vil du nå motta en e-post fra oss. Følg instruksjonene i denne e-posten.',
    validFrom: 'Gyldig fra',
    validTo: 'Gyldig til',
    vatAmount: 'Merverdiavgift',
    vendor: 'Grossist',
    vendorDiscountPercent: 'Rabatt fra leverandør',
    vendorDiscountSource: 'Rabatt fra leverandør',
    vendorInfo: 'Leverandørinformasjon',
    vendorNumber: 'Leverandørnr.',
    vendors: 'Leverandører',
    version: 'Versjon',
    versions: 'Versjoner',
    viewFullTable: 'Klikk for å se hele tabellen',
    viewing: 'Visning',
    volume: 'Volum',
    volumeUnit: 'Enhetsvolum',
    wasAdded: 'ble lagt til',
    weight: 'Vekt',
    weightUnit: 'Enhetsvekt',
    whitespace: ' ',
    withinValidLicenseDate: 'Dato må være innenfor lisensens gyldighetstid',
    work: 'Arbeid',
    workAmountFactor: 'Faktor for totalt kalkulert',
    workCost: 'Kostnader arbeid',
    workCostFactor: 'Faktor for selvkost',
    workCostPrice_short: 'Selvkost arb.',
    workCostPrice: 'Selvkost',
    workCostPriceOfferTable: 'Selvkost enh. arbeid',
    workCostPricePerUnit: 'Selvkost per enhet',
    workCostSum: 'Sum selvkost arbeid',
    workCustomerAmount: 'Beløp arbeid eks. mva',
    workCustomerPrice: 'Enh.pris arbeid eks. mva',
    WorkDiscountPercent: 'Rabatt på arbeid i prosent',
    workMarkupFactor: 'Faktor for påslag',
    workMarkupPercent_short: 'Påslag matr.',
    WorkMarkupPercent: 'Påslag på arbeid i prosent',
    workMarkupPerUnit: 'Påslag per enhet',
    workMarkupSource: 'Grunnlag for påslag på arbeid',
    workNetCost: 'Lønnskostnad',
    workNetCostFactor: 'Faktor for lønnskostnad',
    workPrice: 'Pris for arbeid',
    workProfitPercent: 'Fortjeneste arbeid (%)',
    workTravelCostAmountFactor: 'Faktor for lønn reise',
    wouldYouLikeToincludeRoutinesParagraph: 'Vil du inkludere rutinens avsnitt?',
    wouldYouLikeToOpen: 'Ønsker du å åpne?',
    wouldYouLikeToOpenIt: 'Ønsker du å åpne den?',
    wouldYouLikeToRestore: 'Ønsker du å gjenopprette?',
    wouldYouLikeToRestoreIt: 'Ønsker du å gjenopprette den?',
    write: 'Skrive',
    wrongFileTypeError: 'Feil filtype. Du kan kun laste opp xml-filer.',
    wrongUrl: 'Nettadressen er feil eller ikke tilgjengelig. Gå tilbake og prøv igjen.',
    wrongUsernameOrPassword: 'Du har angitt feil brukernavn eller passord',
    yardNo: 'Gårdsnummer',
    yes: 'Ja',
    yesDeletePermanent: 'Ja, slett permanent',
  },
  sv: {
    addNewUsers: 'Lägg till nya användare',
    addExistingUsers: 'Lägg till befintliga användare',
    searchForUsers: 'Sök efter användare',
    access: 'Åtkomst',
    accessLevel: 'Åtkomstnivå',
    activate: 'Aktivera',
    activated: 'Aktiverad',
    activateUser: 'Aktivera användare',
    activationMailSent: 'Aktiveringsmail sänt',
    active: 'Aktiv',
    activeRevision: 'Aktiv revision',
    activeSince: 'Aktiv sen',
    add: 'Skapa',
    addAndSendActivationMail: 'Skapa och skicka aktiveringsmail',
    addAreaOfResponsibility: 'Lägg till nytt ansvarsområde',
    addCustomerBeforeOfferWarning: 'Skapa kund innan du skapar en ny offert',
    addExisting: 'Lägg till befintliga',
    additionalType: 'Tilläggstyp',
    additions: 'Tillägg',
    addNew: 'Lägg till',
    addNewCatalog: 'Lägg till ny katalog',
    addNewCompany: 'Skapa nytt företag',
    addNewContactPerson: 'Lägg till ny kontaktperson',
    addNewCustomer: 'Skapa ny kund',
    addNewFromTemplate: 'Lägg till ny från mall',
    addNewPackage: 'Skapa nytt paket',
    addNewPerson: 'Skapa ny person',
    addNewProperty: 'Lägg till ny egendom',
    addNewSite: 'Skapa ny anläggning',
    addParamsBeforeOfferWarning: 'Skapa en parameter innan du skapar en ny offert',
    addPiecework: 'Lägg till ackord',
    address: 'Adress',
    addUserRoleFirst: 'Lägg till användarroll först',
    addVendor: 'Skapa ny leverantör',
    adjustments: 'Justeringar',
    adjustPieceWork: 'Justera ackord',
    adjustTo: 'Justera till',
    admin: 'Admin',
    administration: 'Administrering',
    advancedPriceCalculation: 'Avancerad prisberäkning',
    agreement: 'Avtal',
    all: 'Allt',
    allowComments: 'Tillåt kommentarer',
    altinnLoggedIn: 'Du är inloggad i Altinn.',
    amount: 'Avrundad',
    amountOfPoints: 'Antal punkt',
    amountOfUsers: 'Antal användare',
    aPDFOfTheManualWasAddedToTheProject: 'En PDF av manualen har lagts till i projektet',
    application: 'Ansökan',
    applications: 'Applikationer',
    applicationSavedInAltinn: 'Din ansökan / deklaration har lagts i Altinn',
    approve: 'Godkänna',
    approved: 'Godkänd',
    approveVersion: 'Godkänna version',
    areaOfResponsibility: 'Ansvarsområde',
    areYouSure: 'Är du säker?',
    areYouSureFinalizeChecklist:
      'Om du väljer att avsluta checklistan kan du inte längre göra ändringar. Är du säker på att du vill avsluta checklistan?',
    areYouSureYouWantToDelete: 'Är du säker på att du vill radera?',
    around: 'Runt',
    as: 'Som',
    asDeleted: 'Som raderad',
    attachments: 'Bilagor',
    available: 'Tillgänglig',
    availableExternally: 'Tillgänglig externt',
    availables: 'Tillgängliga',
    averageSalary: 'Genomsnittlig timlön',
    back: 'Tillbaka',
    base: 'Bas',
    basicRegister: 'Grundregister',
    blank: 'Tom',
    buildingElement: 'Bygnadsdel',
    buildingElements: 'Byggnadsdelar',
    calculatePieceWork: 'Beräkna ackordsarbete',
    calculatePieceWorkWarning1:
      'Beräkning av ackordarbete i beräkningsparametrarna är inte markerad. Skall ackordskostnader beräknas på',
    calculatePieceWorkWarning2: ', måste detta vara avmarkerat i beräkningsparametrarna.',
    calculatePieceWorkWarning3:
      'Timlön är inte tillagd i beräkningsparametrarna. För att kunna beräkna ackordskostnader, samt justera kostnad och ersättning för arbete, måste den genomsnittliga ackordslönen sättas i beräkningsparametrarna och vara större än 0.',
    calculationType: 'Beräkningstyp',
    cancel: 'Avbryt',
    cannotDeleteASignedApplication: 'Kan inte radera en signerad ansökan',
    cannotDeleteCompletedChecklist: 'Kan inte radera en ifylld checklista',
    cannotDeleteStartedChecklist: 'Kan inte radera en påbörjad checklista',
    cannotDeleteTenantWithUsers: 'Kan inte radera tenant med associerad användare',
    cantDeletePersonError: 'Personen är associerad med en kund och kan inte raderas.',
    caseManager: 'Handläggare',
    catalog: 'Katalog',
    catalogs: 'Kataloger',
    catalogType: 'Katalogtyp',
    categories: 'Kategorier',
    category: 'Kategori',
    categoryOrAgreement: 'Kategori eller avtal',
    chainAdminPrivileges:
      'Eftersom du har adminstratör rättigheter för flera kedjor, är det nödvändigt att välja vilken kedja du önskar att knyta den offentliga katalogen mot.',
    change: 'Ändra',
    changeChapterWarning: 'Ändringar du gör på den här sidan kommer att träda i kraft överallt där kapitlet används!',
    changeCustomer: 'Byt kund',
    changePlacement: 'Byt placering',
    changesHaveBeenMade: 'Ändringar har gjorts på',
    changesWasSaved: 'Ändringarna sparades',
    chapter: 'Kapitel',
    checkboxField: 'Avkryssningsruta',
    checkForm: 'Kontrollera schema',
    checklist: 'Checklista',
    checklists: 'Checklistor',
    checklistSettingsDescription:
      'Välj vilken information som ska visas på checklistan och om den ska synas i handhållna system.',
    checklistTemplate: 'Checklistemal',
    checklistTemplates: 'Checklistemallar',
    checkpoint: 'Checklistepunkt',
    checkpointTemplate: 'Checklistepunktmall',
    checkpointTemplates: 'Checklistepunktmallar',
    choose: 'Välj',
    chooseCompany: 'Välj företag',
    chooseCustomer: 'Välj kund',
    chooseCustomerType: 'Välj kundtyp',
    chooseFilter: 'Välj filtrering',
    chooseModulePackage: 'Välj modulpaket',
    chooseNodesToUpdate: 'Välj vad i specifikationen som ska uppdateras',
    chooseOrganization: 'Välj organisation',
    choosePackageCatalogToCopyTo: 'Välj katalog som paketet ska kopieras till',
    chooseParams: 'Välj beräkningsmetod',
    choosePieceworkCategory: 'Välj ackordkategori',
    chooseRevision: 'Välj revision',
    chooseSite: 'Välj anläggning',
    chooseSorting: 'Välj sortera',
    chooseTenant: 'Tenant måste väljas',
    chooseType: 'Välj typ',
    chooseUnit: 'Välj enhet',
    chooseUser: 'Välj användare',
    chooseValidFromDate: 'Välj giltigt från-datum',
    chooseValidToDate: 'Välj giltigt till-datum',
    chooseWhatToAdjust: 'Välj vad som ska justeras',
    city: 'Stad',
    clearFilter: 'Töm filter',
    close: 'Stäng',
    closeSideMenu: 'Stäng sidmeny',
    code: 'Kod',
    collectiveAdjustment: 'Tariffpåslag',
    collectiveAdjustmentAmount: 'Belopp tillegg',
    collectiveAdjustmentFactor: 'Faktor för tarifftillägg',
    collectiveAdjustmentPercent: 'Tillegg (%)',
    column: 'Kolumn',
    columnLayout: 'Inställning kolumn',
    columns: 'Kolumner',
    comment: 'Kommentar',
    companies: 'Företag',
    company: 'Företag',
    companyinformation: 'Företagsinformation',
    companyName: 'Företagsnamn',
    companyNameRequired: 'Företagsnamn måste fyllas i',
    complete: 'Avsluta',
    completed: 'Färdig',
    completedInFieldSystem: 'Skickat från Cordel UTE',
    completedInFieldSystemAndApproved: 'Färdig UTE och godkänd',
    completedMultiple: 'Avslutade',
    compositePoint: 'Anpassad',
    confirmPassword: 'Bekräfta lösenord',
    confirmPasswordPlease: 'Vänligen bekräfta lösenordet',
    conflict: 'Konflikt',
    connectCatalog: 'Knyt katalogen till en eller flera av dina kedjor:',
    connectPersonToEmployee: 'Länka medarbetare till person i personregistret',
    contact: 'Kontakt',
    contactInformation: 'Kontaktinformation',
    contactPerson: 'Kontaktperson',
    contactpersons: 'Kontaktpersoner',
    contactResponsibleIn: 'Kontakta ansvarig i',
    contactWasChanged: 'Kontakten ändrades',
    content: 'Innehåll',
    copy_noun: 'Kopia',
    copy: 'Kopiera',
    copyPackage: 'Kopiera paket',
    cost: 'Självkostnad',
    costAndSurcharge: 'Självkostnad + tillägg översikt',
    costPrice: 'Självkostnad',
    costProcureProducts: 'Självkostnad prisbokvaror',
    costsAndWastage: 'Omkostnader/svinn',
    costStockProducts: 'Självkostnad prisbokvaror',
    couldNotFindEditPoint: 'Kunde inte hitta punkt att redigera. Stäng fönstret och försök igen.',
    couldNotLoadImages: 'Kunde inte ladda bilder. Ladda om sidan och försök igen.',
    couldNotSignApplicationError: 'Något gick fel. Kunde inte underteckna ansökan / deklaration.',
    couldNotSignError: 'Något gick fel. Kunde inte signera',
    country: 'Land',
    coverageOnSalary: 'Täckning på lön',
    coveragePerHour: 'Täckning per timme',
    coverageTotal: 'Total täckning',
    create: 'Skapa',
    createAndSendActivationMail: 'Skapa och skicka aktiveringspost',
    createBlank: 'Skapa tom',
    createCopy: 'Skapa kopia',
    created: 'Skapat',
    createdBy: 'Skapad av',
    createImageParagraph: 'Skapa bildparagraf',
    createNewOffer: 'Skapa en ny offert',
    createNewRevision: 'Gör en ny revision',
    createTextParagraph: 'Skapa textparagraf',
    cubicMeter_short: 'm3',
    cubicMeter: 'Kubikmeter',
    currency: 'Valuta',
    currentLicenses: 'Nuvarande licenser',
    currentlyUsing: 'Använder',
    customer: 'Kund',
    customerAmount: 'Sum',
    customerAmountIncVat: 'Kundens totalbelopp inkl moms',
    customerIdNotValid: 'Kund-ID är inte giltigt.',
    customerInfo: 'Kundinformation',
    customerName: 'Kundnamn',
    customerNumber: 'Kundnr.',
    customerNumberOrOrganizationAlreadyExist: 'Kundnummer eller organisation finns redan',
    customerNumberRequired: 'Kundnr. krävs',
    customerPrice: 'Kundens pris eks. mva',
    customers: 'Kunder',
    customerTotalAmount: 'Kundens totala belopp',
    customerType: 'Kundtyp',
    customerUnitPrice: 'Kundens styckepris',
    dashboard: 'Dashbord',
    date: 'Datum',
    dateAsc: 'Äldst till nyast',
    dateDesc: 'Nyast till äldst',
    dateEarliestValid: 'Tidigaste giltiga datum',
    dateField: 'Datum och tid',
    dateIsPastValid: 'Giltig till-datum kan inte vara mer än 2 år fram i tid',
    dateLatestValid: 'Senaste giltiga datum',
    dateMustBeWithinValidLicenseDate: 'Datum måste vara inom giltig licensdatum',
    dates: 'Datum',
    day: 'Dag',
    deactivated: 'Inaktiverad',
    deactivatedLicenses: 'Inaktiverade licenser',
    declarationAndSigningHeader: 'Deklaration och signering',
    declarationApplies: 'Deklarationen gäller',
    declarationCheckboxKontroll:
      'Ansvarig kontrollerande försäkrar en oberoende position, jf. SAK10 § 14-1, och vill redogöra för ändringar som kan påverka den oberoende positionen jf. SAK10 §12-5',
    declarationCheckboxProsjekterende:
      'Ansvarig projekterande deklarerar att projekteringen ska vara planerad, genomförd och kvalitetssäkrad i samsvar med pbl jf. SAK10 §12-3',
    declarationCheckboxUtfoerende:
      'Ansvarig utförande deklarerar att arbetet inte ska starta innan det föreligger kvalitetssäkrat produktionsunderlag för respektive del av genomförandet jf SAK10 § 12-4',
    declarationHeader: 'Deklaration',
    declarationPenaltyInfo:
      'Vi känner till reglerna om straff och saktioner efter plan- och byggningslagen, och att felaktiga upplysningar kan föra till reaktioner.',
    declarationSAKInfo: 'Vi förpliktigar oss till att ha riktig kompetans i byggprojektet, jf. SAK10 kapitel 10 og 11.',
    declarationSignedAndSubmitted: 'Deklarationen är signerad och insänd.',
    default: 'Standard',
    defaultCost: 'Standardkostnad',
    defaultCostCurrency: 'Standardkostnadsvaluta',
    defaultMaterialMarkup: 'Standardpåslag',
    defaultPoints: 'Standardpunkter',
    defaultPriceCurrency: 'Standardprisvaluta',
    defaultVendorDiscount: 'Standardleverantörsrabatt',
    DefaultVendorDiscountSource: 'Använd standardpåslag',
    delete: 'Radera',
    deleted: 'Raderad',
    deletedChecklist: 'Raderad checklista',
    deletedChecklistInfo: 'Den här checklista är raderad!',
    deletedChecklistTemplates: 'Raderade checklista mallar',
    deletedCompanies: 'Raderade företag',
    deletedCustomers: 'Raderade kunder',
    deletedManualDraftChaptersRegister: 'Raderade manualkapitel',
    deletedManualDraftsRegister: 'Raderade handboksutkast',
    deletedPersons: 'Raderade personer',
    deletedSites: 'Raderade anläggningar',
    deletedVendors: 'Raderade leverantörer',
    department: 'Avdelning',
    description: 'Beskrivning',
    descriptionIsRequired: 'Beskrivelse är nödvändigt',
    detailsMaterial: 'Detaljer material',
    detailsWork: 'Detaljer arbete',
    discount: 'Rabatt',
    discountAndSurcharge: 'Rabatt och påslag',
    discountPercent: 'Rabatt kund (%)',
    discountPerUnit: 'Rabatt per enhet',
    documents: 'Dokumenter',
    doYouWantToResetChanges: 'vill du återställa alla ändringar?',
    draft: 'Förslag',
    drafts: 'Förslag',
    ean: 'EAN',
    edit: 'Ändra',
    editCatalogs: 'Redigera kataloger',
    editCompany: 'Redigera företag',
    editContactInfo: 'Redigera kontaktinformation',
    editCustomerInfo: 'Redigera kundinformation',
    editEmail: 'Redigera e-post',
    editInformation: 'Redigera information',
    editOffer: 'Redigera erbjudande',
    editPackage: 'Redigera paket',
    editPackageGlobal: 'Ändra paket globalt',
    editPackageInfo: 'Redigera paketinformation',
    editPerson: 'Redigera person',
    editPhonenumber: 'Redigera telefonnummer',
    editPriceCalculation: 'Redigera kalkyl',
    editProduct: 'Redigera vara',
    editVendor: 'Redigera leverantör',
    editVendorInfo: 'Redigera leverantörsinformation',
    email: 'E-post',
    employees: 'Anställda',
    endDate: 'Slutdatum',
    entrepreneur: 'Entreprenör',
    environmentTax: 'Miljöskatt',
    erklaeringOmAnsvarsrett: 'Ansvarsrettsdeklaration',
    event: 'Händelse',
    example: 'Exempel',
    excludeFromSum: 'Sammanfatta inte',
    expired: 'Utgången',
    export: 'Export',
    extendTo: 'Förläng till',
    externalCode: 'Externt nr.',
    factorBasis: 'Grundberäkning av faktor 1',
    farmNo: 'Gård nr.',
    favorite: 'Favorit',
    file: 'Fil',
    fileType: 'Filtyp',
    fileTypeHasExistingDefaultFolder: 'Filtyp har redan en standardmapp',
    fileTypeMustBeImage: 'Filtyp måste vara jpeg, jpg eller png.',
    fillInAllRequiredFields: 'Fyll i alla obligatoriska fält',
    fillInAllRequiredFieldsOrMarkAsNotApplicable: 'Fyll i alla obligatoriska fält eller markera som ej tillämplig',
    filter: 'Filtrera',
    finalize: 'Avslut',
    finalizeChecklist: 'Avsluta checklista?',
    finalized: 'Avslutad',
    finishProject: 'Komplett projekt',
    firstName: 'Förnamn',
    firstNameRequired: 'Förnamn måste fyllas ut',
    fixedPrice: 'Fast pris',
    folder: 'Mapp',
    forename: 'Förnamn',
    forgotPassword: 'Glömt ditt lösenord?',
    formatACatalogIsNeededBeforeCreatingAnX: 'En katalog behövs innan du kan skapa en {0}',
    formatACatalogIsNeededBeforeCreatingAX: 'En katalog behövs innan du kan skapa en {0}',
    formatAddAfterX: 'Lägg till efter {0}',
    formatAddExistingX: 'Lägg till befintligt {0}',
    formatAddNewX: 'Lägg till ny {0}',
    formatAddX: 'Lägg till {0}',
    formatAddXAfterX: 'Lägg till {0} efter {1}',
    formatAddXBeforeX: 'Lägg till {0} före {1}',
    formatAddXOfXToX: 'Lägg till {0} av {1} till {2}',
    formatAddXTo: 'Lägg till i {0}',
    formatAddXToX: 'Lägg till {0} i {1}',
    formatAllXX: 'Alla {0} {1}',
    formatAreYouSureYouWantToDeleteX: 'Är du säker på att du vill radera {0}?',
    formatAreYouSureYouWantToPermanentlyDeleteX: 'Är du säker på att du vill radera permanent {0}?',
    formatAXIsNeededBeforeCreatingAnX: 'En {0} behövs innan du kan skapa en {1}',
    formatAXIsNeededBeforeCreatingAX: 'En {0} behövs innan du kan skapa en {1}',
    formatCannotPreviewX: 'Kan inte förhandsgranska {0}',
    formatCatalogWillBeAvailableForX: 'Denne katalogen kommer att vara tillgänglig för medlemmar i {0}',
    formatChoosePlacementForX: 'Välj placering för {0}',
    formatChooseX: 'Välja {0}',
    formatCouldNotAddXToX: 'Kunde inte lägga till {0} till {1}',
    formatCouldNotConnectXToX: 'Kunde inte ansluta {0} till {1}',
    formatCouldNotDeleteX: 'Kan inte radera {0}',
    formatCouldNotDeleteXConnectedToX: 'Kan inte radera {0} som ansluten till {1}',
    formatCouldNotDeleteXContainingX: 'Kan inte radera {0} som innehåller {1}',
    formatCouldNotDeleteXFromX: 'Kan inte radera {0} från {1}',
    formatCouldNotFindAnyX: 'Kunde inte hitta några {0}',
    formatCouldNotGetInformationAboutX: 'Kunde inte hämta information om {0}',
    formatCouldNotLoadX: 'Kunde inte ladda {0}',
    formatCouldNotPreviewX: 'kunde inte förhandsgranska {0}',
    formatCouldNotRestoreX: 'Kunde inte återställa {0}',
    formatCouldNotUpdateX: 'Kunde inte uppdatera {0}. Prova igen.',
    formatCreateANewVersionOfAnExistingX: 'Upprätta ny versjon av en befintlig {0}',
    formatCreateANewVersionOfX: 'Upprätt en ny version av {0}',
    formatCreateANewX: 'Skapa ett nytt {0}',
    formatCreateNewX: 'Skapa ny {0}',
    formatCreateX: 'Skapa {0}',
    formatDeletedX: 'Raderade {0}',
    formatDeleteX: 'Radera {0}',
    formatDeleteXFromX: 'Radera {0} från {1}',
    formatDownloadX: 'Ladda ner {0}',
    formatEditX: 'Redigera {0}',
    formatEnterX: 'Skriv {0}...',
    formatErrorDownloadingX: 'Fel vid nedladdning av {0}. Försök igen.',
    formatErrorXIsX: 'Fel ved {0} - {0} är {1}.',
    formatExtendTheX: 'Förlänga {0}',
    formatExtendX: 'Utöka {0}',
    formatGoToNextX: 'Gå till nästa {0}',
    formatGoToPreviousX: 'Gå till föregående {0}',
    formatIfYouDeleteXItCanNotBeUndone: 'Om du raderar {0}, kan den inte ångras. Vi frågar därför igen:',
    formatManuallyAddX: 'Lägga till användare manuellt',
    formatMaxAllowedXIsX: 'Max tillåtna {0} är {1}',
    formatMinAllowedXIsX: 'Lägsta tillåtna {0} är {1}',
    formatMoveX: 'Flytta {0}',
    formatMoveXToX: 'Flytta {0} till {1}',
    formatNewXAdded: 'Ny {0} tillagd',
    formatNoX: 'Inga {0}',
    formatNoXAvailable: 'Inga {0} tillgängligt',
    formatNoXChosen: 'Inga {0} valda',
    formatOrInDefaultFolderForX: 'Eller i standardmapp för {0}',
    formatProblemCreatingX: 'Problem med att skapa {0}',
    formatPutXOnX: 'Sätt {0} på {1}',
    formatRemoveX: 'Ta bort {0}',
    formatResetX: 'Återställ {0}',
    formatSelectAXToPreview: 'Välj en {0} för att förhandsgranska',
    formatSelectedX: 'Utvald {0}',
    formatSelectX: 'Välj {0}',
    formatSelectXToCreateAnX: 'Välj {0} för att skapa en {1}',
    formatSelectXToCreateAX: 'Välj {0} för att skapa en {1}',
    formatSelectXToCreateX: 'Välj {0} för att skapa {1}',
    formatThereExistsNoX: 'Det finns inga {0}',
    formatThereExistsNoXForX: 'Det finns inga {0} för {1}',
    formatTypeInX: 'Skriv in {0}...',
    formatUploadNewX: 'Ladda upp ny {0}',
    formatUploadX: 'Ladda upp {0}',
    formatWasAdded: '{0} lades till',
    formatXAlreadyAdded: '{0} har redan lagts till',
    formatXCannotExceedX: '{0} får inte överskrida {1}',
    formatXDoesNotHaveAnX: '{0} har ingen {1}',
    formatXDoesNotHaveAX: '{0} har ingen {1}',
    formatXDoesNotHaveX: '{0} har inte {1}',
    formatXFailedToImport: '{0} importerades inte',
    formatXFromX: '{0} från {1}',
    formatXGotImported: '{0} importerades',
    formatXHyphenX: '{0} – {1}',
    formatXIsAlreadyConnectedToTheX: '{0} är redan ansluten till {1}',
    formatXIsAlreadyConnectedToX: '{0} är redan ansluten till {1}',
    formatXIsNotValid: 'Formatet {0} är inte giltigt',
    formatXIsRequired: '{0} krävs',
    formatXMarkedAsX: '{0} markerades som {1}',
    formatXMissingX: '{0} saknar {1}',
    formatXMustBeAValidX: '{0} måste vara en giltig {1}',
    formatXNeedsX: '{0} behöver {1}',
    formatXNeedsXAndX: '{0} behöver {1} och {2}',
    formatXWasAdded: '{0} lades till',
    formatXWasConnectedToX: '{0} var ansluten till {1}',
    formatXWasCopied: '{0} kopierades',
    formatXWasDeleted: '{0} raderades',
    formatXWasDisconnectedFromX: '{0} kopplades bort från {1}',
    formatXWasNotAdded: '{0} kunde inte läggas till',
    formatXWasNotConnectedToX: '{0} var inte ansluten till {1}',
    formatXWasNotDisconnectedFromX: '{0} kopplades inte bort från {1}',
    formatXWasPublished: '{0} publicerades',
    formatXWasRestored: '{0} återställdes',
    formatXWasSaved: '{0} sparades',
    formatXWasSavedInX: '{0} sparades i {1}',
    formatXWasUpdated: '{0} uppdaterades',
    formatXWasUploaded: '{0} uppladdad',
    formatXWithXXAlreadyExists: '{0} med {1} {2} finns redan',
    formatXXWasAdded: '{0} {1} lades till',
    formatYouNeedToAddAtleastOneXBeforeYouCanAddX: 'Du måste lägga till minst en {0} innan du kan lägga till {1}',
    formatYouNeedToAddXBeforeYouCanAddX: 'Du måste lägga till {0} innan du kan lägga till {1}',
    freeText: 'Fri text',
    freight: 'Frakt',
    fromAltinn: 'Från Altinn',
    fullName: 'Namn',
    general: 'Allmänt',
    generalContractor: 'Entreprenör',
    generalInformation: 'Generell information',
    getCorrectAccessAltinn: 'För att kunna lämna in deklarationen måste du ha rätt tillgång i Altinn.',
    getStartedWithCordelByggsok: 'Kom igång med Cordel Byggsök - Cordel Kund sidor',
    getStartedWithCordelKS: 'Kom igång med Cordel KS | HSE - Cordel Kund sidor',
    getXml: 'Hämta XML',
    goBack: 'Gå tillbaka',
    goodAfternoon: 'God eftermiddag',
    goodEvening: 'God kväll',
    goodMorning: 'God morgon',
    goTo: 'Gå till',
    handheld: 'Handhållen',
    headerField: 'Rubrik',
    hereYouWillFindByggsok: 'Här finner du all information du behöver för att komma igång med Cordel byggsök',
    hereYouWillFindKS: 'Här finner du all information du behöver för att komma igång med Cordel KS|HMS',
    hide: 'Dölj',
    history: 'Historia',
    hour_short: 'Tim',
    hour: 'Timme',
    hourCategory: 'Tidskategori',
    hourCategoryNr: 'Tidskategori nr.',
    identical: 'Identisk',
    ifNoPlacementIsSelectedXWillBePlacedOnTopLevel:
      'Om ingen position väljs kommer {0} att placeras på den översta nivån',
    import: 'Import',
    importNS3459: 'Importera prisförfrågan NS3459',
    include: 'Inkludera',
    includes: 'Innehåller',
    incomeCurrency: 'Valuta för intäkter',
    incSocialCostsFactor: 'Faktor för inkl sociala kostnader',
    indirectCosts: 'Indirekta kostnader',
    indirectCostsFactor: 'Faktor för indirekta kostnader',
    industry: 'Bransch',
    information: 'Information',
    informationField: 'Informationsfält',
    inProgress: 'Pågående',
    internal: 'Internt',
    internalInformation: 'Intern information',
    internals: 'Interna',
    itemsPerPackage: 'Antal per paket',
    keywords: 'Nyckelord',
    kilogram_short: 'kg',
    kilogram: 'Kilo',
    kilometer_short: 'Km',
    kilometer: 'Kilometer',
    knowledge: 'Kunskap',
    knowledgeBase: 'Kunskapsbas',
    last: 'Sist',
    lastName: 'Efternamn',
    lastNameRequired: 'Efternamn måste fyllas ut',
    lastUpdated: 'Senast ändrad',
    lastUpdatedBy: 'Senast ändrad av',
    latestProject: 'Senaste projektet',
    leaseNumber: 'Hyresnummer',
    license: 'Licens',
    licenseInformation: 'Licensinformation',
    licenses: 'Licenser',
    line: 'Rad',
    linebreak: 'Ny rad',
    listPrice: 'Listpris',
    litre_short: 'l',
    litre: 'Liter',
    loading: 'Laddar...',
    loadMore: 'Ladda mer',
    locked: 'Låst',
    lockPrice: 'Låst pris',
    log: 'Logg',
    loggedIn: 'Senast inloggad',
    loggedOutIn: 'Du blir utloggad om:',
    login: 'Logga in',
    loginWith: 'Logga in med',
    logo: 'Logo',
    logout: 'Logga ut',
    logs: 'Loggar',
    mainSubmissionNumber: 'Huvuddinsendingsnr.',
    manual: 'Manuell',
    manualDraft: 'Handboksmall',
    manualDraftRegister: 'Handbokregister för utkast av handbok',
    manualDrafts: 'Handboksmallar',
    manualDraftsRegister: 'Handboksmallar',
    manualHasNoChapters: 'Handbok har inga kapitel',
    manualHMS: 'HandbokHMS',
    manualId: 'Handbok-Id',
    manuals: 'Handböcker',
    manualsRegister: 'Handboksregister',
    markup: 'Påslag',
    markupBasis: 'Påslagsunderlag',
    markupPercent: 'Påslag (%)',
    markupPercentProcureProducts: 'Påslag tillbehör',
    markupPercentStockProducts: 'Tillägg prisbokvaror',
    matCost: 'Självkostnad material',
    matCostPricePerUnit: 'Självkostn. per enhet',
    matCostSum: 'Sum självkostnad mat.',
    matCustomerAmount: 'Belopp mat. exkl. mva',
    matCustomerPrice: 'Enh.pris mat. exkl. mva',
    material: 'Material',
    materialCostPrice: 'Självkostnad enh. mat.',
    MaterialDiscountPercent: 'Rabatt på material i procent',
    materialDiscountSource: 'Underlag för rabatt på material',
    materialMarkupPercent_short: 'Påslag matr.',
    MaterialMarkupPercent: 'Påslag på material i procent',
    materialMarkupSource: 'Underlag för påslag på material',
    materialPrice: 'Pris för material',
    materialsList: 'Materiallista',
    matMarkupPercent: 'Påslag material',
    matPriceInklMarkup: 'Pris inkl påslag',
    matProfitPercent: 'Förtjänst mat. (%)',
    matrikkelInformation: 'Matrikkelinformation',
    meter_short: 'm',
    meter: 'Meter',
    minute_short: 'Min',
    minute: 'Minut',
    minutes: 'Minuter',
    modified: 'Ändrad',
    modifiedBy: 'Ändrad av',
    module: 'Modul',
    moduleCode: 'Modulkod',
    moduleConnection: 'Modulkoppling',
    moduleName: 'Modulnamn',
    modulePackage: 'Modulpaket',
    modulePackageInformation: 'Modulpaketinformation',
    modulePackages: 'Modulpaket',
    modules: 'Moduler',
    more: 'Mer...',
    municipality: 'Kommun',
    municipalityCaseNumber: 'kommun ärendenummer',
    municipalityNo: 'Kommunkod',
    name: 'Namn',
    nameIsRequired: 'Namn måste fyllas ut',
    nameRequired: 'Namn måste fyllas ut',
    nb: 'Ops!',
    netCostPrice_short: 'Självkostn. matr.',
    netCostPrice: 'Nettokostnad',
    netCostPriceLocked: 'Lås självkostnadspris',
    newContactAdded: 'Ny kontakt lagt till',
    newTenantAdded: 'Ny tenant lagt till',
    next: 'Nästa',
    nextPage: 'Gå till nästa sida',
    no: 'Nej',
    noAbortAndCancel: 'Nej, ångra och avbryt',
    noAccessInAltinnTo: 'Du har inte tillgång till Altinn för',
    noAccessPage: 'Du har inte tillgång till den sidan.',
    noAltinnAccessForCompany:
      'Du har inte tillgång till Altinn för företaget. För att kunna lämna in deklarationen måste du ha rätt tillgång i Altinn. Kontakta ansvarig i företaget.',
    noCaseManagerSelected: 'Ingen saksbehandlare vald',
    noCatalog: 'Inga kataloger',
    noCatalogsExists: 'Det finns inga kataloger',
    noCatalogsExistsInstructions: 'För att fortsätta måste du först skapa en katalog.',
    noCatalogsForPackages:
      'Det finns inga kataloger för paket. För att kunna skapa ett paket måste det vara länkat till en befintlig katalog.',
    noCatalogsOnHourCategoryWarning:
      'Det finns inga kataloger för tidskategorier. För att kunna skapa en tidskategori måste den vara länkad till en befintlig katalog.',
    noCategoriesExistsInstructions: 'För att fortsätta måste du först skapa en kategori.',
    noCityInAltinn:
      'Fick ofullständig data från Altinn. Kontrollera/uppdatera företagets adress i Altinn / Brønnøysundregistret.',
    noContactPerson: 'Ingen kontaktperson tillagd',
    noContent: 'Inget innehåll',
    noCustomer: 'Ingen kund',
    noData: 'Ingen tillgänglig data',
    noDataInTable: 'Ingen tillgänglig data i datatabellen',
    noElementsOnThisPage: 'Inget element på denna sida',
    noEmail: 'Ingen e-post',
    noInformationAvailable: 'Ingen Information tillgänglig',
    noModuleInModulePackage: 'Ingen modul i modulpaket',
    none: 'Ingen',
    noOptionsText: 'Inga val motsvarar',
    noPersonSelected: 'Ingen person har valts',
    noPhoneNumber: 'Inget telefonnummer',
    noProjectAvailableInDashboard:
      'Här får du upp information om det senaste projektet du har varit inne på. Välj projekt från menyn eller gå till Projekt för att få upp information här.',
    noSubContent: 'Inget underinnehåll',
    notActive: 'Inte aktiv',
    note: 'Anteckning',
    notice: 'Notering',
    notloggedInToAltinn: 'Du är inte inloggad i Altinn.',
    noWriteAccess: 'Du har inte skrivrättigheter till den här sidan.',
    nscode: 'NS-kod',
    nsCode: 'NS-kod',
    nsCodes: 'NS-koder',
    nsSum: 'NSSum',
    number: 'Nummer',
    numberOfHours: 'Antal timmar',
    numberOfHoursProduction: 'Antal produktionstimmar',
    numberOfHoursTravel: 'Antal restimmar',
    numberOfSquareMeters: 'Antal kvadratmeter',
    numberShort: 'Nr.',
    numericField: 'Numeriskt',
    of: 'av',
    offer: 'Offert',
    offerDate: 'Offertdatum',
    offerDeadline: 'Sista anbudsdatum',
    offerInfo: 'Offertinformation',
    offerNumber: 'Offertnr.',
    offers: 'Offert',
    offerSettings: 'Erbjudandeinställningar',
    ok: 'Okej',
    onBehalfOf: 'på uppdrag av',
    oneOrMoreTypesNotAdded: 'En eller flera typer kunde inte läggas till',
    oneOrMoreTypesWasNotRemoved: 'En eller flera typer kunde inte tas bort',
    ongoing: 'Pågående',
    onlyCreatorOfTemplateCanHideInfo: 'Endast skaparen av mallen kan visa/dölja versionsinformation',
    open: 'Öppna',
    orderBy: 'Sortera efter',
    orDragFileHere: 'eller dra hit filen.',
    organization: 'Organisation',
    organizationDeleted: 'Organisationen raderades',
    organizationInformation: 'Organisationsinformation',
    organizationMissingName: 'Organisationens namn måste fyllas ut',
    organizationMissingNumber: 'Organisationsnummer måste fyllas ut',
    organizationNumber: 'Organisationsnummer',
    organizationRestored: 'Organisationen återställdes',
    organizations: 'Organisationer',
    organizationsCouldNotLoad: 'Kunde inte hämta in organisationer. Prova igen senare.',
    organizationsLogo: 'Organisationens logo',
    ourCustomerNumber: 'Egendefinierat kundnummer',
    ourDeclarationsAvailableAtCompliance: 'Våra deklarationer kommer att finnas tillgängliga på:',
    ourDeclarationsAvailableAtControl: 'Våra kontrolldeklarationer kommer att finnas tilgängliga på:',
    outcome: 'Resultat',
    overall: 'Samlad',
    overallTotals: 'Den övergripande bilden',
    overview: 'Översikt',
    package: 'Paket',
    packageCopyError:
      'Paketnummer måste vara unik per katalog. Kopiera paketet till en annan katalog eller ändra paketnummer.',
    packageInfo: 'Paketinformation',
    packageNumber: 'Paketnummer',
    packages: 'Paket',
    packageUnit: 'Paketenhet',
    packageWithoutFixedPrice: 'Detta paket har inte fast pris',
    page: 'Sida',
    pageBreak: 'Sidbrytning',
    paragraph: 'Paragraf',
    param: 'Standardparameter',
    params: 'Parametrar',
    partsSurcharge: 'Påslag delar',
    partsSurchargeAmount: 'Belopp delar',
    partsSurchargeBasis_short: 'Grl. delar',
    partsSurchargeBasis: 'Basdelar / kostnader',
    partsSurchargeMethod: 'Metod för påslag delar',
    partsSurchargePercent: 'Delar (%)',
    partyNo: 'Parti nr.',
    password: 'Lösenord',
    passwordMismatch: 'Lösenorden matchar inte',
    passwordRequirment: 'Lösenorden måste uppfylla lösenordskraven',
    passwordRequirmentsNotMet: 'Lösenordskrav ej uppfyllda',
    passwordSuccessfullyActivatedMessage:
      'Du har aktiverat din användare och kommer nu att omdirigeras till inloggningssidan.',
    passwordSuccessfullyResetMessage:
      'Du har återställt ditt lösenord och kommer nu att omdirigeras till inloggningssidan.',
    pdf: 'PDF',
    permanent: 'Permanent',
    person: 'Person',
    personIdNotValid: 'Person-ID är inte giltigt.',
    persons: 'Personer',
    phone: 'Telefon',
    pickFile: 'Välj fil',
    piece_short: 'Stk',
    piece: 'Styck',
    piecework: 'Ackordstariff',
    pieceWorkAmount: 'Ackordlön per enhet',
    pieceWorkAmount2: 'Löneavtal',
    pieceWorkAmountFactor: 'Faktor för löneackord',
    pieceWorkCategory: 'Ackordkategori',
    pieceWorkCode: 'Ackordkod',
    pieceWorkCostCurrency: 'Valuta för ackordskostnader',
    pieceWorkDescription: 'Ackordbeskrivning',
    pieceWorkIncAdjustments: 'Ackord inkl. Justering',
    pieceWorkIncAdjustmentsFactor: 'Faktor för ackord inkl Justering',
    pieceWorkRate: 'Ackordsats',
    pieceworkSalary: 'Ackordslön per timme',
    pieceWorkWarning1: 'Beräkning av ackordarbete i beräkningsparametrarna är inte markerad.',
    pieceWorkWarning2: 'Timlön är inte tillagd i beräkningsparametrarna.',
    placeholderDescription: 'Fyll i beskrivning...',
    placeholderName: 'Fyll i namn...',
    placementOfParagraph: 'Avsnittsplacering',
    planAndBuilding: 'efter plan- og bygglagen (pbl) (2010:900)',
    pointTemplates: 'Punktmaler',
    posNumber: 'Pos.nr.',
    postalAddress: 'Ort',
    postalCode: 'Postnummer',
    preview: 'Förhandsgranska',
    previewNotAvailable: 'Förhandsgranskning inte tillgänglig. Ladda ner för att öppna filen.',
    previousPage: 'Gå till föregående sida',
    price: 'Pris',
    priceCalculation: 'Priskalkyl',
    priceIncVat: 'Styckpris inkl moms',
    priceIsLocked: 'Lås',
    pricePerSquareMeter: 'Pris per kvadratmeter',
    pricePerUser: 'Pris per använder',
    pricePerUserRequired: 'Pris per använder måste fyllas ut',
    priceRequired: 'Pris måste fyllas ut',
    print: 'Skriv ut',
    printing: 'Skriv ut',
    printOfferForCustomer: 'Offert för kund',
    printOfferForCustomerSimple: 'Offert för kund förenklat',
    printPDF: 'Skriv ut PDF',
    procureProducts: 'Tillbehör',
    product: 'Vara',
    productCatalogWarning: 'Skapa en katalog för att lägga till varor',
    productInfo: 'Varuinformation',
    products: 'Varor',
    profit: 'Vinst',
    profitAmount: 'Förtjänst (kr)',
    profitOnSalary: 'Förtjänst per lönekrona',
    profitPercent: 'Förtjänst (%)',
    profitPerHour: 'Förtjänst per timme',
    project: 'Projekt',
    projectDescription: 'Projekt beskrivning',
    projectFinished: 'Projektet avslutat',
    projectIdNotValid: 'Projekt-id är inte giltigt',
    projectInformation: 'Projekt information',
    projectName: 'Projektnamn',
    projectNoLongerFinalized: 'Projektet är inte längre avslutat',
    projectNumber: 'Projekt nr.',
    projectRegister: 'Projektregister',
    projectRequirements: 'Ett projekt måste ha ett namn, giltigt datum, kategori och en kund',
    projects: 'Projekt',
    projectType: 'Projekttyp',
    propertyAndMatrikkelInfo: 'Egendoms- och matrikelinformation',
    propertyAndSite: 'Egendom / Byggplats',
    propertyUnitNumber: 'Bruksnummer',
    publish: 'Publicera',
    quantity: 'Antal',
    radioField: 'Radioknappar',
    rateUnit: 'Satsenhet',
    rateUnitAmount: 'Belopp',
    rateUnitHour: 'Timer',
    rateUnitMinutes: 'Minuter',
    reactivate: 'Återaktivera',
    reactivateProject: 'Återaktivera projekt',
    read: 'Läsa',
    refreshDescriptions: 'Uppdatera beskrivningar',
    refreshHourAndProductPrices: 'Uppdatera priser på timmar och material',
    refreshMarkupOnProcureProducts: 'Uppdatera inköpsartiklar med standardpåslag',
    refreshPackageContent: 'Uppdatera paketspecifikationer',
    refreshPieceWorkRates: 'Uppdatera ackordssatser',
    remove: 'Avlägsna',
    removeChoice: 'Ta bort val',
    removed: 'Tog bort',
    removeFile: 'Ta bort fil',
    removePieceWork: 'Ta bort ackord',
    replacementproduct: 'Tillbehör',
    required: 'Obligatorisk',
    requireImage: 'Kräv foto',
    requiresLoginToAltinn: 'Kräver inloggning till Altinn',
    reset: 'Återställa',
    responsibilityInContructionProject: 'Ansvar för byggprojektet',
    responsibleApplicant: 'Ansvarig Sökare',
    responsibleCompany: 'Ansvarigt företag',
    restore: 'Återskapa',
    revisionOverview: 'Revisionsöversikt',
    revisions: 'Revisioner',
    revoked: 'Återkallad',
    root: 'Starta specifikation',
    routine: 'Rutin',
    row: 'Rad',
    salaryAmount: 'Lönetimmar',
    salaryAndSocialCosts: 'Inkl. Sociala avgifter',
    salaryPerUnit: 'Lön per enhet',
    salaryPerUnitPieceWorkAndHours: 'Total lön per enhet (ackord + timmar)',
    salesPrice: 'Säljpris',
    samsvarserklaering: 'Samsvarserklæring',
    save: 'Spara',
    saveInAltinn: 'Spara i Altinn',
    savePDFOnProject: 'Spara PDF på projekt',
    search: 'Sök',
    searchBy: 'Søk efter',
    searchFor: 'Søk efter',
    searchIn: 'Sök i',
    sectionNo: 'Sektionsnummer',
    seeMore: 'Se mer',
    selectCaseManager: 'Välj saksbehandlare',
    selectColumnLayout: 'Välj inställning för kolumn',
    selected: 'Vald',
    selectedRootError: 'Root kan inte väljas när du lägger till rader i ett nytt stycke! Ta bort översta valet:',
    selectExisting: 'Välj befintlig',
    selectFileTypesThisFolderShouldBeDefaultFor: 'Välj filtyper mappen ska vara standard för',
    selectLicenseValidityPeriode: 'Välj licensens giltighetstid',
    selectUsecases: 'Välj användningsområde:',
    sendActivationEmail: 'Skicka aktiveringsmail',
    sendActivationMail: 'Sänd aktiveringsmail',
    sentFromFieldSystem: 'Skickat från fältsystemet',
    setAsActive: 'Ställ in som aktiv',
    setIndividualValidityDateUserLicense: 'Ställ in individuellt giltighetsdatum för användarlicens',
    setNewPassword: 'Ange nytt lösenord',
    settings: 'Inställningar',
    show: 'Visa',
    showInHandheld: 'Visa på mobila enheter',
    showSideMenu: 'Visa sidmeny',
    showTotals: 'Visa helhetsvy',
    showVersionInfo: 'Visa versionsinformation på PDF',
    signatureField: 'Signatur',
    signedBy: 'Signerad av',
    signingHeader: 'Signering',
    signingInfo:
      'Innan signering måste schemat först valideras, för att se till att det uppfyller kraven till insändning. Avslöjas det inte några grundläggande fel, kommer du att få möjlighet att signera och sända in. Det är möjligt att spara schemat i Altinn innan det sänds in',
    site: 'Anlägg',
    siteAddress: 'Anläggningsadress',
    siteInformation: 'Anläggningsinformation',
    sites: 'Anläggning',
    socialCostsFactor: 'Faktor för sociala kostnader',
    somethingWentWrong: 'Något gick fel!',
    refreshPage: 'Ladda om sidant',
    somethingWentWrongError: 'Något gick fel, försök igen.',
    sort: 'Sortera',
    sosialCosts: 'Sociala avgifter',
    specification: 'Specifikation',
    specificationUpdated: 'Specifikationen är nu uppdaterad',
    spesificationSelectFieldsToAddDiscount:
      'Du måste välja fält i specifikationen för att lägga till rabatter och tilläggsavgifter',
    squareMeter_short: 'm2',
    squareMeter: 'Kvadratmeter',
    start: 'Start',
    startDate: 'Start datum',
    status: 'Status',
    stdParamName: 'Namn på metod',
    stdParams_short: 'Std.parametrar',
    stdParams: 'Standardparametrar',
    stdText_short: 'Std.text',
    stdText: 'Standardtext',
    subcontract: 'Underleverantörer',
    sumUnitPrice: 'Samlad enh.pris',
    supplier: 'Leverantör',
    supplierName: 'Leverantör',
    suppliers: 'Leverantörer',
    surname: 'Efternamn',
    table: 'Tabell',
    tableField: 'Tabell',
    takeBreakAndPlay: 'Vi vet hur frustrerande det kan vara. Ta ett djupt andetag och spela en runda.',
    technicalInformation: 'Teknisk beskrivning',
    telephoneNumber: 'Telefonnummer',
    telephoneNumbers: 'Telefonnummer',
    templateCatalog: 'Mallkatalog',
    templateInformation: 'Mallinformation',
    tenant: 'Cordelkund',
    tenantAdmin: 'Tenantadmin',
    tenantAlreadyExists: 'Tenant finns redan i organisationen',
    tenantDeleted: 'Tenant raderades',
    tenantId: 'Tenant-id',
    tenants: 'Tenants',
    text: 'Text',
    textField: 'Textfält',
    theApplication: 'Ansökan',
    theCompany: 'företaget',
    title: 'Titel',
    total: 'Total',
    totalAmount: 'Helt uträknat',
    totalAmountChecklists: 'Total antall checklistor',
    totalCost: 'Total kostnad',
    totalCosts: 'Totala kostnader',
    totalCustomerMaterialCost: 'Kundens totalpris för material',
    totalCustomerWorkCost: 'Kundens totalpris för arbete',
    totalMaterialCost: 'Total självkostnadsmaterial',
    totalPartsSurcharge: 'Sum delar',
    totalProfit: 'Total vinst',
    totalProfitPercent: 'Total vinst',
    totals: 'Totalt',
    totalsMaterialWork: 'Material och arbete samlat',
    totalUnitPrice: 'Totalt enhetspris',
    totalVatAmount: 'Totalt inkl moms',
    totalWorkCost: 'Total självkostnad arbete',
    travelCostAmount: 'Transport / kost',
    travelCostAmount2: 'Lön resor',
    type: 'Typ',
    under: 'Under',
    unit: 'Enhet',
    unitPrice: 'Enhetspris',
    unitPricesPosNumber: 'Enhetspriser totalt med positionsnummer',
    unknown: 'Okänt',
    unknowNodetype: 'Okänd nodtyp',
    unLockPrice: 'Lås upp priset',
    update: 'Uppdatera',
    updateParams: 'Uppdateringsparametrar',
    uploadCsvUsersInstructions:
      'För att filen ska läsas in korrekt måste den första raden i filen vara en rubrik bestående av',
    uploadFromCsvFile: 'Ladda upp från CSV-fil',
    uploadOneFileError: 'Det är bara möjligt att ladda upp en fil åt gången.',
    uploadUsersFromACsvFile: 'Ladda upp användare med en csv-fil.',
    useNo: 'Använd nr.',
    user: 'Användare',
    userActivated: 'Användaren aktiverades',
    userCountInvalid: 'Antalet användare måste vara 0 eller fler',
    userInformation: 'Användarinformation',
    userLicense: 'Användarlisens',
    userLicenses: 'Användarlisenser',
    username: 'Användarnamn',
    userRole: 'Användarroll',
    userRoles: 'Användarroller',
    users: 'Användare',
    userSettings: 'Användarinställningar',
    userType: 'Användartyp',
    userTypeRequired: 'Användartyp måste väljas',
    userWillReceiveMail:
      'Om du har en användare i vårt system får du nu ett mejl från oss. Följ instruktionerna i detta mail.',
    validFrom: 'Giltig från',
    validTo: 'Giltig till',
    vatAmount: 'Moms',
    vendor: 'Grossist',
    vendorDiscountPercent: 'Rabatt från leverantör',
    vendorDiscountSource: 'Rabatt från leverantör',
    vendorInfo: 'Leverantörsinformation',
    vendorNumber: 'Leverantörsnummer',
    vendors: 'Leverantörer',
    version: 'Version',
    versions: 'Versioner',
    viewFullTable: 'Klicka för att visa hela tabellen',
    viewing: 'Visning',
    volume: 'Volym',
    volumeUnit: 'Enhetsvolym',
    wasAdded: 'lades till',
    weight: 'Vikt',
    weightUnit: 'Enhetsvikt',
    whitespace: ' ',
    withinValidLicenseDate: 'Datum måste vara inom lisensens giltighetstid',
    work: 'Arbete',
    workAmountFactor: 'Faktor för totalt beräknad',
    workCost: 'Arbetskostnader',
    workCostFactor: 'Faktor för självkostnad',
    workCostPrice_short: 'Självkostn. arb.',
    workCostPrice: 'Självkostnad',
    workCostPriceOfferTable: 'Självkostnad enh. arbete',
    workCostPricePerUnit: 'Kostnad per enhet',
    workCostSum: 'Sum självkostnad arbete',
    workCustomerAmount: 'Belopp arbete exkl. mva',
    workCustomerPrice: 'Enh.pris arbete exkl. mva',
    WorkDiscountPercent: 'Rabatt på arbete i procent',
    workMarkupFactor: 'Faktor för påslag',
    workMarkupPercent_short: 'Påslag matr.',
    WorkMarkupPercent: 'Påslag på arbete i procent',
    workMarkupPerUnit: 'Tillägg per enhet',
    workMarkupSource: 'Underlag för påslag på arbete',
    workNetCost: 'Lönekostnad',
    workNetCostFactor: 'Faktor för lönekostnad',
    workPrice: 'Pris för arbete',
    workProfitPercent: 'Förtjänst arbete (%)',
    workTravelCostAmountFactor: 'Faktor för reseersättning',
    wouldYouLikeToincludeRoutinesParagraph: 'Vill du inkludera rutinens paragrafer?',
    wouldYouLikeToOpen: 'Vill du öppna?',
    wouldYouLikeToOpenIt: 'Vill du öppna den?',
    wouldYouLikeToRestore: 'Vill du återställa?',
    wouldYouLikeToRestoreIt: 'Vill du återställa den?',
    write: 'Skriva',
    wrongFileTypeError: 'Felaktig filtyp. Du kan bara ladda upp xml-filer.',
    wrongUrl: 'Webbadressen är felaktig eller inte tillgänglig. Gå tillbaka och försök igen.',
    wrongUsernameOrPassword: 'Du angav fel användarnamn eller lösenord',
    yardNo: 'Gårdsnummer',
    yes: 'Ja',
    yesDeletePermanent: 'Ja, radera permanent',
  },
  en: {
    addNewUsers: 'Add new users',
    addExistingUsers: 'Add existing users',
    searchForUsers: 'Search for users',
    access: 'Access',
    accessLevel: 'Access level',
    activate: 'Activate',
    activated: 'Activated',
    activateUser: 'Activate user',
    activationMailSent: 'Activation mail was sent',
    active: 'Active',
    activeRevision: 'Active audit',
    activeSince: 'Active since',
    add: 'Create',
    addAndSendActivationMail: 'Create and send activation mail',
    addAreaOfResponsibility: 'Add new area of responsibility',
    addCustomerBeforeOfferWarning: 'Create a customer before creating a new offer',
    addExisting: 'Add existing',
    additionalType: 'Additional type',
    additions: 'Additions',
    addNew: 'Add',
    addNewCatalog: 'Add new catalog',
    addNewCompany: 'Create a new company',
    addNewContactPerson: 'Add new contact person',
    addNewCustomer: 'Create a new customer',
    addNewFromTemplate: 'Add new from template',
    addNewPackage: 'Create new package',
    addNewPerson: 'Create new person',
    addNewProperty: 'Add new property',
    addNewSite: 'Create new facility',
    addParamsBeforeOfferWarning: 'Create a std. parameter before you create a new offer',
    addPiecework: 'Add to the contract',
    address: 'Address',
    addUserRoleFirst: 'Add a user role first',
    addVendor: 'Create new supplier',
    adjustments: 'Adjustments',
    adjustPieceWork: 'Adjust contract',
    adjustTo: 'Adjust to',
    admin: 'Admin',
    administration: 'Administration',
    advancedPriceCalculation: 'Advanced price calculation',
    agreement: 'Agreement',
    all: 'All',
    allowComments: 'Allow comments',
    altinnLoggedIn: 'You are logged in to Altinn.',
    amount: 'Round figure',
    amountOfPoints: 'Number of points',
    amountOfUsers: 'Number of users',
    aPDFOfTheManualWasAddedToTheProject: 'A PDF of the manual was added to the project',
    application: 'Application',
    applications: 'Applications',
    applicationSavedInAltinn: 'Application saved in Altinn',
    approve: 'Approve',
    approved: 'Approved',
    approveVersion: 'Approve version',
    areaOfResponsibility: 'Area of responsibility',
    areYouSure: 'Are you sure?',
    areYouSureFinalizeChecklist:
      'If you choose to finalize the checklist, you can no longer make changes. Are you sure you want to finalize the checklist?',
    areYouSureYouWantToDelete: 'Are you sure you want to delete',
    around: 'Around',
    as: 'As',
    asDeleted: 'As deleted',
    attachments: 'Attachments',
    available: 'Available',
    availableExternally: 'Available externally',
    availables: 'Available',
    averageSalary: 'Average salary',
    back: 'Back',
    base: 'Base',
    basicRegister: 'Basic register',
    blank: 'Blank',
    buildingElement: 'Building element',
    buildingElements: 'Building elements',
    calculatePieceWork: 'Calculate piecework contract work',
    calculatePieceWorkWarning1:
      'It is not checked for calculating piecework in the calculation parameters. Should piecework costs be calculated on',
    calculatePieceWorkWarning2: ', this must be checked in the calculation parameters.',
    calculatePieceWorkWarning3:
      'Hourly piecework wage is not included in the calculation parameters. In order to calculate piecework costs, as well as adjust cost and allowance for work, the average piecework wage must be checked in the calculation parameters and be greater than 0.',
    calculationType: 'Calculation type',
    cancel: 'Cancel',
    cannotDeleteASignedApplication: "You can't delete a signed application",
    cannotDeleteCompletedChecklist: 'A completed checklist can not be deleted',
    cannotDeleteStartedChecklist: 'A started checklist can not be deleted',
    cannotDeleteTenantWithUsers: 'Cannot delete a tenant with associated users',
    cantDeletePersonError: 'The person is associated with a customer and cannot be deleted.',
    caseManager: 'Caseworker',
    catalog: 'Catalog',
    catalogs: 'Catalogs',
    catalogType: 'Catalog type',
    categories: 'Categories',
    category: 'Category',
    categoryOrAgreement: 'Category or agreement',
    chainAdminPrivileges:
      'Because you have administrator privileges for multiple chains you have to choose which chains to connect the public catalog to.',
    change: 'Change',
    changeChapterWarning: 'Changes you make on this page will take effect everywhere the chapter is in use!',
    changeCustomer: 'Change customer',
    changePlacement: 'Change placement',
    changesHaveBeenMade: 'Changes have been made',
    changesWasSaved: 'Changes were saved',
    chapter: 'Chapter',
    checkboxField: 'Checkbox',
    checkForm: 'Validate form',
    checklist: 'Checklist',
    checklists: 'Checklists',
    checklistSettingsDescription:
      'Select what information to display on the checklist and whether it should be visible in handheld systems.',
    checklistTemplate: 'Checklist template',
    checklistTemplates: 'Checklist templates',
    checkpoint: 'Checkpoint',
    checkpointTemplate: 'Checkpoint template',
    checkpointTemplates: 'Checkpoint templates',
    choose: 'Select',
    chooseCompany: 'Select company',
    chooseCustomer: 'Select customer',
    chooseCustomerType: 'Select customer type',
    chooseFilter: 'Choose filtering',
    chooseModulePackage: 'Select module package',
    chooseNodesToUpdate: 'Select what you wish to update in the specification',
    chooseOrganization: 'Select organization',
    choosePackageCatalogToCopyTo: 'Select the catalog to which the package is to be copied',
    chooseParams: 'Select calculation method',
    choosePieceworkCategory: 'Select the contract category',
    chooseRevision: 'Select revision',
    chooseSite: 'Select facility',
    chooseSorting: 'Choose sorting',
    chooseTenant: 'Tenant must be chosen',
    chooseType: 'Select type',
    chooseUnit: 'Select unit',
    chooseUser: 'Select user',
    chooseValidFromDate: 'Valid from date must be chosen',
    chooseValidToDate: 'Valid to date must be chosen',
    chooseWhatToAdjust: 'Choose what to adjust',
    city: 'City',
    clearFilter: 'Clear filter',
    close: 'Close',
    closeSideMenu: 'Close side menu',
    code: 'Code',
    collectiveAdjustment: 'Tariff supplement',
    collectiveAdjustmentAmount: 'Collective adjustment amount',
    collectiveAdjustmentFactor: 'Collective adjustment factor',
    collectiveAdjustmentPercent: 'Collective adjustment (%)',
    column: 'Column',
    columnLayout: 'Column layout',
    columns: 'Columns',
    comment: 'Comment',
    companies: 'Companies',
    company: 'Company',
    companyinformation: 'Company information',
    companyName: 'Company name',
    companyNameRequired: 'Company name is required',
    complete: 'Complete',
    completed: 'Completed',
    completedInFieldSystem: 'Sent from Cordel UTE',
    completedInFieldSystemAndApproved: 'Completed in Field System, in addition to approved',
    completedMultiple: 'Completed',
    compositePoint: 'Composite',
    confirmPassword: 'Confirm password',
    confirmPasswordPlease: 'Please confirm password',
    conflict: 'Conflict',
    connectCatalog: 'Connect catalog to one or more of your chains:',
    connectPersonToEmployee: 'Link employee to person in the person register',
    contact: 'Contact',
    contactInformation: 'Contact information',
    contactPerson: 'contact',
    contactpersons: 'Contact persons',
    contactResponsibleIn: 'Contact responsible in ',
    contactWasChanged: 'Contact was changed',
    content: 'Content',
    copy_noun: 'Copy',
    copy: 'Copying',
    copyPackage: 'Copy package',
    cost: 'Full cost',
    costAndSurcharge: 'Cost + surcharge overview',
    costPrice: 'Cost price',
    costProcureProducts: 'Cost of stock products',
    costsAndWastage: 'Costs / waste',
    costStockProducts: 'Cost of stock products',
    couldNotFindEditPoint: 'Could not find edit point. Close dialog and try again.',
    couldNotLoadImages: 'Could not load images. Refresh and try again.',
    couldNotSignApplicationError: 'Something went wrong. Could not sign the application.',
    couldNotSignError: 'Something went wrong. Could not sign',
    country: 'Country',
    coverageOnSalary: 'Coverage on salary',
    coveragePerHour: 'Coverage per hour',
    coverageTotal: 'Total coverage',
    create: 'Create',
    createAndSendActivationMail: 'Create and send activation e-mail',
    createBlank: 'Create blank',
    createCopy: 'Create copy',
    created: 'Created',
    createdBy: 'Created by',
    createImageParagraph: 'Create a image paragraph',
    createNewOffer: 'Add new offer',
    createNewRevision: 'Make a new revision',
    createTextParagraph: 'Create a text paragraph',
    cubicMeter_short: 'm3',
    cubicMeter: 'cubic meters',
    currency: 'Currency',
    currentLicenses: 'Current licenses',
    currentlyUsing: 'Currently using',
    customer: 'Customer',
    customerAmount: 'Customer amount',
    customerAmountIncVat: 'Total customer amount incl. VAT',
    customerIdNotValid: 'Customer ID is invalid.',
    customerInfo: 'Customer information',
    customerName: 'Customer Name',
    customerNumber: 'Customer No.',
    customerNumberOrOrganizationAlreadyExist: 'Customer number or organization already exists',
    customerNumberRequired: 'Customer number required',
    customerPrice: 'Sum',
    customers: 'Customers',
    customerTotalAmount: "The customers's total amount",
    customerType: 'Customers Type',
    customerUnitPrice: "The customers's unit price",
    dashboard: 'Dashboard',
    date: 'Date',
    dateAsc: 'Oldest to newest',
    dateDesc: 'Newest to oldest',
    dateEarliestValid: 'Earliest valid date',
    dateField: 'Date and time',
    dateIsPastValid: 'Valid to date can not be more than 2 years in the future',
    dateLatestValid: 'Latest valid date',
    dateMustBeWithinValidLicenseDate: 'Date must be within valid license date',
    dates: 'Dates',
    day: 'day',
    deactivated: 'Deactivated',
    deactivatedLicenses: 'Deactivated licenses',
    declarationAndSigningHeader: 'Declaration and signing',
    declarationApplies: 'The declaration applies ',
    declarationCheckboxKontroll:
      'Responsible controller declares independence, cf. SAK10 § 14-1, and will account for changes that may affect independence, cf. SAK10 §12-5',
    declarationCheckboxProsjekterende:
      'The responsible designer declares that the design shall be planned, carried out and quality assured in accordance with pbl, cf. SAK10 §12-3',
    declarationCheckboxUtfoerende:
      'The responsible contractor declares that the work shall not start until there is a quality-assured production basis for the respective part of the execution, cf. SAK10 § 12-4',
    declarationHeader: 'Declaration',
    declarationPenaltyInfo:
      'We are aware of the rules on penalties and sanctions in the Planning and Building Act, Chapter 32, and that incorrect information can lead to reactions.',
    declarationSAKInfo:
      'We commit to provide the right competence in the construction project, cf. SAK10 chapters 10 and 11.',
    declarationSignedAndSubmitted: 'The declaration has been signed and submitted. ',
    default: 'Standard',
    defaultCost: 'Standard cost',
    defaultCostCurrency: 'Standard cost currency',
    defaultMaterialMarkup: 'Standard surcharge',
    defaultPoints: 'Default points',
    defaultPriceCurrency: 'Standard price currency',
    defaultVendorDiscount: 'Std. supplier discount',
    DefaultVendorDiscountSource: 'Use standard surcharge',
    delete: 'Delete',
    deleted: 'Deleted',
    deletedChecklist: 'Deleted checklist',
    deletedChecklistInfo: 'This checklist is deleted!',
    deletedChecklistTemplates: 'Deleted checklist templates',
    deletedCompanies: 'Deleted companies',
    deletedCustomers: 'Deleted customers',
    deletedManualDraftChaptersRegister: 'Deleted manual draft chapters',
    deletedManualDraftsRegister: 'Deleted manual drafts',
    deletedPersons: 'Deleted persons',
    deletedSites: 'Deleted facilities',
    deletedVendors: 'Deleted suppliers',
    department: 'Department',
    description: 'Description',
    descriptionIsRequired: 'Description is required',
    detailsMaterial: 'Details materials',
    detailsWork: 'Details piecework (work)',
    discount: 'Discount',
    discountAndSurcharge: 'Discount and surcharge',
    discountPercent: 'Discount (%)',
    discountPerUnit: 'Discount per unit',
    documents: 'Documents',
    doYouWantToResetChanges: 'do you want to reset all changes?',
    draft: 'Draft',
    drafts: 'Drafts',
    ean: 'EAN',
    edit: 'Edit',
    editCatalogs: 'Edit catalogs',
    editCompany: 'Edit company',
    editContactInfo: 'Edit contact information',
    editCustomerInfo: 'Edit customer details',
    editEmail: 'Edit e-mail',
    editInformation: 'Edit information',
    editOffer: 'Edit offer',
    editPackage: 'Edit package',
    editPackageGlobal: 'Change the package globally',
    editPackageInfo: 'Edit package information',
    editPerson: 'Edit person',
    editPhonenumber: 'Edit telephone no.',
    editPriceCalculation: 'Edit calculation',
    editProduct: 'Edit product',
    editVendor: 'Edit supplier',
    editVendorInfo: 'Edit supplier information',
    email: 'E-mail',
    employees: 'Employees',
    endDate: 'End date',
    entrepreneur: 'Contractor',
    environmentTax: 'Environment tax',
    erklaeringOmAnsvarsrett: 'Declaration of responsibility',
    event: 'Event',
    example: 'Example',
    excludeFromSum: 'Do not sum up',
    expired: 'Expired',
    export: 'Export',
    extendTo: 'Extend to',
    externalCode: 'External No.',
    factorBasis: 'Basis calculation of factor 1',
    farmNo: 'Farm No.',
    favorite: 'Favourite',
    file: 'File',
    fileType: 'File type',
    fileTypeHasExistingDefaultFolder: 'File type has existing default folder',
    fileTypeMustBeImage: 'File type must be jpeg, jpg or png.',
    fillInAllRequiredFields: 'Fill in all required fields',
    fillInAllRequiredFieldsOrMarkAsNotApplicable: 'Fill in all required fields or mark as not applicable',
    filter: 'Filter',
    finalize: 'Finalize',
    finalizeChecklist: 'Finalize checklist?',
    finalized: 'Finalized',
    finishProject: 'Complete project',
    firstName: 'First name',
    firstNameRequired: 'First name must be filled out',
    fixedPrice: 'Fixed price',
    folder: 'Folder',
    forename: 'Forename',
    forgotPassword: 'Forgot password?',
    formatACatalogIsNeededBeforeCreatingAnX: 'A catalog is needed before creating an {0}',
    formatACatalogIsNeededBeforeCreatingAX: 'A catalog is needed before creating a {0}',
    formatAddAfterX: 'Add after {0}',
    formatAddExistingX: 'Add existing {0}',
    formatAddNewX: 'Add new {0}',
    formatAddX: 'Add {0}',
    formatAddXAfterX: 'Add {0} after {1}',
    formatAddXBeforeX: 'Add {0} before {1}',
    formatAddXOfXToX: 'Add {0} of {1} to {2}',
    formatAddXTo: 'Add to {0}',
    formatAddXToX: 'Add {0} to {1}',
    formatAllXX: 'All {0} {1}',
    formatAreYouSureYouWantToDeleteX: 'Are you sure you want to delete {0}?',
    formatAreYouSureYouWantToPermanentlyDeleteX: 'Are you sure you want to permanently delete {0}?',
    formatAXIsNeededBeforeCreatingAnX: 'A {0} is needed before creating an {1}',
    formatAXIsNeededBeforeCreatingAX: 'A {0} is needed before creating a {1}',
    formatCannotPreviewX: 'Can not preview {0}',
    formatCatalogWillBeAvailableForX: 'This catalog will be available to members of {0}',
    formatChoosePlacementForX: 'Select placement for {0}',
    formatChooseX: 'Choose {0}',
    formatCouldNotAddXToX: 'Could not add {0} to {1}',
    formatCouldNotConnectXToX: 'Could not connect {0} to {1}',
    formatCouldNotDeleteX: 'Could not delete {0}',
    formatCouldNotDeleteXConnectedToX: 'Cannot delete {0} because it is connected to {1}',
    formatCouldNotDeleteXContainingX: 'Cannot delete {0} containing {1}',
    formatCouldNotDeleteXFromX: 'Could not delete {0} from {1}',
    formatCouldNotFindAnyX: 'Could not find any {0}',
    formatCouldNotGetInformationAboutX: 'Could not get information about {0}',
    formatCouldNotLoadX: 'Could not load {0}',
    formatCouldNotPreviewX: 'Could not preview {0}',
    formatCouldNotRestoreX: 'Could not restore {0}',
    formatCouldNotUpdateX: 'Could not update {0}. Please try again.',
    formatCreateANewVersionOfAnExistingX: 'Create new version of an existing {0}',
    formatCreateANewVersionOfX: 'Create a new version of {0}',
    formatCreateANewX: 'Create a new {0}',
    formatCreateNewX: 'Create new {0}',
    formatCreateX: 'Create {0}',
    formatDeletedX: 'Deleted {0}',
    formatDeleteX: 'Delete {0}',
    formatDeleteXFromX: 'Delete {0} from {1}',
    formatDownloadX: 'Download {0}',
    formatEditX: 'Edit {0}',
    formatEnterX: 'Enter {0}...',
    formatErrorDownloadingX: 'Error downloading {0}. Try again.',
    formatErrorXIsX: 'Error - {0} is {1}.',
    formatExtendTheX: 'Extend the {0}',
    formatExtendX: 'Extend {0}',
    formatGoToNextX: 'Go to next {0}',
    formatGoToPreviousX: 'Go to previous {0}',
    formatIfYouDeleteXItCanNotBeUndone: 'If you delete {0}, it can not be undone. We therefore asks again:',
    formatManuallyAddX: 'Manually add user',
    formatMaxAllowedXIsX: 'Max allowed {0} is {1}',
    formatMinAllowedXIsX: 'Min allowed {0} is {1}',
    formatMoveX: 'Move {0}',
    formatMoveXToX: 'Move {0} to {1}',
    formatNewXAdded: 'New {0} added',
    formatNoX: 'No {0}',
    formatNoXAvailable: 'No {0} available',
    formatNoXChosen: 'No {0} chosen',
    formatOrInDefaultFolderForX: 'Or in the default folder for that {0}',
    formatProblemCreatingX: 'Problem creating {0}',
    formatPutXOnX: 'Put {0} on {1}',
    formatRemoveX: 'Remove {0}',
    formatResetX: 'Reset {0}',
    formatSelectAXToPreview: 'Select a {0} to preview',
    formatSelectedX: 'Selected {0}',
    formatSelectX: 'Select {0}',
    formatSelectXToCreateAnX: 'Select {0} to create an {1}',
    formatSelectXToCreateAX: 'Select {0} to create a {1}',
    formatSelectXToCreateX: 'Select {0} to create {1}',
    formatThereExistsNoX: 'There is no {0}',
    formatThereExistsNoXForX: 'There is no {0} for {1}',
    formatTypeInX: 'Type in {0}...',
    formatUploadNewX: 'Upload new {0}',
    formatUploadX: 'Upload {0}',
    formatWasAdded: '{0} was added',
    formatXAlreadyAdded: '{0} already added',
    formatXCannotExceedX: '{0} cannot exceed {1}',
    formatXDoesNotHaveAnX: '{0} does not have a {1}',
    formatXDoesNotHaveAX: '{0} does not have a {1}',
    formatXDoesNotHaveX: '{0} does not have {1}',
    formatXFailedToImport: '{0} failed to import',
    formatXFromX: '{0} from {1}',
    formatXGotImported: '{0} got imported',
    formatXHyphenX: '{0} – {1}',
    formatXIsAlreadyConnectedToTheX: '{0} is already connected to the {1}',
    formatXIsAlreadyConnectedToX: '{0} is already connected to {1}',
    formatXIsNotValid: '{0} is not valid',
    formatXIsRequired: '{0} is required',
    formatXMarkedAsX: '{0} marked as {1}',
    formatXMissingX: '{0} missing {1}',
    formatXMustBeAValidX: '{0} must be a valid {1}',
    formatXNeedsX: '{0} needs {1}',
    formatXNeedsXAndX: '{0} needs {1} and {2}',
    formatXWasAdded: '{0} was added',
    formatXWasConnectedToX: '{0} was connected to {1}',
    formatXWasCopied: '{0} was copied',
    formatXWasDeleted: '{0} was deleted',
    formatXWasDisconnectedFromX: '{0} was disconnected from {1}',
    formatXWasNotAdded: '{0} was not added',
    formatXWasNotConnectedToX: '{0} was not connected to {1}',
    formatXWasNotDisconnectedFromX: '{0} was not disconnected from {1}',
    formatXWasPublished: '{0} was published',
    formatXWasRestored: '{0} was restored',
    formatXWasSaved: '{0} was saved',
    formatXWasSavedInX: '{0} was saved in {1}',
    formatXWasUpdated: '{0} was updated',
    formatXWasUploaded: '{0} was uploaded',
    formatXWithXXAlreadyExists: '{0} with {1} {2} already exists',
    formatXXWasAdded: '{0} {1} was added',
    formatYouNeedToAddAtleastOneXBeforeYouCanAddX: 'You need to add at least one {0} before you can add {1}',
    formatYouNeedToAddXBeforeYouCanAddX: 'You need to add {0} before you can add {1}',
    freeText: 'Free text',
    freight: 'Freight',
    fromAltinn: 'From Altinn',
    fullName: 'Name',
    general: 'General',
    generalContractor: 'Contractor',
    generalInformation: 'General information',
    getCorrectAccessAltinn:
      '. To be able to submit an application / declaration, you must have the correct access in Altinn. ',
    getStartedWithCordelByggsok: 'Get started with Cordel Buildsearch - Cordel Customer knowledge base',
    getStartedWithCordelKS: 'Get started with Cordel KS | HSE - Cordel Customer knowledge base',
    getXml: 'Download XML',
    goBack: 'Go back',
    goodAfternoon: 'Good afternoon',
    goodEvening: 'Good evening',
    goodMorning: 'Good morning',
    goTo: 'Go to',
    handheld: 'Handheld',
    headerField: 'Heading',
    hereYouWillFindByggsok: 'Here you will find tutorial videos and more information on how to use Cordel byggsök',
    hereYouWillFindKS: 'Here you will find tutorial videos and more information on how to use Cordel KS|HMS',
    hide: 'Hide',
    history: 'History',
    hour_short: 'hr',
    hour: 'Hour',
    hourCategory: 'Hour category',
    hourCategoryNr: 'Hour category number',
    identical: 'Identical',
    ifNoPlacementIsSelectedXWillBePlacedOnTopLevel: 'The {0} will be placed on top level if no placement is selected',
    import: 'Import',
    importNS3459: 'Import price request NS3459',
    include: 'Include',
    includes: 'Includes',
    incomeCurrency: 'Currency for income',
    incSocialCostsFactor: 'Factor for incl. social costs',
    indirectCosts: 'Indirect costs',
    indirectCostsFactor: 'Factor for indirect costs',
    industry: 'Industry',
    information: 'Information',
    informationField: 'Information field',
    inProgress: 'In progress',
    internal: 'Internal',
    internalInformation: 'Internal information',
    internals: 'Internal',
    itemsPerPackage: 'Number per package',
    keywords: 'Keywords',
    kilogram_short: 'kg',
    kilogram: 'Kilo',
    kilometer_short: 'km',
    kilometer: 'kilometer',
    knowledge: 'Knowledge',
    knowledgeBase: 'Knowledge base',
    last: 'last',
    lastName: 'Surname',
    lastNameRequired: 'Last name must be filled out',
    lastUpdated: 'Last modified at',
    lastUpdatedBy: 'Last modified by',
    latestProject: 'Latest project',
    leaseNumber: 'Lease number',
    license: 'License',
    licenseInformation: 'License information',
    licenses: 'Licenses',
    line: 'Line',
    linebreak: 'Line break',
    listPrice: 'List price',
    litre_short: 'l',
    litre: 'Litre',
    loading: 'Loading...',
    loadMore: 'Load more',
    locked: 'Locked',
    lockPrice: 'Lock price',
    log: 'Log',
    loggedIn: 'Last logged in',
    loggedOutIn: 'You will be logged out in:',
    login: 'Login',
    loginWith: 'Login with',
    logo: 'Logo',
    logout: 'Logout',
    logs: 'Logs',
    mainSubmissionNumber: 'Main submission number.',
    manual: 'Manually',
    manualDraft: 'Manual draft',
    manualDraftRegister: 'Manual draft register',
    manualDrafts: 'Manual drafts',
    manualDraftsRegister: 'Manual drafts',
    manualHasNoChapters: 'Manual does not have any chapters',
    manualHMS: 'Manual',
    manualId: 'Manual-Id',
    manuals: 'Manuals',
    manualsRegister: 'Manuals register',
    markup: 'Surcharge',
    markupBasis: 'Surcharge basis',
    markupPercent: 'Markup (%)',
    markupPercentProcureProducts: 'Markup percent on procure products',
    markupPercentStockProducts: 'Markup percent on stock products',
    matCost: 'Material costs',
    matCostPricePerUnit: 'Self-catering per unit',
    matCostSum: 'Sum material cost',
    matCustomerAmount: 'Mat. amount ex. VAT',
    matCustomerPrice: 'Unit price mat. ex. VAT',
    material: 'Material',
    materialCostPrice: 'Mat. full cost per unit',
    MaterialDiscountPercent: 'Discount on material in percent',
    materialDiscountSource: 'Basis for discount on material',
    materialMarkupPercent_short: 'Surcharge matr.',
    MaterialMarkupPercent: 'Percentage of material surcharge',
    materialMarkupSource: 'Basis for surcharge on material',
    materialPrice: 'Price for material',
    materialsList: 'Material list',
    matMarkupPercent: 'Markup percent on materials',
    matPriceInklMarkup: 'Price incl. markup percent',
    matProfitPercent: 'Profit material (%)',
    matrikkelInformation: 'Land registry information',
    meter_short: 'm',
    meter: 'Meter',
    minute_short: 'min',
    minute: 'minute',
    minutes: 'Minutes',
    modified: 'Modified',
    modifiedBy: 'Modified by',
    module: 'Module',
    moduleCode: 'Module code',
    moduleConnection: 'Module connection',
    moduleName: 'Module name',
    modulePackage: 'Module package',
    modulePackageInformation: 'Module package information',
    modulePackages: 'Module packages',
    modules: 'Modules',
    more: 'More...',
    municipality: 'Municipality',
    municipalityCaseNumber: 'Municipality case number.',
    municipalityNo: 'Municipality Number',
    name: 'Name',
    nameIsRequired: 'Name is required',
    nameRequired: 'Name must be filled out',
    nb: 'NB!',
    netCostPrice_short: 'Self-catering matr.',
    netCostPrice: 'Net self-catering',
    netCostPriceLocked: 'Fixed self-catering price',
    newContactAdded: 'New contact added',
    newTenantAdded: 'New tenant added',
    next: 'Next',
    nextPage: 'Go to the next page',
    no: 'No',
    noAbortAndCancel: 'No, abort and cancel',
    noAccessInAltinnTo: 'You do not have access in Altinn to ',
    noAccessPage: 'You do not have access to this page.',
    noAltinnAccessForCompany:
      'You do not have correct access in Altinn for the company. To be able to submit an application / declaration, you must have the correct access in Altinn. Contact the person responsible in the company.',
    noCaseManagerSelected: 'No case manager selected',
    noCatalog: 'No catalogs',
    noCatalogsExists: 'No catalogs exist',
    noCatalogsExistsInstructions: 'In order to continue, you must first create a catalog.',
    noCatalogsForPackages:
      'No catalogs exist for packages. To be able to create a package, it must be linked to an existing catalog.',
    noCatalogsOnHourCategoryWarning:
      'No catalogs exist for hour categories. To be able to create an hour category, it must be linked to an existing catalog.',
    noCategoriesExistsInstructions: 'In order to continue, you must first create a category.',
    noCityInAltinn:
      "Received incomplete data from Altinn. Check / update the company's address in the Altinn or the Brønnøysund register.",
    noContactPerson: 'No contact perspon added',
    noContent: 'No content',
    noCustomer: 'No customer',
    noData: 'No data',
    noDataInTable: 'No data in table',
    noElementsOnThisPage: 'No item on this page',
    noEmail: 'No e-mail',
    noInformationAvailable: 'No information available',
    noModuleInModulePackage: 'No module in module package',
    none: 'None',
    noOptionsText: 'No matches found',
    noPersonSelected: 'No person selected',
    noPhoneNumber: 'No telephone number',
    noProjectAvailableInDashboard:
      'No project information available. Select an existing project in Projects to see information here.',
    noSubContent: 'No subcontent',
    notActive: 'Not active',
    note: 'Note',
    notice: 'Comments',
    notloggedInToAltinn: 'You are not logged in to Altinn.',
    noWriteAccess: 'You do not have write access to this page.',
    nsCode: 'NS code',
    nscode: 'NS code',
    nsCodes: 'NS codes',
    nsSum: 'NSSum',
    number: 'Number',
    numberOfHours: 'Number of hours',
    numberOfHoursProduction: 'Number of production hours',
    numberOfHoursTravel: 'Number of hours of travel',
    numberOfSquareMeters: 'Number of square metres',
    numberShort: 'No.',
    numericField: 'Numeric',
    of: 'of',
    offer: 'Offer',
    offerDate: 'Offer Date',
    offerDeadline: 'Offer Deadline',
    offerInfo: 'Offer Information',
    offerNumber: 'Offer No.',
    offers: 'Offers',
    offerSettings: 'Offer settings',
    ok: 'Okay',
    onBehalfOf: 'On behalf of',
    oneOrMoreTypesNotAdded: 'One or more types were not added',
    oneOrMoreTypesWasNotRemoved: 'One or more types were not removed',
    ongoing: 'Ongoing',
    onlyCreatorOfTemplateCanHideInfo: 'Only creator of the template can show/hide version info',
    open: 'Open',
    orderBy: 'Order by',
    orDragFileHere: 'or drag the file here.',
    organization: 'Organization',
    organizationDeleted: 'Organization was deleted',
    organizationInformation: 'Organization information',
    organizationMissingName: 'Organizations name must be filled out',
    organizationMissingNumber: 'Organization number must be filled out',
    organizationNumber: 'Company Reg. No.',
    organizationRestored: 'Organization was restored',
    organizations: 'Organizations',
    organizationsCouldNotLoad: 'Could not load organizations. Try again later.',
    organizationsLogo: 'Organizations logo',
    ourCustomerNumber: 'Own defined customer no',
    ourDeclarationsAvailableAtCompliance: 'Our declarations of conformity will be available at: ',
    ourDeclarationsAvailableAtControl: 'Our inspector statements will be available at: ',
    outcome: 'Outcome',
    overall: 'Overall',
    overallTotals: 'Overall total',
    overview: 'Overview',
    package: 'Package',
    packageCopyError:
      'Package number must be unique per catalog. Copy the package to another catalog or change the package number.',
    packageInfo: 'Package information',
    packageNumber: 'Package No.',
    packages: 'Packages',
    packageUnit: 'Package Unit',
    packageWithoutFixedPrice: 'This package does not have a fixed price',
    page: 'Page',
    pageBreak: 'Page shift',
    paragraph: 'Paragraph',
    param: 'Std. parameter',
    params: 'Parameters',
    partsSurcharge: 'Additional parts',
    partsSurchargeAmount: 'Parts/surcharge (amt)',
    partsSurchargeBasis_short: 'Parts/surch. basis',
    partsSurchargeBasis: 'Parts/surcharge basis',
    partsSurchargeMethod: 'Method of adding parts',
    partsSurchargePercent: 'Parts/surcharge (%)',
    partyNo: 'Party No.',
    password: 'Password',
    passwordMismatch: 'Passwords do not match',
    passwordRequirment: 'Passwords must meet password requirements',
    passwordRequirmentsNotMet: 'Password requirements is not met',
    passwordSuccessfullyActivatedMessage:
      'You have successfully activated your user. You will now be redirected to the login-page.',
    passwordSuccessfullyResetMessage:
      'You have successfully reset your password. You will now be redirected to the login-page.',
    pdf: 'PDF',
    permanent: 'Permanent',
    person: 'Person',
    personIdNotValid: 'Person ID is invalid.',
    persons: 'Persons',
    phone: 'Telephone',
    pickFile: 'Select file',
    piece_short: 'pc',
    piece: 'Piece',
    piecework: 'Contract tariff',
    pieceWorkAmount: 'Piecework pay per unit',
    pieceWorkAmount2: 'Salary agreement',
    pieceWorkAmountFactor: 'Piecework amount factor',
    pieceWorkCategory: 'Contract category',
    pieceWorkCode: 'Contract code',
    pieceWorkCostCurrency: 'Currency for costs from piecework contract',
    pieceWorkDescription: 'Piecework description',
    pieceWorkIncAdjustments: 'Piecework incl. adjustment',
    pieceWorkIncAdjustmentsFactor: 'Piecework incl. adjustment factor',
    pieceWorkRate: 'Contract rate',
    pieceworkSalary: 'Piecework pay per hour',
    pieceWorkWarning1: 'It is not checked for calculating piecework in the calculation parameters.',
    pieceWorkWarning2: 'Hourly piecework wage is not included in the calculation parameters.',
    placeholderDescription: 'Enter description...',
    placeholderName: 'Enter name...',
    placementOfParagraph: 'Placement of paragraph',
    planAndBuilding: 'according to the Planning and Building Act (pbl) § 23-3',
    pointTemplates: 'Point templates',
    posNumber: 'Position number',
    postalAddress: 'Location',
    postalCode: 'Post Code',
    preview: 'Preview',
    previewNotAvailable: 'Preview not available. Download to view this file.',
    previousPage: 'Go to the previous page',
    price: 'Price',
    priceCalculation: 'Price calculation',
    priceIncVat: 'Unit price incl. VAT',
    priceIsLocked: 'Price is locked',
    pricePerSquareMeter: 'Price per square meter',
    pricePerUser: 'Price per user',
    pricePerUserRequired: 'Price per user must be filled out',
    priceRequired: 'Price must be filled out',
    print: 'Print',
    printing: 'Print',
    printOfferForCustomer: 'Offer for customer',
    printOfferForCustomerSimple: 'Offer for customer simplified',
    printPDF: 'Print PDF',
    procureProducts: 'Procure products',
    product: 'Item',
    productCatalogWarning: 'Create a catalog to add products',
    productInfo: 'Item information',
    products: 'Products',
    profit: 'Earnings',
    profitAmount: 'Profit (amt)',
    profitOnSalary: 'Profit on salary',
    profitPercent: 'Profit (%)',
    profitPerHour: 'Profit per hour',
    project: 'Project',
    projectDescription: 'Project description',
    projectFinished: 'Project completed',
    projectIdNotValid: 'Project id is not valid',
    projectInformation: 'Project information',
    projectName: 'Project name',
    projectNoLongerFinalized: 'Project is no longer finalized',
    projectNumber: 'Project No.',
    projectRegister: 'Project Register',
    projectRequirements: 'A project must have a name, valid date, category and a customer',
    projects: 'Projects',
    projectType: 'Project Type',
    propertyAndMatrikkelInfo: 'Property and cadastral information',
    propertyAndSite: 'Property / Construction site ',
    propertyUnitNumber: 'Property unit number',
    publish: 'Publish',
    quantity: 'Quantity',
    radioField: 'Radio buttons',
    rateUnit: 'Batch unit',
    rateUnitAmount: 'Amount',
    rateUnitHour: 'Hours',
    rateUnitMinutes: 'Minutes',
    reactivate: 'Reactivate',
    reactivateProject: 'Reactivate project',
    read: 'Read',
    refreshDescriptions: 'Update descriptions',
    refreshHourAndProductPrices: 'Update hour and product prices',
    refreshMarkupOnProcureProducts: 'Update procurement items to std. surcharge',
    refreshPackageContent: 'Update package specifications',
    refreshPieceWorkRates: 'Update contract rates',
    remove: 'Remove',
    removeChoice: 'Remove choice',
    removed: 'Removed',
    removeFile: 'Remove file',
    removePieceWork: 'Remove the agreement',
    replacementproduct: 'Supplies',
    required: 'required',
    requireImage: 'Require image',
    requiresLoginToAltinn: 'Requires login to Altinn',
    reset: 'Reset',
    responsibilityInContructionProject: 'Responsibility in construction project ',
    responsibleApplicant: 'Responsible applicant',
    responsibleCompany: 'Responsible company ',
    restore: 'Restore',
    revisionOverview: 'Revision overview',
    revisions: 'Audits',
    revoked: 'Revoked',
    root: 'Start specification',
    routine: 'Routine',
    row: 'Row',
    salaryAmount: 'Salary amount',
    salaryAndSocialCosts: 'Inc. Social costs',
    salaryPerUnit: 'Salary per unit',
    salaryPerUnitPieceWorkAndHours: 'Total salary per unit (piecework + hours)',
    salesPrice: 'Sales price',
    samsvarserklaering: 'Samsvarserklæring',
    save: 'Save',
    saveInAltinn: 'Save in Altinn',
    savePDFOnProject: 'Save PDF on project',
    search: 'Search',
    searchBy: 'Search by',
    searchFor: 'Search for',
    searchIn: 'Search In',
    sectionNo: 'Section number',
    seeMore: 'View more',
    selectCaseManager: 'Select case manager',
    selectColumnLayout: 'Select column layout',
    selected: 'Selected',
    selectedRootError: 'Root cannot be marked when adding lines to a new paragraph! Remove the upper selection:',
    selectExisting: 'Select existing',
    selectFileTypesThisFolderShouldBeDefaultFor: 'Select filetypes this folder should be the default for',
    selectLicenseValidityPeriode: 'Select the license validity period',
    selectUsecases: 'Select application:',
    sendActivationEmail: 'Send activation email',
    sendActivationMail: 'Send activation mail',
    sentFromFieldSystem: 'Sent from field system',
    setAsActive: 'Set as active',
    setIndividualValidityDateUserLicense: 'Set individual validity date for user license',
    setNewPassword: 'Set a new password',
    settings: 'Settings',
    show: 'Show',
    showInHandheld: 'Show in handheld',
    showSideMenu: 'Show side menu',
    showTotals: 'Show totals',
    showVersionInfo: 'Show version info on PDF',
    signatureField: 'Signature',
    signedBy: 'Signed by',
    signingHeader: 'Signing',
    signingInfo:
      'To sign the form, it must first be validated, to check that it meets the requirements for submission. If no significant errors are discovered, you will have the opportunity to sign and submit. It is also possible to save the form in Altinn before submitting.',
    site: 'Facility',
    siteAddress: 'Facility address',
    siteInformation: 'Facility information',
    sites: 'Facility',
    socialCostsFactor: 'Factor for social costs',
    somethingWentWrong: 'Something went wrong!',
    refreshPage: 'Refresh page',
    somethingWentWrongError: 'Something went wrong, please try again.',
    sort: 'Sort',
    sosialCosts: 'Social costs',
    specification: 'Specification',
    specificationUpdated: 'The specification is now updated',
    spesificationSelectFieldsToAddDiscount:
      'You must select fields in the specification to add discounts and surcharges',
    squareMeter_short: 'm2',
    squareMeter: 'Square meters',
    start: 'Start',
    startDate: 'Start date',
    status: 'Status',
    stdParamName: 'Name of method',
    stdParams_short: 'Std. parameters',
    stdParams: 'Standardparameters',
    stdText_short: 'Std. text',
    stdText: 'Standard text',
    subcontract: 'Sub-contracting',
    sumUnitPrice: 'Total unit price',
    supplier: 'Supplier',
    supplierName: 'Supplier name',
    suppliers: 'Suppliers',
    surname: 'Surname',
    table: 'Table',
    tableField: 'Table',
    takeBreakAndPlay: 'We know how frustrating it can be. Take a deep breath and play a round.',
    technicalInformation: 'Technical description',
    telephoneNumber: 'Telephone number',
    telephoneNumbers: 'Telephone number',
    templateCatalog: 'Template catalog',
    templateInformation: 'Template information',
    tenant: 'Tenant',
    tenantAdmin: 'Tenantadmin',
    tenantAlreadyExists: 'Tenant already exists in the organization',
    tenantDeleted: 'Tenant was deleted',
    tenantId: 'Tenant-id',
    tenants: 'Tenants',
    text: 'Text',
    textField: 'Text field',
    theApplication: 'Application',
    theCompany: 'the company',
    title: 'Title',
    total: 'Total',
    totalAmount: 'Total amount',
    totalAmountChecklists: 'Total amount of checklists',
    totalCost: 'Total self-catering',
    totalCosts: 'Total costs',
    totalCustomerMaterialCost: "Customer's total price material",
    totalCustomerWorkCost: 'Total customer costs work',
    totalMaterialCost: 'Total cost material',
    totalPartsSurcharge: 'Total parts/surcharge',
    totalProfit: 'Total earnings',
    totalProfitPercent: 'Total earnings',
    totals: 'Totals',
    totalsMaterialWork: 'Material and work',
    totalUnitPrice: 'Total unit price',
    totalVatAmount: 'Total including VAT',
    totalWorkCost: 'Total work cost',
    travelCostAmount: 'Travel cost amount',
    travelCostAmount2: 'Travel cost amount',
    type: 'Type',
    under: 'Under',
    unit: 'Unit',
    unitPrice: 'Unit price',
    unitPricesPosNumber: 'Total unit prices with position number',
    unknown: 'Unknown',
    unknowNodetype: 'Unknown node type',
    unLockPrice: 'Unlock price',
    update: 'Refresh',
    updateParams: 'Updating parameters',
    uploadCsvUsersInstructions:
      'For the file to be uploaded correctly, the first line of the file must be a header consisting of',
    uploadFromCsvFile: 'Upload from CSV file',
    uploadOneFileError: 'It is only possible to upload one file at a time.',
    uploadUsersFromACsvFile: 'Upload users from a csv file.',
    useNo: 'User No.',
    user: 'User',
    userActivated: 'User was activated',
    userCountInvalid: 'User count must be 0 or more',
    userInformation: 'User information',
    userLicense: 'User license',
    userLicenses: 'User licenses',
    username: 'Username',
    userRole: 'User role',
    userRoles: 'User roles',
    users: 'Users',
    userSettings: 'User settings',
    userType: 'User type',
    userTypeRequired: 'User type must be chosen',
    userWillReceiveMail:
      'If you have a user in our system, you will now receive an email from us. Follow the instructions in this email.',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    vatAmount: 'VAT amount',
    vendor: 'Supplier',
    vendorDiscountPercent: 'Discount from supplier',
    vendorDiscountSource: 'Discount from supplier',
    vendorInfo: 'Supplier Information',
    vendorNumber: 'Supplier No.',
    vendors: 'Suppliers',
    version: 'Version',
    versions: 'Versions',
    viewFullTable: 'Click to view full table',
    viewing: 'Viewing',
    volume: 'Volume',
    volumeUnit: 'Unit Volume 1',
    wasAdded: 'was added',
    weight: 'Weight',
    weightUnit: 'Unit Weight',
    whitespace: ' ',
    withinValidLicenseDate: 'Date must be within the validity of the license',
    work: 'Jobs',
    workAmountFactor: 'Factor for work amount',
    workCost: 'Work cost',
    workCostFactor: 'Factor for work cost',
    workCostPrice_short: 'Self-catering work.',
    workCostPrice: 'Full cost',
    workCostPriceOfferTable: 'workCostPriceOfferTable',
    workCostPricePerUnit: 'Cost per unit',
    workCostSum: 'workCostSum',
    workCustomerAmount: 'workCustomerAmount',
    workCustomerPrice: 'workCustomerPrice',
    WorkDiscountPercent: 'Discount on work in percent',
    workMarkupFactor: 'Factor for work markup',
    workMarkupPercent_short: 'Surcharge matr.',
    WorkMarkupPercent: 'Surcharge for work as a percentage',
    workMarkupPerUnit: 'Surcharge per unit',
    workMarkupSource: 'Basis for surcharge on work',
    workNetCost: 'Work net cost',
    workNetCostFactor: 'Factor for work net cost',
    workPrice: 'Price for work',
    workProfitPercent: 'workProfitPercent',
    workTravelCostAmountFactor: 'Factor for work travel cost amount',
    wouldYouLikeToincludeRoutinesParagraph: "Do you want to include the routine's paragraphs",
    wouldYouLikeToOpen: 'Would you like to open?',
    wouldYouLikeToOpenIt: 'Would you like to open it?',
    wouldYouLikeToRestore: 'Would you like to restore?',
    wouldYouLikeToRestoreIt: 'Would you like to restore it?',
    write: 'Write',
    wrongFileTypeError: 'Incorrect file type. You can only upload xml files.',
    wrongUrl: 'The URL is incorrect or not available. Please go back and try again.',
    wrongUsernameOrPassword: 'You entered the wrong username or password',
    yardNo: 'YardNo',
    yes: 'Yes',
    yesDeletePermanent: 'Yes, delete permanently',
  },
});

export default strings;
