import { BUILDING_ELEMENTS_ACTIONS } from './types';

import { IBuildingElementsState } from './../BuildingElementsInterfaces';
import {
  LOAD_ALL_BUILDING_ELEMENTS_SUCCESS,
  SET_BUILDING_ELEMENTS_LOADER,
  SAVE_BUILDING_ELEMENTS_ITEM,
} from './buildingElementsActions';

const defaultState: IBuildingElementsState = {
  error: null,
  isItemsLoading: false,
  isItemLoading: false,
  items: [],
  totalItemsNumber: 0,
  selectedItem: null,
  deletedItems: null,
  searchedItems: null,
  createdItem: null,
  hasMoreItems: true,
};

const buildingElementsReducer = (state = defaultState, action: BUILDING_ELEMENTS_ACTIONS) => {
  switch (action.type) {
    case SET_BUILDING_ELEMENTS_LOADER:
      return { ...state, isItemLoading: action.isLoading };
    case LOAD_ALL_BUILDING_ELEMENTS_SUCCESS:
      if (action.overWrite)
        return {
          ...state,
          items: action.allBuildingElements,
          isItemsLoading: false,
          totalItemsNumber: action.totalBuildingElementsNumber,
        };
      return {
        ...state,
        items: action.allBuildingElements,
        isItemLoading: false,
        totalItemsNumber: action.totalBuildingElementsNumber,
      };
    case SAVE_BUILDING_ELEMENTS_ITEM:
      return { ...state, selectedItem: action.buildingElementItem };
    default:
      return state;
  }
};

export default buildingElementsReducer;
