import { TabProps } from '@mui/material';

import { TabItemStyled } from './TabItem.styled';

function TabItem(props: TabProps) {
  return (
    <TabItemStyled
      component="button"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default TabItem;
