import { FC } from 'react';

type MOMTemplateIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MOMTemplateIcon: FC<MOMTemplateIconPropTypes> = ({
  width = 24,
  height = 24,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 4H8C7.45 4 7 4.45 7 5V18C7 18.55 7.45 19 8 19H16C16.55 19 17 18.55 17 18V5C17 4.45 16.55 4 16 4Z"
        fill={color}
      />
      <path d="M4 6C3.45 6 3 6.45 3 7V16C3 16.55 3.45 17 4 17C4.55 17 5 16.55 5 16V7C5 6.45 4.55 6 4 6Z" fill={color} />
      <path
        d="M19 7C19 6.45 19.45 6 20 6C20.55 6 21 6.45 21 7V16C21 16.55 20.55 17 20 17C19.45 17 19 16.55 19 16V7Z"
        fill={color}
      />
    </svg>
  );
};

export default MOMTemplateIcon;
