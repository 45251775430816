import { FC } from 'react';

type BriefcasePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Briefcase: FC<BriefcasePropTypes> = ({ width = 28, height = 27, color = '#32226B', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M24.8944 5.93504H20.7892V3.19823C20.7892 1.6793 19.5713 0.461426 18.0524 0.461426H9.842C8.32308 0.461426 7.1052 1.6793 7.1052 3.19823V5.93504H2.99999C1.48106 5.93504 0.263184 7.15292 0.263184 8.67184V23.7243C0.263184 25.2432 1.48106 26.4611 2.99999 26.4611H24.8944C26.4134 26.4611 27.6312 25.2432 27.6312 23.7243V8.67184C27.6312 7.15292 26.4134 5.93504 24.8944 5.93504ZM9.842 3.19823H18.0524V5.93504H9.842V3.19823ZM24.8944 23.7243H2.99999V20.9875H24.8944V23.7243ZM24.8944 16.8823H2.99999V8.67184H7.1052V11.4086H9.842V8.67184H18.0524V11.4086H20.7892V8.67184H24.8944V16.8823Z"
        fill={color}
      />
    </svg>
  );
};

export default Briefcase;
