import { FC } from 'react';

type UnfoldMorePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const UnfoldMore: FC<UnfoldMorePropTypes> = ({ width = 9, height = 18, color = '#C3CAD9', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1725 2.42249L6.6325 4.88249C7.0225 5.27249 7.6525 5.27249 8.0425 4.88249C8.4325 4.49249 8.4325 3.86249 8.0425 3.47249L4.8725 0.292486C4.68567 0.105233 4.43202 0 4.1675 0C3.90298 0 3.64933 0.105233 3.4625 0.292486L0.2925 3.47249C-0.0975 3.86249 -0.0975 4.49249 0.2925 4.88249C0.6825 5.27249 1.3125 5.27249 1.7025 4.88249L4.1725 2.42249ZM4.1725 14.7625L1.7125 12.3025C1.52567 12.1152 1.27202 12.01 1.0075 12.01C0.742982 12.01 0.489332 12.1152 0.3025 12.3025C-0.0875 12.6925 -0.0875 13.3225 0.3025 13.7125L3.4725 16.8925C3.8625 17.2825 4.4925 17.2825 4.8825 16.8925L8.0525 13.7225C8.4425 13.3325 8.4425 12.7025 8.0525 12.3125C7.86567 12.1252 7.61202 12.02 7.3475 12.02C7.08298 12.02 6.82933 12.1252 6.6425 12.3125L4.1725 14.7625Z"
        fill={color}
      />
    </svg>
  );
};

export default UnfoldMore;
