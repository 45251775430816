import { t } from 'i18next';

import { Typography } from '@mui/material';
import { Theme, createTheme } from '@mui/material/styles';

import { IconItem } from 'components/Icons';
import * as icons from 'components/Icons/index';

import { OfferFilters, DynamicFilterTypes } from 'modules/Offers/offersInterfaces';
import API from 'utils/API';

export const search = async (url: string) => {
  return API.get(url).then(({ data: { hits, totalHits } }: any) => {
    return { hits, totalHits };
  });
};

export const searchByPost = async (url: string, data: any) => {
  return API.post(url, data).then(({ data: { hits, totalHits } }: any) => {
    return { hits, totalHits };
  });
};

export const getRootUrl = (url: string): string => {
  return `/${url.split('/')[1]}`;
};

export const getSubRootUrl = (url: string): string => {
  return `/${url.split('/')[2]}`;
};

export const stringAvatar = (name: string) => {
  return {
    children: `${name.split(' ')?.[0]?.[0]}${name.split(' ')?.[1]?.[0]}`,
  };
};
/**
 *
 * @param arr the array we want to filter
 * @param key the key by which we want to filter the array
 * @param value the value of that key by which we want to filter
 * @returns a new array with that object removed
 */
export const removeObjectByKeyInArray = (arr: any[], key: string, value: string | number) => {
  return arr.filter((obj) => obj[key] !== value);
};

export const formatDate = (date?: Date | string) => {
  if (!date) return '';
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const newDate = new Date(date);
  if (!newDate) return '';

  return `${newDate.getDate()} ${monthNames[newDate.getMonth()]}, ${newDate.getFullYear()}`;
};

export const formatTime = (date?: Date | string) => {
  if (!date) return '';
  const newDate = new Date(date);
  if (!newDate) return '';
  const hours = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
  const minutes = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
  return `${hours}:${minutes}`;
};

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return;
  if (typeof word !== 'string') return;

  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const dateIsValid = (date: Date | string) => {
  return !Number.isNaN(new Date(date).getTime());
};

export const getDayMonthYearFormat = (date?: Date | string) => {
  if (!date) return '';

  const newDate = new Date(date);
  // if dd or mm is less than 10, add a 0 to the front
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  if (day < 10) day = `0${day}` as any;
  if (month < 10) month = `0${month}` as any;
  return `${day}.${month}.${newDate.getFullYear()}`;
};

export const correctTimeZoneOffset = (date: Date | string) => {
  if (date === null || date === undefined) return '';
  if (!dateIsValid(date)) return '';
  const timeZoneOffset = new Date(date).getTimezoneOffset() * 60000;
  return new Date(new Date(date).getTime() - timeZoneOffset).toISOString();
};

export const getPageTitleFromUrl = (url: string) => {
  const splitUrl = url.split('/');
  const page = splitUrl[splitUrl.length - 1] || '';
  if (page === '') return 'Dashboard';
  if (page === 'adjustments') return 'Collective Adjustments';
  return page.charAt(0).toUpperCase() + page.slice(1);
};

export const hoursBeforeDeadline = (deadline: Date | string) => {
  const now = new Date();
  const deadlineDate = new Date(deadline);
  const diff = deadlineDate.getTime() - now.getTime();
  return Math.ceil(diff / (1000 * 60 * 60));
};

export const parseNum = (value: any, decimalSep = '.') => {
  if (value == null) return NaN;
  // Return the value as-is if it's already a number:
  if (typeof value === 'number') return value;

  // build regex to strip out everything except digits, decimal point and minus sign:
  var regex = new RegExp('[^0-9-' + decimalSep + ']', 'g');
  var unformatted = value.toString(); // explicitly convert to string
  unformatted = unformatted
    // .replace(/\((.*)\)/, '-$1') // replace bracketed values with negatives
    .replace(regex, '') // strip out any cruft
    .replace(decimalSep, '.'); // make sure decimal point is standard

  unformatted = parseFloat(unformatted);

  return unformatted;
};

export const defaultOptions: NumberFormatArgumenTypes = {
  locale: 'en-US',
  minimumFractionDigits: 0,
  style: 'currency',
  currency: 'USD',
  showCurrency: true,
};

export const formatNum = (number: any, showCurrency: boolean = false, currency: Currency = 'USD') => {
  const options = { ...defaultOptions, currency, showCurrency };
  let auxNumber = parseNum(number);

  if (isNaN(auxNumber)) return '0.00';

  let result = new Intl.NumberFormat(options.locale, { ...options, signDisplay: 'auto' }).format(number);
  if (!options.showCurrency) result = result.slice(1);
  return result;
};

export const formatNumAccordingLang = (number: any) => {
  let locale = localStorage?.getItem('i18nextLng') || 'no-NB';
  const options = { locale, minimumFractionDigits: 2 };
  return new Intl.NumberFormat(locale, { ...options }).format(number);
};

export const numberFormat = (number: any) => {
  number = parseNum(number);

  if (isNaN(number)) return '0.00';

  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(number);
};

export const formatCellNumber = (number?: number | string) => {
  let userLocale = localStorage?.getItem('i18nextLng') || 'no-NB';
  let num = number?.toLocaleString(userLocale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  });
  return num ?? '';
};

export const hasObjectAtLeastOneTruthyProperty = (obj: any): boolean => {
  // Iterate through each property in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const property = obj[key];

      // Check if the property is an object
      if (typeof property === 'object' && property !== null) {
        // Check if the object is not empty
        if (Object.keys(property).length !== 0) {
          return true;
        }
      }

      // Check if the property is a string and is truthy
      if (typeof property === 'string' && property) {
        return true;
      }

      // Check if the property is an array and is truthy (length is more than 0)
      if (Array.isArray(property) && property.length > 0) {
        return true;
      }
    }
  }

  // Return false if all properties are falsy
  return false;
};

export const renderGreeting = (name: string = '') => {
  const today = new Date();
  const curHr = today.getHours();
  const greet = t('globals.goodMorning') + (name ? `, ${name}!` : '!');
  if (curHr < 12) {
    return (
      <Typography variant="h3" component="h3">
        <>{greet}</>
      </Typography>
    );
  } else if (curHr < 18) {
    return (
      <Typography variant="h3" component="h3">
        <>
          {t('globals.goodAfternoon') + ','} {name + '!'}
        </>
      </Typography>
    );
  } else {
    return (
      <Typography variant="h3" component="h3">
        <>
          {t('globals.goodEvening') + ','} {name + '!'}
        </>
      </Typography>
    );
  }
};

export function areArraysEqual(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const frequencyCounter1: { [key: string]: number } = {};
  const frequencyCounter2: { [key: string]: number } = {};

  for (const element of arr1) {
    frequencyCounter1[element] = (frequencyCounter1[element] || 0) + 1;
  }

  for (const element of arr2) {
    frequencyCounter2[element] = (frequencyCounter2[element] || 0) + 1;
  }

  for (const key in frequencyCounter1) {
    if (!(key in frequencyCounter2)) {
      return false;
    }

    if (frequencyCounter1[key] !== frequencyCounter2[key]) {
      return false;
    }
  }

  return true;
}

export function convertFiltersBack(filters: OfferFilters): DynamicFilterTypes {
  const newFilters: DynamicFilterTypes = {
    categories: '',
    dateFilter: 'None',
    dateFrom: null,
    dateTo: null,
    tags: [],
    statuses: '',
    amountFilter: 'None',
    amountFrom: '',
    amountTo: '',
    responsibleUserId: '',
    customerId: '',
  };
  if (!filters) return newFilters;
  if (filters?.categoryId) newFilters.categories = filters.categoryId;
  if (filters?.tags) newFilters.tags = filters.tags.map((tag: string) => ({ value: tag, label: tag })) || [];
  if (filters?.fromSentToCustomer || filters.toSentToCustomer) newFilters.dateFilter = 'SentToCustomer' || null;
  if (filters?.fromPlannedFollowupDate || filters.toPlannedFollowupDate)
    newFilters.dateFilter = 'PlannedFollowUpDate' || 'None';
  if (filters?.fromAcceptedByCustomer || filters.toAcceptedByCustomer)
    newFilters.dateFilter = 'AcceptedByCustomer' || 'None';
  if (filters?.fromRejectedByCustomer || filters.toRejectedByCustomer)
    newFilters.dateFilter = 'RejectedByCustomer' || 'None';
  if (filters?.fromSentToCustomer) newFilters.dateFrom = filters.fromSentToCustomer || null;
  if (filters?.toSentToCustomer) newFilters.dateTo = filters.toSentToCustomer || null;
  if (filters?.fromPlannedFollowupDate) newFilters.dateFrom = filters.fromPlannedFollowupDate || null;
  if (filters?.toPlannedFollowupDate) newFilters.dateTo = filters.toPlannedFollowupDate || null;
  if (filters?.fromAcceptedByCustomer) newFilters.dateFrom = filters.fromAcceptedByCustomer || null;
  if (filters?.toAcceptedByCustomer) newFilters.dateTo = filters.toAcceptedByCustomer || null;
  if (filters?.fromRejectedByCustomer) newFilters.dateFrom = filters.fromRejectedByCustomer || null;
  if (filters?.toRejectedByCustomer) newFilters.dateTo = filters.toRejectedByCustomer || null;
  if (filters?.fromCustomerAmount || filters.toCustomerAmount) newFilters.amountFilter = 'CustomerAmount' || 'None';
  if (filters?.fromContribution || filters.toContribution) newFilters.amountFilter = 'Contribution' || 'None';
  if (filters?.fromCustomerAmount) newFilters.amountFrom = filters.fromCustomerAmount || '';
  if (filters?.toCustomerAmount) newFilters.amountTo = filters.toCustomerAmount || '';
  if (filters?.fromContribution) newFilters.amountFrom = filters.fromContribution || '';
  if (filters?.toContribution) newFilters.amountTo = filters.toContribution || '';
  if (filters?.responsibleUserId) newFilters.responsibleUserId = filters.responsibleUserId || '';
  return newFilters;
}

export function areTwoObjectsSame(
  obj1: Record<string, string | number>,
  obj2: Record<string, string | number>
): boolean {
  if (!obj1 || !obj2) return false;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of properties is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the properties and compare their values
  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    // Check if the property exists in both objects
    if (!(key in obj2)) {
      return false;
    }

    // Check if the property values are the same
    if (value1 !== value2) {
      return false;
    }
  }

  // All properties have the same values
  return true;
}

export const getNonFalsyValues = (args: string[]) => {
  const truthyValyes = args.filter((value) => value !== null && value !== undefined && value !== '').join(', ');
  return truthyValyes;
};
export const dateThirtyDaysFromNow = () => {
  const today = new Date();
  const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 30));
  return thirtyDaysFromNow;
};

export const dateThirtyDaysFromNowAsIsoString = () => dateThirtyDaysFromNow().toISOString();

export const findMissingObjects = (firstArray: string[] = [''], secondArray: string[] = ['']): string[] => {
  const missingString = [];

  for (const objArray of secondArray) {
    const found = firstArray.includes(objArray);
    if (!found) {
      missingString.push(objArray);
    }
  }

  return missingString;
};
export const stripTrailingSlash = (str?: string) => {
  if (!str) return '';
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const customTagColors = (tag: string, theme: Theme) => {
  const color = {
    'a-f': { bgColor: theme.palette.error.main, color: theme.palette.error.contrastText },
    'g-m': { bgColor: theme.palette.success.main, color: theme.palette.success.light },
    'n-s': { bgColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
    't-z': { bgColor: theme.palette.purple.main, color: theme.palette.purple.contrastText },
    default: { bgColor: theme.palette.error.main, color: theme.palette.error.contrastText },
  };
  const charCode = tag.charCodeAt(0);
  if (charCode >= 'a'.charCodeAt(0) && charCode <= 'f'.charCodeAt(0)) return color['a-f'];
  if (charCode >= 'g'.charCodeAt(0) && charCode <= 'm'.charCodeAt(0)) return color['g-m'];
  if (charCode >= 'n'.charCodeAt(0) && charCode <= 's'.charCodeAt(0)) return color['n-s'];
  if (charCode >= 't'.charCodeAt(0) && charCode <= 'z'.charCodeAt(0)) return color['t-z'];

  return color['default'];
};

export const isNumeric = (value: string) => /^\d+$/.test(value);

export const currencies = [
  { label: 'EUR', value: 'EUR' },
  { label: 'NOK', value: 'NOK' },
  { label: 'SEK', value: 'SEK' },
  { label: 'USD', value: 'USD' },
  { label: 'GBP', value: 'GBP' },
  { label: 'DKK', value: 'DKK' },
  { label: 'PLN', value: 'PLN' },
  { label: 'ISK', value: 'ISK' },
  { label: 'JPY', value: 'JPY' },
  { label: 'CNY', value: 'CNY' },
];

// colors

export function hexToRgb(hex: string) {
  if (hex.length < 6) return 'Hex needs to be 6 characters long';
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgb(${r}, ${g}, ${b})`;
}

export function rgbStringToHex(rgbString: string): string | null {
  if (rgbString === 'transparent' || rgbString[0] === '#') return rgbString;

  const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  if (!match) {
    // Invalid RGB string format
    return null;
  }

  const [, r, g, b] = match.map(Number);

  const validR = Math.min(255, Math.max(0, r || 0));
  const validG = Math.min(255, Math.max(0, g || 0));
  const validB = Math.min(255, Math.max(0, b || 0));

  const hex = ((validR << 16) | (validG << 8) | validB).toString(16).padStart(6, '0');

  // Prepend a hash (#) to the hex string
  return `#${hex}`.toLocaleLowerCase();
}

export const resolveThemeColor = (colorValue: string, theme: Theme) => {
  let splitColor: string[] = [];
  if (colorValue.includes('.')) {
    splitColor = colorValue.split('.');
  }
  if (colorValue.includes('[')) {
    splitColor = colorValue.split('[');
    splitColor[1] = splitColor[1]?.replace(']', '') ?? '';
  }
  if (splitColor.length > 2) {
    splitColor.splice(0, 2);
  }

  const colorFromTheme = theme.palette[splitColor[0]!]?.[splitColor[1]!] ?? '';
  return colorFromTheme || colorValue; // return color prop value if not found in the theme
};
export const renderCopyName = (name: string) => {
  if (!name) return '';
  const words = [...name.split(' ')];
  const lastWord = words[words.length - 1];
  const secondLastWord = words[words.length - 2];

  if (lastWord === 'copy') {
    words[words.length - 2] = ' - ';
    words[words.length - 1] = 'copy 1';
  } else if (secondLastWord === 'copy' && !isNaN(Number(lastWord))) {
    words[words.length - 3] = ' - ';
    words[words.length - 2] = 'copy';
    words[words.length - 1] = `${Number(lastWord) + 1}`;
  } else {
    words.push(' - copy');
  }

  return words.join(' ');
};

export const getRoute = (url: string) => {
  const splitUrl = url.split('/');
  const page = splitUrl[1];
  if (page === 'v1') {
    return splitUrl.slice(1, 3).join('/');
  }
  return page ?? '';
};

export const getContrastText = (backgroundColor: string) => {
  const theme = createTheme();

  // Use the getContrastText function from the theme
  return theme.palette.getContrastText(backgroundColor);
};

// RENDER HELPERS
export const renderIcon = (icon: keyof typeof icons | JSX.Element, iconColor: string) => {
  if (typeof icon === 'string') return <IconItem name={icon} defaultColor={iconColor} />;
  return icon;
};
