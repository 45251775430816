import { FC } from 'react';

type FileAttachedPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const FileAttached: FC<FileAttachedPropTypes> = ({ width = 16, height = 16, color = '#6B7A99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.40785 1.25391H3.76311C3.0732 1.25391 2.50873 1.81838 2.50873 2.50829V12.5434C2.50873 13.2333 3.0732 13.7978 3.76311 13.7978H11.2894C11.9793 13.7978 12.5438 13.2333 12.5438 12.5434V4.38987L9.40785 1.25391ZM3.76311 12.5434V2.50829H8.78066V5.01706H11.2894V12.5434H3.76311ZM10.035 6.27145V9.40741C10.035 10.7935 8.91237 11.9162 7.52627 11.9162C6.14018 11.9162 5.0175 10.7935 5.0175 9.40741V5.33066C5.0175 4.40869 5.80776 3.67487 6.74855 3.76895C7.5639 3.85049 8.15346 4.59685 8.15346 5.41847V9.40741H6.89908V5.33066C6.89908 5.15505 6.7611 5.01706 6.58548 5.01706C6.40987 5.01706 6.27189 5.15505 6.27189 5.33066V9.40741C6.27189 10.0973 6.83636 10.6618 7.52627 10.6618C8.21618 10.6618 8.78066 10.0973 8.78066 9.40741V6.27145H10.035Z"
        fill={color}
      />
    </svg>
  );
};

export default FileAttached;
