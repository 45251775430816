import { FC } from 'react';

type RewindPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Rewind: FC<RewindPropTypes> = ({ width = 34, height = 34, color = '#202236', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.2629 14.5728H19.9851L23.7345 10.714C19.9988 7.01926 13.9505 6.88242 10.2147 10.5771C6.47898 14.2855 6.47898 20.2654 10.2147 23.9738C13.9505 27.6821 19.9988 27.6821 23.7345 23.9738C25.5956 22.1401 26.5261 19.9917 26.5261 17.2823H29.2629C29.2629 19.9917 28.0587 23.5085 25.6503 25.8895C20.8472 30.6516 13.0473 30.6516 8.24422 25.8895C3.45481 21.1412 3.41376 13.4234 8.21685 8.67503C13.0199 3.92667 20.7241 3.92667 25.5271 8.67503L29.2629 4.82982V14.5728ZM17.6315 11.6718V17.4875L22.4209 20.3338L21.4356 21.9896L15.5789 18.5138V11.6718H17.6315Z"
        fill={color}
      />
    </svg>
  );
};

export default Rewind;
