import { FC } from 'react';

type VendorPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Vendor: FC<VendorPropTypes> = ({ width = 30, height = 30, color = '#78603D', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 5H5V7.5H25V5ZM26.25 17.5V15L25 8.75H5L3.75 15V17.5H5V25H17.5V17.5H22.5V25H25V17.5H26.25ZM15 22.5H7.5V17.5H15V22.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Vendor;
