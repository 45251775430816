import { IVendor } from '../vendorsInterfaces';

export const VendorInitial: IVendor = {
  id: '',
  name: '',
  description: '',
  vendorNumber: 0,
  ourCustomerNumber: '',
  deleted: false,
  created: '',
  createdBy: '',
  lastUpdated: '',
  lastUpdatedBy: '',
  person: null,
  company: null,
  externalReference: null,
};
