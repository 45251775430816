import { t } from 'i18next';

export const frequencies = [
  { value: 'Blank', label: t('momTemplates.blank') },
  { value: 'Daily', label: t('momTemplates.daily') },
  { value: 'Weekly', label: t('momTemplates.weekly') },
  { value: 'Monthly', label: t('momTemplates.monthly') },
  { value: 'Every second month', label: t('momTemplates.everySecondMonth') },
  { value: 'Quarterly', label: t('momTemplates.quarterly') },
  { value: 'Biannually', label: t('momTemplates.biannually') },
  { value: 'Annually', label: t('momTemplates.annually') },
  { value: 'Every two years', label: t('momTemplates.everyTwoYears') },
  { value: 'When needed', label: t('momTemplates.whenNeeded') },
  { value: 'After use', label: t('momTemplates.afterUse') },
  { value: 'Not relevant', label: t('momTemplates.notRelevant') },
];

export const checkIfUpdatingStatus = (obj: object) => {
  const statusFields = ['done', 'generalInfoDone', 'attachmentsDone', 'instructionsDone', 'productsDone'];
  return statusFields.some((s) => s in obj);
};
