import { FC, ReactElement } from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import Zoom from '@mui/material/Zoom';

type TProps = {
  children: ReactElement;
  title: string;
} & TooltipProps;

export const Tooltip: FC<TProps> = ({ title, children, ...props }) => {
  return (
    <MuiTooltip
      title={title}
      placement="top-start"
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 400 }}
      {...props}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
