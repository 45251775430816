import { FC } from 'react';

type EditPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Edit: FC<EditPropTypes> = ({ width = 28, height = 29, color = '#935D64', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.1799 25.5885L22.9274 18.3359H21.5727L18.0969 21.8117V23.1664L25.3495 30.4189C25.8831 30.9526 26.7452 30.9526 27.2789 30.4189L30.1799 27.5179C30.7136 26.9979 30.7136 26.1221 30.1799 25.5885Z"
        fill={color}
      />
      <path
        d="M24.2546 14.6686L26.1841 12.7392L29.0851 15.6402C30.6861 14.0392 30.6861 11.4392 29.0851 9.83818L24.2409 4.99403L22.3115 6.92348V3.06458L21.3536 2.09302L16.5094 6.93716L17.481 7.90873H21.3536L19.4241 9.83818L20.8746 11.2887L16.92 15.2434L11.2685 9.59186V7.64873L7.13589 3.51616L3.26331 7.38874L7.40957 11.535H9.33901L14.9905 17.1865L13.8274 18.3496H10.9264L3.67383 25.6022C3.14015 26.1359 3.14015 26.9979 3.67383 27.5316L6.57484 30.4326C7.10852 30.9663 7.97061 30.9663 8.50429 30.4326L15.7568 23.1801V20.2791L22.8041 13.2318L24.2546 14.6686Z"
        fill={color}
      />
    </svg>
  );
};

export default Edit;
