import { he } from 'date-fns/locale';
import { FC } from 'react';

type PaperPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Paper: FC<PaperPropTypes> = ({ width = 34, height = 34, color = '#0774A4', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.684 3.46143H8.73681C7.23156 3.46143 6 
            4.69299 6 6.19823V28.0927C6 29.5979 7.21788 30.8295 
            8.72312 30.8295H21.0524V19.8823H27.8944V11.6718L19.684 
            3.46143ZM18.3156 13.0402V5.51403L25.8418 13.0402H18.3156ZM23.7892 
            30.3642V22.6191H31.5344V25.3559H28.4692L32.506 29.3927L30.5765 
            31.3221L26.526 27.2853V30.3505H23.7892V30.3642Z"
        fill={color}
      />
    </svg>
  );
};

export default Paper;
