import { IVendor, IVendorState } from '../vendorsInterfaces';
import {
  IS_VENDORS_LOADING,
  LOAD_ALL_VENDORS,
  DELETE_VENDOR,
  LOAD_DELETED_VENDORS,
  RESTORE_VENDOR,
  IS_VENDORS_DIALOG_LOADING,
  CREATE_VENDOR,
  EDIT_VENDOR,
  LOAD_VENDOR_ITEM,
  HARD_DELETE_VENDOR,
} from './vendorsActions';
import { VendorInitial } from './initialState';
import { VENDORS_ACTIONS } from './types';

const defaultState: IVendorState = {
  items: [VendorInitial],
  selectedItem: VendorInitial,
  totalItems: 0,
  isLoading: false,
  isDialogLoading: false,
  deletedItems: [VendorInitial],
  error: {},
};

export const vendorsReducer = (state = defaultState, action: VENDORS_ACTIONS) => {
  switch (action.type) {
    case IS_VENDORS_LOADING:
      return { ...state, isLoading: action.isLoading };

    case IS_VENDORS_DIALOG_LOADING:
      return { ...state, isDialogLoading: action.isDialogLoading };

    case CREATE_VENDOR:
      return { ...state, items: [...state.items, { ...action.vendorItem }] };

    case LOAD_VENDOR_ITEM:
      return { ...state, selectedItem: action.vendor };

    case LOAD_ALL_VENDORS:
      return { ...state, items: action.vendors, totalItems: action.totalItems };
    case LOAD_DELETED_VENDORS:
      return { ...state, deletedVendors: action.deletedVendors };

    case EDIT_VENDOR:
      return {
        ...state,
        selectedItem: action.vendorItem,
      };

    case DELETE_VENDOR:
    case HARD_DELETE_VENDOR:
    case RESTORE_VENDOR:
      const deletedItem = action.vendorItem as IVendor;
      const key = action.type === DELETE_VENDOR ? 'items' : 'deletedItems';
      const deletedItems = state[key]?.filter(({ id }: IVendor) => id !== deletedItem.id);
      return { ...state, [key]: deletedItems };
  }
  return state;
};

export default vendorsReducer;
