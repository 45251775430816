import { ReactElement } from 'react';
import logo from 'assets/images/logo.svg';
import { Stack, Collapse, Typography } from '@mui/material';

const Logo = (props: { hideText: boolean }): ReactElement => {
  const { hideText = false } = props;
  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} sx={{ px: 1, py: 2 }}>
        <img src={logo} alt="imageLogo" width="40" />
        <Collapse in={!hideText} orientation="horizontal">
          <Typography variant="h2">cordel</Typography>
        </Collapse>
      </Stack>
    </>
  );
};

export default Logo;
