// @ts-nocheck
import { TableRow as MuiTableRow, TableRowProps, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

const TableRowStyled = styled(MuiTableRow, {
  shouldForwardProp: (prop) => prop !== 'xPadding',
})(({ xPadding, theme }) => {
  return {
    '& > td:first-of-type, & > th:first-of-type': {
      paddingLeft: xPadding + 'px',
    },
    '& > td:last-of-type,& > th:last-of-type': {
      paddingRight: xPadding + 'px',
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: theme.palette.secondary.lighter,
    },
  };
});

interface ITableRow extends TableRowProps {
  xPadding?: number;
  isHover?: boolean;
}

export const TableRow: FC<ITableRow & TableRowProps> = (props) => {
  const { children, xPadding = 32, isHover = false, ...rest } = props;
  const { palette } = useTheme();
  return (
    <TableRowStyled
      xPadding={xPadding}
      {...rest}
      sx={{ background: isHover ? palette.primary.lighter : 'none', cursor: 'pointer' }}>
      {children}
    </TableRowStyled>
  );
};

export default TableRow;
