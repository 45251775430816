import { FC } from 'react';

type NewAddIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const NewAddIcon: FC<NewAddIconPropTypes> = ({ width = 40, height = 40, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6359_4985)">
        <rect x="5" y="3" width="30" height="30" rx="15" fill="#046B99" />
      </g>
      <path
        d="M19.9997 11.3333C16.3197 11.3333 13.333 14.32 13.333 18C13.333 21.68 16.3197 24.6667 19.9997 24.6667C23.6797 24.6667 26.6663 21.68 26.6663 18C26.6663 14.32 23.6797 11.3333 19.9997 11.3333ZM23.333 18.6667H20.6663V21.3333H19.333V18.6667H16.6663V17.3333H19.333V14.6667H20.6663V17.3333H23.333V18.6667Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_6359_4985"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6359_4985" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6359_4985" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default NewAddIcon;
