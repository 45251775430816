import CloseIcon from '@mui/icons-material/Close';

import { Box, Dialog, LinearProgress, DialogProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { useModuleList } from 'modules/common';

interface INewDialogWrapper extends DialogProps {
  children: ReactNode;
  open: boolean;
  onConfirm?: () => void;
  showClose?: boolean;
  isDialogLoading?: boolean;
  closeHandler?: () => void;
  hideCloseIcon?: boolean;
  title: string;
  titleBarColor?: string;
  id?: string;
}
const NewDialogWrapper = (props: INewDialogWrapper) => {
  const { closeDialog: clearDialog } = useModuleList();
  const {
    children,
    onConfirm,
    closeHandler,
    open = false,
    maxWidth = 'sm',
    hideCloseIcon = false,
    isDialogLoading = false,
    title,
    titleBarColor = 'primary.main',
    id,
    onClose,
    ...rest
  } = props;

  const closeDialog = () => {
    if (closeHandler) {
      closeHandler();
    } else {
      clearDialog();
    }
  };

  return (
    <Dialog id={id} fullWidth open={open} onClose={onClose} maxWidth={maxWidth} sx={{ height: '100%' }} {...rest}>
      {isDialogLoading && <LinearProgress color="secondary" />}
      <Box sx={{ p: 3, height: '100%', backgroundColor: 'grey.300' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display="flex" alignItems="center">
            <Box sx={{ width: '16px', height: '32px', backgroundColor: titleBarColor, borderRadius: 1 }} />
            <Typography variant="h4" sx={{ pl: 1 }}>
              {title}
            </Typography>
          </Box>
          {!hideCloseIcon && (
            <Box
              onClick={closeDialog}
              sx={{
                backgroundColor: '#efefef',
                '&:hover': {
                  backgroundColor: '#bfbfbf',
                },
                transition: 'all 0.2s ease',
                cursor: 'pointer',
                borderRadius: '50%',
                p: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CloseIcon />
            </Box>
          )}
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};

export default NewDialogWrapper;
