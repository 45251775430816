import logoHorizontalSloganWhite from './../img/cordel-hvit.svg';
import logoIconWhite from './../img/cordel-ikon-hvit.svg';
import logoIconOrange from './../img/cordel-ikon-orange.svg';
import logoVerticalWhiteOrange from './../img/cordel-vertikal-hvit-orange.svg';
import logoVerticalWhite from './../img/cordel-vertikal-hvit.svg';
import logoHorizontalSlogan from './../img/cordel.svg';

export function initialCards() {
  const cards = [
    {
      cid: 1,
      value: logoHorizontalSlogan,
      matched: false,
      flipped: false,
      bg: '#323232',
    },
    {
      cid: 1,
      value: logoHorizontalSlogan,
      matched: false,
      flipped: false,
      bg: '#323232',
    },
    {
      cid: 6,
      value: logoVerticalWhiteOrange,
      matched: false,
      flipped: false,
      bg: '#323232',
    },
    {
      cid: 3,
      value: logoVerticalWhite,
      matched: false,
      flipped: false,
      bg: '#ec6607',
    },
    {
      cid: 2,
      value: logoIconWhite,
      matched: false,
      flipped: false,
      bg: '#ec6607',
    },
    {
      cid: 3,
      value: logoVerticalWhite,
      matched: false,
      flipped: false,
      bg: '#ec6607',
    },
    {
      cid: 6,
      value: logoVerticalWhiteOrange,
      matched: false,
      flipped: false,
      bg: '#323232',
    },
    {
      cid: 4,
      value: logoIconOrange,
      matched: false,
      flipped: false,
      bg: '#323232',
    },
    {
      cid: 5,
      value: logoHorizontalSloganWhite,
      matched: false,
      flipped: false,
      bg: '#ec6607',
    },
    {
      cid: 5,
      value: logoHorizontalSloganWhite,
      matched: false,
      flipped: false,
      bg: '#ec6607',
    },
    {
      cid: 2,
      value: logoIconWhite,
      matched: false,
      flipped: false,
      bg: '#ec6607',
    },
    {
      cid: 4,
      value: logoIconOrange,
      matched: false,
      flipped: false,
      bg: '#323232',
    },
  ];

  let randomCards: any[];
  randomCards = [];

  for (let i = 0; i < cards.length; i++) {
    let randomIndex = Math.floor(Math.random() * cards.length);

    while (randomCards.includes(cards[randomIndex])) {
      randomIndex = Math.floor(Math.random() * cards.length);
    }

    randomCards[i] = cards[randomIndex];
  }

  return randomCards;
}
