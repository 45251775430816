import { FC } from 'react';

type DollarPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Dollar: FC<DollarPropTypes> = ({ width = 34, height = 34, color = '#507358', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1651 15.9139C15.7293 15.1065 14.5525 14.6002 14.5525 13.3139C14.5525 11.9181 16.0714 11.4118 17.0293 11.4118C18.8219 11.4118 19.4788 12.7666 19.6293 13.2455L21.7914 12.3287C21.5861 11.7129 20.6693 9.70133 18.3156 9.26344V7.56662H15.5788V9.29081C12.1852 10.0571 12.1715 13.2044 12.1715 13.3413C12.1715 16.4476 15.2504 17.3233 16.7556 17.8707C18.9177 18.637 19.8756 19.3349 19.8756 20.6486C19.8756 22.1948 18.4388 22.8517 17.1661 22.8517C14.6757 22.8517 13.9641 20.2928 13.882 19.9917L11.6104 20.9085C12.4725 23.9053 14.7304 24.7127 15.5788 24.959V26.7243H18.3156V25.0274C18.863 24.9043 22.284 24.2201 22.284 20.6212C22.284 18.7191 21.4493 17.0497 18.1651 15.9139ZM4.63158 29.4611H1.89478V21.2506H10.1052V23.9875H6.71155C8.91468 27.2853 12.6778 29.4611 16.9472 29.4611C23.7482 29.4611 29.2628 23.9464 29.2628 17.1454H31.9996C31.9996 25.4653 25.2671 32.1979 16.9472 32.1979C11.8567 32.1979 7.3547 29.6663 4.63158 25.8074V29.4611ZM1.89478 17.1454C1.89478 8.82555 8.62732 2.09301 16.9472 2.09301C22.0377 2.09301 26.5397 4.62456 29.2628 8.48345V4.82982H31.9996V13.0402H23.7892V10.3034H27.1829C24.9797 7.00558 21.2166 4.82982 16.9472 4.82982C10.1462 4.82982 4.63158 10.3445 4.63158 17.1454H1.89478Z"
        fill={color}
      />
    </svg>
  );
};

export default Dollar;
