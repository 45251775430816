import { FC } from 'react';

type DocumentScannerFilledType = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const DocumentScannerFilled: FC<DocumentScannerFilledType> = ({
  width = 34,
  height = 34,
  color = '#0774A4',
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 34" fill="none" {...rest}>
      <path
        d="M10.1067 4.82958H6.00145V8.93479H3.26465V2.09277H10.1067V4.82958ZM30.6327 8.93479V2.09277H23.7907V4.82958H27.8959V8.93479H30.6327ZM10.1067 29.4608H6.00145V25.3556H3.26465V32.1976H10.1067V29.4608ZM27.8959 25.3556V29.4608H23.7907V32.1976H30.6327V25.3556H27.8959ZM26.5275 25.3556C26.5275 26.8609 25.2959 28.0924 23.7907 28.0924H10.1067C8.60142 28.0924 7.36986 26.8609 7.36986 25.3556V8.93479C7.36986 7.42954 8.60142 6.19798 10.1067 6.19798H23.7907C25.2959 6.19798 26.5275 7.42954 26.5275 8.93479V25.3556ZM21.0539 11.6716H12.8435V14.4084H21.0539V11.6716ZM21.0539 15.7768H12.8435V18.5136H21.0539V15.7768ZM21.0539 19.882H12.8435V22.6188H21.0539V19.882Z"
        fill={color}
      />
    </svg>
  );
};

export default DocumentScannerFilled;
