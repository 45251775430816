import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useAppSelector } from 'redux/hooks';

import { Box, Typography } from '@mui/material';
import {
  FC,
  ReactElement,
  ReactNode,
  ChangeEvent,
  useState,
  useEffect,
  Key,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import TopNavigation from '../../Layout/TopNavigation';
import { SelectedItem } from './ListProps';
import SkeletonSidebar from './SkeletonSidebar';
import { IconWrapper, SidebarWrapper, EditButton, DeleteButton, SidebarActionButtons } from './index.styled';
import { AppContext, IAppContext } from 'AppProvider';

import { Button, SearchField } from 'components';
import DynamicFilter from 'components/DynamicFilter/DynamicFilter';
import { IconItem } from 'components/Icons';
import SkeletonListItem from 'components/List/SkeletonListItem';
import { FlexBetween } from 'components/Stylings';

import { OFFERS_CODE } from 'model/utils/moduleDefinitions';
import { OfferFilters } from 'modules/Offers/offersInterfaces';
import { useModuleList } from 'modules/common';
import RoleWrapper from 'utils/authentication/RoleWrapper';
import { useDebounce, usePrevious, useInfiniteScroll } from 'utils/hooks';
import { useWindowOffset } from 'utils/hooks';

interface IPage {
  title: string;
  children?: ReactNode;
  hasSearch?: boolean;
  hasFilter?: boolean;
  moduleReducer: string;
  dialogUrl: string;
  listItem: any;
  sidebar: any;
  dialog: any;
  searchForItemAPI: (query: string, saveInSearch: boolean, params: IParams) => void;
  loadAllItemsAPI: (value: any, overWrite?: boolean) => void;
  getSelectedItem: (id: string, item?: any) => void;
  deleteSelectedItem: (arg: any) => void;
  nullifySearchedItems?: (arg: null) => void;
  filterItems?: (arg: any) => void;
  saveChosenFilters?: (arg: OfferFilters | null) => void;
  fetchTags?: () => void;
}

export interface ISidebarProps {
  isItemLoading: boolean;
  selectedItem: SelectedItem;
  deleteSelectedItem: (id: SelectedItem) => void;
  sidebar: any;
}

type Sidebar = 'sidebar';
type Filter = 'filter';
type Catalog = 'catalog';
type Category = 'category';

export type TSidebar = Sidebar | Filter | Catalog | Category | null;
export interface ISearchBarProps {
  title: string;
  hasFilter?: boolean;
  resultItemsNumber: number;
  searchValue: string;
  handleSearchFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSidebars: (sidebar: TSidebar) => void;
  setSearchOption?: Function;
  catalogs?: boolean;
}

export const pageNumberKey = 'pageNumber';

const SideBarComponent: FC<ISidebarProps> = ({ isItemLoading, selectedItem, deleteSelectedItem, sidebar: Sidebar }) => {
  const { t } = useModuleList();
  const navigate = useNavigate();
  return (
    <>
      {isItemLoading && (
        <SidebarWrapper>
          <SkeletonSidebar />
        </SidebarWrapper>
      )}
      {selectedItem && !isItemLoading && (
        <SidebarWrapper>
          {selectedItem && <Sidebar item={selectedItem} />}
          <RoleWrapper role="w" moduleCode={OFFERS_CODE}>
            <SidebarActionButtons>
              <EditButton onClick={() => navigate(selectedItem.id!)}>{t('globals.edit')}</EditButton>
              <DeleteButton onClick={() => deleteSelectedItem(selectedItem)}>{t('globals.delete')}</DeleteButton>
            </SidebarActionButtons>
          </RoleWrapper>
        </SidebarWrapper>
      )}
    </>
  );
};

let containerStyles = {};

export const SearchBar: FC<ISearchBarProps> = ({
  title,
  resultItemsNumber,
  searchValue,
  handleSearchFilter,
  handleSidebars,
  hasFilter = true,
}) => {
  const searchBarRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useWindowOffset();
  const { t } = useModuleList();

  useEffect(() => {
    if (!searchBarRef.current) return;
    if (searchBarRef.current?.offsetTop < scrollY) {
      containerStyles = {
        position: 'fixed',
        top: 0,
        paddingTop: 5,
        backgroundColor: 'theme.palette.background.default',
        pl: 4.25,
        width: searchBarRef.current?.clientWidth,
        alignItems: 'flex-start',
      };
    }
    if (searchBarRef.current?.clientHeight > scrollY) {
      containerStyles = {
        pl: 4.25,
      };
    }
  }, [scrollY, searchBarRef]);
  return (
    <FlexBetween ref={searchBarRef} sx={containerStyles}>
      <Box>
        <Typography lineHeight={1.85} variant="h1">
          {title}
        </Typography>
        <Typography
          sx={{
            color: 'theme.palette.primary.main',
            mt: 1,
            lineHeight: 1,
          }}>
          {resultItemsNumber + ' ' + t('globals.results').toLowerCase()}
        </Typography>
        <Typography
          sx={{
            mb: 1,
            pl: 0.25,
            fontSize: '14px',
            lineHeight: 1.5,
          }}>
          {t('offers.basedOnYourFilter')}
        </Typography>
      </Box>
      <Box
        display="flex"
        marginX={4}
        marginRight={0}
        alignItems="center"
        bgcolor="#f7f7f7"
        sx={{ minWidth: '60%', maxWidth: '70%' }}>
        <SearchField
          size="medium"
          type="search"
          fullWidth
          value={searchValue}
          onChange={handleSearchFilter}
          sx={{ py: 0, border: 0, backgroundColor: 'grey.300', pl: 1 }}
        />
        {hasFilter && (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pl: 1 }}>
            <Button
              startIcon={<FilterAltOutlinedIcon />}
              sx={{ borderRadius: 4, maxHeight: 36.125 }}
              color="primary"
              variant="contained"
              onClick={() => handleSidebars('filter')}>
              <Typography sx={{ pr: 2 }}>{t('globals.filters')}</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </FlexBetween>
  );
};

export const ListPage: FC<IPage> = (props): ReactElement => {
  const {
    title,
    hasSearch = true,
    hasFilter = true,
    searchForItemAPI,
    moduleReducer,
    dialogUrl,
    loadAllItemsAPI,
    getSelectedItem,
    deleteSelectedItem,
    nullifySearchedItems,
    filterItems,
    saveChosenFilters,
    fetchTags,
    listItem: ListItem,
    sidebar: Sidebar,
    dialog: Dialog,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const [sidebar, setSidebar] = useState<TSidebar>(null);

  const debouncedValue = useDebounce<string>(searchValue, 500);

  const { isAppDrawerOpen = false } = useContext(AppContext) as IAppContext;

  const { items, searchedItems, isItemsLoading, selectedItem, isItemLoading, hasMoreItems } = useAppSelector(
    (data) => data[moduleReducer as keyof typeof data]
  );

  const { dispatch, pageSize, pageNumber, openDialog, showDialog } = useModuleList({ items });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const previousPageNumber: number = usePrevious(Number(pageNumber));
  const previousSearchValue: string = usePrevious(debouncedValue);

  const { ref } = useInfiniteScroll({
    pageNumber,
    isLoading: isItemsLoading,
    hasMoreItems,
    searchValueLength: debouncedValue.length,
    searchedItems: searchedItems?.hits,
  });

  //functions
  const handleSearchFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  }, []);

  const handleSidebarToggle = useCallback(
    (id: string, item?: any) => {
      if (id === selectedItemId) {
        setSidebar(null);
        setSelectedItemId('');
      } else {
        setSelectedItemId(id);
        setSidebar('sidebar');
        dispatch(getSelectedItem(id, item));
      }
    },
    [dispatch, getSelectedItem, selectedItemId]
  );

  // This is a function to switch between fetching only searched items or fetching all items
  const appropriatefetchAPI = useCallback(
    async ({ pageSize, pageNumber }: IParams) => {
      // We check that no two requests are made for the same page
      if (previousPageNumber === Number(pageNumber) && previousSearchValue === debouncedValue) return;
      if (debouncedValue.length === 0) {
        // We check if there are items in the store, it means that probably the user just copied the url and pasted it in a new tab. so we need to fetch the appropriate number of items and overwrite the whole items store
        if (!Number(pageNumber) && items?.length > 0) return;
        if (!items || items?.length === 0) {
          await dispatch(
            loadAllItemsAPI(
              {
                pageSize: Number(pageSize) * Number(pageNumber) + Number(pageSize),
                pageNumber: 0,
              } as IParams,
              true
            )
          );
        } else {
          await dispatch(
            loadAllItemsAPI({
              pageSize,
              pageNumber: Math.floor(items.length / 10) + 1,
            } as IParams)
          );
        }
      }
      if (debouncedValue.length > 0) {
        if (!searchedItems || searchedItems?.hits?.length === 0) {
          // if there are no searched items, we need to fetch the first paged items
          const newSearchParams = new URLSearchParams(searchParams);
          setSearchParams(newSearchParams);
          await dispatch(
            searchForItemAPI(debouncedValue, true, {
              pageSize,
              pageNumber: 0,
            } as IParams)
          );
        } else {
          await dispatch(
            searchForItemAPI(debouncedValue, true, {
              pageSize,
              pageNumber,
            } as IParams)
          );
        }
      }
    },
    [
      debouncedValue,
      dispatch,
      items,
      loadAllItemsAPI,
      previousPageNumber,
      previousSearchValue,
      searchForItemAPI,
      searchParams,
      searchedItems,
      setSearchParams,
    ]
  );

  const handleSidebars = useCallback(
    (name: TSidebar) => {
      if (name === sidebar) {
        setSidebar(null);
        return;
      }
      setSidebar(name);
    },
    [sidebar]
  );

  const handleListWidth = () => {
    if (isAppDrawerOpen && sidebar) return 'calc(80% - 50px)';
    if (sidebar || isAppDrawerOpen) return 'calc(80% - 10px)';
    return '100%';
  };

  // renders
  const renderAllItems = useCallback(() => {
    if (items?.length > 0) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {items?.map((item: { id: Key | null | undefined }) => (
            <Box
              key={item.id}
              onDoubleClick={() => navigate(item.id! as string)}
              onClick={() => handleSidebarToggle(item.id! as string, item)}>
              <ListItem item={item} isSelected={item.id === selectedItemId && sidebar === 'sidebar'} />
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  }, [items, ListItem, selectedItemId, sidebar, navigate, handleSidebarToggle]);

  const renderSearchedItems = useCallback(() => {
    if (searchedItems?.hits?.length > 0) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {searchedItems?.hits?.map((item: { id: Key | null | undefined }) => (
            <Box
              key={item?.id}
              onDoubleClick={() => navigate(item.id! as string)}
              onClick={() => handleSidebarToggle(item.id! as string)}>
              <ListItem item={item} isSelected={item?.id === selectedItemId && sidebar === 'sidebar'} />
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  }, [searchedItems?.hits, ListItem, selectedItemId, sidebar, navigate, handleSidebarToggle]);

  const renderLoading = useCallback(() => {
    if (isItemsLoading) {
      return (
        <>
          {[...Array(30)].map((item, index) => (
            <SkeletonListItem key={index} />
          ))}
        </>
      );
    }
    return null;
  }, [isItemsLoading]);

  const renderPlusIcon = useCallback(() => {
    return (
      <IconWrapper onClick={() => openDialog(dialogUrl)}>
        <IconItem name="AddCircle" defaultColor="#fff" />
      </IconWrapper>
    );
  }, [openDialog, dialogUrl]);

  const resultItemsNumber = useMemo(() => {
    if (searchedItems?.hits?.length > 0) return searchedItems?.hits?.length;
    if (items?.length > 0) return items?.length;
    return 0;
  }, [items?.length, searchedItems?.hits?.length]);

  // effects
  useEffect(() => {
    appropriatefetchAPI({ pageSize, pageNumber } as IParams);
  }, [appropriatefetchAPI, debouncedValue.length, dispatch, loadAllItemsAPI, pageNumber, pageSize, searchForItemAPI]);

  useEffect(() => {
    if (debouncedValue.length === 0 && nullifySearchedItems) {
      dispatch(nullifySearchedItems(null));
    }
  }, [debouncedValue.length, dispatch, nullifySearchedItems]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 3, backgroundColor: 'theme.palette.background.default' }}>
      <TopNavigation title={title} />
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: handleListWidth(),
            minHeight: '100vh',
            // overflowY: 'scroll',
          }}
          className="list_page-container">
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {hasSearch ? (
                <SearchBar
                  hasFilter={hasFilter}
                  title={title}
                  resultItemsNumber={resultItemsNumber}
                  searchValue={searchValue}
                  handleSearchFilter={handleSearchFilter}
                  handleSidebars={handleSidebars}
                />
              ) : (
                <Typography variant="h1" sx={{ ml: 3.25, mb: 5 }}>
                  {title}
                </Typography>
              )}
              {searchedItems?.hits?.length > 0 ? renderSearchedItems() : renderAllItems()}
              {renderLoading()}
              <div ref={ref} className="ref" />
            </Box>
          </Box>
          {/* <Box className="pagination-footer">
            <Paging length={resultItemsNumber} totalItems={totalItemsNumber} />
          </Box> */}
          <RoleWrapper role="w" moduleCode={OFFERS_CODE}>
            {renderPlusIcon()}
          </RoleWrapper>
        </Box>
        <>
          {sidebar === 'filter' && hasFilter && (
            <DynamicFilter
              fetchTags={fetchTags}
              filterItems={filterItems}
              handleOpenClose={() => handleSidebars('filter')}
              saveChosenFilters={saveChosenFilters}
            />
          )}
          {sidebar === 'sidebar' && (
            <SideBarComponent
              isItemLoading={isItemLoading}
              selectedItem={selectedItem}
              sidebar={Sidebar}
              deleteSelectedItem={deleteSelectedItem}
            />
          )}
          {showDialog === dialogUrl && <Dialog />}
        </>
      </Box>
    </Box>
  );
};

export default ListPage;
