import { LicenseInfo } from '@mui/x-license-pro';
import { registerLicense } from '@syncfusion/ej2-base';
import mixpanel from 'mixpanel-browser';

import { setMixpanelEnabled } from 'utils/tracking';

const varsSource = (window as any)['appValues'] ?? process.env;
const { REACT_APP_MIXPANEL_KEY, REACT_APP_SYNCFUSION_KEY, REACT_APP_MUIX_KEY } = varsSource;

// const isDevEnv = varsSource.NODE_ENV === 'development';

export const registerLicenses = () => {
  // Mixpanel
  if (REACT_APP_MIXPANEL_KEY) {
    mixpanel.init(REACT_APP_MIXPANEL_KEY, { debug: false });
    mixpanel.set_config({ persistence: 'localStorage' });
    setMixpanelEnabled(true);
  }

  // Syncfusion
  if (REACT_APP_SYNCFUSION_KEY) {
    registerLicense(REACT_APP_SYNCFUSION_KEY);
  }

  // MUI-X Pro
  if (REACT_APP_MUIX_KEY) {
    LicenseInfo.setLicenseKey(REACT_APP_MUIX_KEY);
  }
};
