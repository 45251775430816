import { AxiosResponse } from 'axios';
import i18n from 'i18next';

import { INode } from '../NewOfferTable/types';

import { IOrderOfferNode, IOrderOfferPostBody, OrderType } from '../Specification/OrderHandling/orderHandlingInterface';
import { getProductsForPostBody, getProductsWithRemaining } from '../Specification/OrderHandling/orderHandlingUtils';
import { getAdjustableItemLabel } from '../Specification/SpecificationActionButtons/Adjustments/AdjustableItemsUtils';
import {
  IAccumulatedFormItem,
  IAccumulatedItem,
  IAccumulatedPUTResponse,
  IAdjustableItem,
  IOfferProperty,
  ISelectedSpecificationItem,
  ISpecificationItemNodeOverview,
  ISpecificationNode,
  ISpecificationRoot,
  ISpecificationState,
} from '../Specification/specificationInterface';
import { INewSpecificationItem } from '../SpecificationItems/specificationItemsInterfaces';
import { loadOfferTotal } from './offersActions';
import { refreshListItems } from './specificationItemsActions';

import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { IOfferSetting } from 'modules/OfferSettings/OfferSettingsInterfaces';
import API from 'utils/API';

export const SET_IS_SPECIFICATION_LOADING = 'SET_IS_SPECIFICATION_LOADING';
export const LOAD_OFFER_SPECIFICATION = 'LOAD_OFFER_SPECIFICATION';
export const UPDATE_DROP_DOWN_STATE = 'UPDATE_DROP_DOWN_STATE';
export const SELECT_OFFER_SPECIFICATION_NODE = 'SELECT_OFFER_SPECIFICATION_NODE';
export const DESELECT_OFFER_SPECIFICATION_NODE = 'DESELECT_OFFER_SPECIFICATION_NODE';
export const SELECT_OFFER_SPECIFICATION_UPDATED_ITEM = 'SELECT_OFFER_SPECIFICATION_UPDATED_ITEM';
export const RESET_OFFER_SPECIFICATION = 'RESET_OFFER_SPECIFICATION';
export const SELECT_NODE_ROW = 'SELECT_NODE_ROW';
export const GET_SPECIFICATION_OFFER_SETTING = 'GET_SPECIFICATION_OFFER_SETTING';
export const UPDATE_MULTI_SELECTED_NODES = 'UPDATE_MULTI_SELECTED_NODES';
export const LOAD_ACCUMULATED_LIST = 'LOAD_ACCUMULATED_LIST';
export const SET_EMAILS_TO_CORDEL_2 = 'SET_EMAILS_TO_CORDEL_2';
export const LOAD_CUSTOMER_EMAILS_FOR_SELECTED_OFFER = 'LOAD_CUSTOMER_EMAILS_FOR_SELECTED_OFFER';
export const SET_NODE_SESSION_ID = 'SET_NODE_SESSION_ID';
export const LOAD_ADJUSTABLE_ITEMS = 'LOAD_ADJUSTABLE_ITEMS';
export const LOAD_OFFER_PROPERTIES = 'LOAD_OFFER_PROPERTIES';
export const RESET_OFFER_SPECIFICATION_STATE = 'RESET_OFFER_SPECIFICATION_STATE';

export const resetOfferSpecificationState = () => ({ type: RESET_OFFER_SPECIFICATION_STATE });

export const setNodeSessionId = (sessionId: string | null) => ({
  type: SET_NODE_SESSION_ID,
  payload: { sessionId },
});

const loadOfferPropertiesSuccess = (offerProperties: Array<IOfferProperty>) => ({
  type: LOAD_OFFER_PROPERTIES,
  payload: { offerProperties },
});

export const loadAdjustableItemsSuccess = (adjustableItems: Array<IAdjustableItem>) => ({
  type: LOAD_ADJUSTABLE_ITEMS,
  payload: { adjustableItems },
});

const setIsLoading = (value: boolean) => ({ type: SET_IS_SPECIFICATION_LOADING, payload: { isLoading: value } });

export const setEmailsToCordel2 = (selectedEmails: Array<string>) => ({
  type: SET_EMAILS_TO_CORDEL_2,
  payload: { selectedEmails },
});

export const updateMultiSelectedNodes = (multiSelectedNodes: Array<ISpecificationNode>) => ({
  type: UPDATE_MULTI_SELECTED_NODES,
  payload: { multiSelectedNodes },
});

export const loadOfferSpecificationSuccess = (specificationRoot: ISpecificationRoot) => ({
  payload: { specificationRoot },
  type: LOAD_OFFER_SPECIFICATION,
});

export const refreshOfferSpecificationTree = () => {
  return async (dispatch: Function, getState: Function) => {
    const { specificationRoot } = getState().offerSpecificationModule;
    dispatch(loadOfferSpecificationSuccess({ ...specificationRoot, updateTime: Date.now() }));
  };
};

const buildSelectedNodeWithChildren = (selectedNode: ISpecificationNode, allNodes: Array<ISpecificationNode>) => {
  allNodes.push(selectedNode);
  const children = Array.isArray(selectedNode?.children) ? selectedNode?.children : selectedNode.childNodes ?? [];
  children.forEach((node) => {
    buildSelectedNodeWithChildren(node, allNodes);
  });
};

export const selectSpecificationNode = (item: ISpecificationNode) => {
  return async (dispatch: Function, getState: Function) => {
    const allNodes: Array<ISpecificationNode> = [];
    const { multiSelectedNodes } = getState().offerSpecificationModule;
    buildSelectedNodeWithChildren(item, allNodes);
    const filteredNodes = allNodes.filter((item) => {
      return multiSelectedNodes.find((node: ISpecificationNode) => node.nodeId === item.nodeId) === undefined;
    });
    dispatch({
      type: SELECT_OFFER_SPECIFICATION_NODE,
      payload: {
        selectedNode: item,
        multiSelectedNodes: [...multiSelectedNodes, ...filteredNodes],
      },
    });
  };
};

export const deselectSelectedNode = () => ({
  type: DESELECT_OFFER_SPECIFICATION_NODE,
  payload: { selectedNode: null, multiSelectedNodes: [], updatedNode: null },
});

export const deselectSpecificationNode = (nodeId: string) => {
  return async (dispatch: Function, getState: Function) => {
    const nodesToDeselect: Array<ISpecificationNode> = [];
    const { multiSelectedNodes }: ISpecificationState = getState().offerSpecificationModule;
    const node = multiSelectedNodes.find((node: ISpecificationNode) => node.nodeId === nodeId) as ISpecificationNode;
    buildSelectedNodeWithChildren(node, nodesToDeselect);
    const newItems = multiSelectedNodes.filter(
      (item) => !Boolean(nodesToDeselect.find((node) => node.nodeId === item.nodeId))
    );
    dispatch({
      type: DESELECT_OFFER_SPECIFICATION_NODE,
      payload: {
        updatedNode: null,
        multiSelectedNodes: newItems,
        // selectedNode: newItems.length === 1 ? newItems[0] : null,
      },
    });
  };
};

export const loadSpecificationNodeSuccess = (updatedNode: ISelectedSpecificationItem) => ({
  payload: { updatedNode },
  type: SELECT_OFFER_SPECIFICATION_UPDATED_ITEM,
});

export const loadAccumulatedListSuccess = (accumulatedList: Array<IAccumulatedItem>) => ({
  payload: { accumulatedList },
  type: LOAD_ACCUMULATED_LIST,
});

export const resetOfferSpecification = () => ({
  type: RESET_OFFER_SPECIFICATION,
  payload: { specificationRoot: null },
});

export const selectNodeRow = (node: ISpecificationItemNodeOverview | null) => ({
  type: SELECT_NODE_ROW,
  payload: { selectedRow: node, updatedNode: null },
});

const loadOfferSettingsSuccess = (offerSettingItem: IOfferSetting) => ({
  type: GET_SPECIFICATION_OFFER_SETTING,
  payload: { offerSettingItem },
});

export const loadCustomerEmailsForSelectedOfferSuccess = (emailAddresses: Array<string>) => ({
  type: LOAD_CUSTOMER_EMAILS_FOR_SELECTED_OFFER,
  payload: { emailAddresses },
});

export const loadOfferSpecification = (offerId: string, versionId: string) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const URL = `/offer/api/offerCalc/${offerId}/overview/extended?versionId=${versionId}&convertToIncomeCurrency=true`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadOfferSpecificationSuccess({ ...payload, updateTime: Date.now() }));
    } catch (error) {
      const message = i18n.t('offers.errorLoadingSpecification');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadOfferSpecification2 = (offerId: string, versionId: string, propertyNames: Array<string>) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const URL = `/offer/api/offers/${offerId}/versions/${versionId}/columns`;
      const {
        data: { payload },
      } = await API.post(URL, { propertyNames });
      // const res = await API.get('/offers/api/OfferCalc/Properties');
      // console.log(res.data);
      // console.log(payload);
      dispatch(loadOfferSpecificationSuccess({ ...payload, updateTime: Date.now() }));
    } catch (error) {
      const message = i18n.t('offers.errorLoadingSpecification');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateNodePosition = (
  offerId: string,
  versionId: string,
  nodeId: string,
  parentId: string,
  prevId = ''
) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offer/api/offerCalc/${offerId}/node/${nodeId}?versionId=${versionId}`;
      await API.put(URL, { position: { parentId, prevId } });
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingNodePosition');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateNodePositionNew = (nodeId: string, parentId: string, prevId = '') => {
  return async (dispatch: Function, getState: Function) => {
    const { specificationRoot }: ISpecificationState = getState().offerSpecificationModule;
    const { offerVersionId = '', offerId = '' } = specificationRoot ?? {};
    try {
      dispatch(setIsLoading(true));
      const URL = `/offer/api/offerCalc/${offerId}/node/${nodeId}?versionId=${offerVersionId}`;
      await API.put(URL, { position: { parentId, prevId } });
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingNodePosition');
      dispatch(loadOfferSpecification(offerId, offerVersionId));
      dispatch(loadOfferTotal(offerId, offerVersionId));
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const undoNodeUpdatesWithSession = (callback: Function) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { sessionId, specificationRoot }: ISpecificationState = getState().offerSpecificationModule;
      const { offerVersionId = '', offerId = '' } = specificationRoot ?? {};
      if (!sessionId) return;
      const URL = `/offer/api/offerCalc/${offerId}/sessions/${sessionId}/undo?versionId=${offerVersionId}`;
      await API.put(URL);
      dispatch(loadOfferSpecification(offerId, offerVersionId));
      dispatch(loadOfferTotal(offerId, offerVersionId));
      const message = i18n.t('offers.changesWereReset');
      dispatch(addSuccessMessage({ message }));
      callback();
    } catch (e: any) {
      const message = e?.response?.data ?? '';
      if (message !== '') {
        callback();
      } else {
        const message = i18n.t('offers.failedToUndoNodeChanges');
        dispatch(addErrorMessage({ message }));
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateNodeData = (
  data: any,
  callback?: Function | null,
  nodeData?: any,
  ignoreSpecificationUpdate = false,
  materialCostChanged?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedRow, sessionId }: ISpecificationState = getState().offerSpecificationModule;
      const { offerId, versionId, nodeId } = nodeData ? nodeData : selectedRow;
      let URL = `/offer/api/offerCalc/${offerId}/node/${nodeId}?versionId=${versionId}`;
      if (sessionId) {
        URL += `&sessionId=${sessionId}`;
      }
      const {
        data: { payload },
      } = await API.put(URL, { ...data });
      dispatch(loadSpecificationNodeSuccess(payload));
      if (callback) {
        callback();
      }
      if (!ignoreSpecificationUpdate) {
        dispatch(loadOfferSpecification(offerId, versionId));
        dispatch(loadOfferTotal(offerId, versionId));
      }
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const setOpenDropDownItem = (itemId: string, isOpen: boolean) => ({
  type: UPDATE_DROP_DOWN_STATE,
  payload: { itemId, isOpen },
});

export const addNodes = (
  offerId: string,
  versionId: string,
  item: INewSpecificationItem | Array<INewSpecificationItem>,
  callback = () => {},
  isDraggable = false
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const data = Array.isArray(item) ? item : [item];
      const { selectedRow } = getState().offerSpecificationModule;
      let queries = `versionId=${versionId}`;
      // add new list items to selected specification node row
      // drag & drop is always for one node which is sent an object and not as an array
      if (selectedRow && !isDraggable) {
        const parentId = selectedRow.isSumLevel ? selectedRow.nodeId : selectedRow.parentId;
        // If selectedRow has children, get the nodeId from the last child element.
        // This ensures that the new item will placed as the last child of selectedRow.
        const lastChildId = selectedRow?.children?.length
          ? selectedRow.children[selectedRow.children.length - 1].nodeId
          : '';
        const prevId = selectedRow.isSumLevel ? lastChildId : selectedRow.nodeId;
        // Add position to the url as queries, to tell backend where to put it.
        queries += `&parentId=${parentId}&prevId=${prevId}`;
        // Remove position from the nodes, to place them at the bottom of selected node
        data.forEach((node: INewSpecificationItem) => {
          node.position = null;
        });
      }
      const URL = `/offer/api/offerCalc/${offerId}/multi?${queries}`;
      await API.post(URL, data);
      dispatch(selectNodeRow(null));
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(refreshListItems());
      callback();
    } catch (e) {
      const message = i18n.t('offers.errorAddingNewNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const addNodesToParagraph = (
  offerId: string,
  versionId: string,
  item: INewSpecificationItem,
  callback = () => {}
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { multiSelectedNodes }: ISpecificationState = getState().offerSpecificationModule;
      let URL = `/offer/api/offerCalc/${offerId}/paragraph?versionId=${versionId}`;
      let query = '';

      const {
        data: { payload },
      } = await API.post(URL, item);

      const clonedNodes = JSON.parse(JSON.stringify(multiSelectedNodes));
      const sortedNodes = clonedNodes.sort((nodeA: ISpecificationNode, nodeB: ISpecificationNode) => {
        nodeA['prevId'] = nodeB.nodeId;
        return nodeA.displayOrder < nodeB.displayOrder;
      });

      const parentId = payload.nodeId;
      URL = `/offer/api/offerCalc/${offerId}/nodes?versionId=${versionId}`;
      URL += query;
      const data = sortedNodes.map(({ nodeId, prevId }: { nodeId: string; prevId?: string }) => {
        return {
          nodeId,
          position: {
            parentId,
            prevId: prevId ?? '',
          },
        };
      });
      await API.put(URL, data);

      callback();
    } catch (e) {
      const message = i18n.t('offers.errorAddingNewNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
      dispatch(deselectSelectedNode());
      dispatch(selectNodeRow(null));
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
    }
  };
};

export const loadSpecificationNode = () => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedRow } = getState().offerSpecificationModule;
      const { offerId, versionId, nodeId } = selectedRow;
      const URL = `/offer/api/offerCalc/${offerId}/node/${nodeId}?versionId=${versionId}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadSpecificationNodeSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingSpecificationNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteSpecificationNode = (offerId: string, versionId: string, callback = () => {}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { multiSelectedNodes }: ISpecificationState = getState().offerSpecificationModule;
      let nodeIds = '';
      multiSelectedNodes.forEach(({ nodeId }) => {
        nodeIds += `&nodeIds=${nodeId}`;
      });
      const URL = `/offer/api/offerCalc/${offerId}/node?versionId=${versionId}${nodeIds}`;
      await API.delete(URL);
      dispatch(deselectSelectedNode());
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
      callback();
    } catch (e) {
      const message = i18n.t('offers.errorDeletingNodes');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(selectNodeRow(null));
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteSingleSpecificationNode = (
  offerId: string,
  versionId: string,
  nodeId: string,
  callback = () => {}
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offer/api/offerCalc/${offerId}/node?versionId=${versionId}&nodeIds=${nodeId}`;
      await API.delete(URL);
      dispatch(deselectSelectedNode());
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
      callback();
    } catch (e) {
      const message = i18n.t('offers.errorDeletingNodes');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(selectNodeRow(null));
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteNodeChildren = (offerId: string, versionId: string, node: INode, callback = () => {}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      if (!node.children?.length) return;
      let nodeIds = '';
      node.children?.forEach(({ nodeId }) => {
        nodeIds += `&nodeIds=${nodeId}`;
      });
      const URL = `/offer/api/offerCalc/${offerId}/node?versionId=${versionId}${nodeIds}`;
      await API.delete(URL);
      dispatch(deselectSelectedNode());
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
      callback();
    } catch (e) {
      const message = i18n.t('offers.errorDeletingNodes');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(selectNodeRow(null));
      dispatch(setIsLoading(false));
    }
  };
};

export const updateOfferPrice = (offerId: string, versionId: string, callback = () => {}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/bdintegration/api/offers/${offerId}/version/${versionId}/prices`;
      await API.put(URL);
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
      const message = i18n.t('offers.successUpdatingOfferPrice');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingOfferPrice');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
      callback();
    }
  };
};

export const updateCurrencyRate = (offerId: string, versionId: string, callback = () => {}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/bdintegration/api/offers/${offerId}/version/${versionId}/currencyrates`;
      await API.put(URL);
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
      const message = i18n.t('offers.successUpdatingCurrencyRate');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingCurrencyRate');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
      callback();
    }
  };
};

export const copyNodes = (offerId: string, versionId: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      let URL = `/offer/api/offerCalc/copy`;
      const { multiSelectedNodes, selectedRow, specificationRoot }: ISpecificationState =
        getState().offerSpecificationModule;

      const clonedNodes = structuredClone(multiSelectedNodes);
      const sortedNodes = clonedNodes.sort(
        (nodeA: ISpecificationNode, nodeB: ISpecificationNode) => nodeA.displayOrder - nodeB.displayOrder
      );
      const { isSumLevel, nodeId, parentId: selectedRowParentId } = selectedRow ?? {};
      const {
        rootNode: { nodeId: rootNodeId, children },
      }: ISpecificationRoot | any = specificationRoot ?? { rootNode: { nodeId: null, children: [{ nodeId: '' }] } };

      const getPosition = () => {
        if (selectedRow) {
          if (isSumLevel) {
            return { parentId: nodeId ?? rootNodeId, prevId: null };
          } else {
            return { parentId: selectedRowParentId ?? rootNodeId, prevId: nodeId };
          }
        } else {
          return { parentId: rootNodeId, prevId: children.at(-1).nodeId };
        }
      };

      const data = {
        sourceOffer: offerId,
        sourceVersion: versionId,
        targetOffer: offerId,
        targetVersion: versionId,
        updatePrices: false,
        position: getPosition(),
        nodes: sortedNodes.map(({ nodeId }: { nodeId: string }) => {
          return {
            nodeId,
            recursive: true,
            position: null,
          };
        }),
      };
      await API.post(URL, data);
      dispatch(deselectSelectedNode());
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
    } catch (e: any) {
      let message = i18n.t('offers.errorCopyingNodes');
      if (e?.['response']?.['data']?.includes("Can't find new parent"))
        message = i18n.t('offers.copyCannotFindSelectedParent');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createParagraph = (
  offerId: string,
  versionId: string,
  item: INewSpecificationItem,
  callback = () => {}
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedRow, specificationRoot }: ISpecificationState = getState().offerSpecificationModule;
      const children = specificationRoot?.rootNode?.children ?? [];
      const lastChild = children.at(-1)?.nodeId ?? null;
      const prevId = selectedRow ? selectedRow.nodeId : lastChild;
      const parentId = selectedRow ? selectedRow.parentId : specificationRoot?.rootNode.nodeId;
      if (item.position) {
        item.position.parentId = parentId ?? '';
        item.position.prevId = prevId;
      }
      let URL = `/offer/api/offerCalc/${offerId}/paragraph?versionId=${versionId}`;
      URL += `&parentId=${parentId}&prevId=${prevId}`;
      await API.post(URL, item);
      dispatch(selectNodeRow(null));
      dispatch(deselectSelectedNode());
      dispatch(loadOfferSpecification(offerId, versionId));
      callback();
    } catch (e) {
      const message = i18n.t('offers.errorCopyingNodes');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createSection = (
  offerId: string,
  versionId: string,
  parentId: string,
  prevId: string,
  item: INewSpecificationItem
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      let URL = `/offer/api/offerCalc/${offerId}/paragraph?versionId=${versionId}`;
      URL += `&parentId=${parentId}&prevId=${prevId}`;
      await API.post(URL, item);
      dispatch(loadOfferSpecification(offerId, versionId));
    } catch (e) {
      const message = i18n.t('offers.errorCopyingNodes');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateAllNodes = (offerId: string, versionId: string, data: any, callback = () => {}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offer/api/offerCalc/${offerId}/refresh?versionId=${versionId}`;
      await API.put(URL, data);
      dispatch(loadOfferSpecification(offerId, versionId));
      const message = i18n.t('offers.successUpdatingAllItems');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingAllItems');
      dispatch(addErrorMessage({ message }));
    } finally {
      callback();
      dispatch(setIsLoading(false));
    }
  };
};

export const loadOfferSettings = (offerId: string, versionId: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offer/api/offers/${offerId}/parameters?versionId=${versionId}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadOfferSettingsSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingOfferSettings');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateOfferSettings = (offerId: string, versionId: string, data: IOfferSetting) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offer/api/offers/${offerId}/parameters?versionId=${versionId}`;
      const {
        data: { payload },
      } = await API.put(URL, data);
      dispatch(loadOfferSettingsSuccess(payload));
      const message = i18n.t('offers.successUpdatingOfferSettings');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingOfferSettings');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const lockSelectedNodes = (offerId: string, versionId: string, values: any, lock: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const stringValue = lock ? '' : 'Un';
    try {
      dispatch(setIsLoading(true));
      const { multiSelectedNodes }: ISpecificationState = getState().offerSpecificationModule;
      const data = multiSelectedNodes.map(({ nodeId }) => {
        return {
          nodeId,
          values,
        };
      });
      const URL = `/offer/api/offerCalc/${offerId}/nodes?versionId=${versionId}`;
      await API.put(URL, data);
      dispatch(loadOfferSpecification(offerId, versionId));
      const message = i18n.t('offers.success' + stringValue + 'LockingPrices');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('offers.error' + stringValue + 'LockingPrices');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateSelectedNodes = (adjustments: any, filter: any, callback = () => {}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { multiSelectedNodes, specificationRoot }: ISpecificationState = getState().offerSpecificationModule;
      const { offerId = '', offerVersionId = '' } = specificationRoot ?? {};
      const URL = `/offer/api/offerCalc/${offerId}/adjust?versionId=${offerVersionId}`;
      let data: any = {
        adjustments,
        selection: {},
      };
      if (filter) data.selection.filter = filter;
      if (multiSelectedNodes.length) data.selection.selectedNodes = multiSelectedNodes.map(({ nodeId }) => nodeId);
      await API.put(URL, data);
      dispatch(loadOfferSpecification(offerId, offerVersionId));
      const message = i18n.t('offers.successUpdatingSelectedNodes');
      dispatch(addSuccessMessage({ message }));
      callback();
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingSelectedNodes');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadOrderHandling = (offerId: string, versionId: string) => {
  const URL = `bdintegration/api/offers/${offerId}/version/${versionId}`;
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const getOrderOfferPromise = API.get(URL);
      const {
        data: { payload },
      } = await getOrderOfferPromise;
      dispatch(loadOfferSpecificationSuccess({ ...payload, updateTime: Date.now() }));
    } catch (error) {
      const message = i18n.t('offers.errorUpdatingOfferSettings');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export type TCreateOrderHandling = {
  offerId: string;
  versionId: string;
  orderType: OrderType;
  callback?: Function;
  projectNumber?: string | null;
  projectElement?: string | null;
};

export const createOfferOrderAllRemaining = ({
  offerId,
  versionId,
  orderType = 'pro',
  callback = () => {},
  projectNumber = null,
  projectElement = null,
}: TCreateOrderHandling) => {
  const URL = `bdintegration/api/offers/${offerId}/version/${versionId}/Orders`;

  return async (dispatch: Function, getState: Function) => {
    dispatch(setIsLoading(true));
    try {
      const { rootNode }: ISpecificationRoot = getState().offerSpecificationModule?.specificationRoot;
      const childrenNodes = (rootNode?.children || []) as Array<IOrderOfferNode>;
      const nodes = getProductsWithRemaining(childrenNodes);
      const nodesToSend = getProductsForPostBody(nodes);
      const data: IOrderOfferPostBody = { items: nodesToSend, orderType, projectNumber, projectElement };
      await API.post(URL, data);
      const message = i18n.t('offers.successCreatingOfferOrder');
      dispatch(loadOrderHandling(offerId, versionId));
      dispatch(addSuccessMessage({ message }));
      callback();
    } catch (error) {
      const message = i18n.t('offers.errorCreatingOfferOrder');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createOfferOrderForSelection = ({
  offerId,
  versionId,
  orderType = 'pro',
  callback = () => {},
  projectNumber = null,
  projectElement = null,
}: TCreateOrderHandling) => {
  const URL = `bdintegration/api/offers/${offerId}/version/${versionId}/Orders`;

  return async (dispatch: Function, getState: Function) => {
    dispatch(setIsLoading(true));
    try {
      const { multiSelectedNodes } = getState().offerSpecificationModule;
      const childrenNodes = (multiSelectedNodes || []) as Array<IOrderOfferNode>;
      const nodes = getProductsWithRemaining(childrenNodes);
      const nodesToSend = getProductsForPostBody(nodes);
      const data: IOrderOfferPostBody = { items: nodesToSend, orderType, projectNumber, projectElement };
      await API.post(URL, data);
      const message = i18n.t('offers.successCreatingOfferOrder');
      dispatch(loadOrderHandling(offerId, versionId));
      dispatch(addSuccessMessage({ message }));
      callback();
    } catch (error) {
      const message = i18n.t('offers.errorCreatingOfferOrder');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const replaceNode = (nodeId: string, externalId: string) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { specificationRoot }: ISpecificationState = getState().offerSpecificationModule;
      const { offerId = '', offerVersionId = '' } = specificationRoot ?? {};
      const URL = `/offer/api/offerCalc/${offerId}/node/${nodeId}?versionId=${offerVersionId}`;
      const data = { info: { externalId } };
      await API.put(URL, data);
      dispatch(loadOfferSpecification(offerId, offerVersionId));
    } catch (e) {
      const message = i18n.t('offers.errorReplacingNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadAccumulatedList = (offerId: string, versionId: string, pageSize?: number, pageNumber?: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const pageSizeQuery = pageSize ? `&pageSize=${pageSize}` : '';
      const pageNumberQuery = pageNumber ? `&pageNumber=${pageNumber}` : '';
      const queries = `versionId=${versionId}${pageSizeQuery}${pageNumberQuery}`;
      const URL = `/offer/api/offerCalc/${offerId}/Accumulated?${queries}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadAccumulatedListSuccess([...payload]));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingAccumulatedList');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateAccumulatedListItem = (
  data: IAccumulatedFormItem,
  sessionId?: string // frontend-generated id for a session (used to undo changes)
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { specificationRoot }: ISpecificationState = getState().offerSpecificationModule;
      const { offerId = '', offerVersionId = '' } = specificationRoot || {};

      const sessionQuery = sessionId ? `&sessionId=${sessionId}` : '';
      const versionQuery = `versionId=${offerVersionId}`;
      const queries = `${versionQuery}${sessionQuery}`;
      const URL = `/offer/api/offerCalc/${offerId}/Accumulated?${queries}`;
      await API.put<AxiosResponse<IAccumulatedPUTResponse>>(URL, data);
      dispatch(loadAccumulatedList(offerId, offerVersionId));
      const message = i18n.t('offers.successUpdatingNode');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const sendOfferToCordel2 = (callback = () => {}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { specificationRoot, selectedEmails }: ISpecificationState = getState().offerSpecificationModule;
      const { offerId = '', offerVersionId = '' } = specificationRoot || {};
      const URL = `/facade/api/offers/${offerId}/share/cordel2`;
      await API.post(URL, {
        emailAddresses: selectedEmails,
        versionId: offerVersionId,
      });
      callback();
      const message = i18n.t('offers.offerWasSendToCordel2');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('offers.errorSendingOfferToCordel2');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadCustomerEmailsForSelectedOffer = (customerId: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/crm/api/customers/${customerId}`;
      const {
        data: { payload },
      } = await API.get(URL);
      let value = [];
      if (payload.company) {
        value = payload.company?.emailAddresses ?? [];
      } else if (payload.person) {
        value = payload.person?.emailAddresses ?? [];
      }
      value = value.map(({ email }: any) => email);
      dispatch(loadCustomerEmailsForSelectedOfferSuccess(value));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingCustomerEmails');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const undoLastOperation = (offerId: string, versionId: string, callback = () => {}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offer/api/offerCalc/${offerId}/undo?versionId=${versionId}`;
      await API.put(URL);
      dispatch(loadOfferSpecification(offerId, versionId));
      dispatch(loadOfferTotal(offerId, versionId));
      callback();
    } catch (e) {
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadAdjustableItems = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offers/api/offercalc/properties/adjustable`;
      const {
        data: { payload },
      } = await API.get(URL);
      const items = payload.map((item: IAdjustableItem) => ({ ...item, label: getAdjustableItemLabel(item.name) }));
      dispatch(loadAdjustableItemsSuccess(items));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingAdjustableItems');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const loadOfferProperties = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/offers/api/offercalc/properties`;
      const {
        data: { payload },
      } = await API.get(URL);
      payload?.forEach((item: IOfferProperty) => {
        item.name = i18n.t(`offerProperties.${item.name}`);
      });
      dispatch(loadOfferPropertiesSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingOfferProperties');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const flagNodeRow = (nodeId: string, flag: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { specificationRoot }: ISpecificationState = getState().offerSpecificationModule;
      const { offerId = '', offerVersionId = '' } = specificationRoot ?? {};
      const URL = `/offer/api/offerCalc/${offerId}/node/${nodeId}?versionId=${offerVersionId}`;
      const data = { info: { flagged: flag } };
      await API.put(URL, data);
      dispatch(loadOfferSpecification(offerId, offerVersionId));
    } catch (e) {
      const message = i18n.t('offers.errorReplacingNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
