import i18n from 'i18next';

import { addErrorMessage } from '../../AppAlerts/AppAlertsActions';
import { IMomTreeState } from '../interfaces/MomTreeInterfaces';
import { IChapter, IComponent, nodesIdsType, NodeType } from '../interfaces/ProjectMomInterfaces';

import API from 'utils/API';

export const IS_LOADING = 'IS_LOADING';
export const SELECT_MOM_TREE_ROW = 'SELECT_MOM_TREE_ROW';
export const SET_SELECTED_TEMPLATE_ID = 'SET_SELECTED_TEMPLATE_ID';
export const SET_SELECTED_TEMPLATE_CATALOG_ID = 'SET_SELECTED_TEMPLATE_CATALOG_ID';
export const LOAD_ALL_TEMPLATE_CATALOGS = 'LOAD_ALL_TEMPLATE_CATALOGS';
export const GET_TEMPLATE_CONTENT_BY_ID = 'GET_TEMPLATE_CONTENT_BY_ID';
export const LOAD_MOM_TEMPLATES_BY_CATALOG_ID = 'LOAD_MOM_TEMPLATES_BY_CATALOG_ID';
export const MOM_SELECT_NODE = 'MOM_SELECT_NODE';
export const MOM_DESELECT_NODE = 'MOM_DESELECT_NODE';
export const MOM_SET_SELECTED_NODES_CONTAINERS = 'MOM_SET_SELECTED_NODES_CONTAINERS';
export const MOM_RESET_MODULE = 'MOM_RESET_MODULE';
export const MOM_SELECT_ALL_NODES = 'MOM_SELECT_ALL_NODES';
export const MOM_DESELECT_ALL_NODES = 'MOM_DESELECT_ALL_NODES';
export const MOM_SET_NODES_COUNT = 'MOM_SET_NODES_COUNT';

const setIsLoading = (isLoading: boolean) => ({ type: IS_LOADING, payload: { isLoading } });

export const resetMomTreeModuleToInitialState = () => ({ type: MOM_RESET_MODULE });

export const setNodesCount = (numberOfNodes = 0, numberOfSelectedNodes = 0) => ({
  type: MOM_SET_NODES_COUNT,
  payload: { numberOfNodes, numberOfSelectedNodes },
});

export const selectMomTreeRow = (selectedRow: NodeType | null) => ({
  type: SELECT_MOM_TREE_ROW,
  payload: { selectedRow },
});

const buildSelectedNodeWithChildren = (node: NodeType, allNodes: any) => {
  allNodes[node.id] = node.id;
  if (node.sectionType !== 'component' && Array.isArray(node?.children)) {
    node.children.forEach((node) => buildSelectedNodeWithChildren(node, allNodes));
  }
};

export const selectNode = (node: NodeType) => {
  return (dispatch: Function, getState: Function) => {
    const allSelectedNodes: nodesIdsType = {};
    buildSelectedNodeWithChildren(node, allSelectedNodes);
    const { selectedNodesIds }: IMomTreeState = getState().momTreeModule;
    delete allSelectedNodes.root;
    dispatch({ type: MOM_SELECT_NODE, payload: { selectedNodesIds: { ...selectedNodesIds, ...allSelectedNodes } } });
  };
};

export const selectAllNodes = (nodesTree: Array<NodeType>) => {
  return (dispatch: Function, getState: Function) => {
    const { numberOfSelectedNodes, numberOfNodes }: IMomTreeState = getState().momTreeModule;
    const areAllNodesSelected = numberOfNodes === numberOfSelectedNodes;
    if (!areAllNodesSelected) {
      const root = { id: 'root', children: nodesTree };
      dispatch(selectNode(root as NodeType));
    } else {
      dispatch({
        type: MOM_DESELECT_ALL_NODES,
        payload: {
          selectedNodesIds: {},
        },
      });
    }
  };
};

export const setNodesContainer = (idsOfSelectedNodesContainers: nodesIdsType) => ({
  type: MOM_SET_SELECTED_NODES_CONTAINERS,
  payload: { idsOfSelectedNodesContainers },
});

// @ts-ignore
const findNode = (nodeId: string, chapters: Array<NodeType>) => {
  for (let i = 0; i < chapters.length; i++) {
    // @ts-ignore
    const { id = '', children = [] } = chapters[i];
    if (id === nodeId) {
      return chapters[i];
    }
    // @ts-ignore
    const target = findNode(nodeId, children);
    if (target) return target;
  }
};

export const deselectNode = (deselectedNode: NodeType) => {
  return (dispatch: Function, getState: Function) => {
    const allNodesToDeselect: nodesIdsType = {};
    buildSelectedNodeWithChildren(deselectedNode, allNodesToDeselect);
    const { selectedNodesIds }: IMomTreeState = getState().momTreeModule;
    const selectedNodesIdsClone = structuredClone(selectedNodesIds);

    Object.keys(allNodesToDeselect).forEach((id: string) => {
      delete selectedNodesIdsClone[id];
    });

    dispatch({
      type: MOM_DESELECT_NODE,
      payload: {
        selectedNodesIds: selectedNodesIdsClone,
      },
    });
  };
};

const deselectDeletedNodes = (nodesIds: any) => {
  return (dispatch: Function, getState: Function) => {
    const { selectedNodesIds }: IMomTreeState = getState().momTreeModule;
    const selectedNodesIdsClone = structuredClone(selectedNodesIds);
    Object.keys(nodesIds).forEach((id: string) => {
      delete selectedNodesIdsClone[id];
    });
    dispatch({
      type: MOM_DESELECT_NODE,
      payload: {
        selectedNodesIds: selectedNodesIdsClone,
      },
    });
    dispatch(setNodesContainer({}));
  };
};

export const deleteNodes = (siteId: string, nodesTree: Array<NodeType>, callback = () => {}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { idsOfSelectedNodesContainers }: IMomTreeState = getState().momTreeModule;
      const containerNodesToBeDeleted: Array<IChapter | IComponent> = [];
      Object.values(idsOfSelectedNodesContainers).forEach((id) => {
        const node = findNode(id, nodesTree);
        if (node) containerNodesToBeDeleted.push(node);
      });
      const nodesIds = {};
      containerNodesToBeDeleted.forEach((node: NodeType) => buildSelectedNodeWithChildren(node, nodesIds));
      const query = Object.values(nodesIds).reduce(
        (accumulator, currentValue) => accumulator + `&id=${currentValue}`,
        ''
      );
      const url = `/fdv/api/sites/${siteId}/sections?${query}`;
      await API.delete(url);
      dispatch(selectMomTreeRow(null));
      dispatch(deselectDeletedNodes(nodesIds));
      callback();
    } catch (e) {
      const message = i18n.t('products.errorDeletingSelectedNode');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
