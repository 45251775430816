import { useEffect, useState } from 'react';
import API from 'utils/API';

type IDefaultOfferFilter = {
  categoryId: string;
  tags: Array<string>;
  offerType: string;
};

type IDefaultFilter = IDefaultOfferFilter;

type IProps = {
  filterKey: string;
  url?: string;
};

const useDefaultFilter = (props: IProps) => {
  const { url = 'userconfiguration/tenantdata', filterKey } = props;
  const [defaultFilter, setDefaultFilter] = useState<IDefaultFilter | null>(null);

  useEffect(() => {
    const getDefaultFilterConfig = async () => {
      const configUrl = `${url}/${filterKey}`;
      try {
        const dataPromise = await API.get(configUrl);
        const data = await dataPromise.data;
        setDefaultFilter(data);
      } catch (error) {
        console.log(error);
      }
    };
    getDefaultFilterConfig();
  }, [url, filterKey]);

  return { defaultFilter };
};
export default useDefaultFilter;
