import { styled, Box } from '@mui/material';

export const SelectSupplierWrapper = styled(Box)`
  display: inline-flex;
  align-items: center;
  position: relative;
`;

export const IconStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(0%, -50%)',
  cursor: 'pointer',
  margin: 'auto',
  right: '3rem',
};
