import { getParams } from './Params';

import Auth from 'utils/Auth';

export type JSONResponse = {
  errorResponse?: {
    statusText?: string;
  };
  payload?: any[];
  statusCode: number;
  serviceTime: string;
  message: string;
  pagingInfo: {
    defaultPageSize: number;
    maxPageSize: number;
  };
};
export type BackendResponse = {
  body: any;
  bodyUsed: boolean;
  headers: any;
  ok: boolean;
  redirected: boolean;
  status: number;
  statusText: string;
  type: 'cors';
  url: string;
};

// Sjekker status på responsen
export const checkStatus = async (response: Response) => {
  if (response.ok) {
    return response;
  } else {
    const error: any = new Error(response.statusText);
    const message: string = await response.json().then((data) => data?.message ?? '');
    error.errorResponse = response;
    error.message = message;
    throw error;
  }
};

// Check status on response from axios
export const checkAxiosStatus = (response: any) => {
  if (response === undefined) throw new Error('response is undefined');
  if (response?.status === 200) return response.data;
  else {
    const error: any = new Error(response.statusText);
    error.errorResponse = response;
    throw error;
  }
};

// Handle error catch from axios
export const handleErrorCatch = (error: any) => {
  if (error === undefined) throw new Error('response is undefined');
  if (error?.response?.status === 200) return error.data;
  else {
    const errorResponse: any = new Error(error?.message);
    errorResponse.errorResponse = error?.response;
    return errorResponse;
  }
};

// Gjør om responsen til JSON
export const parseJSON = (result: any): JSONResponse => {
  return result.json();
};

/** Function that makes a fetch request and retries x-times if the response is 401.
 * @param url path to fetch
 * @param options options
 * @param maxRetries Number of times to retry the request
 * @returns Promise<Response>
 */
const fetchWithRefreshRetry = async (url: string, options: RequestInit, maxRetries: number = 1): Promise<Response> => {
  let response = await fetch(url, options);
  let retries = 0;

  while (retries < maxRetries && response.status === 401) {
    const refreshResponse = await Auth.refreshToken();
    const { authToken } = refreshResponse;

    if (!authToken) {
      Auth.logout();
      throw new Error('Could not refresh token');
    }

    // Update the request headers with the new token
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${authToken}`,
    };

    response = await fetch(url, options);
    retries++;
  }

  return response;
};

// Hjelpefunksjoner for get, put, post, patch og delete. Laget egne funksjoner for refresh og login.
export const Fetcher = {
  postLogin: (path: string, body: any) =>
    fetch(path, getParams('POSTLOGIN', body))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  getRefresh: (path: string) =>
    fetch(path, getParams('GETREFRESH'))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  getCodeLogin: (path: string) =>
    fetch(path, getParams('GETCODELOGIN'))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  get: (path: string) =>
    fetchWithRefreshRetry(path, getParams('GET'))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),
  getFetch: (path: string) =>
    fetchWithRefreshRetry(path, getParams('GET'))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  getWithoutJSON: (path: string) =>
    fetchWithRefreshRetry(path, getParams('GET'))
      .then(checkStatus)
      .then((result) => result)
      .catch((error) => error),

  getWithoutJSONasTextPlain: (path: string) =>
    fetchWithRefreshRetry(path, getParams('GETTEXTPLAIN'))
      .then(checkStatus)
      .then((result) => result)
      .catch((error) => error),

  post: (path: string, body?: any, contentType?: string) =>
    fetchWithRefreshRetry(path, getParams('POST', body, contentType))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  postWithoutJSON: (path: string, body?: string) =>
    fetchWithRefreshRetry(path, getParams('POST', body))
      .then(checkStatus)
      .then((result) => result)
      .catch((error) => error),

  postFile: (path: string, body: any) =>
    fetchWithRefreshRetry(path, getParams('POSTFILE', body))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  put: (path: string, body?: any) =>
    fetchWithRefreshRetry(path, getParams('PUT', body))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  patch: (path: string, body: any) =>
    fetchWithRefreshRetry(path, getParams('PATCH', body))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),

  delete: (path: string, body?: string) =>
    fetchWithRefreshRetry(path, getParams('DELETE', body))
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => result)
      .catch((error) => error),
};

export default Fetcher;
