import { FC } from 'react';

type NonSelectedRoundCheckboxType = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const NonSelectedRoundCheckbox: FC<NonSelectedRoundCheckboxType> = ({
  width = 36,
  height = 36,
  color = '#F8FBFC',
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 36" fill="none" {...rest}>
      <circle cx="18" cy="18" r="18" fill={color} />
    </svg>
  );
};

export default NonSelectedRoundCheckbox;
