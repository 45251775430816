export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_DIALOG_LOADING = 'SET_IS_DIALOG_LOADING';

export const setIsLoading = (payload) => {
  return {
    type: SET_IS_LOADING,
    payload,
  };
};

export const setIsDialogLoading = (payload) => {
  return {
    type: SET_IS_DIALOG_LOADING,
    payload,
  };
};
