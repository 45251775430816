import { Grid, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { ChangeEventHandler } from 'react';
import { IOption } from './FilterOptions';
import { useTranslation } from 'react-i18next';
import { FilterTotalsInputWrapper } from './DynamicFilter.styled';
import { NewAutoCompleteStyled } from 'modules/AdminBoard/AdminBoard.styled';

type IProps = {
  totalsOptions: Array<IOption>;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setFieldValue?: Function;
  values?: any;
  errors?: any;
  touched?: any;
};

const FilterTotals = (props: IProps) => {
  const { totalsOptions, handleChange, values } = props;
  const { t } = useTranslation();

  return (
    <Grid container flexDirection={'column'}>
      <Grid item flexDirection={'column'} justifyContent={'space-evenly'}>
        <Grid item>
          <Typography sx={{ fontWeight: 600, pb: 1 }}>{t('globals.totals')}</Typography>
        </Grid>
        <Grid item>
          <NewAutoCompleteStyled
            fullWidth
            hasBorder={false}
            options={totalsOptions}
            onChange={handleChange}
            name="amountFilter"
            optionKeyLabel="label"
            optionKeyValue="value"
            value={values.amountFilter}
            sx={{ borderRadius: '12px' }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-evenly'} pt={1}>
        <Grid item xs={12} md={6} lg={6} pr={1}>
          <FilterTotalsInputWrapper>
            <OutlinedInput
              endAdornment={<InputAdornment position="start">kr</InputAdornment>}
              placeholder={t('globals.from')}
              name="amountFrom"
              value={values.amountFrom}
              onChange={handleChange}
              disabled={values?.amountFilter ? false : true}
            />
          </FilterTotalsInputWrapper>
        </Grid>
        <Grid item xs={12} md={6} lg={6} pl={1}>
          <FilterTotalsInputWrapper>
            <OutlinedInput
              endAdornment={<InputAdornment position="start">kr</InputAdornment>}
              placeholder={t('globals.to')}
              name="amountTo"
              value={values.amountTo}
              onChange={handleChange}
              disabled={values?.amountFilter ? false : true}
            />
          </FilterTotalsInputWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FilterTotals;
