import { FC } from 'react';

type MultiplePersonsPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MultiplePersons: FC<MultiplePersonsPropTypes> = ({
  width = 40,
  height = 40,
  color = 'white',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="white" fillOpacity="0.61" />
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M19.9891 9.16675C14.0091 9.16675 9.15576 14.0201 9.15576 20.0001C9.15576 
        25.9801 14.0091 30.8334 19.9891 30.8334C25.9691 30.8334 30.8224 25.9801 
        30.8224 20.0001C30.8224 14.0201 25.9691 9.16675 19.9891 9.16675ZM23.8999 
        16.0351C25.0591 16.0351 25.9908 16.9667 25.9908 18.1259C25.9908 19.2851 
        25.0591 20.2167 23.8999 20.2167C22.7408 20.2167 21.8091 19.2851 21.8091 
        18.1259C21.7983 16.9667 22.7408 16.0351 23.8999 16.0351ZM17.3999 
        14.3234C18.8083 14.3234 19.9566 15.4717 19.9566 16.8801C19.9566 
        18.2884 18.8083 19.4367 17.3999 19.4367C15.9916 19.4367 14.8433 
        18.2884 14.8433 16.8801C14.8433 15.4609 15.9808 14.3234 17.3999 
        14.3234ZM17.3999 24.2142V28.2767C14.7999 27.4642 12.7416 25.4601 
        11.8316 22.9034C12.9691 21.6901 15.8074 21.0726 17.3999 21.0726C17.9741 
        21.0726 18.6999 21.1592 19.4583 21.3109C17.6816 22.2534 17.3999 23.4992 
        17.3999 24.2142ZM19.9891 28.6667C19.6966 28.6667 19.4149 28.6559 19.1333 
        28.6234V24.2142C19.1333 22.6759 22.3183 21.9067 23.8999 21.9067C25.0591 
        21.9067 27.0633 22.3292 28.0599 23.1526C26.7924 26.3701 23.6616 28.6667 
        19.9891 28.6667Z"
        fill={color}
      />
    </svg>
  );
};

export default MultiplePersons;
