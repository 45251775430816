import i18n from 'i18next';
import { 
  ADD_ERROR_MESSAGE_ACTION, 
  SET_BUILDING_ELEMENTS_LOADER_ACTION, 
  SET_HAS_MORE_ITEMS_ACTION,
  LOAD_ALL_BUILDING_ELEMENTS_SUCCESS_ACTION,
  SAVE_BUILDING_ELEMENT_ITEM_ACTION,
  SET_BUILDING_ELEMENT_ITEM_LOADER_ACTION,
} from './types';
import { 
  IParams, 
  IBuildingElementItem, 
  IBuildingElementsResponse, 
  ICreateBuildingElementItem,
  IBuildingElementItemResponse,
} from './../BuildingElementsInterfaces';
import API from "utils/API";
import { AxiosResponse } from 'axios';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';


export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

export const LOAD_ALL_ELEMENTS = 'LOAD_ALL_BUILDING_ELEMENTS';
export const LOAD_ELEMENTS_ITEM = 'LOAD_BUILDING_ELEMENTS_ITEM';

export const IS_ALL_ELEMENTS_ITEMS_LOADING = 'IS_ALL_BUILDING_ELEMENTS_ITEMS_LOADING';
export const IS_ELEMENTS_ITEM_LOADING = 'IS_BUILDING_ELEMENTS_ITEM_LOADING';

export const SET_BUILDING_ELEMENTS_LOADER = 'SET_BUILDING_ELEMENTS_LOADER';
export const SET_BUILDING_ELEMENT_ITEM_LOADER = 'SET_BUILDING_ELEMENT_ITEM_LOADER';
export const LOAD_ALL_BUILDING_ELEMENTS_SUCCESS = 'LOAD_ALL_BUILDING_ELEMENTS_SUCCESS';

export const SET_HAS_MORE_ITEMS = 'SET_HAS_MORE_ITEMS';

export const SAVE_BUILDING_ELEMENTS_ITEM = 'SAVE_BUILDING_ELEMENTS_ITEM';

export const loadAllItemsAction = (elements: any[]) => ({
  type: LOAD_ALL_ELEMENTS,
  elements,
});

export const addErrorMessageAction = (error: any): ADD_ERROR_MESSAGE_ACTION => ({
  type: ADD_ERROR_MESSAGE,
  error,
});

export const setBuildingElementsLoader = (isLoading: boolean): SET_BUILDING_ELEMENTS_LOADER_ACTION => ({
  type: SET_BUILDING_ELEMENTS_LOADER,
  isLoading,
})

export const setBuildingElementItemLoader = (isLoading: boolean): SET_BUILDING_ELEMENT_ITEM_LOADER_ACTION => ({
  type: SET_BUILDING_ELEMENT_ITEM_LOADER,
  isLoading,
});

export const setHasMoreItems = (hasMoreItems: boolean): SET_HAS_MORE_ITEMS_ACTION => ({
  type: SET_HAS_MORE_ITEMS,
  hasMoreItems,
});

export const saveAllBuildingElementsAction = (
  allBuildingElements: IBuildingElementItem[] | [],
  totalBuildingElementsNumber: number,
  overWrite?: boolean
): LOAD_ALL_BUILDING_ELEMENTS_SUCCESS_ACTION => ({
  type: LOAD_ALL_BUILDING_ELEMENTS_SUCCESS,
  allBuildingElements,
  totalBuildingElementsNumber,
  overWrite,
});

export const saveBuildingElementItem = (buildingElementItem: IBuildingElementItem | null): SAVE_BUILDING_ELEMENT_ITEM_ACTION => ({
  type: SAVE_BUILDING_ELEMENTS_ITEM,
  buildingElementItem,
});

export const loadAllBuildingElements = (params?: IParams | null, overWrite?: boolean) => {
  const pageNumber = params?.pageNumber || 0;
  const pageSize = params?.pageSize || 20;
  const countUrl = 'buildingelements/api/tables/count';
  const allBuildingElementsURL = `buildingelements/api/tables?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    dispatch(setBuildingElementsLoader(true));
    try {
      const countPromise = API.get(countUrl);
      const allBuildingElementsData = API.get(allBuildingElementsURL);
      const {
        data: { payload:  buildingelements },
      }: AxiosResponse<IBuildingElementsResponse> = await allBuildingElementsData;
      const {
        data: { payload: totalBuildingElementsNumber },
      } = await countPromise;
      if (buildingelements.length === 0 || buildingelements.length < Number(pageSize)) {
        //Promise.reject();
        dispatch(setHasMoreItems(false));
      } else dispatch(setHasMoreItems(true));
      dispatch(saveAllBuildingElementsAction(buildingelements, totalBuildingElementsNumber, overWrite));
    } catch (error) {
      dispatch(addErrorMessage({ message: 'Something went wrong' }));
    } finally {
      dispatch(setBuildingElementsLoader(false));
    }
  };
};

// Add Building Element to table
export const addBuildingElement = (tableId: string, requestBody: ICreateBuildingElementItem, callback: Function) => {
  const addBuildingElementUrl = `buildingelements/api/tables/${tableId}/Elements`;

  return async (dispatch: Function) => {
    dispatch(setBuildingElementsLoader(true));
    try {
      const {
        data: { payload: buildingElementItem },
      }: AxiosResponse<IBuildingElementItemResponse> = await API.post(addBuildingElementUrl, requestBody);
      dispatch(loadAllBuildingElements({ pageSize: 20, pageNumber: 0 }, true));
      callback?.(buildingElementItem.id);
      const message = i18n.t('buildingElements.successAddBuildingElementItem');
      dispatch(addSuccessMessage({ message }));
    } catch (error) {
      const message = i18n.t('buildingElements.failAddBuildingElementItem');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setBuildingElementsLoader(false));
    }
  };
};


export const loadBuildingElementItem = (buildingElementId: string) => {
  const getBuildingElementUrl = `buildingelements/api/tables/${buildingElementId}`;
  return async (dispatch: Function) => {
    dispatch(setBuildingElementItemLoader(true));
    try {
      const getBuildingElementPromise = API.get(getBuildingElementUrl);
      const {
        data: { payload: buildingElementItem },
      }: AxiosResponse<IBuildingElementItemResponse> = await getBuildingElementPromise;
      dispatch(saveBuildingElementItem(buildingElementItem));
    } catch (error) {
      dispatch(addErrorMessageAction(error));
    } finally {
      dispatch(setBuildingElementItemLoader(false));
    }
  };
};
