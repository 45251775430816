import { FC } from 'react';

type EmptyStateLoadingIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const EmptyStateLoadingIcon: FC<EmptyStateLoadingIconPropTypes> = ({
  width = 512,
  height = 512,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="512" height="512" fill="white" />
      <path
        d="M128.036 448H383.956C390.99 448.017 397.771 445.359 402.939 440.559C424.498 420.478 441.745 396.172 453.62 369.137C465.495 342.101 471.746 312.908 471.99 283.351C473.094 163.171 375.985 64.2842 256.52 64.0006C136.987 63.7168 39.9992 161.115 39.9992 281.302C39.965 311.216 46.0876 340.812 57.9813 368.229C69.875 395.645 87.2822 420.287 109.107 440.603C114.264 445.381 121.025 448.023 128.036 448Z"
        fill="#EFF1F8"
      />
      <path
        d="M345.587 159.218V122.783H166.413V159.218C166.413 168.29 168.317 177.261 172.001 185.552C175.685 193.842 181.068 201.267 187.802 207.346L251.569 264.918L187.802 322.489C181.068 328.569 175.685 335.994 172.001 344.284C168.317 352.574 166.413 361.545 166.413 370.617V407.053H345.587V370.618C345.587 361.546 343.684 352.575 339.999 344.285C336.315 335.995 330.932 328.57 324.199 322.49L260.431 264.918L324.199 207.345C330.932 201.266 336.315 193.841 339.999 185.551C343.684 177.261 345.587 168.29 345.587 159.218Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="6"
      />
      <path d="M212.34 225.5C232.459 202.785 256 247.922 299.66 225.5L250.551 252.958L212.34 225.5Z" fill="#0975A5" />
      <path
        d="M212.34 225.5C232.459 202.785 256 247.922 299.66 225.5L250.551 252.958L212.34 225.5Z"
        fill="white"
        fillOpacity="0.15"
      />
      <path
        d="M348.844 82.9106H163.157C159.517 82.9106 156.566 85.8612 156.566 89.5009V116.654C156.566 120.294 159.517 123.244 163.157 123.244H348.844C352.484 123.244 355.434 120.294 355.434 116.654V89.5009C355.434 85.8612 352.484 82.9106 348.844 82.9106Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M348.844 407.053H163.157C159.517 407.053 156.566 410.004 156.566 413.643V440.797C156.566 444.436 159.517 447.387 163.157 447.387H348.844C352.484 447.387 355.434 444.436 355.434 440.797V413.643C355.434 410.004 352.484 407.053 348.844 407.053Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M316.341 385.316L281.024 361.489C275.497 357.759 270.832 352.891 267.342 347.209C263.852 341.528 261.619 335.165 260.792 328.549H260.739C260.538 326.529 260.432 324.578 260.432 322.76V260.918L299.66 225.5C265.754 213.493 246.398 250.304 212.34 225.5L251.58 260.918V322.752C251.58 324.69 251.459 326.626 251.219 328.549C250.392 335.165 248.158 341.528 244.669 347.209C241.179 352.891 236.514 357.759 230.987 361.489L195.67 385.316C192.603 387.385 190.091 390.176 188.354 393.443C186.617 396.71 185.709 400.353 185.709 404.053H326.302C326.302 400.353 325.394 396.71 323.657 393.443C321.92 390.176 319.408 387.385 316.341 385.316Z"
        fill="#0975A5"
      />
      <path
        d="M232.624 79.8296C228.328 79.8248 224.21 78.1161 221.172 75.0785C218.134 72.0409 216.426 67.9223 216.421 63.6265C216.402 62.5573 215.964 61.5384 215.2 60.7899C214.437 60.0414 213.409 59.6235 212.34 59.6265C211.279 59.6265 210.262 60.048 209.511 60.7981C208.761 61.5482 208.34 62.5656 208.34 63.6265C208.335 67.9438 206.618 72.083 203.565 75.1358C200.512 78.1886 196.373 79.9058 192.056 79.9107C190.995 79.9107 189.977 80.3321 189.227 81.0822C188.477 81.8324 188.056 82.8498 188.056 83.9107C188.056 84.9715 188.477 85.9889 189.227 86.7391C189.977 87.4892 190.995 87.9107 192.056 87.9107C196.373 87.9155 200.512 89.6327 203.565 92.6855C206.618 95.7384 208.335 99.8775 208.34 104.195C208.34 105.256 208.761 106.273 209.511 107.023C210.262 107.773 211.279 108.195 212.34 108.195C213.401 108.195 214.418 107.773 215.168 107.023C215.918 106.273 216.34 105.256 216.34 104.195C216.345 99.8775 218.062 95.7384 221.115 92.6855C224.168 89.6327 228.307 87.9155 232.624 87.9107C233.693 87.8922 234.712 87.4538 235.461 86.6901C236.209 85.9265 236.627 84.8989 236.624 83.8296C236.624 82.7688 236.203 81.7514 235.452 81.0012C234.702 80.2511 233.685 79.8297 232.624 79.8296Z"
        fill="#0975A5"
      />
      <path
        d="M172.347 418.51C169.313 415.469 167.609 411.349 167.609 407.053C167.609 402.757 169.313 398.637 172.347 395.596C173.09 394.827 173.501 393.796 173.49 392.727C173.479 391.658 173.048 390.636 172.29 389.882C171.54 389.131 170.522 388.71 169.462 388.71C168.401 388.71 167.383 389.131 166.633 389.882C163.577 392.931 159.436 394.643 155.118 394.643C150.801 394.643 146.66 392.931 143.604 389.882C142.854 389.131 141.836 388.71 140.775 388.71C139.715 388.71 138.697 389.131 137.947 389.882C137.197 390.632 136.775 391.649 136.775 392.71C136.775 393.771 137.197 394.788 137.947 395.538C140.996 398.595 142.709 402.736 142.709 407.053C142.709 411.37 140.996 415.511 137.947 418.568C137.576 418.939 137.281 419.38 137.08 419.865C136.879 420.351 136.775 420.871 136.775 421.396C136.775 421.921 136.879 422.442 137.08 422.927C137.281 423.412 137.576 423.853 137.947 424.225C138.318 424.596 138.759 424.891 139.245 425.092C139.73 425.293 140.25 425.396 140.775 425.396C141.301 425.396 141.821 425.293 142.306 425.092C142.791 424.891 143.232 424.596 143.604 424.225C146.66 421.175 150.801 419.463 155.118 419.463C159.436 419.463 163.577 421.175 166.633 424.225C167.402 424.968 168.433 425.378 169.502 425.367C170.571 425.357 171.593 424.925 172.347 424.167C173.097 423.417 173.519 422.4 173.519 421.339C173.519 420.278 173.097 419.261 172.347 418.51Z"
        fill="#0975A5"
      />
      <path
        d="M365.333 272.692C362.298 269.651 360.594 265.531 360.594 261.235C360.594 256.939 362.298 252.818 365.333 249.777C366.076 249.008 366.486 247.978 366.475 246.909C366.465 245.839 366.034 244.817 365.275 244.063C364.525 243.313 363.508 242.892 362.447 242.892C361.386 242.892 360.369 243.313 359.618 244.063C356.562 247.113 352.421 248.825 348.104 248.825C343.786 248.825 339.645 247.113 336.589 244.063C335.839 243.313 334.822 242.892 333.761 242.892C332.7 242.892 331.682 243.313 330.932 244.063C330.182 244.813 329.761 245.831 329.761 246.892C329.761 247.952 330.182 248.97 330.932 249.72C333.982 252.776 335.694 256.917 335.694 261.235C335.694 265.552 333.982 269.693 330.932 272.749C330.182 273.499 329.761 274.517 329.761 275.578C329.761 276.639 330.182 277.656 330.932 278.406C331.682 279.156 332.7 279.578 333.761 279.578C334.822 279.578 335.839 279.156 336.589 278.406C339.645 275.357 343.786 273.644 348.104 273.644C352.421 273.644 356.562 275.357 359.618 278.406C360.388 279.149 361.418 279.56 362.487 279.549C363.557 279.538 364.579 279.107 365.333 278.349C366.083 277.599 366.504 276.581 366.504 275.52C366.504 274.46 366.083 273.442 365.333 272.692Z"
        fill="#0975A5"
      />
    </svg>
  );
};

export default EmptyStateLoadingIcon;
