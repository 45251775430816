import { useMediaQuery, useTheme } from '@mui/material';

export const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1920,
  },
};

export const useBreakPoints = () => {
  const theme = useTheme();

  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isDownXl = useMediaQuery(theme.breakpoints.down('xl'));

  const isBetweenXsAndSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isBetweenMdAndLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isBetweenLgAndXl = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  return {
    isUpSm,
    isUpMd,
    isUpLg,
    isUpXl,
    isDownSm,
    isDownMd,
    isDownLg,
    isDownXl,
    isBetweenXsAndSm,
    isBetweenSmAndMd,
    isBetweenMdAndLg,
    isBetweenLgAndXl,
    isMobileView,
  };
};

export default useBreakPoints;
