import { FC } from 'react';

type MenuIconMoveIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconMoveIcon: FC<MenuIconMoveIconPropTypes> = ({ width = 35, height = 35, color = "#C3CAD9", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2539_15959)">
    <rect x="5" y="3.85193" width="24.3956" height="24.3956" rx="12.1978" fill="white"/>
    <rect x="5" y="3.85193" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.1966 10.6284C14.2041 10.6284 11.7754 13.0571 11.7754 16.0497C11.7754 19.0422 14.2041 21.4709 17.1966 21.4709C20.1892 21.4709 22.6179 19.0422 22.6179 16.0497C22.6179 13.0571 20.1892 10.6284 17.1966 10.6284ZM20.4494 16.0497C20.4494 17.8441 18.9911 19.3024 17.1966 19.3024C15.4022 19.3024 13.9439 17.8441 13.9439 16.0497H15.0281C15.0281 17.2478 15.9985 18.2182 17.1966 18.2182C18.3947 18.2182 19.3651 17.2478 19.3651 16.0497C19.3651 14.8516 18.3947 13.8812 17.1966 13.8812V15.5075L15.0281 13.339L17.1966 11.1705V12.7969C18.9911 12.7969 20.4494 14.2552 20.4494 16.0497Z" fill={color}/>
    <defs>
    <filter id="filter0_d_2539_15959" x="0.121566" y="0.599197" width="34.1534" height="34.1538" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.62637"/>
    <feGaussianBlur stdDeviation="2.03297"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2539_15959"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2539_15959" result="shape"/>
    </filter>
    </defs>
    </svg>
  );
};

export default MenuIconMoveIcon;
