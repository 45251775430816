import {
  SET_INDUSTRY_LIST,
  SET_ADJUSTMENT_LIST,
  CHANGE_INDUSTRY,
  ADD_ERROR_MESSAGE,
  SET_LOADING,
  SAVE_INDUSTRY_ITEM,
} from './adjustmentsActions';
import { ADJUSTMENTS_ACTIONS } from './types';
import { IAdjustmantsState } from 'modules/CollectiveAdjustments/adjustmentInterfaces';

const defaultState: IAdjustmantsState = {
  industries: [],
  adjustments: [],
  totalAdjustmentsNumber: 0,
  isLoading: false,
  chosenIndustryId: '',
  selectedItem: null,
};

const adjustmentsReducer = (state = defaultState, action: ADJUSTMENTS_ACTIONS) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    case ADD_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_INDUSTRY_LIST:
      return {
        ...state,
        industries: action.allIndustries,
      };
    case SET_ADJUSTMENT_LIST:
      return {
        ...state,
        adjustments: action.allAdjustments,
        totalAdjustmentsNumber: action.totalAdjustmentsNumber,
      };
    case CHANGE_INDUSTRY:
      return {
        ...state,
        chosenIndustryId: action.industryId,
      };
    case SAVE_INDUSTRY_ITEM:
      return {
        ...state,
        selectedItem: action.adjustment,
      };
    default:
      return state;
  }
};

export default adjustmentsReducer;
