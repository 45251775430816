import i18n from 'i18next';
import API from 'utils/API';
import { IOfferSetting, IProductPriceSelectors } from '../OfferSettingsInterfaces';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { useQueryParams } from 'routeUtils';
import { ICreateProductSelector } from '../OfferSettingsView/Dialogs/ProductSelectionDialog';

export const LOAD_OFFER_SETTINGS = 'LOAD_OFFER_SETTINGS';
export const IS_OFFER_SETTINGS_LOADING = 'IS_OFFER_SETTINGS_LOADING';
export const IS_OFFER_SETTING_ITEM_LOADING = 'IS_OFFER_SETTING_ITEM_LOADING';
export const IS_OFFER_SETTINGS_DIALOG_LOADING = 'IS_OFFER_SETTINGS_DIALOG_LOADING';
export const EDIT_OFFER_SETTING = 'EDIT_OFFER_SETTING';
export const LOAD_DELETED_OFFER_SETTING = 'LOAD_DELETED_OFFER_SETTING';
export const HARD_DELETE_OFFER_SETTINGS = 'HARD_DELETE_OFFER_SETTINGS';
export const DELETE_OFFER_SETTINGS = 'DELETE_OFFER_SETTINGS';
export const RESTORE_OFFER_SETTINGS = 'RESTORE_OFFER_SETTINGS';
export const SAVE_SEARCH_RESULT = 'SAVE_SEARCH_RESULT';
export const GET_OFFER_SETTINGS_BY_ID = 'GET_OFFER_SETTINGS_BY_ID';
export const GET_PRODUCT_PRICE_SELECTORS = 'GET_PRODUCT_PRICE_SELECTORS';

export const RESET_OFFER_SETTINGS_STATE = 'RESET_OFFER_SETTINGS_STATE';

export const resetOfferSettingsState = () => ({ type: RESET_OFFER_SETTINGS_STATE });

const setIsItemsLoading = (value: boolean) => ({ type: IS_OFFER_SETTINGS_LOADING, payload: { isItemsLoading: value } });

const setIsItemLoading = (value: boolean) => ({
  type: IS_OFFER_SETTING_ITEM_LOADING,
  payload: { isItemLoading: value },
});

const setIsDialogLoading = (value: boolean) => ({
  type: IS_OFFER_SETTINGS_DIALOG_LOADING,
  payload: { isDialogLoading: value },
});

const loadAllOfferSettingsSuccess = (payload: Array<IOfferSetting>, totalItems: number) => ({
  type: LOAD_OFFER_SETTINGS,
  payload: { items: [...payload], totalItems },
});

export const loadDeletedOfferSettingsSuccess = (payload: Array<IOfferSetting>, totalDeletedItems: number) => ({
  payload: { deletedItems: [...payload], totalDeletedItems },
  type: LOAD_DELETED_OFFER_SETTING,
});

const deleteOfferSettingSuccess = (payload: IOfferSetting, hardDelete: boolean) => ({
  payload,
  type: hardDelete ? HARD_DELETE_OFFER_SETTINGS : DELETE_OFFER_SETTINGS,
});

export const restoreOfferSettingSuccess = (payload: IOfferSetting) => ({
  payload,
  type: RESTORE_OFFER_SETTINGS,
});

export const getOfferSettingByIdSuccess = (item: IOfferSetting | null) => ({
  type: GET_OFFER_SETTINGS_BY_ID,
  payload: { selectedItem: item },
});

export const editOfferSettingSuccess = (item: IOfferSetting | null) => ({
  type: EDIT_OFFER_SETTING,
  payload: { selectedItem: item },
});

export const getProductPriceSelectorsSuccess = (payload: IProductPriceSelectors[]) => ({
  type: GET_PRODUCT_PRICE_SELECTORS,
  payload: { productPriceSelectors: payload },
});

export const loadAllOfferSettings = (params: any) => {
  const { pageNumber = 0, pageSize = 10 } = params ?? {};
  const url = `/offer/api/stdparameters?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    try {
      dispatch(setIsItemsLoading(true));
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      dispatch(loadAllOfferSettingsSuccess(payload, payload.length));
    } catch (error) {
      const message = i18n.t('offerSettings.errorLoadingOfferSettings');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsItemsLoading(false));
    }
  };
};

export const loadDeletedOfferSettings = (params?: any) => {
  const { pageNumber = 0, pageSize = 10 } = params ?? {};
  const URL = `/offer/api/stdparameters/deleted?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const dataPromise = API.get(URL);
      const {
        data: { payload },
      } = await dataPromise;
      dispatch(loadDeletedOfferSettingsSuccess(payload, payload.length));
    } catch (error) {
      const message = i18n.t('offerSettings.errorLoadingDeletedOfferSettings');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const deleteOfferSetting = (id: string, hardDelete = false) => {
  return async (dispatch: Function) => {
    try {
      const URL = `/offer/api/stdparameters/${id}?hardDelete=${hardDelete}`;
      hardDelete ? dispatch(setIsDialogLoading(true)) : dispatch(setIsItemsLoading(true));
      const {
        data: { payload },
      } = await API.delete(URL);
      dispatch(deleteOfferSettingSuccess(payload, hardDelete));
      const message = i18n.t('offerSettings.successDeletingOfferSetting');
      dispatch(addSuccessMessage({ message }));
      dispatch(loadDeletedOfferSettings({}));
    } catch (error) {
      const message = i18n.t('offerSettings.errorDeletingOfferSetting');
      dispatch(addErrorMessage({ message }));
    } finally {
      hardDelete ? dispatch(setIsDialogLoading(false)) : dispatch(setIsItemsLoading(false));
    }
  };
};

export const restoreOfferSetting = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/offer/api/stdparameters/deleted/${id}`;
      const {
        data: { payload },
      } = await API.put(URL);
      dispatch(restoreOfferSettingSuccess(payload));
      const message = i18n.t('offerSettings.successRestoringDeletedOfferSetting');
      dispatch(addSuccessMessage({ message }));
      const { pageNumber, pageSize } = useQueryParams();
      dispatch(loadAllOfferSettings({ pageNumber, pageSize }));
    } catch (error) {
      const message = i18n.t('offerSettings.errorRestoringDeletedOfferSetting');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const createOfferSetting = (data: IOfferSetting, callback: Function) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/offer/api/stdparameters`;
      const {
        data: { payload: offerSetting },
      } = await API.post(URL, data);
      callback(offerSetting?.id);
      const message = i18n.t('offerSettings.successCreatingNewOfferSetting');
      dispatch(addSuccessMessage({ message }));
      const { pageSize, pageNumber } = useQueryParams();
      dispatch(loadAllOfferSettings({ pageSize, pageNumber }));
    } catch (e) {
      const message = i18n.t('offerSettings.errorCreatingNewOfferSetting');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const getOfferSettingById = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsItemLoading(true));
      //dispatch(getOfferSettingByIdSuccess(null));
      const URL = `/offer/api/stdparameters/${id}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(getOfferSettingByIdSuccess(payload));
    } catch (e) {
      const message = i18n.t('offerSettings.errorLoadingOfferSetting');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsItemLoading(false));
    }
  };
};

export const editOfferSetting = (id: string, data: IOfferSetting, callback = () => {}) => {
  return async (dispatch: Function) => {
    const url = `/offer/api/stdparameters/${id}`;
    try {
      dispatch(setIsDialogLoading(true));
      const {
        data: { payload },
      } = await API.put(url, data);
      dispatch(editOfferSettingSuccess(payload));
      const message = i18n.t('offerSettings.successEditingOfferSetting');
      dispatch(addSuccessMessage({ message }));
      callback();
    } catch (e) {
      const message = i18n.t('offerSettings.errorEditingOfferSetting');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const getProductPriceSelectors = (parametersId: string) => {
  return async (dispatch: Function) => {
    try {
      const getProductSelectors = `/offer/api/stdparameters/${parametersId}/productpriceselectors`;
      const getProductSelectorsPromise = API.get(getProductSelectors);
      const {
        data: { payload },
      } = await getProductSelectorsPromise;
      dispatch(getProductPriceSelectorsSuccess(payload));
    } catch {
      const message = i18n.t('offerSettings.errorGettingSelectors');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const addProductPriceSelector = (parametersId: string, data: ICreateProductSelector) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsItemLoading(true));
      const createProductSelector = `/offer/api/stdparameters/${parametersId}/productpriceselectors`;
      const createProductSelectorPromise = API.post(createProductSelector, data);
      const {
        data: { payload },
      } = await createProductSelectorPromise;
    } catch (e) {
      const message = i18n.t('offerSettings.errorCreatingSelector');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsItemLoading(false));
      dispatch(getProductPriceSelectors(parametersId));
    }
  };
};

export const removeProductPriceSelector = (parametersId: string, selectorId: string) => {
  return async (dispatch: Function) => {
    try {
      const deleteProductSelector = `/offer/api/stdparameters/${parametersId}/productpriceselectors/${selectorId}`;
      const deleteProductSelectorPromise = API.delete(deleteProductSelector);
      const {
        data: { payload },
      } = await deleteProductSelectorPromise;
    } catch (e) {
      const message = i18n.t('offerSettings.errorDeletingSelector');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(getProductPriceSelectors(parametersId));
    }
  };
};

export const updateProductPriceSelector = (parametersId: string, selectorId: string, body: any) => {
  return async (dispatch: Function) => {
    try {
      const updateProductSelector = `/offer/api/stdparameters/${parametersId}/productpriceselectors/${selectorId}`;
      const updateProductSelectorPromise = API.put(updateProductSelector, body);
      const {
        data: { payload },
      } = await updateProductSelectorPromise;
    } catch (e) {
      const message = i18n.t('offerSettings.errorUpdatingSelector');
      dispatch(addErrorMessage({ message }));
    } finally {
    }
  };
};
