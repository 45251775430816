import { FC } from 'react';

type TriceptaPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const Tricepta: FC<TriceptaPropTypes> = ({
  width = 48,
  height = 48,
  color = '#046B99',
  bgColor = 'white',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 11.2C0 5.01441 5.01441 0 11.2 0H36.8C42.9856 0 48 5.01441 48 11.2V36.8C48 42.9856 42.9856 48 36.8 48H11.2C5.01441 48 0 42.9856 0 36.8V11.2Z"
        fill={bgColor}
      />
      <path d="M25 12.1667L19.0416 21.9167H30.9583L25 12.1667Z" fill={color} />
      <path
        d="M30.9584 33.8334C33.6508 33.8334 35.8334 31.6508 35.8334 28.9584C35.8334 26.266 
        33.6508 24.0834 30.9584 24.0834C28.266 24.0834 26.0834 26.266 26.0834 28.9584C26.0834 31.6508 
        28.266 33.8334 30.9584 33.8334Z"
        fill={color}
        fillOpacity="0.54"
      />
      <path d="M15.25 24.625H23.9167V33.2917H15.25V24.625Z" fill={color} fillOpacity="0.4" />
    </svg>
  );
};

export default Tricepta;
