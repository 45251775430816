import { FormControl, Autocomplete, Box, TextField, styled } from '@mui/material';
import { useState, useEffect, SyntheticEvent, FC, ReactElement } from 'react';

export interface IAutoCompleteProps {
  value?: string;
  name?: string;
  label: string;
  onBlur?: Function;
  options: Array<any>;
  onChange?: Function;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  optionKeyValue: string;
  optionKeyLabel: string;
  size?: 'small' | 'medium';
  loading?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
}

let counter = 0;

const OptionStyled = styled(Box)<any>`
  font-size: 0.75rem;
  line-height: 1.5;
`;

export const AutoComplete: FC<any> = (props: IAutoCompleteProps): ReactElement => {
  const {
    fullWidth,
    margin = 'none',
    options = [],
    value,
    onChange = () => {},
    onBlur = () => {},
    name,
    label,
    placeholder,
    error,
    helperText,
    optionKeyValue,
    optionKeyLabel,
    size = 'medium',
    variant = 'outlined',
    ...rest
  } = props;

  const [objectValue, setObjectValue] = useState(options.find((item) => item[optionKeyValue] === value) ?? null);

  useEffect(() => {
    // In case we need to return the whole object, we won't pass the optionKeyValue property and this function
    // will set the whole object as the value. (Example AddnewOfferDialog with customer add. We need id AND customerType)
    //if (optionKeyValue) setObjectValue(() => options.find((item) => item[optionKeyValue] === value) ?? null);
    //else setObjectValue(() => options.find((item) => item === value) ?? null);
    if (optionKeyValue) return;
    setObjectValue(() => options.find((item) => item === value) ?? null);
  }, [options, optionKeyValue, value]);

  const handleChange = (e: SyntheticEvent<Element, Event>, option: any) => {
    if (option) {
      // In case we need to return the whole object, we won't pass the optionKeyValue property and this function
      // will set the whole object as the value. (Example AddnewOfferDialog with customer add. We need id AND customerType)
      onChange({ target: { name, value: optionKeyValue ? option[optionKeyValue] : option } });
      setObjectValue({ ...option });
    } else {
      onChange({ target: { name, value: '' } });
    }
  };

  const handleBlur = (e: SyntheticEvent<Element, Event>) => {
    const target = e.target as HTMLInputElement;
    if (e.type === 'blur') {
      onBlur({ target: { name, value: target?.value } });
    }
  };

  const getOptionLabel = (option: any) => {
    let value = '';
    if (Array.isArray(optionKeyLabel)) {
      optionKeyLabel.forEach((item) => {
        value = value + option[item] + ' ';
      });
      value = value.trim();
    } else {
      value = option[optionKeyLabel] || '';
    }
    return value;
  };

  return (
    <FormControl fullWidth={fullWidth} size={size}>
      <Autocomplete
        fullWidth
        autoComplete
        clearOnEscape
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        value={objectValue}
        isOptionEqualToValue={(option) => option[optionKeyValue] === objectValue[optionKeyValue]}
        getOptionLabel={getOptionLabel}
        renderOption={(props = {}, option, state) => {
          // @ts-ignore
          props.key = option.id
            ? option.id
            : !Array.isArray(optionKeyLabel)
            ? `${optionKeyLabel}-${counter++}`
            : `key-${counter++}`;
          return (
            <OptionStyled component="li" {...props}>
              {getOptionLabel(option) ?? ''}
            </OptionStyled>
          );
        }}
        renderInput={(params) => {
          // @ts-ignore
          params.size = size;
          return (
            <TextField
              {...params}
              size={size}
              margin={margin}
              label={label}
              error={error}
              variant={variant}
              placeholder={placeholder}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
              }}
            />
          );
        }}
        {...rest}
      />
    </FormControl>
  );
};

export default AutoComplete;
