import { FC } from 'react';

type UpdateArrowIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const UpdateArrowIcon: FC<UpdateArrowIconPropTypes> = ({ width = 80, height = 80, color = "#fefefe", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3255_17166)">
    <circle cx="40" cy="36" r="16" fill="#049975"/>
    </g>
    <path d="M45.4907 32.8628L42.3534 36.0001H44.7064C44.7064 38.5962 42.5966 40.706 40.0005 40.706C39.2083 40.706 38.4554 40.5099 37.8044 40.157L36.6593 41.3021C37.624 41.9138 38.7691 42.2746 40.0005 42.2746C43.4672 42.2746 46.275 39.4668 46.275 36.0001H48.6279L45.4907 32.8628ZM35.2946 36.0001C35.2946 33.404 37.4044 31.2942 40.0005 31.2942C40.7927 31.2942 41.5456 31.4903 42.1966 31.8432L43.3417 30.6981C42.377 30.0864 41.2319 29.7256 40.0005 29.7256C36.5338 29.7256 33.726 32.5334 33.726 36.0001H31.373L34.5103 39.1374L37.6476 36.0001H35.2946Z" fill={color}/>
    <defs>
    <filter id="filter0_d_3255_17166" x="0" y="0" width="80" height="80" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="12"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3255_17166"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3255_17166" result="shape"/>
    </filter>
    </defs>
    </svg>
  );
};

export default UpdateArrowIcon;
