import { LOAD_ALL_OFFER_TYPES, SAVE_TOTAL_OFFER_TYPES, SET_HAS_MORE_ITEMS } from './offerTypesActions';
import { IOfferTypesAction, IOfferTypesState } from './types';

const defaultState: IOfferTypesState = {
  offerTypes: [],
  selectedItems: null,
  totalItems: 0,
  isLoading: false,
  hasMoreItems: false,
};

const offerTypes = (state = defaultState, { type, payload }: IOfferTypesAction) => {
  switch (type) {
    case LOAD_ALL_OFFER_TYPES:
      return { ...state, offerTypes: payload };

    case SET_HAS_MORE_ITEMS:
      return { ...state, hasMoreItems: payload };

    case SAVE_TOTAL_OFFER_TYPES:
      return { ...state, totalItems: payload };
  }
  return state;
};

export default offerTypes;
