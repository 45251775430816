import { TableCell as MuiTableCell, TableCellProps, styled } from '@mui/material';

const TableCellStyled = styled(MuiTableCell)`
  color: #828282;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  background-color: transparent;
`;

export const TableHeading = ({ children, ...rest }: TableCellProps) => {
  return (
    <TableCellStyled variant="head" {...rest}>
      {children}
    </TableCellStyled>
  );
};

export default TableHeading;
