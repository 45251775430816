import { Box, Grid, Typography } from '@mui/material';
import { Props } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';

import { IOption } from 'components/DynamicFilter/FilterOptions';
import { IconItem } from 'components/Icons';

const style = {
  menu: (provided: any) => ({ ...provided, zIndex: 5 }),
  control: (base: any) => ({
    ...base,
    boxShadow: 'none',
    border: 'none',
    borderColor: '#efefef',
    borderRadius: '10px',
    ':hover': {
      borderColor: 'transparent',
      cursor: 'text',
    },
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#efefef',
      borderRadius: '11px',
      padding: '0 5px',
      color: '#000',
    };
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    padding: 0,
    paddingLeft: '2px',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    paddingRight: '2px',
    paddingLeft: '0px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
    },
    ':hover svg': {
      color: 'red',
    },
  }),
};

interface ISelectorProps extends Props {
  mode: 'createable' | 'searchable' | 'alternative';
  options: IOption[];
  handleSearch?: Function;
  required?: boolean;
  error?: boolean;
  onCreateOption?: any;
  defaultValue?: IOption[];
  hasBorder?: boolean;
}

const NewSelect = (props: ISelectorProps) => {
  const filterOptions = (inputValue: string) => {
    return props?.options?.filter((i: any) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      if (props?.handleSearch) props.handleSearch(inputValue);
      resolve(filterOptions(inputValue));
    });

  const MultiValue = ({ children, ...props }: any) => {
    const typeChildren: any = children;
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{ border: '1.21px solid #efefef', borderRadius: '12px' }}
        pl={1}
        pr={1}>
        <Typography pr={1}>{typeChildren.props.children[0]}</Typography>
        <IconItem
          isHover
          width={15}
          height={15}
          name="RemoveCircle"
          onClick={() => props.removeProps.onClick(props.data)}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: 'grey.300',
        width: '100%',
        borderRadius: '12px',
        border: (theme) => (props.hasBorder ? theme.palette.secondary.light : 'none'),
      }}>
      {props.mode === 'createable' && (
        <CreatableSelect
          styles={style}
          name={props.name}
          className="basic-multi-select"
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
          }}
          required={props?.required || false}
          {...props}
        />
      )}
      {props.mode === 'searchable' && (
        <AsyncSelect
          styles={style}
          loadOptions={promiseOptions}
          defaultOptions={props.options}
          defaultValue={props.defaultValue}
          cacheOptions
          name={props.name}
          className="basic-multi-select"
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
          }}
          required={props?.required || false}
          {...props}
        />
      )}
      {props.mode === 'alternative' && (
        <AsyncSelect
          styles={style}
          loadOptions={promiseOptions}
          cacheOptions
          defaultOptions={props.options}
          name={props.name}
          className="basic-multi-select"
          classNamePrefix="select"
          components={{
            MultiValue,
            IndicatorSeparator: () => null,
          }}
          formatOptionLabel={(option) => {
            const { label = '' } = option as IOption;
            return (
              <Grid container justifyContent={'space-between'}>
                {label} <IconItem name="AddCircle" defaultColor="#fff" />
              </Grid>
            );
          }}
          required={props?.required || false}
          {...props}
        />
      )}
    </Box>
  );
};

export default NewSelect;
