import SearchIcon from '@mui/icons-material/Search';

import { Box, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { FC, ReactElement } from 'react';

export const MuiTextFieldStyled = styled(MuiTextField, {
  shouldForwardProp: (props) => props !== 'bgColor',
})<TextFieldProps>(({ size }) => ({
  borderRadius: '8px',
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
    borderRadius: '8px',
  },
  '.MuiInputBase-root > input': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    padding: '8px 4px',
  },
  ...(size === 'medium' && {
    '.MuiOutlinedInput-input': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  }),
  ...(size === 'small' && {
    '.MuiOutlinedInput-input': {
      // padding: '10px 16px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  }),
}));

export const SearchField: FC<TextFieldProps> = ({ fullWidth, sx, ...rest }): ReactElement => {
  const theme = useTheme();
  return (
    <Box style={{ display: 'flex', width: fullWidth ? '100%' : 'auto' }}>
      <MuiTextFieldStyled fullWidth sx={{ backgroundColor: (theme) => theme.palette.grey[300], ...sx }} {...rest} />
      <Box
        pl={1}
        pr={1}
        m={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          background: '#046B99',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        }}>
        <SearchIcon sx={{ color: theme.palette.grey[300] }} />
      </Box>
    </Box>
  );
};

export default SearchField;
