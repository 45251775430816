import { FC } from 'react';

type RefreshIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const RefreshIcon: FC<RefreshIconPropTypes> = ({ width = 40, height = 40, color = '#fff', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6359_4989)">
        <rect x="6" y="4" width="30" height="30" rx="15" fill="white" />
        <rect x="6" y="4" width="30" height="30" rx="15" stroke="#F5F6F7" strokeWidth="2" />
      </g>
      <path
        d="M16.333 19.0001H14.333L16.9997 21.6667L19.6663 19.0001H17.6663C17.6663 16.7934 19.4597 15.0001 21.6663 15.0001C23.6463 15.0001 25.2863 16.4467 25.6063 18.3334H26.953C26.6263 15.7001 24.3863 13.6667 21.6663 13.6667C18.7197 13.6667 16.333 16.0534 16.333 19.0001Z"
        fill="#0975A5"
      />
      <path
        d="M21.6664 23C20.5664 23 19.5664 22.5534 18.8397 21.8267L17.8931 22.7734C18.8597 23.7334 20.1931 24.3334 21.6664 24.3334C24.3864 24.3334 26.6264 22.3 26.9531 19.6667H25.6064C25.2864 21.5534 23.6464 23 21.6664 23Z"
        fill="#0975A5"
      />
      <defs>
        <filter
          id="filter0_d_6359_4989"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6359_4989" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6359_4989" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default RefreshIcon;
