import { FC } from 'react';

type MenuIconCopyIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconCopyIcon: FC<MenuIconCopyIconPropTypes> = ({ width = 35, height = 35, color = "#C3CAD9", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2539_15873)">
    <rect x="5" y="3.53326" width="24.3956" height="24.3956" rx="12.1978" fill="white"/>
    <rect x="5" y="3.53326" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637"/>
    </g>
    <path d="M19.6381 9.02228H12.3194C11.6485 9.02228 11.0996 9.57118 11.0996 10.2421V18.7805H12.3194V10.2421H19.6381V9.02228ZM19.0282 11.4618L22.6875 15.1212V21.2201C22.6875 21.891 22.1386 22.4399 21.4677 22.4399H14.7529C14.082 22.4399 13.5392 21.891 13.5392 21.2201L13.5453 12.6816C13.5453 12.0107 14.0881 11.4618 14.7589 11.4618H19.0282ZM18.4183 15.7311H21.7727L18.4183 12.3767V15.7311Z" fill={color}/>
    <defs>
    <filter id="filter0_d_2539_15873" x="0.121566" y="0.280532" width="34.1534" height="34.1538" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.62637"/>
    <feGaussianBlur stdDeviation="2.03297"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2539_15873"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2539_15873" result="shape"/>
    </filter>
    </defs>
    </svg>
  );
};

export default MenuIconCopyIcon;
