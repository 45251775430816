import { SET_IS_LOADING, SET_IS_DIALOG_LOADING } from './commonActions';

export const isLoading = (state = false, { type, payload }: any) => {
  if (type === SET_IS_LOADING) {
    return payload;
  }
  return state;
};

export const isDialogLoading = (state = false, { type, payload }: any) => {
  if (type === SET_IS_DIALOG_LOADING) {
    return payload;
  }
  return state;
};
