import { FC } from 'react';

type CollapseArrowDownIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const CollapseArrowDownIcon: FC<CollapseArrowDownIconPropTypes> = ({
  width = 22,
  height = 22,
  color = '#5A6E81',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.45714 5.71094L1.83464 7.33344L11.0013 16.5001L20.168 7.33344L18.5455 5.71094L11.0013 13.2551L3.45714 5.71094Z"
        fill={color}
      />
    </svg>
  );
};

export default CollapseArrowDownIcon;
