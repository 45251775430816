import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';

import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';
import { createContext, useMemo, ReactNode, useContext } from 'react';

const ColorModeContext = createContext({ toggleColorMode: () => {} });

interface ModeTogglerProps {
  children: ReactNode;
}

export const useModeToggler = () => useContext(ColorModeContext);

const ModeToggle = ({ children }: ModeTogglerProps) => {
  const { setMode: setMaterialMode } = useMaterialColorScheme();
  const { mode, setMode: setJoyMode } = useJoyColorScheme();

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMaterialMode(mode === 'dark' ? 'light' : 'dark');
        setJoyMode(mode === 'dark' ? 'light' : 'dark');
      },
    }),
    [mode, setJoyMode, setMaterialMode]
  );

  return <ColorModeContext.Provider value={colorMode}>{children}</ColorModeContext.Provider>;
};

export default ModeToggle;
