import {
  LOAD_ALL_CUSTOMERS,
  IS_CUSTOMERS_LOADING,
  IS_CUSTOMERS_DIALOG_LOADING,
  EDIT_CUSTOMER,
  LOAD_DELETED_CUSTOMERS,
  RESTORE_CUSTOMER,
  DELETE_CUSTOMER,
  HARD_DELETE_CUSTOMER,
  SAVE_SEARCH_RESULT,
  GET_CUSTOMER_BY_ID,
  CREATE_CUSTOMER,
} from './CustomersActions';
import { ICustomerState, ICustomerAction, ICustomer } from '../CustomersInterfaces';

const defaultState: ICustomerState = {
  items: [],
  selectedItem: null,
  totalItems: 0,
  isLoading: false,
  isDialogLoading: false,
  deletedItems: [],
  totalDeletedItems: 0,
  hits: [],
  totalHits: 0,
  createdItem: null,
};

const customers = (state = defaultState, { type, payload }: ICustomerAction) => {
  switch (type) {
    case IS_CUSTOMERS_LOADING:
    case IS_CUSTOMERS_DIALOG_LOADING:
    case LOAD_ALL_CUSTOMERS:
    case LOAD_DELETED_CUSTOMERS:
    case SAVE_SEARCH_RESULT:
    case GET_CUSTOMER_BY_ID:
      return { ...state, ...payload };

    case EDIT_CUSTOMER:
      return { ...state, selectedItem: { ...payload } };

    case CREATE_CUSTOMER:
      return { ...state, createdItem: payload };

    case DELETE_CUSTOMER:
    case RESTORE_CUSTOMER:
    case HARD_DELETE_CUSTOMER:
      const deletedItem = payload as ICustomer;
      const key = type === DELETE_CUSTOMER ? 'items' : 'deletedItems';
      const deletedItems = state[key]?.filter(({ id }: ICustomer) => id !== deletedItem.id);
      return { ...state, [key]: deletedItems };
  }
  return state;
};

export default customers;
