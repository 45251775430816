import i18n from 'i18next';
import API from 'utils/API';
import { AxiosResponse } from 'axios';
import { ICustomer, INewCustomer, ISearch, IParams, IEditCustomer } from '../CustomersInterfaces';
import { addErrorMessage, addSuccessMessage } from 'modules/AppAlerts/AppAlertsActions';
import { useQueryParams } from 'routeUtils';
import { IS_BD_O_HYWER_TENANT } from 'utils/authentication/RoleWrapper';
import { SYSTEM_ADMIN_ID } from 'utils/uuidFunctions';

export const LOAD_ALL_CUSTOMERS = 'LOAD_ALL_CUSTOMERS';
export const IS_CUSTOMERS_LOADING = 'IS_CUSTOMERS_LOADING';
export const IS_CUSTOMERS_DIALOG_LOADING = 'IS_CUSTOMERS_DIALOG_LOADING';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const LOAD_DELETED_CUSTOMERS = 'LOAD_DELETED_CUSTOMERS';
export const HARD_DELETE_CUSTOMER = 'HARD_DELETE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const RESTORE_CUSTOMER = 'RESTORE_CUSTOMER';
export const SAVE_SEARCH_RESULT = 'SAVE_SEARCH_RESULT';
export const GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';

const setIsLoading = (value: boolean) => ({ type: IS_CUSTOMERS_LOADING, payload: { isLoading: value } });

const setIsDialogLoading = (value: boolean) => ({
  type: IS_CUSTOMERS_DIALOG_LOADING,
  payload: { isDialogLoading: value },
});

const loadAllCustomersSuccess = (payload: Array<ICustomer>, totalItems: number) => ({
  type: LOAD_ALL_CUSTOMERS,
  payload: { items: [...payload], totalItems },
});

export const loadDeletedCustomersSuccess = (payload: Array<ICustomer>, totalDeletedItems: number) => ({
  payload: { deletedItems: [...payload], totalDeletedItems },
  type: LOAD_DELETED_CUSTOMERS,
});

const deleteCustomerSuccess = (payload: ICustomer, hardDelete: boolean) => ({
  payload,
  type: hardDelete ? HARD_DELETE_CUSTOMER : DELETE_CUSTOMER,
});

export const restoreCustomerSuccess = (payload: ICustomer) => ({
  payload,
  type: RESTORE_CUSTOMER,
});

export const searchSuccess = (payload: ISearch) => ({ type: SAVE_SEARCH_RESULT, payload });

export const getCustomerByIdSuccess = (item: ICustomer | null) => ({
  type: GET_CUSTOMER_BY_ID,
  payload: { selectedItem: item },
});

export const addCustomerSuccess = (payload: ICustomer | null) => ({ type: CREATE_CUSTOMER, payload });

export const loadAllCustomers = (params: any) => {
  const { pageNumber = 0, pageSize = 10 } = params ?? {};
  const countUrl = `/crm/api/customers/count`;
  const url = `/crm/api/customers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const countPromise = API.get(countUrl);
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      dispatch(loadAllCustomersSuccess(payload, totalItems));
    } catch (error) {
      const message = i18n.t('customers.errorLoadingCustomers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadDeletedCustomers = (params?: any) => {
  const { pageNumber = 0, pageSize = 10 } = params;
  const countURL = `/crm/api/customers/deleted/count`;
  const URL = `/crm/api/customers/deleted?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const countPromise = API.get(countURL);
      const dataPromise = API.get(URL);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalDeletedItems },
      } = await countPromise;
      dispatch(loadDeletedCustomersSuccess(payload, totalDeletedItems));
    } catch (error) {
      const message = i18n.t('customers.errorLoadingDeletedCustomers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const deleteCustomer = (id: string, hardDelete = false) => {
  return async (dispatch: Function) => {
    try {
      const URL = `/crm/api/customers/${id}?hardDelete=${hardDelete}`;
      hardDelete ? dispatch(setIsDialogLoading(true)) : dispatch(setIsLoading(true));
      const {
        data: { payload },
      } = await API.delete(URL);
      dispatch(deleteCustomerSuccess(payload, hardDelete));
      const message = i18n.t('customers.successDeletingCustomer');
      dispatch(addSuccessMessage({ message }));
      dispatch(loadDeletedCustomers({}));
    } catch (error) {
      const message = i18n.t('customers.errorDeletingCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      hardDelete ? dispatch(setIsDialogLoading(false)) : dispatch(setIsLoading(false));
    }
  };
};

export const restoreCustomer = (id: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/crm/api/customers/deleted/${id}`;
      const {
        data: { payload },
      } = await API.put(URL);
      dispatch(restoreCustomerSuccess(payload));
      const message = i18n.t('products.successRestoringDeletedCustomer');
      dispatch(addSuccessMessage({ message }));
      const { pageNumber, pageSize } = useQueryParams();
      dispatch(loadAllCustomers({ pageNumber, pageSize }));
    } catch (error) {
      const message = i18n.t('products.errorRestoringDeletedCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

// Commented this code, because it uses OLD search APIs. Left as comment, because we need to dicuss
// if we want to copy the search PERSON and COMPANY APIs here, or do we want to use the already existing ones in their modules
// export const searchForPerson = (searchString: string) => {
//   return async (dispatch: Function) => {
//     try {
//       dispatch(setIsDialogLoading(true));
//       const URL = `/search/api/persons?fieldsToSearch=lastName&fieldsToSearch=firstName&searchString=${searchString}`;
//       const payload = await search(URL);
//       dispatch(searchSuccess(payload));
//     } catch (e) {
//       const message = i18n.t('customers.errorSearchingForPerson');
//       dispatch(addErrorMessage({ message }));
//     } finally {
//       dispatch(setIsDialogLoading(false));
//     }
//   };
// };

export const searchForPersons = (query: string, params: IParams = { pageNumber: 0, pageSize: 15 }) => {
  const searchForPersonssUrl = `search/api/persons/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: ['firstname', 'lastname', 'postalcode', 'postaladdress', 'address'],
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForCompaniesPromise = API.post(searchForPersonssUrl, { ...searchFields });
      const { data } = await searchForCompaniesPromise;
      dispatch(searchSuccess(data));
    } catch (error) {
      const message = i18n.t('persons.errorSearchingForPerson');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

// export const searchForCompany = (searchString: string) => {
//   return async (dispatch: Function) => {
//     try {
//       dispatch(setIsDialogLoading(true));
//       const URL = `/search/api/companies?fieldsToSearch=name&searchString=${searchString}`;
//       const payload = await search(URL);
//       dispatch(searchSuccess(payload));
//     } catch (e) {
//       const message = i18n.t('customers.errorSearchingForCompany');
//       dispatch(addErrorMessage({ message }));
//     } finally {
//       dispatch(setIsDialogLoading(false));
//     }
//   };
// };

export const searchForCompanies = (query: string, params: IParams = { pageNumber: 0, pageSize: 15 }) => {
  const searchForCompaniesUrl = `search/api/companies/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields: ['name', 'address'],
  };
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForCompaniesPromise = API.post(searchForCompaniesUrl, { ...searchFields });
      const { data } = await searchForCompaniesPromise;
      dispatch(searchSuccess(data));
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCompany');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const createCustomer = (data: INewCustomer, callback = () => {}) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/crm/api/customers`;

      const {
        data: { payload },
      } = await API.post(URL, data);
      const message = i18n.t('customers.successCreatingNewCustomer');
      dispatch(addSuccessMessage({ message }));
      const { pageSize, pageNumber } = useQueryParams();
      dispatch(addCustomerSuccess(payload as ICustomer));
      dispatch(loadAllCustomers({ pageSize, pageNumber }));
      callback();
    } catch (e) {
      const message = i18n.t('customers.errorCreatingNewCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const editCustomer = (customerId: string, editCustomer: IEditCustomer) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsDialogLoading(true));
      const URL = `/crm/api/customers/${customerId}`;
      await API.put(URL, editCustomer);
      const message = i18n.t('customers.successEditingCustomer');
      dispatch(addSuccessMessage({ message }));
    } catch (e) {
      const message = i18n.t('customers.errorEditingCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsDialogLoading(false));
    }
  };
};

export const getCustomerById = (id: string) => {
  return async (dispatch: Function) => {
    try {
      if (id === SYSTEM_ADMIN_ID) return;
      dispatch(setIsLoading(true));
      dispatch(getCustomerByIdSuccess(null));
      const URL = `/crm/api/customers/${id}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(getCustomerByIdSuccess(payload));
    } catch (e) {
      const message = i18n.t('customers.errorLoadingCustomers');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

// export const searchForCustomers = (searchString: string | IParams) => {
//   return async (dispatch: Function) => {
//     try {
//       dispatch(setIsLoading(true));
//       const URL = `/search/api/customers?fieldsToSearch=name&searchString=${searchString}`;
//       const payload = await search(URL);
//       dispatch(searchSuccess(payload));
//     } catch (e) {
//       const message = i18n.t('customers.errorSearchingForCustomer');
//       dispatch(addErrorMessage({ message }));
//     } finally {
//       dispatch(setIsLoading(false));
//     }
//   };
// };

const containsOnlyNumbers = (str: string) => /^[0-9]+$/.test(str);

const searchRules = (query: string | null) => {
  if (!query) return { fields: null, orderBy: null };
  const fields = containsOnlyNumbers(query)
    ? IS_BD_O_HYWER_TENANT()
      ? ['externalreference']
      : ['customerNumber']
    : [IS_BD_O_HYWER_TENANT() ? 'externalreference' : 'customerNumber', 'name'];
  const orderBy = containsOnlyNumbers(query) ? (IS_BD_O_HYWER_TENANT() ? 'externalreference' : 'customerNumber') : null;

  return {
    fields,
    orderBy,
  };
};

export const searchForCustomers = (
  query: string | null,
  saveInSearched: boolean = false,
  params: IParams = { pageNumber: 0, pageSize: 100 },
  extraFilter?: any
) => {
  const { fields, orderBy } = searchRules(query);

  const searchForCompaniesUrl = `search/api/customers/autocomplete`;
  const searchFields = {
    query,
    ...params,
    fields,
    orderBy,
    ...extraFilter,
  };

  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const searchForCompaniesPromise = API.post(searchForCompaniesUrl, { ...searchFields });
      const {
        data: { totalHits, hits },
      }: AxiosResponse<ISearch> = await searchForCompaniesPromise;
      if (saveInSearched) dispatch(searchSuccess({ totalHits, hits }));
      if (!saveInSearched) dispatch(loadAllCustomersSuccess(hits as ICustomer[], totalHits));
    } catch (error) {
      const message = i18n.t('customers.errorSearchingForCustomer');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
