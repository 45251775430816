import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import thunk from 'redux-thunk';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware({
          serializableCheck: false,
          immutableCheck: false,
        }).concat(thunk)
      : getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(thunk);
  },
  devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
