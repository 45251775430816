import { FC } from 'react';

type CloseInCirclenPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const CloseInCircle: FC<CloseInCirclenPropTypes> = ({ width = 22, height = 22, color = '#EFEFEF', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill={color} fillOpacity="0.76" />
      <path
        d="M8.7943 8.20529C8.63158 8.04257 8.36777 8.04257 8.20505 8.20529C8.04233 8.36801 8.04233 8.63183 8.20505 8.79455L10.4104 10.9999L8.20506 13.2053C8.04234 13.368 8.04234 13.6318 8.20506 13.7945C8.36778 13.9573 8.6316 13.9573 8.79431 13.7945L10.9997 11.5892L13.205 13.7946C13.3678 13.9573 13.6316 13.9573 13.7943 13.7946C13.957 13.6318 13.957 13.368 13.7943 13.2053L11.5889 10.9999L13.7943 8.79455C13.957 8.63183 13.957 8.36801 13.7943 8.20529C13.6316 8.04257 13.3678 8.04257 13.2051 8.20529L10.9997 10.4107L8.7943 8.20529Z"
        fill="#33383F"
      />
    </svg>
  );
};

export default CloseInCircle;
