import { extendTheme, CssVarsProvider } from '@mui/joy';

import { ReactNode } from 'react';

import { smartcraftPalette } from './palette';

declare module '@mui/joy/styles' {
  interface Shadow {
    container: string;
  }
}

const smartcraftTheme = extendTheme({
  colorSchemes: {
    light: smartcraftPalette,
    dark: smartcraftPalette,
  },
  radius: {
    sm: '4px',
  },
  fontWeight: {
    xs: '300',
    sm: '400',
  },
  shadow: {
    xs: '0px 0px 2px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 2px 4px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08));',
    sm: '0px 0px 2px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 2px 4px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 4px 8px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08));',
    md: '0px 0px 2px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 2px 4px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 4px 8px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 8px 16px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08));',
    lg: '0px 0px 2px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 2px 4px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 4px 8px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 8px 16px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 16px 32px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08));',
    xl: '0px 0px 2px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 2px 4px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 4px 8px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 8px 16px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 16px 32px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08)), 0px 32px 64px 0px var(--scds-shadow, rgba(18, 9, 49, 0.08));',
    container: '0px 8px 32px -8px var(--scds-shadow, rgba(18, 9, 49, 0.08));',
  },
});

type AppThemeProps = {
  children: ReactNode;
};

export const SmartcraftThemeProvider = ({ children }: AppThemeProps) => {
  return <CssVarsProvider theme={smartcraftTheme}>{children}</CssVarsProvider>;
};

export default SmartcraftThemeProvider;
