import i18n from 'i18next';
import * as yup from 'yup';

export const adjustableItemObject = {
  propertyName: '',
  method: '',
  value: '',
};

export interface IAdjustableItem {
  propertyName: string;
  method: string;
  value: string | boolean;
}

export type adjustableItemObjectType = typeof adjustableItemObject;

const map = {
  'Info.ExcludeFromSum': 'offers.excludeFromSum',
  'Info.CustomerPriceIsLocked': 'offers.customerPriceIsLocked',
  'Material.Cost.VendorDiscountPercent': 'offers.vendorDiscountPercent',
  'Material.Cost.VendorDiscount.persistedStandardPercent': 'offers.persistedStandardPercent',
  'Material.Cost.VendorDiscount.ExtraPercent1': 'offers.extraPercent1',
  'Material.Cost.VendorDiscount.ExtraPercent2': 'offers.extraPercent2',
  'Material.Cost.NetCostPrice': 'offers.netCostPriceAmount',
  'Material.Income.MarkupPercent': 'offers.markupPercent',
  'Material.Income.DiscountPercent': 'offers.discountPercent',
  'Work.Cost.TravelCostAmount': 'offers.travelCostAmount',
  'Work.Income.MarkupPercent': 'offers.markupPercent',
  'Work.Income.DiscountPercent': 'offers.discountPercent',
};

export const schema = {
  'Info.ExcludeFromSum': yup.boolean(),
  'Info.CustomerPriceIsLocked': yup.boolean(),
  'Material.Cost.VendorDiscountPercent': yup.number(),
  'Material.Cost.VendorDiscount.persistedStandardPercent': yup.number(),
  'Material.Cost.VendorDiscount.ExtraPercent1': yup.number(),
  'Material.Cost.VendorDiscount.ExtraPercent2': yup.number(),
  'Material.Cost.NetCostPrice': yup.number(),
  'Material.Income.MarkupPercent': yup.number(),
  'Material.Income.DiscountPercent': yup.number(),
  'Work.Cost.TravelCostAmount': yup.number(),
  'Work.Income.MarkupPercent': yup.number(),
  'Work.Income.DiscountPercent': yup.number(),
};

export const getAdjustableItemLabel = (label: string) => i18n.t(map[label]);

export const areAdjustableItemsValid = (items: Array<IAdjustableItem>) => {
  if (items.length === 0) return false;
  try {
    const validItems = items.filter((item) => {
      const itemSchema = schema[item.propertyName];
      if (item.method === '') return false;
      return itemSchema.isValidSync(item.value);
    });
    return validItems.length === items.length;
  } catch (e) {
    return false;
  }
};
