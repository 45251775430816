import { FC, createContext, useState } from 'react';

export interface IAppContext {
  isAppDrawerOpen: boolean;
  setIsAppDrawerOpen: Function;
  drawerWidth: number;
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

const AppProvider: FC<any> = (props) => {
  const { children } = props;
  const drawerWidth = 250;
  const [isAppDrawerOpen, setIsAppDrawerOpen] = useState(false);
  return (
    <AppContext.Provider value={{ isAppDrawerOpen, setIsAppDrawerOpen, drawerWidth }}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
