import { FC } from 'react';

type EmptyStateAttachmentsIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const EmptyStateAttachmentsIcon: FC<EmptyStateAttachmentsIconPropTypes> = ({
  width = 123,
  height = 95,
  color = '#046B99',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 123 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.6186 92.1029H92.8768C94.588 92.107 96.2375 91.4695 97.4947 90.3181C102.739 85.5017 106.935 79.672 109.824 73.1875C112.713 66.703 114.234 59.7009 114.293 52.6117C114.561 23.7863 90.9377 0.0681764 61.8752 0.000148366C32.7962 -0.0679182 9.20169 23.2931 9.20169 52.1202C9.19339 59.295 10.6828 66.3938 13.5762 72.9697C16.4696 79.5455 20.7043 85.4559 26.0137 90.3288C27.2683 91.4748 28.913 92.1085 30.6186 92.1029Z"
        fill="#EFF1F8"
      />
      <path
        d="M86.2885 92.8614L52.443 69.4958C52.0318 69.2119 51.6813 68.8509 51.4116 68.4335C51.1419 68.0161 50.9582 67.5504 50.8711 67.063C50.7839 66.5756 50.7949 66.076 50.9036 65.5928C51.0122 65.1096 51.2164 64.6523 51.5043 64.2469L89.3298 10.986C89.6177 10.5806 89.9838 10.2351 90.4072 9.96914C90.8305 9.70321 91.3029 9.52211 91.7973 9.43616C92.2916 9.35022 92.7983 9.36112 93.2884 9.46824C93.7785 9.57536 94.2424 9.77661 94.6535 10.0605L117.877 26.0931L122 49.1478L91.6123 91.9359C91.3243 92.3413 90.9582 92.6869 90.5349 92.9528C90.1115 93.2187 89.6391 93.3998 89.1448 93.4858C88.6504 93.5717 88.1437 93.5608 87.6537 93.4537C87.1636 93.3465 86.6997 93.1453 86.2885 92.8614Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.43911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.0086 78.2911L29.1826 92.2239C28.7109 92.3932 28.21 92.4692 27.7085 92.4476C27.207 92.426 26.7147 92.3073 26.2598 92.0981C25.8048 91.8889 25.3961 91.5935 25.057 91.2286C24.7178 90.8637 24.4549 90.4365 24.2832 89.9714L1.72809 28.8731C1.5564 28.408 1.4793 27.9141 1.5012 27.4197C1.52309 26.9252 1.64355 26.4399 1.8557 25.9913C2.06785 25.5428 2.36753 25.1398 2.73763 24.8054C3.10773 24.4711 3.541 24.2119 4.01271 24.0426L30.6536 14.4824L52.1731 24.376L70.2932 73.4606C70.4649 73.9257 70.542 74.4195 70.5201 74.914C70.4982 75.4085 70.3777 75.8938 70.1656 76.3424C69.9534 76.7909 69.6537 77.1939 69.2836 77.5283C68.9136 77.8626 68.4803 78.1218 68.0086 78.2911Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.43911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.1723 24.376L39.9872 28.7487C39.5155 28.918 39.0146 28.994 38.5131 28.9724C38.0116 28.9508 37.5193 28.832 37.0644 28.6229C36.6094 28.4137 36.2007 28.1182 35.8616 27.7533C35.5225 27.3885 35.2595 26.9613 35.0878 26.4962L30.6528 14.4824L52.1723 24.376Z"
        fill="#0975A5"
      />
      <path
        d="M27.4857 29.0453L12.4533 34.4397C11.6916 34.713 11.2989 35.5433 11.5762 36.2942L11.5762 36.2942C11.8534 37.0452 12.6955 37.4323 13.4571 37.159L28.4895 31.7646C29.2512 31.4913 29.6439 30.661 29.3667 29.9101C29.0894 29.1591 28.2473 28.772 27.4857 29.0453Z"
        fill="#D4D9E3"
      />
      <path
        d="M46.57 33.7371L16.215 44.6301C15.4534 44.9034 15.0607 45.7337 15.3379 46.4847L15.3379 46.4847C15.6151 47.2356 16.4572 47.6228 17.2189 47.3495L47.5739 36.4565C48.3355 36.1831 48.7282 35.3528 48.451 34.6019C48.1738 33.851 47.3316 33.4638 46.57 33.7371Z"
        fill="#D4D9E3"
      />
      <path
        d="M50.3317 43.9285L19.9767 54.8215C19.2151 55.0948 18.8224 55.9251 19.0996 56.6761L19.0996 56.6761C19.3768 57.427 20.2189 57.8142 20.9806 57.5409L51.3356 46.6479C52.0972 46.3746 52.4899 45.5442 52.2127 44.7933C51.9355 44.0424 51.0933 43.6552 50.3317 43.9285Z"
        fill="#D4D9E3"
      />
      <path
        d="M54.0939 54.118L23.7389 65.011C22.9773 65.2843 22.5846 66.1146 22.8618 66.8655L22.8618 66.8655C23.139 67.6164 23.9812 68.0036 24.7428 67.7303L55.0978 56.8373C55.8594 56.564 56.2521 55.7337 55.9749 54.9828C55.6977 54.2319 54.8556 53.8447 54.0939 54.118Z"
        fill="#D4D9E3"
      />
      <path
        d="M50.078 67.0994L27.5006 75.2014C26.739 75.4747 26.3463 76.305 26.6235 77.0559C26.9007 77.8069 27.7429 78.194 28.5045 77.9207L51.0819 69.8188C51.8435 69.5455 52.2362 68.7152 51.959 67.9642C51.6818 67.2133 50.8396 66.8261 50.078 67.0994Z"
        fill="#D4D9E3"
      />
      <path
        d="M121.999 49.1464L111.377 41.8134C110.965 41.5295 110.615 41.1685 110.345 40.7511C110.076 40.3337 109.892 39.868 109.805 39.3806C109.717 38.8932 109.729 38.3936 109.837 37.9104C109.946 37.4272 110.15 36.9699 110.438 36.5645L117.876 26.0918L121.999 49.1464Z"
        fill="#0975A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.855 48.2126C106.99 48.0227 107.077 47.8039 107.11 47.5742C107.142 47.3445 107.118 47.1106 107.041 46.8917C106.963 46.6728 106.834 46.4752 106.664 46.3152C106.494 46.1553 106.287 46.0375 106.062 45.9716L89.9813 41.2711C89.5678 41.1503 89.1262 41.1606 88.719 41.3008C88.3118 41.4409 87.9596 41.7037 87.7123 42.0521L78.5228 54.9914C77.6484 54.3934 76.5033 53.9815 75.2263 53.8713C72.268 53.6161 69.7217 55.077 69.5392 57.1343C69.3566 59.1916 71.6069 61.0662 74.5652 61.3214C77.4313 61.5687 79.9106 60.2052 80.2285 58.2492L85.5967 50.6904L98.4185 54.4369L92.9356 62.1571C92.095 61.6409 91.0384 61.2883 89.8717 61.1877C86.9133 60.9325 84.3671 62.3934 84.1845 64.4507C84.002 66.508 86.2522 68.3827 89.2106 68.6378C92.169 68.893 94.7152 67.4321 94.8978 65.3748C94.9077 65.2632 94.9104 65.1521 94.9063 65.0417L106.855 48.2126ZM87.588 47.8866L89.8989 44.6326L102.721 48.3791L100.41 51.633L87.588 47.8866Z"
        fill="#D4D9E3"
      />
      <path
        d="M82.4068 78.4213H41.0891C40.0753 78.4213 39.1031 78.0243 38.3862 77.3175C37.6693 76.6107 37.2666 75.6521 37.2666 74.6525V9.63304C37.2666 8.6335 37.6693 7.67489 38.3862 6.96811C39.1031 6.26132 40.0753 5.86426 41.0891 5.86426H69.4397L86.2294 22.4178V74.6525C86.2294 75.1475 86.1305 75.6375 85.9384 76.0948C85.7463 76.552 85.4648 76.9675 85.1098 77.3175C84.7548 77.6674 84.3334 77.945 83.8697 78.1344C83.4059 78.3238 82.9088 78.4213 82.4068 78.4213Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.43911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.7476 94.5877C68.4653 94.5877 73.9112 89.2184 73.9112 82.5951C73.9112 75.9718 68.4653 70.6025 61.7476 70.6025C55.0298 70.6025 49.584 75.9718 49.584 82.5951C49.584 89.2184 55.0298 94.5877 61.7476 94.5877Z"
        fill="#0975A5"
      />
      <path
        d="M66.4611 81.4521H62.9087V77.9495C62.9082 77.6465 62.7858 77.3561 62.5683 77.142C62.3509 76.9279 62.0561 76.8076 61.7488 76.8076C61.4415 76.8076 61.1467 76.9279 60.9292 77.142C60.7118 77.3561 60.5894 77.6465 60.5889 77.9495V81.4521H57.0861C56.7918 81.4472 56.506 81.5499 56.2837 81.7402C56.0615 81.9306 55.9184 82.1952 55.8821 82.4832C55.8662 82.6421 55.8842 82.8025 55.935 82.9541C55.9858 83.1057 56.0682 83.2451 56.1769 83.3634C56.2857 83.4817 56.4183 83.5762 56.5663 83.641C56.7144 83.7057 56.8745 83.7391 57.0364 83.7391H60.5889V87.1926C60.584 87.4828 60.6881 87.7646 60.8812 87.9838C61.0743 88.2029 61.3427 88.344 61.6349 88.3798C61.796 88.3954 61.9587 88.3777 62.1124 88.3276C62.2662 88.2775 62.4076 88.1963 62.5276 88.0891C62.6476 87.9819 62.7435 87.8511 62.8091 87.7051C62.8747 87.5592 62.9086 87.4013 62.9086 87.2417V83.7391H66.4611C66.7687 83.7391 67.0637 83.6186 67.2812 83.4042C67.4987 83.1897 67.6209 82.8989 67.6209 82.5956C67.6209 82.2923 67.4987 82.0015 67.2812 81.787C67.0637 81.5725 66.7687 81.4521 66.4611 81.4521V81.4521Z"
        fill="white"
      />
      <path
        d="M86.2296 22.4178H73.2625C72.2487 22.4178 71.2764 22.0208 70.5595 21.314C69.8427 20.6072 69.4399 19.6486 69.4399 18.649V5.86426L86.2296 22.4178Z"
        fill="#0975A5"
      />
      <path
        d="M74.3247 32.4707H49.1713C48.6067 32.4707 48.1489 32.922 48.1489 33.4787V58.2784C48.1489 58.8351 48.6067 59.2864 49.1713 59.2864H74.3247C74.8893 59.2864 75.3471 58.8351 75.3471 58.2784V33.4787C75.3471 32.922 74.8893 32.4707 74.3247 32.4707Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.43911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.3248 59.2866C74.5959 59.2866 74.856 59.1804 75.0477 58.9913C75.2395 58.8023 75.3472 58.5459 75.3472 58.2785V50.7519L69.6731 45.1576C69.3228 44.8122 68.8476 44.6182 68.3522 44.6182C67.8567 44.6182 67.3815 44.8122 67.0312 45.1576L59.7592 52.3274L57.1809 50.7302L56.0283 59.2866H74.3248Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.43911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1713 59.2865H74.3246C74.5066 59.2856 74.6849 59.2364 74.8408 59.1441L58.0629 42.602C57.7125 42.2566 57.2374 42.0625 56.7419 42.0625C56.2465 42.0625 55.7713 42.2566 55.421 42.602L48.1489 49.7718V58.2785C48.1489 58.5458 48.2566 58.8022 48.4484 58.9913C48.6401 59.1803 48.9002 59.2865 49.1713 59.2865Z"
        fill="white"
        stroke="#D4D9E3"
        strokeWidth="1.43911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9281 42.878C69.2035 42.878 71.0481 41.0594 71.0481 38.816C71.0481 36.5726 69.2035 34.7539 66.9281 34.7539C64.6527 34.7539 62.8081 36.5726 62.8081 38.816C62.8081 41.0594 64.6527 42.878 66.9281 42.878Z"
        fill="#D4D9E3"
      />
    </svg>
  );
};

export default EmptyStateAttachmentsIcon;
