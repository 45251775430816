import { FC } from 'react';

type AddInSquarePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const AddInSquare: FC<AddInSquarePropTypes> = ({ width = 26, height = 26, color = '#0975A5', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M4.33341 6.5H2.16675V21.6667C2.16675 22.8583 3.14175 23.8333 4.33341 23.8333H19.5001V21.6667H4.33341V6.5ZM21.6667 
        2.16667H8.66675C7.47508 2.16667 6.50008 3.14167 6.50008 4.33334V17.3333C6.50008 18.525 7.47508 19.5 8.66675 
        19.5H21.6667C22.8584 19.5 23.8334 18.525 23.8334 17.3333V4.33334C23.8334 3.14167 22.8584
         2.16667 21.6667 2.16667ZM20.5834 
         11.9167H16.2501V16.25H14.0834V11.9167H9.75008V9.75H14.0834V5.41667H16.2501V9.75H20.5834V11.9167Z"
        fill={color}
      />
    </svg>
  );
};

export default AddInSquare;
