import { Box } from '@mui/material';
import { ReactNode, FC } from 'react';

interface ITabPanel {
  children: ReactNode;
  selectedTab: number;
  index: number;
}

export const TabPanel: FC<ITabPanel> = (props: ITabPanel) => {
  const { children, selectedTab, index, ...rest } = props;
  if (selectedTab !== index) return null;

  return (
    <Box
      role="tab-panel"
      hidden={selectedTab !== index}
      id={`simple-tab-panel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}>
      {children}
    </Box>
  );
};

export default TabPanel;
