import { USER_ACTIONS } from './types';

import { IUserState } from '../userInterfaces';
import {
  SAVE_MY_INFORMATION,
  ADD_ERROR_MESSAGE,
  SET_MY_INFO_LOADER,
  UPDATE_MY_INFO,
  SAVE_USER_INFO,
  SAVE_ADMINISTRATOR_FOR_LIST,
  SAVE_ALL_USERS,
} from './userActions';

const defaultState: IUserState = {
  myInfo: null,
  error: null,
  isLoading: false,
  user: null,
  administratorForList: [],
  users: [],
};

const usersReducer = (state = defaultState, action: USER_ACTIONS) => {
  switch (action.type) {
    case SAVE_MY_INFORMATION:
      return { ...state, myInfo: action.myInfo };
    case ADD_ERROR_MESSAGE:
      return { ...state, error: action.error };
    case SET_MY_INFO_LOADER:
      return { ...state, isLoading: action.isLoading };
    case UPDATE_MY_INFO:
      return { ...state, myInfo: { ...action.myInfo } };
    case SAVE_USER_INFO:
      return { ...state, user: action.userInfo };
    case SAVE_ADMINISTRATOR_FOR_LIST:
      return { ...state, administratorForList: action.administratorForList };
    case SAVE_ALL_USERS:
      return { ...state, users: action.users };
    default:
      return state;
  }
};

export default usersReducer;
