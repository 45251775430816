import { FC } from 'react';

type MenuIconViewIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const MenuIconViewIcon: FC<MenuIconViewIconPropTypes> = ({ width = 35, height = 35, color = "#C3CAD9", ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2539_15885)">
    <rect x="5" y="3.51129" width="24.3956" height="24.3956" rx="12.1978" fill="white"/>
    <rect x="5" y="3.51129" width="24.3956" height="24.3956" rx="12.1978" stroke="#F5F6F7" strokeWidth="1.62637"/>
    </g>
    <path d="M20.9904 10.83H13.4007C12.7989 10.83 12.3164 11.3179 12.3164 11.9142V19.5039C12.3164 20.1003 12.7989 20.5882 13.4007 20.5882H20.9904C21.5867 20.5882 22.0746 20.1003 22.0746 19.5039V11.9142C22.0746 11.3179 21.5922 10.83 20.9904 10.83ZM20.9904 19.5039H13.4007V12.9985H20.9904V19.5039ZM18.0087 16.2512C18.0087 16.7012 17.6455 17.0644 17.1955 17.0644C16.7456 17.0644 16.3823 16.7012 16.3823 16.2512C16.3823 15.8012 16.7456 15.438 17.1955 15.438C17.6455 15.438 18.0087 15.8012 18.0087 16.2512ZM17.1955 14.0827C15.7155 14.0827 14.4524 14.9826 13.9428 16.2512C14.4524 17.5198 15.7155 18.4197 17.1955 18.4197C18.6755 18.4197 19.9387 17.5198 20.4483 16.2512C19.9387 14.9826 18.6755 14.0827 17.1955 14.0827ZM17.1955 17.6065C16.4474 17.6065 15.8402 16.9993 15.8402 16.2512C15.8402 15.5031 16.4474 14.8959 17.1955 14.8959C17.9437 14.8959 18.5508 15.5031 18.5508 16.2512C18.5508 16.9993 17.9437 17.6065 17.1955 17.6065Z" fill={color}/>
    <defs>
    <filter id="filter0_d_2539_15885" x="0.121566" y="0.25856" width="34.1534" height="34.1538" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.62637"/>
    <feGaussianBlur stdDeviation="2.03297"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2539_15885"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2539_15885" result="shape"/>
    </filter>
    </defs>
    </svg>
  );
};

export default MenuIconViewIcon;
