import i18n from 'i18next';

import { IPackageState } from './types';

import { INewPackageSpecificationItem } from '../PackageEditTable/PackageSpecificationItems';
import { getPackageById } from './packagesActions';

import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import {
  INewNodesListItem,
  IPackageSpecificationItemsState,
  IPieceworkCategory,
  ISinglePackageItemNode,
  ISpecificationType,
  ITrade,
} from 'modules/Packages/PackageSpecificationItems/packagesSpecificationItemsInterface';
import { IProduct } from 'modules/Products/ProductsInterfaces';
import API from 'utils/API';
import { searchByPost } from 'utils/helpers';

export const IS_CATALOG_LOADING_IN_PACKAGES = 'IS_CATALOG_LOADING_IN_PACKAGES';
export const IS_TABLE_LOADING_IN_PACKAGES = 'IS_TABLE_LOADING_IN_PACKAGES';

export const SELECT_NODE_ROW_IN_PACKAGES = 'SELECT_NODE_ROW_IN_PACKAGES';
export const SELECT_SPECIFICATION_NODE_IN_PACKAGES = 'SELECT_SPECIFICATION_NODE_IN_PACKAGES';
export const DESELECT_SPECIFICATION_NODE_IN_PACKAGES = 'DESELECT_SPECIFICATION_NODE_IN_PACKAGES';
export const LOAD_PRODUCTS_CATALOGS_IN_PACKAGES = 'LOAD_PRODUCTS_CATALOGS_IN_PACKAGES';
export const LOAD_PACKAGES_CATALOGS_IN_PACKAGES = 'LOAD_PACKAGES_CATALOGS_IN_PACKAGES';
export const LOAD_NSCODES_CATEGORIES_IN_PACKAGES = 'LOAD_NSCODES_CATEGORIES_IN_PACKAGES';
export const LOAD_PIECE_WORK_RATE_CATALOGS_IN_PACKAGES = 'LOAD_PIECE_WORK_RATE_CATALOGS_IN_PACKAGES';
export const LOAD_SPECIFICATION_TABLE_ITEMS_IN_PACKAGES = 'LOAD_SPECIFICATION_TABLE_ITEMS_IN_PACKAGES';
export const SELECT_SPECIFICATION_ITEM_IN_PACKAGES = 'SELECT_SPECIFICATION_ITEM_IN_PACKAGES';
export const DESELECT_SPECIFICATION_ITEM_IN_PACKAGES = 'DESELECT_SPECIFICATION_ITEM_IN_PACKAGES';
export const LOAD_PIECE_WORK_TRADES_IN_PACKAGES = 'LOAD_PIECE_WORK_TRADES_IN_PACKAGES';
export const LOAD_PIECE_WORK_CATEGORIES_IN_PACKAGES = 'LOAD_PIECE_WORK_CATEGORIES_IN_PACKAGES';
export const SEARCH_ITEM_SUCCESS_IN_PACKAGES = 'SEARCH_ITEM_SUCCESS_IN_PACKAGES';
export const LOAD_WORK_CATEGORIES_IN_PACKAGES = 'LOAD_WORK_CATEGORIES_IN_PACKAGES';
export const SELECT_PRODUCT_CATALOG_IN_PACKAGES = 'SELECT_PRODUCT_CATALOG_IN_PACKAGES';
export const SELECT_PACKAGE_CATALOG_IN_PACKAGES = 'SELECT_PACKAGE_CATALOG_IN_PACKAGES';
export const SELECT_NSCODE_CATEGORY_IN_PACKAGES = 'SELECT_NSCODE_CATEGORY_IN_PACKAGES';
export const SELECT_WORK_CATALOG_IN_PACKAGES = 'SELECT_WORK_CATALOG_IN_PACKAGES';
export const CLEAR_ITEMS_IN_PACKAGES = 'CLEAR_ITEMS_IN_PACKAGES';
export const CLEAR_SEARCH_ITEMS_IN_PACKAGES = 'CLEAR_SEARCH_ITEMS_IN_PACKAGES';
export const CLEAR_SELECTED_ITEMS_IN_PACKAGES = 'CLEAR_SELECTED_ITEMS_IN_PACKAGES';
export const SINGLE_SELECTED_ITEM_IN_PACKAGES = 'SINGLE_SELECTED_ITEM_IN_PACKAGES';
export const CLEAR_SINGLE_SELECTED_ITEM_IN_PACKAGES = 'CLEAR_SINGLE_SELECTED_ITEM_IN_PACKAGES';
export const LOAD_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES = 'LOAD_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES';
export const UPDATE_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES = 'UPDATE_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES';
export const LOAD_SPECIFICATION_PRODUCT_IDS_IN_PACKAGES = 'LOAD_SPECIFICATION_PRODUCT_IDS_IN_PACKAGES';
export const REFRESH_ITEMS_LIST_IN_PACKAGES = 'REFRESH_ITEMS_LIST_IN_PACKAGES';
export const RESET_SPECIFICATION_PACKAGES_MODULE_STATE = 'RESET_SPECIFICATION_PACKAGES_MODULE_STATE';

export const resetPackageSpecificationItemState = () => ({ type: RESET_SPECIFICATION_PACKAGES_MODULE_STATE });

const setIsLoading = (payload: boolean) => ({ type: IS_TABLE_LOADING_IN_PACKAGES, payload });

const setIsCatalogLoading = (value: boolean) => ({
  type: IS_CATALOG_LOADING_IN_PACKAGES,
  payload: { isCatalogLoading: value },
});
const setIsTableLoading = (value: boolean) => ({
  type: IS_TABLE_LOADING_IN_PACKAGES,
  payload: { isTableLoading: value },
});

export const selectItem = (item: INewNodesListItem) => ({
  type: SELECT_SPECIFICATION_ITEM_IN_PACKAGES,
  payload: { selectedItems: [item] },
});

export const deselectItem = (payload: INewNodesListItem) => ({
  type: DESELECT_SPECIFICATION_ITEM_IN_PACKAGES,
  payload,
});

export const selectNodeRow = (node: ISinglePackageItemNode | null) => ({
  type: SELECT_NODE_ROW_IN_PACKAGES,
  payload: { selectedRow: node, updatedNode: null },
});

const loadSpecificationItemsConfigsSuccess = (specificationItemsConfigs: any) => ({
  type: LOAD_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES,
  payload: { ...specificationItemsConfigs },
});
const updateSpecificationItemsConfigsSuccess = (specificationItemsConfigs: any) => ({
  type: UPDATE_SPECIFICATION_ITEMS_CONFIG_IN_PACKAGES,
  payload: { specificationItemsConfigs },
});

export const refreshListItems = () => ({
  type: REFRESH_ITEMS_LIST_IN_PACKAGES,
  payload: { itemsLastUpdate: Date.now() },
});

export const selectWorkCatalog = (selectedWorkCatalog: ISpecificationType) => ({
  type: SELECT_WORK_CATALOG_IN_PACKAGES,
  payload: {
    selectedProductCatalog: null,
    selectedPackageCatalog: null,
    selectedWorkCatalog,
    selectedNSCodeCategory: null,
  },
});

export const selectPackageCatalog = (selectedPackageCatalog: ISpecificationType) => ({
  type: SELECT_PACKAGE_CATALOG_IN_PACKAGES,
  payload: {
    selectedProductCatalog: null,
    selectedPackageCatalog,
    selectedWorkCatalog: null,
    selectedNSCodeCategory: null,
  },
});

export const selectNSCodeCategory = (selectedNSCodeCategory: ISpecificationType) => ({
  type: SELECT_NSCODE_CATEGORY_IN_PACKAGES,
  payload: {
    selectedProductCatalog: null,
    selectedPackageCatalog: null,
    selectedWorkCatalog: null,
    selectedNSCodeCategory,
  },
});

export const selectProductCatalog = (selectedProductCatalog: ISpecificationType) => ({
  type: SELECT_PRODUCT_CATALOG_IN_PACKAGES,
  payload: {
    selectedProductCatalog,
    selectedPackageCatalog: null,
    selectedWorkCatalog: null,
    selectedNSCodeCategory: null,
  },
});

export const loadSpecificationProductIdsSuccess = (products: Array<IProduct>) => ({
  type: LOAD_SPECIFICATION_PRODUCT_IDS_IN_PACKAGES,
  payload: { products },
});

export const loadProductsCatalogsSuccess = (productsCatalogs: Array<any>) => ({
  type: LOAD_PRODUCTS_CATALOGS_IN_PACKAGES,
  payload: { productsCatalogs },
});

export const loadNSCodesCategoriesSuccess = (nsCodeCategories: Array<any>) => ({
  type: LOAD_NSCODES_CATEGORIES_IN_PACKAGES,
  payload: { nsCodeCategories },
});

export const loadPackagesCatalogsSuccess = (packagesCatalogs: Array<any>) => ({
  type: LOAD_PACKAGES_CATALOGS_IN_PACKAGES,
  payload: { packagesCatalogs },
});

export const loadWorkCategoriesCatalogsSuccess = (workCatalogs: Array<any>) => ({
  type: LOAD_WORK_CATEGORIES_IN_PACKAGES,
  payload: { workCatalogs },
});

export const loadItemsForCatalogSuccess = (items: Array<INewNodesListItem>, totalItems: number) => ({
  type: LOAD_SPECIFICATION_TABLE_ITEMS_IN_PACKAGES,
  payload: { items, totalItems, itemsLastUpdate: Date.now() },
});

export const loadPieceWorkTradeSuccess = (trades: Array<ITrade>) => ({
  type: LOAD_PIECE_WORK_TRADES_IN_PACKAGES,
  payload: { trades },
});

export const loadPieceWorkRatesCategoriesSuccess = (pieceworkCategories: Array<IPieceworkCategory>) => ({
  type: LOAD_PIECE_WORK_CATEGORIES_IN_PACKAGES,
  payload: { pieceworkCategories },
});

export const searchForItemSuccess = (hits: Array<INewNodesListItem>, totalHits: number) => ({
  type: SEARCH_ITEM_SUCCESS_IN_PACKAGES,
  payload: { hits, totalHits },
});

export const clearItems = () => ({ type: CLEAR_ITEMS_IN_PACKAGES, payload: { items: [], totalItems: 0 } });
export const clearSearchItems = () => ({ type: CLEAR_SEARCH_ITEMS_IN_PACKAGES, payload: { hits: [], totalHits: 0 } });

export const clearSelectedItems = () => ({ type: CLEAR_SELECTED_ITEMS_IN_PACKAGES, payload: { selectedItems: [] } });

export const setSingleSelectedItem = (item: INewNodesListItem) => ({
  type: SINGLE_SELECTED_ITEM_IN_PACKAGES,
  payload: { singleSelectedItem: item },
});

export const clearSingleSelectedItem = () => ({
  payload: { singleSelectedItem: null },
  type: CLEAR_SINGLE_SELECTED_ITEM_IN_PACKAGES,
});

export const selectSpecificationNode = (item: ISinglePackageItemNode) => {
  return async (dispatch: Function, getState: Function) => {
    const { multiSelectedNodes }: IPackageSpecificationItemsState = getState().packageSpecificationItemsModule;
    const isExist = multiSelectedNodes.find((node: ISinglePackageItemNode) => node.id === item.id);
    if (!isExist) {
      dispatch({
        type: SELECT_SPECIFICATION_NODE_IN_PACKAGES,
        payload: {
          selectedNode: item,
          multiSelectedNodes: [...multiSelectedNodes, item],
        },
      });
    }
  };
};

export const deselectSpecificationNode = (id: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { multiSelectedNodes }: IPackageSpecificationItemsState = getState().packageSpecificationItemsModule;
    const isExist = multiSelectedNodes.find((node: ISinglePackageItemNode) => node.id === id);
    if (isExist) {
      const newItems = multiSelectedNodes.filter((item: ISinglePackageItemNode) => item.id !== id);
      dispatch({
        type: DESELECT_SPECIFICATION_NODE_IN_PACKAGES,
        payload: {
          updatedNode: null,
          multiSelectedNodes: newItems,
          selectedNode: newItems.length === 1 ? newItems[0] : null,
        },
      });
    }
  };
};

export const addProductToPackageSpecification = (
  packageId: string,
  products: any,
  clearFunction?: Function,
  isDragged?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsLoading(true));
      const { selectedRow }: IPackageSpecificationItemsState = getState().packageSpecificationItemsModule;
      const url = `/packages/api/packages/${packageId}/items/multiple`;
      if (selectedRow && !isDragged) {
        const [parentId, previousId] =
          selectedRow?.itemType === 'paragraph' ? [selectedRow?.id, null] : [selectedRow.parentId, selectedRow?.id];
        products.items.forEach((item: INewNodesListItem, index: number) => {
          const prevId: any = index === 0 ? previousId : null;
          item.position = { parentId, prevId };
        });
      } else {
        console.log(products);
      }
      const addProductToPackageSpecification = API.post(url, products);
      await addProductToPackageSpecification;
    } catch (error) {
      dispatch(addErrorMessage({ message: i18n.t('packages.errorAddingProductToPackage') }));
    } finally {
      clearFunction?.();
      dispatch(getPackageById(packageId));
      dispatch(refreshListItems());
      dispatch(setIsLoading(false));
    }
  };
};

export const deleteProductFromPackageSpecification = (packageId: string, productId: string) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `/packages/api/packages/${packageId}/items/${productId}`;
      const deleteProductFromPackage = API.delete(url);
      await deleteProductFromPackage;
    } catch (error) {
      dispatch(addErrorMessage({ message: i18n.t('packages.errorDeletingProductFromPackage') }));
    } finally {
      dispatch(getPackageById(packageId));
      dispatch(selectNodeRow(null));
      dispatch(setIsLoading(false));
    }
  };
};

export const updatePackageItem = (packageId: string, itemId: string, body: any, ignoreSpecificationUpdate = false) => {
  return async (dispatch: Function) => {
    dispatch(setIsLoading(true));
    try {
      const url = `/packages/api/packages/${packageId}/items/${itemId}`;
      const updatePackagePosition = API.put(url, body);
      await updatePackagePosition;
      if (!ignoreSpecificationUpdate) dispatch(getPackageById(packageId));
    } catch (error) {
      dispatch(addErrorMessage({ message: i18n.t('packages.errorUpdatingPackagePosition') }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const findAndSelectSingleItem = (draggedNode: INewPackageSpecificationItem) => {
  return (dispatch: Function, getState: Function) => {
    const { items }: IPackageSpecificationItemsState = getState().packageSpecificationItemsModule;
    const target = items.find(({ id }) => id === draggedNode?.id);
    const body = { ...target, ...draggedNode } as INewNodesListItem;
    dispatch(setSingleSelectedItem(body));
  };
};

export const loadProductsCatalogs = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/products/api/catalogs';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadProductsCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingProductsCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadPackagesCatalogs = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/packages/api/catalogs';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadPackagesCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingPackagesCatalogs');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadNSCodesCategories = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/nscodes/api/categories';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadNSCodesCategoriesSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingNSCodesCategories');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadProductsForCatalog = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { catalogId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const countUrl = `/products/api/products/catalog/${catalogId}/count`;
      const url = `/products/api/products/catalog/${catalogId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countPromise = API.get(countUrl);
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      dispatch(loadItemsForCatalogSuccess(payload, totalItems));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadPackagesForCatalog = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { catalogId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const url = `/packages/api/packages/catalog/${catalogId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      dispatch(loadItemsForCatalogSuccess(payload, 0));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadNSCodesForCategories = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { categoryId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const countUrl = `/nscodes/api/nscodes/category/${categoryId}/count`; // this does not work as expected
      const url = `/nscodes/api/nscodes/category/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countPromise = API.get(countUrl);
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;
      const {
        data: { payload: totalItems },
      } = await countPromise;
      const items = payload.map((item: any) => ({ ...item, code: item.nsCode }));
      dispatch(loadItemsForCatalogSuccess(items, totalItems));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadPieceWorkTrade = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/piecework/api/trades';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadPieceWorkTradeSuccess(payload));
    } catch (e) {
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadPieceWorkRatesCategories = (tradeId: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = `/piecework/api/PieceWorkCategories/trade/${tradeId}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadPieceWorkRatesCategoriesSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingPieceWorkRatesCategories');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadPieceworkRatesForCategories = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { categoryId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const URL = `/piecework/api/pieceworkRates/category/${categoryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadItemsForCatalogSuccess(payload, 0));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const searchForItem = (searchString: string, type: string, searchType: string, showDeleted = false) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const module = getState().packageSpecificationItemsModule;
      const {
        pieceworkCategories,
        productsCatalogs,
        packagesCatalogs,
        workCatalogs,
        nsCodeCategories,
        selectedProductCatalog,
        selectedPackageCatalog,
        selectedWorkCatalog,
        selectedNSCodeCategory,
      }: IPackageSpecificationItemsState = module;

      const isSearchTypeCode = searchType === 'code';
      const isTypeNSCode = type === 'nsCodes';

      const searchTypeArray = isTypeNSCode
        ? isSearchTypeCode
          ? ['nsCode']
          : ['nsCode', 'description']
        : ['code', 'description'];

      let data: any = {
        query: searchString,
        fields: searchType === 'all' || (isSearchTypeCode && isTypeNSCode) ? searchTypeArray : [searchType],
        orderBy: isSearchTypeCode || searchType === 'tags' ? (isTypeNSCode ? 'nsCode' : 'code') : null,
        deleted: showDeleted,
      };

      if (type === 'products') {
        data.catalogIds = selectedProductCatalog?.id
          ? [selectedProductCatalog?.id]
          : productsCatalogs?.map(({ id }) => id);
      } else if (type === 'packages') {
        data.catalogIds = selectedPackageCatalog?.id
          ? [selectedPackageCatalog?.id]
          : packagesCatalogs?.map(({ id }) => id);
      } else if (type === 'workCategories') {
        data.catalogIds = selectedWorkCatalog?.id ? [selectedWorkCatalog?.id] : workCatalogs?.map(({ id }) => id);
      } else if (isTypeNSCode) {
        data.categoryIds = selectedNSCodeCategory?.id
          ? [selectedNSCodeCategory?.id]
          : nsCodeCategories?.map(({ id }) => id);
      } else {
        data.categoryIds = pieceworkCategories?.map(({ id }) => id);
      }
      const URL = `/search/api/${type}/autocomplete`;

      const { hits, totalHits } = await searchByPost(URL, data);
      dispatch(searchForItemSuccess(hits, totalHits));
    } catch (e) {
      const key =
        type === 'products'
          ? `errorSearchingForProducts`
          : type === 'packages'
          ? 'errorSearchingForPackages'
          : 'errorSearchingForPieceworkRates';
      const message = i18n.t(`offers.${key}`);
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadWorkCategories = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsCatalogLoading(true));
      const URL = '/workCategories/api/catalogs';
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadWorkCategoriesCatalogsSuccess(payload));
    } catch (e) {
      const message = i18n.t('offers.errorWorkCategories');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsCatalogLoading(false));
    }
  };
};

export const loadWorkCategoriesForCatalog = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { catalogId = '', pageNumber = 0, pageSize = 10 } = params ?? {};
      const URL = `/workCategories/api/workCategories/catalog/${catalogId}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(loadItemsForCatalogSuccess(payload, 0));
    } catch (e) {
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadSpecificationItemsConfigs = () => {
  return async (dispatch: Function) => {
    const URL = '/userConfiguration/tenantdata/PackageSpecificationItemsConfigs';
    try {
      const { data } = await API.get(URL);
      dispatch(loadSpecificationItemsConfigsSuccess(data));
    } catch (e) {}
  };
};

export const updateSpecificationItemsConfigs = (key: string, value: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { specificationItemsConfigs }: IPackageSpecificationItemsState = getState().packageSpecificationItemsModule;
    const URL = '/userConfiguration/tenantdata/PackageSpecificationItemsConfigs';
    const objKey = typeof value === 'object' && value.hasOwnProperty('name') ? value.name + 'CatalogId' : key;
    const objVal = typeof value === 'object' && value.hasOwnProperty('id') ? value.id : value;
    const data = { [objKey]: objVal };
    try {
      await API.put(URL, data);
      dispatch(updateSpecificationItemsConfigsSuccess({ ...specificationItemsConfigs, ...data }));
    } catch (e) {
      const message = i18n.t('offers.errorUpdatingSpecificationItemsConfigs');
      dispatch(addErrorMessage({ message }));
    }
  };
};

export const loadStandardText = (params: any) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsTableLoading(true));
      const { pageNumber = 0, pageSize = 10 } = params ?? {};
      const countURL = `/texts/api/texts/count`;
      const URL = `/texts/api/texts?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const countPromise = API.get(countURL);
      const {
        data: { payload },
      } = await API.get(URL);
      const {
        data: { payload: totalItems },
      } = await countPromise;
      dispatch(loadItemsForCatalogSuccess(payload, totalItems));
    } catch (e) {
      const message = i18n.t('offers.errorLoadingStandardText');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsTableLoading(false));
    }
  };
};

export const loadSpecificationProductIds = (listOfIds: Array<string>) => {
  let url = `/products/api/products/List`;
  url += `?id=${listOfIds[0]}`;
  if (listOfIds[1]) {
    for (let i = 1; i < listOfIds.length; i++) {
      url += `&id=${listOfIds[i]}`;
    }
  }
  return async (dispatch: Function) => {
    try {
      const dataPromise = API.get(url);
      const {
        data: { payload },
      } = await dataPromise;

      dispatch(loadSpecificationProductIdsSuccess(payload));
    } catch (error) {
    } finally {
    }
  };
};

export const createParagraphInPackage = (item: any, callback = () => {}) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      let position: any;
      const { selectedRow }: IPackageSpecificationItemsState = getState().packageSpecificationItemsModule;
      const { selectedItem }: IPackageState = getState().packagesModule;
      if (selectedRow) {
        const [parentId, prevId] =
          selectedRow?.itemType === 'paragraph' ? [selectedRow?.id, null] : [selectedRow.parentId, selectedRow?.id];
        position = { parentId, prevId };
      } else {
        position = { parentId: selectedItem?.root?.id, prevId: null };
      }
      item.position = position;
      item.itemType = 'paragraph';
      const url = `/packages/api/packages/${selectedItem?.id}/items`;
      await API.post(url, item);
      dispatch(getPackageById(selectedItem?.id as string));
    } catch (e) {
      const message = i18n.t('offers.errorCreatingNewParagraph');
      dispatch(addErrorMessage({ message }));
    } finally {
      callback();
      dispatch(refreshListItems());
      dispatch(setIsLoading(false));
    }
  };
};
