import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

import { Box, FormControl } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { IconStyles } from './DatePicker.styled';

import { NewTextField } from 'components';
import { IconItem } from 'components/Icons';

interface IProps {
  value?: string | Date | null;
  name?: string;
  setFieldValue: Function;
  label?: string;
  onChange?: Function;
  placeholder?: string;
  error?: boolean;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  hasBorder?: boolean;
}
export const NewDatePicker: FC<IProps> = ({
  value,
  name,
  setFieldValue,
  label,
  onChange,
  placeholder,
  error,
  fullWidth,
  margin,
  hasBorder = false,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      sx={{
        label: {
          lineHeight: 1,
        },
      }}>
      <MuiDatePicker
        inputFormat="DD.MM.YYYY"
        views={['year', 'month', 'day']}
        componentsProps={{
          actionBar: {
            actions: ['clear'],
          },
        }}
        label={label}
        renderInput={(params: any) => (
          <Box alignItems={'center'} display={'flex'}>
            <NewTextField
              fullWidth
              hasBorder={hasBorder}
              bgColor="primary.contrastText"
              name={name}
              {...params}
              error={error}
            />
            <IconItem
              name="X"
              defaultColor={theme.palette.grey['A400']}
              activeColor={theme.palette.grey['A700']}
              isHover
              onClick={() => setFieldValue(name, null)}
              styles={IconStyles}
            />
          </Box>
        )}
        value={value}
        onChange={(newDate: any) => {
          setFieldValue(name, newDate);
        }}
        {...rest}
      />
    </FormControl>
  );
};

export default NewDatePicker;
