import { FC } from 'react';

type AnalyticSolidIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const AnalyticSolidIcon: FC<AnalyticSolidIconPropTypes> = ({
  width = 210,
  height = 200,
  color = '#0975A5',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 210 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.7957 188.978H167.201C170.621 188.986 173.917 187.694 176.429 185.36C186.909 175.599 195.293 163.784 201.066 150.641C206.838 137.499 209.877 123.308 209.995 108.94C210.532 50.5191 163.327 2.44921 105.253 2.31134C47.1471 2.17338 6.89951e-05 49.5196 6.89951e-05 107.944C-0.0165233 122.485 2.95971 136.873 8.74137 150.2C14.523 163.527 22.9849 175.506 33.5941 185.382C36.101 187.705 39.3876 188.989 42.7957 188.978Z"
        fill="url(#paint0_linear_5780_9658)"
      />
      <path
        d="M176.609 188.623H70.7077C68.1092 188.623 65.6172 187.59 63.7798 185.753C61.9424 183.916 60.9102 181.424 60.9102 178.825V9.79752C60.9102 8.51089 61.1636 7.23686 61.656 6.04817C62.1483 4.85948 62.87 3.77941 63.7798 2.86963C64.6896 1.95984 65.7696 1.23816 66.9583 0.745793C68.147 0.253421 69.421 0 70.7077 0H143.373L186.406 43.0334V178.825C186.406 180.112 186.153 181.386 185.661 182.575C185.168 183.763 184.447 184.843 183.537 185.753C182.627 186.663 181.547 187.385 180.358 187.877C179.17 188.369 177.896 188.623 176.609 188.623Z"
        fill="#E5E9F1"
      />
      <path
        d="M176.609 188.623H70.7077C68.1092 188.623 65.6172 187.59 63.7798 185.753C61.9424 183.916 60.9102 181.424 60.9102 178.825V9.79752C60.9102 8.51089 61.1636 7.23686 61.656 6.04817C62.1483 4.85948 62.87 3.77941 63.7798 2.86963C64.6896 1.95984 65.7696 1.23816 66.9583 0.745793C68.147 0.253421 69.421 0 70.7077 0H143.373L186.406 43.0334V178.825C186.406 180.112 186.153 181.386 185.661 182.575C185.168 183.763 184.447 184.843 183.537 185.753C182.627 186.663 181.547 187.385 180.358 187.877C179.17 188.369 177.896 188.623 176.609 188.623Z"
        fill="#E5E9F1"
      />
      <path
        d="M186.406 43.0334H153.171C150.572 43.0334 148.08 42.0012 146.243 40.1638C144.405 38.3264 143.373 35.8344 143.373 33.2359V0L186.406 43.0334Z"
        fill={color}
      />
      <path
        d="M122.795 32.8013H81.7927C79.7153 32.8013 78.0312 34.4853 78.0312 36.5627V36.5628C78.0312 38.6402 79.7153 40.3242 81.7927 40.3242H122.795C124.872 40.3242 126.556 38.6402 126.556 36.5627C126.556 34.4853 124.872 32.8013 122.795 32.8013Z"
        fill="white"
      />
      <path
        d="M164.589 58.1616H81.7927C79.7153 58.1616 78.0312 59.8457 78.0312 61.9231V61.9231C78.0312 64.0005 79.7153 65.6846 81.7927 65.6846H164.589C166.666 65.6846 168.35 64.0005 168.35 61.9231C168.35 59.8457 166.666 58.1616 164.589 58.1616Z"
        fill="white"
      />
      <path
        d="M164.589 83.522H81.7927C79.7153 83.522 78.0312 85.206 78.0312 87.2835V87.2835C78.0312 89.3609 79.7153 91.0449 81.7927 91.0449H164.589C166.666 91.0449 168.35 89.3609 168.35 87.2835C168.35 85.206 166.666 83.522 164.589 83.522Z"
        fill="white"
      />
      <path
        d="M164.589 108.883H81.7927C79.7153 108.883 78.0312 110.567 78.0312 112.644V112.644C78.0312 114.722 79.7153 116.406 81.7927 116.406H164.589C166.666 116.406 168.35 114.722 168.35 112.644C168.35 110.567 166.666 108.883 164.589 108.883Z"
        fill="white"
      />
      <path
        d="M164.891 134.243H81.7927C79.7153 134.243 78.0312 135.927 78.0312 138.005C78.0312 140.082 79.7153 141.766 81.7927 141.766H164.891C166.969 141.766 168.653 140.082 168.653 138.005C168.653 135.927 166.969 134.243 164.891 134.243Z"
        fill="white"
      />
      <path
        d="M126.556 159.455H81.7927C79.7153 159.455 78.0312 161.139 78.0312 163.217C78.0312 165.294 79.7153 166.978 81.7927 166.978H126.556C128.634 166.978 130.318 165.294 130.318 163.217C130.318 161.139 128.634 159.455 126.556 159.455Z"
        fill="white"
      />
      <path
        d="M31.4955 159.135H15.3223C13.5058 159.135 12.0332 160.733 12.0332 162.705V195.746C12.0332 197.718 13.5058 199.317 15.3223 199.317H31.4955C33.312 199.317 34.7846 197.718 34.7846 195.746V162.705C34.7846 160.733 33.312 159.135 31.4955 159.135Z"
        fill={color}
      />
      <path
        d="M62.9955 140.189H46.8223C45.0058 140.189 43.5332 141.788 43.5332 143.76V195.747C43.5332 197.718 45.0058 199.317 46.8223 199.317H62.9955C64.812 199.317 66.2846 197.718 66.2846 195.747V143.76C66.2846 141.788 64.812 140.189 62.9955 140.189Z"
        fill={color}
      />
      <path
        d="M94.4974 126.867H78.3243C76.5077 126.867 75.0352 128.466 75.0352 130.437V195.746C75.0352 197.718 76.5077 199.316 78.3243 199.316H94.4974C96.314 199.316 97.7865 197.718 97.7865 195.746V130.437C97.7865 128.466 96.314 126.867 94.4974 126.867Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_5780_9658"
          x1="105"
          y1="2.31104"
          x2="105"
          y2="188.978"
          gradient-units="userSpaceOnUse">
          <stop stopColor="#FBFDFE" />
          <stop offset="1" stopColor="#F0F5FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AnalyticSolidIcon;
