import { useEffect, useState } from 'react';

import { SwitchContainer, SwitchInput, SwitchKnobs, SwitchLayer } from './Switch.styled';

type TSwitchPros = {
  checked?: boolean;
  disableUncheck?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  name?: string;
};

const Switch = ({ checked, onChange, isLoading, name, disableUncheck }: TSwitchPros) => {
  // This is done so checking is instant on frontend. If for some reason it fails, it will change back with useEffect and the API should show an error
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (e: any) => {
    const preventUncheck = disableUncheck && isChecked;
    if (!onChange || preventUncheck) return;
    setIsChecked(true);
    onChange(e);
  };

  return (
    <SwitchContainer data-testid="switch-container">
      <SwitchInput
        name={name}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        data-testid="checkbox-switch"
      />
      <SwitchKnobs name={name} isChecked={isChecked} isLoading={isLoading} />
      <SwitchLayer name={name} isChecked={isChecked} />
    </SwitchContainer>
  );
};

export default Switch;
