import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type ArrowHeadPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const ArrowHead: FC<ArrowHeadPropTypes> = ({ width = 18, height = 18, color = 'softPurple', ...rest }) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.67285 15.1725L6.00035 16.5L13.5004 9L6.00035 1.5L4.67285 2.8275L10.8454 9L4.67285 15.1725Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default ArrowHead;
