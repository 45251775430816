import { FC } from 'react';

type FolderOffPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const FolderOff: FC<FolderOffPropTypes> = ({
  width = 24,
  height = 24,
  color = '#046B99',
  bgColor = '#fff',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="FolderOffOutlinedIcon"
      {...rest}>
      <path d="M20 6h-8l-2-2H7.17l4 4H20v9.17l1.76 1.76c.15-.28.24-.59.24-.93V8c0-1.1-.9-2-2-2zM2.1 2.1.69 3.51l1.56 1.56c-.15.28-.24.59-.24.93L2 18c0 1.1.9 2 2 2h13.17l3.31 3.31 1.41-1.41L2.1 2.1zM4 18V6.83L15.17 18H4z"></path>
    </svg>
  );
};

export default FolderOff;
