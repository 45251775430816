import { FC } from 'react';

type CircleIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const CircleIcon: FC<CircleIconPropTypes> = ({ width = 56, height = 56, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28.7891" cy="28" r="28" fill="url(#paint0_linear_409_10717)" />
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M21.7887 29.1667C19.2221 29.1667 17.1221 31.2667 17.1221 33.8333C17.1221 36.4 19.2221 38.5 21.7887 38.5C24.3554 38.5 26.4554 36.4 26.4554 33.8333C26.4554 31.2667 24.3554 29.1667 21.7887 29.1667ZM28.7887 17.5C26.2221 17.5 24.1221 19.6 24.1221 22.1667C24.1221 24.7333 26.2221 26.8333 28.7887 26.8333C31.3554 26.8333 33.4554 24.7333 33.4554 22.1667C33.4554 19.6 31.3554 17.5 28.7887 17.5ZM35.7887 29.1667C33.2221 29.1667 31.1221 31.2667 31.1221 33.8333C31.1221 36.4 33.2221 38.5 35.7887 38.5C38.3554 38.5 40.4554 36.4 40.4554 33.8333C40.4554 31.2667 38.3554 29.1667 35.7887 29.1667Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_409_10717"
          x1="0.789062"
          y1="0"
          x2="56.7891"
          y2="56"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor="#0A83B9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CircleIcon;
