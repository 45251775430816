import { AppTheme } from 'theme';

import { CssBaseline } from '@mui/material';
import { ReactElement, Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { AppStyled } from './App.styled';
import Layout from './containers/Layout/Layout';
import AppAlerts from './modules/AppAlerts/AppAlerts';
import './theme/fonts.css';
import Auth from './utils/Auth';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const AppRoutes = lazy(() => import('./AppRoutes'));
const LoginPage = lazy(() => import('modules/Login/LoginPage'));
const ResetPassword = lazy(() => import('modules/ResetPassword/ResetPasswordPage'));
const SSOLanding = lazy(() => import('modules/Login/SSOLanding'));

function App(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const isRedirectPath =
    location.pathname === '/login' ||
    location.pathname === '/reset-password/:segment' ||
    location.pathname === '/sg_sso_landing';

  useEffect(() => {
    if (Auth.isLoggedIn()) return;
    if (isRedirectPath) return;

    Auth.setRedirectedUrl(location.pathname);
    navigate('/login');
  }, [Auth.isLoggedIn(), isRedirectPath]); // eslint-disable-line

  return (
    <AppTheme>
      <AppStyled className="App" data-testid="App">
        <CssBaseline />
        <AppAlerts />
        {Auth.isLoggedIn() && <Layout />}
        <ErrorBoundary location={location}>
          <Suspense>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/reset-password/:segment" element={<ResetPassword />} />
              <Route path="/sg_sso_landing" element={<SSOLanding />} />
              <Route path="/*" element={<AppRoutes />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </AppStyled>
    </AppTheme>
  );
}

export default App;
