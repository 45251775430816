import { ICompaniesState } from '../companiesInterfaces';
import { CompanyInitial, CompanyContacItemInitial, PersonInitial } from './initialState';
import {
  SET_COMPANIES_LOADER,
  SAVE_COMPANIES,
  LOAD_COMPANIES_CONTACTS_SUCCESS,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  IS_DELETED_COMPANIES_LOADING,
  SAVE_DELETED_COMPANIES,
  LOAD_COMPANY_ITEM,
  ADD_ERROR_MESSAGE,
  UPDATE_COMPANY_ITEM,
  SAVE_ORGANIZATIONS,
  SAVE_SEARCHED_CONTACT,
  SAVE_SEARCHED_COMPANIES_BY_NAME,
  CREATE_COMPANY,
} from './companiesActions';
import { COMPANIES_ACTIONS } from './types';

const defaultState: ICompaniesState = {
  error: null,
  isCompaniesLoading: false,
  isDeletedCompaniesLoading: false,
  companies: [CompanyInitial],
  companyContacts: [CompanyContacItemInitial],
  searchedCompaniesByName: null,
  totalCompaniesNumber: 0,
  searchedOrganizations: null,
  searchedContacts: [PersonInitial],
  companyItem: CompanyInitial,
  deletedCompanies: [CompanyInitial],
  deletedCompaniesNumber: 0,
  createdItem: null,
};

const companiesReducer = (state = defaultState, action: COMPANIES_ACTIONS) => {
  switch (action.type) {
    case SET_COMPANIES_LOADER:
      return { ...state, isCompaniesLoading: action.isLoading };
    case IS_DELETED_COMPANIES_LOADING:
      return { ...state, isDeletedCompaniesLoading: true };
    case SAVE_COMPANIES:
      return {
        ...state,
        companies: action.allCompanies,
        isCompaniesLoading: false,
        totalCompaniesNumber: action.totalCompaniesNumber,
      };
    case LOAD_COMPANIES_CONTACTS_SUCCESS:
      return { ...state, companyContacts: action.contacts };
    case DELETE_COMPANY_REQUEST:
      return { ...state, isCompaniesLoading: true };
    case DELETE_COMPANY_SUCCESS:
      const companies = state.companies.filter((company) => company.id !== action.id);
      return { ...state, isCompaniesLoading: false, companies };
    case SAVE_DELETED_COMPANIES:
      return {
        ...state,
        deletedCompanies: action.deletedCompanies,
        isDeletedCompaniesLoading: false,
        deletedCompaniesNumber: action.deletedCompaniesNumber,
      };
    case LOAD_COMPANY_ITEM:
      return {
        ...state,
        companyItem: action.company,
        isCompaniesLoading: false,
      };
    case UPDATE_COMPANY_ITEM:
      return { ...state, companyItem: action.company };
    case SAVE_ORGANIZATIONS:
      return { ...state, searchedOrganizations: action.organizations };
    case SAVE_SEARCHED_CONTACT:
      return { ...state, searchedContacts: action.contactPersons };
    case ADD_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        isCompaniesLoading: false,
        error: action.error,
      };
    case CREATE_COMPANY:
      return { ...state, createdItem: action.company };
    case SAVE_SEARCHED_COMPANIES_BY_NAME:
      return { ...state, searchedCompaniesByName: action.companies };
    default:
      return state;
  }
};

export default companiesReducer;
