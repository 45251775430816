import { darken, lighten } from '@mui/system';

import { PaletteOptions } from '@mui/material';

import { getContrastText } from 'utils/helpers';

declare module '@mui/material/styles' {
  interface Palette {
    gray: PaletteColorOptions;
    purple: PaletteColorOptions;
    brown: PaletteColorOptions;
  }

  interface PaletteOptions {
    gray?: PaletteColorOptions;
    purple?: PaletteColorOptions;
    brown?: PaletteColorOptions;
  }

  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
  interface PaletteColorOptions {
    main?: string;
    light?: string;
    lighter?: string;
    dark?: string;
    contrastText?: string;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
    purple: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    gray: true;
    purple: true;
  }
}

export const generalPalette = {
  primary: {
    main: '#0975A5',
    light: '#03567A',
    lighter: '#e6f0f5',
    dark: '#1B2559',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#1A1D1F',
    light: '#d7d7d7',
    lighter: '#EFEFEF',
    dark: '#fff',
    contrastText: '#FCFCFC',
  },
  error: {
    main: '#EE7C7C',
    light: '#FFE2E2',
    lighter: '#FCF0F2',
    dark: '#935d64',
    contrastText: '#fff',
  },
  info: {
    main: '#1A1D1F',
    light: '#EFEFEF',
    lighter: '#fff',
    dark: '#d7d7d7',
    contrastText: '#FCFCFC',
  },
  success: {
    main: '#507358',
    light: '#B2C8B6',
    lighter: '#F4F7FE',
    dark: '#00684F',
    contrastText: getContrastText('#EEFBEF'),
  },
  gray: {
    main: '#505565',
    light: '#ACACAC',
    lighter: '#F8F8F8',
    dark: '#202236',
    contrastText: getContrastText('#505565'),
  },
  brown: {
    main: '#78603D',
    light: '#FDF7ED',
    lighter: lighten('#78603D', 0.2),
    dark: darken('#78603D', 0.2),
    contrastText: getContrastText('#78603D'),
  },
  purple: {
    main: '#32226B',
    light: '#A3AED0',
    lighter: '#EAECF4',
    dark: darken('#32226B', 0.2),
    contrastText: getContrastText('#32226B'),
  },
  text: {
    primary: '#434D60',
    secondary: '#0D1A2C',
    disabled: '#636B77',
  },
  background: {
    default: '#F7F7F7',
    paper: '#FCFCFC',
  },
  grey: {
    100: '#3689AD', // former light
    200: '#DADEE6', // former main/90
    300: '#fff', // former contrastText
    400: '#FFFFFF', // former border color
    500: '#C3CAD9', // former 85
    600: '#6B7A99', // former 60
    700: '#EDEFF2', // former 95
    800: '#F5F6F7', // former 97
    900: '#EFEFEF', //former borderlightgrey
    A100: '#333333', // former 1
    A200: '#F2F2F2', // former 6
    A400: '#CCC',
    A700: '#a3a3a3',
  },
  light: {},
  dark: {},
};

export const lightPalette: PaletteOptions = {
  ...generalPalette,
};

export const darkPalette: PaletteOptions = {
  ...generalPalette,
};

export const smartcraftPalette = {
  palette: {
    primary: {
      '50': '#F5FAFB',
      '100': '#D6EDED',
      '200': '#ADDADB',
      '300': '#83C7C9',
      '400': '#57B4B6',
      '500': '#397F80',
      '600': '#2D6465',
      '700': '#224B4C',
      '800': '#173333',
      '900': '#081313',
    },
    neutral: {
      '100': '#E8E8EB',
      '200': '#D1D1D7',
      '300': '#BBBBC3',
      '400': '#A5A5B0',
      '500': '#737385',
      '600': '#5A5A6F',
      '700': '#42425A',
      '800': '#2C2C46',
      '900': '#0E0E28',
    },
    danger: {
      '50': '#FEF8F6',
      '100': '#FBE3DC',
      '200': '#F7C7B8',
      '300': '#F4AA93',
      '400': '#F08B6D',
      '500': '#C74D28',
      '600': '#9E3D20',
      '700': '#762E18',
      '800': '#501F10',
      '900': '#1E0C06',
    },
    warning: {
      '100': '#FAE4D4',
      '200': '#F5C9A9',
      '300': '#F0AD7C',
      '400': '#E79153',
      '500': '#A3663B',
      '600': '#81512E',
      '700': '#603C23',
      '800': '#412917',
      '900': '#180F09',
      '50': '#FEF8F4',
    },
    success: {
      '50': '#F5FBF8',
      '100': '#D7EDE2',
      '200': '#AEDCC6',
      '300': '#85CAA9',
      '400': '#5BB78B',
      '500': '#278457',
      '600': '#1F6845',
      '700': '#174E33',
      '800': '#0F3523',
      '900': '#06140D',
    },
    common: { white: '#FFFFFF', black: '#000000' },
  },
};

export const cordelPalette = {
  palette: {
    primary: {
      '50': '#F7FAFB',
      '100': '#E1E9F0',
      '200': '#C3D4E1',
      '300': '#A6BFD2',
      '400': '#89AAC3',
      '500': '#4579A1',
      '600': '#37607F',
      '700': '#29475F',
      '800': '#1C3040',
      '900': '#0A1218',
      '050': '#F7FAFB',
    },
    neutral: {
      '50': '#F9F9FA',
      '100': '#E7E8EB',
      '200': '#CFD2D7',
      '300': '#B8BCC3',
      '400': '#A1A6B0',
      '500': '#6D7584',
      '600': '#565C68',
      '700': '#40454D',
      '800': '#2B2E34',
      '900': '#101113',
      '050': '#F9F9FA',
    },
    danger: {
      '50': '#FEF8F6',
      '100': '#FBE3DC',
      '200': '#F7C7B8',
      '300': '#F4AA93',
      '400': '#F08B6D',
      '500': '#C74D28',
      '600': '#9E3D20',
      '700': '#762E18',
      '800': '#501F10',
      '900': '#1E0C06',
    },
    warning: {
      '100': '#FAE4D4',
      '200': '#F5C9A9',
      '300': '#F0AD7C',
      '400': '#E79153',
      '500': '#A3663B',
      '600': '#81512E',
      '700': '#603C23',
      '800': '#412917',
      '900': '#180F09',
      '50': '#FEF8F4',
    },
    success: {
      '50': '#F5FBF8',
      '100': '#D7EDE2',
      '200': '#AEDCC6',
      '300': '#85CAA9',
      '400': '#5BB78B',
      '500': '#278457',
      '600': '#1F6845',
      '700': '#174E33',
      '800': '#0F3523',
      '900': '#06140D',
    },
    common: { white: '#FFFFFF', black: '#000000' },
  },
};
