import { FC } from 'react';

type FolderMapIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const FolderMapIcon: FC<FolderMapIconPropTypes> = ({ width = 56, height = 56, color = '#0774A4', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56.8417" height="56.8417" rx="12" fill="#F4F7FE"/>
    <path d="M25.6836 17.4736H17.4731C15.9679 17.4736 14.75 18.7052 14.75 20.2104L14.7363 36.6313C14.7363 38.1365 15.9679 39.3681 17.4731 39.3681H39.3676C40.8728 39.3681 42.1044 38.1365 42.1044 36.6313V22.9472C42.1044 21.442 40.8728 20.2104 39.3676 20.2104H28.4204L25.6836 17.4736Z" fill={color}/>
    </svg>
  );
};

export default FolderMapIcon;
