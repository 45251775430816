import { IDocumentState } from "./DocumentsInterface";
import { ADD_ERROR_MESSAGE, LOAD_DOCUMENTS, LOAD_FOLDERS, SAVE_SEARCHED_FOLDERS, SET_HAS_MORE_ITEMS, SET_IS_LOADING, SET_TOTAL_DOCUMENTS } from "./documentsActions";
import { DOCUMENTS_ACTIONS } from "./types";

const defaultState: IDocumentState = {
    error: null,
    isLoading: false,
    hasMoreItems: false,
    totalDocuments: 0,
    documents: [],
    folders: { subFolders: [] },
    searchedFolders: { subFolders: [] },
  };
  
  const documentsReducer = (state = defaultState, action: DOCUMENTS_ACTIONS) => {
    switch (action.type) {
        case SET_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case ADD_ERROR_MESSAGE:
            return {
            ...state,
            loading: false,
            isLoading: false,
            error: action.error,
        };
        case SET_HAS_MORE_ITEMS:
            return { ...state, hasMoreItems: action.hasMoreItems };
        case SET_TOTAL_DOCUMENTS:
            return { ...state, totalDocuments: action.totalDocuments };
        case LOAD_DOCUMENTS:
            return { ...state, documents: action.documents };
        case LOAD_FOLDERS:
            return { ...state, folders: action.folders };
        case SAVE_SEARCHED_FOLDERS:
            return { ...state, searchedFolders: action.searchedFolders };

      default:
        return state;
    };
  };
  
  export default documentsReducer;