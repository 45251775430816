import {
  DELETE_CATALOG,
  EDIT_CATALOG,
  HARD_DELETE_CATALOG,
  LOAD_ALL_CATALOGS,
  LOAD_ALLOWED_MODULES,
  LOAD_DELETED_CATALOGS,
  SELECT_CATALOG,
  RESTORE_CATALOG,
  IS_CATALOG_LOADING,
  IS_CATALOG_DIALOG_LOADING,
} from './CatalogsActions';
import { ICatalog, ICatalogAction, ICatalogState } from '../ProductsInterfaces';

const defaultState = {
  items: [],
  maxPageSize: 0,
  defaultPageSize: 0,
  selectedItem: null,
  allowedModules: [],
  deletedItems: [],
  isLoading: false,
  isDialogLoading: false,
} as ICatalogState;

export const catalogs = (state = defaultState, action: ICatalogAction) => {
  const { type, payload } = action;

  switch (type) {
    case IS_CATALOG_LOADING:
      return { ...state, isLoading: payload };
    case IS_CATALOG_DIALOG_LOADING:
      return { ...state, isDialogLoading: payload };

    case LOAD_ALL_CATALOGS:
    case LOAD_ALLOWED_MODULES:
    case LOAD_DELETED_CATALOGS:
      return { ...state, ...payload };

    case SELECT_CATALOG:
      return { ...state, selectedItem: { ...payload } };
    case EDIT_CATALOG:
      const updatedItem = payload as ICatalog;
      const items = state.items.map((item) => {
        return item.id === updatedItem.id ? { ...payload } : item;
      });
      return { ...state, items };
    case DELETE_CATALOG:
    case RESTORE_CATALOG:
    case HARD_DELETE_CATALOG:
      const deletedItem = payload as ICatalog;
      const key = type === DELETE_CATALOG ? 'items' : 'deletedItems';
      const deletedItems = state[key].filter(({ id }) => {
        return deletedItem.id !== id;
      });
      return { ...state, [key]: deletedItems };
  }
  return state;
};

export default catalogs;
