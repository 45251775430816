import { FC } from 'react';

type FactoryPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Factory: FC<FactoryPropTypes> = ({ width = 30, height = 30, color = '#507358', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5002 12.5V27.5H2.50024V12.5L11.2502 8.75V11.25L17.5002 8.75V12.5H27.5002ZM21.5002 10.625L22.5002 2.5H26.2502L27.2502 10.625H21.5002ZM13.7502 22.5H16.2502V17.5H13.7502V22.5ZM8.75024 22.5H11.2502V17.5H8.75024V22.5ZM21.2502 17.5H18.7502V22.5H21.2502V17.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Factory;
