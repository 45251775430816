import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { Box, List, ListItemButton, ListItemIcon, ListItemText, Avatar, Divider, Typography } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import {
  FirstContainerStyled,
  SecondContainerStyled,
  UserListItemButtonStyles,
  ListStyles,
  IconStyles,
} from './LayoutStyles';

import { switchAccountDialog } from 'modules/User/UserSwitchDialog';
import { getMyInfo, logOutAccount } from 'modules/User/redux/userActions';
import { IUserState } from 'modules/User/userInterfaces';
import { useModuleList } from 'modules/common';
import Auth from 'utils/Auth';
import { getRootUrl, stringAvatar } from 'utils/helpers';
import { reset as resetUserTracking, track } from 'utils/tracking';

type TUserPanelProps = {
  isOpen: boolean;
};

const UserPanel = ({ isOpen }: TUserPanelProps) => {
  const { t } = useTranslation();

  const { dispatch, useAppSelector, openDialog } = useModuleList();
  const { pathname } = useLocation();

  const myInfo: IUserState['myInfo'] = useAppSelector(({ userModule }) => userModule.myInfo);

  const [activeLink, setActiveLink] = useState('none');
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const isLoggedIn = Auth.isLoggedIn();
  const { forename = '', surname = '', username = '' } = myInfo ?? {};
  const fullName = forename + ' ' + surname;

  const handleLogout = useCallback(() => {
    track('User logged out with logout button');
    resetUserTracking();
    dispatch(logOutAccount());
  }, [dispatch]);

  const handleProfileShow = () => setIsVisible((prev) => !prev);

  useEffect(() => {
    setActiveLink(getRootUrl(pathname));
  }, [pathname]);

  useEffect(() => {
    if (!myInfo && isLoggedIn) dispatch(getMyInfo());
  }, [dispatch, myInfo, isLoggedIn]);

  const renderUserPanel = () => (
    <FirstContainerStyled isOpen={isOpen}>
      <List component="div" sx={!isOpen ? ListStyles : null}>
        <Box display="flex" alignItems="center" justifyContent="center" marginY={1}>
          <ListItemIcon sx={{ minWidth: 32, marginRight: isOpen ? 2 : 0 }}>
            <Avatar {...stringAvatar(fullName)} sx={{ bgcolor: 'orange', width: 32, height: 32, fontSize: 16 }} />
          </ListItemIcon>
          {isOpen && (
            <Box>
              <ListItemText primary={<Typography sx={{ fontSize: '12px' }}>{fullName}</Typography>} />
              <ListItemText primary={<Typography sx={{ fontSize: '10px' }}>{username}</Typography>} />
            </Box>
          )}
        </Box>
        <Divider sx={{ marginBottom: 1 }} />
        <ListItemButton
          sx={UserListItemButtonStyles(isOpen)}
          to={'/user'}
          selected={activeLink === '/user'}
          component={NavLink}>
          <ListItemIcon sx={IconStyles(isOpen)}>
            <PersonIcon />
          </ListItemIcon>
          {isOpen && <ListItemText>{t('globals.userSettings')}</ListItemText>}
        </ListItemButton>
        <ListItemButton sx={UserListItemButtonStyles(isOpen)} onClick={() => openDialog(switchAccountDialog)}>
          <ListItemIcon sx={IconStyles(isOpen)}>
            <PublishedWithChangesIcon />
          </ListItemIcon>
          {isOpen && <ListItemText>{t('globals.switchAccount')}</ListItemText>}
        </ListItemButton>
        {/* <ListItemButton sx={UserListItemButtonStyles(isOpen)}>
          <ListItemIcon sx={IconStyles(isOpen)}>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          {isOpen && <ListItemText>Admin page</ListItemText>}
        </ListItemButton> */}
        <ListItemButton sx={UserListItemButtonStyles(isOpen)} onClick={handleLogout}>
          <ListItemIcon sx={IconStyles(isOpen)}>
            <ExitToAppIcon />
          </ListItemIcon>
          {isOpen && <ListItemText>{t('globals.logout')}</ListItemText>}
        </ListItemButton>
      </List>
    </FirstContainerStyled>
  );

  return (
    <Box display="flex" flexDirection="column">
      {isVisible && renderUserPanel()}
      <Box>
        <SecondContainerStyled isOpen={isOpen} onClick={handleProfileShow}>
          <ListItemIcon sx={{ minWidth: 36 }}>
            <Avatar {...stringAvatar(fullName)} sx={{ bgcolor: 'orange', width: 24, height: 24, fontSize: 12 }} />
          </ListItemIcon>
          <ListItemText sx={{ display: isOpen ? 'block' : 'none' }}>{t('globals.profile')}</ListItemText>
        </SecondContainerStyled>
      </Box>
    </Box>
  );
};

export default UserPanel;
