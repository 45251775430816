import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type PersonPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Person: FC<PersonPropTypes> = ({ width = 48, height = 48, color = 'white', ...rest }) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 11.2C0 5.01441 5.01441 0 11.2 0H36.8C42.9856 0 48 5.01441 48 11.2V36.8C48 42.9856 42.9856 48 36.8 48H11.2C5.01441 48 0 42.9856 0 36.8V11.2Z"
        fill={resolvedColor}
      />
      <path
        d="M23.9999 24C26.2099 24 27.9999 22.21 27.9999 20C27.9999 17.79 26.2099 16 23.9999 16C21.7899 16 19.9999 17.79 19.9999 20C19.9999 22.21 21.7899 24 23.9999 24ZM23.9999 26C21.3299 26 15.9999 27.34 15.9999 30V32H31.9999V30C31.9999 27.34 26.6699 26 23.9999 26Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default Person;
