import { Box, Skeleton } from '@mui/material';
import { memo } from 'react';

const SkeletonSidebar = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Skeleton variant="rectangular" width="64px" height="64px" sx={{ borderRadius: 3, marginRight: 3 }} />
      <Skeleton variant="text" sx={{ color: 'text.primary' }}></Skeleton>
      <Skeleton variant="text" sx={{ color: 'text.secondary' }}></Skeleton>
      <Skeleton variant="text" sx={{ color: 'success.main' }}></Skeleton>
      <Skeleton variant="text" sx={{ color: 'text.primary', marginTop: 3 }}></Skeleton>
      <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
      <Box>
        <Skeleton variant="text" sx={{ color: 'text.primary', marginTop: 3 }}></Skeleton>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
        </Box>
        <Skeleton variant="text" sx={{ color: 'text.primary', marginTop: 3 }}></Skeleton>
        <Box style={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: 'calc(50% - 5px)' }}>
            <Skeleton variant="text" sx={{ color: 'text.secondary', marginTop: 3 }}></Skeleton>
            <Skeleton variant="text" sx={{ color: 'theme.palette.primary.main' }}></Skeleton>
          </Box>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', marginTop: 4 }}>
          <Skeleton variant="text"></Skeleton>
          <Skeleton variant="text"></Skeleton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SkeletonSidebar);
