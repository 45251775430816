import { Tabs as MUITabs } from '@mui/material';
import { FC } from 'react';

import TabItem from './TabItem';

type TTabProps = {
  value: number;
  onChange: any;
  tabValues: any[];
  color?: string;
};

export const Tabs: FC<TTabProps> = (props) => {
  const { value, onChange, tabValues, color = 'main' } = props;

  return (
    <MUITabs
      sx={{ ' & .MuiTabs-indicator': { display: 'none' }, minHeight: 'auto' }}
      value={value}
      onChange={onChange}
      aria-label="basic tabs example">
      {tabValues.map((tab, index) => {
        return <TabItem key={tab} data-testid={'tab-' + index} label={tab} color={color ?? 'primary'} value={index} />;
      })}
    </MUITabs>
  );
};

export default Tabs;
