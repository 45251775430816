import { ISpecificationState, ISpecificationAction, IDropDownState } from '../Specification/specificationInterface';
import {
  LOAD_OFFER_SPECIFICATION,
  SET_IS_SPECIFICATION_LOADING,
  DESELECT_OFFER_SPECIFICATION_NODE,
  SELECT_OFFER_SPECIFICATION_UPDATED_ITEM,
  RESET_OFFER_SPECIFICATION,
  SELECT_OFFER_SPECIFICATION_NODE,
  SELECT_NODE_ROW,
  GET_SPECIFICATION_OFFER_SETTING,
  UPDATE_MULTI_SELECTED_NODES,
  LOAD_ACCUMULATED_LIST,
  SET_EMAILS_TO_CORDEL_2,
  LOAD_CUSTOMER_EMAILS_FOR_SELECTED_OFFER,
  SET_NODE_SESSION_ID,
  LOAD_ADJUSTABLE_ITEMS,
  LOAD_OFFER_PROPERTIES,
  UPDATE_DROP_DOWN_STATE,
  RESET_OFFER_SPECIFICATION_STATE,
} from './offerSpecificationActions';

const initialState: ISpecificationState = {
  isLoading: false,
  specificationRoot: null,
  openDropDownItems: [],
  selectedNode: null,
  updatedNode: null,
  multiSelectedNodes: [],
  selectedRow: null,
  offerSettingItem: null,
  accumulatedList: [],
  emailAddresses: [],
  selectedEmails: [],
  sessionId: null,
  adjustableItems: [],
  offerProperties: [],
};

const offerSpecificationReducer = (state = initialState, { type, payload }: ISpecificationAction) => {
  const newState = () => ({ ...state, ...payload });
  const updateDropDownState = () => {
    let openDropDownItems;
    const { itemId, isOpen } = payload as IDropDownState;
    if (isOpen) {
      openDropDownItems = [...state.openDropDownItems, itemId];
    } else {
      openDropDownItems = state.openDropDownItems.filter((id) => id !== itemId);
    }
    return { ...state, openDropDownItems };
  };

  const resetState = () => initialState;

  const stateActions = {
    [LOAD_OFFER_SPECIFICATION]: newState,
    [SET_IS_SPECIFICATION_LOADING]: newState,
    [DESELECT_OFFER_SPECIFICATION_NODE]: newState,
    [SELECT_OFFER_SPECIFICATION_UPDATED_ITEM]: newState,
    [RESET_OFFER_SPECIFICATION]: newState,
    [SELECT_OFFER_SPECIFICATION_NODE]: newState,
    [SELECT_NODE_ROW]: newState,
    [GET_SPECIFICATION_OFFER_SETTING]: newState,
    [UPDATE_MULTI_SELECTED_NODES]: newState,
    [LOAD_ACCUMULATED_LIST]: newState,
    [SET_EMAILS_TO_CORDEL_2]: newState,
    [LOAD_CUSTOMER_EMAILS_FOR_SELECTED_OFFER]: newState,
    [SET_NODE_SESSION_ID]: newState,
    [LOAD_ADJUSTABLE_ITEMS]: newState,
    [LOAD_OFFER_PROPERTIES]: newState,
    [UPDATE_DROP_DOWN_STATE]: updateDropDownState,
    [RESET_OFFER_SPECIFICATION_STATE]: resetState,
  };

  const currentState = stateActions[type];
  if (!currentState) {
    return state;
  }
  return currentState();
};

export default offerSpecificationReducer;
