import { FC } from 'react';

type DividedCircleIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  background?: string;
  [x: string]: any;
};

export const ProductTools: FC<DividedCircleIconPropTypes> = ({ width = 60, height = 60, color = '#32226B', background = '#FFF', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={width} height={height} rx="14" fill={background}/>
        <path d="M34.88 31.3132L32.2284 33.9648L39.7237 41.4601L42.3753 38.8084L34.88 31.3132Z" fill={color}/>
        <path d="M36.875 27.5C39.2875 27.5 41.25 25.5375 41.25 23.125C41.25 22.4 41.05 21.725 40.7375 21.125L37.3625 24.5L35.5 22.6375L38.875 19.2625C38.275 18.95 37.6 18.75 36.875 18.75C34.4625 18.75 32.5 20.7125 32.5 23.125C32.5 23.6375 32.6 24.125 32.7625 24.575L30.45 26.8875L28.225 24.6625L29.1125 23.775L27.35 22.0125L30 19.3625C28.5375 17.9 26.1625 17.9 24.7 19.3625L20.275 23.7875L22.0375 25.55H18.5125L17.625 26.4375L22.05 30.8625L22.9375 29.975V26.4375L24.7 28.2L25.5875 27.3125L27.8125 29.5375L18.55 38.8L21.2 41.45L35.425 27.2375C35.875 27.4 36.3625 27.5 36.875 27.5Z" fill={color}/>
    </svg>
  );
};

export default ProductTools;
