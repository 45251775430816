import CloseIcon from '@mui/icons-material/Close';

import { Dialog, DialogActions, LinearProgress, IconButton, DialogProps, SxProps } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/StyledMUI';

import { useModuleList } from 'modules/common';

export interface IDialogWrapper extends DialogProps {
  children: ReactNode;
  open: boolean;
  onConfirm?: () => void;
  showClose?: boolean;
  isDialogLoading?: boolean;
  closeHandler?: () => void;
  hideActions?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  confirmText?: string;
  cancelText?: string;
  disableConfirmButton?: boolean;
  hideCloseIcon?: boolean;
  spaceBetweenActions?: boolean;
}

const closeIconStyles = {
  position: 'absolute',
  right: 16,
  top: 16,
  zIndex: 10,
};

export const DialogWrapper = (props: IDialogWrapper) => {
  const { t } = useTranslation();
  const { closeDialog: clearDialog } = useModuleList();
  const {
    children,
    onConfirm,
    closeHandler,
    open = false,
    maxWidth = 'sm',
    hideCloseIcon = false,
    showClose = false,
    isDialogLoading = false,
    hideActions = false,
    buttonSize = 'large',
    confirmText = t('globals.confirm'),
    cancelText = t('globals.cancel'),
    disableConfirmButton = false,
    scroll,
    spaceBetweenActions = false,
    ...rest
  } = props;

  const actionStyles = useMemo(() => {
    let styles: SxProps = { px: 3, py: 2 };
    if (spaceBetweenActions) {
      styles.justifyContent = 'space-between';
    }
    return styles;
  }, [spaceBetweenActions]);

  const closeDialog = () => {
    if (closeHandler) {
      closeHandler();
    } else {
      clearDialog();
    }
  };

  return (
    <Dialog fullWidth open={open} maxWidth={maxWidth} {...rest} scroll={scroll}>
      {isDialogLoading ? <LinearProgress color="secondary" /> : null}
      {!hideCloseIcon && (
        <IconButton size="small" color="primary" aria-label="close" onClick={closeDialog} sx={closeIconStyles}>
          <CloseIcon />
        </IconButton>
      )}
      {children}
      {!hideActions && (
        <DialogActions sx={actionStyles}>
          {showClose && (
            <Button variant="outlined" onClick={closeDialog} size={buttonSize}>
              {cancelText}
            </Button>
          )}
          {onConfirm && (
            <Button
              type="button"
              size={buttonSize}
              variant="contained"
              data-testid={'confirm-dialogue'}
              onClick={onConfirm}
              disabled={disableConfirmButton}>
              {confirmText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogWrapper;
