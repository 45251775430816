import { FC } from 'react';

type CubePropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Cube: FC<CubePropTypes> = ({ width = 57, height = 56, color = 'none', ...rest }) => {
  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill={color} xmlns="http://www.w3.org/2000/svg">
      <circle cx="28.7891" cy="28" r="28" fill="#F4F7FE" />
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M16.4204 29.5139H27.3676V15.8298H16.4204V29.5139ZM16.4204 40.4611H27.3676V32.2507H16.4204V40.4611ZM30.1044 40.4611H41.0517V26.7771H30.1044V40.4611ZM30.1044 15.8298V24.0403H41.0517V15.8298H30.1044Z"
        fill="#0774A4"
      />
    </svg>
  );
};

export default Cube;
