import {
  MapsHomeWork,
  SettingsApplications,
  Group,
  ControlCamera,
  ExpandMore,
  ExpandLess,
  Tune,
  StickyNote2,
  AdminPanelSettings,
  MenuBook,
  Dashboard,
  FactCheck,
  AccountTree,
  Construction,
  Inventory2,
  HistoryToggleOff,
  TextFields,
  Store,
  ListAlt,
  Workspaces,
  RecentActors,
  Apartment,
  HomeRepairService,
  Business,
  VerifiedUserOutlined,
  TableView,
  WidgetsOutlined,
  ManageAccountsOutlined,
} from '@mui/icons-material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import { Box, List, Collapse, Typography } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { ListItemButtonStyled, ListItemIconStyled } from './LayoutStyles';

import { MOMTemplate } from 'components/Icons';

import {
  CHECKLISTS_CODE,
  CRM_CODE,
  MANUALS_CODE,
  OFFERS_CODE,
  PRODUCTS_CODE,
  PROJECTS_CODE,
  SITES_CODE,
  GLOBAL_TENANT_ADMINISTRATOR_CODE,
  PACKAGES_CODE,
  PIECEWORK_CODE,
  TEXTS_CODE,
  BUILDINGELEMENTS_CODE,
  NSCODES_CODE,
  LOCAL_TENANT_ADMINISTRATOR_CODE,
  FDV_CODE,
} from 'model/utils/moduleDefinitions';
import { canUserView } from 'utils/authentication/RoleWrapper';
import { getRootUrl, getSubRootUrl } from 'utils/helpers';

export interface INavigationLinksProps {
  isAppDrawerOpen: boolean;
}

const NavigationLinks = (props: INavigationLinksProps): ReactElement => {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState('none');
  const [open, setOpen] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openManuals, setOpenManuals] = useState(false);
  const { t } = useTranslation();

  const { isAppDrawerOpen } = props;

  const toggleBasicRegister = () => {
    setOpen(!open);
  };

  const toggleAdminPanel = () => {
    setOpenAdmin(!openAdmin);
  };

  const toggleManuals = () => {
    setOpenManuals(!openManuals);
  };

  useEffect(() => {
    const link = pathname.includes('/v1') ? '/v1' + getSubRootUrl(pathname) : getRootUrl(pathname);
    setActiveLink(link);
  }, [pathname]);

  return (
    <Box>
      <List component="div">
        <ListItemButtonStyled to={'/'} selected={activeLink === '/'} component={NavLink}>
          <ListItemIconStyled>
            <Dashboard color="primary" />
          </ListItemIconStyled>
          <Typography color="text.primary">{t('globals.dashboard')}</Typography>
        </ListItemButtonStyled>

        {canUserView({ moduleCode: OFFERS_CODE, role: 'r' }) && (
          <ListItemButtonStyled to={'/offers'} selected={activeLink === '/offers'} component={NavLink}>
            <ListItemIconStyled>
              <StickyNote2 color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.offers')}</Typography>
          </ListItemButtonStyled>
        )}

        {canUserView({ moduleCode: OFFERS_CODE, role: 'r' }) && (
          <ListItemButtonStyled to={'/offer_settings'} selected={activeLink === '/offer_settings'} component={NavLink}>
            <ListItemIconStyled>
              <SettingsApplications color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.offerSettings')}</Typography>
          </ListItemButtonStyled>
        )}

        {canUserView({ moduleCode: PROJECTS_CODE, role: 'r' }) && (
          <ListItemButtonStyled to={'/projects'} selected={activeLink === '/projects'} component={NavLink}>
            <ListItemIconStyled>
              <AccountTree color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.projects')}</Typography>
          </ListItemButtonStyled>
        )}

        {/* {canUserView({ moduleCode: PROJECTS_CODE, role: 'r' }) && (
          <ListItemButtonStyled to={'/v1/projects'} selected={activeLink === '/v1/projects'} component={NavLink}>
            <ListItemIconStyled>
              <AccountTree color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.projects')}</Typography>
          </ListItemButtonStyled>
        )} */}

        {canUserView({ moduleCode: CHECKLISTS_CODE, role: 'r' }) && (
          <ListItemButtonStyled
            to={'/v1/checklistTemplates'}
            selected={activeLink === '/v1/checklistTemplates'}
            component={NavLink}>
            <ListItemIconStyled>
              <FactCheck color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.checklistTemplates')}</Typography>
          </ListItemButtonStyled>
        )}

        {canUserView({ moduleCode: FDV_CODE, role: 'r' }) && (
          <ListItemButtonStyled to={'/momtemplates'} selected={activeLink === '/momtemplates'} component={NavLink}>
            <ListItemIconStyled>
              <MOMTemplate />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.momTemplates')}</Typography>
          </ListItemButtonStyled>
        )}

        {canUserView({ moduleCode: MANUALS_CODE, role: 'r' }) && (
          <ListItemButtonStyled color="primary" onClick={toggleManuals}>
            <ListItemIconStyled>
              <MenuBook color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.manuals')}</Typography>
            {openManuals ? (
              <ExpandLess sx={{ color: 'text.primary' }} />
            ) : (
              <ExpandMore sx={{ color: 'text.primary' }} />
            )}
          </ListItemButtonStyled>
        )}

        {canUserView({ moduleCode: MANUALS_CODE, role: 'r' }) && (
          <Collapse in={openManuals} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButtonStyled
                hasPadding
                to={'/v1/manuals'}
                selected={activeLink === '/v1/manuals'}
                component={NavLink}>
                <Typography>{t('globals.manualRegister')}</Typography>
              </ListItemButtonStyled>
              <ListItemButtonStyled
                hasPadding
                to={'/v1/manualDrafts'}
                selected={activeLink === '/v1/manualDrafts'}
                component={NavLink}>
                <Typography>{t('globals.manualDraft')}</Typography>
              </ListItemButtonStyled>
            </List>
          </Collapse>
        )}

        <ListItemButtonStyled onClick={toggleBasicRegister}>
          <ListItemIconStyled>
            <Tune color="primary" />
          </ListItemIconStyled>
          <Typography color="text.primary">{t('globals.basicRegister')}</Typography>
          {open ? <ExpandLess sx={{ color: 'text.primary' }} /> : <ExpandMore sx={{ color: 'text.primary' }} />}
        </ListItemButtonStyled>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {canUserView({ moduleCode: OFFERS_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/adjustments'}
                selected={activeLink === '/adjustments'}
                component={NavLink}>
                <ListItemIconStyled>
                  <ControlCamera sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('offers.collectiveAdjustments')}</Typography>
              </ListItemButtonStyled>
            )}
            {/* NO LONGER USED SINCE 17.08.2023 */}
            {/* VALID TEMPORARILY */}
            {canUserView({ moduleCode: CRM_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/companies'}
                selected={activeLink === '/companies'}
                component={NavLink}>
                <ListItemIconStyled>
                  <Apartment sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('companies.companies')}</Typography>
              </ListItemButtonStyled>
            )}
            {/* NO LONGER USED SINCE 17.08.2023 */}
            {/* VALID TEMPORARILY */}
            {canUserView({ moduleCode: CRM_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/customers'}
                selected={activeLink === '/customers'}
                component={NavLink}>
                <ListItemIconStyled>
                  <RecentActors sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('customers.customers')}</Typography>
              </ListItemButtonStyled>
            )}

            {canUserView({ moduleCode: SITES_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/v1/sites'}
                selected={activeLink === '/v1/sites'}
                component={NavLink}>
                <ListItemIconStyled>
                  <MapsHomeWork sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('globals.site')}</Typography>
              </ListItemButtonStyled>
            )}

            {canUserView({ moduleCode: PACKAGES_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/packages'}
                selected={activeLink === '/packages'}
                component={NavLink}>
                <ListItemIconStyled>
                  <Inventory2 sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('offers.packages')}</Typography>
              </ListItemButtonStyled>
            )}

            {canUserView({ moduleCode: BUILDINGELEMENTS_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/building_elements'}
                selected={activeLink === '/building_elements'}
                component={NavLink}>
                <ListItemIconStyled>
                  <ListAlt sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('buildingElements.buildingElements')}</Typography>
              </ListItemButtonStyled>
            )}

            {canUserView({ moduleCode: PIECEWORK_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/v1/piecework'}
                selected={activeLink === '/v1/piecework'}
                component={NavLink}>
                <ListItemIconStyled>
                  <HistoryToggleOff sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('offers.pieceWorkRate')}</Typography>
              </ListItemButtonStyled>
            )}

            {canUserView({ moduleCode: PRODUCTS_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/products'}
                selected={activeLink === '/products'}
                component={NavLink}>
                <ListItemIconStyled>
                  <Construction sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('products.products')}</Typography>
              </ListItemButtonStyled>
            )}

            {canUserView({ moduleCode: NSCODES_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/nscodes'}
                selected={activeLink === '/nscodes'}
                component={NavLink}>
                <ListItemIconStyled>
                  <Workspaces sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('nsCodes.nsCode')}</Typography>
              </ListItemButtonStyled>
            )}
            {/* NO LONGER USED SINCE 17.08.2023 */}
            {/* VALID TEMPORARILY */}
            {canUserView({ moduleCode: CRM_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/persons'}
                selected={activeLink === '/persons'}
                component={NavLink}>
                <ListItemIconStyled>
                  <Group sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('persons.persons')}</Typography>
              </ListItemButtonStyled>
            )}

            {/* {canUserView({ moduleCode: CRM_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/crm'}
                selected={activeLink === '/crm'}
                component={NavLink}>
                <ListItemIconStyled>
                  <Group sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>CRM</Typography>
              </ListItemButtonStyled>
            )} */}

            {canUserView({ moduleCode: TEXTS_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/v1/stdText'}
                selected={activeLink === '/v1/stdText'}
                component={NavLink}>
                <ListItemIconStyled>
                  <TextFields sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('globals.stdText')}</Typography>
              </ListItemButtonStyled>
            )}

            {canUserView({ moduleCode: CRM_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/suppliers'}
                selected={activeLink === '/suppliers'}
                component={NavLink}>
                <ListItemIconStyled>
                  <Store sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('products.suppliers')}</Typography>
              </ListItemButtonStyled>
            )}
            {/* NO LONGER USED SINCE 17.08.2023 */}
            {/* VALID TEMPORARILY */}
            {canUserView({ moduleCode: CRM_CODE, role: 'r' }) && (
              <ListItemButtonStyled
                sx={{ pl: isAppDrawerOpen ? 5 : 2 }}
                hasPadding
                to={'/vendors'}
                selected={activeLink === '/vendors'}
                component={NavLink}>
                <ListItemIconStyled>
                  <HomeRepairService sx={{ color: 'text.secondary' }} />
                </ListItemIconStyled>
                <Typography>{t('vendors.vendors')}</Typography>
              </ListItemButtonStyled>
            )}
          </List>
        </Collapse>

        {canUserView({ moduleCode: GLOBAL_TENANT_ADMINISTRATOR_CODE, role: 'r' }) && (
          <ListItemButtonStyled onClick={toggleAdminPanel}>
            <ListItemIconStyled>
              <AdminPanelSettings color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.administration')}</Typography>
            {openAdmin ? <ExpandLess sx={{ color: 'text.primary' }} /> : <ExpandMore sx={{ color: 'text.primary' }} />}
          </ListItemButtonStyled>
        )}

        <Collapse in={openAdmin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButtonStyled
              hasPadding
              to={'/v1/licenses'}
              selected={activeLink === '/v1/licenses'}
              component={NavLink}
              sx={{ pl: isAppDrawerOpen ? 5 : 2 }}>
              <ListItemIconStyled>
                <VerifiedUserOutlined sx={{ color: 'text.secondary' }} />
              </ListItemIconStyled>
              <Typography>{t('globals.licenses')}</Typography>
            </ListItemButtonStyled>
            <ListItemButtonStyled
              hasPadding
              to={'/v1/modulePackages'}
              selected={activeLink === '/v1/modulePackages'}
              component={NavLink}
              sx={{ pl: isAppDrawerOpen ? 5 : 2 }}>
              <ListItemIconStyled>
                <WidgetsOutlined sx={{ color: 'text.secondary' }} />
              </ListItemIconStyled>
              <Typography>{t('globals.modulePackages')}</Typography>
            </ListItemButtonStyled>
            <ListItemButtonStyled
              hasPadding
              to={'/v1/organizations'}
              selected={activeLink === '/v1/organizations'}
              component={NavLink}
              sx={{ pl: isAppDrawerOpen ? 5 : 2 }}>
              <ListItemIconStyled>
                <Business sx={{ color: 'text.secondary' }} />
              </ListItemIconStyled>
              <Typography>{t('globals.organizations')}</Typography>
            </ListItemButtonStyled>
            <ListItemButtonStyled
              hasPadding
              to={'/v1/tenants'}
              selected={activeLink === '/v1/tenants'}
              component={NavLink}
              sx={{ pl: isAppDrawerOpen ? 5 : 2 }}>
              <ListItemIconStyled>
                <TableView sx={{ color: 'text.secondary' }} />
              </ListItemIconStyled>
              <Typography>{t('globals.tenants')}</Typography>
            </ListItemButtonStyled>
            <ListItemButtonStyled
              hasPadding
              to={'/v1/users'}
              selected={activeLink === '/v1/users'}
              component={NavLink}
              sx={{ pl: isAppDrawerOpen ? 5 : 2 }}>
              <ListItemIconStyled>
                <ManageAccountsOutlined sx={{ color: 'text.secondary' }} />
              </ListItemIconStyled>
              <Typography>{t('globals.users')}</Typography>
            </ListItemButtonStyled>
          </List>
        </Collapse>

        {canUserView({ moduleCode: LOCAL_TENANT_ADMINISTRATOR_CODE, role: 'r' }) && (
          <ListItemButtonStyled to={'/adminBoard'} selected={activeLink === '/adminBoard'} component={NavLink}>
            <ListItemIconStyled>
              <SettingsApplicationsIcon color="primary" />
            </ListItemIconStyled>
            <Typography color="text.primary">{t('globals.adminBoard')}</Typography>
          </ListItemButtonStyled>
        )}
      </List>
    </Box>
  );
};

export default NavigationLinks;
