import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useModuleList } from 'modules/common';

function AppAlerts() {
  const { enqueueSnackbar } = useSnackbar();
  const { useAppSelector } = useModuleList({});
  const { appAlerts = [] } = useAppSelector((state) => state);

  useEffect(() => {
    appAlerts.forEach(({ id, type, message, duration }) => {
      enqueueSnackbar(message, {
        key: id,
        variant: type,
        autoHideDuration: duration,
        disableWindowBlurListener: true,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        preventDuplicate: true,
      });
    });
  }, [appAlerts.length, enqueueSnackbar]); // eslint-disable-line
  return <></>;
}

export default AppAlerts;
