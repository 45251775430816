import { IMomPdfState, IMomPdfAction } from '../interfaces/ProjectInterfaces';
import { IS_PDF_LOADING, SAVE_PREVIEW_PDF_BLOB } from './MomPdfActions';

const defaultState: IMomPdfState = {
  blob: null,
  isLoading: false,
};

const MomPdfReducer = (state = defaultState, { type, payload }: IMomPdfAction) => {
  const newState = () => ({ ...state, ...payload });

  const stateActions = {
    [SAVE_PREVIEW_PDF_BLOB]: newState,
    [IS_PDF_LOADING]: newState,
  };

  const currentState = stateActions[type];
  if (!currentState) return state;
  return currentState();
};

export default MomPdfReducer;
