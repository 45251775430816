import { FC } from 'react';

type TagIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  bgColor?: string;
  [x: string]: any;
};

export const TagIcon: FC<TagIconPropTypes> = ({
  width = 34,
  height = 34,
  color = '#32226B',
  bgColor = '#F3EEFE',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill={bgColor} />
      <path
        d="M20.7533 12.8933C20.5133 12.5533 20.1133 12.3333 19.6667 12.3333L12.3333 12.34C11.6 12.34 11 12.9333 11 13.6666V20.3333C11 21.0666 11.6 21.66 12.3333 21.66L19.6667 21.6666C20.1133 21.6666 20.5133 21.4466 20.7533 21.1066L23.6667 17L20.7533 12.8933Z"
        fill={color}
      />
    </svg>
  );
};

export default TagIcon;
