import { IAppMessage, ADD_MESSAGE, REMOVE_MESSAGE } from './AppAlertsActions';

interface IProps {
  type: string;
  payload: IAppMessage;
}

const AppAlerts = (state = [], { payload, type }: IProps) => {
  switch (type) {
    case ADD_MESSAGE: {
      return [...state, payload];
    }
    case REMOVE_MESSAGE: {
      return state.filter((item: IAppMessage) => {
        return item.id !== payload.id;
      });
    }
    default:
      return state;
  }
};

export default AppAlerts;
