import { ICompanyItem, ICompaniesLoadResponse, ICompanyContactItem, IPersonItem } from '../companiesInterfaces';
export const CompanyInitial: ICompanyItem = {
  id: '',
  address: '',
  contacts: [
    {
      companyId: '',
      id: '',
      created: '',
      createdBy: '',
      lastUpdated: '',
      lastUpdatedBy: '',
      personId: '',
      role: '',
    },
  ],
  country: '',
  created: '',
  createdBy: '',
  deleted: true,
  lastUpdated: '',
  lastUpdatedBy: '',
  name: '',
  organizationNumber: '',
  postalAddress: '',
  postalCode: '',
  telephoneNumbers: [
    {
      created: '',
      createdBy: '',
      description: '',
      id: '',
      lastUpdated: '',
      lastUpdatedBy: '',
      number: '',
      primary: false,
    },
  ],
  emailAddresses: [
    {
      email: '',
      created: '',
      createdBy: '',
      description: '',
      id: '',
      lastUpdated: '',
      lastUpdatedBy: '',
      primary: false,
    },
  ],
};

export const CompaniessListInitial: ICompaniesLoadResponse = {
  payload: [{ ...CompanyInitial }],
  message: '',
  serviceTime: '',
  statusCode: 0,
  pagingInfo: {
    defaultPageSize: 0,
    maxPageSize: 0,
  },
};

export const PersonInitial: IPersonItem = {
  firstName: '',
  lastName: '',
  address: '',
  country: '',
  created: '',
  createdBy: '',
  deleted: false,
  id: '',
  lastUpdated: '',
  lastUpdatedBy: '',
  postalAddress: '',
  postalCode: '',
  telephoneNumbers: [
    {
      created: '',
      createdBy: '',
      description: '',
      id: '',
      lastUpdated: '',
      lastUpdatedBy: '',
      number: '',
      primary: false,
    },
  ],
  emailAddresses: [
    {
      email: '',
      created: '',
      createdBy: '',
      description: '',
      id: '',
      lastUpdated: '',
      lastUpdatedBy: '',
      primary: false,
    },
  ],
};

export const CompanyContacItemInitial: ICompanyContactItem = {
  companyId: '',
  id: '',
  created: '',
  createdBy: '',
  lastUpdated: '',
  lastUpdatedBy: '',
  role: '',
  person: {
    firstName: '',
    lastName: '',
    address: '',
    country: '',
    created: '',
    createdBy: '',
    deleted: false,
    id: '',
    lastUpdated: '',
    lastUpdatedBy: '',
    postalAddress: '',
    postalCode: '',
    telephoneNumbers: [
      {
        created: '',
        createdBy: '',
        description: '',
        id: '',
        lastUpdated: '',
        lastUpdatedBy: '',
        number: '',
        primary: false,
      },
    ],
    emailAddresses: [
      {
        email: '',
        created: '',
        createdBy: '',
        description: '',
        id: '',
        lastUpdated: '',
        lastUpdatedBy: '',
        primary: false,
      },
    ],
  },
};
