import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type AddCircleIconPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const AddCircleIcon: FC<AddCircleIconPropTypes> = ({ width = 30, height = 30, color = '#fff', ...rest }) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);

  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10.5" cy="10.3723" rx="10.5" ry="10.3723" fill="0774A4" />
      <path
        d="M6.16033 11.0467V9.18674H9.42825V6.17956H11.3229V9.18674H14.5735V11.0467H11.3229V14.0539H9.42825V11.0467H6.16033Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default AddCircleIcon;
