import {
  LOAD_ALL_OFFERS,
  LOAD_USERS,
  SAVE_TOTAL_OFFERS,
  SAVE_TOTAL_USERS,
  SET_HAS_MORE_ITEMS,
} from './adminBoardActions';
import { IAdminBoardAction, IAdminBoardState } from './types';

const defaultState: IAdminBoardState = {
  users: [],
  offers: [],
  selectedItems: null,
  totalItems: 0,
  isLoading: false,
  hasMoreItems: false,
  isDialogLoading: false,
};

const adminBoard = (state = defaultState, { type, payload, overwrite }: IAdminBoardAction) => {
  switch (type) {
    case SAVE_TOTAL_USERS:
    case SAVE_TOTAL_OFFERS:
      return { ...state, totalItems: payload };

    case LOAD_ALL_OFFERS:
      return { ...state, offers: payload };

    case LOAD_USERS:
      return { ...state, users: payload };

    case SET_HAS_MORE_ITEMS:
      return { ...state, hasMoreItems: payload };
  }
  return state;
};

export default adminBoard;
