import {
    LOAD_ALL_PIECEWORKS,
    LOAD_ALL_CATEGORIES,
    LOAD_ONE_PIECEWORK,
    LOAD_ALL_TRADES,
    LOAD_LIST_OF_PIECEWORKS,
    IS_PIECEWORK_LOADING,
    LOAD_SEARCHED_PIECEWORKS,
    LOAD_TOTAL_PIECEWORK_COUNT,
    SAVE_SEARCHED_PIECEWORKS,
} from './PieceworkActions';
import { IPieceworkAction, IPieceworkState } from './PieceworkInterface';

const defaultState: IPieceworkState = {
    categories: [],
    trades: [],
    pieceworks: [],
    searchedItems: [],
    searchedPieceworks: { hits: [], totalHits: 0 },
    totalPieceworks: 0,
    maxPageSize: 0,
    defaultPageSize: 0,
    selectedItem: null,
    allowedModules: [],
    deletedItems: [],
    isLoading: false,
    isDialogLoading: false,
};

export const catalogs = (state = defaultState, action: IPieceworkAction) => {
const { type, payload } = action;

switch (type) {
    case IS_PIECEWORK_LOADING:
        return { ...state, isLoading: payload };
    case LOAD_ALL_CATEGORIES:
        return { ...state, categories: payload };
    case LOAD_ALL_PIECEWORKS:
        return { ...state, pieceworks: payload };
    case LOAD_ALL_TRADES:
        return {...state, trades: payload };
    case LOAD_LIST_OF_PIECEWORKS:
        return {...state, pieceworks: payload };
    case LOAD_ONE_PIECEWORK:
        return { ...state, selectedItem: payload };
    case LOAD_SEARCHED_PIECEWORKS:
        return { ...state, searchedItems: payload };
    case LOAD_TOTAL_PIECEWORK_COUNT:
        return { ...state, totalPieceworks: payload };
    case SAVE_SEARCHED_PIECEWORKS:
        return { ...state, searchedPieceworks: payload };
}
    return state;
};

export default catalogs;