import { Box, styled } from '@mui/material';

export const AppStyled = styled(Box)`
  display: flex;
  min-height: 100vh;
`;

export const AppRoutesStyled = styled(Box)<any>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
