import { combineReducers } from 'redux';

import adminBoardModule from 'modules/AdminBoard/redux/adminBoardReducer';
import offerTypesModule from 'modules/AdminBoard/redux/offerTypesReducer';
import appAlerts from 'modules/AppAlerts/AppAlertsReducer';
import buildingElementsModule from 'modules/BuildingElements/redux/buildingElementsReducer';
import adjustmentsModule from 'modules/CollectiveAdjustments/redux/adjustmentsReducer';
import companiesModule from 'modules/Companies/redux/companiesReducer';
import customersModule from 'modules/Customers/redux/CustomersReducer';
import momTemplateCatalogsModule from 'modules/MOMTemplates/redux/Catalogs/catalogsReducer';
import momTemplatesModule from 'modules/MOMTemplates/redux/Templates/templatesReducer';
import nsCodesModule from 'modules/NSCodes/redux/nsCodesReducer';
import offerSettingsModule from 'modules/OfferSettings/redux/OfferSettingsReducer';
import documentsModule from 'modules/Offers/DocumentTab/redux/documentsReducer';
import offerSpecificationModule from 'modules/Offers/redux/offerSpecificationReducer';
import offersModule from 'modules/Offers/redux/offersReducer';
import specificationItemsModule from 'modules/Offers/redux/specificationItemsReducer';
import packageSpecificationItemsModule from 'modules/Packages/redux/packageSpecificationItemsReducer';
import packagesModule from 'modules/Packages/redux/packagesReducer';
import personsModule from 'modules/Persons/redux/personsReducer';
import pieceworkModule from 'modules/Piecework/redux/PieceworkReducer';
import productsCatalogsModule from 'modules/Products/redux/CatalogsReducer';
import productsModule from 'modules/Products/redux/ProductsReducer';
import projectDocumentsModule from 'modules/Projects/ProjectDetails/Documents/redux/ProjectDocumentsReducer';
import momPdfModule from 'modules/Projects/redux/MomPdfReducer';
import momSidebarModule from 'modules/Projects/redux/MomSidebarReducer';
import momTreeModule from 'modules/Projects/redux/MomTreeReducer';
import projectsMomModule from 'modules/Projects/redux/ProjectMOMListReducer';
import projectsModule from 'modules/Projects/redux/ProjectsReducer';
import suppliersModule from 'modules/Suppliers/redux/SuppliersReducer';
import userModule from 'modules/User/redux/userReducer';
import vendorsModule from 'modules/Vendors/redux/vendorsReducer';
import { isDialogLoading, isLoading } from 'modules/common/commonReducer';

const reducer = combineReducers({
  isLoading,
  isDialogLoading,
  productsCatalogsModule,
  productsModule,
  personsModule,
  companiesModule,
  appAlerts,
  customersModule,
  suppliersModule,
  vendorsModule,
  userModule,
  offersModule,
  offerSettingsModule,
  specificationItemsModule,
  offerSpecificationModule,
  adjustmentsModule,
  buildingElementsModule,
  pieceworkModule,
  documentsModule,
  nsCodesModule,
  packagesModule,
  adminBoardModule,
  packageSpecificationItemsModule,
  offerTypesModule,
  projectsMomModule,
  momTemplateCatalogsModule,
  momTemplatesModule,
  projectsModule,
  projectDocumentsModule,
  momTreeModule,
  momSidebarModule,
  momPdfModule,
});
export default reducer;
