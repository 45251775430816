import { FC } from 'react';

type ChartPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Chart: FC<ChartPropTypes> = ({
  width = 70,
  height = 45,
  color = 'url(#paint0_linear_409_10732)',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 81 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M2 41.5C2 41.5 8.27975 -6.00537 24 16C39.7203 38.0054 46 36.9946 52 20C59.4168 -1.0075 86.758 17.0806 89.5 2"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_409_10732"
          x1="-1.50002"
          y1="46.5"
          x2="70.5778"
          y2="-16.9144"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" stopOpacity="0.19" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Chart;
