import { FC } from 'react';

type FolderPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Folder: FC<FolderPropTypes> = ({ width = 16, height = 16, color = '#046B99', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66671 2.66675H2.66671C1.93337 2.66675 1.34004 3.26675 1.34004 4.00008L1.33337 12.0001C1.33337 12.7334 1.93337 13.3334 2.66671 13.3334H13.3334C14.0667 
        13.3334 14.6667 12.7334 14.6667 12.0001V5.33341C14.6667 4.60008 14.0667 4.00008 13.3334 4.00008H8.00004L6.66671 2.66675Z"
        fill={color}
      />
    </svg>
  );
};

export default Folder;
