import { useTheme } from '@mui/material/styles';
import { FC } from 'react';

import { resolveThemeColor } from 'utils/helpers';

type EditPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  [x: string]: any;
};

export const Edit: FC<EditPropTypes> = ({ width = 22, height = 22, color = '#5A6E81', ...rest }) => {
  const theme = useTheme();

  const resolvedColor = resolveThemeColor(color, theme);
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ transition: 'all 0.3s ease-in-out' }}
        d="M2.75 15.8125V19.25H6.1875L16.3258 9.1117L12.8883 5.6742L2.75 15.8125ZM18.9842 6.45337C19.3417 6.09587 19.3417 5.51837 18.9842 5.16087L16.8392 3.01587C16.4817 2.65837 15.9042 2.65837 15.5467 3.01587L13.8692 4.69337L17.3067 8.13087L18.9842 6.45337Z"
        fill={resolvedColor}
      />
    </svg>
  );
};

export default Edit;
