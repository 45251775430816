import i18n from 'i18next';

import { IProject, IProjectSite } from '../interfaces/ProjectInterfaces';

import { addErrorMessage } from 'modules/AppAlerts/AppAlertsActions';
import API from 'utils/API';

export const IS_PROJECT_LOADING = 'IS_PROJECT_LOADING';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const SET_INTERNAL_PROJECT_SITE = 'SET_INTERNAL_PROJECT_SITE';
export const GET_PROJECT_DOCUMENTS_ROOT = 'GET_PROJECT_DOCUMENTS_ROOT';

const setIsLoading = (isLoading: boolean) => ({ type: IS_PROJECT_LOADING, payload: { isLoading } });

export const setInternalProjectSite = (site: IProjectSite | null) => ({
  type: SET_INTERNAL_PROJECT_SITE,
  payload: { site },
});

const setSelectedProject = (selectedItem: IProject) => ({ type: SET_SELECTED_PROJECT, payload: { selectedItem } });

export const getProjectById = (projectId: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setIsLoading(true));
      const URL = `/projects/api/projects/${projectId}`;
      const {
        data: { payload },
      } = await API.get(URL);
      dispatch(setSelectedProject(payload));
    } catch (e) {
      const message = i18n.t('projects.errorLoadingProject');
      dispatch(addErrorMessage({ message }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
