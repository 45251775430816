import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { Autocomplete, TextField, Checkbox, Chip } from '@mui/material';
import { FC, SyntheticEvent, KeyboardEvent, useMemo } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type OptionBody = {
  name: string;
  id: string;
};

interface IMultiCheckbox {
  onChange: Function;
  onBlur?: Function;
  value: Array<any>;
  name: string;
  label: string;
  placeholder: string;
  error?: boolean;
  options: Array<OptionBody>;
  helperText?: string | any;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  // if exist we return an array of primitive type else we return the selected options as array of selected objects
  returnValue?: string;
  optionKeyLabel: string;
  size?: 'small' | 'medium';
  loading: boolean;
  visibleValuesCount?: number;
}

export const MultiCheckbox: FC<IMultiCheckbox> = (props) => {
  const {
    fullWidth,
    margin,
    options = [],
    value = [],
    onChange = () => {},
    onBlur = () => {},
    name,
    label,
    placeholder,
    error,
    helperText,
    returnValue,
    optionKeyLabel = 'name',
    size = 'medium',
    loading = false,
    visibleValuesCount = 1,
  } = props;

  const localValues = useMemo(() => {
    if (!options.length) return [];
    if (value.length) {
      if (returnValue && Boolean(returnValue)) {
        return options.filter((item) => value.includes(item[returnValue]));
      } else {
        return [...value];
      }
    }
    return [];
  }, [value, returnValue, options]);

  const renderOption = (props: any, option: OptionBody, { selected }: any) => {
    return (
      <li {...props}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
        {option[optionKeyLabel]}
      </li>
    );
  };

  /**
   * modify rendered Chip tags inside the text field
   * @param tagValue
   * @param getTagProps
   */
  const renderTags = (tagValue: Array<OptionBody>, getTagProps: Function) => {
    const filteredItems = tagValue.filter((item, index) => index < visibleValuesCount);
    const count = tagValue.length - filteredItems.length;
    const countString = count > 0 ? `+${count}` : '';

    return (
      <>
        {filteredItems.map((option, index) => {
          return (
            <Chip label={typeof option === 'string' ? option : option[optionKeyLabel]} {...getTagProps({ index })} />
          );
        })}
        <span className="MuiAutocomplete-tag MuiAutocomplete-tagSizeSmall">{countString}</span>
      </>
    );
  };

  /**
   * it returns an array of primitive type else we return the selected options as array of selected objects
   * @param e
   * @param selectedOptions
   */
  const handleChange = (e: SyntheticEvent<Element, Event>, selectedOptions: any) => {
    const value =
      returnValue && Boolean(returnValue)
        ? selectedOptions.map((item: OptionBody) => item[returnValue])
        : selectedOptions;
    onChange({ target: { name, value } });
  };
  const handleBlur = (e: SyntheticEvent<KeyboardEvent | Element>) => {
    if (e.type === 'blur') {
      onBlur({ target: { name, value } });
    }
  };

  const handleIsOptionEqualToValue = (option: OptionBody, value: OptionBody) => {
    if (typeof option !== 'string' && typeof value === 'string') {
      return option[optionKeyLabel] === value;
    }
    if (typeof option === 'string' && typeof value !== 'string') {
      return option === value[optionKeyLabel];
    }
    if (typeof option === 'string' && typeof value === 'string') {
      return option === value;
    }
    return option[optionKeyLabel] === value[optionKeyLabel];
  };

  return (
    <Autocomplete
      data-testId="multiCheckbox"
      multiple
      size={size}
      autoComplete
      limitTags={1}
      loading={loading}
      fullWidth={fullWidth}
      value={localValues}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      renderOption={renderOption}
      renderTags={renderTags}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      getOptionLabel={(option: OptionBody) => (typeof option === 'string' ? option : option[optionKeyLabel])}
      id={`multi-checkbox-select-${label.replace(' ', '-')}`}
      renderInput={(params) => (
        <TextField
          error={error}
          label={label}
          margin={margin}
          placeholder={localValues.length ? '' : placeholder}
          helperText={Array.isArray(helperText) ? helperText[0] : helperText}
          {...params}
        />
      )}
    />
  );
};

export default MultiCheckbox;
