import { Box, Typography } from '@mui/material';
import { Page } from 'containers';
import strings from 'utils/language/Localization';
import Game from './errorViews/Game/Game';
import { Button } from 'components';
import { ArrowBack, Replay } from '@mui/icons-material';

const ErrorView = () => (
  <Page>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m="auto" mt={'-50px'}>
      <Typography variant="h1" mb={4}>
        {strings.somethingWentWrong}
      </Typography>
      <Box display="flex" gap={2}>
        <Button variant="outlined" onClick={() => window.history.back()} sx={{ lineHeight: 1 }}>
          <ArrowBack sx={{ mr: 0.75 }} /> {strings.goBack}
        </Button>
        <Button variant="contained" onClick={() => window.location.reload()} sx={{ lineHeight: 1 }}>
          <Replay sx={{ mr: 0.75 }} /> {strings.refreshPage}
        </Button>
      </Box>
      <Typography variant="body1" mt={4}>
        {strings.takeBreakAndPlay}
      </Typography>
      <Game />
    </Box>
  </Page>
);

export default ErrorView;
